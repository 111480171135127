import React from "react";
import PropTypes from "prop-types";
import "components/Certificate/style.css";
import moment from "moment";

const Certificate = React.forwardRef(({ request }, cert) => {
  return (
    <div ref={cert} className="application">
        <div className="certificate" id="certificate">
            <img alt="CTIA Logo" className="logo-container" src="/CTIA-Certification_Logo.png"/>
            <p className="header">CERTIFICATE</p>
            <div className="sub-header"><hr className="sub-head-hr-left"></hr><span>OF COMPLIANCE</span><hr className="sub-head-hr"/></div>
            <div className="content">
                <p className="certificate-text">This certificate recognizes the following device as meeting the {request.certCategoryName} requirements of the CTIA Cybersecurity Certification Program for IoT Devices. Awarded by CTIA Certification, Washington, DC.</p>
                <table className="manufacturer-model-container">
                    <tbody>
                        <tr>
                            <td className="manufacturer">
                                Manufacturer
                            </td>
                            <td>
                                <span className="manufacturer-text">{request.vendor}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="model">
                                Model
                            </td>
                            <td>
                                <span className="model-text">{request.model.modelnumber}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-container">
                    <thead>
                    <tr>
                        <th className="table-header form-text-field">ID</th>
                        <th className="table-header form-text-field">Hardware Version</th>
                        <th className="table-header form-text-field">Software Version</th>
                        <th className="table-header form-text-field">Date Certified</th>
                    </tr>
                    </thead>
                    <tbody>
                        {Object.keys(request.model.requests).map((key, index) => {
                            if(request.model.requests[key].completedat.length && request.model.requests[key].client === 'cyber') {
                                var date = moment(request.model.requests[key].completedat);
                                return (
                                    <tr key={index}>
                                        <td className="table-cell form-text-field" key={"ID"+index}>{request.model.requests[key].formatedtransactionnumber}</td>
                                        <td className="table-cell form-text-field" key={"HW"+index}>{request.model.requests[key].hwversion}</td>
                                        <td className="table-cell form-text-field" key={"SW"+index}>{request.model.requests[key].swversion}</td>
                                        <td className="table-cell form-text-field" key={"CD"+index}>{date.format('MMMM DD, YYYY')}</td>
                                    </tr>
                                )
                            } else return null;
                        })}
                    </tbody>
                </table>
                <table className="learn-container">
                    <tbody>
                        <tr>
                            <td className="ptcrb-qr-container">
                                <img alt="CTIA QR Code" className="qr-container" src="/Certification QR Code.jpg"/>
                            </td>
                            <td>
                                <span className="hidden">-</span>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="learn-more">Learn more</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a
                                                href="https://www.ctiacertification.org"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link underlined">
                                                ctiacertification.org
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  );
});

Certificate.propTypes = {
    request: PropTypes.object,
};

Certificate.defaultProps = {
    request: {},
};

export default Certificate;

