import { restClientGeneral, restClient } from "libs/restClient";
import * as actions from "actions/types";
import * as call from "helpers/ActionHelper";
import * as args from "helpers/ArgsBatteryHelper";
import { notifyMessages } from "helpers/NotifyHelper";

export const createAddress = manufSite => async dispatch => {
  try {
    const reqBody = args.prepareManufSiteAddressArgs(manufSite);
    const response = await restClientGeneral.post(
      "addresses",
      JSON.stringify(reqBody)
    );

    // get addressId from API response
    const payload = response.data.data.id;

    Promise.resolve({ action: "Create", manufSiteAddressId: payload });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantCreateAddress));

    return Promise.reject(e);
  }
};

export const fetchAddresses = (companyId = null) => async dispatch => {
  try {
    // companyid uses in case if someone want to fetch data for different company
    // (e.g. Staff viewing Vendor's request)
    const urlTail = companyId ? `&where=companyid=${companyId}` : "";
    const url = `/addresses?page=0${urlTail}`;
    const response = await restClient.get(url);

    dispatch({
      type: actions.FETCH_ADDRESSES,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantFetchBillingAddresses));

    return Promise.reject(e);
  }
};

export const getAddressDetails = addressId => async dispatch => {
  try {
    const url = `/addresses/${addressId}`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.GET_ADDRESS_DETAILS,
      payload: payload.data.data
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const updateAddress = addressInfo => async dispatch => {
  try {
    const url = `/addresses/${addressInfo.id}`;
    const body = args.prepareManufSiteAddressArgs(addressInfo);
    const payload = await restClient.put(url, body);

    dispatch({
      type: actions.UPDATE_ADDRESS,
      payload: payload.data
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};
