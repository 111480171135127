import React, { useEffect } from "react";
import { connect } from "react-redux";
import OrgLabRow from "containers/ControlPanel/Orgs/Labs/OrgLabRow";
import OrgListGeneric from "containers/ControlPanel/Orgs/OrgListGeneric";
import { setOrgType, fetchCompaniesLab, clearOrgList } from "actions/org";
import { LAB } from "dictionaries/Roles";

const OrgsLabs = ({ org, setOrgType, fetchCompaniesLab, clearOrgList }) => {
  useEffect(() => {
    if (!org || !org.type || org.type !== LAB) {
      setOrgType(LAB);
    }
  }, [org, setOrgType]);

  useEffect(() => {
    if (org && org.type === LAB && !org.orgList) {
      fetchCompaniesLab();
    }
  }, [org, fetchCompaniesLab]);

  useEffect(() => {
    return () => {
      clearOrgList();
    };
  }, [clearOrgList]);

  const headerFields = [
    { title: "Name", fieldname: "name" },
    { title: "City", fieldname: "citydisplay" },
    { title: "Country", fieldname: "country" },
    { title: "Contact", fieldname: "contact" },
    { title: "ATL", fieldname: "atlstatus" },
    { title: "Status", fieldname: "active" },
    { title: "", fieldname: "" }
  ];

  return (
    <OrgListGeneric
      org={org}
      headerFields={headerFields}
      rowRenderer={OrgLabRow}
    />
  );
};

function mapStateToProps({ org }) {
  return { org };
}

export default connect(mapStateToProps, {
  setOrgType,
  fetchCompaniesLab,
  clearOrgList
})(OrgsLabs);
