import React from "react";
import { connect } from "react-redux";
import SaveButton from "components/form/buttons/SaveButton";
import { saveWizard } from "actions/wizard";

const WizardSaveButton = ({
  app,
  wizard,
  request,
  saveWizard,
  onSave,
  isValid,
}) => {
  const onClick = () => {
    return onSave ? onSave() : saveWizard();
  };

  // cyber uses "agreementAccepted", but for battery we need some more complex than that
  const disabled =
    !isValid && request.agreementAccepted === false // for battery "agreementAccepted" will be always false
      ? true
      : request.agreementAccepted;

  if (request.canBeSaved && wizard && wizard.isFinalStep) {
    return (
      <SaveButton
        onClick={onClick}
        disabled={disabled}
        isLoading={app.isSaving}
        loadingText="Saving..."
        label="Save Request"
      />
    );
  }

  return null;
};

function mapStateToProps({ wizard, request, app }) {
  return { wizard, request, app };
}

export default connect(mapStateToProps, { saveWizard })(WizardSaveButton);
