import React from "react";
import { Alert } from "react-bootstrap";

const ErrorForm = ({ errors }) => {
  if (!errors) return null;

  const renderErrors = () => {
    if (Array.isArray(errors)) {
      return (
        <ul>
          {errors.map(err => {
            return <li key={err.PROPERTY}>{err.MESSAGE}</li>;
          })}
        </ul>
      );
    } else return errors;
  };

  return <Alert bsStyle="danger">{renderErrors()}</Alert>;
};

export default ErrorForm;
