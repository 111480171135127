import React from 'react';
import BatteryApproveList from 'containers/request/battery/BatteryApprove/BatteryApproveList';

const BatteryApproveContainer = () => {
  return (
    <div>
      <h1>Approve Request</h1>
      <BatteryApproveList />
    </div>
  );
};

export default BatteryApproveContainer;
