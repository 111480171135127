import _ from "lodash";
import * as actions from "actions/types";
import { mapCertPrograms } from "helpers/OrgHelper";

export default function (state = null, action) {
  switch (action.type) {
    case actions.SET_ORG_TYPE:
      return { ...state, type: action.payload };
    case actions.GET_ORGANIZATIONS_BY_TYPE:
      return { ...state, orgList: action.payload };
    case actions.GET_ORGANIZATIONS_FOR_USER:
      return { ...state, userOrgs: action.payload };
    case actions.CLEAR_ORG_LIST:
      return { ...state, orgList: null, userTypeOrg: null };
    case actions.CLEAR_USER_ORG_LIST:
      return { ...state, userOrgs: null, userTypeOrg: null };
    case actions.GET_ORGANIZATION:
      return { ...state, orgData: action.payload };
    case actions.ADD_ASC_COMPANY:
    case actions.ADD_VENDOR_COMPANY:
    // case actions.POST_NEW_COMPANY:
      const newOrg = Object.assign({}, action.payload);
      // fix for ATL update issue
      newOrg.isactiveatl = newOrg.isActiveATL || 0;

      // fix for operator update issue
      newOrg.companycerttypeslist =
        newOrg.companycerttypes && newOrg.companycerttypes.length > 0
          ? mapCertPrograms(newOrg.companycerttypes)
          : "";

      const currList = state ? state.orgList : [];
      currList.push(newOrg);

      const sortedList = _.sortBy(currList, "name");
      return { ...state, orgList: sortedList };
    case actions.UPDATE_ASC_COMPANY:
      const updatedFields = _.pick(action.payload, [
        "name",
        "address1",
        "address2",
        "address3",
        "city",
        "citydisplay",
        "stateid",
        "zipcode",
        "country",
        "countrycode",
        "countrydisplay",
        "contactid",
        "contactname",
        "contactemail",
        "ascstatus",
        "asclicensedate",
        "authorizationlevel"
      ]);
      const orgIdx = _.findIndex(state.orgList, { id: action.payload.id });
      const updatedOrg = {
        ...state.orgList[orgIdx],
        ...updatedFields,
        contactname: updatedFields.contactname,
        contactemail: updatedFields.contactemail
      };
      _.set(state, `orgList[${orgIdx}]`, updatedOrg);

      return { ...state };
    case actions.UPDATE_VENDOR_COMPANY:
      const updatedVendorFields = _.pick(action.payload, [
        "name",
        "contactname",
        "contactemail"
      ]);
      const vendorId = _.findIndex(state.orgList, { id: action.payload.id });

      // Modifying state???
      _.set(state, `orgList[${vendorId}]`, {
        ...state.orgList[vendorId],
        ...updatedVendorFields
      });

      return { ...state };
    case actions.UPDATE_LAB_COMPANY:
      const updatedLabFields = _.pick(action.payload, [
        "active",
        "address1",
        "address2",
        "address3",
        "ATLCode",
        "ATLlicensedate",
        "ATLstatus",
        "CATLlicensedate",
        "CATLstatus",
        "city",
        "citydisplay",
        "comments",
        "companyinterfaces",
        "companyieeetypes",
        "companycerttypes",
        "contactname",
        "contactemail",
        "deletedat",
        "isactiveatl",
        "name",
        "role",
        "roleid",
        "stateid",
        "zipcode"
      ]);
      const labId = _.findIndex(state.orgList, { id: action.payload.id });

      // Modifying state???
      _.set(state, `orgList[${labId}]`, {
        ...state.orgList[labId],
        atlstatus: action.payload.ATLstatus,
        ...updatedLabFields
      });

      return { ...state };
    case actions.UPDATE_OPERATOR_COMPANY:
      const updatedOperatorFields = _.pick(action.payload, [
        "name",
        "contactid",
        "contactname"
      ]);
      const operatorId = _.findIndex(state.orgList, { id: action.payload.id });
      const certTypesList = mapCertPrograms(action.payload.companycerttypes);

      _.set(state, `orgList[${operatorId}]`, {
        ...state.orgList[operatorId],
        ...updatedOperatorFields,
        companycerttypeslist: certTypesList
      });

      return { ...state };
    case actions.SET_USER_TYPE_ORG:
      return { ...state, userTypeOrg: action.role };
    case actions.ENABLE_ORGANIZATION:
    case actions.DISABLE_ORGANIZATION:
      // find the org in store
      if (state && state.orgList) {
        const { id, active } = action;
        const orgList = [...state.orgList];
        const org = _.find(orgList, ["id", id]);

        if (org) {
          org.active = active;
          return { ...state, orgList };
        }
      }

      return { ...state };
    default:
      return state;
  }
}
