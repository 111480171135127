import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import ModelOptions from "containers/request/battery/BatterySupplier/ModelOptions";
import NoDataMessage from "containers/request/battery/BatterySupplier/NoDataMessage";
import { requestFields } from "helpers/BatterySupplierHelper";
import { formatPackModels, getPackCellById } from "helpers/SupplierHelper";
import { setValue } from "actions/request";
import { fetchCertifiedPackRequests } from "actions/suppliers";

const warnMsg =
  "You have selected an embedded (non-user replaceable) pack. As described in the CTIA Certification Battery Program Management Document (see Subsystem Recognition Process-Embedded Packs), additional battery pack validation testing will be performed during the system evaluation.";

const SelectBatteryModel = ({
  app,
  request,
  suppliers,
  setValue,
  fetchCertifiedPackRequests
}) => {
  const [show, setShow] = useState(false);
  const [showEmbeddedWarning, setShowWarning] = useState(false);
  const requestField = requestFields.model;
  const supplier = request[requestFields.supplier];
  const { certifiedPacks: models } = suppliers;

  useEffect(() => {
    if (supplier && supplier > 0) {
      setShow(true);
    } else {
      setShow(false);
      setValue(requestField, "");
    }

    // hide embedded warning message
    setShowWarning(false);
  }, [supplier, requestField, setValue, setShowWarning]);

  const onChange = event => {
    const modelId = Number(event.target.value);

    setValue(requestField, modelId);

    const pack = getPackCellById(modelId, models);

    if (pack) {
      if (pack.certTypeId) {
        setShowWarning(pack.certTypeId === app.idEPRR);
      }

      setValue(requestFields.modelId, modelId);
      setValue(requestFields.modelName, pack.title);
      setValue(requestFields.linkedRequestId, pack.reqId);

      // fetch related revisions
      fetchCertifiedPackRequests(modelId, request.ieee, app.idPRR, app.idEPRR);
    } else {
      setShowWarning(false);

      setValue(requestFields.modelId, null);
      setValue(requestFields.modelName, null);
      setValue(requestFields.linkedRequestId, null);
    }
  };

  if (!show || !models) return null;

  if (!models.length)
    return <NoDataMessage message="No available models for selected type" />;

  return (
    <div>
      <ModelOptions />
      <FormElement
        type="select"
        id={requestField}
        show={Number(request[requestFields.modelOption]) === 1}
        value={request[requestField] || 0}
        onChange={onChange}
        options={formatPackModels(models)}
      />
      <FormElement
        type="formWarning"
        show={showEmbeddedWarning}
        text={warnMsg}
      />
    </div>
  );
};

function mapStateToProps({ app, request, suppliers }) {
  return { app, request, suppliers };
}

export default connect(mapStateToProps, {
  setValue,
  fetchCertifiedPackRequests
})(SelectBatteryModel);
