import React from "react";
import { WithModal } from "ctia-ui";
import EditServiceCenterModal from "containers/ControlPanel/Orgs/ServiceCenter/EditServiceCenterModal";

class EditServiceCenter extends WithModal {
  render() {
    const { id, name } = this.props;

    return (
      <div className="glow-link cursor-pointer">
        <span onClick={this.handleModal}>{name}</span>
        {this.state.showModal && (
          <EditServiceCenterModal id={id} modalHandler={this.handleModal} />
        )}
      </div>
    );
  }
}

export default EditServiceCenter;
