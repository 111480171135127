import React from "react";
import { WithModal } from "ctia-ui";
import DisableOrgModal from "containers/ControlPanel/Orgs/DisableOrg/DisableOrgModal";
import EnableOrgLink from "containers/ControlPanel/Orgs/DisableOrg/EnableOrgLink";
import DisableOrgLink from "containers/ControlPanel/Orgs/DisableOrg/DisableOrgLink";

class DisableOrg extends WithModal {
  render() {
    const { org } = this.props;

    return (
      <>
        <EnableOrgLink org={org} show={org.active === 0} />
        <DisableOrgLink
          toggleModal={this.handleModal}
          show={org.active === 1}
        />
        <DisableOrgModal
          show={this.state.showModal}
          modalHandler={this.handleModal}
          org={org}
        />
      </>
    );
  }
}

export default DisableOrg;
