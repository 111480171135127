import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dropdown } from "ctia-react-bootstrap-v4";
import { setInvoicingYear, fetchAtlList, fetchAtlInvoices } from "actions/catl";
import { startPreloader, finishPreloader } from "actions/preloader";
import {
  getInvoicingYears,
  getCurrentYear,
  getCurrATLYear
} from "helpers/DateHelper";
import { showNotice } from "actions/notifier";
import { notifyMessages } from "helpers/NotifyHelper";

const InvoiceYearSelector = ({
  catl,
  setInvoicingYear,
  fetchAtlList,
  fetchAtlInvoices,
  startPreloader,
  finishPreloader,
  showNotice
}) => {
  useEffect(() => {
    if (catl && !catl.invoicingYear) {
      setInvoicingYear(getCurrATLYear());
    }
  }, [setInvoicingYear, catl]);

  const YearToggle = React.forwardRef(({ children, onClick }, ref) => {
    return (
      <span
        className="dashed cursor-pointer"
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </span>
    );
  });

  const currYear = getCurrentYear();
  const years = getInvoicingYears();
  const selectedYear =
    catl && catl.invoicingYear ? catl.invoicingYear : currYear;

  return (
    <Dropdown
      onSelect={year => {
        // not allow re-fetching while there's an active background job
        if (catl.isBackgroundJobRunning) {
          return showNotice(notifyMessages.backgroundJobInActionWarning);
        }

        if (Number(year) !== Number(catl.invoicingYear)) {
          startPreloader();
          setInvoicingYear(year);
          fetchAtlList(year)
            .then(() => {
              fetchAtlInvoices(year);
              finishPreloader();
            })
            .catch(finishPreloader);
        }
      }}
    >
      <Dropdown.Toggle as={YearToggle} id="dropdown-custom-components">
        {selectedYear}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>Select a year:</Dropdown.Header>
        {years.map(year => {
          const isActive =
            catl && catl.invoicingYear
              ? year === catl.invoicingYear
              : year === currYear;
          return (
            <Dropdown.Item key={year} eventKey={year} active={isActive}>
              {year}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

function mapStateToProps({ catl }) {
  return { catl };
}

export default connect(mapStateToProps, {
  setInvoicingYear,
  fetchAtlList,
  fetchAtlInvoices,
  startPreloader,
  finishPreloader,
  showNotice
})(InvoiceYearSelector);
