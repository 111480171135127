import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import VendorContent from "containers/request/RequestContent/VendorContent";
import reqTypes from "dictionaries/ReqTypes";
import { batteryTabsBLC, batteryTabsBLCECO } from "helpers/RequestTabsHelper";
import {
  getChecklistInitialBLC,
  getChecklistEcoBLC
} from "helpers/ChecklistBatteryHelper";

const RequestContentBLC = ({ hideBillingPay = false, request, focusLab }) => {
  switch (request.type) {
    case reqTypes.initial:
      const checkListInitial = getChecklistInitialBLC(request);
      const tabsInitial = hideBillingPay
        ? _.filter(batteryTabsBLC, tab => {
            return tab.key !== "billing";
          })
        : batteryTabsBLC;

      return (
        <VendorContent
          tabs={tabsInitial}
          checkList={checkListInitial}
          defaultTab={focusLab ? "labInfo" : "deviceInfo"}
        />
      );
    case reqTypes.eco:
      const checkListECO = getChecklistEcoBLC(request);
      const tabsECO = hideBillingPay
        ? _.filter(batteryTabsBLCECO, tab => {
            return tab.key !== "billing";
          })
        : batteryTabsBLCECO;

      return (
        <VendorContent
          tabs={tabsECO}
          checkList={checkListECO}
          defaultTab={focusLab ? "labInfo" : "deviceInfo"}
        />
      );
    default:
      return null;
  }
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(RequestContentBLC);
