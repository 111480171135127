import * as actions from "actions/types";

const fetchComments = (action, state) => {
  if (!action.payload.data) return state;

  const { meta, data } = action.payload.data;
  return { pager: meta, data };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_REQUEST_COMMENTS:
    case actions.FETCH_ATL_COMMENTS:
    case actions.FETCH_ASC_COMMENTS:
      return fetchComments(action, state);
    case actions.LOGOUT:
    case actions.CLEAR_COMMENTS:
      return null;
    case actions.POST_COMMENT:
    case actions.POST_ATL_COMMENT:
    case actions.POST_ASC_COMMENT:
    default:
      return state;
  }
}
