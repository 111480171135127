import React from "react";
import "./canvas.css";

const IoTLogoCanvas = ({ labCode }) => {
  return (
    <div className="canvasLogoContainer canvasLogoContainer__IoT">
      <img src="/assets/CTIA_IoT_Logo.svg" alt="IoT Logo" height={100} />
      <div className="canvasText_IoT">
        <b>{labCode}</b>
      </div>
    </div>
  );
};

export default IoTLogoCanvas;
