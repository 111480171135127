import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Glyphicon } from "react-bootstrap";
import UploadQueue from "components/UploadQueue/index";
import ReqLink from "components/RequestsLink";
import {
  hasDocsForUpload,
  getUploadQueue,
  hasTitlesForChange,
  getUploadedTitlesQueue,
  manageQueue,
  prepareUsersManualOptions,
  prepareSupDocOptions
} from "helpers/UploadsHelper";
import {
  uploadUsersManual,
  uploadSupportDocument,
  addToQueue,
  removeFromQueue,
  clearQueue,
  updateSupDocTitle
} from "actions/uploads";
import { fetchRequest } from "actions/requestCyber";

const getModelId = (deviceModelId, model) => {
  if (deviceModelId) return deviceModelId;
  if (model) return model.id || null;

  return null;
};

class RequestCompletionPanel extends Component {
  params = null;

  initPanelParams = () => {
    const {
      id,
      newId,
      model,
      deviceModelId,
      supportDocs,
      usersManual,
      isUpdate
    } = this.props.request;

    this.params = {
      requestId: newId || id,
      modelId: getModelId(deviceModelId, model),
      supportDocs,
      usersManual,
      isUpdate
    };
  };

  // uploading users manual
  manualUpload = () => {
    const { usersManual, modelId } = this.params;
    const { uploadUsersManual, addToQueue, removeFromQueue } = this.props;

    if (usersManual && usersManual.file) {
      const uploadOptions = prepareUsersManualOptions(usersManual, modelId);

      manageQueue(
        uploadOptions,
        uploadUsersManual,
        addToQueue,
        removeFromQueue
      );
    }
  };

  // uploading support documents
  supDocsUpload = () => {
    const { supportDocs, isUpdate, requestId } = this.params;
    const {
      uploadSupportDocument,
      addToQueue,
      removeFromQueue,
      request
    } = this.props;

    if (hasDocsForUpload(supportDocs)) {
      const queue = getUploadQueue(supportDocs);
      _.map(queue, doc => {
        const { file, title, slot } = doc;
        const uploadOptions = prepareSupDocOptions({
          slot,
          title,
          requestId,
          file,
          isUpdate,
          request
        });
        manageQueue(
          uploadOptions,
          uploadSupportDocument,
          addToQueue,
          removeFromQueue
        );
      });
    }
  };

  // update sup docs' titles
  updateSupDocTitles = () => {
    const { supportDocs, requestId } = this.params;

    if (hasTitlesForChange(supportDocs)) {
      const queue = getUploadedTitlesQueue(supportDocs);
      _.map(queue, doc => {
        this.props.updateSupDocTitle(requestId, doc.slot, doc.title);
      });
    }
  };

  // fetch request invoice info to generate a notice message
  fetchRequestInvoice = () => {
    const { request } = this.props;

    if (_.has(request, "newId")) {
      fetchRequest(request.newId);
    }
  };

  componentDidMount() {
    this.initPanelParams();

    // uploading users manual
    this.manualUpload();

    // uploading support documents
    this.supDocsUpload();

    // update sup docs' titles
    this.updateSupDocTitles();

    // fetch request invoice info to generate a notice message
    this.fetchRequestInvoice();
  }

  componentWillUnmount() {
    this.props.clearQueue();
  }

  definePanelProps = () => {
    const { isSubmitted, isSaved } = this.props.wizard;
    const { isUpdated } = this.props.request;
    return { isSubmitted, isSaved, isUpdated };
  };

  getResultTitle = () => {
    const { isSubmitted, isUpdated } = this.definePanelProps();

    if (isSubmitted) return "submitted";
    if (isUpdated) return "updated";

    return "saved";
  };

  getReqNum = () => {
    const { request } = this.props;
    if (!request || !request.tn) return null;

    return <b>{request.tn}</b>;
  };

  render() {
    return (
      <Alert style={{ margin: "30px 0" }}>
        <p>
          <Glyphicon glyph="ok" className="icon-right-offset" />
          Request {this.getReqNum()} has been successfully{" "}
          {this.getResultTitle()}. You can review{" "}
          <b>
            <ReqLink linkTitle="all your requests here" />
          </b>
          .
        </p>
        <UploadQueue />
      </Alert>
    );
  }
}

function mapStateToProps({ request, wizard }) {
  return { request, wizard };
}

export default connect(mapStateToProps, {
  uploadUsersManual,
  uploadSupportDocument,
  addToQueue,
  removeFromQueue,
  clearQueue,
  updateSupDocTitle,
  fetchRequest
})(RequestCompletionPanel);
