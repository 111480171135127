import _ from "lodash";
import React, { Component } from "react";
import { Bubble } from "ctia-ui";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import LabResultList from "containers/request/battery/TestResults/BatteryLabResultList";
import { getRequestsForTesting } from "actions/battery/batteryRequests";
import { clearRequests } from "actions/requests";

class BatteryTestResults extends Component {
  componentDidMount() {
    this.props.getRequestsForTesting();
  }

  componentWillUnmount() {
    this.props.clearRequests();
  }

  render() {
    const { requests } = this.props;

    if (_.isNull(requests)) {
      return null;
    }

    const renderResults = () => {
      if (_.isEmpty(requests.auditList))
        return (
          <Bubble type="warning">
            No requests waiting for entering results yet.
          </Bubble>
        );
      return <LabResultList list={requests.auditList} />;
    };

    return (
      <div>
        <Row>
          <Col xs={6}>
            <h1 className="block-bottom-offset">Test Results</h1>
          </Col>
          <Col xs={6} />
        </Row>
        {renderResults()}
      </div>
    );
  }
}

function mapStateToProps({ app, requests }) {
  return { app, requests };
}

export default connect(mapStateToProps, {
  getRequestsForTesting,
  clearRequests
})(BatteryTestResults);
