import React from "react";
import { renderRequestLink } from "helpers/RequestHelper";

const ListOfReqLinks = ({ list, subtype }) => {
  if (!list || list.length === 0) return "Empty list";

  return (
    <ul className="ctia-list form-field-list">
      {list.map((el, i) => {
        const reqLink = renderRequestLink(el.value, el.label, true, subtype);

        return <li key={`link-list-el-${i}`}>{reqLink}</li>;
      })}
    </ul>
  );
};

export default ListOfReqLinks;
