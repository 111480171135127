import React from "react";
import TestStatusTableRow from "containers/request/Initial/TestStatusTableRow";
import LabTestInfoContainerEPR from "containers/request/EPRR/LabTestInfoContainerEPR";

const customTitles = {
  col1: "Test",
  col2: "Lab",
  col3: "Status",
  col1Row: "Battery Compliance"
};

const LabTestInfoEPR = ({ editable }) => {
  return (
    <LabTestInfoContainerEPR
      editable={editable}
      customTitles={customTitles}
      statusComponent={TestStatusTableRow}
    />
  );
};

export default LabTestInfoEPR;
