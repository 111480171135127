import { SHOW_NOTICE, HIDE_NOTICE } from 'actions/types';

export default function(state = null, action) {
  switch (action.type) {
    case SHOW_NOTICE:
      return { show: true, message: action.payload };
    case HIDE_NOTICE:
      return { show: action.payload };
    default:
      return state;
  }
}
