export const pending = 1;
export const approved = 2;
export const rejected = 3;

export const isPendingDoc = status => Number(status) === pending;
export const isApprovedDoc = status => Number(status) === approved;
export const isRejectedDoc = status => Number(status) === rejected;

export const docStatuses = {
  pending: {
    id: 1,
    title: "Pending",
    icon: "hourglass",
    variant: "default"
  },
  approved: {
    id: 2,
    title: "Approved",
    icon: "ok",
    variant: "success"
  },
  rejected: {
    id: 3,
    title: "Rejected",
    icon: "remove",
    variant: "danger"
  }
};

/**
 * Returns object for document status by its ID.
 *
 * @param {number} statusId Document status ID
 * @returns object
 */
export function getDocStatus(statusId) {
  switch (Number(statusId)) {
    case pending:
      return docStatuses.pending;
    case approved:
      return docStatuses.approved;
    case rejected:
      return docStatuses.rejected;
    default:
      return docStatuses.pending;
  }
}
