import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import BatteryModelAutocomplete from "containers/request/battery/BatteryModelAutocomplete";
import RequestRevision from "components/request/RequestRevision";
import NonSarSites from "containers/request/battery/NonSarSites/NonSarSites";
import NonSarSitesReadonly from "containers/request/battery/NonSarSites/NonSarSitesReadonly";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import { setValue, setBatteryRequestPermissions } from "actions/request";
import { initARR, updateDeviceInfoARR } from "actions/battery/arrRequest";
import { mapNonSarManufSites } from "actions/battery/batteryRequests";
import { submitWizardStep } from "actions/wizard";
import { ifModelExists } from "actions/model";
import { hasStringValue } from "helpers/RequestHelper";
import { findExistedModelByName } from "helpers/ModelHelper";
import { getRequestOwner } from "helpers/UsersHelper";

class RequestContainerARR extends CyberComponent {
  componentDidMount() {
    const { request, user } = this.props;
    const ownerId = getRequestOwner(request, user);

    // fetch only for the first time on submissions
    if (!this.isValidToSubmit() && !request.id) {
      this.props.initARR(request.ieee, ownerId);
    }

    // fetch for existed requests
    if (request.id) {
      this.props
        .initARR(request.ieee, ownerId)
        .then(() => this.props.mapNonSarManufSites(request.linkedManufsites));
    }

    // set permissions for existed request
    this.props.setBatteryRequestPermissions(request, user);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.togglerEnabled !== this.props.user.togglerEnabled) {
      return this.props.setBatteryRequestPermissions(
        this.props.request,
        this.props.user
      );
    }
  }

  onSaveClick = () => {
    const { request, sites, updateDeviceInfoARR } = this.props;
    updateDeviceInfoARR(request, sites.nonSarSites);
  };

  onContinueClick = () => {
    const { request, requests, user, setValue, ifModelExists } = this.props;

    // check if entered model name exists
    const existedModel = findExistedModelByName(
      request.existedModel,
      requests.vendorBatteryModels
    );

    // if so - use existed model id
    const existedModelId = existedModel ? existedModel.value : null;

    // update request field in redux
    setValue("existedModelId", existedModelId);

    // check modelname uniqueness
    if (!existedModel) {
      ifModelExists(request.existedModel, user.companyid).then(isUnique => {
        if (isUnique) {
          return this.props.submitWizardStep();
        }
      });
    } else {
      return this.props.submitWizardStep();
    }
  };

  validateModel() {
    const { request, requests } = this.props;
    if (!request || !requests) return false;

    const fieldName = request.id ? "modelName" : "existedModel";

    return hasStringValue(request, fieldName);
  }

  validateRevision() {
    return hasStringValue(this.props.request, "revision");
  }

  validateManufSites() {
    const { sites } = this.props;

    return sites && sites.nonSarSites && _.some(sites.nonSarSites, "checked");
  }

  isValidToSubmit() {
    const { canEditDeviceInfo } = this.props.user;

    // don't need to validate if form is non-editable
    if (canEditDeviceInfo === false) return true;

    // check if all required fields are valid
    return (
      this.validateModel() &&
      this.validateRevision() &&
      this.validateManufSites()
    );
  }

  render() {
    const { canEditDeviceInfo } = this.props.user;
    const saveHandler = canEditDeviceInfo ? this.onSaveClick : null;
    const isEditable = { editable: canEditDeviceInfo };
    const isValid = this.isValidToSubmit();

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestManufacturer />
          <BatteryModelAutocomplete />
          <RequestRevision fieldId="revision" {...isEditable} />
          <SubmissionDetails />
          <hr />
          {canEditDeviceInfo ? <NonSarSites /> : <NonSarSitesReadonly />}
          <RequestControls
            isValidToSubmit={this.isValidToSubmit()}
            isValid={isValid}
            onSave={saveHandler}
            onContinue={this.onContinueClick}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ app, request, requests, user, sites }) {
  return { app, request, requests, user, sites };
}

export default connect(mapStateToProps, {
  initARR,
  submitWizardStep,
  updateDeviceInfoARR,
  setValue,
  setBatteryRequestPermissions,
  mapNonSarManufSites,
  ifModelExists
})(RequestContainerARR);
