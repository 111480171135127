import { restClient } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import { getPageArgsAsQueryString } from "helpers/ArgsHelper";
import * as call from "helpers/ActionHelper";
import * as actions from "actions/types";

// ##########################################
//              Pure API calls
// ##########################################
export const fetchRequestList = ({
  page,
  size,
  statusFilter
}) => async dispatch => {
  try {
    const filterList = statusFilter.join();
    const pagerArgs = getPageArgsAsQueryString(page, size);
    const url = `/requests/table?${pagerArgs}&orderBy=transactionnumber DESC&where=requeststatusid IN (${filterList})`;
    const response = await restClient.get(url);

    dispatch({
      type: actions.FETCH_CERTIFICATION_REQUESTS,
      payload: response.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

// ##########################################
//          Redux action creators
// ##########################################
export function fetchCertificationRequests(settings) {
  return call.dispatchWithPreloader(fetchRequestList, settings);
}
