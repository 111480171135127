import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Bubble, LoaderButton } from "ctia-ui";
import { getPrerenderValues } from "components/ModalConfirm/types";

const ModalConfirm = ({
  show,
  onHide,
  onHandle,
  onValidate,
  requestData,
  isLoading,
  type,
  isFormValid,
  errorMessage
}) => {
  if (!show) return null;

  const preRender = getPrerenderValues(type, requestData);

  if (!preRender) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{preRender.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <Bubble type="danger">{errorMessage}</Bubble>}
        {preRender.body(isFormValid, onValidate)}
      </Modal.Body>
      <Modal.Footer>
        <LoaderButton
          bsStyle={preRender.button}
          text={preRender.buttonText}
          onClick={onHandle}
          isLoading={isLoading}
          loadingText={preRender.buttonLoadingText}
          className="button-right-offset"
        />
        <LoaderButton text="Close" onClick={onHide} disabled={isLoading} />
      </Modal.Footer>
    </Modal>
  );
};

ModalConfirm.propTypes = {
  errorMessage: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onHandle: PropTypes.func,
  requestData: PropTypes.object,
  isLoading: PropTypes.bool,
  type: PropTypes.string
};

export default ModalConfirm;
