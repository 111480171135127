import React from "react";
import StaticText from "components/form/StaticText";
import InputText from "components/form/InputText";
import Select from "components/form/Select";
import CheckboxList from "components/form/CheckboxList/index";
import TextArea from "components/form/TextArea";
import Date from "components/form/Date";
import Checkbox from "components/form/Checkbox";
import ToggleButtonGroup from "components/form/ToggleButtonGroup";
import ToggleRadioGroup from "components/form/ToggleRadioGroup";
import MaskedInput from "components/form/InputTextWithMask";
import Toggler from "components/form/Toggler";
import Combobox from "components/form/Combobox";
import ActionButton from "components/form/ActionButton";
import { MailtoLink } from "ctia-ui";
import Voltage from "components/form/Voltage";
import ReadonlyList from "components/form/ReadonlyList";
import OneLineText from "components/form/OneLineText";
import ListOfReqLinks from "components/form/ListOfReqLinks";
import ListOfLinksCRR from "components/form/ListOfLinksCRR";
import ListOfLinksBSC from "components/form/ListOfLinksBSC";
import FormWarning from "components/form/FormWarning";
import CountrySelector from "components/CountrySelector/CountrySelector";
import StateAndProvinceSelector from "components/StateAndProvinceSelector";

const textStyle = "bold dark-teal";

const FormElementSelector = ({ type, ...rest }) => {
  switch (type) {
    case "text":
      return <InputText {...rest} />;
    case "password":
      return <InputText {...rest} isPassword={true} />;
    case "select":
      return <Select {...rest} />;
    case "checkboxList":
      return <CheckboxList {...rest} />;
    case "textarea":
      return <TextArea {...rest} />;
    case "date":
      return <Date {...rest} />;
    case "checkbox":
      return <Checkbox {...rest} />;
    case "toggleButtonGroup":
      return <ToggleButtonGroup {...rest} />;
    case "toggleRadioGroup":
      return <ToggleRadioGroup {...rest} />;
    case "maskedInput":
      return <MaskedInput {...rest} />;
    case "toggler":
      return <Toggler {...rest} />;
    case "actionButton":
      return <ActionButton {...rest} />;
    case "comboBox":
      return <Combobox {...rest} />;
    case "emailLink":
      return (
        <MailtoLink textStyle={textStyle} linkStyle="glow-link" {...rest} />
      );
    case "voltage":
      return <Voltage {...rest} />;
    case "readonlyList":
      return <ReadonlyList {...rest} />;
    case "oneLineText":
      return <OneLineText {...rest} />;
    case "listOfReqLinks":
        return <ListOfReqLinks {...rest} />;
    case "listOfLinksCRR":
      return <ListOfLinksCRR {...rest} />;
    case "listOfLinksBSC":
      return <ListOfLinksBSC {...rest} />;
    case "formWarning":
      return <FormWarning {...rest} />;
    case "country":
      return <CountrySelector {...rest} />;
    case "stateAndProvince":
      return <StateAndProvinceSelector {...rest} />;
    default:
      return <StaticText {...rest} />;
  }
};

export default FormElementSelector;
