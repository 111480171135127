import {
  restClient,
  restClientGeneral,
  restClientAdmin
} from "libs/restClient";
import * as call from "helpers/ActionHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import * as actions from "actions/types";

export const fetchAuthorizedLabs = () => async dispatch => {
  try {
    const url = "/companies/labs?perpage=500&orderBy=name ASC";
    const payload = await restClient.get(url);

    dispatch({
      type: actions.FETCH_AUTHORIZED_LABS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getCyberLabs() {
  return call.dispatchWithPreloader(fetchAuthorizedLabs);
}

export function getOtaLabs() {
  return call.dispatchWithPreloader(fetchAuthorizedLabs);
}

export function getDhrLabs() {
  return call.dispatchWithPreloader(fetchAuthorizedLabs);
}

export const fetchBatteryAuthorizedLabs = () => async dispatch => {
  try {
    const url =
      "/companies?page=0&orderBy=name ASC&where=assignedBatteryInterfaces IS NOT NULL AND isActiveLab = 1";
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_AUTHORIZED_LABS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getBatteryLabs() {
  return call.dispatchWithPreloader(fetchBatteryAuthorizedLabs);
}

export function getBLCLabs() {
  return call.dispatchWithPreloader(fetchAuthorizedLabs);
}

export const fetchAuthorizedLabsByIEEE =
  (ieee, urlPrefix = "sar") =>
  async dispatch => {
    try {
      const urlHead = urlPrefix === "sar" ? "" : `${urlPrefix}/`;
      const url = `/${urlHead}companies/labs?page=0&orderBy=name ASC&where=assignedBatteryInterfaces IS NOT NULL AND ieee${ieee}Authorized = 1 AND isActiveLab = 1`;
      const payload = await restClientGeneral.get(url);

      dispatch({
        type: actions.FETCH_AUTHORIZED_LABS,
        payload
      });

      return payload;
    } catch (e) {
      dispatch(call.showNotice(notifyMessages.errorAsyncAction));

      return Promise.reject(e);
    }
  };

export function getAuthorizedLabsByIEEE(ieeeType, urlPrefix) {
  const ieee = Number(ieeeType);
  return dispatch => {
    const finalize = () => dispatch(call.stopPreloader());
    dispatch(call.startPreloader());
    return dispatch(fetchAuthorizedLabsByIEEE(ieee, urlPrefix))
      .then(finalize)
      .catch(finalize);
  };
}

export const getLabsToSwitch = () => async dispatch => {
  try {
    const res = await restClientGeneral.get("switchto?orderby=name");
    const payload = res.data.data;

    dispatch({
      type: actions.GET_LABS_TO_SWITCH,
      payload
    });

    return payload;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const switchLabCompany = companyId => async dispatch => {
  // start preloader
  dispatch({
    type: actions.START_PRELOADER,
    payload: null
  });

  try {
    const url = `/switchto/${companyId}`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.LOGOUT,
      payload: null
    });

    dispatch({
      type: actions.SAVE_USER_TOKEN,
      payload: response.data.data
    });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });
  }

  // stop preloader
  dispatch({
    type: actions.STOP_PRELOADER,
    payload: null
  });
};

export const getAtlUsers = labId => async dispatch => {
  try {
    const url = `/users?page=0&include=company&orderBy=fullname ASC&where=companyid=${labId}`;
    const response = await restClientAdmin.get(url);

    dispatch({
      type: actions.GET_ATL_USERS,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
