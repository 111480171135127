import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Bubble } from "ctia-ui";
import { simpleGetManufSites } from "actions/sitesSar";
import CyberComponent from "containers/CyberComponent";
import RequestControls from "containers/request/RequestControls";
import RenewalContainer from "containers/request/battery/ManufSite/RenewalContainer";
import Instructions from "containers/request/battery/ManufSite/RenewalBubble";
import { validateRenewalSite } from "helpers/ManufSiteHelper";

class SiteRenewalContainer extends CyberComponent {
  componentDidMount() {
    const { request, user, simpleGetManufSites } = this.props;
    const companyid = user.isStaff
      ? request.submitterId
      : user.requestOwnerCompanyId;

    // fetch existed sites and addresses
    simpleGetManufSites(request.ieee, companyid);
  }

  onSaveClick = () => {
    console.log("save data");
  };

  validate() {
    const { request, user } = this.props;

    // don't need to validate if form is non-editable
    if (!user.canEditRequest) return true;

    return validateRenewalSite(request);
  }

  renderError = () => {
    return (
      <Bubble type="danger">
        Please submit a SAR Initial request first in order to submit the
        Update/Renewal.
      </Bubble>
    );
  };

  renderSelector = () => {
    return (
      <div>
        <Form horizontal>
          <Instructions />
          <RenewalContainer />
          <RequestControls
            isValid={this.validate()}
            onSave={this.onSaveClick}
          />
        </Form>
      </div>
    );
  };

  setRenderOptions = () => {
    const { sites } = this.props;

    if (sites && sites.list && sites.list.length === 0) {
      return this.renderError();
    }

    return this.renderSelector();
  };

  render() {
    return this.setRenderOptions();
  }
}

function mapStateToProps({ request, user, sites }) {
  return { request, user, sites };
}

export default connect(mapStateToProps, {
  simpleGetManufSites,
})(SiteRenewalContainer);
