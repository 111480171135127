import _ from "lodash";
import { restClient, restClientGeneral } from "libs/restClient";
import { downloadFile } from "helpers/UploadsHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { getModelName } from "helpers/RequestHelper";
import * as actions from "actions/types";

const agreementTemplate = "LicenseAgreement_Template.pdf";

export const signAgreement = request => async dispatch => {
  const action = {
    error: false,
    requestId: request.id,
    modelId: request.model.id
  };

  // 1 - start preloader
  dispatch({ type: actions.START_PRELOADER });

  // 2 - sign an agreement for selected model
  try {
    const url = `/devicemodels/${action.modelId}/licenseagreements`;
    const res = await restClient.post(url);
    const isSigned = res.data.data.documentstatusid === 1;

    dispatch({ type: actions.SIGN_AGREEMENT, payload: isSigned });
  } catch (e) {
    // 2 - can't sign an agreement on server side
    action.error = true;

    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantSignAgreement
    });
  }

  // 3 - agreement signed, updating redux store (request)
  if (!action.error) {
    try {
      const url = `/requests/${action.requestId}/details`;
      const response = await restClient.get(url);

      dispatch({
        type: actions.FETCH_REQUEST_CYBER,
        payload: response.data.data
      });
    } catch (e) {
      dispatch({
        type: actions.SHOW_NOTICE,
        payload: notifyMessages.errorFetchRequestAction
      });
    }
  }

  if (!action.error) {
    try {
      const url = `/requesttests?include=reports,requesttestconductinglabs,lab&where=requestid=${action.requestId}`;
      const payload = restClient.get(url);

      dispatch({
        type: actions.FETCH_REQUEST_TESTS,
        payload
      });
    } catch (e) {
      dispatch({
        type: actions.SHOW_NOTICE,
        payload: notifyMessages.errorAsyncAction
      });
    }
  }

  // 4 - stop preloader
  dispatch({ type: actions.STOP_PRELOADER });
  dispatch({
    type: actions.SHOW_NOTICE,
    payload: notifyMessages.requestUpdatedSuccessfully
  });
};

export function downloadAgreement({ model, tn }) {
  // file name pattern: CYBER000000_LicenseAgreement.pdf
  // e.g. CYBER000345_LicenseAgreement.pdf
  const fileName = `${tn}_LicenseAgreement.pdf`;
  const url = `/devicemodels/${model.id}/licenseagreements?download=true`;
  const payload = restClient
    .get(url, { responseType: "blob" })
    .then(res => {
      downloadFile(res.data, fileName);
      return res;
    })
    .catch(err => err);

  return {
    type: actions.DOWNLOAD_SIGNED_AGREEMENT,
    payload
  };
}

export function downloadAgreementBlank(
  submitter = "",
  modelnumber = "",
  certProg = ""
) {
  const urlPrefix = certProg ? `/${certProg}` : "";
  const url = `${urlPrefix}/licenseagreements?download=true&submitter=${submitter}&devicemodelnumber=${modelnumber}`;
  const payload = restClientGeneral
    .get(url, { responseType: "blob" })
    .then(response => {
      downloadFile(response.data, agreementTemplate);
      return response;
    })
    .catch(err => err);

  return {
    type: actions.DOWNLOAD_AGREEMENT_BLANK,
    payload
  };
}

export function download(agreement, request, user, certProg) {
  if (agreement && !_.isUndefined(agreement.document)) {
    return downloadAgreement(request);
  } else {
    const modelName = getModelName(request);
    return downloadAgreementBlank(user.company, modelName, certProg);
  }
}

export function cleanAgreementInfo() {
  return {
    type: actions.CLEAN_AGREEMENT_INFO,
    payload: null
  };
}

export const getAgreementInfo = request => async dispatch => {
  if (_.isUndefined(request.model.id)) {
    dispatch(cleanAgreementInfo());
  } else {
    dispatch({ type: actions.START_PRELOADER });

    try {
      const url = `/devicemodels/${request.model.id}/licenseagreements`;
      const response = await restClient.get(url);
      dispatch({
        type: actions.GET_AGREEMENT_INFO,
        payload: response.data.data
      });
    } catch (e) {
      dispatch({
        type: actions.SHOW_NOTICE,
        payload: notifyMessages.errorAsyncAction
      });
    }

    dispatch({ type: actions.STOP_PRELOADER });
  }
};

export const sign = modelId => async dispatch => {
  try {
    const url = `/devicemodels/${modelId}/licenseagreements`;
    const res = await restClient.post(url);
    const isSigned = res.data.data.documentstatusid === 1;

    dispatch({ type: actions.SIGN_AGREEMENT, payload: isSigned });

    return res;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantSignAgreement
    });

    return Promise.reject(e);
  }
};

export const getNewestLicenseAgreementVersion = () => async () => {
	const response = await restClientGeneral.get("licenseagreementversion?newest=true");
	const data = response.data;

	return data;
}
