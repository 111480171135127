import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { Bubble } from "ctia-ui";
import { handleInputChange } from "actions/request";
import { clearCountryState } from "actions/countries";
import { getCountriesList } from "helpers/Countries";

const BillingAddressCountry = ({
  user,
  request,
  countries,
  handleInputChange,
  clearCountryState
}) => {
  if (!countries.list)
    return <Bubble>Error: can't fetch countries from server.</Bubble>;

  const isEditable = user.canEditRequest || user.canEditBilling;

  const onCountryChange = event => {
    // need to clear country state when country changed
    clearCountryState();

    // now we can handle country as well
    handleInputChange(event);
  };

  return (
    <FormElement
      type="select"
      id="billingAddressCountry"
      label="Country"
      value={request.billingAddressCountry}
      onChange={onCountryChange}
      options={getCountriesList(countries.list)}
      editable={isEditable}
    />
  );
};

function mapStateToProps({ request, user, countries }) {
  return { request, user, countries };
}

export default connect(mapStateToProps, {
  handleInputChange,
  clearCountryState
})(BillingAddressCountry);
