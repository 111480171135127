import React from "react";
import "./canvas.css";

const PTCRBLogoCanvas = ({ labCode }) => {
  return (
    <div className="canvasLogoContainer canvasLogoContainer__PTCRB">
      <img src="/assets/CTIA_PTCRB_Logo.svg" alt="PTCRB Logo" height={100} />
      <div className="canvasText_PTCRB">
        <b>{labCode}</b>
      </div>
    </div>
  );
};

export default PTCRBLogoCanvas;
