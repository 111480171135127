import _ from "lodash";

export const interfaceNameForLabs = lab => {
  return !_.isEmpty(lab.labname)
    ? lab.labname
    : lab.name;
};

export const formatInterfaceForUIComponent = ({
  interfaceItem,
  isRequired = false,
  precheckedIds = []
}) => {
  const id = interfaceItem.id;
  let defaultChecked = _.isArray(id)
    ? _.some(precheckedIds, preCheckedId => _.includes(id, preCheckedId))
    : _.includes(precheckedIds, id);

  return {
    type: 'interface',
    defaultChecked,
    id,
    isRequired,
    value: interfaceNameForLabs(interfaceItem)
  };
}