// ES6 Helpers
import _ from "lodash";

// React / Redux
import { Link } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";

// Components
import WithdrawButton
  from "components/DeviceRecognition/WithdrawModalAndButton/WithdrawButton";
import OneLineText from "components/form/OneLineText";

// Helpers and Dictionaries
import { getCertTypeFromCertTypeId } from "helpers/BatteryCertHelper";
import { formatBillingAddress as formatAddress } from "helpers/BillingHelper";
import { mapSiteAddress } from "helpers/ManufSiteHelper";
import { concatStr } from "helpers/StringHelper";

const BatteryManufSiteRow = ({
  requestReloadTable,
  rowData,
  showWithdrawColumn,
  // Redux Stores
  app,
  user
}) => {
  // Unpack row and make sure that props are always valid before passing in
  const {
    certtypeid: certTypeId,
    companyname: companyName,
    id: modelId,
    name: modelName,
    primarylabids: primaryLabIds,
    requeststatusid: requestStatusId,
    requesttypeid: requestTypeId,
    vendorname: vendorName,
    vendorproductname: vendorProductName,
    withdrawnat: withdrawnAt,
    withdrawreason: withdrawReason,
  } = rowData;

  const address = concatStr(formatAddress(rowData));
  const addressContent = mapSiteAddress(rowData);
  const companyTitle = companyName || vendorName;
  const modelTitle = modelName || vendorProductName;

  const siteNameRendersAsLink = () => {
    if (user.isStaff) return true;
    if ((user.isSupplier || user.isVendor) && companyTitle === user.company)
      return true;
    if (user.isLab && _.includes(primaryLabIds, user.companyid)) return true;

    return false;
  };

  const requestCertType = getCertTypeFromCertTypeId({
    certTypeId: certTypeId,
    certTypeIds: app.certTypes
  });
  const certAbbreviation = requestCertType ? requestCertType.name : "";

  return (
    <tr key={`battery-device-${modelId}`}>
      <td>{companyTitle}</td>
      {siteNameRendersAsLink() ? (
        <td>
          <Link to={`/battery/devices/audit/${modelId}`} className="glow-link">
            {modelTitle}
          </Link>
        </td>
      ) : (
        <td>{modelTitle}</td>
      )}
      <td>
        <OneLineText
          value={address}
          content={addressContent}
          title={companyName}
          titleId={`site-address-${modelId}`}
        />
      </td>
      <td>{certAbbreviation}</td>
      {showWithdrawColumn &&
        <td>
          <WithdrawButton
            deviceModelId={modelId}
            modalInfo={rowData}
            onSuccess={requestReloadTable}
            requestStatusId={requestStatusId}
            requestTypeId={requestTypeId}
            selectedCertType={certAbbreviation}
            shouldDelistOrRelistOnSuccess={false}
            withdrawnAt={withdrawnAt}
            withdrawReason={withdrawReason}
          />
        </td>
      }
    </tr>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps)(BatteryManufSiteRow);
