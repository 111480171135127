import { InputGroup } from "react-bootstrap";
import { getFirstDayOfYear } from "helpers/DateHelper";

export const datePickerOptions = {
  labelColSizeSm: 4,
  labelColSizeMd: 6,
  inputColSizeSm: 2,
  inputColSizeMd: 4,
  inputComponentClass: InputGroup,
};

export const initialState = {
  settings: {
    dateFrom: getFirstDayOfYear(),
    dateTo: new Date(),
  },
};
