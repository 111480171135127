import _ from "lodash";
import React from "react";
import { Bubble } from "ctia-ui";
import { Col, Row, Tab, Nav, NavItem } from "react-bootstrap";
import TestModule from "containers/request/OTA/TestResults/OTATestResultsItem";
import StatusBadge from "components/Badges/TestStatusBadge";

const OtaResultsWizard = ({ list }) => {
  if (!list || _.isEmpty(list))
    return (
      <Bubble type="warning" withIcon={true} icon="exclamation-sign">
        The selected request doesn't have any tests related to your company.
      </Bubble>
    );

  // no tabs, no lists - it's just 1 test for this lab
  if (_.size(list) === 1) return <TestModule testModule={list[0]} />;

  const renderTabs = () => {
    return list.map(tab => {
      return (
        <NavItem eventKey={`test-id-${tab.id}`} key={tab.id}>
          {tab.interface.name}&nbsp;
          <StatusBadge statusId={tab.requestteststatusid} />
        </NavItem>
      );
    });
  };

  const renderForms = () => {
    return list.map(testModule => {
      return (
        <Tab.Pane eventKey={`test-id-${testModule.id}`} key={testModule.id}>
          <TestModule testModule={testModule} />
        </Tab.Pane>
      );
    });
  };

  return (
    <Tab.Container
      id="enter-test-results"
      defaultActiveKey={`test-id-${list[0].id}`}
    >
      <Row className="clearfix">
        <Col xs={12} sm={12} md={12}>
          <Nav bsStyle="tabs">{renderTabs()}</Nav>
        </Col>
        <Col xs={12} sm={12} md={12} className="block-top-offset">
          <Tab.Content animation>{renderForms()}</Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default OtaResultsWizard;
