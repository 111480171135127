import FormElement from "components/FormElement";
import React from "react";
import { connect } from "react-redux";
import { concatStr } from "helpers/StringHelper";

const SubsystemTypeSelector = ({
  onChangeHandler,
  selectedSubsystem,
  user
}) => {
  const listOptions = [
    {
      id: 0,
      label: "All Recognized Subsystems",
      disabled: false
    },
    {
      id: user.companyid,
      label: concatStr(`${user.company} Recognized Subsystems`),
      disabled: false
    }
  ];

  return (
    <FormElement
      type="toggleRadioGroup"
      id="subsystemType"
      label="Select a Subsystem"
      value={selectedSubsystem}
      list={listOptions}
      onChange={onChangeHandler}
    />
  );
};

function mapPropsToState({ user }) {
  return { user };
}

export default connect(mapPropsToState, {})(SubsystemTypeSelector);
