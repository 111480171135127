import React, { useState }  from "react";
import { connect } from "react-redux";
import { Badge, Card, Table, Col } from "ctia-react-bootstrap-v4";
import ReportRow from "containers/request/OTA/TestPlans/ApproveReports/OtaApproveReportRow";
import ReportDownloadAll from "containers/request/OTA/TestResults/OtaReportDownloadAll";
import TestStatusInfo from "containers/request/OTA/TestResults/OtaTestStatusInfo";
import EditButton from "components/form/buttons/EditButton";
import { Drawer } from "ctia-ui";
import EditTestResultForm from "containers/request/BLC/EditTestResultFormBLC";

const OtaApproveReport = ({ testModule, user, request }) => {

  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [lockDrawer, setLockDrawer] = useState(false);

  if (!testModule || !testModule.reports.length) return null;

  const {
    reports,
    lab,
    planversion,
    actualstartdate,
    actualenddate,
    reportedat
  } = testModule;

  const moduleInfo = {
    details: {
      planversion,
      actualstartdate,
      actualenddate,
      reportedat,
      reports
    },
    catlInfo: { name: lab.name }
  };

  const showEditLabResult =
  user.isStaff &&
  user.canEditRequest &&
  request.isApproved;


  const toggleEditDrawer = () => {
    if (!lockDrawer) setShowEditDrawer(!showEditDrawer);
  };

  const toggleLockDrawer = () => {
    setLockDrawer(!lockDrawer);
  };

  return (
    <div className="block-bottom-offset">
      <Card border="light">
        <Card.Body>
          <h3>
            <Badge>{testModule.interface.name}</Badge>
          </h3>
          <TestStatusInfo testModule={moduleInfo} />
          <h3 className="text-center">
            <Badge variant="info">Test Reports</Badge>
          </h3>
          <Col xs={6} className="text-right">
            {showEditLabResult && (
              <>
                <EditButton
                  size="small"
                  label="Edit"
                  action={() => toggleEditDrawer()}
                />
                <Drawer
                  position="bottom"
                  show={showEditDrawer}
                  title="Edit Test Results"
                  toggleDrawer={() => toggleEditDrawer()}
                  content={
                    <EditTestResultForm
                      testModule={testModule}
                      toggleDrawer={() => toggleEditDrawer()}
                      toggleLockDrawer={() => toggleLockDrawer()}
                    />
                  }
                  height="auto"
                />
              </>
            )}
          </Col>
          <Table responsive striped hover size="sm" className="ctia-table">
            <thead>
              <tr>
                <th>Status</th>
                <th>Document Title</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {reports.map(report => {
                return <ReportRow key={report.id} report={report} />;
              })}
            </tbody>
          </Table>
          <ReportDownloadAll
            show={reports.length > 1}
            testModule={testModule}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(OtaApproveReport);
