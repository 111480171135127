import React from "react";
import { connect } from "react-redux";
import { DeleteButton } from "ctia-ui";
import { removeTestPlan, deleteOtaTest } from "actions/ota";
// import { formatCurrency } from "helpers/StringHelper";
import { isExisted } from "helpers/RequestHelper";
import {
  renderTestStatusBadge,
  getTestPlanStatusId
} from "helpers/RequestTestsHelper";
import { Row, Col } from "ctia-react-bootstrap-v4";

const OTATestPlanListRow = ({
  testPlan,
  removeTestPlan,
  request,
  deleteOtaTest,
  app
}) => {
  if (!testPlan) return null;

  const { catlInfo, details, planInfo } = testPlan;

  const deleteHandler = () => {
    return isExisted(request)
      ? deleteOtaTest(testPlan)
      : removeTestPlan(testPlan);
  };

  const testStatusId = getTestPlanStatusId(details);

  const colMd = {
    status: app.lg ? 4 : 3,
    title: app.lg ? 8 : 9
  };

  return (
    <tr>
      <td>
        <Row>
          <Col xs={12} md={colMd.status}>
            {renderTestStatusBadge(testStatusId)}
          </Col>
          <Col xs={12} md={colMd.title}>
            {planInfo.title}
          </Col>
        </Row>
      </td>
      {/* <td>{formatCurrency(planInfo.costAmount)}</td> */}
      <td>{catlInfo.name}</td>
      <td>
        <DeleteButton size="xs" label="Remove" action={deleteHandler} />
      </td>
    </tr>
  );
};

function mapStateToProps({ app, request }) {
  return { app, request };
}

export default connect(mapStateToProps, {
  removeTestPlan,
  deleteOtaTest
})(OTATestPlanListRow);
