import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import BatteryDeviceTypeReadonly from "components/request/BatteryDeviceTypeReadonly";

const BatteryDeviceType = ({ request, editable }) => {
  return editable ? (
    <FormElement
      type="checkboxList"
      id="deviceTypes"
      label="Device Type"
      list={request.deviceTypes}
      editable={true}
      inline={false}
      selectAll={false}
      readonly={false}
    />
  ) : (
    <BatteryDeviceTypeReadonly />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(BatteryDeviceType);
