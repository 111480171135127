import moment from "moment";
import * as qs from "query-string";
import { formatDate } from "helpers/DateHelper";
import { requestStatuses } from "helpers/RequestHelper";
import { isResultPass } from "helpers/RequestTestsHelper";
import { getToggledWireless } from "helpers/Wireless";
import { getSelectedOperatorIds } from "helpers/Operators";
import { getRequestOwner, mergeExtPhone } from "helpers/UsersHelper";
import { formatZipCode } from "helpers/StringHelper";
import userTypesLegacy from "helpers/UserTypesLegacy";
import { CTIA_NOTIFICATION, OTA_NOTIFICATION } from "helpers/NotifyHelper";
import * as roles from "dictionaries/Roles";

export const makeSimpleArgs = argObj => {
  return {
    args: { ...argObj }
  };
};

export const getTicket = urlLocation => {
  const parsed = qs.parse(urlLocation.search);
  const { ticket } = parsed;
  return ticket;
};

export const prepareDocStatusArgs = newDocStatus => {
  return makeSimpleArgs({
    documentstatusid: newDocStatus,
    includeSoftDeletes: false
  });
};

export const prepareTestResultArgs = testModule => {
  const {
    actualstartdate,
    actualenddate,
    requesttestpassedstatusid,
    planversion
  } = testModule;

  const passed = isResultPass(requesttestpassedstatusid);

  // if no testing, should exclude dates
  const dates = {
    actualstartdate: passed ? formatDate(actualstartdate) : "",
    actualenddate: passed ? formatDate(actualenddate) : ""
  };

  return makeSimpleArgs({
    ...dates,
    planversion,
    requesttestpassedstatusid
  });
};

export const prepareOtaTestResultArgs = testModule => {
  const { actualstartdate, actualenddate, planversion } = testModule;

  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return makeSimpleArgs({
    ...dates,
    planversion,
    requesttestpassedstatusid: 3 // hardcoded since it's only 1 type (info-only)
  });
};

export const prepareDhrTestResultArgs = testModule => {
  const {
    actualstartdate,
    actualenddate,
    requesttestpassedstatusid,
    planversion
  } = testModule;

  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return makeSimpleArgs({
    ...dates,
    planversion,
    requesttestpassedstatusid
  });
};

export const preparePocInfoArgs = (pocInfo, user) => {
  const companyid = getRequestOwner(pocInfo, user);
  return makeSimpleArgs({
    firstname: pocInfo.pocFirstName,
    lastname: pocInfo.pocLastName,
    email: pocInfo.pocEmail,
    phone: mergeExtPhone(pocInfo.pocPhone, pocInfo.pocPhoneExt),
    companyid
  });
};

export const prepareBillingPocArgs = (pocInfo, user) => {
  const companyid = getRequestOwner(pocInfo, user);
  return makeSimpleArgs({
    firstname: pocInfo.billingPocFirstName,
    lastname: pocInfo.billingPocLastName,
    email: pocInfo.billingPocEmail,
    phone: mergeExtPhone(pocInfo.billingPocPhone, pocInfo.billingPocPhoneExt),
    companyid
  });
};

export const prepareBillingAddressArgs = (billing, user) => {
  const companyid = getRequestOwner(billing, user);
  const { billingAddressCountry, billingAddressZip } = billing;
  const zipcode = formatZipCode(billingAddressCountry, billingAddressZip);

  return makeSimpleArgs({
    name: billing.billingAddressName,
    countrycode: billingAddressCountry,
    state: billing.billingAddressState,
    zipcode,
    city: billing.billingAddressCity,
    address1: billing.billingAddressLine1,
    address2: billing.billingAddressLine2,
    order: billing.billingOrderNum,
    companyid
  });
};

export const prepareRequestArgs = (request, billingId, modelId) => {
  return makeSimpleArgs({
    requesttypeid: request.type,
    devicemodelid: modelId,
    primarylabid: request.cdmaLab,
    pocid: request.pocId,
    billingid: billingId,
    purchaseorder: request.billingOrderNum
  });
};

export const prepareEcoRequestArgs = (request, billingid) => {
  return makeSimpleArgs({
    requesttypeid: request.type,
    devicemodelid: request.parentModelId,
    primarylabid: request.cdmaLab,
    hwversion: request.hwVersion,
    swversion: request.swVersion,
    pocid: request.pocId,
    parentid: request.parentId,
    descriptionofchange: request.descriptionOfChange,
    billingid,
    purchaseorder: request.billingOrderNum
  });
};

export const prepareWithdrawnArgs = reason => {
  return makeSimpleArgs({
    requeststatusid: requestStatuses.requestWithdrawn,
    withdrawreason: reason
  });
};

export const prepareUnwithdrawnArgs = (isApproved = false) => {
  return makeSimpleArgs({
    requeststatusid: isApproved
      ? requestStatuses.approved
      : requestStatuses.pending
  });
};

export const prepareDelistArgs = () => {
  return makeSimpleArgs({
    delistedat: moment()
  });
};

export const prepareRelistArgs = () => {
  return makeSimpleArgs({
    delistedat: ""
  });
};

export const prepareDocumentApproveArgs = newDocStatus => {
  return makeSimpleArgs({
    documentstatusid: newDocStatus
  });
};

export const prepareRequestApproveArgs = newReqStatus => {
  return makeSimpleArgs({
    requeststatusid: newReqStatus
  });
};

export const prepareBillingArgs = request => {
  return makeSimpleArgs({
    addressid: Number(request.billingAddressId),
    pocid: Number(request.billingPocId)
  });
};

export const prepareOperatorsArgs = operators => {
  return makeSimpleArgs({
    devicemodeloperators: getSelectedOperatorIds(operators)
  });
};

export const setPaymentArgs = request => {
  const { payMethod, customPayMethod, payCheckNumber, payNote } = request;
  const paymentmethod =
    customPayMethod && String(customPayMethod).length
      ? String(customPayMethod)
      : payMethod;
  const checkNumber = payMethod === "Check" ? payCheckNumber || "" : "";

  return makeSimpleArgs({
    paymentmethod,
    paidat: formatDate(request.payDate),
    checknumber: checkNumber,
    paymentnote: payNote,
    createdat: formatDate(request.ivcDate)
  });
};

export const prepareDeviceModelArgs = request => {
  const {
    model,
    modelName,
    certCategory,
    wirelessToggled,
    existedModel,
    existedModelName
  } = request;

  const isCyberModel = String(modelName).length > 0;
  const modelnumber = isCyberModel ? modelName : existedModelName;
  const legacyModelId = isCyberModel ? 0 : existedModel;

  return {
    id: model.id,
    modelnumber,
    legacydevicemodelid: legacyModelId,
    certcategoryid: certCategory,
    devicemodelwirelesscapabilities: getToggledWireless(wirelessToggled)
  };
};

export const prepareHwSwArgs = request => {
  const { id, hwVersion, swVersion, descriptionOfChange } = request;
  return {
    id,
    hwVersion,
    swVersion,
    descriptionOfChange
  };
};

export const prepareOtaDeviceModelArgs = request => {
  const { existedModel, existedModelId, id, model, modelName } = request;

  return {
    id: id ? model.id : existedModelId,
    modelnumber: id ? modelName : existedModel
  };
};

export const prepareAuditResultArgs = testModule => {
  const { actualstartdate, actualenddate, requesttestpassedstatusid } =
    testModule;

  // Note: if no testing, still need to include dates (not as for Cyber/OTA)
  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return makeSimpleArgs({
    ...dates,
    requesttestpassedstatusid
  });
};

export const prepareVoltageCapacityArgs = request => {
  const [capacityrateid, capacity] = request.capacity;
  const [voltagerateid, voltage] = request.voltage;

  return { voltage, capacity, voltagerateid, capacityrateid };
};

export const prepareEditUserArgs = ({
  userType,
  firstName,
  lastName,
  userName,
  password,
  address1,
  address2,
  address3,
  country,
  state,
  city,
  zip,
  email,
  workPhone,
  workPhoneExt,
  mobilePhone,
  company,
  website,
  title,
  isEditForm,
  isLegacyOperator,
  ctiaNotifRights,
  ctiaNotifEmails,
  otaNotifRights,
  otaNotifEmails,
  labID
}) => {
  const editMode = isEditForm && isEditForm.value === "true";
  const countryid = country.value;
  const stateid = countryid === "US" || countryid === "CA" ? state.value : "";

  const args = {
    firstname: firstName.value,
    lastname: lastName.value,
    username: userName.value,
    address1: address1.value,
    address2: address2.value,
    address3: address3.value,
    countryid,
    stateid,
    city: city.value,
    zipcode: zip.value,
    email: email.value,
    workphone: mergeExtPhone(workPhone.value, workPhoneExt.value),
    mobilephone: mobilePhone.value,
    website: website.value,
    title: title.value,
    LegacyUserTypes: [
      {
        typeid: userTypesLegacy[userType.value].id
      }
    ],
    companyID: labID?.value ? labID.value : null
  };

  const uType = userType.value;

  const ptcrbNotifications = ctiaNotifRights
    ? [
        {
          groupid: 1,
          emailnotifyid: CTIA_NOTIFICATION, // Certification Notification
          mailrights: ctiaNotifRights.value,
          emailnotification: ctiaNotifEmails.value
        },
        {
          groupid: 1,
          emailnotifyid: OTA_NOTIFICATION, // OTA No Testing Notification
          mailrights: otaNotifRights.value,
          emailnotification: otaNotifEmails.value
        }
      ]
    : null;

  /* 
    Assign different values for different user types 
    @NOTE: CTIA Staff and Accounting along with Unassigned type not needed to provide a company id
  */
  if (uType === roles.DB_ASC) {
    args["servicecenterid"] = company.value;
  }

  if (uType === roles.DB_LAB) {
    args["labid"] = company.value;
  }

  if (uType === roles.DB_VENDOR || uType === roles.DB_SUPPLIER) {
    args["vendorid"] = company.value;
  }

  if (uType === roles.DB_OPERATOR) {
    args["operatorid"] = company.value;

    if (isLegacyOperator && isLegacyOperator.value === "true") {
      args["ptcrbUserDocGroupsEmailNotifications"] = ptcrbNotifications;
    }
  }

  if (uType === roles.DB_UNASSIGNED) {
    args["unassignedcompanyname"] = company.value;
  }

  if (uType === roles.DB_STAFF && editMode) {
    args["ptcrbUserDocGroupsEmailNotifications"] = ptcrbNotifications;
  }

  // include password at Add User mode
  if (!editMode) {
    args["password"] = password.value;
    args["confirmpassword"] = password.value; // @NOTE: we don't use it on UI, but BE has the field required
  }

  return args;
};

export const prepareNewUserArgs = ({
  userType,
  firstName,
  lastName,
  userName,
  password,
  address1,
  address2,
  address3,
  country,
  state,
  city,
  zip,
  email,
  workPhone,
  workPhoneExt,
  mobilePhone,
  company,
  website,
  title,
  isEditForm,
  isLegacyOperator,
  ctiaNotifRights,
  ctiaNotifEmails,
  otaNotifRights,
  otaNotifEmails
}) => {
  const editMode = isEditForm && isEditForm.value === "true";
  const countryid = country.value;
  const stateid = countryid === "US" || countryid === "CA" ? state.value : "";

  const args = {
    firstname: firstName.value,
    lastname: lastName.value,
    username: userName.value,
    address1: address1.value,
    address2: address2.value,
    address3: address3.value,
    countryid,
    stateid,
    city: city.value,
    zipcode: zip.value,
    email: email.value,
    workphone: mergeExtPhone(workPhone.value, workPhoneExt.value),
    mobilephone: mobilePhone.value,
    website: website.value,
    title: title.value,
    LegacyUserTypes: [
      {
        typeid: userTypesLegacy[userType.value].id
      }
    ]
  };

  const uType = userType.value;

  const ptcrbNotifications = ctiaNotifRights
    ? [
        {
          groupid: 1,
          emailnotifyid: CTIA_NOTIFICATION, // Certification Notification
          mailrights: ctiaNotifRights.value,
          emailnotification: ctiaNotifEmails.value
        },
        {
          groupid: 1,
          emailnotifyid: OTA_NOTIFICATION, // OTA No Testing Notification
          mailrights: otaNotifRights.value,
          emailnotification: otaNotifEmails.value
        }
      ]
    : null;

  /*
    Assign different values for different user types
    @NOTE: CTIA Staff and Accounting along with Unassigned type not needed to provide a company id
  */
  if (uType === roles.DB_ASC) {
    args["servicecenterid"] = company.value;
  }

  if (uType === roles.DB_LAB) {
    args["labid"] = company.value;
  }

  if (uType === roles.DB_VENDOR || uType === roles.DB_SUPPLIER) {
    args["vendorid"] = company.value;
  }

  if (uType === roles.DB_OPERATOR) {
    args["operatorid"] = company.value;

    if (isLegacyOperator && isLegacyOperator.value === "true") {
      args["ptcrbUserDocGroupsEmailNotifications"] = ptcrbNotifications;
    }
  }

  if (uType === roles.DB_UNASSIGNED) {
    args["unassignedcompanyname"] = company.value;
  }

  if (uType === roles.DB_STAFF && editMode) {
    args["ptcrbUserDocGroupsEmailNotifications"] = ptcrbNotifications;
  }

  // include password at Add User mode
  if (!editMode) {
    args["password"] = password.value;
    args["confirmpassword"] = password.value; // @NOTE: we don't use it on UI, but BE has the field required
  }

  return args;
};

export const setIvcDateArgs = request => {
  return makeSimpleArgs({
    createdat: formatDate(request.ivcDate)
  });
};

export const getPageArgs = (pageNum, pageSize) => {
  if (pageSize === 0) return { page: 0 };

  return {
    page: pageNum,
    perpage: pageSize
  };
};

export const getPageArgsAsQueryString = (pageNum, pageSize) => {
  if (pageSize === 0 || pageNum === 0) return "page=0";

  return `page=${pageNum}&perpage=${pageSize}`;
};
