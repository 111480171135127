import { connect } from "react-redux";
import React, { Component } from "react";
import { Row, Col, Tab } from "react-bootstrap";
import { Checklist } from "ctia-ui";
import Comments from "components/Comments/index";
import CollapsablePanels from "containers/request/RequestContent/CollapsablePanels";
import { renderTabs, renderTabsContent } from "helpers/RequestTabsHelper";
import { setRequestViewActiveTab } from "actions/app";

class VendorContent extends Component {
  componentDidMount() {
    const { defaultTab, setRequestViewActiveTab } = this.props;
    setRequestViewActiveTab(defaultTab);
  }

  componentWillUnmount() {
    // set as 'null'
    this.props.setRequestViewActiveTab();
  }

  handleTab = key => {
    this.props.setRequestViewActiveTab(key);
  };

  renderRightColumn = () => {
    return (
      <div className="sticky-block">
        <Col xs={12} sm={12} md={2}>
          <Checklist list={this.props.checkList} />
          <Comments />
        </Col>
      </div>
    );
  };

  renderDefaultView = () => {
    const { app, tabs } = this.props;
    const { activeTab } = app.requestView;
    return (
      <Tab.Container
        id="request-nav-tabs"
        defaultActiveKey={activeTab}
        onSelect={this.handleTab}
      >
        <Row className="clearfix">
          {renderTabs(tabs)}
          {renderTabsContent(tabs, activeTab)}
          {this.renderRightColumn()}
        </Row>
      </Tab.Container>
    );
  };

  render() {
    const { compactView, requestView } = this.props.app;

    // nothing to show, skip rendering
    if (!requestView.activeTab) return null;

    if (compactView === true) {
      return this.renderDefaultView();
    }

    return (
      <Row>
        <CollapsablePanels tabs={this.props.tabs} />
        {this.renderRightColumn()}
      </Row>
    );
  }
}

function mapStateToProps({ app }) {
  return { app };
}

export default connect(mapStateToProps, { setRequestViewActiveTab })(
  VendorContent
);
