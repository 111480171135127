// React/Redux imports
import React from "react";
import { connect } from "react-redux";

// Helper imports
import { getCategoryByAbbreviation } from "helpers/CertCategories";
import { formatDate } from "helpers/DateHelper";
import { getStatusTitle } from "helpers/RequestHelper";

// Component imports
import Portal from "components/Portal";
import DeviceHistoryDrawer from "./DeviceHistoryDrawer";

// UI imports
import "./style.css";

const CertifiedPendingReportTableRow = ({
  certificationType = "",
  dateCertified,
  dateSubmitted,
  formattedTransactionNumber,
  manufacturer,
  modelId,
  modelName,
  deviceType,
  requestNumber,
  requestStatus,
  requestType,
  showDateCertified = false,
  showRequestType = true,
  app,
}) => {
  /**
   * Component setup
   */
  const selectedCertification = getCategoryByAbbreviation(
    certificationType,
    app.certTypes
  );
  const selectedCertificationName =
    selectedCertification !== null ? selectedCertification.name : "";
  const client =
    selectedCertification !== null ? selectedCertification.client : null;

  const getRequestLink = () => {
    if (!selectedCertification || !client) return formattedTransactionNumber;

    let url;
    if (
      client === "battery" &&
      selectedCertificationName.toLowerCase() === "blc"
    ) {
      url = `/blc/admin/requests/${requestNumber}`;
    } else if (client === "battery") {
      url = `/battery/admin/requests/${selectedCertificationName}/${requestNumber}`;
    } else {
      url = `/${client}/admin/requests/${requestNumber}`;
    }

    return (
      <Portal
        changeCertProgram={client}
        to={url}
        tn={formattedTransactionNumber}
      />
    );
  };

  const getModelLink = () => {
    if (!selectedCertification || !client) return modelName;

    return (
      <DeviceHistoryDrawer
        certificationType={certificationType}
        modelId={modelId}
        modelName={modelName}
        requestNumber={requestNumber}
        requestStatusId={requestStatus}
      />
    );
  };

  return (
    <tr>
      <td>{getRequestLink()}</td>
      <td>{manufacturer}</td>
      <td className="LongText">{getModelLink()}</td>
      {selectedCertificationName === "BLC" && (
        <td>{deviceType}</td>
      )}
      <td>{formatDate(dateSubmitted)}</td>
      {showDateCertified && <td>{formatDate(dateCertified)}</td>}
      {showRequestType && <td>{requestType}</td>}
      <td>{getStatusTitle(requestStatus)}</td>
    </tr>
  );
};

function mapStateToProps({ app }) {
  return { app };
}

export default connect(mapStateToProps)(CertifiedPendingReportTableRow);
