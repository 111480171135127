import React from "react";
import { IconLabel } from "ctia-ui";
import { Link } from "react-router-dom";
import { Col } from "ctia-react-bootstrap-v4";

const CpCard = ({ card }) => {
  return (
    <Col lg={4} md={6} sm={12}>
      <div className={`ctia-jumbo logo-${card.id} text-center box-with-shadow`}>
        <div className="ctia-jumbo__title">
          <h3 className="jumbo-block-header">{card.title}</h3>
        </div>
        <div className="ctia-jumbo__descr">
          {card.actions.map(action => {
            return (
              <p key={`${card.id}-${action.icon}`}>
                <IconLabel
                  awesomeIcon={action.icon}
                  label={
                    <Link to={action.route} className="glow-link">
                      {action.title}
                    </Link>
                  }
                />
              </p>
            );
          })}
        </div>
      </div>
    </Col>
  );
};

export default CpCard;
