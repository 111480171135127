import React from "react";
import { connect } from "react-redux";
import SelectTestPlan from "containers/request/OTA/TestPlans/SelectTestPlan";
// import OTAFee from "containers/request/OTA/TestPlans/OTAFee";
import SelectCATL from "containers/request/OTA/TestPlans/SelectCATL";
import AddButton from "containers/request/OTA/TestPlans/TestPlanAdd";

const OTATestPlanSelector = ({ ota }) => {
  if (!ota) return null;

  return (
    <div>
      <SelectTestPlan />
      {/* <OTAFee /> */}
      <SelectCATL />
      <AddButton />
    </div>
  );
};

function mapStateToProps({ ota }) {
  return { ota };
}

export default connect(mapStateToProps)(OTATestPlanSelector);
