import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  clearSelectedNewUserRequest,
  setUserRequestFormValues
} from "actions/newUserRequest";

import { formatDate } from "helpers/DateHelper";

import UserRequestDispositions from "dictionaries/UserRequestDispositions";

import { IconLabel } from "ctia-ui";
import LinkableColumn from "components/Table/TableColumn/LinkableColumn";
import { putUserDataIntoFormatForReduxStore } from "containers/users/UserRequest/UserRequestForm/FormHelpers";

const NewUserRequestTableRow = ({
  onRowClick = () => {},
  rowData = {},
  rowIndex,
  showNeedsMoreInfo = false,
  // Redux
  companies,
  clearSelectedNewUserRequest,
  setUserRequestFormValues
}) => {
  const history = useHistory();

  const fullName = `${rowData.lastname}, ${rowData.firstname}`;
  const { company, dateSubmitted, email, program, status, title } = {
    company: rowData.company,
    dateSubmitted: formatDate(rowData.createdat),
    email: rowData.email,
    program: rowData.program,
    status: rowData.active,
    title: rowData.title
  };

  const userIsRequestedForMoreInfo =
    status === UserRequestDispositions.moreInfo;

  const onClickHandle = event => {
    clearSelectedNewUserRequest();

    const { formValues, userNameIsValid } = putUserDataIntoFormatForReduxStore({
      userData: rowData,
      companies
    });
    setUserRequestFormValues({ userNameIsValid, ...formValues });

    const userId = formValues.userId;
    history.push(`/admin/cp/users/newuserrequests/${userId}`);

    onRowClick(event);
  };

  return (
    <tr key={rowIndex} onClick={onClickHandle}>
      <LinkableColumn>{fullName}</LinkableColumn>
      <td>{title}</td>
      <td>{company}</td>
      <td>{email}</td>
      <td>{dateSubmitted}</td>
      <td>{program}</td>
      {showNeedsMoreInfo && (
        <td>
          {" "}
          {userIsRequestedForMoreInfo && <IconLabel awesomeIcon="comment" />}
        </td>
      )}
    </tr>
  );
};

function mapStateToProps({ companies }) {
  return { companies };
}

export default connect(mapStateToProps, {
  clearSelectedNewUserRequest,
  setUserRequestFormValues
})(NewUserRequestTableRow);
