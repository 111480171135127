import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Bubble } from "ctia-ui";
import PocAdd from "containers/request/Initial/Steps/Vendor/BillingPocAdd";
import PocSelector from "containers/request/Initial/Steps/Vendor/BillingPocSelector";
import AddressAdd from "containers/request/Initial/Steps/Vendor/BillingAddressAdd";
import AddressSelector from "containers/request/Initial/Steps/Vendor/BillingAddressSelector";
import BillingSubmit from "containers/request/Initial/Steps/Vendor/BillingSubmit";
import ReturnLink from "containers/request/Initial/Steps/Vendor/BillingReturnLink";
import PurchaseOrder from "containers/request/Initial/Steps/Vendor/BillingPurchaseOrder";
import { startPreloader, finishPreloader } from "actions/preloader";
import {
  cleanBillingPocInfo,
  cleanBillingAddressInfo,
  setValue
} from "actions/request";
import { fetchPocs } from "actions/requestCyber";
import { fetchCountries } from "actions/countries";
import { fetchBillingAddresses } from "actions/billing";
import { getBillingPermissions } from "helpers/BillingHelper";

class Billing extends Component {
  state = {
    isNewRequest: true,
    showPocSelector: true,
    showAddressSelector: true
  };

  pocReturnLinkTitle = "I want to select PoC from a list";
  addressReturnLinkTitle = "I want to select Billing Address from a list";

  fetchAddressList = () => {
    const { user, fetchBillingAddresses } = this.props;
    return fetchBillingAddresses(user.requestOwnerCompanyId);
  };

  fetchBillingData = () => {
    const {
      startPreloader,
      finishPreloader,
      fetchPocs,
      fetchCountries,
      user
    } = this.props;

    startPreloader();

    return fetchPocs(user.requestOwnerCompanyId)
      .then(this.fetchAddressList)
      .then(fetchCountries)
      .then(finishPreloader);
  };

  componentDidMount() {
    this.fetchBillingData();

    this.props.setValue("billingPocAdd", false);
    this.props.setValue("billingAddressAdd", false);

    // init component state
    const isNewRequest = _.isUndefined(this.props.request.id);
    this.setState({ isNewRequest });
  }

  // TODO: Fix
  // componentDidUpdate(prevProps) {
  //   // this.props ==> prevProps
  //   // nextProps ==> this.props
  //   const hasNoPocs = _.isEmpty(this.props.request.pocs);
  //   const hasNoAddresses = _.isEmpty(this.props.request.billingAddresses);

  //   // update state only one time
  //   if (hasNoPocs && prevProps.request.pocs) {
  //     const pocListExists = !_.isEmpty(prevProps.request.pocs);
  //     this.setState({ showPocSelector: pocListExists });
  //   }

  //   if (hasNoAddresses && _.isUndefined(prevProps.request.billingAddresses)) {
  //     const addressListExists = !_.isEmpty(prevProps.request.billingAddresses);
  //     this.setState({ showAddressSelector: addressListExists });
  //   }

  //   if (hasNoAddresses && prevProps.request.billingAddresses === false) {
  //     this.setState({ showAddressSelector: false });
  //   }

  //   if (hasNoAddresses && !_.isEmpty(prevProps.request.billingAddresses)) {
  //     this.setState({ showAddressSelector: true });
  //   }

  //   const { billingPocId, billingAddressId } = this.props.request;

  //   // fix for return to prev step issue (billing poc)
  //   if (
  //     _.isEmpty(String(billingPocId)) &&
  //     !_.isEmpty(String(prevProps.request.billingPocId)) &&
  //     this.state.showPocSelector === false
  //   ) {
  //     this.fetchBillingData().then(this.togglePocSelector(true));
  //   }

  //   // fix for return to prev step issue (billing address)
  //   if (
  //     _.isEmpty(String(billingAddressId)) &&
  //     !_.isEmpty(String(prevProps.request.billingAddressId)) &&
  //     this.state.showAddressSelector === false
  //   ) {
  //     this.fetchBillingData().then(this.toggleAddressSelector(true));
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    const hasNoPocs = _.isEmpty(this.props.request.pocs);
    const hasNoAddresses = _.isEmpty(this.props.request.billingAddresses);
    const hasLegacyAddresses =
      !_.isEmpty(this.props.request.billingCompanyName) &&
      !_.isEmpty(this.props.request.billingAddress);
    const showLegacyAddress =
      (hasNoAddresses && hasLegacyAddresses) ||
      (nextProps.request.isLegacy && !nextProps.request.billingCompanyId);

    // update state only one time
    if (hasNoPocs && nextProps.request.pocs) {
      const pocListExists = !_.isEmpty(nextProps.request.pocs);
      this.setState({ showPocSelector: pocListExists });
    }

    if (hasNoAddresses && _.isUndefined(nextProps.request.billingAddresses)) {
      const addressListExists = !_.isEmpty(nextProps.request.billingAddresses);
      this.setState({ showAddressSelector: addressListExists });
    }

    if (hasNoAddresses && nextProps.request.billingAddresses === false) {
      this.setState({ showAddressSelector: false });
    }

    if (
      hasNoAddresses &&
      (!_.isEmpty(nextProps.request.billingAddresses) || showLegacyAddress)
    ) {
      this.setState({ showAddressSelector: true });
    }

    const { billingPocAdd, billingAddressAdd } = this.props.request;

    // fix for return to prev step issue (billing poc)
    if (
      billingPocAdd &&
      !nextProps.request.billingPocAdd &&
      this.state.showPocSelector === false
    ) {
      this.fetchBillingData().then(this.togglePocSelector(true));
    }

    // fix for return to prev step issue (billing address)
    if (
      billingAddressAdd &&
      !nextProps.request.billingAddressAdd &&
      this.state.showAddressSelector === false
    ) {
      this.fetchBillingData().then(this.toggleAddressSelector(true));
    }
  }

  togglePocSelector = value => {
    this.setState({ showPocSelector: value });
  };

  toggleAddressSelector = value => {
    this.setState({ showAddressSelector: value });
  };

  validate() {
    return this.props.request.pocId > 0;
  }

  pocLinkHandler = () => {
    // clean POC data from request
    this.props.cleanBillingPocInfo(this.props.request.billingPocAdd);

    // toggle selector view and show POC form instead
    this.togglePocSelector(true);
  };

  addressLinkHandler = () => {
    // remove Billing Address from request
    this.props.cleanBillingAddressInfo(this.props.request.billingAddressAdd);

    // toggle selector view and show address form instead
    this.toggleAddressSelector(true);
  };

  render() {
    const { app, show, request, user } = this.props;

    if (!show) return null;

    const { showPocSelector, showAddressSelector, isNewRequest } = this.state;
    const showPocReturnLink =
      !showPocSelector && request.pocs && request.pocs.length > 0;
    const showAddressReturnLink =
      !showAddressSelector &&
      request.billingAddresses &&
      request.billingAddresses.length > 0;
    const isEditable = getBillingPermissions(app, request, user);

    return (
      <div>
        <Bubble show={true}>
          Please select the <strong>Billing Point of Contact (PoC)</strong> and{" "}
          <strong>Billing Address</strong> for this request.
        </Bubble>
        <Form horizontal>
          <ReturnLink
            show={showPocReturnLink}
            title={this.pocReturnLinkTitle}
            linkHandler={this.pocLinkHandler}
          />
          <PocAdd
            show={!showPocSelector}
            isEditable={isEditable}
            isNew={isNewRequest}
            toggleSelector={this.togglePocSelector}
          />
          <PocSelector
            show={showPocSelector}
            isEditable={isEditable}
            isNew={isNewRequest}
            toggleSelector={this.togglePocSelector}
          />
          <hr />
          <ReturnLink
            show={showAddressReturnLink}
            title={this.addressReturnLinkTitle}
            linkHandler={this.addressLinkHandler}
          />
          <AddressAdd
            show={!showAddressSelector}
            isEditable={isEditable}
            isNew={isNewRequest}
            toggleSelector={this.toggleAddressSelector}
          />
          <AddressSelector
            show={showAddressSelector}
            isEditable={isEditable}
            isNew={isNewRequest}
            toggleSelector={this.toggleAddressSelector}
          />
          <PurchaseOrder isEditable={isEditable} />
          <BillingSubmit />
        </Form>
      </div>
    );
  }
}

Billing.propTypes = {
  show: PropTypes.bool
};

Billing.defaultProps = {
  show: true
};

function mapStateToProps({ app, request, user }) {
  return { app, request, user };
}

export default connect(mapStateToProps, {
  startPreloader,
  finishPreloader,
  fetchPocs,
  fetchBillingAddresses,
  cleanBillingPocInfo,
  cleanBillingAddressInfo,
  setValue,
  fetchCountries
})(Billing);
