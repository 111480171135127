import React, { useState } from "react";
import { connect } from "react-redux";
import { IconLabel, LoaderButton } from "ctia-ui";
import { Modal } from "react-bootstrap";
import { Form } from "ctia-react-bootstrap-v4";
import { signAgreementASC, downloadAgreementASC } from "actions/asc";
import { getUserInfo } from "actions/user";
import { getCurrATLYear } from "helpers/DateHelper";

import "containers/request/LicenseAgreement/style.css";

const AgreementModalASC = ({
  show,
  user,
  getUserInfo,
  modalHandler,
  signAgreementASC,
  downloadAgreementASC
}) => {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);

  const currYear = getCurrATLYear();
  const isValid = () => {
    const isNameValid = name !== "";
    const isTitleValid = title !== "";

    return isNameValid && isTitleValid && accepted;
  };

  const cleanupModal = () => {
    setName("");
    setTitle("");
    setAccepted(false);
    setLoading(false);
    modalHandler();
  };

  const handleSubmit = () => {
    setLoading(true);
    signAgreementASC(user.companyid, name, title)
      .then(() => {
        getUserInfo();
        cleanupModal();
      })
      .catch(cleanupModal);
  };

  return (
    <Modal show={show} onHide={modalHandler} bsSize="large">
      <Modal.Header closeButton>
        <Modal.Title>
          <div>{user.company}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          Please review the{" "}
          <span
            className="glow-link cursor-pointer"
            onClick={() => downloadAgreementASC(user.companyid)}
          >
            ASC License and Service Agreement
          </span>{" "}
          then:
        </div>
        <div className="button-top-offset">
          <ol className="ctia-list">
            <li>Enter your name and title below</li>
            <li>Click the accept box below</li>
            <li>Click Submit</li>
          </ol>
        </div>
        <div>
          <i>
            I understand and acknowledge that my company agrees to the terms of
            the License and Service Agreement set forth in the link above that
            will become effective January 1, {currYear}.
          </i>
        </div>
        <hr />
        <Form className="ctia-form">
          <Form.Group controlId="atlFormName">
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your full name"
              onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="atlFormTitle">
            <Form.Label>Business Title:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your business title"
              onChange={e => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="atlFormCheckbox">
            <Form.Check
              type="checkbox"
              label="I accept the License and Service Agreement terms and conditions."
              onChange={() => setAccepted(!accepted)}
            />
          </Form.Group>
          <LoaderButton
            bsStyle="primary"
            bsSize="large"
            text={<IconLabel awesomeIcon="signature" label="Sign and Submit" />}
            onClick={handleSubmit}
            isLoading={loading}
            loadingText="Signing..."
            disabled={!isValid()}
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  getUserInfo,
  signAgreementASC,
  downloadAgreementASC
})(AgreementModalASC);
