import React, { useState } from "react";
import { connect } from "react-redux";
import { IconLabel, Drawer } from "ctia-ui";
import LabComments from "containers/ControlPanel/Payments/LabComments";
import { clearComments } from "actions/comments";

const ShowCommentsATL = ({ atl, catl, clearComments }) => {
  const [showComments, setShowComments] = useState(false);
  const toggleCommentsDrawer = () => {
    if (!showComments) clearComments();
    setShowComments(!showComments);
  };

  // how many comments
  const counter = atl[`ATLCommentsCount${catl.invoicingYear}`];

  return (
    <div>
      <IconLabel awesomeIcon="comments" />{" "}
      <span
        className="glow-link cursor-pointer"
        onClick={() => toggleCommentsDrawer()}
      >
        Comments {counter > 0 && `(${counter})`}
      </span>
      <Drawer
        position="right"
        show={showComments}
        title="View/Add Lab Comments"
        toggleDrawer={toggleCommentsDrawer}
        content={
          <LabComments
            companyId={atl.id}
            labName={atl.name}
            flagCode={atl.country}
          />
        }
        width="350"
      />
    </div>
  );
};

function mapStateToProps({ catl }) {
  return { catl };
}

export default connect(mapStateToProps, {
  clearComments
})(ShowCommentsATL);
