import { FETCH_BOOKS } from 'actions/types';

export default function(state = null, action) {
  switch (action.type) {
    case FETCH_BOOKS:
      return { ...state, books: action.payload.allBooks };
    default:
      return state;
  }
}
