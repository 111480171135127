import React from "react";
import RequestContentPRR from "containers/request/battery/RequestContent/RequestContentPRR";
import LabContentPRR from "containers/request/battery/RequestContent/RequestContentLabPRR";
import roles from "dictionaries/UserRoles";

const ContentBatteryPRR = ({ role, focusLab }) => {
  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.supplier:
    case roles.accountant:
    case roles.operator:
      return <RequestContentPRR focusLab={focusLab} />;
    case roles.lab:
      return <LabContentPRR />;
    default:
      return null;
  }
};

export default ContentBatteryPRR;
