import React from 'react';
import { Panel } from 'react-bootstrap';
import { ctiaSupportEmail } from 'helpers/Emails';

const ErrorMsg = ({ error, history }) => {
  if (!error) return null;

  const { statusText, statusCode, url, responseText } = error;
  const style = { fontSize: 12 };

  const Header = () => {
    return (
      <b>
        Please,{' '}
        <a href={`mailto:${ctiaSupportEmail}`}>contact website administrator</a>{' '}
        and provide next information:
      </b>
    );
  };

  const { location } = history;
  const { pathname = '/', search = '' } = location;

  return (
    <div>
      <h3>Something went wrong</h3>
      <Panel header={<Header />} bsStyle="danger">
        <p style={style}>
          <b>Status:</b> {`${statusCode} (${statusText})`}
        </p>
        <p style={style}>
          <b>Page URL:</b> {`${pathname}${search}`}
        </p>
        <p style={style}>
          <b>Response URL:</b> <a href={url}>{url}</a>
        </p>
        <p style={style}>
          <b>Response text:</b>
        </p>
        <pre style={style}>{responseText}</pre>
        {/* <iframe srcDoc={<div style={{ width: '90%' }} dangerouslySetInnerHTML={{__html: responseText}} />} /> */}
        {/* <iframe srcDoc={responseText} /> */}
      </Panel>
    </div>
  );
};

export default ErrorMsg;
