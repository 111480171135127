import _ from "lodash";

import React, { useState } from "react";
import { connect } from "react-redux";

import { updateNewUserRequest } from "actions/newUserRequest";
import { showNotice } from "actions/notifier";

import UserRequestDispositions
  from "dictionaries/UserRequestDispositions";
import { notifyMessages } from "helpers/NotifyHelper";
import {
  formatErrorsForDisplay,
  prepareFormForUpdateUserRequest,
} from "containers/users/UserRequest/UserRequestForm/FormHelpers";

import { IconLabel, LoaderButton } from "ctia-ui";

const NewUserRequestSaveButton = ({
  // Props
  formIsValid = false,
  onError = () => {},
  onSubmit = () => {},
  onUserUpdated = () => {},
  // Redux Stores
  companies,
  newUserRequest,
  // Redux Actions
  showNotice,
  updateNewUserRequest
}) => {
  const [
    waitingForSubmissionResponse,
    setWaitingForSubmissionResponse
  ] = useState(false);

  const { formValues } = newUserRequest.selectedUserRequest;
  if (_.isUndefined(formValues)) return null;

  const handleSubmit = () => {
    onSubmit();
    setWaitingForSubmissionResponse(true);

    // Handle submit
    const { disposition, program, updateFields, userId } =
      prepareFormForUpdateUserRequest({ companies, formValues });

    updateNewUserRequest({ updateFields, userId, program })
    .then(() => {
      let notificationMessage = "";

      if (disposition === UserRequestDispositions.moreInfo)
        notificationMessage = notifyMessages.userRequestMoreInfo;

      else if (disposition === UserRequestDispositions.rejected)
        notificationMessage = notifyMessages.userRequestRejected;

      showNotice(notificationMessage);
      setWaitingForSubmissionResponse(false);
      onUserUpdated(true);
    })
    .catch(error => {
      const errorMessage = formatErrorsForDisplay(error);
      showNotice(errorMessage);
      setWaitingForSubmissionResponse(false);
      onError(errorMessage.text);
    });
  };

  const canSubmit = !waitingForSubmissionResponse && formIsValid;

  return (
    <LoaderButton
      bsStyle="success"
      disabled={!canSubmit}
      isLoading={waitingForSubmissionResponse}
      onClick={handleSubmit}
      style={{ marginBottom: 15 }}
      text={<IconLabel label="Save" awesomeIcon="check" />}
    />
  );
};

function mapPropsToState({ newUserRequest }) {
  return { newUserRequest };
}

export default connect(mapPropsToState, {
  showNotice,
  updateNewUserRequest
})(NewUserRequestSaveButton);