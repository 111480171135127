import React from "react";
import { connect } from "react-redux";
import RequestVoltage from "components/request/RequestVoltage";

const BatteryVoltageCapacity = ({
  request,
  user,
  visibleCapacity = true,
  visibleVoltage = true
}) => {
  if (!visibleCapacity && !visibleVoltage) return null;

  return (
    <>
      {visibleVoltage && (
        <RequestVoltage
          fieldId="voltage"
          fieldLabel="Voltage (V)"
          shouldBeNumber={!request.isLegacy}
          editable={user.canEditVoltageCapacity && user.canEditDeviceInfo}
        />
      )}
      {visibleCapacity && (
        <RequestVoltage
          fieldId="capacity"
          fieldLabel="Capacity (mAh)"
          shouldBeNumber={!request.isLegacy}
          editable={user.canEditVoltageCapacity && user.canEditDeviceInfo}
        />
      )}
    </>
  );
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(BatteryVoltageCapacity);
