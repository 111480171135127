import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import { send, messageGlyphs } from 'helpers/NotifyHelper';
import { hideNotice } from 'actions/notifier';

class Notifier extends Component {
  componentWillUpdate(nextProps) {
    // show notify message
    const currNotifier = this.props.notifier;
    const nextNotifier = nextProps.notifier;

    if (currNotifier !== nextNotifier && nextNotifier.show) {
      const { message } = nextNotifier;
      const { type, text } = message;
      const msg = this.renderMessage(type, text);

      send(msg, type);

      this.props.hideNotice();
    }
  }

  renderHelper = (text, icon) => {
    return (
      <span>
        <Glyphicon glyph={icon} className="icon-right-offset" /> {text}
      </span>
    );
  };

  renderMessage = (type, text) => {
    switch (type) {
      case 'success':
      case 'error':
      case 'warning':
        return this.renderHelper(text, messageGlyphs[type]);
      default:
        return text;
    }
  };

  render() {
    return null;
  }
}

function mapStateToProps({ notifier }) {
  return { notifier };
}

export default connect(
  mapStateToProps,
  { hideNotice }
)(Notifier);
