import React from "react";
import { FormControl } from "react-bootstrap";
import PropTypes from "prop-types";

const Select = ({
  value,
  onChange,
  editable,
  options,
  multiple,
  disabledOptionLabel
}) => {
  const values = options.map((type, i) => {
    const styleRule = type.disabled ? "option-disabled" : "";
    const renderValue = () => {
      if (type.disabled) {
        return `[${disabledOptionLabel}]: ${type.title || type.linkTitle}`;
      }

      return type.title || type.linkTitle;
    };

    return (
      <option
        value={type.value}
        key={i}
        disabled={type.disabled}
        className={styleRule}
      >
        {renderValue()}
      </option>
    );
  });

  return (
    <FormControl
      value={value}
      onChange={onChange}
      disabled={!editable}
      componentClass="select"
      multiple={multiple}
    >
      {values}
    </FormControl>
  );
};

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  editable: PropTypes.bool
};

Select.defaultProps = {
  multiple: false,
  disabledOptionLabel: "in use"
};

export default Select;
