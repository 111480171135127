import React from "react";
import RequestContentSAR from "containers/request/battery/RequestContent/RequestContentSAR";
import LabContentSAR from "containers/request/battery/RequestContent/RequestContentLabSAR";
import roles from "dictionaries/UserRoles";

const ContentBatterySAR = ({ role, focusLab }) => {

  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.supplier:
    case roles.accountant:
    case roles.operator:
      return <RequestContentSAR focusLab={focusLab}/>;
    case roles.lab:
      return <LabContentSAR />;
    default:
      return null;
  }
};

export default ContentBatterySAR;
