import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Badge } from "ctia-react-bootstrap-v4";
import { Table, FormGroup, FormControl } from "react-bootstrap";
import { DeleteButton, DownloadButton, IconLabel } from "ctia-ui";
import InputFile from "components/form/InputFile";
import UploadInstructions from "components/UploadInstructions";
import { handleDocFileChange, handleDocTitleChange, returnToEditRequest } from "actions/request";
import { fetchTestReportsBLC } from "actions/battery/blcRequest";
import { deleteTestReport, mustBeExcelFileMessage, mustBeWordPDFFileMessage } from "actions/uploads";
import { hasReportDocuments, countReports } from "helpers/RequestTestsHelper";
import { hasExcelFormat, hasWordPDFFormat } from "helpers/UploadsHelper";
import { cutFileExtension } from "helpers/StringHelper";

const actionsColStyle = { width: 300 };

class LabResultUploadBLC extends Component {

  onDelete = (testId, slot) => {
    const { deleteTestReport, fetchTestReportsBLC, request } = this.props;

    return deleteTestReport(testId, slot).then(() =>
      fetchTestReportsBLC({ id: testId }).then((res) => {
        if(res.data.data === false)
          this.props.fetchHandler(request.id)
      }
  ));
  };

  onSelectFile = (index, event) => {
    const { handleDocFileChange, handleDocTitleChange, request } = this.props;

    // Slots 1 and 2 are mandatory and allows to upload Excel files only
    if (index < 2 ) {
      const userFile = event.target.files[0];

      if (!hasExcelFormat(userFile) || userFile.size < 4700) {
        return this.props.mustBeExcelFileMessage();
      }
    } else if (index === 2) {
      const userFile = event.target.files[0];

      if (!hasWordPDFFormat(userFile)) {
        return this.props.mustBeWordPDFFileMessage();
      }
    } else {
      // apply file title change for other slots
      const currentTitle = String(request.testResults[index].title);
      if (_.isEmpty(currentTitle)) {
        // set file description as file name by default (w/o file extension)
        const fileName = cutFileExtension(event.target.files[0].name);

        // create event for changing description action manually
        const titleChangeEvent = {
          id: "testResults",
          target: { value: fileName }
        };

        handleDocTitleChange(index, request.testResults, titleChangeEvent);
      }
    }

    // handle file change action
    handleDocFileChange(index, request.testResults, event);
  };

  render() {
    const { show, request, downloadHandler, handleDocTitleChange } = this.props;
    if (!show) return null;

    const results = request.testResults;
    const hasReports = hasReportDocuments(results);
    const reportsCount = countReports(results);

    return (
      <div>
        <h3>Upload Results</h3>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>Document Title</th>
              <th>Select Document</th>
              {hasReports && <th style={actionsColStyle}>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {results.map((item, i) => {
              const renderTitle = () => {
                if (item.slot > 3) {
                  return (
                    <FormControl
                      type="text"
                      value={item.title}
                      onChange={handleDocTitleChange.bind(this, i, results)}
                    />
                  );
                }

                return (
                  <FormControl.Static className="dark-teal bold form-control-static">
                    {item.title}
                  </FormControl.Static>
                );
              };
              return (
                <tr key={i}>
                  <td>
                    <FormGroup controlId="testResults">
                      {renderTitle()}
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup controlId="testResults">
                      <InputFile
                        id={`report${i}`}
                        name="testResults"
                        onChange={this.onSelectFile.bind(this, i)}
                        file={item.file}
                        disabled={false}
                      />
                      {(item.slot < 3) && (
                        <Badge variant="ctia-green">
                          <IconLabel
                            awesomeIcon="file-excel"
                            label="Only .xls or .xlsx files allowed"
                          />
                        </Badge>
                      )}
                      {(item.slot === 3) && (
                        <Badge variant="ctia-blue">
                          <IconLabel
                            awesomeIcon="file-pdf"
                            label="Only .pdf, .doc, or .docx files allowed"
                          />
                        </Badge>
                      )}
                    </FormGroup>
                  </td>
                  {hasReports && (
                    <td>
                      {item.docId > 0 && (
                        <DeleteButton
                          size="small"
                          action={() =>
                            this.onDelete(item.requesttestid, item.slot)
                          }
                          customClassName="button-right-offset"
                        />
                      )}
                      {item.docId > 0 && (
                        <DownloadButton
                          size="small"
                          action={() => downloadHandler(item, request)}
                        />
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <UploadInstructions />
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  handleDocFileChange,
  handleDocTitleChange,
  deleteTestReport,
  fetchTestReportsBLC,
  mustBeExcelFileMessage,
  mustBeWordPDFFileMessage
})(LabResultUploadBLC);
