// React/Redux imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchCertifiedDeviceVersions } from "actions/battery/batteryRequests";

// Helper imports
import { types } from "helpers/BatteryCertHelper";

/// Component imports
import BatteryModelDetailsBSC from "containers/Devices/Battery/BatteryDeviceDetails/BatteryModelDetailsTables/BatteryModelDetailsBSC/BatteryModelDetailsBSC";
import BatteryModelDetailsSAR from "containers/Devices/Battery/BatteryDeviceDetails/BatteryModelDetailsTables/BatteryModelDetailsSAR/BatteryModelDetailsSAR";
import BatteryModelDetailsOtherCerts from "containers/Devices/Battery/BatteryDeviceDetails/BatteryModelDetailsTables/BatteryModelDetailsOtherCerts/BatteryModelDetailsOtherCerts";
import { InlinePreloader } from "ctia-ui";

const BatteryDeviceHistory = ({
  certificationType,
  modelId,
  requestStatusId,
  fetchCertifiedDeviceVersions,
  model
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCertifiedDeviceVersions(
      modelId,
      [certificationType],
      [requestStatusId],
      false
    ).then(() => setLoading(false));
  }, [
    fetchCertifiedDeviceVersions,
    certificationType,
    modelId,
    requestStatusId
  ]);

  if (loading) return <InlinePreloader />;

  if (!model || !model.versions) return <span>No Information</span>;

  switch (certificationType) {
    case types.BSC:
      return <BatteryModelDetailsBSC />;
    case types.SAR:
      return <BatteryModelDetailsSAR certType={certificationType} />;
    default:
      return <BatteryModelDetailsOtherCerts certType={certificationType} />;
  }
};

function mapStateToProps({ model }) {
  return { model };
}

export default connect(mapStateToProps, {
  fetchCertifiedDeviceVersions
})(BatteryDeviceHistory);
