import { restClientAdmin } from "libs/restClient";
import * as actions from "actions/types";
import * as call from "helpers/ActionHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { formatDateRangeForFileExport, toAPIString } from "helpers/DateHelper";
import { makeSimpleArgs, getPageArgsAsQueryString } from "helpers/ArgsHelper";
import { fileDownload } from "actions/uploads";

export const updateBudgetLimits = budgetLimitInfo => async dispatch => {
  try {
    const url = `/reports/budgets/1`;
    const body = makeSimpleArgs(budgetLimitInfo);
    const response1 = await restClientAdmin.put(url, body);

    if (response1.status !== 200) {
      throw new Error("Unable to update budget limits.");
    }

    dispatch(call.showNotice(notifyMessages.updatedBudgetLimitsSuccesfully));

    const response2 = await restClientAdmin.get("/reports/budgets");

    if (response2.status !== 200) {
      throw new Error(
        "Unable to retrieve updated budget limits. Please reload page."
      );
    }

    dispatch({
      type: actions.FETCH_BUDGET_LIMITS,
      payload: response2.data.data[0]
    });

    return response2;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateBudgetLimits));

    return Promise.reject(e);
  }
};

export const fetchBudgetLimits = () => async dispatch => {
  try {
    const response = await restClientAdmin.get("/reports/budgets");

    dispatch({
      type: actions.FETCH_BUDGET_LIMITS,
      payload: response.data.data[0]
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function switchView(certType, viewType) {
  return {
    type: actions.SWITCH_BUDGET_DETAILS_VIEW,
    payload: {
      certType,
      viewType
    }
  };
}

export function setBudgetSetting(id, value) {
  return {
    type: actions.SET_BUDGET_SETTING,
    payload: {
      id,
      value
    }
  };
}

export function setBudgetLimit(id, value) {
  return {
    type: actions.SET_BUDGET_LIMIT,
    payload: {
      id,
      value
    }
  };
}

export function downloadBudgetReport(
  certTypeIds = [25, 26],
  dateFrom,
  dateTo,
  status,
  download = false,
  order = ""
) {
  const ids = certTypeIds.toString();
  const dateFromM = toAPIString(dateFrom);
  const dateToM = toAPIString(dateTo);
  const url = `/reports/budget/details?certtypeids=${ids}&startat=${dateFromM}&endat=${dateToM}&status=${status}&page=0&order=${order}&asExcel=${download}`;

  const dates = formatDateRangeForFileExport(dateFrom, dateTo);
  const fileName = `Budget_Report_${dates}.zip`;

  return fileDownload(fileName, url, "admin");
}

export const fetchDetails = (
  certTypeIds = [25, 26],
  dateFrom,
  dateTo,
  status,
  page = 1,
  perPage = 50,
  order = ""
) => async dispatch => {
  try {
    const ids = certTypeIds.toString();
    const dateFromM = toAPIString(dateFrom);
    const dateToM = toAPIString(dateTo);
    const pagerArgs = getPageArgsAsQueryString(page, perPage);
    const url = `/reports/budget/details?certtypeids=${ids}&startat=${dateFromM}&endat=${dateToM}&status=${status}&${pagerArgs}&order=${order}`;

    const response = await restClientAdmin.get(url);
    const payload = response.data;

    dispatch({
      type: actions.FETCH_BUDGET_DETAILS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchSummary = (dateFrom, dateTo) => async dispatch => {
  try {
    const dateFromM = toAPIString(dateFrom);
    const dateToM = toAPIString(dateTo);
    const url = `/reports/budget/summary?startat=${dateFromM}&endat=${dateToM}`;

    const response = await restClientAdmin.get(url);
    const payload = response.data;

    dispatch({
      type: actions.FETCH_BUDGET_SUMMARY,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchRevenue = (
  certTypeIds,
  dateFrom,
  dateTo,
  status
) => async dispatch => {
  try {
    const ids = certTypeIds.toString();
    const dateFromM = toAPIString(dateFrom);
    const dateToM = toAPIString(dateTo);
    const url = `/reports/budget/revenue?certtypeids=${ids}&startat=${dateFromM}&endat=${dateToM}&status=${status}`;

    const response = await restClientAdmin.get(url);

    const payload = response.data;

    dispatch({
      type: actions.FETCH_BUDGET_REVENUE,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};
