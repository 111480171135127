import React from "react";
import { connect } from "react-redux";
import VendorContent from "containers/request/RequestContent/VendorContent";
import reqTypes from "dictionaries/ReqTypes";
import { batteryTabsSAR as tabs } from "helpers/RequestTabsHelper";
import {
  getChecklistSARInitial,
  getChecklistSARRenewal,
} from "helpers/ChecklistBatteryHelper";

const RequestContentSAR = ({ request, focusLab }) => {
  const checkListInitial = getChecklistSARInitial(request);
  const checkListRenewal = getChecklistSARRenewal(request);

  switch (request.type) {
    case reqTypes.initial:
      return (
        <VendorContent
          tabs={tabs}
          checkList={checkListInitial}
          defaultTab={focusLab ? "labInfo" : "manufSite"}
        />
      );
    case reqTypes.renewal:
      return (
        <VendorContent
          tabs={tabs}
          checkList={checkListRenewal}
          defaultTab={focusLab ? "labInfo" : "manufSite"}
        />
      );
    default:
      return null;
  }
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(RequestContentSAR);
