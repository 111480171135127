import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

const NewUserRequestNameAndTitle = ({
  newUserRequest
}) => {
  let title = "User's name cannot be empty";
  let nameIsEmpty = true;

  const { formValues } = newUserRequest.selectedUserRequest;
  if (!_.isEmpty(formValues)) {
    const { firstName, lastName } = formValues;
    const fullName = `${firstName} ${lastName}`;
    nameIsEmpty = _.isEqual(fullName, " ");
    if (!nameIsEmpty)
      title = fullName;
  }

  const { companyType } = formValues;

  return (
    <h1 style={{marginLeft: -15}}>
      <span className={nameIsEmpty ? "red" : ""}>{title}</span>
      &nbsp; - &nbsp;
      <span className={_.isUndefined(companyType) ? "red" : ""}>{companyType}</span>
    </h1>
  );
};

function mapStateToProps({ newUserRequest }) {
  return { newUserRequest };
}

export default connect(mapStateToProps)(NewUserRequestNameAndTitle);