import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import NoDocs from "components/NoDocs";
import { Table } from "react-bootstrap";
import { DownloadButton } from "ctia-ui";
import DocTitleReporter from "components/DocTitleReporter";
import DownloadAllButton from "components/form/buttons/DownloadAllButton";
import { documentDownload, documentDownloadAll } from "actions/requestCyber";

const tdStyle = { width: 150 };

const SupportDocsReadonly = ({
  docs,
  request,
  documentDownload,
  documentDownloadAll
}) => {
  if (!docs || !docs.length) return <NoDocs />;

  const filteredDocs = _.filter(docs, "docId");

  if (_.isEmpty(filteredDocs)) return <NoDocs />;

  return (
    <div>
      <Table striped condensed hover className="ctia-table">
        <thead>
          <tr>
            <th>Document Title</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredDocs.map(doc => {
            return (
              <tr key={doc.docId}>
                <td>
                  <DocTitleReporter doc={doc} />
                </td>
                <td style={tdStyle}>
                  <DownloadButton
                    size="xs"
                    action={() => documentDownload(doc, request)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <DownloadAllButton
        show={filteredDocs.length > 1}
        action={() => documentDownloadAll(request)}
      />
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  documentDownload,
  documentDownloadAll
})(SupportDocsReadonly);
