import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Bubble } from "ctia-ui";

const PocSelectorBubble = ({ stateHandler, user }) => {
  if (!stateHandler || !user) return null;

  const renderMessage = () => (
    <span>
      Please select the <strong>Point of Contact (PoC)</strong> for this
      request.
    </span>
  );

  if (user.canEditRequest) {
    return (
      <Bubble show={true}>
        {renderMessage()} or{" "}
        <Button bsSize="small" bsStyle="info" onClick={stateHandler}>
          Create new entry
        </Button>
      </Bubble>
    );
  }

  return <Bubble show={true}>{renderMessage()}</Bubble>;
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(PocSelectorBubble);
