import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { getNewestLicenseAgreementVersion } from "actions/agreement";

/*  Which version of the license agreement a model receives is based on the version ID.
    This is licenseagreementversionid in the devicemodels table. All legacy models (models that exist prior to this update)
    will have an id of 1. The default value for the column is currently 2, so all newly created models will receive the new agreement.

    To add a new agreement version, simply add it to the series of conditionals below, and increment the default value of
    licenseagreementversionid. A new entry should also be added to table licenseagreementversion to document the newly added agreement.
*/

const LicenseAgreementContentText = ({ signedDate, manufacturer, modelName, submitterTitle, modelNameTitle, batterySystem, version, getNewestLicenseAgreementVersion}) => {
    /**
     *  The only difference between License Agreement versions 2 and 3 is the location.
     **/

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchLicenseAgreementVersion() {
			const data = await getNewestLicenseAgreementVersion();
			setData(data.id);
			setLoading(false);
		}

		if(version == undefined || !version ) {
			fetchLicenseAgreementVersion();
		} else {
			setData(version);
			setLoading(false);
		}
	}, []);

    const renderModelName = () => (
        <div>
            <p><b>{modelName}</b></p>
            <p><b>(“{modelNameTitle}”)</b></p>
        </div>
    );

    const renderBatterySystem = () => {
        console.warn("@DEV: Please, check 'src/containers/request/LicenseAgreement/BatteryAgreement/Modals/LicenseContent.js' and replace dummyBattery with real data");

        const { battery, adapter } = batterySystem;

        return (
            <div>
                <p><b>Battery System:</b></p>
                <br />
                <p>Host: {modelName}</p>
                <p>&nbsp;</p>
                {battery.map((b, i) => {
                    return (
                        <p key = {{ i }}>
                            Battery:{" "}
                            {b.hasPendingRecognition ? "Pending Recognition" : b.linkTitle}
                        </p>
                    );
                })}
                {adapter.map((a, i) => {
                    return (
                        <p key = {{ i }}>
                            Adapter:{" "}
                            {parseInt(a.type) === 3 || !a.hasPendingRecognition
                            ? a.linkTitle
                            : "Pending Recognition"}
                        </p>
                    );
                })}
                <p>&nbsp;</p>
                <p><b>(“{modelNameTitle}”)</b></p>
                <p><i>[Remainder of the page intentionally left blank]</i></p>
                <p>&nbsp;</p>
            </div>
        )
    };

	if(loading) {
		return (<div></div>);
	} else {
		const location = data > 2 ? "State of Delaware" : "District of Columbia";

		if(signedDate === '<date certified>' && data !== 1) {
			return (
				<div>
					<div className="text-center">
						<b>CERTIFICATION LICENSE AGREEMENT</b>
						<p>THIS AGREEMENT is made at Washington, D.C.,</p>
						<p>Date: {signedDate}</p>
						<p>by and between</p>
						<p><b>CTIA Certification LLC</b></p>
						<p><b>(“CTIA Certification”)</b></p>
						<p>and</p>
						<p><b>{manufacturer}</b></p>
						<p><b>(“{submitterTitle}”)</b></p>
						<p>&nbsp;</p>
						{batterySystem ? renderBatterySystem() : renderModelName()}
						<hr />
						<p><b>RECITALS</b></p>
					</div>
					<p><b>WHEREAS</b>, CTIA-The Wireless Association (“CTIA”) is a non profit trade association that since 1991 has had a certification program to encourage conformance with industry standards for wireless devices, components, and other related equipment, including but not limited to the specified device listed above (“Specified Device”), through independent verification (“CTIA Certification Program”);</p>
					<p><b>WHEREAS</b>, to ensure such independent verification, the CTIA Certification Program has developed objective test plans and procedures and utilizes independent, industry-recognized testing laboratories (“Authorized Test Labs”) to review and evaluate the compliance of the Specified Device in accordance with industry standards and requirements;</p>
					<p><b>WHEREAS</b>, under the direction of its Board of Directors, CTIA has fully assigned to CTIA Certification all of its rights, duties, and obligations related to the CTIA Certification Program, and title and interest in the CTIA Certification Program Test Plans and Procedures (“Test Plans”);</p>
					<p><b>WHEREAS</b>, Submitter desires to enter into a voluntary relationship with CTIA Certification for the testing of the Specified Device manufactured by or for Submitter;</p>
					<p><b>WHEREAS</b>, Submitter will demonstrate to CTIA Certification’s satisfaction through an independent evaluation performed by a ATL, that the Specified Device will meet CTIA Certification’s requirements for certification as set forth in the applicable CTIA Certification Requirements Document and Test Plans (“Certification Requirements”); and upon receipt of written notification from CTIA Certification acknowledging that Submitter’s Specified Device has met the Certification Requirements, Submitter desires to pursue recognition for, and represent such certification;</p>
					<p><b>NOW THEREFORE</b>, in consideration of the covenants set forth below, the parties agree to the following:</p>
					<div className="text-center">
						<p><b>1. RIGHTS UPON CERTIFICATION</b></p>
					</div>
					<p><b>A.</b>    Submitter accepts and agrees to be bound by the terms of this Certification License Agreement (“Agreement”) via electronic acknowledgement, which will have the same force and effect as delivery of an original signature. The electronic signing and submitting of this Agreement constitutes Submitter’s understanding, acceptance, and agreement to the terms and conditions set forth in the Agreement. Upon successful completion of the Certification Requirements, which includes the upload of completed test results (“Test Report”) into the CTIA Certification Database (“Certification Database”) by the ATL performing the testing, CTIA Certification will deliver written notification to Submitter. The rights provided herein are expressly conditioned on the Specified Device satisfying the CTIA Certification Requirements.</p>
					<div className="text-center">
						<p><b>2. OWNERSHIP AND INTELLECTUAL PROPERTY</b></p>
					</div>
					<p><b>A.</b>	As between the parties, CTIA Certification owns and retains sole right, title and interest in and to the Test Plans, the Certification Requirements, the CTIA Certification Program, the CTIA Certification logos, brands, trademarks, or service marks (hereinafter, collectively “Certification Marks”); patents, the underlying technology used in connection with CTIA Certification programs; all CTIA Certification websites; and any other documents or information provided to Submitter under this Agreement (collectively the “Certification Intellectual Property”) are the exclusive property of CTIA Certification and/or its affiliates or partners. None of the Certification Intellectual Property provided to Submitter may be retransmitted without CTIA Certification’s express written consent. Submitter further acknowledges that nothing herein will be intended or construed as transferring any proprietary ownership interest in the Certification Intellectual Property and agrees not to take any action that would cause Submitter to acquire any rights in the Intellectual Property. Submitter will not acquire or retain any rights, title, or interest in or to the Certification Intellectual Property except for the limited and express license set forth in this section 2 and section 3 below.</p>
					<p><b>B.</b>	As between the parties, Submitter will own the Test Report, subject to CTIA Certification’s rights, title, and interests, to the Certification Intellectual Property, subject to a nonexclusive, perpetual, irrevocable, worldwide, royalty free, fully-paid license to CTIA Certification to store, maintain, and access the Test Report in the Certification Database.</p>
					<div className="text-center">
						<p><b>3. LICENSE GRANT</b></p>
					</div>
					<p><b>A.</b>	The rights granted herein are granted only to Submitter, and do not extend to Submitter’s shareholders, parents, affiliates, partners, or other related entities. The rights and obligations of this Agreement will run to the named parties, their successors in interest, authorized assigns, insurers, and reinsurers.</p>
					<p><b>B.</b>	Subject to the terms and conditions set forth herein, as long as the Specified Device complies with the Certification Requirements, Submitter will have a non-transferable, non-assignable, non-sublicensable, license to represent that the Specified Device meets the Certification Requirements (“License”). Submitter’s exercise of the License herein must strictly comply with the guidelines surrounding use of Certification Intellectual Property (“Certification Brand Guidelines”) and the terms set forth in this Agreement. CTIA Certification may at any time, and in its sole discretion, amend, modify, or supplement the Certification Requirements and the Certification Brand Guidelines, which can be found <a href='https://ctiacertification.org/what-we-do/#our-brands' target="_blank">here</a>.</p>
					<p><b>C.</b>	Nothing in this Agreement will be construed as granting Submitter a license to use any of the Certification Intellectual Property except as expressly set forth by the License in subsection 3(B). All other use of the License is strictly prohibited. For avoidance of doubt, CTIA Certification will provide Submitter with any Certification Marks that Submitter is authorized to use. Submitter will not modify or alter any Certification Marks without CTIA Certification’s prior written consent, and Submitter’s use of any Certification Marks is conditioned on its compliance with any applicable Certification Brand Guidelines. Any uses, modifications, or alteration of any Certification Marks will be the sole benefit of CTIA Certification and will accrue to the exclusive benefit of CTIA Certification.</p>
					<p><b>D.</b>	Submitter will not make unauthorized, incomplete, inaccurate, or misleading references to CTIA Certification, the Certification Requirements, the CTIA Certification Program, Test Plans, or a Test Report, whether or not made in connection with the Specified Device (“Unauthorized Reference”). If CTIA Certification knows, or has reason to know, of an Unauthorized Reference, Submitter will modify, cure, or discontinue such use immediately upon CTIA Certification’s written request.</p>
					<div className="text-center">
						<p><b>4. SUBMITTER OBLIGATIONS</b></p>
					</div>
					<p><b>A.</b>	The Specified Device will comply with all of the Certification Requirements.</p>
					<p><b>B.</b>	Submitter assumes sole and exclusive responsibility for any use of, or reference to, any Certification Intellectual Property.</p>
					<p><b>C.</b>	Submitter agrees that any tests or sampling of the Specified Device conducted by CTIA Certification or ATLs, is only a check as to whether the Specified Device complies with the Certification Requirements and in no way releases Submitter from responsibility for the Specified Device, including but not limited to any liabilities, claims, damages, or costs related to the Specified Device.</p>
					<div className="text-center">
						<p><b>5. CORRECTIVE ACTION</b></p>
					</div>
					<p><b>A.</b>	If at any point after the Effective Date, CTIA Certification becomes aware through any CTIA Certification tests, assessments, examinations, or otherwise that the Specified Device does not comply with, in whole or in part, the Certification Requirements (“Noncompliant Specified Device”), CTIA Certification retains the right to revoke certification of the Noncompliant Specified Device. Submitter agrees that upon CTIA Certification’s written request, it will use best efforts to cooperate with and assist CTIA Certification, or CTIA Certification’s authorized third-party agents and contractors, including but not limited to applicable ATLs, in ascertaining the facts needed to determine that the Specified Device complies with the Certification Requirements. CTIA Certification will notify Submitter, and Submitter will immediately and at its own expense, remove, delete, or destroy any references to CTIA Certification in connection with the Noncompliant Specified Device, including any covenants, obligations, representations, or warranties. Submitter will comply with the applicable laws and regulations governing a recall of the Specified Device.</p>
					<p><b>B.</b>	Submitter will notify CTIA Certification of its decision to rework, recall, or cure all units of a Noncompliant Specified Device, within a reasonable period of time from Submitter’s decision. All rights, interests, and permissions granted herein will immediately revert to CTIA Certification until such units are reworked and cured to CTIA Certification’s satisfaction and are no longer deemed a Noncompliant Specified Device, which CTIA Certification will acknowledge by written notification to Submitter.</p>
					<p><b>C.</b>	If allowable by law, upon prior written notice to Submitter, Submitter agrees that CTIA Certification may notify vendors, authorities, potential users, and others of an improper or unauthorized reference to CTIA Certification, provided that such notification is made to (1) protect CTIA Certification’s property, brand, reputation, or other assets; (2) a matter of public interest; or (3) otherwise necessary for the protection of CTIA Certification’s business or legal interests.</p>
					<div className="text-center">
						<p><b>6. TERM AND TERMINATION</b></p>
					</div>
					<p><b>A.</b>	This Agreement will be effective as of the day CTIA Certification delivers written notification to Submitter acknowledging that the Specified Device has satisfied the Certification Requirements (“Effective Date”). This Agreement will continue so long as the Specified Device meets the Certification Requirements, unless sooner terminated as set forth in this section (“Term”).</p>
					<p><b>B.</b>	If Submitter fails to comply with any of the terms and conditions of this Agreement, CTIA Certification may immediately suspend or terminate this Agreement by providing written notice to Submitter. If CTIA Certification chooses to suspend this Agreement, it will specify the time period for such suspension in its written notification, as well as any applicable cure period.</p>
					<p><b>C.</b>	Upon termination of this Agreement by CTIA Certification, the License granted in section 3 will terminate immediately and all rights, permissions, and interests will revert immediately back to CTIA Certification, and Submitter will cease all use of the Certification Intellectual Property, and will not reference CTIA Certification in connection with the Specified Device, including, but not limited to, any covenants, obligations, representations, or warranties.</p>
					<p><b>D.</b>	Termination of this Agreement by whatever means will not affect any liability of the parties existing as of the date of such termination, and will not relieve Submitter of its obligation to indemnify CTIA Certification hereunder.</p>
					<div className="text-center">
						<p><b>7. INDEMNIFICATION</b></p>
					</div>
					<p><b>A.</b>	Submitter will indemnify and hold harmless CTIA Certification, its officers, directors, employees, members, and agents, against any and all liability, loss, cost, damage, claims, suits, or expenses (including reasonable attorneys’ fees and costs) of any kind whatsoever, arising in any way from any negligent or willful acts or omission or breach of this Agreement by Submitter, its agents, employees, or from Submitter’s use, marketing, sale, or distribution of the Specified Device, including but not limited to any third-party claims allegedly caused by the performance or failure to perform of the Specified Device or false or misleading advertising or marketing in connection with the Specified Device. Submitter’s obligation to indemnify CTIA Certification will survive any termination or expiration of this Agreement. Conversely, CTIA Certification will indemnify and hold harmless Submitter, its officers, directors, employees, members, and agents, against any and all liability, loss, cost, damage, claims, suits or expenses (including reasonable attorneys’ fees and costs) of any kind whatsoever, arising from any reckless and willful acts or omissions by CTIA Certification or its agents or employees resulting in a breach of this Agreement.</p>
					<p><b>B.</b>	The indemnifying party will defend the indemnified party against any claim to which its indemnity relates, provided that: (i) the indemnified party provides the indemnifying party with notice of the claim promptly after the indemnified party becomes aware of such claim, and the notice will state the facts giving rise to such claim; (ii) the indemnifying party controls the defense or settlement of such claim, and the indemnifying party will not settle or otherwise dispose of such claim without the indemnified party’s prior written consent; (iii) the indemnified party cooperates with the indemnifying party in every reasonable way to facilitate the defense or settlement of such claim; and (iv) the indemnified party does not settle or otherwise dispose of such claim without the indemnifying party’s prior written consent, and such consent will not be unreasonably withheld or delayed.</p>
					<div className="text-center">
						<p><b>8. LIMITATION OF LIABILITY</b></p>
					</div>
					<p><b>A.</b>	SUBMITTER ACKNOWLEDGES AND AGREES THAT IN NO EVENT WILL CTIA CERTIFICATION BE LIABLE FOR ANY LOSS, DAMAGE, OR CLAIM, RELATED TO THE SPECIFIED DEVICE, WHETHER LIABILITY IS ASSERTED IN CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR OTHER LEGAL THEORY. IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY, OR TO ANY OTHER THIRD PARTY, FOR THE LOSS OF PROFITS, LOSS OF USE, LOSS OF PRODUCTION, LOSS OF GOODWILL, OR INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND.</p>
					<div className="text-center">
						<p><b>9. NO WARRANTY</b></p>
					</div>
					<p><b>A.</b>	Nothing in this Agreement will be interpreted or construed to constitute a guarantee, representation, or warranty by CTIA Certification in connection with the Specified Device. No representation by Submitter in connection with its use, marketing, sale, or distribution of the Specified Device will directly or indirectly, explicitly, or implicitly convey or suggest any such guarantee or warranty. CTIA Certification reserves the right to require Submitter to include a statement specifically disclaiming CTIA Certification’s guarantee, representation, or warranty in the materials associated with the Specified Device.</p>
					<div className="text-center">
						<p><b>10. INSURANCE</b></p>
					</div>
					<p><b>A.</b>	During the Term of this Agreement, Submitter will maintain liability insurance of at least two (2) million dollars in policy limits covering claims or suits related to the Specified Device, and will include CTIA Certification as an additional insured on the policy as to matters covered by this Agreement, and Submitter will furnish to CTIA Certification evidence of that insurance, upon CTIA Certification’s written request. Submitter must notify CTIA Certification of cancellation or non-renewal of applicable insurance policies within thirty (30) days of such cancellation or non-renewal. Failure to comply with the insurance provisions of this Agreement constitutes a material breach of Submitter’s material obligations under this Agreement, and may result in suspension or revocation of the license granted hereunder as well as termination of this Agreement.</p>
					<div className="text-center">
						<p><b>11. MISCELLANEOUS</b></p>
					</div>
					<p><b>A.</b> <u>Independent Contractor</u>. Each party acknowledges that the other party will perform this Agreement as an independent contractor, and this Agreement creates no agency, partnership, joint venture, or employment relationship between the parties. Each party acknowledges that it is not considered an affiliate or subsidiary of the other party, and is not entitled to any employee rights or benefits of the other party.</p>
					<p><b>B.</b> <u>Survival</u>. The sections 2, 3, 4, 5, 6, 7, 8, 9, and 11 will survive the termination or expiration of this Agreement. Those sections that by their nature are intended to survive the termination or expiration of this Agreement will survive the termination or expiration of this Agreement. Provisions of other sections, which, by their nature, must remain in effect beyond the termination or expiration of this Agreement, will also survive termination or expiration of this Agreement.</p>
					<p><b>C.</b> <u>Assignment and Delegation</u>. Submitter will not assign this Agreement or its rights, and will not delegate its duties and obligations under this Agreement to any other entity without the prior written consent of CTIA Certification, which will not be unreasonably withheld or denied. Furthermore, no work to be performed by Submitter hereunder will be subcontracted to or performed on behalf of Submitter by any third party, except with the prior written consent of CTIA Certification.</p>
					<p><b>D.</b> <u>Notices</u>. All legal notices required or permitted hereunder will be given in writing. Legal notices to Submitter  will be addressed to the point of contact provided to CTIA Certification. Legal notices to CTIA Certification will be addressed to ATTN: General Counsel Department, 1400 16th Street NW, Suite 600, Washington DC 20036. Notices will be effective upon receipt if delivered by certified or registered mail or one (1) day after mailing if delivered by nationally recognized overnight courier service. If a party refuses delivery of a notice, it will nevertheless be deemed to have received the notice for the purposes of this Agreement on the date of refusal. Either party may change its address for purposes hereof by written notice to the other in accordance with the provisions of this paragraph.</p>
					<p><b>E.</b> <u>Governing Law</u>. This Agreement will be governed and interpreted in accordance with the laws of the { location } without regard to principles of conflicts of laws. The parties agree to submit to the exclusive jurisdiction over all disputes hereunder in the appropriate federal or state courts in the { location } .</p>
					<p><b>F.</b> <u>Arbitration</u>. Unless unenforceable due to federal or state law, any controversy or claim, including, but not limited to, errors and omissions arising out of or relating to this Agreement or the breach thereof, will be settled by arbitration in accordance with the rules, then in effect, of the American Arbitration Association (“AAA”) or, if AAA will refuse to arbitrate any such controversy, by such arbitrators as the parties will mutually agree upon. Judgment upon any award rendered by the arbitrators may be entered in any court having jurisdiction thereof. Any arbitration will be held in the { location }. THE PARTIES AGREE THAT ANY DISPUTE WILL BE ARBITRATED ON AN INDIVIDUAL BASIS; CLASS, REPRESENTATIVE, AND PRIVATE ATTORNEY GENERAL ARBITRATIONS AND ACTIONS ARE NOT PERMITTED. A party may bring claims against the other party only in that party’s individual capacity. This arbitration provision does not permit and explicitly prohibits the arbitration of consolidated, class, or representative disputes of any form. In addition, although the arbitrator may award any relief that a court could award that is individualized to the claimant and would not affect other parties, no party may seek, nor may the arbitrator award, non-individualized relief that would affect other parties. Further, the arbitrator may not consolidate or join more than one person's claims unless all parties affirmatively agree in writing. If any of the prohibitions in the preceding paragraph is held to be unenforceable as to a particular claim, then that claim (and only that claim) must be severed from the arbitration and brought in court.</p>
					<p><b>G.</b> <u>Entire Agreement</u>. This Agreement constitutes the entire agreement between the parties concerning the subject matter hereof and supersedes all prior, contemporaneous agreements, and communications, whether oral or written, between the parties relating to the subject matter of this Agreement and all past courses of dealing or industry custom. This Agreement may be amended only with the specific written consent of both parties.</p>
					<p><b>H.</b> <u>Severability</u>. If any court of law determines that any clause in this Agreement is unenforceable or void, that clause will be deleted while the rest of this Agreement remains in force. Moreover, the parties agree to replace the invalid provisions with a substitute provision that will satisfy the intent of the parties.</p>
					<p><b>I.</b> <u>No Waiver</u>. The waiver by either party of any breach of any provision of this Agreement will not be construed to be either a waiver the party’s rights regarding any succeeding breach of any such provision or a waiver of the provision itself. Nor will any delay or omission on the part of a party to exercise or avail itself of any right, power, or privilege that it has, or may have hereunder, operate as a waiver of any right, power, or privilege by the party.</p>
				</div>
			);
		} else if(data === 1)
			return (
				<div>
					<div className="text-center">
						<p>THIS AGREEMENT is made at Washington, D.C.,</p>
						<p>Date: {signedDate}</p>
						<p>by and between</p>
						<p><b>CTIA Certification LLC</b></p>
						<p><b>(“CC LLC”)</b></p>
						<p>and</p>
						<p><b>{manufacturer}</b></p>
						<p><b>(“{submitterTitle}”)</b></p>
						<p>&nbsp;</p>
						{batterySystem ? renderBatterySystem() : renderModelName()}
						<hr />
						<p><b>RECITALS</b></p>
					</div>
					<p><b>WHEREAS</b>, CTIA-The Wireless Association (“CTIA”) is a non profit trade association that has been running the CTIA Certification Program since 1991 to encourage conformance with industry standards for wireless devices, components, and other related equipment, including but not limited to each specified device listed above <b>(“Specified Device”)</b>, through independent verification;</p>
					<p><b>WHEREAS</b>, to ensure such independent verification, the CTIA Certification Program has developed objective test plans and procedures and utilizes independent, industry-recognized testing laboratories <b>(“Authorized Test Labs”)</b> to review and evaluate the compliance of Specified Devices in accordance with industry standards and requirements;</p>
					<p><b>WHEREAS</b>, CTIA has fully assigned to CC LLC all of its rights, duties, and obligations related to the CTIA Certification Program, and title and interest in the Certification Program Test Plans and Procedures <b>(“Test Plans”)</b>;</p>
					<p><b>WHEREAS</b>, Submitter desires to enter into a voluntary relationship with CC LLC for the testing of each Specified Device manufactured by or for the Submitter;</p>
					<p><b>WHEREAS</b>, Submitter will demonstrate to CC LLC's satisfaction through an independent evaluation performed by a ATL that each Specified Device will meet CC LLC's requirements for certification as set forth in the applicable CC LLC Certification Program Management Document and CC LLC Test Plans <b>(“CC LLC Certification Requirements”)</b>;</p>
					<p><b>WHEREAS</b>, upon receipt of written notification from CC LLC acknowledging that Submitter’s Specified Device has met the CC LLC Certification Requirements, Submitter desires to pursue recognition for and represent such certification;</p>
					<p><b>NOW THEREFORE</b>, for these good and lawful reasons, and in consideration of the covenants set forth below, the parties agree to the following:</p>
					<div className="text-center">
						<p><b>SECTION ONE</b></p>
						<p><b>RIGHTS UPON CERTIFICATION</b></p>
					</div>
					<p>1.1 Submitter intends to sign and deliver this License Agreement (“Agreement”) via electronic acceptance, which shall have the same force and effect as delivery of an original signature. Upon successful completion of the CC LLC Certification Requirements, which includes the upload of completed test results to the CC LLC Test Plan for the Specified Device <b>(“Test Report”)</b> into CC LLC’s Certification Database <b>(“Database”)</b> by the ATL performing the testing, CC LLC will deliver written notification to Submitter.</p>
					<p>1.2 This Agreement shall be effective as of the day CC LLC delivers written notification to Submitter acknowledging that the Specified Device has satisfied the CC LLC Certification Requirements.</p>
					<p>1.3 The rights provided herein are expressly conditioned on the Specified Device satisfying the CC LLC Certification Requirements and will become effective only upon Submitter’s receipt of CC LLC’s written acknowledgement of certification.</p>
					<p>1.4 The electronic signing and submitting of this Agreement constitutes Submitter’s understanding, acceptance, and agreement to the terms and conditions set forth below.</p>
					<div className="text-center">
						<p><b>SECTION TWO</b></p>
						<p><b>OWNERSHIP AND INTELLECTUAL PROPERTY</b></p>
					</div>
					<p>2.1 As between the Parties, CC LLC owns and retains sole right, title and interest in and to the CC LLC Test Plans, the CC LLC Certification Requirements, the CC LLC Certification Program, and all intellectual property rights therein or thereto <b>(“CC LLC Owned Materials”)</b>. Submitter shall not acquire or retain any rights, title, or interest in or to the CC LLC Owned Materials except for the limited and expressed license set forth in this Section 2 and Section 3 below.</p>
					<p>2.2 As between the Parties, Submitter shall own the Test Report, subject to CC LLC’s rights, title, and interests, to the CC LLC Owned Materials, and provided that Submitter grants CC LLC an exclusive, perpetual, irrevocable, worldwide, royalty free, fully-paid license to store, maintain, and access the Test Report in the Database.</p>
					<div className="text-center">
						<p><b>SECTION THREE</b></p>
						<p><b>LICENSE GRANT</b></p>
					</div>
					<p>3.1 The rights granted herein are granted only to the Submitter, and do not extend to Submitter’s shareholders, parents, affiliates, partners, or other related entities. The rights and obligations of this Agreement shall run to the named parties, their successors in interest, authorized assigns, insurers, and reinsurers.</p>
					<p>3.2 Subject to the terms and conditions set forth herein, Submitter shall have an exclusive, non-transferable, non-assignable right for as long as the Specified Device complies with the CC LLC Certification Requirements, without the right to sublicense, to represent the Specified Device as meeting CC LLC’s Certification Requirements in Submitter’s non-consumer-facing documents, intranets, and other materials, whether in written, electronic, digital, graphic, or machine-readable form, or orally <b>(“License”)</b>. CC LLC will permit the internal or non-consumer-facing, commercial use of appropriate references to CC LLC’s Certification Requirements, CC LLC’s Test Plans, and the CC LLC Certification Program for the sole and limited purpose of Submitter representing that the Specified Device satisfies the CC LLC Certification Requirements. Submitter will not use the License for any other purpose.</p>
					<p>3.3 Nothing in this Agreement shall be construed as granting Submitter a license to use any of CC LLC’s logos, trademarks, service marks, copyrighted materials <b>(“CC LLC Marks”)</b> or patents related to the Specified Device, CC LLC’s Certification Requirements, CC LLC’s Test Plans, or the CC LLC Certification Program except as expressly set forth by the License described in Subsection 3.2. Submitter shall not modify or alter any CC LLC Marks without CC LLC’s prior written consent, and Submitter’s use of such marks is conditioned on its compliance with any applicable CC LLC usage guidelines. Any uses, modifications, or alteration of any CC LLC Marks shall be the sole benefit of CC LLC and shall accrue to the exclusive benefit of CC LLC.</p>
					<p>3.4 Submitter shall not make unauthorized, incomplete, inaccurate, or misleading references to CC LLC, CC LLC Certification Requirements, the CC LLC Certification Program, CC LLC Test Plans, or a CC LLC Test Report, whether or not made in connection with the Specified Device <b>(“Unauthorized CC LLC Reference”)</b>.</p>
					<p>3.5 If CC LLC knows, or has reason to know, of an Unauthorized CC LLC Reference, Submitter shall modify, cure, or discontinue such use immediately upon CC LLC’s written request.</p>
					<p>3.6 Submitter shall be entitled to represent the Specified Device as meeting CC LLC’s Certification Requirements pursuant to the terms set forth in this Agreement, as long as the Specified Device continues to meet CC LLC’s Certification Requirements, which CC LLC may amend, modify, or supplement at any time, at its sole discretion, provided that CC LLC gives Submitter prior notice of any material changes.</p>
					<div className="text-center">
						<p><b>SECTION FOUR</b></p>
						<p><b>SUBMITTER OBLIGATIONS</b></p>
					</div>
					<p>4.1 The Specified Device shall comply with all of the CC LLC Certification Requirements.</p>
					<p>4.2 Submitter assumes sole and exclusive responsibility for any use or reference to any CC LLC Owned Materials, intellectual property rights to the CC LLC Owned Materials, and the License, including, but not limited to, any references to CC LLC, or that could be reasonably interpreted to reference CC LLC, in connection with the Specified Device, CC LLC Certification Requirements, CC LLC Certification Program, and the CC LLC Test Plans.</p>
					<p>4.3 Submitter agrees that any tests or sampling of the Specified Device conducted by CC LLC or ATLs, is only a check as to whether the Specified Device complies with CC LLC's Certification Requirements and in no way releases Submitter from responsibility for the Specified Device, including but not limited to any liabilities, claims, damages, or costs related to the Specified Device.</p>
					<div className="text-center">
						<p><b>SECTION FIVE</b></p>
						<p><b>CORRECTIVE ACTION</b></p>
					</div>
					<p>5.1 If at any point after this Agreement becomes effective, CC LLC becomes aware through any CC LLC tests, assessments, examinations, or otherwise that the Specified Device does not comply with, in whole or in part, the CC LLC Certification Requirements <b>(“Noncompliant Specified Device”)</b>, CC LLC retains the right to revoke certification of the Noncompliant Specified Device. CC LLC shall notify the Submitter, and the Submitter shall immediately and at its own expense, remove, delete, or destroy any references to CC LLC in connection with the Noncompliant Specified Device, including any covenants, obligations, representations, or warranties. Submitter shall comply with the applicable laws and regulations governing a recall of the Specified Device.</p>
					<p>5.2 Submitter shall notify CC LLC of its decision to rework, recall, or cure all units of a Noncompliant Specified Device, within a reasonable period of time from Submitter’s decision. All rights, interests, and permissions granted herein shall immediately revert to CC LLC until such units are reworked and cured to CC LLC’s satisfaction and are no longer deemed noncompliant, which CC LLC will acknowledge by written notification to Submitter.</p>
					<p>5.3 Submitter agrees that upon CC LLC’s written request, it will use best efforts to cooperate with and assist CC LLC, or CC LLC’s authorized third-party agents and contractors, including but not limited to applicable ATLs, in ascertaining the facts needed to determine that the Specified Device complies with CC LLC's Certification Requirements.</p>
					<p>5.4 Submitter agrees that CC LLC may notify vendors, authorities, potential users, and others of an improper or unauthorized reference to CC LLC, provided that such notification is made to (1) protect CC LLC’s property, brand, reputation, or other assets; (2) a matter of public interest; or (3) otherwise necessary for the protection of CC LLC’s business or legal interests.</p>
					<div className="text-center">
						<p><b>SECTION SIX</b></p>
						<p><b>TERMINATION</b></p>
					</div>
					<p>6.1 This Agreement shall continue so long as the Specified Device meets the CC LLC Certification Requirements, unless sooner terminated as set forth in this Section.</p>
					<p>6.2 If Submitter fails to comply with any of the terms and conditions of this Agreement, CC LLC may immediately suspend or terminate this Agreement by providing written notice to Submitter. If CC LLC chooses to suspend this Agreement, it will specify the time period for such suspension in its written notification, as well as any applicable cure period.</p>
					<p>6.3 Upon termination of this Agreement by CC LLC, the license granted in Section 3 will terminate immediately and all rights, permissions, and interests shall revert immediately back to CC LLC, and Submitter shall cease all use of CC LLC Owned Materials, or any of CC LLC’s intellectual property rights to the Test Report or otherwise, and shall not reference CC LLC in connection with the Specified Device, including, but not limited to, any covenants, obligations, representations, or warranties.</p>
					<p>6.4 Termination of this Agreement by whatever means shall not affect any liability of the parties existing as of the date of such termination, and shall not relieve Submitter of its obligation to indemnify CC LLC hereunder.</p>
					<div className="text-center">
						<p><b>SECTION SEVEN</b></p>
						<p><b>INDEMNIFICATION</b></p>
					</div>
					<p>7.1 Submitter shall indemnify and hold harmless CC LLC, its officers, directors, employees, members, and agents, against any and all liability, loss, cost, damage, claims, suits, or expenses (including reasonable attorneys’ fees and costs) of any kind whatsoever, arising in any way from any negligent or willful acts or omission or breach of this Agreement by Submitter, its agents, employees, or from Submitter’s use, marketing, sale, or distribution of the Specified Device, including but not limited to any third-party claims allegedly caused by the performance or failure to perform of the Specified Device or false or misleading advertising or marketing in connection with the Specified Device. Submitter’s obligation to indemnify CC LLC shall survive any termination or expiration of this Agreement. Conversely, CC LLC shall indemnify and hold harmless Submitter, its officers, directors, employees, members, and agents, against any and all liability, loss, cost, damage, claims, suits or expenses (including reasonable attorneys’ fees and costs) of any kind whatsoever, arising from any reckless acts by CC LLC or its agents or employees.</p>
					<p>7.2 Submitter shall defend CC LLC against any claim to which its indemnity relates, provided that:</p>
					<ol type="i">
						<li>CC LLC provides Submitter with notice of the claim promptly after CC LLC becomes aware of such claim, and the notice shall state the facts giving rise to such claim;</li>
						<li>Submitter controls the defense or settlement of such claim, and Submitter shall not settle or otherwise dispose of such claim without CC LLC’s prior written consent;</li>
						<li>CC LLC cooperates with Submitter in every reasonable way to facilitate the defense or settlement of such claim; and</li>
						<li>CC LLC does not settle or otherwise dispose of such claim without Submitter’s prior written consent, and such consent shall not be unreasonably withheld or delayed.</li>
					</ol>
					<p>7.3 CC LLC shall defend Submitter against any claim to which its indemnity relates, provided that:</p>
					<ol type="i">
						<li>Submitter provides CC LLC with notice of the claim promptly after Submitter becomes aware of such claim, and the notice shall state the facts giving rise to such claim;</li>
						<li>CC LLC controls the defense or settlement of such claim, and CC LLC shall not settle or otherwise dispose of such claim without Submitter’s prior written consent;</li>
						<li>Submitter cooperates with CC LLC in every reasonable way to facilitate the defense or settlement of such claim; and</li>
						<li>Submitter does not settle or otherwise dispose of such claim without CC LLC’s prior written consent, and such consent shall not be unreasonably withheld or delayed.</li>
					</ol>
					<div className="text-center">
						<p><b>SECTION EIGHT</b></p>
						<p><b>LIMITATION OF LIABILITY</b></p>
					</div>
					<p>8.1 SUBMITTER ACKNOWLEDGES AND AGREES THAT IN NO EVENT SHALL CC LLC BE LIABLE FOR ANY LOSS, DAMAGE, OR CLAIM, RELATED TO THE SPECIFIED DEVICE, WHETHER LIABILITY IS ASSERTED IN CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR OTHER LEGAL THEORY. IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY, OR TO ANY OTHER THIRD PARTY, FOR THE LOSS OF PROFITS, LOSS OF USE, LOSS OF PRODUCTION, LOSS OF GOODWILL, OR INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND.</p>
					<div className="text-center">
						<p><b>SECTION NINE</b></p>
						<p><b>NO APPROVAL</b></p>
					</div>
					<p>9.1 Nothing in this Agreement shall be interpreted or construed to constitute a guarantee, representation, or warranty by CC LLC in connection with the Specified Device. No representation by Submitter in connection with its use, marketing, sale, or distribution of the Specified Device will directly or indirectly, explicitly, or implicitly convey or suggest any such guarantee or warranty. CC LLC reserves the right to require Submitter to include a statement disclaiming directly or indirectly CC LLC’s guarantee, representation, or warranty in the marketing and informational materials associated with the Specified Device.</p>
					<div className="text-center">
						<p><b>SECTION TEN</b></p>
						<p><b>INSURANCE</b></p>
					</div>
					<p>10.1	During the period of this Agreement, Submitter will maintain liability insurance of at least two million dollars in policy limits covering claims or suits related to the Specified Device, and will include CC LLC as an additional insured on the policy as to matters covered by this Agreement, and Submitter will furnish to CC LLC evidence of that insurance, upon CC LLC’s written request.</p>
					<p>10.2	Submitter must notify CC LLC of cancellation or non-renewal of applicable insurance policies within thirty days of such cancellation or non-renewal. Failure to comply with the insurance provisions of this Agreement constitutes a material breach of Submitter’s material obligations under this Agreement, and may result in suspension or revocation of the license granted hereunder as well as termination of this Agreement.</p>
					<div className="text-center">
						<p><b>SECTION ELEVEN</b></p>
						<p><b>MISCELLANEOUS</b></p>
					</div>
					<p>11.1	<b>Survival.</b> The sections 2, 3, 4, 5, 6, 7, 8, 9, and 11 shall survive the termination or expiration of this Agreement. Those sections that by their nature are intended to survive this Agreement shall survive the termination or expiration of this Agreement. Upon expiration or termination of this Agreement, any provisions herein that provide for its survival shall survive the termination or expiration of this Agreement for any reason. Provisions of other sections, which, by their nature, must remain in effect beyond the termination or expiration of this Agreement, shall also survive termination or expiration of this Agreement for any reason.</p>
					<p>11.2	<b>Assignment and Delegation.</b> Submitter shall not assign this Agreement or its rights, and shall not delegate its duties and obligations under this Agreement to any other entity without the prior written consent of CC LLC, which shall not be unreasonably withheld or denied. Furthermore, no work to be performed by Submitter hereunder shall be subcontracted to or performed on behalf of Submitter by any third party, except with the prior written consent of CC LLC.</p>
					<p>11.3	<b>Governing Law.</b> This Agreement shall be governed and interpreted in accordance with the laws of the District of Columbia without regard to principles of conflicts of laws. The Parties agree to submit to the exclusive jurisdiction over all disputes hereunder in the appropriate federal or state courts in the District of Columbia.</p>
					<p>11.4	<b>Arbitration.</b> Unless unenforceable due to federal or state law, any controversy or claim, including, but not limited to, errors and omissions arising out of or relating to this Agreement or the breach thereof, shall be settled by arbitration in accordance with the rules, then in effect, of the American Arbitration Association <b>(“AAA”)</b> or, if AAA shall refuse to arbitrate any such controversy, by such arbitrators as the parties shall mutually agree upon. Judgment upon any award rendered by the arbitrators may be entered in any court having jurisdiction thereof. Any arbitration shall be held in the District of Columbia.</p>
					<p>THE PARTIES AGREE THAT ANY DISPUTE WILL BE ARBITRATED ON AN INDIVIDUAL BASIS; CLASS, REPRESENTATIVE, AND PRIVATE ATTORNEY GENERAL ARBITRATIONS AND ACTIONS ARE NOT PERMITTED. A party may bring claims against another party only in that party's individual capacity and may not participate as a class member or serve as a named plaintiff in any purported class, representative, or private attorney general proceeding. This arbitration provision does not permit and explicitly prohibits the arbitration of consolidated, class, or representative disputes of any form. In addition, although the arbitrator may award any relief that a court could award that is individualized to the claimant and would not affect other parties, no party may seek, nor may the arbitrator award, non-individualized relief that would affect other parties. Further, the arbitrator may not consolidate or join more than one person's claims unless all parties affirmatively agree in writing. If any of the prohibitions in the preceding paragraph is held to be unenforceable as to a particular claim, then that claim (and only that claim) must be severed from the arbitration and brought in court.</p>
					<p>11.5	<b>Entire Agreement.</b> This Agreement constitutes the entire agreement between the Parties concerning the subject matter hereof and supersedes all prior, contemporaneous agreements, and communications, whether oral or written, between the Parties relating to the subject matter of this Agreement and all past courses of dealing or industry custom. This Agreement may be amended only with the specific written consent of both Parties.</p>
					<p>11.6	<b>Severability.</b> If any court of law determines that any clause in this Agreement is unenforceable or void, that clause shall be deleted while the rest of this Agreement remains in force. Moreover, the Parties agree to replace the invalid provisions with a substitute provision that will satisfy the intent of the Parties.</p>
					<p>11.7	<b>No Waiver.</b> The waiver by either Party of any breach of any provision of this Agreement shall not be construed to be either a waiver of CC LLC’s or Submitter’s rights regarding any succeeding breach of any such provision or a waiver of the provision itself, nor shall any delay or omission on the part of CC LLC or Submitter to exercise or avail itself of any right, power, or privilege that it has, or may have hereunder, operate as a waiver of any right, power, or privilege by CC LLC or Submitter.</p>
				</div>
			)
		else {
			return (
				<div>
					<div className="text-center">
						<b>CERTIFICATION LICENSE AGREEMENT</b>
						<p>THIS AGREEMENT is made at Washington, D.C.,</p>
						<p>Date: {signedDate}</p>
						<p>by and between</p>
						<p><b>CTIA Certification LLC</b></p>
						<p><b>(“CTIA Certification”)</b></p>
						<p>and</p>
						<p><b>{manufacturer}</b></p>
						<p><b>(“{submitterTitle}”)</b></p>
						<p>&nbsp;</p>
						{batterySystem ? renderBatterySystem() : renderModelName()}
						<hr />
						<p><b>RECITALS</b></p>
					</div>
					<p><b>WHEREAS</b>, CTIA-The Wireless Association (“CTIA”) is a non profit trade association that since 1991 has had a certification program to encourage conformance with industry standards for wireless devices, components, and other related equipment, including but not limited to the specified device listed above (“Specified Device”), through independent verification (“CTIA Certification Program”);</p>
					<p><b>WHEREAS</b>, to ensure such independent verification, the CTIA Certification Program has developed objective test plans and procedures and utilizes independent, industry-recognized testing laboratories (“Authorized Test Labs”) to review and evaluate the compliance of the Specified Device in accordance with industry standards and requirements;</p>
					<p><b>WHEREAS</b>, under the direction of its Board of Directors, CTIA has fully assigned to CTIA Certification all of its rights, duties, and obligations related to the CTIA Certification Program, and title and interest in the CTIA Certification Program Test Plans and Procedures (“Test Plans”);</p>
					<p><b>WHEREAS</b>, Submitter desires to enter into a voluntary relationship with CTIA Certification for the testing of the Specified Device manufactured by or for Submitter;</p>
					<p><b>WHEREAS</b>, Submitter will demonstrate to CTIA Certification’s satisfaction through an independent evaluation performed by a ATL, that the Specified Device will meet CTIA Certification’s requirements for certification as set forth in the applicable CTIA Certification Requirements Document and Test Plans (“Certification Requirements”); and upon receipt of written notification from CTIA Certification acknowledging that Submitter’s Specified Device has met the Certification Requirements, Submitter desires to pursue recognition for, and represent such certification;</p>
					<p><b>NOW THEREFORE</b>, in consideration of the covenants set forth below, the parties agree to the following:</p>
					<div className="text-center">
						<p><b>1. RIGHTS UPON CERTIFICATION</b></p>
					</div>
					<p><b>A.</b>    Submitter accepts and agrees to be bound by the terms of this Certification License Agreement (“Agreement”) via electronic acknowledgement, which will have the same force and effect as delivery of an original signature. The electronic signing and submitting of this Agreement constitutes Submitter’s understanding, acceptance, and agreement to the terms and conditions set forth in the Agreement. Upon successful completion of the Certification Requirements, which includes the upload of completed test results (“Test Report”) into the CTIA Certification Database (“Certification Database”) by the ATL performing the testing, CTIA Certification will deliver written notification to Submitter. The rights provided herein are expressly conditioned on the Specified Device satisfying the CTIA Certification Requirements.</p>
					<div className="text-center">
						<p><b>2. OWNERSHIP AND INTELLECTUAL PROPERTY</b></p>
					</div>
					<p><b>A.</b>	As between the parties, CTIA Certification owns and retains sole right, title and interest in and to the Test Plans, the Certification Requirements, the CTIA Certification Program, the CTIA Certification logos, brands, trademarks, or service marks (hereinafter, collectively “Certification Marks”); patents, the underlying technology used in connection with CTIA Certification programs; all CTIA Certification websites; and any other documents or information provided to Submitter under this Agreement (collectively the “Certification Intellectual Property”) are the exclusive property of CTIA Certification and/or its affiliates or partners. None of the Certification Intellectual Property provided to Submitter may be retransmitted without CTIA Certification’s express written consent. Submitter further acknowledges that nothing herein will be intended or construed as transferring any proprietary ownership interest in the Certification Intellectual Property and agrees not to take any action that would cause Submitter to acquire any rights in the Intellectual Property. Submitter will not acquire or retain any rights, title, or interest in or to the Certification Intellectual Property except for the limited and express license set forth in this section 2 and section 3 below.</p>
					<p><b>B.</b>	As between the parties, Submitter will own the Test Report, subject to CTIA Certification’s rights, title, and interests, to the Certification Intellectual Property, subject to a nonexclusive, perpetual, irrevocable, worldwide, royalty free, fully-paid license to CTIA Certification to store, maintain, and access the Test Report in the Certification Database.</p>
					<div className="text-center">
						<p><b>3. LICENSE GRANT</b></p>
					</div>
					<p><b>A.</b>	The rights granted herein are granted only to Submitter, and do not extend to Submitter’s shareholders, parents, affiliates, partners, or other related entities. The rights and obligations of this Agreement will run to the named parties, their successors in interest, authorized assigns, insurers, and reinsurers.</p>
					<p><b>B.</b>	Subject to the terms and conditions set forth herein, as long as the Specified Device complies with the Certification Requirements, Submitter will have a non-transferable, non-assignable, non-sublicensable, license to represent that the Specified Device meets the Certification Requirements (“License”). Submitter’s exercise of the License herein must strictly comply with the guidelines surrounding use of Certification Intellectual Property (“Certification Brand Guidelines”) and the terms set forth in this Agreement. CTIA Certification may at any time, and in its sole discretion, amend, modify, or supplement the Certification Requirements and the Certification Brand Guidelines, which can be found<a href='https://ctiacertification.org/what-we-do/##our-brands'>here</a>.</p>
					<p><b>C.</b>	Nothing in this Agreement will be construed as granting Submitter a license to use any of the Certification Intellectual Property except as expressly set forth by the License in subsection 3(B). All other use of the License is strictly prohibited. For avoidance of doubt, CTIA Certification will provide Submitter with any Certification Marks that Submitter is authorized to use. Submitter will not modify or alter any Certification Marks without CTIA Certification’s prior written consent, and Submitter’s use of any Certification Marks is conditioned on its compliance with any applicable Certification Brand Guidelines. Any uses, modifications, or alteration of any Certification Marks will be the sole benefit of CTIA Certification and will accrue to the exclusive benefit of CTIA Certification.</p>
					<p><b>D.</b>	Submitter will not make unauthorized, incomplete, inaccurate, or misleading references to CTIA Certification, the Certification Requirements, the CTIA Certification Program, Test Plans, or a Test Report, whether or not made in connection with the Specified Device (“Unauthorized Reference”). If CTIA Certification knows, or has reason to know, of an Unauthorized Reference, Submitter will modify, cure, or discontinue such use immediately upon CTIA Certification’s written request.</p>
					<div className="text-center">
						<p><b>4. SUBMITTER OBLIGATIONS</b></p>
					</div>
					<p><b>A.</b>	The Specified Device will comply with all of the Certification Requirements.</p>
					<p><b>B.</b>	Submitter assumes sole and exclusive responsibility for any use of, or reference to, any Certification Intellectual Property.</p>
					<p><b>C.</b>	Submitter agrees that any tests or sampling of the Specified Device conducted by CTIA Certification or ATLs, is only a check as to whether the Specified Device complies with the Certification Requirements and in no way releases Submitter from responsibility for the Specified Device, including but not limited to any liabilities, claims, damages, or costs related to the Specified Device.</p>
					<div className="text-center">
						<p><b>5. CORRECTIVE ACTION</b></p>
					</div>
					<p><b>A.</b>	If at any point after the Effective Date, CTIA Certification becomes aware through any CTIA Certification tests, assessments, examinations, or otherwise that the Specified Device does not comply with, in whole or in part, the Certification Requirements (“Noncompliant Specified Device”), CTIA Certification retains the right to revoke certification of the Noncompliant Specified Device. Submitter agrees that upon CTIA Certification’s written request, it will use best efforts to cooperate with and assist CTIA Certification, or CTIA Certification’s authorized third-party agents and contractors, including but not limited to applicable ATLs, in ascertaining the facts needed to determine that the Specified Device complies with the Certification Requirements. CTIA Certification will notify Submitter, and Submitter will immediately and at its own expense, remove, delete, or destroy any references to CTIA Certification in connection with the Noncompliant Specified Device, including any covenants, obligations, representations, or warranties. Submitter will comply with the applicable laws and regulations governing a recall of the Specified Device.</p>
					<p><b>B.</b>	Submitter will notify CTIA Certification of its decision to rework, recall, or cure all units of a Noncompliant Specified Device, within a reasonable period of time from Submitter’s decision. All rights, interests, and permissions granted herein will immediately revert to CTIA Certification until such units are reworked and cured to CTIA Certification’s satisfaction and are no longer deemed a Noncompliant Specified Device, which CTIA Certification will acknowledge by written notification to Submitter.</p>
					<p><b>C.</b>	If allowable by law, upon prior written notice to Submitter, Submitter agrees that CTIA Certification may notify vendors, authorities, potential users, and others of an improper or unauthorized reference to CTIA Certification, provided that such notification is made to (1) protect CTIA Certification’s property, brand, reputation, or other assets; (2) a matter of public interest; or (3) otherwise necessary for the protection of CTIA Certification’s business or legal interests.</p>
					<div className="text-center">
						<p><b>6. TERM AND TERMINATION</b></p>
					</div>
					<p><b>A.</b>	This Agreement will be effective as of the day CTIA Certification delivers written notification to Submitter acknowledging that the Specified Device has satisfied the Certification Requirements (“Effective Date”). This Agreement will continue so long as the Specified Device meets the Certification Requirements, unless sooner terminated as set forth in this section (“Term”).</p>
					<p><b>B.</b>	If Submitter fails to comply with any of the terms and conditions of this Agreement, CTIA Certification may immediately suspend or terminate this Agreement by providing written notice to Submitter. If CTIA Certification chooses to suspend this Agreement, it will specify the time period for such suspension in its written notification, as well as any applicable cure period.</p>
					<p><b>C.</b>	Upon termination of this Agreement by CTIA Certification, the License granted in section 3 will terminate immediately and all rights, permissions, and interests will revert immediately back to CTIA Certification, and Submitter will cease all use of the Certification Intellectual Property, and will not reference CTIA Certification in connection with the Specified Device, including, but not limited to, any covenants, obligations, representations, or warranties.</p>
					<p><b>D.</b>	Termination of this Agreement by whatever means will not affect any liability of the parties existing as of the date of such termination, and will not relieve Submitter of its obligation to indemnify CTIA Certification hereunder.</p>
					<div className="text-center">
						<p><b>7. INDEMNIFICATION</b></p>
					</div>
					<p><b>A.</b>	Submitter will indemnify and hold harmless CTIA Certification, its officers, directors, employees, members, and agents, against any and all liability, loss, cost, damage, claims, suits, or expenses (including reasonable attorneys’ fees and costs) of any kind whatsoever, arising in any way from any negligent or willful acts or omission or breach of this Agreement by Submitter, its agents, employees, or from Submitter’s use, marketing, sale, or distribution of the Specified Device, including but not limited to any third-party claims allegedly caused by the performance or failure to perform of the Specified Device or false or misleading advertising or marketing in connection with the Specified Device. Submitter’s obligation to indemnify CTIA Certification will survive any termination or expiration of this Agreement. Conversely, CTIA Certification will indemnify and hold harmless Submitter, its officers, directors, employees, members, and agents, against any and all liability, loss, cost, damage, claims, suits or expenses (including reasonable attorneys’ fees and costs) of any kind whatsoever, arising from any reckless and willful acts or omissions by CTIA Certification or its agents or employees resulting in a breach of this Agreement.</p>
					<p><b>B.</b>	The indemnifying party will defend the indemnified party against any claim to which its indemnity relates, provided that: (i) the indemnified party provides the indemnifying party with notice of the claim promptly after the indemnified party becomes aware of such claim, and the notice will state the facts giving rise to such claim; (ii) the indemnifying party controls the defense or settlement of such claim, and the indemnifying party will not settle or otherwise dispose of such claim without the indemnified party’s prior written consent; (iii) the indemnified party cooperates with the indemnifying party in every reasonable way to facilitate the defense or settlement of such claim; and (iv) the indemnified party does not settle or otherwise dispose of such claim without the indemnifying party’s prior written consent, and such consent will not be unreasonably withheld or delayed.</p>
					<div className="text-center">
						<p><b>8. LIMITATION OF LIABILITY</b></p>
					</div>
					<p><b>A.</b>	SUBMITTER ACKNOWLEDGES AND AGREES THAT IN NO EVENT WILL CTIA CERTIFICATION BE LIABLE FOR ANY LOSS, DAMAGE, OR CLAIM, RELATED TO THE SPECIFIED DEVICE, WHETHER LIABILITY IS ASSERTED IN CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR OTHER LEGAL THEORY. IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY, OR TO ANY OTHER THIRD PARTY, FOR THE LOSS OF PROFITS, LOSS OF USE, LOSS OF PRODUCTION, LOSS OF GOODWILL, OR INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND.</p>
					<div className="text-center">
						<p><b>9. NO WARRANTY</b></p>
					</div>
					<p><b>A.</b>	Nothing in this Agreement will be interpreted or construed to constitute a guarantee, representation, or warranty by CTIA Certification in connection with the Specified Device. No representation by Submitter in connection with its use, marketing, sale, or distribution of the Specified Device will directly or indirectly, explicitly, or implicitly convey or suggest any such guarantee or warranty. CTIA Certification reserves the right to require Submitter to include a statement specifically disclaiming CTIA Certification’s guarantee, representation, or warranty in the materials associated with the Specified Device.</p>
					<div className="text-center">
						<p><b>10. INSURANCE</b></p>
					</div>
					<p><b>A.</b>	During the Term of this Agreement, Submitter will maintain liability insurance of at least two (2) million dollars in policy limits covering claims or suits related to the Specified Device, and will include CTIA Certification as an additional insured on the policy as to matters covered by this Agreement, and Submitter will furnish to CTIA Certification evidence of that insurance, upon CTIA Certification’s written request. Submitter must notify CTIA Certification of cancellation or non-renewal of applicable insurance policies within thirty (30) days of such cancellation or non-renewal. Failure to comply with the insurance provisions of this Agreement constitutes a material breach of Submitter’s material obligations under this Agreement, and may result in suspension or revocation of the license granted hereunder as well as termination of this Agreement.</p>
					<div className="text-center">
						<p><b>11. MISCELLANEOUS</b></p>
					</div>
					<p><b>A.</b> <u>Independent Contractor</u>. Each party acknowledges that the other party will perform this Agreement as an independent contractor, and this Agreement creates no agency, partnership, joint venture, or employment relationship between the parties. Each party acknowledges that it is not considered an affiliate or subsidiary of the other party, and is not entitled to any employee rights or benefits of the other party.</p>
					<p><b>B.</b> <u>Survival</u>. The sections 2, 3, 4, 5, 6, 7, 8, 9, and 11 will survive the termination or expiration of this Agreement. Those sections that by their nature are intended to survive the termination or expiration of this Agreement will survive the termination or expiration of this Agreement. Provisions of other sections, which, by their nature, must remain in effect beyond the termination or expiration of this Agreement, will also survive termination or expiration of this Agreement.</p>
					<p><b>C.</b> <u>Assignment and Delegation</u>. Submitter will not assign this Agreement or its rights, and will not delegate its duties and obligations under this Agreement to any other entity without the prior written consent of CTIA Certification, which will not be unreasonably withheld or denied. Furthermore, no work to be performed by Submitter hereunder will be subcontracted to or performed on behalf of Submitter by any third party, except with the prior written consent of CTIA Certification.</p>
					<p><b>D.</b> <u>Notices</u>. All legal notices required or permitted hereunder will be given in writing. Legal notices to Submitter  will be addressed to the point of contact provided to CTIA Certification. Legal notices to CTIA Certification will be addressed to ATTN: General Counsel Department, 1400 16th Street NW, Suite 600, Washington DC 20036. Notices will be effective upon receipt if delivered by certified or registered mail or one (1) day after mailing if delivered by nationally recognized overnight courier service. If a party refuses delivery of a notice, it will nevertheless be deemed to have received the notice for the purposes of this Agreement on the date of refusal. Either party may change its address for purposes hereof by written notice to the other in accordance with the provisions of this paragraph.</p>
					<p><b>E.</b> <u>Governing Law</u>. This Agreement will be governed and interpreted in accordance with the laws of the { location } without regard to principles of conflicts of laws. The parties agree to submit to the exclusive jurisdiction over all disputes hereunder in the appropriate federal or state courts in the { location }.</p>
					<p><b>F.</b> <u>Arbitration</u>. Unless unenforceable due to federal or state law, any controversy or claim, including, but not limited to, errors and omissions arising out of or relating to this Agreement or the breach thereof, will be settled by arbitration in accordance with the rules, then in effect, of the American Arbitration Association (“AAA”) or, if AAA will refuse to arbitrate any such controversy, by such arbitrators as the parties will mutually agree upon. Judgment upon any award rendered by the arbitrators may be entered in any court having jurisdiction thereof. Any arbitration will be held in the { location }. THE PARTIES AGREE THAT ANY DISPUTE WILL BE ARBITRATED ON AN INDIVIDUAL BASIS; CLASS, REPRESENTATIVE, AND PRIVATE ATTORNEY GENERAL ARBITRATIONS AND ACTIONS ARE NOT PERMITTED. A party may bring claims against the other party only in that party’s individual capacity. This arbitration provision does not permit and explicitly prohibits the arbitration of consolidated, class, or representative disputes of any form. In addition, although the arbitrator may award any relief that a court could award that is individualized to the claimant and would not affect other parties, no party may seek, nor may the arbitrator award, non-individualized relief that would affect other parties. Further, the arbitrator may not consolidate or join more than one person's claims unless all parties affirmatively agree in writing. If any of the prohibitions in the preceding paragraph is held to be unenforceable as to a particular claim, then that claim (and only that claim) must be severed from the arbitration and brought in court.</p>
					<p><b>G.</b> <u>Entire Agreement</u>. This Agreement constitutes the entire agreement between the parties concerning the subject matter hereof and supersedes all prior, contemporaneous agreements, and communications, whether oral or written, between the parties relating to the subject matter of this Agreement and all past courses of dealing or industry custom. This Agreement may be amended only with the specific written consent of both parties.</p>
					<p><b>H.</b> <u>Severability</u>. If any court of law determines that any clause in this Agreement is unenforceable or void, that clause will be deleted while the rest of this Agreement remains in force. Moreover, the parties agree to replace the invalid provisions with a substitute provision that will satisfy the intent of the parties.</p>
					<p><b>I.</b> <u>No Waiver</u>. The waiver by either party of any breach of any provision of this Agreement will not be construed to be either a waiver the party’s rights regarding any succeeding breach of any such provision or a waiver of the provision itself. Nor will any delay or omission on the part of a party to exercise or avail itself of any right, power, or privilege that it has, or may have hereunder, operate as a waiver of any right, power, or privilege by the party.</p>
				</div>
			);
		}
	}

}

LicenseAgreementContentText.propTypes = {
    signedDate: PropTypes.string,
    manufacturer: PropTypes.string,
    modelName: PropTypes.string,
    submitterTitle: PropTypes.string,
    modelNameTitle: PropTypes.string,
    batterySystem: PropTypes.object,
    version: PropTypes.number
  };
  
LicenseAgreementContentText.defaultProps = {
    signedDate: '<date certified>',
    manufacturer: 'N/A',
    modelName: '<Model Name/Number>',
    submitterTitle: 'Submitter',
    modelNameTitle: 'Specified Device',
    batterySystem: null
};

export default connect(null, {getNewestLicenseAgreementVersion})(LicenseAgreementContentText);
