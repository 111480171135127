import React, { Component } from 'react';
import { Bubble, LoaderButton } from 'ctia-ui';
import { isAccepted, isRejected, isPending } from 'helpers/RequestTestsHelper';

export default class LabDecision extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccepted: false,
      isRejected: false
    };
  }

  clickHandler = accept => {
    this.setState({
      isAccepted: accept === true,
      isRejected: accept === false
    });

    this.props.labDecisionHandler(accept);
  };

  renderAcceptBtn = () => {
    const { isAccepted, isRejected } = this.state;
    return (
      <LoaderButton
        bsStyle="success"
        text="Accept"
        isLoading={isAccepted}
        onClick={this.clickHandler.bind(this, true)}
        disabled={isAccepted || isRejected}
        className="btn-margin10-both"
      />
    );
  };

  renderRejectBtn = () => {
    const { isAccepted, isRejected } = this.state;
    return (
      <LoaderButton
        bsStyle="danger"
        text="Reject"
        isLoading={isRejected}
        onClick={this.clickHandler.bind(this, false)}
        disabled={isAccepted || isRejected}
        className="btn-margin10-both"
      />
    );
  };

  render() {
    const status = this.props.testStatus;

    if (isAccepted(status)) {
      return (
        <Bubble type="warning" show={this.props.show}>
          This request was <b>Accepted</b> for testing before. Click{' '}
          {this.renderRejectBtn()}
          to <b>Reject</b> it.
        </Bubble>
      );
    }

    if (isRejected(status)) {
      return (
        <Bubble type="warning" show={this.props.show}>
          This request was <b>Rejected</b> for testing before. Click{' '}
          {this.renderAcceptBtn()}
          to <b>Accept</b> it.
        </Bubble>
      );
    }

    if (isPending(status)) {
      return (
        <Bubble type="warning" show={this.props.show}>
          Please review this request for testing and click the
          {this.renderAcceptBtn()}
          or
          {this.renderRejectBtn()}
          button.
        </Bubble>
      );
    }

    return null;
  }
}
