import React from "react";
import { connect } from "react-redux";
import { Badge } from "ctia-react-bootstrap-v4";
import IeeeBadge from "components/Badges/IeeeBadge";
import { isInitial, isEco, isOTA, isRenewal } from "helpers/RequestHelper";

const RequestPageHeader = ({ request }) => {
  const renderId = tn => {
    if (tn) return `${tn}`;
    return "";
  };

  const renderTitle = type => {
    if (isInitial(type)) return "Initial Certification Request";
    if (isEco(type)) return "ECO Certification Request";
    if (isOTA(type)) return "OTA Test Plan Use Request";
    if (isRenewal(type)) return "Update/Renewal Request";
    return "Uncategorized Certification Request";
  };

  const renderType = () => {
    const { type, tn } = request;
    const reqId = renderId(tn);
    const pageTitle = renderTitle(type);

    return (
      <h1>
        {pageTitle} {reqId}
      </h1>
    );
  };

  const renderBatteryTags = () => {
    const { batteryType, ieee } = request;

    if (request && batteryType) {
      const renderIeee = () => {
        if (ieee) {
          return <IeeeBadge ieeeType={ieee} />;
        }
      };

      return (
        <h3>
          <Badge className="icon-right-offset">{batteryType}</Badge>
          {renderIeee()}
        </h3>
      );
    }
  };

  return (
    <div>
      {renderType()}
      {renderBatteryTags()}
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(RequestPageHeader);
