import React from "react";
import { connect } from "react-redux";
import RequestSubmitter from "components/request/RequestSubmitter";
import RequestSubmissionDate from "components/request/RequestSubmissionDate";
import RequestCertifiedDate from "components/request/RequestCertifiedDate";

const SubmissionDetails = ({
  showDelimiter = true,
  showSubmitter = true,
  showSubmissionDate = true,
  showCertifiedDate = true,
  request
}) => {
  if (!request || !request.id) return null;
  
  return (
    <>
      {showDelimiter && <hr />}
      {showSubmitter && <RequestSubmitter />}
      {showSubmissionDate && <RequestSubmissionDate />}
      {showCertifiedDate && <RequestCertifiedDate />}
    </>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(SubmissionDetails);
