/* 
    This is a list of form fields that contain only `number` values
    and should be formatted from string to number before sending forward
*/
export default [
  'deviceType',
  'cdmaLab',
  'cdmaLabPoc',
  'testResult',
  'parentModel',
  'requesttestpassedstatusid'
];
