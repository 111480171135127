import * as types from 'actions/types';

const checkServer = action => {
  const serverIsDown =
    action.payload &&
    ((action.payload.response && action.payload.response.status === 503) ||
      (action.payload.response && action.payload.response.status === 403) ||
      action.payload.message === 'Network Error');

  return { serverIsDown };
};

export default function(state = null, action) {
  switch (action.type) {
    case types.GET_SYSTEM_INFO:
    case types.SIGN_AGREEMENT:
    case types.DOWNLOAD_SIGNED_AGREEMENT:
    case types.DOWNLOAD_AGREEMENT_BLANK:
    case types.GET_AGREEMENT_INFO:
    case types.FETCH_REQUEST_COMMENTS:
    case types.POST_COMMENT:
    case types.FETCH_DEVICE_LIST:
    case types.FETCH_AUTHORIZED_LABS:
    case types.FETCH_MODEL_VERSIONS:
    case types.FETCH_MODEL:
    case types.CHECK_EXISTED_MODEL:
    case types.FETCH_REQUEST_CYBER:
    case types.FETCH_REQUEST_OTA:
    case types.UPDATE_USERS_MANUAL:
    case types.UPDATE_REQUEST_STATUS:
    case types.FETCH_POCS:
    case types.CREATE_NEW_POC:
    case types.DOWNLOAD_USERS_MANUAL:
    case types.GET_USERS_MANUAL_INFO:
    case types.DOWNLOAD_DOCUMENT:
    case types.DELETE_DOCUMENT:
    case types.UPDATE_REQUEST:
    case types.SUBMIT_INITIAL_REQUEST:
    case types.SUBMIT_ECO_REQUEST:
    case types.WITHDRAW_REQUEST:
    case types.UNWITHDRAW_REQUEST:
    case types.SEARCH_REQUEST:
    case types.FETCH_CERTIFICATION_REQUESTS:
    case types.COUNT_APPROVE_WAITING_REQUESTS:
    case types.FETCH_MODEL_LIST:
    case types.FETCH_REQUEST_TESTS:
    case types.SEND_TEST_RESULT:
    case types.DOWNLOAD_TEST_REPORT:
    case types.FETCH_TEST_REPORTS:
    case types.UPDATE_REPORT_STATUS:
    case types.DELETE_TEST_REPORT:
    case types.UPLOAD_SUPPORT_DOC:
    case types.UPLOAD_USERS_MANUAL:
    case types.DELETE_USERS_MANUAL:
    case types.DELETE_SUPPORT_DOCUMENT:
    case types.UPLOAD_TEST_REPORT:
    case types.UPDATE_DOCUMENT_TITLE:
    case types.SAVE_USER_TOKEN:
    case types.AGREE_TERMS:
      return checkServer(action);
    case types.LOGOUT:
      return null;
    default:
      return state;
  }
}
