// addresses
export const FETCH_ADDRESSES = "fetch_addresses";
export const GET_ADDRESS_DETAILS = "get_address_details";
export const UPDATE_ADDRESS = "update_address";

// agreement
export const SIGN_AGREEMENT = "sign_agreement";
export const SIGN_AGREEMENT_BATTERY_RECOGNITION =
  "sign_agreement_battery_recognition";
export const SIGN_AGREEMENT_BATTERY_DECLARATION =
  "sign_agreement_battery_declaration";
export const DOWNLOAD_SIGNED_AGREEMENT = "download_signed_agreement";
export const DOWNLOAD_AGREEMENT_BLANK = "download_agreement_blank";
export const GET_AGREEMENT_INFO = "get_agreement_info";
export const CLEAN_AGREEMENT_INFO = "clean_agreement_info";

// app
export const RESIZE_WINDOW = "resize_window";
export const TOGGLE_VIEW_MODE = "toggle_view_mode";
export const RESET_CERT_PROGRAM_STATUS = "reset_cert_program_status";
export const CHANGE_CERT_PROGRAM = "change_cert_program";
export const SET_PAGE_SIZE = "set_page_size";
export const CHANGE_CYBER_REQUEST_STATUS = "change_cyber_request_status";
export const CHANGE_CYBER_REQUEST_TEST_STATUS =
  "change_cyber_request_test_status";
export const CHANGE_CYBER_ADMIN_USER_TYPE = "change_cyber_admin_user_type";
export const SET_REQUEST_VIEW_ACTIVE_TAB = "set_request_view_active_tab";
export const CHANGE_OTA_REQUEST_STATUS = "change_ota_request_status";
export const CHANGE_OTA_REQUEST_TEST_STATUS = "change_ota_request_test_status";
export const CHANGE_DHR_REQUEST_TEST_STATUS = "change_dhr_request_test_status";
export const START_SAVING = "start_saving";
export const STOP_SAVING = "stop_saving";
export const START_SUBMITTING = "start_submitting";
export const STOP_SUBMITTING = "stop_submitting";
export const SET_REQUEST_LIST_ACTIVE_TAB = "set_request_list_active_tab";
export const CHANGE_BATTERY_REQUEST_STATUS = "change_battery_request_status";
export const CHANGE_BATTERY_REQUEST_TEST_STATUS =
  "change_battery_request_test_status";
export const GET_CERT_TYPES = "get_cert_types";
export const TOGGLE_USER_STATUS = "toggle_user_status";

// ARR
export const FETCH_REQUEST_ARR = "fetch_request_arr";
export const FETCH_ARR_MODEL = "fetch_arr_model";
export const FETCH_ARR_ECO_MODELS = "fetch_arr_eco_models";

// ACR
export const FETCH_REQUEST_ACR = "fetch_request_acr";
export const FETCH_ACR_MODEL = "fetch_acr_model";
export const FETCH_ACR_ECO_MODELS = "fetch_acr_eco_models";

// auth
export const AUTHORIZE_USER = "authorize_user";
export const AUTH_ERROR = "auth_error";

// billing
export const FETCH_BILLING_ADDRESSES = "fetch_billing_addresses";
export const FETCH_REQUEST_BILLING_INFO = "fetch_request_billing_info";
export const UPDATE_BILLING_INFO = "update_billing_info";
export const CREATE_NEW_BILLING_POC = "create_new_billing_poc";
export const CREATE_NEW_BILLING_ADDRESS = "create_new_billing_address";

// BLC
export const FETCH_REQUEST_BLC = "fetch_request_blc";
export const FETCH_BLC_MODEL = "fetch_blc_model";
export const FETCH_BLC_INITIAL_MODELS = "fetch_blc_initial_model";
export const FETCH_BLC_ECO_MODELS = "fetch_blc_eco_models";
export const FETCH_OPERATING_SYSTEMS = "fetch_operating_systems";

// BSC
export const FETCH_REQUEST_BSC = "fetch_request_bsc";
export const FETCH_BSC_MODEL = "fetch_bsc_model";
export const FETCH_BSC_ECO_MODELS = "fetch_bsc_eco_models";

// budget report
export const FETCH_BUDGET_LIMITS = "fetch_budget_limits";
export const SET_BUDGET_LIMITS = "set_budget_limits";
export const SET_BUDGET_LIMIT = "set_budget_limit";
export const SWITCH_BUDGET_DETAILS_VIEW = "switch_budget_details_view";
export const SET_BUDGET_SETTING = "set_budget_setting";
export const FETCH_BUDGET_DETAILS = "fetch_budget_details";
export const FETCH_BUDGET_SUMMARY = "fetch_budget_summary";
export const FETCH_BUDGET_REVENUE = "fetch_budget_revenue";

// (C)ATL - Authorized Testing Labs
export const SIGN_ATL_AGREEMENT = "sign_atl_agreement";
export const DOWNLOAD_ATL_AGREEMENT = "download_atl_agreement";
export const DOWNLOAD_ATL_INVOICE = "download_atl_invoice";
export const SET_INVOICING_YEAR = "set_invoicing_year";
export const FETCH_ATL_LIST = "fetch_atl_list";
export const TOGGLE_ATL_MEMBERSHIP = "toggle_atl_membership";
export const TOGGLE_ATL_MEMBERSHIP_ALL = "toggle_atl_membership_all";
export const TOGGLE_ATL_BACKGROUND_JOB_STATUS =
  "toggle_atl_background_job_status";
export const TOGGLE_ATL_FEE = "toggle_atl_fee";
export const TOGGLE_ATL_FEE_ALL = "toggle_atl_fee_all";
export const CLEAR_ATL_LIST = "clear_atl_list";
export const FETCH_ATL_INVOICE = "fetch_atl_invoice";
export const FETCH_ATL_INVOICES_COSTS = "fetch_atl_invoices_costs";
export const CHANGE_INVOICE_FIELD = "change_invoice_field";
export const CHANGE_COST_FIELD = "change_cost_field";
export const CLEAR_INVOICE_ATL = "clear_invoice_atl";
export const UPDATE_INVOICE_ATL = "update_invoice_atl";
export const REMOVE_ATL_PAYMENT = "remove_atl_payment";

// ASC - Authorized Service Center
export const SIGN_ASC_AGREEMENT = "sign_asc_agreement";
export const DOWNLOAD_ASC_AGREEMENT = "download_asc_agreement";
export const DOWNLOAD_ASC_INVOICE = "download_asc_invoice";
export const SET_ASC_INVOICING_YEAR = "set_asc_invoicing_year";
export const FETCH_ASC_LIST = "fetch_asc_list";
export const TOGGLE_ASC_MEMBERSHIP = "toggle_asc_membership";
export const TOGGLE_ASC_MEMBERSHIP_ALL = "toggle_asc_membership_all";
export const TOGGLE_ASC_BACKGROUND_JOB_STATUS =
  "toggle_asc_background_job_status";
export const TOGGLE_ASC_FEE = "toggle_asc_fee";
export const TOGGLE_ASC_FEE_ALL = "toggle_asc_fee_all";
export const CLEAR_ASC_LIST = "clear_asc_list";
export const FETCH_ASC_INVOICE = "fetch_asc_invoice";
export const FETCH_ASC_INVOICES_COSTS = "fetch_asc_invoices_costs";
export const CHANGE_ASC_INVOICE_FIELD = "change_asc_invoice_field";
export const CHANGE_ASC_COST_FIELD = "change_asc_cost_field";
export const CLEAR_INVOICE_ASC = "clear_invoice_asc";
export const UPDATE_INVOICE_ASC = "update_invoice_asc";
export const ADD_ASC_COMPANY = "add_asc_company";
export const UPDATE_ASC_COMPANY = "update_asc_company";
export const REMOVE_ASC_PAYMENT = "remove_asc_payment";

// certified pending report
export const CLEAR_CERTIFIED_PENDING_REPORT_DETAILS =
  "clear_certified_pending_report_details";
export const FETCH_CERTIFIED_PENDING_REPORT_DETAILS =
  "fetch_certified_pending_report_details";

// comments
export const FETCH_REQUEST_COMMENTS = "fetch_request_comment";
export const POST_COMMENT = "post_comment";
export const FETCH_ATL_COMMENTS = "fetch_atl_comment";
export const POST_ATL_COMMENT = "post_atl_comment";
export const CLEAR_COMMENTS = "clear_comments";
export const FETCH_ASC_COMMENTS = "fetch_asc_comment";
export const POST_ASC_COMMENT = "post_asc_comment";

// companies
export const CLEAR_ALL_COMPANIES = "clear_all_companies";
export const APPEND_COMPANY_TO_STORE = "append_company_to_store";
export const FETCH_AUTHORIZED_LABS = "fetch_authorized_labs";
export const FETCH_AUTHORIZED_MANUFACTURERS = "fetch_authorized_manufacturers";
export const FETCH_AUTHORIZED_OPERATORS = "fetch_authorized_operators";
export const FETCH_AUTHORIZED_PTCRB_LABS = "fetch_authorized_ptcrb_labs";
export const FETCH_AUTHORIZED_SUPPLIERS = "fetch_authorized_suppliers";
export const POST_NEW_COMPANY = "post_new_company";

// countries
export const FETCH_COUNTRIES = "fetch_countries";
export const FETCH_COUNTRY_STATES = "fetch_country_states";
export const CLEAR_COUNTRY_STATE = "clear_country_state";
export const CLEAR_COUNTRY_STATES = "clear_country_states";
export const SET_SELECTED_COUNTRY = "set_selected_country";
export const SET_SELECTED_STATE = "set_selected_state";

// CRR
export const FETCH_CRR_LABS = "fetch_crr_labs";
export const CLEAR_CRR_STORE = "clear_crr_store";
export const FETCH_CRR_ECO_MODELS = "fetch_crr_eco_models";
export const MAP_CRR_MANUF_SITES = "map_crr_manuf_sites";
export const MAP_CRR_MODEL_FIELDS = "map_crr_model_fields";
export const FETCH_CRR_MODEL = "fetch_crr_model";

// devices
export const FETCH_DEVICE_LIST = "fetch_device_list";
export const SET_KEEP_LIST = "set_keep_list";
export const CLEAR_DEVICE_LIST = "clear_device_list";
export const SET_DEVICE_LIST_SEARCH_TERM = "set_device_list_search_term";
export const SET_DEVICE_LIST_FILTERED_LIST = "set_device_list_filtered_list";
export const FETCH_BATTERY_DEVICE_LIST = "fetch_battery_device_list";
export const FETCH_SAR_DEVICE_LIST = "fetch_sar_device_list";

// Device Hardware Reliability (DHR)
export const FETCH_DHR_LABS = "fetch_dhr_labs";
export const CLEAR_DHR_STORE = "clear_dhr_store";
export const FETCH_DHR_EXISTED_MODELS = "fetch_dhr_existed_models";
export const FETCH_REQUEST_DHR = "fetch_request_dhr";
export const FETCH_DHR_ECO_MODELS = "fetch_dhr_eco_models";

// EPR
export const FETCH_REQUEST_EPR = "fetch_request_epr";
export const MAP_EPR_MODEL_FIELDS = "map_epr_model_fields";
export const FETCH_EPR_MODEL = "fetch_epr_model";
export const FETCH_EPR_ECO_MODELS = "fetch_epr_eco_models";

// HRR
export const FETCH_REQUEST_HRR = "fetch_request_hrr";
export const FETCH_HRR_MODEL = "fetch_hrr_model";
export const FETCH_HRR_ECO_MODELS = "fetch_hrr_eco_models";
export const MAP_BATTERY_DEVICE_TYPE = "map_battery_device_type";

// invoice report
export const FETCH_INVOICE_REPORT_DETAILS = "fetch_invoice_report_details";

// labs
export const FETCH_ACTIVE_LABS = "fetch_active_labs";
export const GET_LABS_TO_SWITCH = "get_labs_to_switch";
export const FETCH_USER_LABS = "fetch_user_labs";

// model
export const FETCH_MODEL = "fetch_model";
export const UPDATE_MODEL_FIELD = "update_model_field";
export const FETCH_MODEL_VERSIONS = "fetch_model_versions";
export const CLEAR_MODEL = "clear_model";
export const CHECK_EXISTED_MODEL = "check_existed_model";
export const CHECK_PTCRB_MODELS = "check_ptcrb_models";
export const FETCH_OTA_EXISTED_MODELS = "fetch_ota_existed_models";
export const FETCH_MODEL_INTERFACES = "fetch_model_interfaces";
export const CLEAR_MODEL_INTERFACES = "clear_model_interfaces";
export const FETCH_VENDOR_BATTERY_MODELS = "fetch_vendor_battery_models";
export const FETCH_BSC_MODELS = "fetch_bsc_models";
export const FETCH_PTCRB_MODELS_FOR_BSC = "fetch_ptcrb_models_for_bsc";

// new user request
export const CLEAR_NEW_USER_REQUEST_STORE = "clear_new_user_request_store";
export const CLEAR_SELECTED_USER_REQUEST = "clear_selected_user_request";
export const FETCH_NEW_USERS_REQUESTS_LISTS = "fetch_new_users_requests_lists";
export const SET_USER_REQUEST_FORM_VALUES = "set_user_request_form_values";
export const SET_USER_REQUEST_USER_NAME_VALID =
  "set_user_request_user_name_valid";
export const UPDATE_NEW_USER_REQUEST = "update_new_user_request";

// non-SAR sites
export const MAP_NON_SAR_SITES = "map_non_sar_sites";
export const FETCH_NON_SAR_SITES = "fetch_non_sar_sites";
export const ADD_NON_SAR_SITE = "add_non_sar_site";
export const UPDATE_NON_SAR_SITE_NAME = "update_non_sar_site_name";
export const DELETE_NON_SAR_SITE = "delete_non_sar_site";
export const CLEAR_NON_SAR_SITES = "clear_non_sar_sites";

// notifier
export const SHOW_NOTICE = "show_notice";
export const HIDE_NOTICE = "hide_notice";

// operators
export const CLEAR_OPERATORS = "clear_operators";
export const FETCH_OPERATORS = "fecth_operators";
export const UPDATE_MODEL_OPERATORS = "update_model_operators";

// organ admin
export const ADD_VENDOR_COMPANY = "add_vendor_company";
export const UPDATE_VENDOR_COMPANY = "update_vendor_company";
export const UPDATE_LAB_COMPANY = "update_lab_company";
export const UPDATE_OPERATOR_COMPANY = "update_operator_company";

// organizations
export const SET_ORG_TYPE = "set_org_type";
export const GET_ORGANIZATION = "get_organization";
export const GET_ORGANIZATIONS_BY_TYPE = "get_organizations_by_type";
export const GET_ORGANIZATIONS_FOR_USER = "get_organizations_for_user";
export const CLEAR_ORG_LIST = "clear_org_list";
export const CLEAR_USER_ORG_LIST = "clear_user_org_list";
export const SET_USER_TYPE_ORG = "set_user_type_org";
export const ENABLE_ORGANIZATION = "enable_organization";
export const DISABLE_ORGANIZATION = "disable_organization";

// OTA test plans CATLs
export const FETCH_OTA_INTERFACES = "fetch_ota_interfaces";
export const ADD_OTA_TEST_PLAN = "add_ota_test_plan";
export const REMOVE_OTA_TEST_PLAN = "remove_ota_test_plan";
export const FILTER_OTA_TEST_PLANS = "filter_ota_test_plans";
export const GET_OTA_COSTS = "get_ota_costs";
export const FETCH_OTA_LABS = "fetch_ota_labs";
export const CLEAR_OTA_STORE = "clear_ota_store";
export const CLEAR_OTA_INTERFACES = "clear_ota_interfaces";

// payments
export const UPDATE_PAYMENT = "update_payment";
export const CONFIRM_ATL_PAYMENT = "confirm_atl_payment";
export const CONFIRM_ASC_PAYMENT = "confirm_asc_payment";
export const UPDATE_INVOICE_DATE = "update_invoice_date";

// preloader
export const PRELOADER_START = "preloader_start"; // TODO: Get rid of it
export const PRELOADER_FINISH = "preloader_finish"; // TODO: Get rid of it
export const START_PRELOADER = "start_preloader";
export const STOP_PRELOADER = "stop_preloader";

// PRR
export const FETCH_REQUEST_PRR = "fetch_request_prr";
export const MAP_PRR_MODEL_FIELDS = "map_prr_model_fields";
export const FETCH_PRR_MODEL = "fetch_prr_model";
export const FETCH_PRR_ECO_MODELS = "fetch_prr_eco_models";

// request
export const FETCH_REQUEST_CYBER = "fetch_request_cyber";
export const FETCH_REQUEST_OTA = "fetch_request_ota";
export const CHANGE_REQUEST_FIELD = "change_request_field";
export const SAVE_REQUEST = "save_request";
export const CLEAR_REQUEST = "clear_request";
export const INIT_NEW_REQUEST = "init_new_request";
export const FETCH_POCS = "fetch_pocs";
export const CREATE_NEW_POC = "create_new_poc";
export const DOWNLOAD_USERS_MANUAL = "download_users_manual";
export const DOWNLOAD_DOCUMENT = "download_document";
export const DELETE_DOCUMENT = "delete_document";
export const UPDATE_REQUEST = "update_request";
export const FORCE_REQUEST_UPDATE = "force_request_update";
export const GET_USERS_MANUAL_INFO = "get_users_manual_info";
export const CLEAN_POC_INFO = "clean_poc_info";
export const SUBMIT_INITIAL_REQUEST = "submit_initial_request";
export const SUBMIT_ECO_REQUEST = "submit_eco_request";
export const WITHDRAW_REQUEST = "withdraw_request";
export const UNWITHDRAW_REQUEST = "unwithdraw_request";
export const UPDATE_USERS_MANUAL = "update_users_manual";
export const UPDATE_REQUEST_STATUS = "update_request_status";
export const RETURN_TO_EDIT_REQUEST = "return_to_edit_request";
export const SEARCH_REQUEST = "search_request";
export const MAKE_LAB_DECISION = "make_lab_decision";
export const SET_VALIDATION_STATUS = "set_validation_status";
export const FETCH_CERT_CATEGORIES = "fetch_cert_categories";
export const MAP_REQUEST_OPERATORS = "map_request_operators";
export const SET_LTE_WIRELESS = "set_lte_wireless";
export const SET_OTA_INTERFACES = "set_ota_interfaces";
export const FETCH_REQUEST_SAR = "fetch_request_sar";
export const UPDATE_REQUEST_SAR = "update_request_sar";
export const MAP_LINKED_REQUESTS = "map_linked_requests";
export const FETCH_REQUEST_CRR = "fetch_request_crr";
export const FETCH_PARENT_REQUEST_CRR = "fetch_parent_request_crr";
export const UPDATE_REQUEST_CRR = "update_request_crr";
export const ATTACH_APP_CERT_TYPES = "attach_app_cert_types";

// requests
export const FETCH_CERTIFICATION_REQUESTS = "fetch_certification_requests";
export const CLEAR_REQUESTS = "clear_requests";
export const FETCH_APPROVE_WAITING_REQUESTS = "fetch_approve_waiting_requests";
export const COUNT_APPROVE_WAITING_REQUESTS = "count_approve_waiting_requests";
export const FETCH_MODEL_LIST = "fetch_model_list";
export const FETCH_REQUESTS_FOR_TESTING = "fetch_requests_for_testing";
export const FETCH_REQUESTS_FOR_AUDITING = "fetch_requests_for_auditing";
export const FETCH_UNPAID_REQUESTS = "fetch_unpaid_requests";

// sites (manuf sites for SAR and non-SAR battery stuff too)
export const FETCH_MANUF_SITES = "fetch_manuf_sites";
export const HANDLE_SITE_FIELD = "handle_site_field";
export const CLEAR_CURR_SITE = "clear_curr_site";
export const CHECK_AWAITING_LINKED_SITES = "check_awaiting_linked_sites";

// suppliers
export const FETCH_BATTERY_SUPPLIERS = "fetch_battery_suppliers";
export const ADD_BATTERY_SUPPLIER = "add_battery_supplier";
export const REMOVE_BATTERY_SUPPLIER = "remove_battery_supplier";
export const FETCH_RECOGNIZED_ADAPTERS = "fetch_recognized_adapters";
export const FETCH_RECOGNIZED_VERSIONS = "fetch_recognized_versions";
export const FETCH_VERSIONS_MODEL = "fetch_versions_model";
export const FETCH_ADAPTER_MODELS = "fetch_adapter_models";
export const ADD_ADAPTER_SUPPLIER = "add_adapter_supplier";
export const REMOVE_ADAPTER_SUPPLIER = "remove_adapter_supplier";
export const FETCH_CELL_SUPPLIERS = "fetch_cell_suppliers";
export const FETCH_CELL_MODELS = "fetch_cell_models";
export const ADD_CELL_SUPPLIER = "add_cell_supplier";
export const REMOVE_CELL_SUPPLIER = "remove_cell_supplier";
export const FETCH_APPROVED_ADAPTER_SUPPLIERS =
  "fetch_approved_adapter_suppliers";
export const FETCH_CERTIFIED_ADAPTERS = "fetch_certified_adapters";
export const FETCH_CERTIFIED_ADAPTER_REQUESTS =
  "fetch_certified_adapter_requests";
export const FETCH_APPROVED_RECOGNITION_SUPPLIERS =
  "fetch_approved_recognition_suppliers";
export const FETCH_RECOGNIZED_ADAPTER_REQUESTS =
  "fetch_recognized_adapter_requests";
export const FETCH_APPROVED_PACK_SUPPLIERS = "fetch_approved_pack_suppliers";
export const FETCH_CERTIFIED_PACKS = "fetch_certified_packs";
export const FETCH_CERTIFIED_PACK_REQUESTS = "fetch_certified_pack_requests";
export const FETCH_CERTIFIED_BSC_PACK_REQUESTS =
  "fetch_certified_bsc_pack_requests";

// system
export const GET_SYSTEM_INFO = "get_system_info";

// test actions
export const FETCH_BOOKS = "fetch_books";

// tests
export const FETCH_REQUEST_TESTS = "fetch_request_tests";
export const SEND_TEST_RESULT = "send_test_result";
export const DOWNLOAD_TEST_REPORT = "download_test_report";
export const CHANGE_TEST_FIELD = "change_test_field";
export const FETCH_TEST_REPORTS = "fetch_test_reports";
export const FETCH_TEST_REPORTS_OTA = "fetch_test_reports_ota";
export const FETCH_TEST_REPORTS_BLC = "fetch_test_reports_blc";
export const CLEAR_TESTS = "clear_tests";
export const UPDATE_REPORT_STATUS = "update_report_status";

// uploads
export const UPLOAD_SUPPORT_DOC = "upload_support_doc";
export const UPLOAD_USERS_MANUAL = "upload_users_manual";
export const ADD_TO_QUEUE = "add_to_queue";
export const REMOVE_FROM_QUEUE = "remove_from_queue";
export const CLEAR_QUEUE = "clear_queue";
export const DELETE_USERS_MANUAL = "delete_users_manual";
export const DELETE_SUPPORT_DOCUMENT = "delete_support_document";
export const UPLOAD_TEST_REPORT = "upload_test_report";
export const DELETE_TEST_REPORT = "delete_test_report";
export const DELETE_TEST_REPORT_OTA = "delete_test_report_ota";
export const UPDATE_DOCUMENT_TITLE = "update_document_title";

// wizard
export const INIT_WIZARD = "init_wizard";
export const SAVE_WIZARD = "save_wizard";
export const SUBMIT_WIZARD = "submit_wizard";
export const CHANGE_WIZARD_STEP = "change_wizard_step";
export const SUBMIT_WIZARD_STEP = "submit_wizard_step";
export const CLEAR_WIZARD = "clear_wizard";
export const UPDATE_WIZARD = "update_wizard";

// user
export const LOGOUT = "logout";
export const AGREE_TERMS = "agree_terms";
export const TOGGLE_REQUEST_EDIT_MODE = "toggle_request_edit_mode";
export const TOGGLE_OTA_REQUEST_EDIT_MODE = "toggle_ota_request_edit_mode";
export const TOGGLE_EDIT_MODE = "toggle_edit_mode";
export const GET_USER_INFO = "get_user_info";
export const SAVE_USER_TOKEN = "save_user_token";
export const GET_USER_PROFILE = "get_user_profile";
export const UPDATE_USER_PROFILE = "update_user_profile";
export const UPDATE_USER_LOCATION = "update_user_location";
export const FETCH_USERS = "fetch_users";
export const FETCH_DELETED_USERS = "fetch_deleted_users";
export const FETCH_PAYMENT_USERS = "fetch_payment_users";
export const FETCH_COMPANY_USERS = "fetch_company_users";
export const CLEAR_USER_LIST = "clear_user_list";
export const SET_PERMISSIONS = "set_permissions";
export const GET_ATL_USERS = "get_atl_users";
export const SET_NEW_PASSWORD = "set_new_password";

// legacy user
export const CREATE_LEGACY_USER = "create_legacy_user";
export const EDIT_LEGACY_USER = "edit_legacy_user";
export const ACTIVATE_INACTIVE_USER = "activate_inactive_user";
export const ACTIVATE_DELETED_USER = "activate_deleted_user";
export const DELETE_USER = "delete_user";
export const CLEAN_DELETED_USERS = "clean_deleted_users";
export const LOCK_USER = "lock_user";
export const UNLOCK_USER = "unlock_user";
export const GET_ACTIVE_LAB_USERS = "get_active_lab_users";

// users2labs
export const SELECT_LAB_USER = "select_lab_user";
export const SELECT_LAB_OF_USERS = "select_lab_of_users";
export const SET_TRANSFER_FORM_VISIBILITY = "toggle_transfer_form_visibility";
export const UNMOUNT_USERS_TO_LABS = "unmount_users_to_labs";
