import React from "react";

const NoDataMessage = ({ message }) => {
  return (
    <div className="block-left-offset">
      <ul className="ctia-list">
        <li>{message}</li>
      </ul>
    </div>
  );
};

export default NoDataMessage;
