import React from "react";
import PropTypes from "prop-types";
import { DownloadButton } from "ctia-ui";
import { Modal, Button } from "react-bootstrap";

import AgreementContent from "components/LicenseAgreementContent";

import "containers/request/LicenseAgreement/style.css";

const LicenseAgreementModal = ({
  show,
  modalHandler,
  downloadHandler,
  certProgram = "IoT Cybersecurity Certification Program"
}) => {
  const renderDownloadButton = () => (
    <span className="icon-left-offset">
      <DownloadButton size="small" action={downloadHandler} />
    </span>
  );

  return (
    <Modal show={show} onHide={modalHandler} bsSize="large">
      <Modal.Header closeButton>
        <Modal.Title>
          <div>License Agreement {renderDownloadButton()}</div>
          <div>
            <small>{certProgram}</small>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AgreementContent />
      </Modal.Body>
      <Modal.Footer>
        {renderDownloadButton()}
        <Button onClick={modalHandler} className="agr-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

LicenseAgreementModal.propTypes = {
  show: PropTypes.bool,
  modalHandler: PropTypes.func,
  downloadHandler: PropTypes.func,
  certProgram: PropTypes.string
};

export default LicenseAgreementModal;
