import { restClient } from "libs/restClient";
import * as actions from "actions/types";
import * as addresses from "actions/battery/sarAddresses";
import * as sites from "actions/sitesSar";
import * as tests from "actions/tests";
import { fileDownload } from "actions/uploads";
import * as call from "helpers/ActionHelper";
import * as args from "helpers/ArgsBatteryHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import * as reqHelper from "helpers/RequestHelper";
import { getPageArgsAsQueryString } from "helpers/ArgsHelper";

export const createSARRequest = request => async dispatch => {
  try {
    const reqBody = args.prepareSARArgs(request);
    const response = await restClient.post("requests", JSON.stringify(reqBody));
    const payload = response.data.data.id;

    dispatch({
      type: actions.SUBMIT_INITIAL_REQUEST,
      payload
    });

    dispatch({
      type: actions.SUBMIT_WIZARD,
      payload: { isSubmitted: true }
    });

    dispatch({
      type: actions.CLEAR_CURR_SITE,
      payload: null
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

// SAR Initial request submission
export function submitSAR(request, manufSite) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    // selected one of the existed manufSites:
    if (request.manufSiteId) {
      return dispatch(createSARRequest(request)).then(quit).catch(quit);
    }

    // otherwise we need to create address as well as manufSite:
    return dispatch(addresses.createAddress(manufSite))
      .then(res => {
        const siteData = {
          addressId: res.data.data.id,
          name: manufSite.manufSiteName,
          ieee: request.ieee
        };

        return dispatch(sites.createManufSite(siteData));
      })
      .then(res => {
        const manufSiteId = res.data.data.id;
        const sarRequest = { ...request, manufSiteId };

        return dispatch(createSARRequest(sarRequest));
      })
      .then(quit)
      .catch(quit);
  };
}

// SAR Renewal/Update request submission
export function submitRenewal(request) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(createSARRequest(request)).then(quit).catch(quit);
  };
}

export const getRequestDetails = id => async dispatch => {
  try {
    const url = `/requests/${id}/details`;
    const response = await restClient.get(url);

    dispatch({ type: actions.FETCH_REQUEST_SAR, payload: response.data.data });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export function getDetailsSAR(requestId) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return (
      dispatch(getRequestDetails(requestId))
        // .then(() => dispatch(getAwaitingLinkedSites(requestId)))
        .then(() => dispatch(tests.fetchRequestTests(requestId)))
        .then(res => {
          const resData = res.data;
          if (!resData) return Promise.resolve(null);

          const testId = resData.data[0].id;
          return dispatch(tests.fetchTestReports({ id: testId }));
        })
        .then(quit)
        .catch(quit)
    );
  };
}

export const updateSARRequest = request => async dispatch => {
  try {
    const url = `/requests/${request.id}`;
    const reqBody = args.prepareSARArgs(request);
    const response = await restClient.put(url, JSON.stringify(reqBody));
    const payload = response.data.data;

    dispatch({
      type: actions.UPDATE_REQUEST_SAR,
      payload
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function updateRequest(request) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(updateSARRequest(request))
      .then(() => dispatch(sites.updateManufSiteName(request)))
      .then(() => dispatch(getDetailsSAR(request.id)))
      .then(() =>
        dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully))
      )
      .then(quit)
      .catch(quit);
  };
}

export const fetchRequestList = ({
  page,
  size,
  statusFilter
}) => async dispatch => {
  try {
    const filterList = statusFilter.join();
    const pagerArgs = getPageArgsAsQueryString(page, size);
    const url = `/requests/table?${pagerArgs}&orderBy=transactionnumber DESC&where=requeststatusid IN (${filterList})`;
    const response = await restClient.get(url);

    dispatch({
      type: actions.FETCH_CERTIFICATION_REQUESTS,
      payload: response.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function fetchCertificationRequests(settings) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(fetchRequestList(settings))
      .then(() => dispatch(call.stopPreloader()))
      .catch(() => dispatch(call.stopPreloader()));
  };
}

export const fetchRequestsForAuditing = ({
  page,
  size,
  statusFilter
}) => async dispatch => {
  try {
    const filterList = `'${statusFilter.join("','")}'`;
    const statusList = reqHelper.getBasicRequestStatusList();
    const pagerArgs = getPageArgsAsQueryString(page, size);

    const url = `/requests/table?${pagerArgs}&orderBy=transactionnumber DESC&where=testingstatus IN (${filterList}) AND requeststatusid IN (${statusList})`;
    const response = await restClient.get(url);

    dispatch({
      type: actions.FETCH_CERTIFICATION_REQUESTS,
      payload: response.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function fetchAuditRequests(tableSettings) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(fetchRequestsForAuditing(tableSettings))
      .then(() => {
        dispatch(call.stopPreloader());
      })
      .catch(() => dispatch(call.stopPreloader()));
  };
}

export function setLabAuditPermissions(allow = true) {
  const payload = {
    canEditManufsite: allow
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export function setEditManufsitePermissions(user, request) {
  let canEditManufsite = false;
  let canEditRequest = false;
  let canEditPoc = false;

  if (user.isSupplier || user.isStaff) {
    const isNewRequest = !request || !request.id;
    const isRequestEditable =
      request &&
      !request.isRenewal &&
      request.isPending &&
      (request.testStatus === "Pending" || request.testStatus === "Rejected");

    // allow on submissions and
    // allow to edit only until lab accepts request
    // allow to edit if admin
    if (user.isStaff || isNewRequest || (!user.isStaff && isRequestEditable)) {
      // TODO: Enable Modify mode version
      // (replaced above with just `user.isStaff`):
      // (user.isStaff && user.togglerEnabled)
      canEditManufsite = true;
      canEditRequest = true;
      canEditPoc = true;
    }
  }

  return {
    type: actions.SET_PERMISSIONS,
    payload: { canEditManufsite, canEditRequest, canEditPoc }
  };
}

export function mapLinkedRequests(availableRequests, requestLinkedList) {
  return {
    type: actions.MAP_LINKED_REQUESTS,
    payload: reqHelper.formatLinkedRequests(
      availableRequests,
      requestLinkedList
    )
  };
}

export function submitAuditResults({ request, testModule, manufSite }) {
  const reqArgs = args.getRequestAuditFields(request);
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(tests.sendAuditResults(testModule))
      .then(() => dispatch(updateSARRequest(reqArgs)))
      .then(() => dispatch(addresses.updateAddress(manufSite)))
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch({
          type: actions.SAVE_REQUEST,
          payload: { formSaved: true, isLoading: true }
        });
        dispatch({
          type: actions.CLEAR_CURR_SITE,
          payload: null
        });
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });
  };
}

export function auditReportDownload(document, request) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this

  // file name pattern: SAR<000000>_REPORT_<Slot>.<extension>
  // e.g. SAR000345_REPORT_3.pdf
  const fileName = `${request.tn}_REPORT_${slot}.${document.document.extension}`;
  const url = `/requesttests/${test}/reports/${slot}?download=1`;

  return fileDownload(fileName, url);
}

export function auditReportsDownloadAll(request) {
  // file name: SAR<000000>_REPORTS.zip
  // e.g. SAR000345_REPORTS.zip
  const fileName = `${request.tn}_REPORTS.zip`;
  const url = `/requests/${request.id}/reports?download=true`;

  return fileDownload(fileName, url);
}

export const updateAuditReportStatus = ({
  requestId,
  testId,
  reportSlot,
  reportStatus
}) => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(tests.changeReportStatus(testId, reportSlot, reportStatus))
    .then(res => {
      const { documentstatusid, report } = res.data.data;

      dispatch({
        type: actions.UPDATE_REPORT_STATUS,
        payload: {
          slot: report.slot,
          status: documentstatusid,
          testId
        }
      });

      return res;
    })
    .then(() => dispatch(getRequestDetails(requestId)))
    .then(() => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
    })
    .catch(err => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

      return Promise.reject(err);
    });
};
