import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RenderOptions from "./RenderOptions";
import ListRow from "./ListRow";
import CyberTable from "containers/CyberTable";
import {
  Pager,
  PagerPageSize,
  PagerStatusFilter,
  Divider,
  CounterLabel,
  LoaderButton,
  IconLabel
} from "ctia-ui";
import { Col, Row, Label, Table } from "react-bootstrap";
import UserStatusToggler from "containers/users/UsersList/UserStatusToggler";
import AddUser from "containers/users/UsersList/AddUser";
import { setPageSize, changeCyberAdminUserType } from "actions/app";
import { fetchUsers, clearUserList } from "actions/user";

class UsersList extends CyberTable {
  componentWillUnmount() {
    this.props.clearUserList();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.app.showOnlyActiveUsers !== this.props.app.showOnlyActiveUsers
    ) {
      setTimeout(this.fetchTableData, 150);
    }
  }

  onUserTypeChange = event => {
    const newUserType = Number(event.target.value);

    // update app filter settings
    this.props.changeCyberAdminUserType(newUserType);

    // fetch new list (waiting for redux storage update first)
    setTimeout(this.fetchTableData, 150);
  };

  fetchTableData = (
    pageNum = this.startPage,
    userType = this.props.app.cyberAdminUsers.userType,
    pageSize = this.props.app.pageSize
  ) =>
    this.props.fetchUsers(
      pageNum,
      pageSize,
      userType,
      this.props.app.showOnlyActiveUsers
    );

  setPagerSize = size => this.props.setPageSize(size);

  render() {
    const { users, app } = this.props;
    const { cyberAdminUsers } = app;
    const tableData = this.setTableData(users);
    const { page, pages, records } = this.setPagerData(users);
    const counter = this.setCounter(tableData, records);

    return (
      <div>
        <Row>
          <Col xs={6} md={8}>
            <h1>
              User Administration <Label>{cyberAdminUsers.title}</Label>
            </h1>
          </Col>
          <Col xs={6} md={4} style={{ textAlign: "right" }}>
            <UserStatusToggler />
          </Col>
        </Row>
        <div id="request-filter block-bottom-offset">
          <Row>
            <Col xs={4} md={4}>
              <CounterLabel counter={counter} title="Total Users" />
              <Link to="/admin/cp/users/search">
                <LoaderButton
                  bsStyle="info"
                  text={<IconLabel label="Search" awesomeIcon="search" />}
                  className="btn-shadow block-top-offset"
                />
              </Link>
              <AddUser />
              <Link to="/admin/cp/users/deleted">
                <LoaderButton
                  bsStyle="default"
                  text={
                    <IconLabel label="Deleted Users" awesomeIcon="user-slash" />
                  }
                  className="btn-shadow block-top-offset icon-left-offset"
                />
              </Link>
            </Col>
            <Col xs={8} md={4}>
              <PagerPageSize
                size={app.pageSize}
                onChangeHandler={this.onChangePageSize}
                options={[50, 100]}
                showAll={true}
              />
            </Col>
            <Col xs={12} md={4}>
              <PagerStatusFilter
                status={cyberAdminUsers.userType}
                statusOptions={<RenderOptions />}
                onStatusChange={this.onUserTypeChange}
                title="User Role"
              />
            </Col>
          </Row>
        </div>
        <Table striped condensed hover className="button-top-offset ctia-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Company</th>
              <th>E-mail</th>
              <th>Last Login</th>
              <th>Status</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <ListRow tableData={tableData} />
          </tbody>
        </Table>
        <Divider />
        <Pager moveTo={this.fetchTableData} totalPages={pages} page={page} />
      </div>
    );
  }
}

function mapStateToProps({ users, app }) {
  return { users, app };
}

export default connect(mapStateToProps, {
  setPageSize,
  changeCyberAdminUserType,
  fetchUsers,
  clearUserList
})(UsersList);
