import _ from "lodash";
import { restClient, restClientGeneral } from "libs/restClient";
import { notifyMessages, getDeleteDocumentResult } from "helpers/NotifyHelper";
import {
  prepareCyberInitialArgs,
  prepareEcoRequestArgs,
  getPreloaderStates
} from "helpers/ArgsCyberHelper";
import { docStatuses } from "helpers/DocumentStatus";
import { getLabDecisionStatus } from "helpers/RequestTestsHelper";
import { requestStatuses, getTransPrefix } from "helpers/RequestHelper";
import { apiUrl } from "dictionaries/Env";
import * as prepareArgs from "helpers/ArgsHelper";
import * as call from "helpers/ActionHelper";
import { getRequestCertType } from "helpers/BatteryCertHelper";
import * as actions from "actions/types";
import { updateDeviceModel } from "actions/model";
import { updateRequest } from "actions/request";
import { fetchRequestBillingInfo } from "actions/billing";
import { fetchRequestTests, fetchTestReports } from "actions/tests";
import { fileDownload } from "actions/uploads";

export const submitCyberInitialRequest = request => async dispatch => {
  // init submission stuff
  const submission = {
    error: false,
    modelId: null,
    requestId: null
  };

  const preloader = getPreloaderStates(request);

  // STEP 0: Set loading status for submit button
  dispatch({ type: preloader.start });

  // STEP 1: Submit device model
  try {
    const args = prepareCyberInitialArgs(request);
    const response = await restClient.post(args.url, JSON.stringify(args.body));

    const { id, devicemodelid, requests } = response.data.data;
    const newId = _.isArray(requests) ? requests[0].id : id;
    const deviceModelId = _.isArray(requests) ? id : devicemodelid;

    submission.modelId = deviceModelId;
    submission.requestId = newId;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantSubmitRequest
    });

    submission.error = true;
  }

  // STEP 2 (optional): sign license agreement
  if (!submission.error) {
    try {
      if (request.agreementAccepted) {
        const url = `/devicemodels/${submission.modelId}/licenseagreements`;
        await restClient.post(url);
      }
    } catch (e) {
      dispatch({
        type: actions.SHOW_NOTICE,
        payload: notifyMessages.cantSignAgreement
      });

      submission.error = true;
    }
  }

  if (!submission.error) {
    // STEP 3: Update request redux store with new id and agreement flag
    dispatch({
      type: actions.SUBMIT_INITIAL_REQUEST,
      payload: {
        newId: submission.requestId,
        deviceModelId: submission.modelId,
        agreementAccepted: request.agreementAccepted
      }
    });

    // STEP 4: Submit wizard
    dispatch({
      type: actions.SUBMIT_WIZARD,
      payload: { isSubmitted: true }
    });
  }

  // STEP 5: Remove loading status
  dispatch({ type: preloader.stop });
};

export const submitCyberECO = request => async dispatch => {
  // STEP 0: Set loading status for submit button
  dispatch({ type: actions.START_SUBMITTING });

  // STEP 1: Create new ECO request (API call + redux stuff)
  try {
    const args = prepareEcoRequestArgs(request);
    const response = await restClient.post("/requests", JSON.stringify(args));
    const { id, devicemodelid, ...rest } = response.data.data;

    // pass updated values to redux
    dispatch({
      type: actions.SUBMIT_ECO_REQUEST,
      payload: {
        ...rest,
        newId: id,
        deviceModelId: devicemodelid
      }
    });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantSubmitRequest
    });
  }

  // STEP 2: Submit wizard
  dispatch({
    type: actions.SUBMIT_WIZARD,
    payload: { isSubmitted: true }
  });

  // STEP 3: Remove loading status
  dispatch({ type: actions.STOP_SUBMITTING });
};

export const fetchRequest = id => async dispatch => {
  try {
    const url = `/requests/${id}/details`;
    const response = await restClient.get(url);
    dispatch({
      type: actions.FETCH_REQUEST_CYBER,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export function getRequestDetails(requestId) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());
    dispatch(fetchRequest(requestId)).then(quit).catch(quit);
  };
}

export function getAllCyber(requestId, user) {
  return dispatch => {
    // will keep request details later
    let details;

    dispatch(call.startPreloader());
    // fetch request details first
    dispatch(fetchRequest(requestId))
      .then(res => {
        details = res.data.data;

        // optional: fetch billing data
        // lab user doesn't need the billing at all
        if (!user.isLab && !user.isOperator) {
          dispatch(fetchRequestBillingInfo(details.billingid));
        }

        return res;
      })
      // get test data
      .then(() => dispatch(fetchRequestTests(requestId)))
      // get reports for test
      .then(res => {
        if (res.data.data.length) {
          const testModule = res.data.data[0];
          dispatch(fetchTestReports(testModule));
        }

        return res;
      })
      .then(() => dispatch(getUsersManualInfo(details.devicemodelid)))
      .then(() => dispatch(call.stopPreloader()))
      .catch(() => {
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
        dispatch(call.stopPreloader());
      });
  };
}

export function approveUserManual(modelId, decision) {
  const newDocStatus =
    decision === true
      ? docStatuses.approved.id
      : decision === false
      ? docStatuses.rejected.id
      : docStatuses.pending.id;

  const url = `/devicemodels/${modelId}/manual`;
  const body = prepareArgs.prepareDocumentApproveArgs(newDocStatus);

  const payload = restClient
    .put(url, body)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.UPDATE_USERS_MANUAL,
    payload
  };
}

export function makeLabDecision(decision, request) {
  const statusId = getLabDecisionStatus(decision);
  const url = `/requests/${request.id}/requesttests?statusid=${statusId}`;

  return {
    type: actions.MAKE_LAB_DECISION,
    payload: restClient.put(url)
  };
}

export const approveRequest = (requestId, approved) => async dispatch => {
  const newReqStatus =
    approved === true
      ? requestStatuses.approved
      : approved === false
      ? requestStatuses.requestWithdrawn
      : null;

  if (!newReqStatus) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantSubmitRequest
    });
  }

  try {
    const url = `/requests/${requestId}`;
    const body = prepareArgs.prepareRequestApproveArgs(newReqStatus);
    const response = await restClient.put(url, body);

    dispatch({ type: actions.UPDATE_REQUEST_STATUS, payload: response });

    const message = approved
      ? notifyMessages.requestApprovedSuccessfully
      : notifyMessages.requestWithdrawnSuccessfully;

    dispatch({ type: actions.SHOW_NOTICE, payload: message });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantUpdateRequest
    });
  }
};

export const fetchPocs = (
  companyId = null,
  silentFetch = true
) => async dispatch => {
  if (!silentFetch) {
    dispatch({ type: actions.START_PRELOADER });
  }

  const stop = () => {
    if (!silentFetch) {
      dispatch({ type: actions.STOP_PRELOADER });
    }
  };

  try {
    // companyid uses in case if someone want to fetch data for different company
    // (e.g. Staff viewing Vendor's request)
    const urlTail = companyId ? `&where=companyid=${companyId}` : "";
    const url = `/pocs?page=0${urlTail}`;
    const response = await restClientGeneral.get(url);

    dispatch({ type: actions.FETCH_POCS, payload: response.data.data });
    stop();

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantFetchPocs
    });

    stop();

    return Promise.reject(e);
  }
};

export const createNewPoc = (
  request,
  user,
  isUpdating = false
) => async dispatch => {
  const action = {
    reqId: request.id,
    pocId: ""
  };

  // STEP 1: Set loading status for full-page preloader
  dispatch({ type: actions.START_PRELOADER });

  // STEP 2: Make an API call (add new POC)
  try {
    const reqBody = prepareArgs.preparePocInfoArgs(request, user);
    const response = await restClientGeneral.post(
      "pocs",
      JSON.stringify(reqBody)
    );
    const payload = response.data.data.id;

    action.pocId = payload;

    dispatch({ type: actions.CREATE_NEW_POC, payload });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantCreatePoc
    });
  }

  // STEP 3: Depends on logic below
  // ==============================
  // STEP 3-1: Updating POC for existed request:
  if (isUpdating) {
    try {
      const reqBody = {
        args: { pocid: action.pocId }
      };

      // update pocid first
      if (request.isBattery) {
        const prefix = getTransPrefix(request.tn);
        await restClientGeneral.put(
          `/${prefix}/requests/${action.reqId}`,
          JSON.stringify(reqBody)
        );
      } else {
        await restClient.put(
          `/requests/${action.reqId}`,
          JSON.stringify(reqBody)
        );
      }

      // now refetch poclist
      try {
        const companyId = user.requestOwnerCompanyId;
        const urlTail = companyId ? `&where=companyid=${companyId}` : "";
        const url = `/pocs?page=0${urlTail}`;
        const response = await restClientGeneral.get(url);

        dispatch({ type: actions.FETCH_POCS, payload: response.data.data });
      } catch (e) {
        dispatch({
          type: actions.SHOW_NOTICE,
          payload: notifyMessages.cantFetchPocs
        });
      }

      dispatch({
        type: actions.SHOW_NOTICE,
        payload: notifyMessages.requestUpdatedSuccessfully
      });
    } catch (e) {
      dispatch({
        type: actions.SHOW_NOTICE,
        payload: notifyMessages.cantUpdateRequest
      });
    }
  }
  // STEP 3-2: Creating a brand new POC during submission:
  else {
    dispatch({ type: actions.SUBMIT_WIZARD_STEP });
  }

  // STEP 4: Disable preloader
  dispatch({ type: actions.STOP_PRELOADER });
};

export function usersManualDownload({ tn, model, usersManual }) {
  // file name pattern: CYBER000000_ProductDescription.extension
  // e.g. CYBER000345_ProductDescription.pdf
  const fileName = `${tn}_ProductDescription.${usersManual.document.extension}`;
  const url = `/devicemodels/${model.id}/manual?download=true`;

  return fileDownload(fileName, url);
}

export const getUsersManualInfo = modelId => async dispatch => {
  try {
    const url = `/devicemodels/${modelId}/manual`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.GET_USERS_MANUAL_INFO,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function documentDownload(document, request) {
  // file name pattern: CYBER000000_SupportDoc_Slot.extension
  // e.g. CYBER000345_SupportDoc_3.pdf
  const fileName = `${request.tn}_SupportDoc_${document.slot}.${document.document.extension}`;
  const url = `/requests/${request.id}/documents/${document.slot}?download=true`;

  return fileDownload(fileName, url);
}

export function documentDownloadAll(request) {
  // file name pattern: CYBER000000_SupportDocs.zip
  // e.g. CYBER000345_SupportDocs.zip
  const fileName = `${request.tn}_SupportDocs.zip`;
  const url = `/requests/${request.id}/documents?download=true`;

  return fileDownload(fileName, url);
}

export const deleteDocument = (requestId, slot) => async dispatch => {
  try {
    const url = `/requests/${requestId}/documents/${slot}`;
    const payload = await restClient.delete(url);

    dispatch({ type: actions.DELETE_DOCUMENT, payload: { ...payload, slot } });
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: getDeleteDocumentResult(payload.status)
    });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantDeleteFile
    });
  }
};

export const withdrawRequest = (
  id,
  reason = "",
  callback,
  prefix = ""
) => async dispatch => {
  try {
    const args = prepareArgs.prepareWithdrawnArgs(reason);
    const apiPrefix = prefix ? `/${prefix}` : "";

    const payload = await restClientGeneral.put(
      `${apiPrefix}/requests/${id}`,
      JSON.stringify(args)
    );

    dispatch({ type: actions.WITHDRAW_REQUEST, payload });
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.requestWithdrawnSuccessfully
    });

    callback();
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantWithdrawRequest
    });

    callback();
  }
};

export const unwithdrawRequest = (
  id,
  callback,
  prefix = ""
) => async dispatch => {
  try {
    const args = prepareArgs.prepareUnwithdrawnArgs();
    const apiPrefix = prefix ? `/${prefix}` : "";

    const payload = await restClientGeneral.put(
      `${apiPrefix}/requests/${id}`,
      JSON.stringify(args)
    );

    dispatch({ type: actions.UNWITHDRAW_REQUEST, payload });
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.requestUnwithdrawnSuccessfully
    });

    callback();
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantUnwithdrawRequest
    });

    callback();
  }
};

export function searchRequest(transactionNumber) {
  const url = `/requests?where=transactionnumber LIKE ${transactionNumber}`;
  const payload = restClient
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.SEARCH_REQUEST,
    payload
  };
}

export const fetchCertCategories = (activeOnly = true) => async dispatch => {
  try {
    let url = `${apiUrl}/certcategories${activeOnly ? "?where=active=1" : ""}`;

    const response = await restClient.get(url);

    dispatch({
      type: actions.FETCH_CERT_CATEGORIES,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));
    return Promise.reject(e);
  }
};

export function invoiceDownload({ tn, invoice }) {
  // file name pattern: CYBER000000_Invoice.pdf
  // e.g. CYBER000345_Invoice.pdf
  const fileName = `${tn}_Invoice.pdf`;
  const url = `/invoices/${invoice.id}?pdf=true`;

  return fileDownload(fileName, url);
}

export function updateDeviceInfo(request) {
  const deviceModel = prepareArgs.prepareDeviceModelArgs(request);
  const hwSwArgs = prepareArgs.prepareHwSwArgs(request);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(updateDeviceModel(deviceModel))
      .then(() => dispatch(updateRequest(hwSwArgs)))
      .then(() => {
        dispatch(call.forceRequestUpdate());
        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

export function updateRequestPOC(request) {
  const urlPrefix = getRequestCertType(request);
  const reqFields = {
    urlPrefix,
    id: request.id,
    pocid: request.pocId
  };

  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(updateRequest(reqFields))
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(successMsg));
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(failureMsg));
      });
  };
}

export function updateRequestLab(request) {
  const urlPrefix = getRequestCertType(request);
  const reqFields = {
    urlPrefix,
    id: request.id,
    primarylabid: request.cdmaLab
  };
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(updateRequest(reqFields))
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(successMsg));
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(failureMsg));
      });
  };
}

export function fetchModelList(vendorId) {
  const url = `/devicemodels?page=0&select=id,modelnumber&where=cyberverified=1 AND vendorid=${vendorId}`;

  const models = restClient
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
  return {
    type: actions.FETCH_MODEL_LIST,
    payload: models
  };
}

export function fetchCyberModels(vendorId) {
  return call.dispatchWithPreloader(fetchModelList, vendorId);
}
