import { Component } from 'react';
import { shouldUpdate } from 'helpers/FormHelper';

class CyberComponent extends Component {
  shouldComponentUpdate(nextProps) {
    return shouldUpdate(nextProps);
  }
}

export default CyberComponent;
