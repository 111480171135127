import * as actions from 'actions/types';

export default function(state = null, action) {
  switch (action.type) {
    case actions.COUNT_APPROVE_WAITING_REQUESTS:
      return {
        ...state,
        approveCount: action.payload,
        updatingApproveCount: false
      };
    case actions.FETCH_REQUEST_CYBER:
    case actions.FETCH_REQUEST_OTA:
    case actions.FETCH_CERTIFICATION_REQUESTS:
      return { ...state, updatingApproveCount: true };
    case actions.LOGOUT:
      return null;
    default:
      return state;
  }
}
