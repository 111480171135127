import React from 'react';
import { connect } from 'react-redux';
import InitialBar from 'containers/request/InfoBar/cyber/CyberInitialInfoBar';
import EcoBar from 'containers/request/InfoBar/cyber/CyberEcoInfoBar';

const CyberInfoBarContainer = ({ request, wizard }) => {
  if (!request) return null;
  if (request.formSaved) return null;

  // cyber submission requires to not render it on 1st step
  if (wizard && wizard.step === 1) return null;

  if (request.isInitial) {
    return <InitialBar />;
  }

  if (request.isEco) {
    return <EcoBar />;
  }

  return null;
};

function mapStateToProps({ request, wizard }) {
  return { request, wizard };
}

export default connect(mapStateToProps)(CyberInfoBarContainer);
