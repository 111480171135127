/**
 * Sort an array of POC by the name field
 *
 * Compare uppercase name field
 *
 * @param {array} arr array of POC objects
 * @param {string} field object property: firstname or lastname
 * @returns {string | undefined} return array of sorted POC by name if array is defined
 */
export const sortByFieldname = (arr, field) => {
  if (!arr) return undefined;
  if (!Array.isArray(arr)) return undefined;

  // need to clone the array first
  // to prevent that source array mutation
  const clonedArr = [...arr];

  return clonedArr.sort((a, b) => {
    const nameA = String(a[field]).toUpperCase();
    const nameB = String(b[field]).toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
};

/**
 * Sort pocs by first then last name
 *
 * @param {array} pocs array of poc objects to be sorted
 * @returns {array} Sorted array of poc objects
 */
export const sortPocs = pocs => {
  // sort by full name first
  const sortedPocs = sortByFieldname(pocs, "lastname");

  // then sort by last name
  return sortByFieldname(sortedPocs, "fullname");
};

/**
 * Switch between ASC and DESC order by strings
 *
 * @param {string} order Order by string to use in query.
 */
export const toggleOrder = (order, lowercase = false) => {
  const toggledOrder = order.toUpperCase() === "DESC" ? "ASC" : "DESC";
  return lowercase ? toggledOrder.toLowerCase() : toggledOrder;
};
