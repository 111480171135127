import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";
import moment from "moment";
import FormFieldLabel from "components/form/FormFieldLabel";
import StateStaticReadonly from "components/form/StateStaticReadonly";
import DatePicker from "components/form/Date";
import { formatDate } from "helpers/DateHelper";
import {
  clearCountryState,
  fetchCountries,
  fetchCountryStates
} from "actions/countries";

const AddServiceCenterForm = ({
  clearCountryState,
  fetchCountries,
  fetchCountryStates,
  countries
}) => {
  const [authDate, setAuthDate] = useState(moment());
  const [showAuthDate, setShowAuthDate] = useState(false);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const hasCountries = countries
    ? _.has(countries, "list") && !_.isEmpty(countries.list)
    : false;
  const hasCountryStates = countries
    ? _.has(countries, "states") && !_.isEmpty(countries.states)
    : false;

  return (
    <>
      <Form.Group controlId="orgName">
        <Form.Label>
          <FormFieldLabel label="Name" isRequired={true} />
        </Form.Label>
        <Form.Control required placeholder="Enter organization name" />
      </Form.Group>
      <Form.Group controlId="address1">
        <Form.Label>
          <FormFieldLabel label="Address 1" isRequired={true} />
        </Form.Label>
        <Form.Control required placeholder="Enter Address line 1" />
      </Form.Group>
      <Form.Group controlId="address2">
        <Form.Label>
          <FormFieldLabel label="Address 2" />
        </Form.Label>
        <Form.Control placeholder="Enter Address line 2" />
      </Form.Group>
      <Form.Group controlId="address3">
        <Form.Label>
          <FormFieldLabel label="Address 3" />
        </Form.Label>
        <Form.Control placeholder="Enter Address line 3" />
      </Form.Group>
      <Form.Group controlId="country">
        <Form.Label>
          <FormFieldLabel label="Country" isRequired={true} />
        </Form.Label>
        <Form.Control
          as="select"
          disabled={!hasCountries}
          onChange={e => {
            const countryCode = e.target.value;

            if (countryCode === "US" || countryCode === "CA") {
              fetchCountryStates(countryCode);
            } else {
              clearCountryState();
            }
          }}
        >
          <option value="none">--- Select One ---</option>
          {hasCountries &&
            countries.list.map(country => {
              return (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              );
            })}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="city">
        <Form.Label>
          <FormFieldLabel label="City" isRequired={true} />
        </Form.Label>
        <Form.Control required placeholder="Enter City" />
      </Form.Group>
      <Form.Group controlId="state">
        <Form.Label>
          <FormFieldLabel label="State/Province" isRequired={true} />
        </Form.Label>
        {hasCountryStates ? (
          <Form.Control required={true} as="select">
            <option value="none">--- Select One ---</option>
            {countries.states.map(state => {
              return (
                <option key={state.id} value={state.short}>
                  {state.name}
                </option>
              );
            })}
          </Form.Control>
        ) : (
          <StateStaticReadonly />
        )}
      </Form.Group>
      <Form.Group controlId="zip">
        <Form.Label>
          <FormFieldLabel label="Zip/Postal Code" isRequired={true} />
        </Form.Label>
        <Form.Control required placeholder="Enter Zip or Postal Code" />
      </Form.Group>
      <hr />
      <Form.Group controlId="status">
        <Form.Label>
          <FormFieldLabel label="ASC Status" isRequired={true} />
        </Form.Label>
        <Form.Control
          as="select"
          onChange={e => {
            setShowAuthDate(e.target.value === "Active");
          }}
        >
          <option value="none">--- Select One ---</option>
          <option>Active</option>
          <option>Expired</option>
          <option>N/A</option>
        </Form.Control>
      </Form.Group>
      {showAuthDate && (
        <Form.Group controlId="authDate">
          <Form.Label>
            <FormFieldLabel label="ASC Authorization Date" isRequired={true} />
          </Form.Label>
          <DatePicker
            value={authDate}
            editable={true}
            onChange={momentDate => setAuthDate(momentDate)}
          />
          <input type="hidden" id="authDate" value={formatDate(authDate)} />
        </Form.Group>
      )}
      <Form.Group controlId="level">
        <Form.Label>
          <FormFieldLabel label="Authorized Level" isRequired={true} />
        </Form.Label>
        <Form.Control as="select">
          <option value="none">--- Select One ---</option>
          <option value={1}>Level 1</option>
          <option value={2}>Level 2</option>
          <option value={3}>Level 2 - Chromebook Repair</option>
        </Form.Control>
      </Form.Group>
    </>
  );
};

function mapStateToProps({ countries }) {
  return { countries };
}

export default connect(mapStateToProps, {
  clearCountryState,
  fetchCountries,
  fetchCountryStates
})(AddServiceCenterForm);
