import React from "react";
import { connect } from "react-redux";
import UpdateInvoiceDate from "containers/request/Payments/UpdateInvoiceDate";

const UpdateInvoiceDateButton = ({ user, request }) => {
  // action not available if not in modify mode or invoice already paid
  if (!user.isStaff || !user.canEditRequest || request.invoice.paid === 1)
    return null;

  return <UpdateInvoiceDate />;
};

function mapStateToProps({ user, request }) {
  return { user, request };
}

export default connect(mapStateToProps)(UpdateInvoiceDateButton);
