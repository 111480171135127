import { restClient, restClientGeneral } from "libs/restClient";
import * as actions from "actions/types";
import * as form from "libs/form";
import * as call from "helpers/ActionHelper";
import * as args from "helpers/ArgsBatteryHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { makeSimpleArgs } from "helpers/ArgsHelper";
import { getOppositeIEEE } from "helpers/BatteryCertHelper";
import * as sarAddresses from "actions/battery/sarAddresses";
import * as sar from "actions/battery/sar";

/**
 *
 * @param {*} ieee Get sites flagged for given ieee type.
 * @param {*} exclusive Exclude sites flagged for other ieee types.
 */
export const simpleFetchManufSites = (
  ieee,
  companyId = null,
  exclusive = false
) => async dispatch => {
  try {
    const ieeeClause = `ieee${ieee}=1${
      exclusive ? ` AND ieee${getOppositeIEEE(ieee)}=0` : ""
    }`;
    const companyClause = companyId ? ` AND companyid=${companyId}` : "";
    const whereClause = `${ieeeClause} ${companyClause}`;
    const url = `manufsites?page=0&orderBy=name ASC&where=${whereClause}`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.FETCH_MANUF_SITES,
      payload: payload.data
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchManufSites = (ieee, isOpposite) => async dispatch => {
  try {
    // need to filter sites by opposite IEEE value:
    const ieeeFlag = isOpposite ? 0 : 1;

    // define opposite IEEE type
    const oppositeType = getOppositeIEEE(ieee);
    const oppositeFlag = ieeeFlag === 0 ? 1 : 0;

    const url = `manufsites?orderBy=name ASC&where=ieee${ieee}=${ieeeFlag} AND ieee${oppositeType}=${oppositeFlag}&page=0`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.FETCH_MANUF_SITES,
      payload: payload.data
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function simpleGetManufSites(ieee, companyId, exclusive = false) {
  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());

    return dispatch(simpleFetchManufSites(ieee, companyId, exclusive))
      .then(() => dispatch(sarAddresses.fetchAddresses(companyId)))
      .then(() => dispatch(call.stopPreloader()))
      .catch(quit);
  };
}

export function getManufSites(ieee, companyId, isOpposite = false) {
  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());

    return dispatch(fetchManufSites(ieee, isOpposite))
      .then(() => dispatch(sarAddresses.fetchAddresses(companyId)))
      .then(() => dispatch(call.stopPreloader()))
      .catch(quit);
  };
}

export function handleInputChange(event) {
  return {
    type: actions.HANDLE_SITE_FIELD,
    payload: form.getInputValue(event)
  };
}

export function clearCurrSite() {
  return {
    type: actions.CLEAR_CURR_SITE,
    payload: null
  };
}

export const createManufSite = manufSite => async dispatch => {
  try {
    const reqBody = args.prepareManufSiteArgs(manufSite);
    const response = await restClient.post(
      "manufsites",
      JSON.stringify(reqBody)
    );

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantCreateManufSite));

    return Promise.reject(e);
  }
};

export function updateManufSite(addressInfo, request) {
  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());

    // TODO: get rid of this hack
    const fakeReq = {
      manufSiteId: request.manufSiteId,
      manufSiteName: addressInfo.manufSiteName
    };

    return dispatch(sarAddresses.updateAddress(addressInfo))
      .then(() => dispatch(updateManufSiteName(fakeReq)))
      .then(() => dispatch(sar.updateSARRequest(request)))
      .then(res => {
        dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
        dispatch(call.stopPreloader());

        return res;
      })
      .catch(quit);
  };
}

export function mapCurrentAddress(siteAddressData) {
  return {
    type: actions.GET_ADDRESS_DETAILS,
    payload: siteAddressData
  };
}

export const updateManufSiteName = request => async dispatch => {
  try {
    const siteName = request.manufSiteName;
    const reqBody = makeSimpleArgs({
      name: siteName,
      description: siteName
    });
    return await restClient.put(
      `/manufsites/${request.manufSiteId}`,
      JSON.stringify(reqBody)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantCreateManufSite));

    return Promise.reject(e);
  }
};

export async function withdrawSite({
  certTypeId,
  manufSiteId,
  withdrawReason
}) {
  const params = `?certtypeid=${certTypeId}&withdrawreason=${withdrawReason}`;
  const url = `/manufsites/${manufSiteId}/withdraw${params}`;

  try {
    const response = await restClientGeneral.put(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function unwithdrawSite({
  certTypeId,
  manufSiteId
}) {
  const params = `?certtypeid=${certTypeId}`;
  const url = `/manufsites/${manufSiteId}/unwithdraw${params}`;

  try {
    const response = await restClientGeneral.put(url);
    return response;
  } catch (error) {
    throw error;
  }
}