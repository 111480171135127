import React from "react";
import { IconLabel } from "ctia-ui";
import { FlagIcon } from "react-flag-kit";
import BipolarBadge from "components/Badges/BipolarBadge";
import Copy from "components/form/buttons/Copy";
import { formatInvoiceDate } from "helpers/DateHelper";

export const renderAgreementBagde = isSigned => {
  return (
    <BipolarBadge
      label={isSigned ? "Signed" : "Incomplete"}
      positive={isSigned}
    />
  );
};

export const renderInvoiceBagde = (isPaid, paidDate, invoiceNumber) => {
  const label = isPaid ? formatInvoiceDate(paidDate) : "No";

  return (
    <>
      <div>
        Invoice Paid: <BipolarBadge label={label} positive={isPaid} />
      </div>
      {invoiceNumber ? (
        <div style={{ display: "inline" }}>
          <Copy text={invoiceNumber}>
            <small>
              <IconLabel awesomeIcon="copy" label={invoiceNumber} />
            </small>
          </Copy>
        </div>
      ) : null}
    </>
  );
};

export const renderNameWithFlag = (name = "n/a", flagCode, size = 22) => {
  return (
    <>
      {flagCode && (
        <>
          <FlagIcon code={flagCode} size={size} />
          &nbsp;
        </>
      )}
      {name}
    </>
  );
};
