import React from 'react';
import { Badge } from 'ctia-react-bootstrap-v4';
import { getTypeTitle, badgeTypes } from 'helpers/AdapterSupplierHelper';

const AdapterTypeBadge = ({ type }) => {
  if (!type) return null;

  return <Badge variant={badgeTypes[type]}>{getTypeTitle(type)}</Badge>;
};

export default AdapterTypeBadge;
