import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Bubble, InlinePreloader } from "ctia-ui";
import {
  fetchApprovedAdapterSuppliers,
  fetchApprovedRecognitionSuppliers
} from "actions/suppliers";
import { setValue } from "actions/request";
import SupplierSelector from "containers/request/battery/AdapterSupplier/AdapterSupplierSelector";
import AdapterSupplierList from "containers/request/battery/AdapterSupplier/AdapterSupplierList";

const validationMsg =
  "Enter the first adapter in the system. Please choose one of the following options:";

const AdapterSupplierContainer = ({
  request,
  setValue,
  fetchApprovedAdapterSuppliers,
  fetchApprovedRecognitionSuppliers
}) => {
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (request && request.ieee && !touched) {
      setTouched(true);
      setLoading(true);
      fetchApprovedAdapterSuppliers(request.ieee)
        .then(() => fetchApprovedRecognitionSuppliers(request.ieee))
        .then(() => setLoading(false));
    }
  }, [
    request,
    touched,
    fetchApprovedAdapterSuppliers,
    fetchApprovedRecognitionSuppliers
  ]);

  // set "No Adapters" by default if no pedning/recognized adapters submitted
  useEffect(() => {
    if (!touched) {
      const hasNoSuppliers =
        request.adapterSuppliers && request.adapterSuppliers.length === 0;
      const isEcoSubmission = !request.id && request.isEco;

      if (hasNoSuppliers && (isEcoSubmission || request.id > 0)) {
        setValue("adapterType", 4);
        setTouched(true);
      }
    }
  }, [touched, request.id, request.isEco, request.adapterSuppliers, setValue]);

  const noAdapter = request.adapterType && Number(request.adapterType) === 4;

  const showWarning = noAdapter
    ? false
    : !request.adapterSuppliers || _.isEmpty(request.adapterSuppliers);

  return (
    <>
      {showWarning && (
        <Bubble icon="alert" type="warning">
          {validationMsg}
        </Bubble>
      )}
      <AdapterSupplierList />
      {loading ? <InlinePreloader /> : <SupplierSelector />}
    </>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  setValue,
  fetchApprovedAdapterSuppliers,
  fetchApprovedRecognitionSuppliers
})(AdapterSupplierContainer);
