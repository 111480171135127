import React from "react";
import { connect } from "react-redux";
import { Row, Container } from "ctia-react-bootstrap-v4";
import cards from "containers/ControlPanel/cards";
import CpCard from "containers/ControlPanel/CpCard";
import "components/ProgramSelector/style.css";

const ControlPanelCards = ({ app, user, history, changeCertProgram }) => {
  const fluid = app.sm ? "fluid" : "";

  const rowSettings = {
    xl: 3,
    lg: 3,
    md: 3,
    sm: 12
  };

  return (
    <Container {...fluid}>
      <Row {...rowSettings} style={{ marginTop: 0 }}>
        <div className="home-description">
          <h1>CTIA Admin Panel</h1>
          <p>Select an action below.</p>
        </div>
      </Row>
      <Row {...rowSettings} style={{ marginTop: 25 }}>
        {cards.map(card => {
          return <CpCard card={card} key={card.id} />;
        })}
      </Row>
    </Container>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps)(ControlPanelCards);
