import React from "react";
import { Badge } from "ctia-react-bootstrap-v4";
import { getDocStatus } from "helpers/DocumentStatus";

const DocumentStatusBadge = ({ doc }) => {
  if (!doc || !doc.document) return null;

  const { title, variant } = getDocStatus(doc.document.documentstatusid);

  return <Badge variant={variant}>{title}</Badge>;
};

export default DocumentStatusBadge;
