import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default ({ component: C, props: cProps, role: routeRole, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!cProps.user || cProps.user.role === null) {
        // return <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
        return <Redirect to="/" />;
      }

      if (cProps.user.role !== routeRole) {
        return <Redirect to="/" />;
      }

      return <C {...props} {...cProps} />;
    }}
  />
);
