import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestModelName from "components/request/RequestModelnameSimple";
import RequestHWVersion from "components/request/RequestHWVersion";
import RequestSWVersion from "components/request/RequestSWVersion";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import RequestParentLink from "components/request/RequestParentLink";
import { initBLC } from "actions/battery/blcRequest";
import RequestOperatingSystem from "components/request/RequestOperatingSystem";
import BatteryPackReadonly from "containers/request/battery/BatterySupplier/BatteryPackReadonlyBLC";
import SelectDeviceType from "containers/request/BLC/RequestDeviceTypeBLC";

const DeviceInfoLabBLC = ({ app, request, user, initBLC }) => {
  useEffect(() => {
    initBLC(request.devicemodelid);
  }, [initBLC, request.devicemodelid]);

  return (
    <div>
      <Instructions />
      <Form horizontal>
        {request.isEco && <RequestParentLink />}
        <RequestManufacturer />
        <RequestModelName />
        <hr />
        <SelectDeviceType editable='false'/>
        <RequestHWVersion />
        <RequestSWVersion />
        <RequestOperatingSystem />
        <SubmissionDetails />
        <hr />
        <BatteryPackReadonly />
      </Form>
    </div>
  );
};

function mapStateToProps({ app, request, user }) {
  return { app, request, user };
}

export default connect(mapStateToProps, {
  initBLC
})(DeviceInfoLabBLC);
