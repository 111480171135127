import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { LoaderButton } from "ctia-ui";
import TestResultsInfoBarBLC from "containers/request/battery/TestResults/TestResultsInfoBarBLC";
import TestResultPanelBLC from "components/TestResultPanelBLC";
import PageTitle from "components/RequestPageTitle";
import LabResultFormBLC from "containers/testResult/LabResultFormBLC";
import LabResultUploadBLC from "containers/testResult/LabResultUploadBLC";
import UnderReviewViewBLC from "containers/testResult/UnderReviewViewBLC";
import GoBackButton from "components/form/buttons/GoBackButton";
import { hasTestReportsBLC, isComplete } from "helpers/RequestTestsHelper";
import {
  clearRequest,
  setBatteryRequestPermissions,
  attachAppCertTypes
} from "actions/request";
import {
  submitTestResultsBLC,
  getDetailsBLC,
  setLabTestPermissionsBLC,
  resetRequestPermissionsForLabBLC,
  getOperatingSystems
} from "actions/battery/blcRequest";
import { downloadReportBLC } from "actions/battery/blcRequest";
import { getBSCPackRequests } from "actions/battery/blcRequest";

const backURL = "/blc/lab/results";

class TestResultsItemBLC extends Component {
  state = { showContent: false };

  fetchHandler = reqId => {
    return this.props.getDetailsBLC(reqId).then(() => {
      console.log(this.props)
      const { request, user } = this.props;
      if (request.devicemodelid)
        this.props.getBSCPackRequests(request.devicemodelid);
      this.props.setBatteryRequestPermissions(request, user);
      this.props.setLabTestPermissionsBLC();
      this.activateRender();
    });
  };

  componentDidMount() {
    this.resetRender();
    this.props.attachAppCertTypes(this.props.app);
    this.props.getOperatingSystems();
    this.fetchHandler(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const newId = nextProps.match.params.id;
    if (newId !== this.props.match.params.id) {
      this.fetchHandler(newId);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
    this.props.resetRequestPermissionsForLabBLC();
  }

  resetRender = () => {
    this.setState({ showContent: false });
  };

  activateRender = () => {
    this.setState({ showContent: true });
  };

  onValidate() {
    const { request, tests } = this.props;
    const { testResults, hwVersion, swVersion, operatingSystem, deviceType } = request;
    const { reports, requesttestpassedstatusid, planversion } = tests[0];

    const commonFieldsValidate =
      requesttestpassedstatusid > 0 &&
      operatingSystem > 0 &&
      planversion !== "" &&
      hwVersion !== "" &&
      swVersion !== "" &&
      deviceType !== "";

    if (!commonFieldsValidate) return false;

    return hasTestReportsBLC(reports, testResults);
  }

  onSubmit = () => {
    const { request, tests, submitTestResultsBLC } = this.props;
    console.log(request);
    return submitTestResultsBLC(request, tests[0]);
  };

  onDone = () => {
    this.props.history.push(backURL);
  };

  render() {
    const { request, tests, downloadReportBLC } = this.props;

    if (_.isNull(request) || _.isNull(tests) || !request.id) {
      return null;
    }

    if (!this.state.showContent) return null;

    if (request.formSaved) {
      return (
        <div>
          <h1 className="block-bottom-offset">Uploading Test Results</h1>
          <TestResultPanelBLC clientType="general" certType="blc" />
          <LoaderButton text="Done" onClick={this.onDone} disabled={false} />
        </div>
      );
    }

    const testModule = { ...tests[0] };
    const completed = isComplete(request.testStatus);

    return (
      <div>
        <PageTitle />
        <GoBackButton dest={backURL} label="Return to list" />
        <TestResultsInfoBarBLC />
        <UnderReviewViewBLC
          show={completed}
          testModule={testModule}
          request={request}
        />
        <LabResultFormBLC show={!completed} testModule={testModule} />
        <hr />
        <LabResultUploadBLC
          show={!completed}
          downloadHandler={downloadReportBLC}
          fetchHandler={this.fetchHandler}
        />
        <Row>
          <Col className="text-center">
            <LoaderButton
              bsStyle="info"
              bsSize="large"
              text="Save changes"
              loadingText="Uploading..."
              onClick={this.onSubmit}
              isLoading={request.isLoading}
              disabled={!this.onValidate()}
              show={!completed}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ app, request, tests }) {
  return { app, request, tests };
}

export default connect(mapStateToProps, {
  clearRequest,
  getDetailsBLC,
  submitTestResultsBLC,
  downloadReportBLC,
  setBatteryRequestPermissions,
  setLabTestPermissionsBLC,
  attachAppCertTypes,
  resetRequestPermissionsForLabBLC,
  getOperatingSystems,
  getBSCPackRequests
})(TestResultsItemBLC);
