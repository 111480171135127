import _ from "lodash";
import { getRequestCertType } from "helpers/BatteryCertHelper";

export const supportDocsCount = 3;
export const testReportsCount = 6;
export const testReportsCountBLC = 2;

export const blcReportSlot1 = "Machine Readable Test Report";
export const blcReportSlot2 = "DUT Details Document";
export const blcReportSlot3 = "Battery Life Test Report";

// @NOTE: for more details check `https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types`
export const excelMimeTypes = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];
export const wordPDFMimeTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];

/**
 * Create new array for documents.
 *
 * By default it has length of 3, but you can pass your own size.
 *
 * @param {number} amount Array length
 * @returns array Array of doc objects
 */
export const fillDocsArray = (amount = supportDocsCount) => {
  let slotCounter = 0;
  return Array.apply(undefined, Array(amount)).map(() => {
    slotCounter++;
    return {
      file: null,
      title: "",
      slot: slotCounter
    };
  });
};

/**
 * Create formatted support docs array for request.
 *
 * @param {number} amount Array length
 * @param {array} docArray Documents list to be added to each entry
 * @returns array Support docs as an array
 */
export const fillUploadedDocsArray = (
  amount = supportDocsCount,
  docArray = []
) => {
  const sortedArr = _.mapKeys(docArray, "slot");
  let slotCounter = 0;
  return Array.apply(undefined, Array(amount)).map(() => {
    slotCounter++;

    const doc = _.isUndefined(sortedArr[slotCounter])
      ? null
      : sortedArr[slotCounter];

    return {
      file: null,
      title: doc && doc.document ? doc.document.title : "",
      slot: doc ? doc.slot : slotCounter,
      docId: doc ? doc.documentid : 0,
      ...doc
    };
  });
};

/**
 * Collect data regarding to current upload queue based on passed documents list.
 *
 * @param {array} docList Document list as an array
 * @returns array Filtered document list or empty array
 */
export const getUploadQueue = docList => {
  return _.filter(docList, doc => {
    const title = String(doc.title);
    return doc.file !== null && title.length > 0;
  });
};

/**
 * Checks whether an uplod queue is empty.
 *
 * @param {array} docList Document list for upload as an array
 * @returns boolean Whether an upload queue is empty
 */
export const hasDocsForUpload = docList => {
  return getUploadQueue(docList).length > 0;
};

/**
 * Filters only uploaded documents from passed list.
 *
 * @param {array} docList Uploaded documents list
 * @returns array Filtered list or empty array
 */
export const getUploadedTitlesQueue = docList => {
  return _.filter(docList, doc => {
    const title = doc.title ? String(doc.title) : "";
    return doc.id > 0 && title.length > 0;
  });
};

/**
 * Whether a passed list has uploaded documents.
 *
 * @param {array} docList Uploaded documents list
 * @returns boolean
 */
export const hasTitlesForChange = docList => {
  return getUploadedTitlesQueue(docList).length > 0;
};

/**
 * Create file with passed name and download it using browser tools.
 *
 * @param {object} fileData Blob object with file data
 * @param {string} fileName Name for a file will be download
 * @returns nothing
 */
export const downloadFile = (fileData, fileName) => {
  if (!fileData || !fileName) throw new Error("Nothing to download");

  const blob = new Blob([fileData]);

  // IE11 doesn't work with Blob on the fly
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }
};

/**
 * Creates and pre-fill an array for test reports.
 *
 * @param {number} amount Amount of reports to fill an array
 * @returns array
 */
export const fillReportsArray = (amount = testReportsCount) => {
  let slotCounter = 0;
  return Array.apply(undefined, Array(amount)).map(() => {
    slotCounter++;
    return {
      status: 0,
      title: "",
      file: null,
      slot: slotCounter
    };
  });
};

/**
 * Creates and pre-fill an array for BLC test reports.
 *
 * @returns array
 */
export const fillReportsArrayBLC = () => {
  console.log('hit');
  return [
    {
      status: 0,
      title: blcReportSlot1,
      file: null,
      slot: 1
    },
    {
      status: 0,
      title: blcReportSlot2,
      file: null,
      slot: 2
    },
    {
      status: 0,
      title: blcReportSlot3,
      file: null,
      slot: 3
    },
    {
      status: 0,
      title: "",
      file: null,
      slot: 4
    },
    {
      status: 0,
      title: "",
      file: null,
      slot: 5
    },
    {
      status: 0,
      title: "",
      file: null,
      slot: 6
    }
  ];
};

/**
 * Whether any files uploading at the moment.
 *
 * @param {array} uploads Uploads queue
 * @returns boolean
 */
export const hasPendingUploads = uploads => {
  return _.some(uploads, "pending");
};

/**
 * Whether server response failed.
 *
 * @param {object} response Server response data
 * @returns boolean
 */
export const hasResponseFailed = response => {
  return !_.isUndefined(response) && _.includes([415, 500], response.status);
};

/**
 * Returns error text description in case of bad response.
 *
 * @param {object} response Server response data
 * @returns string || null
 */
export const getResponseError = response => {
  if (response && response.status !== 201) {
    return response.statusText;
  }
  return null;
};

export const manageQueue = (
  options,
  uploadHandler,
  addHandler,
  removeHandler
) => {
  const { queueId, slot, title } = options;

  // add uploading request to queue
  addHandler({ queueId, slot, title });

  // remove uploaded slot from queue
  const queueCallback = res => {
    let error;
    let errorMessage;

    const { config, response, isAxiosError } = res.payload;
    const isBLCReport = _.includes(config.url, "blc/requesttests/");

    if (isBLCReport && isAxiosError) {
      error = true;
      errorMessage = response.data.exception;
    } else {
      error = hasResponseFailed(response);
      errorMessage = getResponseError(response);
    }

    removeHandler({ queueId, slot, title, error, errorMessage });
  };

  // upload, after remove uploaded slot from queue
  uploadHandler(options).then(queueCallback);
};

/**
 * Creates an object of options for Users Manual document.
 *
 * @param {object} usersManual Uploading file object
 * @param {number} modelId Request's model id
 * @returns object
 */
export const prepareUsersManualOptions = (usersManual, modelId) => {
  return {
    modelId,
    queueId: `pd-model-${modelId}`,
    slot: 0,
    title: "Product Description",
    fileContent: usersManual ? usersManual.file : null
  };
};

export const prepareSupDocOptions = ({ ...params }) => {
  const { slot, title, requestId, file, isUpdate, request } = params;
  const certType = getRequestCertType(request);
  const clientType = certType ? "general" : "";

  return {
    slot,
    title,
    queueId: `supdoc-${requestId}-slot-${slot}`,
    fileContent: file,
    requestId,
    sendEmail: isUpdate,
    certType,
    clientType
  };
};

export const fillUploadedTestReports = (
  amount = testReportsCount,
  docArray = []
) => {
  const sortedArr = _.mapKeys(docArray, "slot");

  let slotCounter = 0;

  return Array.apply(undefined, Array(amount)).map(() => {
    slotCounter++;

    const doc = _.isUndefined(sortedArr[slotCounter])
      ? null
      : sortedArr[slotCounter];

    return {
      file: null,
      title: doc ? doc.document.title : "",
      slot: doc ? doc.slot : slotCounter,
      docId: doc ? doc.documentid : 0,
      ...doc
    };
  });
};

export const hasExcelFormat = userFile => {
  if (!userFile || !userFile.type) {
    console.warn("Passed file doesn't exist or have type");
    return false;
  }

  return _.includes(excelMimeTypes, userFile.type);
};

export const hasWordPDFFormat = userFile => {
  if (!userFile || !userFile.type) {
    console.warn("Passed file doesn't exist or have type");
    return false;
  }

  return _.includes(wordPDFMimeTypes, userFile.type);
};

export const getBlcReportTitle = (doc, slot) => {
  if(slot === 3) {
    return doc ? doc.document.title : blcReportSlot3;
  }

  if (slot > 3) {
    return doc ? doc.document.title : "";
  }

  return slot === 1 ? blcReportSlot1 : slot === 2 ? blcReportSlot2 : "n/a";
};

export const fillUploadedDocsArrayBLC = (docArray = []) => {
  const sortedArr = _.mapKeys(docArray, "slot");
  let slotCounter = 0;
  console.log(sortedArr);
  return Array.apply(undefined, Array(testReportsCount)).map(() => {
    slotCounter++;

    const doc = _.isUndefined(sortedArr[slotCounter])
      ? null
      : sortedArr[slotCounter];

    const slot = doc ? doc.slot : slotCounter;

    const title = getBlcReportTitle(doc, slot);

    return {
      slot,
      title,
      file: null,
      docId: doc ? doc.documentid : 0,
      ...doc
    };
  });
};
