import React from "react";
import { connect } from "react-redux";
import { WithModal, IconLabel } from "ctia-ui";
import ConfirmPaymentModalATL from "containers/ControlPanel/Payments/ConfirmPaymentATL/ConfirmPaymentModalATL";
import { showNotice } from "actions/notifier";
import { notifyMessages } from "helpers/NotifyHelper";

class ConfirmPaymentATL extends WithModal {
  modalHandler = () => {
    const { catl, showNotice } = this.props;

    if (catl.isBackgroundJobRunning) {
      return showNotice(notifyMessages.backgroundJobInActionWarning);
    }

    this.handleModal();
  };

  render() {
    return this.props.showLink ? (
      <div>
        <IconLabel awesomeIcon="check" />{" "}
        <span className="glow-link cursor-pointer" onClick={this.modalHandler}>
          Confirm Payment
        </span>
        <ConfirmPaymentModalATL
          show={this.state.showModal}
          modalHandler={this.handleModal}
          {...this.props}
        />
      </div>
    ) : null;
  }
}

function mapStateToProps({ catl }) {
  return { catl };
}

export default connect(mapStateToProps, { showNotice })(ConfirmPaymentATL);
