import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Divider, Drawer, IconLabel } from "ctia-ui";
import BudgetForm from "containers/ControlPanel/Reports/BudgetReport/BudgetForm";
import EntriesList from "containers/ControlPanel/Reports/BudgetReport/EntriesList";
import SummaryContainer from "containers/ControlPanel/Reports/BudgetReport/SummaryContainer";
import BudgetDateRangeDisplay from "containers/ControlPanel/Reports/BudgetReport/BudgetDateRangeDisplay";

const BudgetReportContainer = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const renderLabel = () => (
    <IconLabel label="Budget Settings" awesomeIcon="sliders-h" />
  );

  return (
    <div>
      <h1>Budget Report</h1>
      <h3>
        <BudgetDateRangeDisplay />
      </h3>

      <Button onClick={toggleDrawer}>{renderLabel()}</Button>
      <SummaryContainer />
      <Divider />
      <div id="budget-report__details">
        <EntriesList />
      </div>
      <Drawer
        position="right"
        show={showDrawer}
        title={renderLabel()}
        toggleDrawer={toggleDrawer}
        content={<BudgetForm />}
        width={600}
      />
    </div>
  );
};

export default BudgetReportContainer;
