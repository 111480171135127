import React, { useState } from "react";
import { connect } from "react-redux";
import { IconBase, IconLabel } from "ctia-ui";
import { downloadInvoiceASC } from "actions/asc";
import "styles/Preloader.css";

const DownloadInvoiceASC = ({ downloadInvoiceASC, ivcId, ivcNum }) => {
  const [loading, setLoading] = useState(false);
  const ascData = {
    invoiceId: ivcId,
    ascInvoiceNumber: ivcNum
  };

  return (
    <div className="Loaderlink">
      {loading ? (
        <IconBase glyph="refresh" className="spinning" />
      ) : (
        <IconLabel awesomeIcon="download" />
      )}{" "}
      <span
        className="glow-link cursor-pointer"
        onClick={() => {
          setLoading(true);
          downloadInvoiceASC(ascData).then(() => setLoading(false));
        }}
      >
        {loading ? "Downloading..." : "Download Invoice"}
      </span>
    </div>
  );
};

export default connect(null, { downloadInvoiceASC })(DownloadInvoiceASC);
