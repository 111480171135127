import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import { appendCompanyToStore } from "actions/companies";
import { fetchCountryStates } from "actions/countries";
import { setUserRequestFormValues } from "actions/newUserRequest";
import { showNotice } from "actions/notifier";

import { getCompanyTypeFromRoleId } from "helpers/CompanyHelper";
import { notifyMessages } from "helpers/NotifyHelper";

import AddCompanyButton
  from "components/Companies/AddCompanyModal/AddCompanyButton";

const NewUserRequestAddCompanyButton = ({
  // Redux Stores
  newUserRequest,
  // Redux Actions
  appendCompanyToStore,
  fetchCountryStates,
  setUserRequestFormValues,
  showNotice
}) => {
  const { formValues } = newUserRequest.selectedUserRequest;
  if (_.isUndefined(formValues)) return null;

  const { company, companyType } = formValues;

  const updateCompanyOnSuccessfulAdd = response => {
    // If company was created, update the user request
    const { ctiaid, isSupplier, name, roleid } = response;
    if (
      !_.isUndefined(ctiaid) &&
      !_.isUndefined(isSupplier) &&
      !_.isUndefined(name) &&
      !_.isUndefined(roleid)
    ) {
      const companyType = getCompanyTypeFromRoleId(roleid, isSupplier).title;
      setUserRequestFormValues({
        company: name,
        companyId: ctiaid
      });

      appendCompanyToStore(response, companyType);

      showNotice(notifyMessages.companyAddedSuccessfully);

      // The lab form fetches states when opening and clears states on form submission
      // Need to refetch after dismissing modal.
      const { country } = formValues;
      fetchCountryStates(country);
    }
  };

  return (
    <AddCompanyButton
      companyType={companyType}
      knownCompanyValues={{ company }}
      onSuccess={updateCompanyOnSuccessfulAdd}
      style={{ marginBottom: 15 }} // Need to match style of 'Go Back' button
    />
  );
};

function mapPropsToState({ newUserRequest }) {
  return { newUserRequest };
}

export default connect(mapPropsToState, {
  appendCompanyToStore,
  fetchCountryStates,
  setUserRequestFormValues,
  showNotice
})(NewUserRequestAddCompanyButton);