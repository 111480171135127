import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { InlineDatepicker } from 'ctia-ui';

const Date = ({ value, onChange, editable, minDate, maxDate }) => {
  return (
    <DatePicker
      customInput={<InlineDatepicker />}
      value={value}
      selected={value}
      onChange={onChange}
      disabled={!editable}
      minDate={minDate}
      maxDate={maxDate}
      showMonthDropdown
      showYearDropdown
    />
  );
};

Date.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  editable: PropTypes.bool
};

Date.defaultProps = {
  minDate: moment().subtract(10, 'years'),
  maxDate: moment()
};

export default Date;
