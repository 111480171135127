export const testResults = [
  {
    title: "--- Select one ---",
    value: 0
  },
  {
    title: "Pass",
    value: 1
  },
  {
    title: "No Testing",
    value: 2
  }
];

export const auditResults = [
  {
    title: "--- Select one ---",
    value: 0
  },
  {
    title: "Pass",
    value: 1
  },
  {
    title: "No Auditing",
    value: 2
  }
];

export const testResultsExtended = [
  ...testResults,
  { title: "Informational Only", value: 3 }
];

export function hasResults(results) {
  if (!results) return false;
  if (!results.length) return false;

  return results.some(element => {
    return element.file && element.title && element.status > 0;
  });
}

export function filterResults(results) {
  return results.filter(element => {
    return element.file && element.title && element.status > 0;
  });
}

export const testResultStatus = ["Pending", "Pass", "No Testing"];

export const auditResultStatus = ["Pending", "Pass", "No Auditing"];
