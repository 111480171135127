import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import FormElement from "components/FormElement";
import RequestVoltage from "components/request/RequestVoltage";
import ManufSitesCRR from "containers/request/CRR/ManufSitesCRR";
import { testResultsExtended } from "dictionaries/TestResults";
import { getTestResultTitle } from "helpers/RequestTestsHelper";
import { toMomentDate } from "helpers/DateHelper";
import { changeTestField, changeTestDate } from "actions/tests";
import {
  handleInputChange,
  setBatteryRequestPermissions
} from "actions/request";

class LabResultFormCRR extends Component {
  componentDidMount() {
    const { request, user, setBatteryRequestPermissions } = this.props;
    setBatteryRequestPermissions(request, user);
  }

  onResultChange = event => {
    this.props.changeTestField(this.props.testModule.id, event);
  };

  render() {
    const {
      show,
      user,
      request,
      testModule,
      changeTestDate,
      handleInputChange
    } = this.props;

    if (!show) return null;

    const { testStatus, revision, crslversion } = request;

    const {
      lab,
      actualenddate,
      actualstartdate,
      requesttestpassedstatusid
    } = testModule;

    const startDate = toMomentDate(actualstartdate);
    const endDate = toMomentDate(actualenddate);

    return (
      <div>
        <Form horizontal>
          <FormElement
            id="testStatus"
            label="Test Status"
            value={getTestResultTitle(testStatus)}
          />
          <FormElement
            id="requesttestconductinglabs"
            label="Lab"
            value={lab.name}
          />
          <FormElement
            type="select"
            id="requesttestpassedstatusid"
            label="Test Result"
            value={requesttestpassedstatusid}
            onChange={this.onResultChange.bind(this)}
            options={testResultsExtended}
          />
          <FormElement
            id="revision"
            label="Revision Number"
            value={revision}
            type="textarea"
            onChange={handleInputChange}
          />
          <FormElement
            type="text"
            id="crslversion"
            label="CRSL Version"
            value={crslversion}
            onChange={handleInputChange}
            placeholder="Enter version number"
          />
          <RequestVoltage
            fieldId="voltage"
            fieldLabel="Voltage (V)"
            shouldBeNumber={true}
            editable={user.canEditVoltageCapacity}
          />
          <RequestVoltage
            fieldId="capacity"
            fieldLabel="Capacity (mAh)"
            shouldBeNumber={true}
            editable={user.canEditVoltageCapacity}
          />
          <FormElement
            type="date"
            id="actualstartdate"
            label="Test Start Date"
            value={startDate}
            onChange={changeTestDate.bind(
              this,
              testModule.id,
              "actualstartdate"
            )}
          />
          <FormElement
            type="date"
            id="actualenddate"
            label="Test End Date"
            value={endDate}
            onChange={changeTestDate.bind(this, testModule.id, "actualenddate")}
            minDate={startDate}
          />
          <hr />
          <ManufSitesCRR />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, tests, user }) {
  return { request, tests, user };
}

export default connect(mapStateToProps, {
  changeTestDate,
  changeTestField,
  handleInputChange,
  setBatteryRequestPermissions
})(LabResultFormCRR);
