// React / Redux
import React from "react";
import { Col, Form } from "ctia-react-bootstrap-v4";

// UI
import { Bubble } from "ctia-ui";

// Components and Containers
import NewUserRequestUserName from "containers/users/UserRequest/UserRequestForm/components/UserName";
import FormFieldLabel from "components/form/FormFieldLabel";

const NewUserRequestDispositionAccepted = () => {
  return (
    <>
      <Form.Row>
        <Col>
          <Bubble type="info">
            Now that you have accepted the user please complete the following:
          </Bubble>
        </Col>
      </Form.Row>
      <NewUserRequestUserName />
      <Form.Group controlId="dispositionPassword">
        <Form.Label>
          <FormFieldLabel label="New Password - Random temporary password" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          disabled={true}
          placeholder="Enter password"
          type="password"
          value="********"
        />
      </Form.Group>
    </>
  );
};

export default NewUserRequestDispositionAccepted;
