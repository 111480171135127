import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { IconLabel, Divider } from "ctia-ui";

const CpoLinks = () => {
  return (
    <div className="block-top-offset userLinks">
      <Link to="/user/recover">
        <Button bsStyle="link" block>
          <IconLabel label="I forgot my password" awesomeIcon="key" />
        </Button>
      </Link>
      <Divider />
      <Link to="/user/new">
        <Button bsStyle="link" block>
          <IconLabel label="Register now" awesomeIcon="user" />
        </Button>
      </Link>
    </div>
  );
};

export default CpoLinks;
