import React from 'react';
import { Bubble, Divider } from 'ctia-ui';
import ReturnLink from 'components/ReturnHome';

export default ({ name }) => (
  <div className="text-center">
    <Bubble>
      <b>{name}</b>, your registration request has been sent to CTIA Staff for
      review.
    </Bubble>
    <Divider />
    <ReturnLink />
  </div>
);
