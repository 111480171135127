import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { renderRequestLink } from "helpers/RequestHelper";

const RequestParentLink = ({ request, certPrefix }) => {
  if (!request || !request.parent) return null;

  const { id, formatedtransactionnumber: tn } = request.parent;
  const reqLink = renderRequestLink(id, tn, true, certPrefix);

  return (
    <FormElement
      id="requestParentLink"
      label="Parent Request"
      value={reqLink}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(RequestParentLink);
