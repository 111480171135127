// ES6 / Helper
import _ from "lodash";

// React / Redux
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components and Containers
import NoTableData from "components/NoTableData";
import BatteryManufSiteRow from "containers/Devices/Battery/BatteryDevices/BatteryManufSiteTable/BatteryManufSiteRow";

const BatteryManufSiteRows = ({
  requestReloadTable,
  showWithdrawColumn,
  tableData
}) => {
  /// Invalid table
  if (!tableData) return null;

  const numberOfColumns = 3;
  if (_.isEmpty(tableData)) return <NoTableData colSpan={numberOfColumns} />;

  /// Valid table

  const getFormattedTableRows = () => {
    return _.map(tableData, (row) => {
      return (
        <BatteryManufSiteRow
          requestReloadTable={requestReloadTable}
          rowData={row}
          showWithdrawColumn={showWithdrawColumn}
        />
      );
    });
  };

  return getFormattedTableRows();
};

BatteryManufSiteRows.propTypes = {
  tableData: PropTypes.array,
  openWithdrawConfirm: PropTypes.func,
  openUnwithdrawConfirm: PropTypes.func
};

function mapStateToProps({ sites, user }) {
  return { sites, user };
}

export default connect(mapStateToProps)(BatteryManufSiteRows);
