import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Bubble, LoaderButton } from "ctia-ui";

const BatteryLabDecision = ({ id, isAccepted, cert }) => {
  const resultTitle = isAccepted ? "accepted" : "rejected";
  const renderReportsLink = () => {
    if (!isAccepted) return null;
    return (
      <Link to={`/battery/lab/results/${cert}/${id}`}>
        <LoaderButton bsStyle="info" text="Upload Reports &rarr;" />
      </Link>
    );
  };

  return (
    <div>
      <Bubble>Request has been successfully {resultTitle}.</Bubble>
      <Link to="/battery/lab/requests" className="button-right-offset">
        <LoaderButton text="Done" />
      </Link>
      {renderReportsLink()}
    </div>
  );
};

BatteryLabDecision.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isAccepted: PropTypes.bool,
  cert: PropTypes.string.isRequired,
};

BatteryLabDecision.defaultProps = {
  isAccepted: false,
  id: 1,
};

export default BatteryLabDecision;
