import _ from "lodash";

export const supplierTypes = {
  battery: 1,
  adapter: 2,
  cell: 3
};

const supplierTypeLabels = {
  1: "a Battery",
  2: "an Adapter",
  3: "a Cell"
};

export const formatSuppliersList = (
  list,
  supplierType = supplierTypes.battery
) => {
  let suppliers = [];
  const listType = supplierTypeLabels[supplierType];

  if (_.isArray(list) && _.size(list) > 0) {
    suppliers = [...list];
  }

  return [
    {
      value: 0,
      title: `Select ${listType} Supplier ↴`
    },
    ...suppliers
  ];
};

export const formatBatteryList = list => {
  const batteries = _.isArray(list) && _.size(list) > 0 ? [...list] : [];

  return [
    {
      value: 0,
      linkTitle: `Select Battery ↴`
    },
    ...batteries
  ];
};

export const formatAdapterSuppliersList = (
  list,
  supplierType = supplierTypes.battery
) => {
  let suppliers = [];
  const listType = supplierTypeLabels[supplierType];

  if (_.isArray(list) && _.size(list) > 0) {
    suppliers = list.map(supplier => {
      return { value: supplier.id, title: supplier.name };
    });
  }

  return [
    {
      value: 0,
      title: `Select ${listType} Supplier ↴`
    },
    ...suppliers
  ];
};

export const formatModelsList = (list = [], modelsInUse = []) => {
  let mappedList = list;

  if (modelsInUse.length > 0) {
    mappedList = list.map(model => {
      const idsInUse = modelsInUse.map(inUse =>
        Number(inUse.linkedrequestdevicemodelid)
      );

      if (_.includes(idsInUse, Number(model.value))) {
        model.disabled = true;
      }

      return model;
    });
  }
  return [
    {
      value: 0,
      title: "Select a Cell ↴"
    },
    ...mappedList
  ];
};

export const formatPackModels = (list = []) => {
  return [
    {
      value: 0,
      title: "Select a Model ↴"
    },
    ...list
  ];
};

export const formatRevisionsList = (list, revisionsInUse = []) => {
  let mappedList = list.map(elem => {
    return {
      value: elem.id,
      title: `Revision: ${elem.revision}, Capacity: ${elem.capacity}`
    };
  });

  if (revisionsInUse.length > 0) {
    mappedList = mappedList.map(model => {
      const idsInUse = revisionsInUse.map(inUse =>
        Number(inUse.linkedrequestid)
      );

      if (_.includes(idsInUse, Number(model.value))) {
        model.disabled = true;
      }

      return model;
    });
  }

  mappedList.unshift({
    value: 0,
    title: "Select a Revision ↴"
  });

  return mappedList;
};

export const toggleOptions = [
  {
    id: 1,
    label: "Select a Model",
    disabled: false
  },
  {
    id: 2,
    label: "Pending Recognition",
    disabled: false
  }
];

export const getSupplierById = (id, suppliers) => {
  if (!id || !suppliers) {
    console.warn(
      "No ID or suppliers passed. Check `getSupplierById` method for a reference."
    );
    return null;
  }

  return _.find(suppliers, ["value", Number(id)]);
};

export const getPackCellById = (id, cells) => {
  if (!id || !cells) {
    console.warn(
      "No ID or cells passed. Check `getPackCellById` method for a reference."
    );
    return null;
  }

  return _.find(cells, ["value", Number(id)]);
};

export const getPackRequestById = (id, packs) => {
  if (!id || !packs) {
    console.warn(
      "No ID or packs passed. Check `getPackRequestById` method for a reference."
    );
    return null;
  }

  return _.find(packs, ["id", Number(id)]);
};
