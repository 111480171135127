const {
  REACT_APP_API,
  REACT_APP_API_OTA,
  REACT_APP_API_ADMIN,
  REACT_APP_API_GENERAL,
  REACT_APP_API_SAR,
  REACT_APP_API_BLC,
  REACT_APP_API_DHR,
  REACT_APP_API_CRR,
  REACT_APP_API_LEGACY_PTCRB,
  REACT_APP_ENVIRONMENT
} = process.env;

// backend (CF) API URL
export const apiUrl = REACT_APP_API;
export const otaApiUrl = REACT_APP_API_OTA;
export const generalApiUrl = REACT_APP_API_GENERAL;
export const sarApiUrl = REACT_APP_API_SAR;
export const blcApiUrl = REACT_APP_API_BLC;
export const dhrApiUrl = REACT_APP_API_DHR;
export const crrApiUrl = REACT_APP_API_CRR;
export const legacyPtcrbApiUrl = REACT_APP_API_LEGACY_PTCRB;
export const adminApiUrl = REACT_APP_API_ADMIN;
export const environment = REACT_APP_ENVIRONMENT;

// Value in milliseconds, when need to request new token from server (each 30 minutes)
export const tokenTimeout = 1800000;

// Value in milliseconds, when need to request system info from server (each 5 minutes)
export const maintenanceTimeout = 300000;
