import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { LoaderButton, Divider } from "ctia-ui";
import { Col, Row } from "react-bootstrap";
import { submitOtaTestResults } from "actions/tests";
import {
  hasPreparedResults,
  hasUploadedReports,
  isComplete
} from "helpers/RequestTestsHelper";

const SubmitTestResults = ({ request, show, tests, submitOtaTestResults }) => {
  if (!show) return null;

  const onSubmit = () => {
    return submitOtaTestResults(request, tests);
  };

  const validateTestModule = testModule => {
    const { reports, planversion } = testModule;

    if (planversion === "") return false;

    return hasPreparedResults(reports) || hasUploadedReports(reports);
  };

  const onValidate = () => {
    const { hwVersion, swVersion } = request;

    const requestFieldsValid = hwVersion !== "" && swVersion !== "";

    if (!requestFieldsValid) return false;

    return _.some(tests, validateTestModule);
  };

  const completed = isComplete(request.testStatus);

  return (
    <Row>
      <Col className="text-center">
        <Divider />
        <LoaderButton
          bsStyle="info"
          bsSize="large"
          text="Save changes"
          loadingText="Uploading..."
          onClick={onSubmit}
          isLoading={request.isLoading}
          disabled={!onValidate()}
          show={!completed}
        />
      </Col>
    </Row>
  );
};

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, { submitOtaTestResults })(
  SubmitTestResults
);
