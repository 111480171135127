import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import AddButton from "components/form/buttons/AddButton";
import { startPreloader, finishPreloader } from "actions/preloader";
import { showNotice } from "actions/notifier";
import { addBatterySupplier } from "actions/suppliers";
import {
  validateSupplierFields,
  requestFields
} from "helpers/BatterySupplierHelper";

const BatterySupplierAdd = ({ request, addBatterySupplier }) => {
  // do not render a button if supplier info has not ready yet
  if (!validateSupplierFields(request)) return null;

  const onClick = () => {
    const hasPendingRecognition = request[requestFields.modelOption] === 2;
    const supplierInfo = {
      hasPendingRecognition,
      linkedrequestid: hasPendingRecognition
        ? null
        : request[requestFields.linkedRequestId],
      linkedrequestdevicemodelid: request[requestFields.modelId],
      linkedrequestvendorproductname: request[requestFields.modelName],
      linkedrequestvendorname: request[requestFields.supplierName],
      linkedrequestvendorid: request[requestFields.supplierId],
      linkedrequestcapacity: request[requestFields.linkedRequestCapacity],
      linkedrequestrevision: request[requestFields.linkedRequestRevision]
    };

    return addBatterySupplier(supplierInfo);
  };

  return (
    <FormElement
      type="actionButton"
      id="addSupplierButton"
      buttonComponent={AddButton}
      buttonLabel="Add Battery"
      onClick={onClick}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  startPreloader,
  finishPreloader,
  showNotice,
  addBatterySupplier
})(BatterySupplierAdd);
