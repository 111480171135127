import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import FormElement from "components/FormElement";
import { toMomentDate } from "helpers/DateHelper";
import { makeEvent } from "libs/form";
import {
  changeTestField,
  changeTestDate,
  changeTestMultiField
} from "actions/tests";
import { handleInputChange } from "actions/request";
import TestPlanBadge from "components/Badges/TestStatusBadge";

class LabResultFormOta extends Component {
  componentDidMount() {
    const { testModule, changeTestField } = this.props;

    // replace planversion value with alias
    const planversion = testModule.planversion.trim();
    const syntEvent = makeEvent("planversion", planversion);
    changeTestField(testModule.id, syntEvent);
  }

  render() {
    const {
      show,
      request,
      testModule,
      changeTestDate,
      changeTestField,
      handleInputChange,
      changeTestMultiField
    } = this.props;

    if (!show) return null;

    const { hwVersion, swVersion } = request;

    const {
      lab,
      planversion,
      actualenddate,
      actualstartdate,
      requestteststatusid
    } = testModule;

    if (!testModule.interface) return null;

    const startDate = toMomentDate(actualstartdate);
    const endDate = toMomentDate(actualenddate);

    return (
      <div>
        <Form horizontal>
          <FormElement
            id="testInterface"
            label="Test Plan"
            value={testModule.interface.name}
          />
          <FormElement
            id="testStatus"
            label="Test Status"
            value={<TestPlanBadge statusId={requestteststatusid} />}
          />
          <FormElement
            id="requesttestconductinglabs"
            label="Lab"
            value={lab.name}
            onChange={changeTestMultiField.bind(this, testModule.id)}
          />
          <FormElement
            type="text"
            id="planversion"
            label="Test Plan Version"
            value={planversion}
            onChange={changeTestField.bind(this, testModule.id)}
            placeholder="Enter version number"
          />
          <FormElement
            type="textarea"
            id="hwVersion"
            label="Device Hardware Version"
            value={hwVersion}
            onChange={handleInputChange}
            placeholder="Enter the final tested device hardware version"
          />
          <FormElement
            type="textarea"
            id="swVersion"
            label="Device Software Version"
            value={swVersion}
            onChange={handleInputChange}
            placeholder="Enter the final tested device software version"
          />
          <FormElement
            type="date"
            id="actualstartdate"
            label="Test Start Date"
            value={startDate}
            onChange={changeTestDate.bind(
              this,
              testModule.id,
              "actualstartdate"
            )}
            // show={!noTesting}
          />
          <FormElement
            type="date"
            id="actualenddate"
            label="Test End Date"
            value={endDate}
            onChange={changeTestDate.bind(this, testModule.id, "actualenddate")}
            // show={!noTesting}
            minDate={startDate}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  changeTestDate,
  changeTestField,
  changeTestMultiField,
  handleInputChange
})(LabResultFormOta);
