import React from "react";
import { connect } from "react-redux";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { Checklist } from "ctia-ui";
import SupportDocs from "components/SupportDocs";
import UnderReviewViewDHR from "containers/testResult/UnderReviewViewDHR";
import { hasSupDocs } from "helpers/RequestHelper";
import { isComplete, isUnderReview } from "helpers/RequestTestsHelper";
import DhrDeviceInfo from "containers/request/DHR/DHRContainer";
import PointOfContact from "containers/request/Initial/Steps/Laboratory/PointOfContactLab";
import { getChecklistDHR } from "helpers/ChecklistHelper";

const LabViewDHR = ({ request, tests }) => {
  const testModule = tests[0];
  const testStatus = request.testStatus;
  const showTestResults = isComplete(testStatus) || isUnderReview(testStatus);
  const showSupDocsTab = hasSupDocs(request);
  const checkList = getChecklistDHR(request);

  return (
    <Row>
      <Col xs={12} sm={8} md={9}>
        <Tabs defaultActiveKey={1} id="request-edit-tabs">
          <Tab eventKey={1} title="Device Info">
            <DhrDeviceInfo />
          </Tab>
          <Tab eventKey={2} title="Point of Contact">
            <PointOfContact />
          </Tab>
          {showTestResults && (
            <Tab eventKey={3} title="Test Results">
              <UnderReviewViewDHR
                show={showTestResults}
                testModule={testModule}
              />
            </Tab>
          )}
          {showSupDocsTab && (
            <Tab eventKey={4} title="Optional Supporting Documentation">
              <SupportDocs />
            </Tab>
          )}
        </Tabs>
      </Col>
      <Col xs={12} sm={4} md={3}>
        <Checklist list={checkList} />
      </Col>
    </Row>
  );
};

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps)(LabViewDHR);
