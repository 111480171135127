import React from "react";
import { Form } from "ctia-react-bootstrap-v4";
import OperatorCertificationSelectors
  from "components/Companies/AddCompanyModal/components/OperatorCertificationSelectors";
import FormFieldLabel from "components/form/FormFieldLabel";

const OperatorForm = ({
  values = {}
}) => {
  return (
    <>
      <Form.Group controlId="name">
        <Form.Label>
          <FormFieldLabel label="Name" isRequired={true} />
        </Form.Label>
        <Form.Control required
          placeholder="Enter organization name"
          defaultValue={values.company}
        />
        <Form.Control.Feedback type="invalid">
          Please choose an organization name.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <FormFieldLabel label="Certification Programs" isRequired={true} />
        </Form.Label>
        <OperatorCertificationSelectors />
      </Form.Group>
    </>
  );
}

export default OperatorForm;