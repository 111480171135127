// ES6 / Helper / React / Redux
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

// Component imports
import IeeeBadge from "components/Badges/IeeeBadge";
import DelistButton from "components/DeviceRecognition/DelistButton/DelistButton";
import WithdrawButton from "components/DeviceRecognition/WithdrawModalAndButton/WithdrawButton";
import ReadonlyList from "components/form/ReadonlyList";
import Portal from "components/Portal";

// Helper imports
import { getSimpleArrayByKey } from "helpers/ArrayHelper";
import { formatDate } from "helpers/DateHelper";
import { getDeviceRowStatus } from "helpers/ModelHelper";
import { getTransPrefix } from "helpers/RequestHelper";
import { getUserPrefix } from "helpers/UsersHelper";

const BatteryModelDetailsOtherCertsRow = ({
  deviceModel,
  modalInfo,
  reloadRow = () => {},
  requestIdsOfChildren = [],
  rowData,
  rowIndex,
  showDateRecognized,
  showWithdrawAndDelistColumns = false,
  userSelectedCertType,
  // Redux stores
  user
}) => {
  const {
    certtypeid: certTypeId,
    crslversion: crslVersion,
    completedat: completedAt,
    delistedat: delistedAt,
    devicemodelid: deviceModelId,
    devicemodel: reqModel,
    id: requestId,
    formatedtransactionnumber: formattedTransactionNumber,
    ieeetypeid: ieeeTypeId,
    primarylabid: primaryLabId,
    requestmanufsites: requestManufSites,
    requeststatusid: requestStatusId,
    requesttypeid: requestTypeId,
    revision,
    withdrawnat: withdrawnAt,
    withdrawreason: withdrawReason
  } = rowData;

  const userCanReviewRequestId =
    user.isStaff ||
    (user.isLab && user.companyid === primaryLabId) ||
    ((user.isSupplier || user.isVendor) &&
      user.companyid === reqModel.vendorid);

  // TODO: This should be considered a temporary solution, as it relies on
  // the formated transaction number format being consistent, and the first
  // 3 chars always providing a cert symbol that matches the FE's routing.
  const certText = getTransPrefix(formattedTransactionNumber);
  const linkToRequest = () => {
    if (!userCanReviewRequestId) return formattedTransactionNumber;

    const role = getUserPrefix(user);
    return (
      <Portal
        changeCertProgram={"battery"}
        to={`/battery/${role}/requests/${certText}/${requestId}`}
        tn={formattedTransactionNumber}
      />
    );
  };
  const certDate = completedAt ? formatDate(completedAt) : "";
  const statusText = getDeviceRowStatus(
    userSelectedCertType,
    requestStatusId,
    delistedAt
  );

  const isDelisted = !_.isEmpty(delistedAt);

  return (
    <tr key={rowIndex}>
      <td>{linkToRequest()}</td>
      <td>
        <IeeeBadge ieeeType={ieeeTypeId} />
      </td>
      <td>{revision}</td>
      <td>
        <ReadonlyList list={getSimpleArrayByKey(requestManufSites, "name")} />
      </td>
      <td>{crslVersion}</td>
      {showDateRecognized && <td>{certDate}</td>}
      <td>{statusText}</td>
      {showWithdrawAndDelistColumns && (
        <>
          <td>
            <WithdrawButton
              deviceModelId={deviceModelId}
              modalInfo={modalInfo}
              requestId={requestId}
              requestIdsOfChildren={requestIdsOfChildren}
              requestStatusId={requestStatusId}
              requestTypeId={requestTypeId}
              selectedCertType={certText}
              shouldDelistOrRelistOnSuccess={true}
              withdrawnAt={withdrawnAt}
              withdrawReason={withdrawReason}
              onError={error => {
                reloadRow();
              }}
              onSuccess={() => {
                reloadRow();
              }}
            />
          </td>
          <td>
            <DelistButton
              isDelisted={isDelisted}
              certTypeId={certTypeId}
              deviceModelId={deviceModelId}
              requestId={requestId}
              selectedCertType={certText}
              onSuccess={reloadRow}
            />
          </td>
        </>
      )}
    </tr>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(BatteryModelDetailsOtherCertsRow);
