import React from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import { isResultPass, getTestPassedTitle } from "helpers/RequestTestsHelper";
import { formatDate } from "helpers/DateHelper";
import UploadedResults from "containers/testResult/UploadedResults";
import StatusBadge from "components/Badges/TestStatusBadge";
import { downloadReportDHR } from "actions/dhr/dhrRequest";

const UnderReviewViewDHR = ({ show, testModule, downloadReportDHR }) => {
  if (!show) return null;

  const startDate = formatDate(testModule.actualstartdate);
  const endDate = formatDate(testModule.actualenddate);
  const passed = isResultPass(testModule.requesttestpassedstatusid);

  return (
    <div>
      <Table responsive className="ctia-table">
        <thead>
          <tr>
            <th>Test Result</th>
            <th>Test Status</th>
            <th>Test Plan Version</th>
            {passed && [
              <th key={1}>Test Start Date</th>,
              <th key={2}>Test End Date</th>
            ]}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{getTestPassedTitle(testModule.requesttestpassedstatusid)}</td>
            <td>
              <StatusBadge statusId={testModule.requestteststatusid} />
            </td>
            <td>{testModule.planversion}</td>
            {passed && [
              <td key={3}>{startDate}</td>,
              <td key={4}>{endDate}</td>
            ]}
          </tr>
        </tbody>
      </Table>
      <UploadedResults
        reports={testModule.reports}
        downloadHandler={downloadReportDHR}
      />
    </div>
  );
};

// @NOTE: need them to allow component updates correctly
function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  downloadReportDHR
})(UnderReviewViewDHR);
