import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IconLabel } from "ctia-ui";
import { Col, Row } from "react-bootstrap";
import AddServiceCenter from "containers/ControlPanel/Orgs/ServiceCenter/AddServiceCenter";
import OrgTypeSelector from "containers/ControlPanel/Orgs/OrgTypeSelector";
import ServiceCenterRow from "containers/ControlPanel/Orgs/ServiceCenter/ServiceCenterRow";
import SortableTable from "containers/SortableTable";
import ListFilter from "components/ListFilter";
import { setOrgType, fetchCompaniesASC, clearOrgList } from "actions/org";
import { ASC } from "dictionaries/Roles";
import pluralize from "pluralize";

const OrgsServiceCenter = ({
  org,
  setOrgType,
  fetchCompaniesASC,
  clearOrgList
}) => {
  const [filterTerm, setFilterTerm] = useState("");
  useEffect(() => {
    if (!org || !org.type || org.type !== ASC) {
      setOrgType(ASC);
    }
  }, [org, setOrgType]);

  useEffect(() => {
    if (org && org.type === ASC && !org.orgList) {
      fetchCompaniesASC();
    }
  }, [org, fetchCompaniesASC]);

  useEffect(() => {
    return () => {
      clearOrgList();
    };
  }, [clearOrgList]);

  const headerFields = [
    { title: "Name", fieldname: "name" },
    { title: "City", fieldname: "citydisplay" },
    { title: "Country", fieldname: "country" },
    { title: "Contact", fieldname: "contactname" },
    { title: "ASC", fieldname: "ascstatus" },
    { title: "Status", fieldname: "isActive" },
    { title: "", fieldname: "" }
  ];

  const handleSort = (item, sortField) => {
    if (sortField && sortField === "active") {
      return !item.active;
    }

    return String(item[sortField]).trim().toLowerCase();
  };

  const renderList = list => {
    if (!list) return "Loading...";

    return (
      <SortableTable
        list={list}
        header={headerFields}
        colNum={headerFields.length}
        handleSort={handleSort}
        rowRenderer={ServiceCenterRow}
      />
    );
  };

  const renderCounter = list => {
    if (!list) return null;

    const pluralizedLabel = pluralize("record", org.orgList.length, true);

    return <IconLabel awesomeIcon="book" label={pluralizedLabel} />;
  };

  const applyFilter = list => {
    if (!list) return null;
    if (!filterTerm) return list;

    return _.filter(list, org => {
      // convert UPPER CASE to lower case for case insensitive search
      const orgName = String(org.name).toLowerCase();
      const contact = String(org.contactname).toLowerCase();
      const city = String(org.citydisplay).toLowerCase();
      const country = String(org.countrydisplay).toLowerCase();

      return (
        orgName.indexOf(filterTerm.toLowerCase()) !== -1 ||
        contact.indexOf(filterTerm.toLowerCase()) !== -1 ||
        city.indexOf(filterTerm.toLowerCase()) !== -1 ||
        country.indexOf(filterTerm.toLowerCase()) !== -1
      );
    });
  };

  const filteredList =
    org && org.orgList && org.orgList.length ? applyFilter(org.orgList) : null;

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div style={{ display: "flex" }} className="h1-div">
            <div>Organization Administration,&nbsp;</div>
            <OrgTypeSelector />
          </div>
          <ListFilter
            label="Search Organization"
            onChange={value => setFilterTerm(value)}
          />
          <p>
            Below is a list of the organizations that currently reside in the
            system.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>{renderCounter(filteredList)}</Col>
        <Col xs={6} className="text-right">
          <AddServiceCenter />
        </Col>
      </Row>
      {renderList(filteredList)}
    </div>
  );
};

function mapStateToProps({ org }) {
  return { org };
}

export default connect(mapStateToProps, {
  setOrgType,
  fetchCompaniesASC,
  clearOrgList
})(OrgsServiceCenter);
