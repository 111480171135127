// React/Redux/UI imports
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Table, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  CounterLabel,
  DownloadButton,
  IconLabel,
  InlinePreloader
} from "ctia-ui";
import {
  clearCertifiedPendingReport,
  downloadCertifiedPendingReport
} from "actions/reports/certifiedPendingReport";

// Helper and ES6 Extension imports
import _ from "lodash";
import { toggleOrder } from "helpers/SortHelper";
import CertifiedPendingReportTableHelper from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Table/Helpers/TableHelper";

// Component imports
import CertifiedPendingReportTableBody from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Table/Components/TableBody";
import FormElement from "components/FormElement";

const CertifiedPendingReportTable = ({
  authorizationType,
  dateFrom,
  dateTo,
  requestStatusId,
  requestTypeId,
  selectedCertificationType = "",
  selectedCertificationTypeId,
  showDateCertified = false,
  app,
  certifiedPendingReport,
  downloadCertifiedPendingReport,
  clearCertifiedPendingReport
}) => {
  const [componentState, setComponentState] = useState({
    isProcessing: false
  });

  const [payload, setPayload] = useState({
    meta: null,
    data: certifiedPendingReport ? certifiedPendingReport.list : null
  });

  const [filterValue, setFilterValue] = useState("");
  const [filteredTableData, setFilteredTableData] = useState([]);

  const [sortedTableData, setSortedTableData] = useState([]);
  const [sort, setSort] = useState({
    field: "formatedtransactionnumber",
    order: "asc"
  });

  const sortableColumn = (title, fieldname) => (
    <button className="button-no-border" onClick={() => handleSort(fieldname)}>
      {sort.field === fieldname ? (
        <IconLabel
          awesomeIcon={
            sort.order.toUpperCase() === "ASC" ? "caret-up" : "caret-down"
          }
          label={title}
          iconFirst={false}
        />
      ) : (
        title
      )}
    </button>
  );

  const downloadExcelFile = () => {
    return downloadCertifiedPendingReport({
      authorizationType: authorizationType,
      certificationTypeId: selectedCertificationTypeId,
      endingDate: dateTo,
      requestStatusId: requestStatusId,
      requestTypeId: requestTypeId,
      startingDate: !_.isNull(dateFrom) ? dateFrom : ''
    });
  };

  const handleSort = field => {
    setSort({
      field: field,
      order: sort.field === field ? toggleOrder(sort.order, true) : sort.order
    });
    setComponentState(state => {
      return {
        ...state,
        isProcessing: true
      };
    });
  };

  const setSearchText = value => {
    setFilterValue(value);
    setComponentState(state => {
      return {
        ...state,
        isProcessing: true
      };
    });
  };

  /**
   * Get updated results when user searches and store them in payload.
   */
  useEffect(() => {
    const dataForTable = certifiedPendingReport
      ? certifiedPendingReport.list
      : [];

    if (dataForTable !== payload.data) {
      setPayload(payload => {
        return {
          meta: certifiedPendingReport.meta,
          data: dataForTable
        };
      });
    }
  }, [certifiedPendingReport, payload.data]);

  /**
   * Handle filtering of the table data
   */
  useEffect(() => {
    const filterTableData = () => {
      setComponentState(state => {
        return {
          ...state,
          isProcessing: true
        };
      });

      CertifiedPendingReportTableHelper.filterTableData({
        searchString: filterValue,
        tableData: payload.data
      })
        .then(newTableData => {
          setFilteredTableData(newTableData);
        })
        .catch(error => {
          setFilteredTableData(payload.data);
        });
    };

    setTimeout(filterTableData, 0);
  }, [payload, filterValue]);

  /**
   * Handling sorting of the table data
   */
  useEffect(() => {
    setComponentState(state => {
      return {
        ...state,
        isProcessing: true
      };
    });

    const rowSortRules = row => {
      let sortValue = "";
      switch (sort.field) {
        // Case: Objects / Dates
        case "completedat":
          sortValue = row[sort.field]._d;
          break;
        case "createdat":
          sortValue = row[sort.field]._d;
          break;
        // Case: String values
        case "formatedtransactionnumber":
        case "modelnumber":
        case "name":
        case "vendorname":
        case "vendorproductname":
          sortValue = row[sort.field].toLowerCase();
          break;
        default:
          sortValue = row[sort.field];
      }

      return sortValue;
    };

    const sortedTableData = _.orderBy(
      filteredTableData,
      row => rowSortRules(row),
      [sort.order]
    );

    setSortedTableData(sortedTableData);
    setComponentState(state => {
      return {
        ...state,
        isProcessing: false
      };
    });
  }, [filteredTableData, sort]);

  /**
   * Setup for UI portion of component
   */

  // Table UI properties
  const counter =
    certifiedPendingReport.meta && certifiedPendingReport.meta.TOTALRECORDS
      ? certifiedPendingReport.meta.TOTALRECORDS
      : certifiedPendingReport.list && certifiedPendingReport.list.length > 0
      ? certifiedPendingReport.list.length
      : null;
  const showRequestType = selectedCertificationType.toLowerCase() !== "ota";
  const showFilteredCount =
    sortedTableData && sortedTableData.length
      ? sortedTableData.length !== counter
      : false;

  const { isProcessing } = componentState;

  return (
    <div>
      {counter && (
        <Row>
          <Col xs={2} md={2}>
            <CounterLabel counter={counter} title="Total records" />
            {showFilteredCount && (
              <CounterLabel
                counter={sortedTableData.length}
                title="Shown records"
              />
            )}
          </Col>
          <Col xs={8} md={8} className="text-right">
            <FormElement
              label="Search"
              onChange={event => {
                const { value } = event.target;
                setSearchText(value);
              }}
              type="text"
              value={filterValue}
              labelColSizeSm={1}
              labelColSizeMd={1}
              inputColSizeSm={9}
              inputColSizeMd={9}
            />
          </Col>
          <Col xs={2} md={2}>
            <div className="label">
              <DownloadButton size="large" action={() => downloadExcelFile()} />
            </div>
          </Col>
        </Row>
      )}
      {certifiedPendingReport.list && (
        <Table condensed hover responsive striped className="ctia-table">
          <thead>
            <tr>
              <th>
                {sortableColumn("Request ID", "formatedtransactionnumber")}
              </th>
              <th>{sortableColumn("Vendor", "vendorname")}</th>
              <th>
                {sortableColumn("Model Name/Number", "vendorproductname")}
              </th>
              {selectedCertificationType === "BLC" && (
                <th>{sortableColumn("Device Type", "devicetype")}</th>
              )}
              <th>{sortableColumn("Date Submitted", "createdat")}</th>
              {showDateCertified && (
                <th>{sortableColumn("Date Certified", "completedat")}</th>
              )}
              {showRequestType && (
                <th>{sortableColumn("Request Type", "name")}</th>
              )}
              <th>{sortableColumn("Status", "requeststatusid")}</th>
            </tr>
          </thead>
          <tbody>
            {!isProcessing && (
              <CertifiedPendingReportTableBody
                tableData={sortedTableData}
                selectedCertType={selectedCertificationType}
                showDateCertified={showDateCertified}
                showRequestType={showRequestType}
              />
            )}
          </tbody>
        </Table>
      )}
      {isProcessing && <InlinePreloader show={true} />}
    </div>
  );
};

function mapStateToProps({ app, certifiedPendingReport }) {
  return { app, certifiedPendingReport };
}

export default connect(mapStateToProps, {
  clearCertifiedPendingReport,
  downloadCertifiedPendingReport
})(CertifiedPendingReportTable);
