import React, { Component } from "react";
import { connect } from "react-redux";
import { Badge } from "react-bootstrap";
import { fetchBatteryForApproveRequests } from "actions/battery/batteryRequests";

class ApproveCountBattery extends Component {
  componentDidMount() {
    this.props.fetchBatteryForApproveRequests();
  }

  componentWillReceiveProps(nextProps) {
    const { badges } = this.props;
    const updatingApproveCount = nextProps.badges.updatingApproveCount;

    if (
      updatingApproveCount === true ||
      (badges && updatingApproveCount !== badges.updatingApproveCount)
    ) {
      this.props.fetchBatteryForApproveRequests();
    }
  }

  render() {
    const { badges } = this.props;
    if (!badges || !badges.approveCount) return null;
    return <Badge className="icon-left-offset">{badges.approveCount}</Badge>;
  }
}

function mapStateToProps({ badges }) {
  return { badges };
}

export default connect(mapStateToProps, { fetchBatteryForApproveRequests })(
  ApproveCountBattery
);
