import React from "react";
import AuditStatus from "containers/request/battery/AuditLabInfo/AuditStatus";
import LabAuditInfoContainer from "containers/request/battery/AuditLabInfo/LabAuditInfoContainer";

const customTitles = {
  col1: "Audit Type",
  col2: "Lab",
  col3: "Status",
  col1Row: "Battery Compliance"
};

const LabAuditInfo = ({ editable }) => {
  return (
    <LabAuditInfoContainer
      editable={editable}
      customTitles={customTitles}
      statusComponent={AuditStatus}
    />
  );
};

export default LabAuditInfo;
