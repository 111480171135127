import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LoaderButton } from "ctia-ui";
import { delistDevice, relistDevice } from "actions/devices";
import { delistRequest, relistRequest } from "actions/battery/batteryRequests";

const DelistDeviceRecognitionButton = ({
  isDelisted = false,
  certTypeId = null,
  deviceModelId = null,
  requestId = null,
  selectedCertType = null,
  onSuccess = () => {}
}) => {
  const [componentState, setComponentState] = useState({
    isDelisted: isDelisted,
    requestPending: false
  });

  useEffect(() => {
    setComponentState(state => {
      return {
        ...state,
        isDelisted: isDelisted
      };
    });
  }, [isDelisted]);

  const handleUserAction = () => {
    setComponentState(state => {
      return { ...state, requestPending: true };
    });

    let call;
    if (!_.isNull(requestId) && !_.isNull(selectedCertType)) {
      const callParams = {
        id: requestId,
        prefix: selectedCertType
      };

      call = isDelisted ? relistRequest(callParams) : delistRequest(callParams);
    } else {
      const callParams = {
        certTypeId: certTypeId,
        deviceId: deviceModelId,
        requestId: requestId
      };
      call = isDelisted ? relistDevice(callParams) : delistDevice(callParams);
    }

    call
      .then(() => {
        setComponentState(state => {
          return {
            ...state,
            isDelisted: !isDelisted,
            requestPending: false
          };
        });
        onSuccess();
      })
      .catch(() => {
        setComponentState(state => {
          return { ...state, requestPending: false };
        });
      });
  };

  if (!certTypeId || !deviceModelId) return null;

  return (
    <LoaderButton
      bsStyle={componentState.isDelisted ? "warning" : "danger"}
      bsSize="xsmall"
      isLoading={componentState.requestPending}
      text={
        componentState.isDelisted ? "Relist Recognition" : "Delist Recognition"
      }
      onClick={() => handleUserAction()}
    />
  );
};

export default connect(null, {
  delistDevice,
  relistDevice
})(DelistDeviceRecognitionButton);
