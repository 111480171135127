import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { Checklist } from "ctia-ui";
import SupportDocs from "components/SupportDocs";
import UnderReviewView from "containers/testResult/UnderReviewViewCommon";
import { hasSupDocs } from "helpers/RequestHelper";
import { getChecklist } from "helpers/ChecklistHelper";
import { isComplete, isUnderReview } from "helpers/RequestTestsHelper";
import DeviceInfo from "containers/request/Initial/Steps/Laboratory/DeviceInfo";
import PointOfContact from "containers/request/Initial/Steps/Laboratory/PointOfContactLab";
import { getUsersManualInfo } from "actions/requestCyber";

class LabInitial extends Component {
  componentDidMount() {
    // need usersmanual info to allow checklist works correctly
    const { request, getUsersManualInfo } = this.props;
    if (request.id) {
      getUsersManualInfo(request.model.id);
    }
  }

  render() {
    const { request, tests } = this.props;
    const testModule = tests[0];
    const testStatus = request.testStatus;
    const showTestResults = isComplete(testStatus) || isUnderReview(testStatus);
    const showSupDocsTab = hasSupDocs(request);

    return (
      <Row>
        <Col xs={12} sm={8} md={9}>
          <Tabs defaultActiveKey={1} id="request-edit-tabs">
            <Tab eventKey={1} title="Device Info">
              <DeviceInfo />
            </Tab>
            <Tab eventKey={2} title="Point of Contact">
              <PointOfContact />
            </Tab>
            {showTestResults && (
              <Tab eventKey={3} title="Test Results">
                <UnderReviewView
                  show={showTestResults}
                  testModule={testModule}
                />
              </Tab>
            )}
            {showSupDocsTab && (
              <Tab eventKey={4} title="Optional Supporting Documentation">
                <SupportDocs />
              </Tab>
            )}
          </Tabs>
        </Col>
        <Col xs={12} sm={4} md={3}>
          <Checklist list={getChecklist(request)} />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, { getUsersManualInfo })(LabInitial);
