import { restClientAdmin } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import * as actions from "actions/types";
import * as call from "helpers/ActionHelper";

export const fetchDeletedUsers = userRole => async dispatch => {
  // start preloader
  dispatch(call.startPreloader());

  try {
    const fields =
      "firstname, lastname, title, role, companyname, email, lastloginat, deletedat, id, ctiaid, active";
    const orders = "lastname ASC, firstname ASC";
    const whereClause = `&where=deletedat IS NOT NULL AND role='${userRole}' AND active IN (-1,0,1)`;
    const selectClause = `&select=${fields}`;
    const orderClause = `orderBy=${orders}`;

    const url = `/users?${orderClause}${whereClause}${selectClause}&page=0`;
    const response = await restClientAdmin.get(url);

    dispatch({
      type: actions.FETCH_DELETED_USERS,
      payload: response.data.data
    });
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));
  }

  // stop preloader
  dispatch(call.stopPreloader());
};

export const searchDeletedUsers = searchTerm => async dispatch => {
  // start preloader
  dispatch(call.startPreloader());

  try {
    const fields =
      "firstname, lastname, title, role, companyname, email, lastloginat, deletedat, id, ctiaid, active";
    const orders = "lastname ASC, firstname ASC";
    const url = `/users?page=0&select=${fields}&orderBy=${orders}`;
    const body = {
      params: {
        where: `(fullname LIKE '%${searchTerm}%' OR companyname LIKE '%${searchTerm}%' OR email LIKE '%${searchTerm}%') AND active = 0`
      }
    };
    const response = await restClientAdmin.get(url, body);

    dispatch({
      type: actions.FETCH_DELETED_USERS,
      payload: response.data.data
    });
  } catch (e) {
    dispatch(call.stopPreloader());
  }

  // stop preloader
  dispatch(call.stopPreloader());
};

export function unmountView() {
  return {
    type: actions.CLEAN_DELETED_USERS
  };
}
