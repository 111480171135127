import React from "react";
import { WithModal, LoaderButton, IconLabel } from "ctia-ui";
import AddUserModal from "containers/users/UsersList/AddUserModal";

class AddUser extends WithModal {
  render() {
    return (
      <>
        <LoaderButton
          bsStyle="warning"
          text={<IconLabel label="Add User" awesomeIcon="plus" />}
          className="btn-shadow block-top-offset icon-left-offset"
          onClick={this.handleModal}
        />
        <AddUserModal
          show={this.state.showModal}
          modalHandler={this.handleModal}
        />
      </>
    );
  }
}

export default AddUser;
