import React from 'react';
import { connect } from 'react-redux';
import SubmitPayment from 'containers/request/Payments/SubmitPayment';

const StaffSubmitButton = ({ user, request }) => {
  // action not available if not in modify mode or invoice already paid
  if (!user.isStaff || !user.canEditRequest || request.invoice.paid === 0)
    return null;

  return <SubmitPayment submitCallback={() => {}} />;
};

function mapStateToProps({ user, request }) {
  return { user, request };
}

export default connect(mapStateToProps)(StaffSubmitButton);
