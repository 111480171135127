import React, { Component } from "react";
import { connect } from "react-redux";
import DevicesSearch from "containers/Devices/DevicesSearch";
import DeviceList from "containers/Devices/DeviceList";
import { keepDeviceList, clearDeviceList } from "actions/devices";
import { clearModel } from "actions/model";

class Devices extends Component {
  componentDidMount() {
    const { devices } = this.props;

    if (devices && devices.list && !devices.keepList) {
      this.props.clearDeviceList();
      this.props.clearModel();
    }

    this.props.keepDeviceList();
  }

  render() {
    return (
      <div>
        <h1>Approved Requests</h1>
        <DevicesSearch history={this.props.history} />
        <DeviceList />
      </div>
    );
  }
}

function mapStateToProps({ devices }) {
  return { devices };
}

export default connect(mapStateToProps, {
  clearDeviceList,
  keepDeviceList,
  clearModel
})(Devices);
