import _ from 'lodash';

export const certProgramms = {
  ptcrb: 1,
  gcf: 2,
  wifi: 3
};

export const certGroups = {
  ptcrb: {
    label: 'PTCRB',
    checked: true,
    disabled: true,
    id: certProgramms.ptcrb
  },
  gcf: {
    label: 'GCF',
    checked: false,
    disabled: false,
    id: certProgramms.gcf
  },
  wifi: {
    label: 'Wi-Fi Alliance',
    checked: false,
    disabled: false,
    id: certProgramms.wifi
  }
};

export const getCertGroups = () => {
  return _.values(certGroups);
};

export const setCertGroups = ({
  hasLteSupport,
  hasWifiSupport,
  verifiedPtcrb,
  verifiedGcf,
  verifiedWifi
}) => {
  let groups = {};

  if (verifiedPtcrb) {
    groups = {
      ...groups,
      ptcrb: {
        label: 'PTCRB',
        checked: true,
        disabled: true,
        id: certProgramms.ptcrb
      }
    };
  }

  if (hasLteSupport) {
    groups = {
      ...groups,
      ptcrb: {
        label: 'PTCRB',
        checked: verifiedPtcrb,
        disabled: true,
        id: certProgramms.ptcrb
      },
      gcf: {
        label: 'GCF',
        checked: verifiedGcf,
        disabled: false,
        id: certProgramms.gcf
      }
    };
  }

  if (hasWifiSupport) {
    groups = {
      ...groups,
      wifi: {
        label: 'Wi-Fi Alliance',
        checked: verifiedWifi,
        disabled: false,
        id: certProgramms.wifi
      }
    };
  }

  return _.values(groups);
};

export const formatCertGroups = list => {
  // get only ids from band list
  const ids = _.map(list, 'id');

  // iterate default wireless and prepare values
  const groups = _.mapValues(certGroups, (value, key) => {
    const numKey = Number(key);
    const newValue = { ...value, id: numKey };
    newValue.checked = _.indexOf(ids, numKey) > -1;
    return newValue;
  });

  // convert object to array before return
  return _.values(groups);
};

export const getCertGroupsAsString = groups => {
  let arr = [];

  _.forEach(groups, certProgram => {
    if (certProgram.checked) {
      arr.push(certProgram.label);
    }
  });

  return arr.length ? arr.join(', ') : 'none';
};

export const checkCertRequirements = groups => {
  const ptcrb = _.find(groups, { id: certProgramms.ptcrb });
  const gcf = _.find(groups, { id: certProgramms.gcf });
  const wifi = _.find(groups, { id: certProgramms.wifi });

  const ptcrbCertified = Boolean(ptcrb && ptcrb.checked);
  const gcfCertified = Boolean(gcf && gcf.checked);
  const wifiCertified = Boolean(wifi && wifi.checked);

  return {
    lteRequired:
      ptcrb && gcf ? Boolean(!gcfCertified || !ptcrbCertified) : null,
    wifiRequired: wifi ? Boolean(!wifiCertified) : null,
    lteCertified: Boolean(ptcrbCertified || gcfCertified),
    wifiCertified
  };
};

export const verifyWireless = ({
  lteRequired,
  wifiRequired,
  lteCertified,
  wifiCertified
}) => {
  return (lteRequired && !lteCertified) || (wifiRequired && !wifiCertified)
    ? false
    : true;
};

export const getCertGroupsToggled = list => {
  let toggled = [];

  if (!list || !list.length) return toggled;

  _.forEach(list, value => {
    if (value.checked) {
      toggled.push(value.id);
    }
  });

  return toggled;
};
