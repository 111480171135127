import CyberFees from "components/ProgramSelector/Fees/CyberFees";
import OtaFees from "components/ProgramSelector/Fees/OtaFees";
import { certProgramms } from "helpers/AppHelper";
import roles from "dictionaries/UserRoles";

const cyberCertification = {
  name: certProgramms.cyber,
  title: "IoT Cybersecurity",
  subTitle: "Certification Program",
  intro:
    "Establishes an industry baseline for security of Internet of Things (IoT) devices on wireless networks.",
  key: "ctiacert-cyber",
  route: {
    [roles.vendor]: "/cyber/vendor/requests",
    [roles.lab]: "/cyber/lab/requests",
    [roles.staff]: "/cyber/admin/requests",
    [roles.operator]: "/cyber/devices",
    [roles.accountant]: "/cyber/accountant/payments"
  },
  fees: {
    title: "Certification Fees",
    icon: "info-circle",
    btn: "warning",
    content: CyberFees
  }
};

const otaTestPlan = {
  name: certProgramms.ota,
  title: "OTA Test Plan",
  subTitle: "Use Request",
  intro:
    "Submit a request for Over-the-Air Performance testing of your wireless device here.",
  key: "ctiacert-ota",
  route: {
    [roles.vendor]: "/ota/vendor/requests",
    [roles.lab]: "/ota/lab/requests",
    [roles.staff]: "/ota/admin/requests",
    [roles.accountant]: "/ota/accountant/payments"
  },
  fees: {
    title: "Test Plan Use Fees",
    icon: "info-circle",
    btn: "warning",
    content: OtaFees
  }
};

const batteryCertification = {
  name: certProgramms.battery,
  title: "Battery Compliance",
  subTitle: "Certification Program",
  intro:
    "Verifies compliance of mobile device lithium ion batteries with the IEEE 1725™ and IEEE 1625™ standards.",
  key: "ctiacert-battery",
  route: {
    [roles.vendor]: "/battery/vendor/requests",
    [roles.lab]: "/battery/lab/requests",
    [roles.staff]: "/battery/admin/requests",
    [roles.accountant]: "/battery/accountant/payments",
    [roles.supplier]: "/battery/supplier/requests",
    [roles.operator]: "/battery/devices"
  }
};

const hardwareReliabilityCertification = {
  name: certProgramms.dhr,
  title: "Device Hardware Reliability",
  subTitle: "Certification Program",
  intro:
    "Evaluates the hardware reliability of smartphones using an industry standard lifecycle testing methodology.",
  key: "ctiacert-hardware-reliability",
  route: {
    [roles.vendor]: "/dhr/vendor/requests",
    [roles.lab]: "/dhr/lab/requests",
    [roles.staff]: "/dhr/admin/requests",
    [roles.accountant]: "/dhr/accountant/payments",
    [roles.operator]: "/dhr/devices"
  }
};

const batteryLifeCertification = {
  name: certProgramms.blc,
  title: "Battery Life",
  subTitle: "Certification Program",
  intro:
    "Provides an estimate of the expected battery life of smartphones and smartwatches.",
  key: "ctiacert-blc-life",
  route: {
    [roles.vendor]: "/blc/vendor/requests",
    [roles.lab]: "/blc/lab/requests",
    [roles.staff]: "/blc/admin/requests",
    [roles.accountant]: "/blc/accountant/payments",
    [roles.supplier]: "/blc/supplier/requests",
    [roles.operator]: "/blc/devices"
  }
};

const defaultProgramSet = [
  batteryCertification,
  batteryLifeCertification,
  cyberCertification,
  hardwareReliabilityCertification,
  otaTestPlan
];

export default {
  [roles.vendor]: [...defaultProgramSet],
  [roles.lab]: [...defaultProgramSet],
  [roles.staff]: [...defaultProgramSet],
  [roles.operator]: [
    cyberCertification,
    batteryCertification,
    batteryLifeCertification,
    hardwareReliabilityCertification
  ],
  [roles.accountant]: [...defaultProgramSet],
  [roles.supplier]: [batteryCertification],
  [roles.serviceCenter]: []
};
