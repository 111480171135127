import _ from "lodash";
import React from "react";
import UserRequestDispositions from "dictionaries/UserRequestDispositions";

const { pending, rejected } = UserRequestDispositions;

const registrationStatuses = {
  pending: { id: pending, title: "Pending" },
  rejected: { id: rejected, title: "Rejected" }
};

const RenderOptions = () => {
  return _.map(registrationStatuses, (option) => {
    return (
      <option value={option.id} key={option.id}>
        {option.title}
      </option>
    );
  });
};

export default RenderOptions;
