import React from "react";
import { notify } from "react-notify-toast";

export const CTIA_NOTIFICATION = 2;
export const OTA_NOTIFICATION = 13;

export const notifyMessages = {
  requestUpdatedSuccessfully: {
    text: "Request has been successfully updated.",
    type: "success"
  },
  pocEmailAlreadyExists: {
    text: "Email address for PoC already exists.",
    type: "error"
  },
  modelnameAlreadyExists: {
    text: "Model name/number already exists.",
    type: "error"
  },
  cantSignAgreement: {
    text: "An error occurred when signing the License Agreement.",
    type: "error"
  },
  cantSubmitRequest: {
    text: "An error occurred when submitting the request.",
    type: "error"
  },
  cantUpdateRequest: {
    text: "An error occurred when updating the request.",
    type: "error"
  },
  cantWithdrawRequest: {
    text: "An error occurred when withdrawing the request.",
    type: "error"
  },
  cantUnwithdrawRequest: {
    text: "An error occurred when unwithdrawing the request.",
    type: "error"
  },
  cantSendTestResult: {
    text: "An error occurred when sending Test Result.",
    type: "error"
  },
  cantDeleteUploadedFile: {
    text: "An error occurred trying to delete selected file.",
    type: "error"
  },
  documentDeletedSuccessfully: {
    text: "Document has been successfully deleted.",
    type: "success"
  },
  documentApprovedSuccessfully: {
    text: "Document has been successfully approved.",
    type: "success"
  },
  documentRejectedSuccessfully: {
    text: "Document has been successfully rejected.",
    type: "success"
  },
  requestApprovedSuccessfully: {
    text: "Request has been successfully approved.",
    type: "success"
  },
  requestWithdrawnSuccessfully: {
    text: "Request has been successfully withdrawn.",
    type: "success"
  },
  requestUnwithdrawnSuccessfully: {
    text: "Request has been successfully unwithdrawn.",
    type: "success"
  },
  cantDownloadFile: {
    text: "This file can not be downloaded.",
    type: "error"
  },
  cantDeleteFile: {
    text: "This file can not be deleted.",
    type: "error"
  },
  ptcrbModelnameAlreadyExists: {
    text: "Model name/number already exists in your PTCRB certified devices.",
    type: "error"
  },
  cantCreatePoc: {
    text: "An error occurred during creating new PoC.",
    type: "error"
  },
  cantCreateBillingAddress: {
    text: "An error occurred during creating new Billing Address.",
    type: "error"
  },
  cantFetchPocs: {
    text: "An error occurred during fetching existed PoCs.",
    type: "error"
  },
  cantFetchBillingAddresses: {
    text: "An error occurred during fetching existed billing addresses.",
    type: "error"
  },
  cantFetchPTCRBModels: {
    text: "An error occurred during fetching existed PTCRB certified models.",
    type: "error"
  },
  cantCreateBillingEntry: {
    text: "An error occurred during creating new billing entry.",
    type: "error"
  },
  errorFetchRequestAction: {
    text: "An error occurred during fetching the request data from server.",
    type: "error"
  },
  errorAsyncAction: {
    text: "An error occurred during fetching data from server.",
    type: "error"
  },
  certificationVerificationComplete: {
    text: "Verification has been successfully confirmed.",
    type: "success"
  },
  paymentUpdatedSuccessfully: {
    text: "Payment Information has been successfully updated.",
    type: "success"
  },
  invoiceUpdatedSuccessfully: {
    text: "Invoice has been successfully updated.",
    type: "success"
  },
  sessionTimeout: {
    text: "Session expired (you were not active for the last 30 minutes)",
    type: "warning"
  },
  authError: {
    text: "You are not authorized to access this web site. Please, log in.",
    type: "error"
  },
  inactiveCertification: {
    text: "Selected certification program is not available right now.",
    type: "warning"
  },
  invoiceAmountAffectedTestAdded: {
    text: "The test plan has been added. The invoice has been updated to reflect the new total.",
    type: "warning"
  },
  invoiceAmountAffectedTestRemoved: {
    text: "The test plan has been removed. The invoice has been updated to reflect the new total.",
    type: "warning"
  },
  invoiceMissingAddress: {
    text: "Invoice Download Failed. Lab is missing an address.",
    type: "warning"
  },
  cantCreateAddress: {
    text: "An error occurred during creating new Address entry.",
    type: "error"
  },
  cantCreateManufSite: {
    text: "An error occurred during creating new Manufacturing Site.",
    type: "error"
  },
  cantUpdateManufSite: {
    text: "An error occurred during updating Manufacturing Site.",
    type: "error"
  },
  cantUpdateBudgetLimits: {
    text: "An error occurred when attempting to update budget settings.",
    type: "error"
  },
  cantUpdateATL: {
    text: "An error occurred when attempting to update ATL.",
    type: "error"
  },
  cantUpdateASC: {
    text: "An error occurred when attempting to update ASC.",
    type: "error"
  },
  updatedBudgetLimitsSuccesfully: {
    text: "Budget settings have been successfully updated.",
    type: "success"
  },
  mustBeExcelFileMessage: {
    text: "Selected file is either empty or not a supported file type. Only .xls or .xlsx files allowed.",
    type: "error"
  },
  mustBeWordPDFFileMessage: {
    text: "Selected file is either empty or not a supported file type. Only .pdf, .doc, or .docx files allowed.",
    type: "error"
  },
  companyAddedSuccessfully: {
    text: "A company has been successfully added.",
    type: "success"
  },
  companyUpdatedSuccessfully: {
    text: "A company has been successfully updated.",
    type: "success"
  },
  failedToUpdateCompany: {
    text: "Encountered an error while updating the company.",
    type: "error"
  },
  userAddedSuccessfully: {
    text: "A user has been successfully added.",
    type: "success"
  },
  atlBulkingSuccessful: {
    text: "ATL invoices have been successfully updated.",
    type: "success"
  },
  ascBulkingSuccessful: {
    text: "ASC invoices have been successfully updated.",
    type: "success"
  },
  ascBulkingFailed: {
    text: "Error occured when updating ASC invoices.",
    type: "error"
  },
  backgroundJobInActionWarning: {
    text: "Background job is running. Try again in a couple of seconds",
    type: "warning"
  },
  paymentRemovedSuccessfully: {
    text: "Payment has been successfully removed.",
    type: "success"
  },
  cantPostComment: {
    text: "An error occurred when attempting to post a comment.",
    type: "error"
  },
  passwordChangedSuccessfully: {
    text: "Your password has been successfully updated.",
    type: "success"
  },
  passwordChangeError: {
    text: "Your password can't be changed now. Try again",
    type: "error"
  },
  userRequestAccepted: {
    text: "You have accepted this user request. A welcome email has been sent to the user.",
    type: "success"
  },
  userRequestMoreInfo: {
    text: "You have requested more info from this user. Please send them an email requesting the missing information.",
    type: "warning"
  },
  userRequestRejected: {
    text: "You have rejected this user request. An email has been sent to the requester, including the reason of rejection",
    type: "warning"
  },
  userRequestUpdateFailed: {
    text: "An error occurred when submitting this user request.",
    type: "error"
  },
  userActivatedSuccessfully: {
    text: "A user has been successfully activated.",
    type: "success"
  },
  userDeletedSuccessfully: {
    text: "A user has been successfully deleted.",
    type: "success"
  },
  userUpdatedSuccessfully: {
    text: "A user has been successfully updated.",
    type: "success"
  },
  assignedLabsUpdatedSuccessfully: {
    text: "User to Labs assignment has been successfully updated.",
    type: "success"
  }
};

/**
 * Shows a notify message via react-notify-toast.
 *
 * @param {string} message Notifier message
 * @param {string} type Notifier type (success, warning, error)
 */
export const send = (message, type) => {
  return notify.show(<b>{message}</b>, type);
};

/**
 * Get notify object after deleting document
 *
 * @param {number} status HTTP status code
 * @returns notify object
 */
export const getDeleteDocumentResult = status => {
  if (!status) return notifyMessages.errorAsyncAction;

  switch (status) {
    case 200:
      return notifyMessages.documentDeletedSuccessfully;
    default:
      return notifyMessages.cantDeleteFile;
  }
};

export const messageGlyphs = {
  success: "ok",
  error: "remove",
  warning: "exclamation-sign"
};

export const mapOperatorNotifications = userSettings => {
  const defaultOperatorNotifications = {
    ctia: { rights: 0, emails: 0 },
    ota: { rights: 0, emails: 0 }
  };

  if (!userSettings || !userSettings.length)
    return defaultOperatorNotifications;

  const notifications = { ...defaultOperatorNotifications };

  userSettings.forEach(notif => {
    if (notif.emailNotifyId === CTIA_NOTIFICATION) {
      notifications.ctia.rights = notif.mailRights;
      notifications.ctia.emails = notif.emailNotification;
    }

    if (notif.emailNotifyId === OTA_NOTIFICATION) {
      notifications.ota.rights = notif.mailRights;
      notifications.ota.emails = notif.emailNotification;
    }
  });

  return notifications;
};
