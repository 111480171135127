import _ from "lodash";
import React, { useState } from "react";
import { IconLabel } from "ctia-ui";
import { Row, Table } from "react-bootstrap";
import { toggleOrder } from "helpers/SortHelper";
import NoTableData from "components/NoTableData";

const SortableTable = props => {
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortType, setSortType] = useState(null);

  const triggerSort = (field, sortType) => {
    const order = sortField === field ? toggleOrder(sortOrder, true) : "ASC";
    const type = sortType ? sortType : null;

    setSortField(field);
    setSortOrder(order);
    setSortType(type);
  };

  const sortableColumn = (title, fieldname, sortType) => {
    return (
      <button
        className="button-no-border"
        onClick={() => triggerSort(fieldname, sortType)}
      >
        {sortField === fieldname ? (
          <IconLabel
            awesomeIcon={
              sortOrder && sortOrder.toUpperCase() === "ASC"
                ? "caret-up"
                : "caret-down"
            }
            label={title}
            iconFirst={false}
          />
        ) : (
          title
        )}
      </button>
    );
  };

  const renderList = list => {
    if (list.length === 0) {
      return <NoTableData colSpan={props.colNum} message={props.noDataMsg}/>;
    }

    const RowRendererComponent = props.rowRenderer;

    return list.map((item, i) => {
      return <RowRendererComponent item={item} key={i} {...props} />;
    });
  };

  const sortedTableData = _.orderBy(
    props.list,
    item => {
      if (sortType === "date") return new Date(item[sortField]);

      return props.handleSort(item, sortField);
    },
    [sortOrder]
  );

  return (
    <Row>
      <Table striped condensed hover className="ctia-table">
        <thead>
          <tr>
            {props.header.map((col, i) => {
              const key = `sortableTableHeader${i}`;

              // makes the column non-sortable
              if (col.exclude) return <th key={key}>{col.title}</th>;

              // usually uses as a column for actions w/o titles
              if (!col.title || !col.fieldname)
                return <th key={key}>&nbsp;</th>;

              return (
                <th key={key}>
                  {sortableColumn(col.title, col.fieldname, col.dataType)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{renderList(sortedTableData)}</tbody>
      </Table>
    </Row>
  );
};

export default SortableTable;
