import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Switcher, DeleteButton } from "ctia-ui";
import EditButton from "components/form/buttons/EditButton";
import "components/form/CheckboxList/style.css";
import { handleCheckboxGroupChange } from "actions/request";

const CheckboxList = ({
  id,
  list,
  editable,
  selectAll = false,
  onSelectAll,
  readonly = false,
  handleCheckboxGroupChange,
  editHandler,
  removeHandler
}) => {
  if (!list || _.isEmpty(list)) return null;

  const renderSelectAllOption = () => {
    if (readonly) return null;

    const checked = _.every(list, "checked");
    return (
      <Switcher
        show={true}
        checked={checked}
        labelText="Select All"
        onChange={onSelectAll}
        disabled={!editable}
      />
    );
  };

  const renderList = () => {
    return (
      <div className="ctia-form">
        <table className="ctia-form__list">
          <tbody>
            {list.map((elem, i) => {
              // unique id
              const elemId = `${id}-checkbox${i}`;

              return (
                <tr key={elemId}>
                  <td className="checkbox-list__label-container">
                    <input
                      type="checkbox"
                      id={elemId}
                      name={id}
                      value={elem.value}
                      checked={elem.checked}
                      disabled={elem.disabled || !editable}
                      onChange={e => {
                        // @HACK: replace unique id with parent ID
                        const fakeEvent = {
                          ...e,
                          target: { id, checked: e.target.checked }
                        };
                        handleCheckboxGroupChange(list, i, fakeEvent);
                      }}
                    />
                    <label htmlFor={elemId}>{elem.label}</label>
                  </td>
                  <td className="checkbox-list__button-container">
                    {editHandler && (
                      <EditButton
                        size="xs"
                        label="Edit"
                        action={() => editHandler(elem.value)}
                      />
                    )}
                  </td>
                  <td className="checkbox-list__button-container">
                    {removeHandler && (
                      <DeleteButton
                        size="xs"
                        label="Remove"
                        action={() => removeHandler(elem)}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  if (selectAll) {
    return (
      <div>
        <div className="checkbox-list__select-all ctia-form">
          {renderSelectAllOption()}
        </div>
        {renderList()}
      </div>
    );
  }

  return renderList();
};

CheckboxList.propTypes = {
  list: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  selectAll: PropTypes.bool,
  onSelectAll: PropTypes.func,
  readonly: PropTypes.bool
};

export default connect(null, {
  handleCheckboxGroupChange
})(CheckboxList);
