import React from "react";
import RequestContentBSC from "containers/request/battery/RequestContent/RequestContentBSC";
import LabContentBSC from "containers/request/battery/RequestContent/RequestContentLabBSC";
import roles from "dictionaries/UserRoles";

const ContentBatteryBSC = ({ role, focusLab }) => {
  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.supplier:
    case roles.accountant:
    case roles.operator:
      return <RequestContentBSC focusLab={focusLab} />;
    case roles.lab:
      return <LabContentBSC />;
    default:
      return null;
  }
};

export default ContentBatteryBSC;
