import React from "react";
import { FlagIcon } from "react-flag-kit";

export const renderNameWithFlag = (name, flagCode, size = 22) => {
  if (!name || name === "") return null;

  return (
    <>
      <FlagIcon code={flagCode} size={size} />
      &nbsp;{name}
    </>
  );
};
