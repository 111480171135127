import React from "react";
import { connect } from "react-redux";
import AscLogoCanvas from "components/Canvas/AscLogoCanvas";

const AscLogo = ({ user, ascCode }) => {
  const facilityCode = user && user.ASCCode ? user.ASCCode : ascCode;

  if (facilityCode) {
    return <AscLogoCanvas facilityCode={facilityCode} />;
  }

  return null;
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(AscLogo);
