import { getProgramPrefix } from "helpers/AppHelper";
import { getUserPrefix } from "helpers/UsersHelper";
import { generalApiUrl } from "dictionaries/Env";

/**
 * Defines URL prefix by passed params.
 * Prefix uses for react routes and redirection rules.
 *
 * Returns empty string for bad params.
 *
 * @param {object} user Redux store user entity
 * @param {number} certProgram Certification program id
 * @returns string URL prefix as a string
 */
export const getUrlPrefix = (user, certProgram) => {
  if (!user || !certProgram) return "";

  // Pattern: `/<certProgram>/<userRole>/<rest-of-URL>`
  // Example: `/cyber/admin/requests/123`
  const prefixCert = getProgramPrefix(certProgram);
  const prefixUser = getUserPrefix(user);

  return `/${prefixCert}/${prefixUser}/`;
};

/**
 * Check whether current nav link is active.
 *
 * @param {string} href Link href path
 * @param {object} location Current user location object
 * @returns boolean Should the link be active or not
 */
export const isActiveNavLink = (href, location) => {
  if (!href || !location) return false;

  const { pathname } = location;

  // if (!pathname || pathname === '/' || href === '/') return false;
  if (!pathname) return false;

  if (href === "/") {
    return pathname === "/";
  } else {
    const rgxp = new RegExp(href);
    if (pathname.match(rgxp)) return true;
    return pathname.match(rgxp) ? true : false;
  }
};

export const getDeviceRedirectUrl = ({
  isCyberCert,
  isOTACert,
  isBatteryCert,
  isBLCCert
}) => {
  if (isCyberCert) return "/cyber/devices";
  if (isOTACert) return "/ota/devices";
  if (isBatteryCert) return "/battery/devices";
  if (isBLCCert) return "/blc/devices";

  return "/cyber/devices";
};

export const getCatlLogoUrl = (companyId, size = "small") =>
  `${generalApiUrl}/companies/${companyId}/logo?size=${size}`;

// TODO: replace with real API URL
export const getAscLogoUrl = (companyId, size = "small") =>
  `${generalApiUrl}/companies/${companyId}/logo?size=${size}`;

export const getPTCRBLogoUrl = (companyId, size = "small") =>
`${generalApiUrl}/companies/${companyId}/logo?size=${size}`;

export const getIoTLogoUrl = (companyId, size = "small") =>
`${generalApiUrl}/companies/${companyId}/logo?size=${size}`;