import _ from "lodash";
import { restClient, restClientGeneral } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import * as call from "helpers/ActionHelper";
import * as args from "helpers/ArgsHelperBSC";
import * as th from "helpers/RequestTestsHelper";
import * as actions from "actions/types";
import * as sign from "actions/agreementsBattery";
import * as req from "actions/request";
import * as tests from "actions/tests";
import { fileDownload } from "actions/uploads";
import { legacyPtcrbApiUrl } from "dictionaries/Env";

export const fetchAvailableModelsBSC = vendorId => async dispatch => {
  try {
    const url = `/devicemodels?select=devicemodels.id,devicemodels.modelnumber&where=ctiaverified=1 AND bscverified <> 1 AND vendorid=${vendorId}&returnAs=Query&distinct=true&page=0&orderBy=modelnumber`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_BSC_MODELS,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    return Promise.reject(e);
  }
};

export const fetchAvailableModelsPTCRB = vendorId => async dispatch => {
  try {
    const response = await restClient.get(
      `${legacyPtcrbApiUrl}/devicemodels?page=0&availableonly=true&companyid=${vendorId}&where=certtypeid=2&select=id,modelnumber,statusid&&orderBy=modelnumber ASC`
    );

    dispatch({
      type: actions.FETCH_PTCRB_MODELS_FOR_BSC,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantFetchPTCRBModels));

    return Promise.reject(e);
  }
};

export const initBSC = vendorId => async dispatch => {
  dispatch(call.startPreloader());

  return dispatch(fetchAvailableModelsBSC(vendorId)) // <--- fetch modern BSC devices
    .then(() => dispatch(fetchAvailableModelsPTCRB(vendorId))) // <--- fetch PTCRB legacy devices
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const fetchEcoModelsBSC = ({ ieee, vendorId }) => async dispatch => {
  try {
    const url = `/bsc/devicemodels?where=ieee${ieee}bsc=1 AND vendorid=${vendorId}&include=devicemodelbatterycomponent&select=id,modelnumber,capacity,voltage,capacityrateid,voltagerateid&orderBY=modelnumber ASC&page=0`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_BSC_ECO_MODELS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const initEcoBSC = args => {
  return call.dispatchWithPreloader(fetchEcoModelsBSC, args);
};

export const createRequestBSC = request => async dispatch => {
  try {
    const bscArgs = args.defineInitialArgsBSC(request);
    const response = await restClientGeneral.post(
      bscArgs.url,
      JSON.stringify(bscArgs.body)
    );

    const { id, devicemodelid, requests } = response.data.data;
    const newId = _.isArray(requests) ? requests[0].id : id;
    const deviceModelId = _.isArray(requests) ? id : devicemodelid;

    dispatch({
      type: actions.SUBMIT_INITIAL_REQUEST,
      payload: {
        newId,
        deviceModelId,
        isBSC: true
      }
    });

    const agreementSigned =
      request.complianceDeclarationToggler && request.licenseAgreementToggler;

    if (agreementSigned) {
      dispatch({
        type: actions.SUBMIT_WIZARD,
        payload: { isSubmitted: true }
      });
    } else {
      dispatch({
        type: actions.SAVE_WIZARD,
        payload: { isSaved: true }
      });
    }

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitInitialBSC(request) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(createRequestBSC(request))
      .then(res => {
        const { id, devicemodelid } = res.data.data;
        const modelId = devicemodelid || id;
        const needAgreementSign =
          request.complianceDeclarationToggler &&
          request.licenseAgreementToggler;

        if (needAgreementSign) {
          return dispatch(
            sign.signDeclarationAgreement(modelId, request.ieee, "bsc")
          ).then(() =>
            dispatch(
              sign.signRecognitionAgreement(modelId, request.ieee, "bsc")
            )
          );
        } else return res;
      })
      .then(quit)
      .catch(quit);
  };
}

export const createEcoRequestBSC = request => async dispatch => {
  try {
    const reqBody = args.prepareEcoArgsBSC(request);
    const response = await restClientGeneral.post(
      "bsc/requests",
      JSON.stringify(reqBody)
    );
    const { id, devicemodelid } = response.data.data;

    dispatch({
      type: actions.SUBMIT_ECO_REQUEST,
      payload: {
        newId: id,
        deviceModelId: devicemodelid,
        isBSC: true
      }
    });

    dispatch({
      type: actions.SUBMIT_WIZARD,
      payload: { isSubmitted: true }
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export const submitEcoBSC = request => {
  return call.dispatchWithPreloader(createEcoRequestBSC, request);
};

export const getRequestDetailsBSC = id => async dispatch => {
  try {
    const url = `/bsc/requests/${id}/details`;
    const res = await restClientGeneral.get(url);
    const payload = res.data.data;

    dispatch({ type: actions.FETCH_REQUEST_BSC, payload });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export const fetchRequestTestsBSC = requestId => async dispatch => {
  try {
    const url = `/bsc/requesttests?include=reports,requesttestconductinglabs,lab,interface&where=requestid=${requestId}`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_REQUEST_TESTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchTestReportsBSC = testModule => async dispatch => {
  try {
    const url = `/bsc/requesttests/${testModule.id}/reports`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_TEST_REPORTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getDetailsBSC(requestId) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getRequestDetailsBSC(requestId))
      .then(res => {
        const { devicemodelid, ieeetypeid } = res.data.data;
        return dispatch(getModelDetailsBSC(devicemodelid, ieeetypeid));
      })
      .then(() => dispatch(fetchRequestTestsBSC(requestId)))
      .then(res => {
        const resData = res.data;
        if (!resData) return Promise.resolve(null);

        const testId = resData.data[0].id;
        return dispatch(fetchTestReportsBSC({ id: testId }));
      })
      .then(quit)
      .catch(quit);
  };
}

export function submitTestResultsBSC(request, testModule) {
  const testResArgs = args.prepareTestResultsArgs(request, testModule);
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(sendTestResultsBSC(testResArgs, request.id))
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch({
          type: actions.SAVE_REQUEST,
          payload: { formSaved: true, isLoading: true }
        });
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });
  };
}

export const sendTestResultsBSC = (args, reqId) => async dispatch => {
  try {
    return await restClientGeneral.put(
      `/bsc/requests/${reqId}`,
      JSON.stringify(args)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSendTestResult));

    return Promise.reject(e);
  }
};

export const updateModelBSC = modelArgs => async dispatch => {
  try {
    const url = `/bsc/devicemodels/${modelArgs.args.modelId}`;
    return await restClientGeneral.put(url, JSON.stringify(modelArgs));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function signSavedInitialBSC(modelId, requestId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(sign.signDeclarationAgreement(modelId, ieee, "bsc"))
      .then(() => dispatch(sign.signRecognitionAgreement(modelId, ieee, "bsc")))
      .then(() => dispatch(getRequestDetailsBSC(requestId)))
      .then(quit)
      .catch(quit);
  };
}

export const getModelDetailsBSC = (modelId, ieee) => async dispatch => {
  try {
    const url = `/bsc/requests?include=linkedrequests,requestpendingrecognitions,requestunrecognizedadapters&where=devicemodelid=${modelId} AND requeststatusid <> 8 AND ieeetypeid=${ieee}&orderBY=requestid DESC&perPage=1`;
    return await restClientGeneral.get(url);
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export function fetchModelBSC(modelId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getModelDetailsBSC(modelId, ieee))
      .then(res => {
        const payload = res.data.data[0];

        dispatch({
          type: actions.FETCH_BSC_MODEL,
          payload
        });

        return res;
      })
      .then(quit)
      .catch(quit);
  };
}

export const updateDeviceModel = deviceModel => async dispatch => {
  try {
    return await restClientGeneral.put(
      `/bsc/devicemodels/${deviceModel.args.modelId}`,
      JSON.stringify(deviceModel)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function updateDeviceInfoBSC(request) {
  const deviceModel = args.prepareModelArgsBSC(request);
  const reqArgs = args.prepareRequestArgs(request);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(updateDeviceModel(deviceModel))
      .then(() => dispatch(req.updateRequest(reqArgs)))
      .then(res => {
        const { requeststatusid } = res.data.data;

        // update request status if any:
        dispatch({
          type: actions.CHANGE_REQUEST_FIELD,
          payload: { field: "status", value: requeststatusid }
        });

        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

export function makeLabDecisionBSC(decision, request) {
  const statusId = th.getLabDecisionStatus(decision);
  const url = `bsc/requests/${request.id}/requesttests?statusid=${statusId}`;

  return {
    type: actions.MAKE_LAB_DECISION,
    payload: restClientGeneral.put(url)
  };
}

export function resetRequestPermissionsForLabBSC() {
  const payload = {
    canEditRequest: false,
    canEditDeviceInfo: false
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export function setLabTestPermissionsBSC() {
  const payload = {
    canEditRequest: true,
    canEditDeviceInfo: true
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export const updateTestReportStatusBSC = ({
  requestId,
  testId,
  reportSlot,
  reportStatus
}) => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(tests.changeReportStatus(testId, reportSlot, reportStatus, "bsc"))
    .then(res => {
      const { documentstatusid, report } = res.data.data;

      dispatch({
        type: actions.UPDATE_REPORT_STATUS,
        payload: {
          slot: report.slot,
          status: documentstatusid,
          testId
        }
      });

      return res;
    })
    .then(() => dispatch(getDetailsBSC(requestId)))
    .then(() => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
    })
    .catch(err => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

      return Promise.reject(err);
    });
};

export function downloadReportBSC(document, request) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this

  // file name pattern: BSC<000000>_REPORT_<Slot>.<extension>
  // e.g. BSC000345_REPORT_3.pdf
  const fileName = `${request.tn}_REPORT_${slot}.${document.document.extension}`;
  const url = `/bsc/requesttests/${test}/reports/${slot}?download=1`;

  return fileDownload(fileName, url, "general");
}

export function downloadAllReportsBSC(request) {
  // file name: BSC<000000>_REPORTS.zip
  // e.g. BSC000345_REPORTS.zip
  const fileName = `${request.tn}_REPORTS.zip`;
  const url = `/bsc/requests/${request.id}/reports?download=true`;

  return fileDownload(fileName, url, "general");
}
