import React from "react";
import { connect } from "react-redux";
import { Bubble } from "ctia-ui";
import PackCellsSelectSupplier from "containers/request/battery/PackCells/PackCellsSelectSupplier";
import PackCellsSelectModel from "containers/request/battery/PackCells/PackCellsSelectModel";
import PackCellsAdd from "containers/request/battery/PackCells/PackCellsAdd";

const PackCellsSelector = ({ suppliers }) => {
  if (!suppliers) return null;

  if (suppliers.cells && suppliers.cells.length === 0) {
    return (
      <Bubble type="danger">
        There are no approved Cells yet. At least one cell must be selected when
        Pack is chosen as the device type.
      </Bubble>
    );
  }

  return (
    <div>
      <PackCellsSelectSupplier />
      <PackCellsSelectModel />
      <PackCellsAdd />
    </div>
  );
};

function mapStateToProps({ suppliers }) {
  return { suppliers };
}

export default connect(mapStateToProps)(PackCellsSelector);
