import _ from "lodash";

/**
 * Convert a string to upper case.
 *
 * Each word of string will be converted.
 *
 * @param {string} str String to be converted
 * @returns {string} Converted (upper-cased) string
 */
export const toTitleCase = str =>
  str.toLowerCase().replace(/(^| )(\w)/g, s => s.toUpperCase());

/**
 * String shortener.
 *
 * Limit string `str` length by cutting it (extra chars) from right side.
 *
 * String limit by default - 50 characters.
 *
 * You can also set a custom length value using `size` param.
 *
 * @param {string} str String to be converted (trimmed)
 * @param {number} size String length. If more - will be trimmed.
 * @returns string Converted string
 */
export const concatStr = (str, size = 50) => {
  if (String(str).length < size) return str;
  return `${str.slice(0, size)}...`;
};

/**
 * Check if URL is matched with current user's location.
 *
 * @param {string} url URL path to compare
 * @returns boolean
 */
export const isMatchURL = url => window.location.href.indexOf(url) > -1;

/**
 * Format number to currency format.
 *
 * @param {number} amount Number to be converted to currency
 * @returns number
 */
export const formatCurrency = amount => {
  if (!_.isNumber(amount)) return `$0.00`;

  const curr = Number(amount);
  const formatted = curr.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return `$${formatted}`;
};

/**
 * Removes file's extension from filename.
 *
 * @param {string} filename Filename with extension
 * @returns string Filename without extension
 */
export const cutFileExtension = filename => filename.replace(/\.[^/.]+$/, "");

/**
 * Removes all letters from passed string.
 *
 * @param {string} str String that may contain letters
 * @returns string String that contains just digits
 */
export const formatNumberString = str => String(str).replace(/\D/g, "");

/**
 * US-based zip code formatter.
 *
 * @param {string/number} zip Postal or ZIP code
 * @returns string Formatted zip code
 */
export const formatUsZip = zip => {
  const zipString = formatNumberString(zip);
  const zipBasic = zipString.slice(0, 5);
  const zipTail = zipString.slice(5);

  // if zip in `99999-9999` format
  if (zipString.length === 9) return `${zipBasic}-${zipTail}`;

  // return just 5 first digits
  return zipBasic;
};

export const formatZipCode = (countryCode, zipCode) => {
  return countryCode === "US" ? formatUsZip(zipCode) : zipCode;
};

export const capitalize = str => {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const addExtraCommaSpaces = str => str.replaceAll(",", ", ");

/**
 * Removes dots, set First/Last name first letter capitalize, etc
 * Example:
 * - name from database: "linus.TORVALDS"
 * - after format applied: "Linus Torvalds"
 * @param {string} nameString First and Last name combined string
 * @returns string Formatted name in format: "Firstname Lastname"
 */
export const formatName = nameString => {
  return nameString
    .toLowerCase()
    .replace(/[.,;]/g, " ") // replace dots, commas and semicolons
    .replace(/\s\s+/g, " ") // replace extra spaces
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
