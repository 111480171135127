import React from "react";
import { Link } from "react-router-dom";

const BackHome = () => {
  return (
    <h3 className="text-right">
      <Link to="/" className="glow-link cursor-pointer">
        Return to Home &rarr;
      </Link>
    </h3>
  );
};

export default BackHome;
