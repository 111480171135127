import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import DeviceInfoExtended from "containers/request/Initial/Steps/Vendor/DeviceInfo/DeviceInfoExtended";
// import DeviceInfoSelector from "containers/request/Initial/Steps/Vendor/DeviceInfo/DeviceInfoSelector";
import CyberModelAutocomplete from "containers/request/Initial/Steps/Vendor/DeviceInfo/CyberModelAutocomplete";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import CertCategories from "containers/request/Initial/Steps/Vendor/DeviceInfo/CertCategories";
import DeviceWireless from "containers/request/Initial/Steps/Vendor/DeviceInfo/DeviceWireless";
import RequestManufacturer from "components/request/RequestManufacturer";
import TabInstructions from "components/request/TabInstructions";
import RequestControls from "containers/request/RequestControls";
// import DeviceTypeSelector from 'containers/request/Initial/Steps/Vendor/DeviceInfo/DeviceTypeSelector';
import { notifyMessages } from "helpers/NotifyHelper";
import {
  hasStringValue,
  isInitial,
  isExisted,
  getVendorId
} from "helpers/RequestHelper";
import { emptyMsg } from "helpers/FormHelper";
import { findExistedModelByName } from "helpers/ModelHelper";
import { validateWireless } from "helpers/Wireless";
import instructions from "dictionaries/TabInstructions";
import {
  setValidationStatus,
  handleInputChange,
  setCyberRequestPermissions,
  setValue
} from "actions/request";
import { updateDeviceInfo } from "actions/requestCyber";
import { submitWizardStep } from "actions/wizard";
import { checkPTCRBModels } from "actions/model";
import { ifModelExists } from "actions/model";

class DeviceInfo extends CyberComponent {
  constructor(props) {
    super(props);
    this.state = {
      modelNumberValid: true,
      isExistedRequest: false,
      hasHwVersion: false,
      hasSwVersion: false,
      hasDescChange: false,
      modelNumberPtcrbExists: false
    };
  }

  setInitialState() {
    this.setState({
      isExistedRequest: isExisted(this.props.request),
      hasHwVersion: this.hasHwVersion(),
      hasSwVersion: this.hasSwVersion(),
      hasDescChange: this.hasDescChange()
    });
  }

  componentDidMount() {
    const {
      user,
      request,
      checkPTCRBModels,
      setCyberRequestPermissions
    } = this.props;

    // legacy models required for vendor and staff views only
    const vendorId = getVendorId(request, user);

    if (vendorId) {
      // check for existed ptcrb devices
      checkPTCRBModels(vendorId).then(() => {
        this.setInitialState();
      });
    }

    setCyberRequestPermissions(request, user);
  }

  componentDidUpdate(prevProps) {
    const { request } = this.props;
    if (
      request.hwVersion !== prevProps.request.hwVersion ||
      request.swVersion !== prevProps.request.swVersion ||
      request.descriptionOfChange !== prevProps.request.descriptionOfChange ||
      request.modelName !== prevProps.request.modelName ||
      request.existedModel !== prevProps.request.existedModel ||
      Number(request.deviceType) !== Number(prevProps.request.deviceType)
    ) {
      const isValid =
        this.validateHwVersion() === true &&
        this.validateSwVersion() === true &&
        this.validateDescChange() === true &&
        this.validateBasicFields() === true;
      if (request.isValid !== isValid) {
        this.props.setValidationStatus(isValid);
      }
    }
  }

  hasHwVersion() {
    return hasStringValue(this.props.request, "hwVersion");
  }

  hasSwVersion() {
    return hasStringValue(this.props.request, "swVersion");
  }

  hasDescChange() {
    return hasStringValue(this.props.request, "descriptionOfChange");
  }

  hasModelNumber() {
    const { request } = this.props;
    return (
      hasStringValue(request, "modelName") ||
      // hasStringValue(request, "existedModelName")
      hasStringValue(request, "existedModel")
    );
  }

  validateHwVersion() {
    // field should be valid if it's empty
    if (!this.state.hasHwVersion) return true;
    return this.hasHwVersion();
  }

  validateSwVersion() {
    // field should be valid if it's empty
    if (!this.state.hasSwVersion) return true;
    return this.hasSwVersion();
  }

  validateDescChange() {
    // field should be valid if it's empty
    if (!this.state.hasDescChange) return true;
    return this.hasDescChange();
  }

  validateBasicFields() {
    const { request } = this.props;
    return (
      this.validateModelNumberComponent() &&
      // request.deviceType > 0 &&
      request.certCategory > 0 &&
      validateWireless(request)
    );
  }

  validate() {
    if (!this.props.user.canEditRequest) return true;
    if (this.state.isExistedRequest) {
      return (
        this.validateBasicFields() &&
        this.validateHwVersion() &&
        this.validateSwVersion() &&
        this.validateDescChange()
      );
    }

    // for new submission need to check model name and device type only
    return this.validateBasicFields();
  }

  onSaveClick = () => {
    this.props.updateDeviceInfo(this.props.request);
  };

  onContinueClick = () => {
    const {
      request,
      model,
      user,
      setValue,
      ifModelExists,
      submitWizardStep
    } = this.props;

    if(!_.isNull(model)) {

      // check if entered model name exists
      const existedModel = findExistedModelByName(
        request.existedModel,
        model.ptcrbModels
      );

      // if so - use existed model id
      const existedModelId = existedModel ? existedModel.value : null;
      const isExistedModelLegacy = existedModel ? true : false;

      // update request field in redux
      setValue("existedModelId", existedModelId);
      setValue("isExistedModelLegacy", isExistedModelLegacy);

      // check modelname uniqueness
      if (!existedModel) {
        ifModelExists(request.existedModel, user.companyid).then(isUnique => {
          if (isUnique) {
            return submitWizardStep();
          }
        });
      } else {
        return submitWizardStep();
      }
    } else {
      const existedModelId = null;
      const isExistedModelLegacy = false;

      // update request field in redux
      setValue("existedModelId", existedModelId);
      setValue("isExistedModelLegacy", isExistedModelLegacy);

      ifModelExists(request.existedModel, user.companyid).then(isUnique => {
        if (isUnique) {
          return submitWizardStep();
        }
      })
    }
  };

  isValidationRequired = () => {
    return this.props.user.canEditRequest;
  };

  hasPtcrbExistedModels = () => {
    const { model } = this.props;
    return model && model.ptcrbModels && model.ptcrbModels.length > 0;
  };

  isModelFieldValid = () => {
    const { isExistedRequest } = this.state;

    if (isExistedRequest && !this.hasPtcrbExistedModels())
      return this.hasModelNumber();
    return true;
  };

  validateModelNumber = () => {
    if (!this.isValidationRequired()) return null;
    return this.isModelFieldValid() ? null : "error";
  };

  validateModel() {
    const { request, requests } = this.props;
    if (!request || !requests) return false;

    const fieldName = request.id ? "modelName" : "existedModel";

    return hasStringValue(request, fieldName);
  }

  getModelValidationMessage = () => {
    if (!this.state.modelNumberValid)
      return notifyMessages.modelnameAlreadyExists.text;
    if (this.state.modelNumberPtcrbExists)
      return notifyMessages.ptcrbModelnameAlreadyExists.text;
    if (!this.hasModelNumber()) return emptyMsg;
    return null;
  };

  // validateDeviceType = () => {
  //   if (!this.isValidationRequired()) return null;
  //   if (this.state.isExistedRequest) {
  //     return this.props.request.deviceType > 0 ? null : "error";
  //   }
  //   return null;
  // };

  validateCertCategory = () => {
    if (!this.isValidationRequired()) return null;
    if (this.state.isExistedRequest) {
      return this.props.request.certCategory > 0 ? null : "error";
    }
    return null;
  };

  validateModelNumberComponent = () => {
    const { request } = this.props;
    const isModelNumberValid =
      this.hasModelNumber() && _.isNull(this.validateModelNumber());

    return this.hasPtcrbExistedModels()
      ? isModelNumberValid || request.existedModel > 0
      : isModelNumberValid;
  };

  render() {
    if (!this.props.show) return null;

    const { request, user } = this.props;

    const isEditable = Boolean(user.canEditRequest);
    const canEditDeviceType = Boolean(isEditable && isInitial(request.type));
    const canChangeCategory = canEditDeviceType ? !request.isPaid : false;
    const isExistingRequest = !!request.id;

    // const deviceTitle = canEditDeviceType
    //   ? deviceType
    //   : getDeviceTitle(deviceType);

    const saveHandler = isEditable ? this.onSaveClick : null;

    return (
      <div>
        <TabInstructions messages={instructions.deviceInfo} />
        <Form horizontal>
          <RequestManufacturer />
          {/* <DeviceTypeSelector /> */}
          <CyberModelAutocomplete />
          {/* <DeviceInfoSelector
            isEditable={canEditDeviceType}
            onModelChange={this.onModelChange}
            onLegacyModelChange={this.onLegacyModelChange}
            isModelNumberValid={this.validateModelNumber()}
            getModelValidationMessage={this.getModelValidationMessage}
          /> */}
          {/* <FormElement
            type={canEditDeviceType ? 'select' : ''}
            id="deviceType"
            label="Device Type"
            value={deviceTitle}
            onChange={handleInputChange}
            options={getDeviceTypeList()}
            editable={isEditable}
            validationState={this.validateDeviceType()}
            validationMessage={emptyMsg}
          /> */}
          <SubmissionDetails />
          <DeviceWireless editable={canEditDeviceType} />
          {/* Shows extended fields like HW/SW Versions */}
          <DeviceInfoExtended />
          <CertCategories
            activeonly={!isExistingRequest || !user.isStaff}
            readonly={!canChangeCategory}
            editable={isEditable}
            validate={this.validateCertCategory}
          />
          <RequestControls
            isValid={this.validate()}
            onSave={saveHandler}
            onContinue={this.onContinueClick}
          />
        </Form>
      </div>
    );
  }
}

DeviceInfo.propTypes = {
  show: PropTypes.bool
};

DeviceInfo.defaultProps = {
  show: true
};

function mapStateToProps({ request, user, model }) {
  return { request, user, model };
}

export default connect(mapStateToProps, {
  submitWizardStep,
  setValidationStatus,
  checkPTCRBModels,
  handleInputChange,
  updateDeviceInfo,
  setCyberRequestPermissions,
  ifModelExists,
  setValue
})(DeviceInfo);
