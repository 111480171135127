import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import * as asupHelper from "helpers/AdapterSupplierHelper";
import { setValue } from "actions/request";

const SelectAdapterRevision = ({ request, suppliers, setValue }) => {
  const [show, setShow] = useState(false);
  const requestFields = asupHelper.requestFields;
  const requestField = requestFields.revision;
  const adapterModel = request[requestFields.model];
  const {
    certifiedAdapterRequests: crtList,
    recognizedAdapterRequests: recList
  } = suppliers;

  const adapterType = Number(request[requestFields.type]);
  const isRecognition = adapterType === 1;
  const requestList = isRecognition ? recList : crtList;

  useEffect(() => {
    if (adapterModel && adapterModel > 0) {
      setShow(true);
    } else {
      setShow(false);
      setValue(requestField, "");
    }
  }, [adapterModel, requestField, setValue]);

  const onChange = event => {
    const reqId = Number(event.target.value);

    setValue(requestField, reqId);

    const reqDetails = asupHelper.getAdapterRequestById(reqId, requestList);

    if (reqDetails) {
      setValue(requestFields.linkedRequestId, reqDetails.id);
      setValue(requestFields.linkedRequestCapacity, reqDetails.capacity);
      setValue(requestFields.linkedRequestRevision, reqDetails.revision);
    } else {
      setValue(requestFields.linkedRequestId, null);
      setValue(requestFields.linkedRequestCapacity, null);
      setValue(requestFields.linkedRequestRevision, null);
    }
  };

  if (!show || !requestList || !requestList.length) return null;

  // don't render for unrecognized adapters
  if (asupHelper.hasUnrecognizedAdapter(request)) return null;

  return (
    <div>
      <FormElement
        type="select"
        id={requestField}
        onChange={onChange}
        label="Adapter Revision"
        show={Number(request[requestFields.modelOption]) !== 2}
        options={asupHelper.formatAdapterRevisionList(
          requestList,
          request.adapterSuppliers
        )}
        value={request[requestField] || 0}
      />
    </div>
  );
};

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, { setValue })(SelectAdapterRevision);
