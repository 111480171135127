import { Col, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchLabs } from "actions/companies";

const CompanySelector = ({ changeHandler, fetchLabs }) => {
  // Form props for company/lab option
  const [dropdownCompanyNames, setDropdownCompanyNames] = useState([]);

  // Form values
  const [companyForm, setCompanyForm] = useState({
    type: "",
    name: "",
    other: "",
    website: ""
  });

  useEffect(() => {
    fetchLabs()
    .then(payload => {
      setDropdownCompanyNames(payload);
    })
    .catch(error => {
      console.log(error);
    });
  }, [fetchLabs]);

  const formValuesForOptions = ["Manufacturer", "Supplier", "Lab", "Operator"];

  const handleInputChange = event => {
    let { type, name, other, website } = companyForm;
    const { id, value } = event.target;

    // TODO: this code sucks
    // NEVER EVER mutate state values directly!!!
    // refactor this part ASAP
    switch (id) {
      case "companyType":
        type = value;
        break;
      case "companyName":
        name = value;
        other = "";
        break;
      case "companyOther":
        other = value;
        break;
      case "website":
        website = value;
        break;
      default:
        break;
    }

    setCompanyForm({ type, name, other, website });

    changeHandler(event);
  };

  const populateCompanyNameDropdown = () => {
    const options = dropdownCompanyNames.map(companyObjects => {
      const { name } = companyObjects;
      return (
        <option key={`option${name}`} value={name}>
          {name}
        </option>
      );
    });

    // prepend a 'select one' option with nil value
    options.unshift(
      <option key="selectOne" value="">
        --- Select One ---
      </option>
    );
    // append an option for "other", which will trigger a manual input
    options.push(
      <option key="enterOther" value="other">
        --- Enter Other ---
      </option>
    );

    return options;
  };

  const renderCompanyNameInput = (useSelector = false) =>
    useSelector ? (
      <FormControl
        componentClass="select"
        value={name}
        onChange={handleInputChange}
      >
        {populateCompanyNameDropdown()}
      </FormControl>
    ) : (
      <FormControl value={name} onChange={handleInputChange} />
    );

  const { type, name, other, website } = companyForm;
  const showCompanyNameSelector = type === formValuesForOptions[2];
  const showCompanyOtherField = showCompanyNameSelector && name === "other";

  const textAreaField = document.getElementById("description");

  // trick to define the textarea height
  if (textAreaField) {
    const height = showCompanyOtherField ? 270 : 190;
    textAreaField.style = `height: ${height}px`;
  }

  return (
    <div>
      {/* Company Type */}
      <FormGroup controlId="companyType">
        <Col componentClass={ControlLabel}>
          <span className="text-danger">* </span>
          My company is a
        </Col>
        <Col>
          <FormControl
            componentClass="select"
            value={type}
            onChange={handleInputChange}
          >
            <option value="">--- Select One ---</option>
            <option value={formValuesForOptions[0]}>
              Vendor/Supplier of wireless devices
            </option>
            <option value={formValuesForOptions[1]}>
              Vendor/Supplier of battery cells, packs or adapters
            </option>
            <option value={formValuesForOptions[2]}>
              CTIA Authorized Test Lab
            </option>
            <option value={formValuesForOptions[3]}>Network Operator</option>
          </FormControl>
        </Col>
      </FormGroup>

      {/* Company Name */}
      <FormGroup controlId="companyName">
        <Col componentClass={ControlLabel}>
          <span className="text-danger">* </span>
          {showCompanyNameSelector
            ? "Search for Company"
            : "Enter Company Name"}
        </Col>
        <Col>{renderCompanyNameInput(showCompanyNameSelector)}</Col>
      </FormGroup>

      {/* Company Other if selected in dropdown above*/}
      {showCompanyOtherField && (
        <FormGroup controlId="companyOther">
          <Col componentClass={ControlLabel}>
            <span className="text-danger">* </span>
            Other
          </Col>
          <Col>
            <FormControl value={other} onChange={handleInputChange} />
          </Col>
        </FormGroup>
      )}

      {/* Company Website */}
      <FormGroup controlId="website">
        <Col componentClass={ControlLabel}>
          <span className="text-danger">* </span>
          Company Website
        </Col>
        <Col>
          <FormControl value={website} onChange={handleInputChange} />
        </Col>
      </FormGroup>
    </div>
  );
};

CompanySelector.propTypes = {
  onChange: PropTypes.func
};

export default connect(null, { fetchLabs })(CompanySelector);
