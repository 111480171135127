import _ from "lodash";
import { formatDate } from "helpers/DateHelper";
import { makeSimpleArgs } from "helpers/ArgsHelper";
import { getSimpleArrayByKey } from "helpers/ArrayHelper";

export const prepareInitialArgsBLC = request => {
  return makeSimpleArgs({
    devicemodelid: request.devicemodelid,
    devicetype: request.deviceType,
    billingid: Number(request.billingId),
    pocid: Number(request.pocId),
    purchaseorder: request.billingOrderNum,
    requesttypeid: 1,
    primarylabid: request.cdmaLab,
    hwversion: request.hwVersion,
    swversion: request.swVersion,
    operatingsystemid: request.operatingSystem,
    linkedrequests: [{ linkedrequestid: request.batteryPackId }]
  });
};

export const prepareEcoArgsBLC = request => {
  const { linkedrequestid } = request.parentRequest.linkedrequests[0];

  return makeSimpleArgs({
    devicemodelid: request.devicemodelid,
    devicetype: request.devicetype,
    requesttypeid: 2,
    pocid: Number(request.pocId),
    primarylabid: request.cdmaLab,
    hwversion: request.hwVersion,
    swversion: request.swVersion,
    operatingsystemid: request.operatingsystemid,
    parentid: request.parentRequest.id,
    linkedrequests: [{ linkedrequestid }]
  });
};

export const getRequestFieldsForTestingBLC = ({
  id,
  revision,
  crslversion
}) => {
  return makeSimpleArgs({
    requestId: id,
    revision,
    crslversion
  });
};

export const getTestFieldsBLC = testModule => {
  const {
    id,
    planversion,
    actualstartdate,
    actualenddate,
    requesttestpassedstatusid
  } = testModule;

  // @NOTE: All battery types require testing dates range
  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return {
    id,
    ...dates,
    planversion,
    requesttestpassedstatusid
  };
};

export const prepareModelArgsBLC = request => {
  return makeSimpleArgs({
    modelId: request.model.id,
    modelnumber: request.modelName
  });
};

export const getDeviceFieldsForTestingBLC = request => {
  return makeSimpleArgs({
    modelId: request.model.id
  });
};

export const prepareRequestArgs = (request, sites) => {
  const { id, hwVersion, swVersion, batteryPackId, operatingSystem, deviceType, modelName } = request;

  return {
    id,
    devicetype: deviceType,
    requeststatusid: request.status,
    modelName: modelName,
    modelId: request.model.id,
    hwversion: hwVersion,
    swversion: swVersion,
    operatingsystemid:operatingSystem,
    linkedrequests: [{ linkedrequestid: batteryPackId }],
    urlPrefix: "blc",
    completedat: request.completedat
  };
};

export const getLinkedRequests = request => {
  let linkedRequests = [];

  const { batterySuppliers: bsup } = request;

  // map Battery Packs
  if (bsup && bsup.length > 0) {
    const packIds = _.compact(getSimpleArrayByKey(bsup, "linkedrequestid"));

    if (packIds.length) {
      packIds.forEach(packId => {
        linkedRequests.push({
          linkedrequestid: packId
        });
      });
    }
  }

  return linkedRequests;
};

export const prepareTestResultsArgs = (request, testModule, certTypes) => {
  const testArgs = getTestFieldsBLC(testModule);

  const {
    model,
    devicetype,
    hwVersion: hwversion,
    swVersion: swversion,
    operatingSystem: operatingsystemid,
    batteryPackId
  } = request;

  const devicemodel = {
    id: model.id
  };

  const args = {
    devicemodel,
    devicetype,
    operatingsystemid,
    hwversion,
    swversion,
    requesttests: [
      {
        ...testArgs
      }
    ],
    linkedrequests: [{ linkedrequestid: batteryPackId }]
  };

  return makeSimpleArgs(args);
};
