import React, { useState } from "react";
import { connect } from "react-redux";
import { DeleteButton, Drawer } from "ctia-ui";
import { removeCellSupplier } from "actions/suppliers";
import RecognizedSubsystem from "containers/request/battery/PackCells/RecognizedSubsystem";
import ModelBadge from "containers/request/battery/PackCellsWithRecognition/PendingRecognitionBadge";

const CellSupplierListRow = ({
  request,
  supplier,
  suppliers,
  removeCellSupplier
}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  if (!supplier) return null;

  if (request.cellSuppliers.length > 0) {
    const {
      linkedrequestdevicemodelid: cellModelId,
      linkedrequestvendorname: supplierName,
      linkedrequestvendorid: vendorId,
      linkedrequestid: reqId
    } = supplier;

    const getIdToBeRemoved = () => {
      return { requestId: reqId || null, vendorId: reqId ? null : vendorId };
    };

    return (
      <tr>
        <td>{supplierName}</td>
        <td>
          <span
            onClick={toggleDrawer}
            className="agr-link cursor-pointer glow-link"
          >
            <ModelBadge row={supplier} />
          </span>
          <Drawer
            position="bottom"
            show={showDrawer}
            title="Recognized Subsystem Detail"
            toggleDrawer={toggleDrawer}
            content={<RecognizedSubsystem modelId={cellModelId} certPrefix="crr" />}
            height="auto"
          />
        </td>
        <td>
          <DeleteButton
            size="xs"
            label="Remove Cell"
            action={() => removeCellSupplier(getIdToBeRemoved())}
          />
        </td>
      </tr>
    );
  }

  return null;
};

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, {
  removeCellSupplier
})(CellSupplierListRow);
