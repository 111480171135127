import _ from "lodash";
import moment from "moment";
import str2num from "libs/StringConverter";
import numFields from "dictionaries/NumberFields";
import statuses from "dictionaries/FileStatuses";

export const formatValue = (name, value) => {
  if (!name) {
    console.warn("Field name has not been provided");
    return undefined;
  }

  // array values should go untouched
  if (Array.isArray(value)) {
    return value;
  }

  // check if passed value is a Moment-JS object:
  if (moment.isMoment(value)) {
    return value;
  }

  // trim whitespaces from start (left)
  const val = _.trimStart(value);

  if (_.isEmpty(val)) {
    return "";
  }

  // Check if form field should be converted string value to number
  return _.includes(numFields, name) ? str2num(val) : val;
};

export const getInputValue = event => {
  const { id, name, value } = event.target;
  const param = id || name;

  return {
    field: param,
    value: formatValue(param, value)
  };
};

export const setManualData = fileData => {
  const field = "usersManual";
  const newData = { ...fileData };

  newData.status = statuses.pending;

  return {
    field,
    value: newData
  };
};

export const changeFileStatus = (fileData, decision) => {
  const newData = { ...fileData };

  newData.status = decision === true ? statuses.approved : statuses.rejected;

  return newData;
};

export const getCheckboxGroupValue = (index, event, values) => {
  const { id, checked } = event.target;

  // copy current checkbox group (as array)
  const group = values.slice();

  // set clicked checkbox value
  group[index].checked = checked;

  return {
    field: id,
    value: group
  };
};

export const setCheckboxGroupValues = (event, values) => {
  const { id, checked } = event;

  // set checkbox value for all values in group
  const group = values.slice().map(option => {
    return { ...option, checked };
  });

  return {
    field: id,
    value: group
  };
};

export const setSelectedGroupValue = (index, event, values) => {
  const { id, value } = event.target;

  // copy current group
  const group = values.slice();

  // set a new value for selected id
  const newValues = group.map((elem, i) => {
    if (index === elem.id) {
      elem.status = Number(value);
    }

    return elem;
  });

  return {
    field: id,
    value: newValues
  };
};

export const getFileData = (event, fileData) => {
  const { id } = event.target;
  const uploadedFile = document.getElementById(id).files[0];
  const newData = { ...fileData };

  newData.file = uploadedFile;

  return newData;
};

export const updateDocumentTitle = (index, value, documents) => {
  const list = [...documents];

  list[index].title = value;

  return list;
};

export const updateDocumentFile = (index, value, documents) => {
  const list = [...documents];

  list[index].file = value;

  return list;
};

export const getHtmlCollectionSelectedValues = collection => {
  let selectedValues = [];

  for (var i = 0; i < collection.length; i++) {
    if (collection[i].selected) {
      selectedValues.push(Number(collection[i].value));
    }
  }

  return selectedValues;
};

export const makeEvent = (id, value) => {
  return { target: { id, value } };
};
