import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchCountryStates } from "actions/countries";
import StateStaticReadonly from "components/form/StateStaticReadonly";

const StateAndProvinceSelector = ({
  fieldValue,
  selectedCountryCode,
  changeHandler,
  countries,
  fetchCountryStates
}) => {
  const [componentParams, setParams] = useState({
    states: []
  });

  const isStateRequired = Boolean(
    selectedCountryCode === "US" || selectedCountryCode === "CA"
  );

  useEffect(() => {
    if (!_.isEmpty(selectedCountryCode) && isStateRequired) {
      fetchCountryStates(selectedCountryCode);
    }
    setParams({ states: [] });
  }, [fetchCountryStates, selectedCountryCode, isStateRequired]);

  useEffect(() => {
    let stateValues = [];
    if (countries !== null && countries.states !== null) {
      stateValues = countries.states;
    }
    setParams({ states: stateValues });
  }, [countries]);

  const renderOptions = () => {
    if (isStateRequired && !_.isEmpty(componentParams.states)) {
      const formOptions = componentParams.states.map(
        (currentOption, currentIndex) => {
          const optionLabel = currentOption.name;
          const optionValue = !_.isEmpty(currentOption.short)
            ? currentOption.short
            : currentOption.name;

          return (
            <option value={optionValue} key={currentIndex}>
              {optionLabel}
            </option>
          );
        }
      );

      return formOptions;
    }

    return null;
  };

  if (!isStateRequired) {
    return <StateStaticReadonly asBlock={false} />;
  }

  return (
    <FormControl
      componentClass="select"
      value={fieldValue}
      onChange={changeHandler}
    >
      <option value="">--- Select One ---</option>
      {renderOptions()}
    </FormControl>
  );
};

StateAndProvinceSelector.propTypes = {
  field: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func
};

function mapStateToProps({ countries }) {
  return { countries };
}

export default connect(mapStateToProps, { fetchCountryStates })(
  StateAndProvinceSelector
);
