import 'rc-collapse/assets/index.css';
import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import Collapse, { Panel } from 'rc-collapse';
import { expandIcon } from 'helpers/ExpandIcon';

class CollapsablePanels extends Component {
  state = { activeKey: this.props.tabs[0].key };

  renderTabsContent = () => {
    return this.props.tabs.map(tab => {
      const TabComponent = tab.component;
      return (
        <Panel header={tab.title} key={tab.key}>
          <TabComponent />
        </Panel>
      );
    });
  };

  onChange = activeKey => {
    this.setState({ activeKey });
  };

  render() {
    return (
      <Col xs={12} sm={12} md={10}>
        <h4>Click any section to expand:</h4>
        <Collapse
          accordion={false}
          onChange={this.onChange}
          activeKey={this.state.activeKey}
          expandIcon={expandIcon}
        >
          {this.renderTabsContent()}
        </Collapse>
      </Col>
    );
  }
}

export default CollapsablePanels;
