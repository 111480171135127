import * as nav from "dictionaries/Navigation";

/* 
  Roles:
    1 - staff
    2 - vendor
    3 - lab
    4 - accountant
    5 - operator
    6 - supplier
*/

export const cyberNavTabs = {
  1: nav.cyberStaffNav,
  2: nav.cyberVendorNav,
  3: nav.cyberLabNav,
  4: nav.cyberAccountantNav,
  5: nav.cyberOperatorNav
};

export const batteryNavTabs = {
  1: nav.batteryStaffNav,
  2: nav.batteryVendorNav,
  3: nav.batteryLabNav,
  4: nav.batteryAccountantNav,
  5: nav.batteryOperatorNav,
  6: nav.batterySupplierNav
};

export const otaNavTabs = {
  1: nav.otaStaffNav,
  2: nav.otaVendorNav,
  3: nav.otaLabNav,
  4: nav.otaAccountantNav,
  5: nav.otaOperatorNav
};

export const dhrNavTabs = {
  1: nav.dhrStaffNav,
  2: nav.dhrVendorNav,
  3: nav.dhrLabNav,
  4: nav.dhrAccountantNav,
  5: nav.dhrOperatorNav
};

export const blcNavTabs = {
  1: nav.blcStaffNav,
  2: nav.blcVendorNav,
  3: nav.blcLabNav,
  4: nav.blcAccountantNav,
  5: nav.blcOperatorNav
};
