import React from "react";
import PropTypes from "prop-types";
import ReqLink from "components/RequestsLink";
import NoTableData from "components/NoTableData";
import { getRequestTypeTitle } from "helpers/RequestHelper";
import { getTestResultTitle } from "helpers/RequestTestsHelper";

const LabResultRow = ({ tableRows }) => {
  if (tableRows.length) {
    return tableRows.map(row => {
      const {
        id,
        name,
        modelnumber,
        requesttypeid,
        testingstatus,
        formatedtransactionnumber
      } = row;

      const tn = formatedtransactionnumber;
      const testStatus = getTestResultTitle(testingstatus);

      return (
        <tr key={id}>
          <td>
            <ReqLink linkTitle={tn} requestId={id} category="results" />
          </td>
          <td>{name}</td>
          <td>{modelnumber}</td>
          <td>{getRequestTypeTitle(requesttypeid)}</td>
          <td>{testStatus}</td>
        </tr>
      );
    });
  }

  return <NoTableData colSpan={5} />;
};

LabResultRow.propTypes = {
  tableRows: PropTypes.array.isRequired
};

export default LabResultRow;
