import { connect } from "react-redux";
import React from "react";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";
import { hasStringValue } from "helpers/RequestHelper";
import { emptyMsg } from "helpers/FormHelper";

class RequestDescChanges extends React.Component {
  state = { show: false, touched: false };

  componentDidMount() {
    this.setState({
      show: this.hasFieldValue()
    });
  }

  isValidationRequired = () => {
    return this.props.user.canEditRequest;
  };

  hasFieldValue() {
    const { app, request } = this.props;

    // check for cyber program
    if (app.isCyberCert) {
      return hasStringValue(request, "descriptionOfChange");
    }

    // check for battery program
    if (app.isBatteryCert) {
      return hasStringValue(request, "descriptionOfChange") || request.isEco;
    }

    // check for DHR program
    if (app.isDHRCert) {
      return hasStringValue(request, "descriptionOfChange") || request.isEco;
    }

    return false;
  }

  validateField() {
    if (!this.isValidationRequired() || !this.state.touched) return null;

    return this.state.show &&
      hasStringValue(this.props.request, "descriptionOfChange")
      ? null
      : "error";
  }

  onFieldChanged = event => {
    if (!this.state.touched) {
      this.setState({ touched: true });
    }

    this.props.handleInputChange(event);
  };

  render() {
    const { request, user } = this.props;
    const isEditable = user.canEditRequest || user.canEditDeviceInfo;
    const inputType = isEditable ? "text" : "";

    return (
      <FormElement
        id="descriptionOfChange"
        label="Description of Change"
        value={request.descriptionOfChange}
        show={this.state.show && request.isEco}
        editable={isEditable}
        type={inputType}
        onChange={this.onFieldChanged}
        validationState={this.validateField()}
        validationMessage={emptyMsg}
      />
    );
  }
}

function mapStateToProps({ app, request, user }) {
  return { app, request, user };
}

export default connect(mapStateToProps, { handleInputChange })(
  RequestDescChanges
);
