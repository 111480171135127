import React from "react";
import { connect } from "react-redux";
import RequestControls from "containers/request/RequestControls";
import { countCheckedAgreements } from "containers/request/LicenseAgreement/BatteryAgreement/agreementHelper";
import {
  submitInitialHRR,
  signSavedInitialHRR
} from "actions/battery/hrrRequest";
import { getAgreementList } from "dictionaries/BatteryAgreementTypes";

const BatteryAgreementControlsHRR = ({
  request,
  sites,
  user,
  wizard,
  submitInitialHRR,
  signSavedInitialHRR
}) => {
  if (!request) return null;

  const requestWithSites = { ...request, manufSites: sites.nonSarSites };
  const wizardSubmitHandler = () => submitInitialHRR(requestWithSites);

  let onSaveHandler;

  let isValid;
  let validToSave;
  let validToSubmit;
  let agreedAmount = 0;

  // logic for submission
  if (wizard) {
    const { requiredAgreements } = wizard.batteryCert.certInfo;
    const totalAgreements = requiredAgreements.length;

    // let's count how many checkboxes selected on agreement page
    agreedAmount = countCheckedAgreements(request);

    // can request be saved
    if (request.canBeSaved && agreedAmount === 0) {
      validToSave = true;
    }

    // can request be submitted
    else if (agreedAmount === totalAgreements) {
      validToSubmit = true;
    }
  } else {
    // logic for existed request
    if ((user.isSupplier || user.isVendor) && !request.agreementAccepted) {
      onSaveHandler = () => {
        return signSavedInitialHRR(request.model.id, request.id, request.ieee);
      };

      const totalAgreements = getAgreementList(request).length || 0;

      // let's count how many checkboxes selected on agreement page
      agreedAmount = countCheckedAgreements(request);

      // can request be saved
      if (agreedAmount === totalAgreements) isValid = true;
    }
  }

  return (
    <RequestControls
      show={true}
      isValid={isValid}
      isValidToSave={validToSave}
      isValidToSubmit={validToSubmit}
      onSave={onSaveHandler}
      onSubmit={wizardSubmitHandler}
      onWizardSave={wizardSubmitHandler}
    />
  );
};

function mapStateToProps({ request, sites, wizard, user }) {
  return { request, sites, wizard, user };
}

export default connect(mapStateToProps, {
  submitInitialHRR,
  signSavedInitialHRR
})(BatteryAgreementControlsHRR);
