import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getModelName } from "helpers/RequestHelper";
import { mapAdapterValues } from "helpers/BatterySupplierHelper";
import { mapBatteryPacksValues } from "helpers/BatterySupplierHelper";
import MainAgreementText from "components/LicenseAgreementContentText";

// TODO: replace with real data from server when BSC implemented
// const dummyBattery = {
//   battery: { linkTitle: "Test", asText: false },
//   adapter: { linkTitle: "Pending Recognition", asText: true }
// };

// BSC License Agreement -- modal content
const LicenseContent = ({ request, user, version }) => {
  const modelName = getModelName(request);
  const manufacturer = request.vendor || user.company;
  const signedDate =
    request && request.isApproved
      ? moment(request.completeDate).format("MM/DD/YYYY")
      : undefined;
  const batterySystem = {
    adapter: mapAdapterValues(request.adapterSuppliers),
    battery: mapBatteryPacksValues(request.batterySuppliers)
  };

  return (
    <MainAgreementText
      manufacturer={manufacturer}
      signedDate={signedDate}
      modelName={modelName}
      batterySystem={batterySystem}
      version={version}
    />
  );
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(LicenseContent);
