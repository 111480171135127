import _ from "lodash";
import * as actions from "actions/types";
import { getCurrATLYear } from "helpers/DateHelper";

const extendWithBulk = (labs, year) => {

  return labs.map(member => {
    const hasInvoice = member[`ATLAnualLicenseFeeKey${year}`] !== "";
    const membershipPaid = member[`hasATLMembershipCost${year}`] === 1;
    const atlFeePaid = member[`hasATLFeeCost${year}`] === 1;

    return {
      hasInvoice,
      membershipPaid,
      atlFeePaid,
      ...member
    };
  });
};

const toggleFee = (action, state, isMembership = true) => {
  const labId = action.payload;
  const atl = _.find(state.labs, ["id", labId]);

  if (!atl) {
    console.error("ATL with id ", labId, " hasn't been found.");
    return state.labs;
  }

  const prop = isMembership ? "membershipPaid" : "atlFeePaid";

  atl[prop] = atl[prop] ? false : true;

  // also mark lab to be involved in bulk action
  atl.touched = true;

  return [...state.labs];
};

const toggleAllFees = (action, state, isMembership = true) => {
  const currValue = action.payload;
  const prop = isMembership ? "membershipPaid" : "atlFeePaid";

  return state.labs.map(lab => {
    lab[prop] = currValue ? false : true;

    // also mark lab to be involved in bulk action
    lab.touched = true;

    return lab;
  });
};

const updateATLPayment = (action, state, positive = true) => {
  const data = action.payload;
  const labIdx = _.findIndex(state.labs, { id: data.companyid });
  _.set(
    state,
    `labs[${labIdx}].ATLAnualLicenseFeePaid${data.targetyear}`,
    data.paid
  );
  _.set(
    state,
    `labs[${labIdx}].ATLAnualLicenseFeePaidAt${data.targetyear}`,
    data.paidat
  );

  const lab = state.labs[labIdx];

  // also need to increment/decrement counters in meta to avoid extra API call
  if (positive) {
    state.meta.TOTALINVOICESPAID++;

    if (lab.membershipPaid) {
      state.meta.TOTALMEMBERSHIPFEESPAID++;
    }

    if (lab.atlFeePaid) {
      state.meta.TOTALATLFEESPAID++;
    }
  } else {
    state.meta.TOTALINVOICESPAID--;

    if (lab.membershipPaid) {
      state.meta.TOTALMEMBERSHIPFEESPAID--;
    }

    if (lab.atlFeePaid) {
      state.meta.TOTALATLFEESPAID--;
    }
  }

  return { ...state };
};

const mapCosts = (action, state) => {
  const { data } = action.payload.data;

  data.forEach(ivc => {
    const lab = _.find(state.labs, ["id", ivc.companyid]);

    if (lab) {
      lab.invoiceCosts = [...ivc.atlinvoicecosts];
	  lab.invoiceAddress = ivc.atlinvoiceaddress;
    } else {
      console.error("Can't find lab with passed id: ", ivc.companyid);
    }
  });
  return { ...state };
};

const updateAtlInvoice = (action, state) => {
  const { id, costs } = action.payload;
  const ivc = _.find(state.labs, [
    `ATLAnualLicenseFeeKey${state.invoicingYear}`,
    id
  ]);

  ivc.invoiceCosts = ivc.invoiceCosts.map(ivcCost => {
    const costToCopy = _.find(costs, ["costid", ivcCost.costid]);
    return { ...ivcCost, ...costToCopy };
  });

  return { ...state };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.GET_LABS_TO_SWITCH:
      return { ...state, labsToSwitch: action.payload };
    case actions.SET_INVOICING_YEAR:
      return { ...state, invoicingYear: action.payload };
    case actions.FETCH_ATL_LIST:
      const { data, meta } = action.payload.data;
      const year =
        state && state.invoicingYear ? state.invoicingYear : getCurrATLYear();
      const readOnly = false;
      const bulkExtended = extendWithBulk(data, year);

      return { ...state, labs: bulkExtended, meta, readOnly };
    case actions.TOGGLE_ATL_MEMBERSHIP:
      const updatedFeeMember = toggleFee(action, state);
      return { ...state, labs: updatedFeeMember };
    case actions.TOGGLE_ATL_MEMBERSHIP_ALL:
      const memberFees = toggleAllFees(action, state);
      return { ...state, labs: memberFees };
    case actions.TOGGLE_ATL_FEE:
      const updatedFeeATL = toggleFee(action, state, false);
      return { ...state, labs: updatedFeeATL };
    case actions.TOGGLE_ATL_FEE_ALL:
      const atlFees = toggleAllFees(action, state, false);
      return { ...state, labs: atlFees };
    case actions.CONFIRM_ATL_PAYMENT:
      return updateATLPayment(action, state);
    case actions.REMOVE_ATL_PAYMENT:
      return updateATLPayment(action, state, false);
    case actions.FETCH_ATL_INVOICES_COSTS:
      return mapCosts(action, state);
    case actions.UPDATE_INVOICE_ATL:
      return updateAtlInvoice(action, state);
    case actions.TOGGLE_ATL_BACKGROUND_JOB_STATUS:
      if (_.has(state, "isBackgroundJobRunning")) {
        return {
          ...state,
          isBackgroundJobRunning: !state.isBackgroundJobRunning
        };
      }

      return { ...state, isBackgroundJobRunning: true };
    case actions.POST_ATL_COMMENT:
      // get CATL by ID
      const catl = _.find(state.labs, ["id", action.companyId]);

      if (catl) {
        // increment counter value
        catl[`ATLCommentsCount${action.year}`]++;
      }

      return state;
    default:
      return state;
  }
}
