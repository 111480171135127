import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";

const BatteryPartsComponents = ({
  request,
  handleInputChange,
  editable = true
}) => {
  const type = editable ? "textarea" : "";
  const hasValue = request.partsComponents && request.partsComponents !== "";

  if (!editable && !hasValue) return null;

  return (
    <FormElement
      id="partsComponents"
      label="Part #s/Components"
      value={request.partsComponents}
      type={type}
      onChange={handleInputChange}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, { handleInputChange })(
  BatteryPartsComponents
);
