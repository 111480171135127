import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Bubble } from "ctia-ui";
import Instructions from "components/request/TabInstructions";
import instructions from "dictionaries/TabInstructions";
import BatteryLifeInfoContent from "containers/request/BLC/BatteryLifeInfo";

const notAvailableMsg =
  "Battery Life Information is not available. Please submit the Battery Life Test report.";

const BatteryLifeInfo = ({ request }) => {
  const blresult =
    request && request.batteryLifeResult
      ? _.pickBy(request.batteryLifeResult, _.identity)
      : null;

  return (
    <div>
      {!blresult || _.isEmpty(blresult) ? (
        <Bubble icon="alert" type="warning">
          {notAvailableMsg}
        </Bubble>
      ) : (
        <>
          <Instructions messages={instructions.batteryLifeInfo} />
          <BatteryLifeInfoContent bldata={blresult} />
        </>
      )}
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(BatteryLifeInfo);
