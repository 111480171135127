import React from "react";
import VendorContentDHR from "containers/request/DHR/VendorRequestContentDHR";
import LabContentDHR from "containers/request/RequestContent/LabViewDHR";
import roles from "dictionaries/UserRoles";

const ContentDHR = ({ role, focusLab }) => {
  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.accountant:
      return <VendorContentDHR  focusLab={focusLab} />;
    case roles.operator:
      return <VendorContentDHR hideBillingPay={true}  focusLab={focusLab} />;
    case roles.lab:
      return <LabContentDHR  focusLab={focusLab} />;
    default:
      return null;
  }
};

export default ContentDHR;
