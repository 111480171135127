import React from "react";
import FormElementSelector from "components/FormElementSelector";
import {
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from "react-bootstrap";
import FormFieldLabel from "components/form/FormFieldLabel";
import PropTypes from "prop-types";

const FormElement = props => {
  if (!props.show) return null;

  return (
    <FormGroup controlId={props.id} validationState={props.validationState}>
      {props.label.length > 0 && (<Col
        componentClass={ControlLabel}
        sm={props.fullWidthColumns ? 0 : props.labelColSizeSm}
        md={props.fullWidthColumns ? 0 : props.labelColSizeMd}
      >
        <FormFieldLabel label={props.label} isRequired={props.isRequired} />
      </Col>)}
      <Col
        componentClass={props.inputComponentClass}
        sm={props.fullWidthColumns ? 0 : props.inputColSizeSm}
        md={props.fullWidthColumns ? 0 : props.inputColSizeMd}
      >
        <FormElementSelector {...props} />
        <FormControl.Feedback />
        {props.validationState && (
          <HelpBlock>{props.validationMessage}</HelpBlock>
        )}
      </Col>
    </FormGroup>
  );
};

FormElement.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool
  ]).isRequired,
  show: PropTypes.bool,
  editable: PropTypes.bool,
  isRequired: PropTypes.bool,
  fullWidthColumns: PropTypes.bool,
  labelColSizeSm: PropTypes.number,
  labelColSizeMd: PropTypes.number,
  inputColSizeSm: PropTypes.number,
  inputColSizeMd: PropTypes.number,
  inputComponentClass: PropTypes.func
};

FormElement.defaultProps = {
  validationState: null,
  validationMessage: "",
  id: "",
  label: "",
  value: "",
  show: true,
  editable: true,
  isRequired: false,
  fullWidthColumns: false,
  labelColSizeSm: 3,
  labelColSizeMd: 3,
  inputColSizeSm: 9,
  inputColSizeMd: 9
};

export default FormElement;
