import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import FormElement from "components/FormElement";
import BatteryPacksReadonly from "containers/request/battery/BatterySupplier/BatteryPackReadonlyBLC";
import RequestOperatingSystem from "components/request/RequestOperatingSystem";
import { testResultsExtended } from "dictionaries/TestResults";
import { getTestResultTitle } from "helpers/RequestTestsHelper";
import { toMomentDate } from "helpers/DateHelper";
import { changeTestField, changeTestDate } from "actions/tests";
import { handleInputChange } from "actions/request";
import SelectDeviceType from "containers/request/BLC/RequestDeviceTypeBLC";

class LabResultFormBLC extends Component {
  onResultChange = event => {
    this.props.changeTestField(this.props.testModule.id, event);
  };

  render() {
    const {
      show,
      request,
      testModule,
      changeTestDate,
      handleInputChange
    } = this.props;

    if (!show) return null;

    const { testStatus, hwVersion, swVersion } = request;

    const {
      lab,
      planversion,
      actualenddate,
      actualstartdate,
      requesttestpassedstatusid
    } = testModule;

    const startDate = toMomentDate(actualstartdate);
    const endDate = toMomentDate(actualenddate);

    return (
      <div>
        <Form horizontal>
          <FormElement
            id="testStatus"
            label="Test Status"
            value={getTestResultTitle(testStatus)}
          />
          <FormElement
            id="requesttestconductinglabs"
            label="Lab"
            value={lab.name}
          />
          <FormElement
            type="select"
            id="requesttestpassedstatusid"
            label="Test Result"
            value={requesttestpassedstatusid}
            onChange={this.onResultChange.bind(this)}
            options={testResultsExtended}
          />
          <FormElement
            type="date"
            id="actualstartdate"
            label="Test Start Date"
            value={startDate}
            onChange={changeTestDate.bind(
              this,
              testModule.id,
              "actualstartdate"
            )}
          />
          <FormElement
            type="date"
            id="actualenddate"
            label="Test End Date"
            value={endDate}
            onChange={changeTestDate.bind(this, testModule.id, "actualenddate")}
            minDate={startDate}
          />
          <hr />
          <FormElement
            type="text"
            id="planversion"
            label="Test Plan Version"
            value={planversion}
            onChange={this.onResultChange.bind(this)}
            placeholder="Enter version number"
          />
          <SelectDeviceType />
          <FormElement
            type="textarea"
            id="hwVersion"
            label="Hardware Version"
            value={hwVersion}
            onChange={handleInputChange}
            placeholder="Enter the final tested hardware version"
          />
          <FormElement
            type="textarea"
            id="swVersion"
            label="Software Version"
            value={swVersion}
            onChange={handleInputChange}
            placeholder="Enter the final tested software version"
          />
          <RequestOperatingSystem />
          <hr />
          <BatteryPacksReadonly />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ app, request, tests }) {
  return { app, request, tests };
}

export default connect(mapStateToProps, {
  changeTestDate,
  changeTestField,
  handleInputChange
})(LabResultFormBLC);
