import React from "react";
import { connect } from "react-redux";
import { IconLabel } from "ctia-ui";
import { showNotice } from "actions/notifier";
import { notifyMessages } from "helpers/NotifyHelper";
import { removePaymentATL } from "actions/catl";

const RemovePaymentATL = ({
  catl,
  showNotice,
  showLink,
  ivcId,
  removePaymentATL
}) => {
  const onRemove = () => {
    if (catl.isBackgroundJobRunning) {
      return showNotice(notifyMessages.backgroundJobInActionWarning);
    }

    removePaymentATL(ivcId);
  };

  return showLink ? (
    <div>
      <IconLabel awesomeIcon="trash-alt" />{" "}
      <span className="glow-link cursor-pointer" onClick={onRemove}>
        Remove Payment
      </span>
    </div>
  ) : null;
};

function mapStateToProps({ catl }) {
  return { catl };
}

export default connect(mapStateToProps, { showNotice, removePaymentATL })(
  RemovePaymentATL
);
