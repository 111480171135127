import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ErrorMsg from "components/ErrorMsg";
import { WithModal } from "ctia-ui";
import AgreementModal from "components/LicenseAgreementModal";
import AgreementCheckbox from "containers/request/LicenseAgreement/AgreementCheckbox";
import RequestControls from "containers/request/RequestControls";
import { isExisted } from "helpers/RequestHelper";
import { submitRequestDHR } from "actions/dhr/dhrRequest";
import {
  download,
  signAgreement,
  getAgreementInfo,
  cleanAgreementInfo
} from "actions/agreement";

import "containers/request/LicenseAgreement/style.css";

class LicenseAgreementDHR extends WithModal {
  componentDidMount() {
    this.props.getAgreementInfo(this.props.request);
  }

  componentWillUnmount() {
    this.props.cleanAgreementInfo();
  }

  onSubmit = () => {
    const { request, submitRequestDHR } = this.props;
    submitRequestDHR(request);
  };

  acceptAgreement = () => {
    this.props.signAgreement(this.props.request);
  };

  onPrintClick = () => {
    const { agreement, request, user, download } = this.props;
    return download(agreement, request, user, "dhr");
  };

  render() {
    const { show, request, history } = this.props;

    if (!show) return null;

    if (request && request.submitError) {
      return <ErrorMsg error={request.submitError} history={history} />;
    }

    const showControls = isExisted(request) ? request.isSaved : true;

    return (
      <div>
        <h3>
          <strong>License Agreement</strong>
        </h3>
        <p className="agr-description">
          Once the device is certified, the certification license agreement
          becomes effective. Please review the agreement{" "}
          <span
            onClick={this.handleModal}
            className="agr-link cursor-pointer glow-link"
          >
            here
          </span>{" "}
          and click the accept box below.
        </p>
        <p className="agr-description">
          I understand and acknowledge that my company agrees to the Terms and
          Conditions for the specified device set forth in the link above that
          will become effective immediately upon the date of device
          certification.
        </p>
        <div className="text-center agr-description">
          <AgreementCheckbox />
        </div>
        <RequestControls
          show={showControls}
          isValid={request.agreementAccepted}
          onSave={this.acceptAgreement}
          onSubmit={this.onSubmit}
          onWizardSave={this.onSubmit}
          isValidToSave={!request.agreementAccepted}
        />
        <AgreementModal
          show={this.state.showModal}
          modalHandler={this.handleModal}
          downloadHandler={this.onPrintClick}
          certProgram="Device Hardware Reliability Certification Program"
        />
      </div>
    );
  }
}

LicenseAgreementDHR.propTypes = {
  show: PropTypes.bool
};

LicenseAgreementDHR.defaultProps = {
  show: true
};

function mapStateToProps({ request, wizard, user, model, agreement }) {
  return { request, wizard, user, model, agreement };
}

export default connect(mapStateToProps, {
  download,
  signAgreement,
  getAgreementInfo,
  cleanAgreementInfo,
  submitRequestDHR
})(LicenseAgreementDHR);
