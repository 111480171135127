import React from "react";
import Main from "app/Main";
import withClearCache from "app/ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  return <Main />;
}

export default App;
