import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestModelName from "components/request/RequestModelnameSimple";
import BatteryVoltageCapacity from "components/request/BatteryVoltageCapacity";
import RequestRevision from "components/request/RequestRevision";
import PackCells from "containers/request/battery/PackCellsWithRecognition/PackCellsWithRecognition";
import PackCellsReadonly from "components/request/PackCellsReadonly";
import NonSarSites from "containers/request/battery/NonSarSites/NonSarSites";
import NonSarSitesReadonly from "containers/request/battery/NonSarSites/NonSarSitesReadonly";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import { setValue, setBatteryRequestPermissions } from "actions/request";
import { initEPR, updateDeviceInfoEPR } from "actions/battery/eprRequest";
import { mapNonSarManufSites } from "actions/battery/batteryRequests";
import { submitWizardStep } from "actions/wizard";
import { ifModelExists } from "actions/model";
import { hasStringValue } from "helpers/RequestHelper";
import { validateCapacity, validateVoltage } from "helpers/ValidatorHelper";

class RequestContainerEPR extends CyberComponent {
  componentDidMount() {
    const { request, user } = this.props;

    // fetch only for the first time on submissions
    if (!this.validate() && !request.id) {
      this.props.initEPR();
    }

    // fetch for existed requests
    if (request.id) {
      const companyid = user.isStaff ? request.submitter.companyid : null;

      this.props
        .initEPR(companyid)
        .then(() => this.props.mapNonSarManufSites(request.linkedManufsites));
    }

    // set permissions for existed request
    this.props.setBatteryRequestPermissions(request, user);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.togglerEnabled !== this.props.user.togglerEnabled) {
      return this.props.setBatteryRequestPermissions(
        this.props.request,
        this.props.user
      );
    }
  }

  onSaveClick = () => {
    const { request, sites, updateDeviceInfoEPR } = this.props;
    updateDeviceInfoEPR(request, sites.nonSarSites);
  };

  onContinueClick = () => {
    const { request, user, ifModelExists, submitWizardStep } = this.props;

    // check modelname uniqueness
    ifModelExists(request.modelName, user.companyid).then(isUnique => {
      if (isUnique) {
        return submitWizardStep();
      }
    });
  };

  validateModel() {
    return hasStringValue(this.props.request, "modelName");
  }

  validateRevision() {
    return hasStringValue(this.props.request, "revision");
  }

  validateManufSites() {
    const { sites } = this.props;

    return sites && sites.nonSarSites && _.some(sites.nonSarSites, "checked");
  }

  validatePackCells() {
    const { request } = this.props;

    return request.cellSuppliers && request.cellSuppliers.length > 0;
  }

  validate() {
    const { request, user } = this.props;
    const { canEditDeviceInfo } = user;

    // don't need to validate if form is non-editable
    if (canEditDeviceInfo === false) return true;

    // check Voltage/Capacity values
    if (!validateCapacity(request) || !validateVoltage(request)) return false;

    // check if all required fields are valid
    return (
      this.validateModel() &&
      this.validateRevision() &&
      this.validateManufSites() &&
      this.validatePackCells()
    );
  }

  render() {
    const { canEditDeviceInfo } = this.props.user;
    const saveHandler = canEditDeviceInfo ? this.onSaveClick : null;
    const isEditable = { editable: canEditDeviceInfo };

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestManufacturer />
          <RequestModelName />
          <RequestRevision fieldId="revision" {...isEditable} />
          <BatteryVoltageCapacity />
          <SubmissionDetails />
          <hr />
          {canEditDeviceInfo ? <NonSarSites /> : <NonSarSitesReadonly />}
          <hr />
          {canEditDeviceInfo ? <PackCells /> : <PackCellsReadonly />}
          <RequestControls
            isValid={this.validate()}
            onSave={saveHandler}
            onContinue={this.onContinueClick}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ app, request, user, sites }) {
  return { app, request, user, sites };
}

export default connect(mapStateToProps, {
  initEPR,
  submitWizardStep,
  updateDeviceInfoEPR,
  setValue,
  setBatteryRequestPermissions,
  mapNonSarManufSites,
  ifModelExists
})(RequestContainerEPR);
