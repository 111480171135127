import * as actions from "actions/types";
import * as call from "helpers/ActionHelper";
import { prepareLabOrgArgs } from "helpers/ArgsOrgAdminHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { restClientAdmin } from "libs/restClient";

export const updateCompanyLab = (formId, id) => async dispatch => {
  try {
    const form = document.getElementById(formId);
    const reqBody = prepareLabOrgArgs(form);
    const response = await restClientAdmin.put(
      `companies/${id}`,
      JSON.stringify(reqBody)
    );

    dispatch({
      type: actions.UPDATE_LAB_COMPANY,
      payload: response.data.data
    });

    dispatch(call.showNotice(notifyMessages.companyUpdatedSuccessfully));

    return response;
  } catch (error) {
    const { data } = error.response;
    dispatch(call.showNotice(notifyMessages.failedToUpdateCompany));

    if (data && data.errors) {
      throw data.errors[0];
    } else {
      throw data.exception;
    }
  }
};