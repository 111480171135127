import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import InfoBar from "containers/request/OTA/TestResults/OTATestResultsInfoBar";
import SubmitButton from "containers/request/OTA/TestResults/SubmitTestResults";
import OtaResultsWizard from "containers/request/OTA/TestResults/OtaResultsWizard";
import { LoaderButton } from "ctia-ui";
import OtaUploadPanel from "containers/request/OTA/TestResults/OtaUploadPanel";
import PageTitle from "components/RequestPageTitle";
import GoBackButton from "components/form/buttons/GoBackButton";
import { clearRequest } from "actions/request";
import { fetchOtaTestResults } from "actions/tests";
import { filterOtaTestsByLab } from "helpers/RequestTestsHelper";

class OTATestResults extends Component {
  state = { showContent: false, noTesting: false };

  componentDidMount() {
    const { match, fetchOtaTestResults, user } = this.props;

    this.resetRender();

    fetchOtaTestResults(match.params.id, user.companyid).then(
      this.activateRender
    );
  }

  componentWillReceiveProps(nextProps) {
    const { match, fetchOtaTestResults, user } = this.props;
    const newId = nextProps.match.params.id;

    if (newId !== match.params.id) {
      fetchOtaTestResults(newId, user.companyid).then(this.activateRender);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
  }

  resetRender = () => {
    this.setState({ showContent: false });
  };

  activateRender = () => {
    this.setState({ showContent: true });
  };

  onDone = () => {
    this.props.history.push("/ota/lab/results");
  };

  render() {
    const { request, tests, user } = this.props;

    if (_.isNull(request) || _.isNull(tests) || !request.id) {
      return null;
    }

    if (!this.state.showContent) return null;

    if (request.formSaved) {
      return (
        <div>
          <h1 className="block-bottom-offset">Uploading Test Results</h1>
          <OtaUploadPanel />
          <LoaderButton text="Done" onClick={this.onDone} disabled={false} />
        </div>
      );
    }

    const list = filterOtaTestsByLab(tests, user);

    return (
      <div>
        <PageTitle />
        <GoBackButton dest="/ota/lab/results" label="Return to list" />
        <InfoBar />
        <OtaResultsWizard list={list} />
        <SubmitButton show={!_.isEmpty(list)} />
      </div>
    );
  }
}

function mapStateToProps({ request, tests, user }) {
  return { request, tests, user };
}

export default connect(mapStateToProps, {
  clearRequest,
  fetchOtaTestResults
})(OTATestResults);
