import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import FormElement from "components/FormElement";

const PointOfContactLab = ({ show, request }) => {
  if (!show) return null;

  const { pocFullName, pocEmail, pocPhone, tn, modelName, vendor } = request;
  const emailData = { tn, email: pocEmail, modelName, vendor };

  return (
    <div>
      <Form horizontal>
        <FormElement
          id="pocName"
          label="Point of Contact (PoC)"
          value={pocFullName}
        />
        <FormElement
          id="pocEmail"
          label="PoC Email"
          value={emailData}
          type="emailLink"
          useSubmitterNameAsTitle={false}
        />
        <FormElement id="pocPhone" label="PoC Phone" value={pocPhone} />
      </Form>
    </div>
  );
};

PointOfContactLab.propTypes = { show: PropTypes.bool };
PointOfContactLab.defaultProps = { show: true };

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(PointOfContactLab);
