import React from "react";
import { connect } from "react-redux";
import {
  formatAdapterSuppliersList,
  supplierTypes
} from "helpers/SupplierHelper";
import { adapterTypes } from "helpers/AdapterSupplierHelper";
import SelectSupplier from "containers/request/battery/AdapterSupplier/SelectAdapterSupplier";
import NoDataMessage from "containers/request/battery/BatterySupplier/NoDataMessage";

const SelectRecognizedAdapter = ({ suppliers }) => {
  if (!suppliers) return null;
  if (!suppliers.approvededRecognitionSuppliers) {
    return <NoDataMessage message="No adapter suppliers available" />;
  }

  const list = formatAdapterSuppliersList(
    suppliers.approvededRecognitionSuppliers,
    supplierTypes.adapter
  );

  return (
    <SelectSupplier
      list={list}
      title={adapterTypes.recognized}
      isRecognized={true}
    />
  );
};

function mapStateToProps({ suppliers }) {
  return { suppliers };
}

export default connect(mapStateToProps)(SelectRecognizedAdapter);
