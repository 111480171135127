import React from "react";
import { connect } from "react-redux";
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import PropTypes from "prop-types";
import { setValue } from "actions/request";

const ToggleRadioGroup = ({ id, list, value, setValue, onChange }) => {
  if (!list) return null;

  const buttonList = list.map((option, i) => {
    return (
      <ToggleButton key={i} value={option.id} disabled={option.disabled}>
        {option.label}
      </ToggleButton>
    );
  });

  const onRadioClick = value => {
    setValue(id, value);
  };

  return (
    <ButtonToolbar>
      <ToggleButtonGroup
        name={id}
        type="radio"
        value={value}
        onChange={onChange || onRadioClick}
      >
        {buttonList}
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
};

ToggleRadioGroup.propTypes = {
  id: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  value: PropTypes.any,
  setValue: PropTypes.func.isRequired
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, { setValue })(ToggleRadioGroup);
