import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestModelName from "components/request/RequestModelnameSimple";
import BatteryPartsComponents from "components/request/BatteryPartsComponents";
import RequestRevision from "components/request/RequestRevision";
import PackDetailsHRR from "containers/request/HRR/PackDetailsHRR";
import NonSarSites from "containers/request/battery/NonSarSites/NonSarSites";
import NonSarSitesReadonly from "containers/request/battery/NonSarSites/NonSarSitesReadonly";
import BatteryDeviceType from "components/request/BatteryDeviceType";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import { setBatteryRequestPermissions } from "actions/request";
import { initHRR, updateDeviceInfoHRR } from "actions/battery/hrrRequest";
import {
  mapNonSarManufSites,
  mapBatteryDeviceType
} from "actions/battery/batteryRequests";
import { submitWizardStep } from "actions/wizard";
import { ifModelExists } from "actions/model";
import { hasStringValue } from "helpers/RequestHelper";
import { validateCapacity } from "helpers/ValidatorHelper";
import { getRequestOwner } from "helpers/UsersHelper";

class RequestContainerHRR extends CyberComponent {
  componentDidMount() {
    const { app, request, user } = this.props;

    // fetch only for the first time on submissions
    if (!this.isValidToSubmit() && !request.id) {
      this.props.initHRR();
    }

    // fetch for existed requests
    if (request.id) {
      const companyid = user.isStaff ? request.submitter.companyid : null;

      this.props.initHRR(companyid).then(() => {
        this.props.mapNonSarManufSites(request.linkedManufsites);
        this.props.mapBatteryDeviceType(app.certTypes);
      });
    }

    // set permissions for existed request
    this.props.setBatteryRequestPermissions(request, user);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.togglerEnabled !== this.props.user.togglerEnabled) {
      return this.props.setBatteryRequestPermissions(
        this.props.request,
        this.props.user
      );
    }
  }

  onSaveClick = () => {
    const {
      request,
      sites,
      user,
      ifModelExists,
      updateDeviceInfoHRR
    } = this.props;

    const modelNameUntouched =
      request.modelName === request.__orig.vendorproductname;

    if (!modelNameUntouched) {
      // admins also can edit request
      const ownerId = getRequestOwner(request, user);

      ifModelExists(request.modelName, ownerId).then(isUnique => {
        // allow to update in case only modelname is unique
        if (isUnique) {
          updateDeviceInfoHRR(request, sites.nonSarSites);
        }
      });
    } else {
      updateDeviceInfoHRR(request, sites.nonSarSites);
    }
  };

  onContinueClick = () => {
    const { request, user, ifModelExists } = this.props;

    ifModelExists(request.modelName, user.companyid).then(isUnique => {
      if (isUnique) {
        return this.props.submitWizardStep();
      }
    });
  };

  validateModel() {
    return hasStringValue(this.props.request, "modelName");
  }

  validateRevision() {
    return hasStringValue(this.props.request, "revision");
  }

  validateManufSites() {
    const { sites } = this.props;

    return sites && sites.nonSarSites && _.some(sites.nonSarSites, "checked");
  }

  validateDeviceTypes() {
    const [pack, adapter] = this.props.request.deviceTypes;

    return pack.checked || adapter.checked;
  }

  isValidToUpdate() {
    const { request, user } = this.props;

    if (user.isSupplier) return this.isValidToSubmit();

    // until lab accepts and provided results:
    if (user.isStaff && !user.canEditLabFields) return this.isValidToSubmit();

    const [pack] = request.deviceTypes;

    if (pack.checked && user.canEditLabFields) {
      if (!request.cellSuppliers.length) return false;
      if (!validateCapacity(request)) return false;
    }

    return this.isValidToSubmit() && this.validateDeviceTypes();
  }

  isValidToSubmit() {
    const { canEditDeviceInfo } = this.props.user;

    // don't need to validate if form is non-editable
    if (canEditDeviceInfo === false) return true;

    return (
      this.validateModel() &&
      this.validateRevision() &&
      this.validateManufSites()
    );
  }

  render() {
    const { request, user } = this.props;
    const { canEditDeviceInfo, canEditLabFields } = user;
    const saveHandler = canEditDeviceInfo ? this.onSaveClick : null;
    const isEditable = { editable: canEditDeviceInfo };
    const isValid =
      request.id > 0 ? this.isValidToUpdate() : this.isValidToSubmit();

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestManufacturer />
          <RequestModelName />
          <BatteryDeviceType editable={canEditLabFields} />
          <PackDetailsHRR />
          <RequestRevision fieldId="revision" {...isEditable} />
          <BatteryPartsComponents editable={canEditLabFields} />
          <SubmissionDetails />
          <hr />
          {canEditDeviceInfo ? <NonSarSites /> : <NonSarSitesReadonly />}
          <RequestControls
            isValidToSubmit={this.isValidToSubmit()}
            isValid={isValid}
            onSave={saveHandler}
            onContinue={this.onContinueClick}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ app, request, requests, user, sites, wizard }) {
  return { app, request, requests, user, sites, wizard };
}

export default connect(mapStateToProps, {
  initHRR,
  submitWizardStep,
  updateDeviceInfoHRR,
  setBatteryRequestPermissions,
  mapNonSarManufSites,
  mapBatteryDeviceType,
  ifModelExists
})(RequestContainerHRR);
