import * as actions from "actions/types";
import { restClientGeneral, restClientAdmin } from "libs/restClient";
import * as call from "helpers/ActionHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { makeSimpleArgs } from "helpers/ArgsHelper";

export const getActiveLabUsers = () => async dispatch => {
  try {
    const response = await restClientAdmin.get(
      `users?where=role='lab' AND active=1&orderBy=lastname ASC, firstname ASC&page=0`
    );
    dispatch({
      type: actions.GET_ACTIVE_LAB_USERS,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchActiveLabs = () => async dispatch => {
  try {
    const url =
      "/companies?page=0&orderBy=name ASC&where=isActiveLab=1&returnAs=query";
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_ACTIVE_LABS,
      payload: payload.data.data
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function selectLabUser(ctiaid) {
  return {
    type: actions.SELECT_LAB_USER,
    ctiaid
  };
}

export function selectLabOfUsers(ctiaid) {
  return {
    type: actions.SELECT_LAB_OF_USERS,
    ctiaid
  };
}

export const fetchUserLabs = ctiaId => async dispatch => {
  try {
    const payload = await restClientGeneral.get(
      `/legacy/users/${ctiaId}?include=legacyuserlabs`
    );

    dispatch({
      type: actions.FETCH_USER_LABS,
      payload: payload.data.data.legacyuserlabs || []
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function setTransferFormVisability(visible) {
  return {
    type: actions.SET_TRANSFER_FORM_VISIBILITY,
    visible
  };
}

export const updateAssignedUserLabs = (ctiaId, labs) => async dispatch => {
  try {
    const url = `/legacy/users/${ctiaId}`;
    const body = makeSimpleArgs({
      legacyuserlabs: labs
    });
    const response = await restClientGeneral.put(url, body);

    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.assignedLabsUpdatedSuccessfully
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantUpdateRequest
    });
  }
};

export const fetchUsersOfLabs = legacyId => async dispatch => {
  // start preloader
  dispatch(call.startPreloader());

  try {
    // const url = `/users?where=companyid=${companyId}&page=0&orderby=lastname ASC, firstname ASC`;
    const url = `/legacy/userstolabs?returnAs=query&include=legacyuser&where=labid=${legacyId} AND active=1&select=id,labid,userid,firstname,lastname&page=0&orderby=lastname ASC, firstname ASC`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_COMPANY_USERS,
      payload: response.data
    });
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));
  }

  // stop preloader
  dispatch(call.stopPreloader());
};

export function unmountView() {
  return {
    type: actions.UNMOUNT_USERS_TO_LABS
  };
}
