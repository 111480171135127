import _ from 'lodash';
import categories from 'dictionaries/CertCategories';

/**
 * Format list of cert categories.
 *
 * The list will come from backend.
 *
 * @param {array} categories Categories list
 * @returns array Formatted list ready to be rendered
 */
export const getCategories = categories => {
  if (!categories || !categories.length) return [];

  return _.map(categories, category => {
    return {
      title: category.name,
      value: category.id
    };
  });
};

/**
 * Get category name by its ID.
 *
 * @param {number} type Category ID
 * @returns string Category name
 */
export const getCategoryTitle = type => {
  if (_.has(categories, type)) {
    return categories[type].title;
  }

  return 'Wrong category level';
};

export const getCategoryByAbbreviation = (selectedCategoryAbbreviation, categories) => {
  if (!selectedCategoryAbbreviation || !categories || !categories.length) return null;

  return  _.find(categories, category =>
    category.name.toLowerCase() === selectedCategoryAbbreviation.toLowerCase());
}
