import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getModelName } from "helpers/RequestHelper";

// Declaration of Compliance -- modal content
const ComplianceContent = ({ request, user }) => {
  const modelName = request ? getModelName(request) : "<Model Name/Number>";
  const manufacturer = request.vendor || user.company;
  const signedDate =
    request && request.isApproved
      ? moment(request.completeDate).format("MM/DD/YYYY")
      : "<not signed yet>";
  const ieee = request.ieee;

  return (
    <div>
      <div className="text-center">
        <p>Date: {signedDate}</p>
        <br />
        <p>
          <b>{manufacturer}</b>
        </p>
        <p>
          <b>(“Declarer”)</b>
        </p>
        <p>&nbsp;</p>
        <p>
          <b>{modelName}</b>
        </p>
        <p>
          <b>(“Subsystem”)</b>
        </p>
        <br />
      </div>
      <p>
        <b>WHEREAS</b>, Declarer has developed the Subsystem that meets the
        terms and conditions of IEEE {ieee}™;
      </p>
      <p>
        <b>WHEREAS</b>, the Declarer issues this Declaration of Compliance in
        order to certify to CTIA that the Subsystem complies with all applicable
        provisions of IEEE {ieee};
      </p>
      <p>
        <b>WHEREAS</b>, for purposes of the CTIA Battery Certification Program,
        the Compliance Folder shall include this declaration, the CTIA
        Authorized Test Lab evaluation report and the CTIA Authorized Test Lab
        worksheets; and
      </p>
      <p>
        <b>WHEREAS</b>, the Compliance Folder constitutes evidence demonstrating
        compliance of the Subsystem to IEEE {ieee} and is maintained by CTIA.
      </p>
      <p>
        <b>WHEREAS</b>, the Declarer undertakes and certifies that the
        Subsystem, as manufactured and marketed, complies with all applicable
        provisions of IEEE {ieee} and the current version of the CTIA Battery
        Program Management Document (BPMD);
      </p>
      <p>
        <b>FURTHERMORE</b>, Declarer further undertakes and certifies that this
        Declaration of Compliance constitutes an essential element of CTIA’s
        Battery Certification Program, and acknowledges responsibility for
        compliance with all applicable provisions; and
      </p>
      <p>
        <b>FURTHERMORE</b>, Declarer authorizes CTIA to audit the Compliance
        Folder.
      </p>
    </div>
  );
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(ComplianceContent);
