import React from "react";
import { connect } from "react-redux";
import { WithModal } from "ctia-ui";
import AgreementModal from "containers/request/LicenseAgreement/BatteryAgreement/BatteryAgreementModal";
import AgreementCheckbox from "containers/request/LicenseAgreement/BatteryAgreement/BatteryAgreementCheckbox";
import ModalContent from "containers/request/LicenseAgreement/BatteryAgreement/Modals/ComplianceContent";
import { DECLARATION_OF_COMPLIANCE } from "containers/request/LicenseAgreement/BatteryAgreement/agreementHelper";
import { downloadDeclaration } from "actions/agreementsBattery";

import "containers/request/LicenseAgreement/style.css";

class DeclarationOfCompliance extends WithModal {
  onPrintClick = () => {
    const { request, certType, downloadDeclaration } = this.props;
    return downloadDeclaration(request, certType);
  };

  render() {
    const title = "Declaration of Compliance";
    const { ieee } = this.props.request;

    return (
      <div>
        <h3>
          <strong>{title}</strong>
        </h3>
        <p className="agr-description">
          A {title} to IEEE {ieee} is required. Please review the declaration{" "}
          <span
            onClick={this.handleModal}
            className="agr-link cursor-pointer glow-link"
          >
            here
          </span>{" "}
          and click the accept box below.
        </p>
        <div className="text-center agr-description">
          <AgreementCheckbox
            togglerId={DECLARATION_OF_COMPLIANCE}
            title={title}
          />
        </div>
        <AgreementModal
          show={this.state.showModal}
          modalTitle={title}
          modalContent={ModalContent}
          modalHandler={this.handleModal}
          downloadHandler={this.onPrintClick}
        />
      </div>
    );
  }
}

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  downloadDeclaration
})(DeclarationOfCompliance);
