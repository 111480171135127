import _ from "lodash";
import { restClientGeneral } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import * as call from "helpers/ActionHelper";
import * as args from "helpers/ArgsHelperCRR";
import * as th from "helpers/RequestTestsHelper";
import * as actions from "actions/types";
import * as sites from "actions/sitesSar";
import * as sign from "actions/agreementsBattery";
import * as req from "actions/request";
import * as tests from "actions/tests";
import * as breq from "actions/battery/batteryRequests";
import { fileDownload } from "actions/uploads";

export const initCRR = (ieee, companyid = null) => async dispatch => {
  dispatch(call.startPreloader());

  return dispatch(sites.simpleFetchManufSites(ieee, companyid, false))
    .then(() => dispatch(breq.fetchBatteryModelsCRR(ieee, companyid)))
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const fetchEcoModelsCRR = (ieee, vendorId) => async dispatch => {
  try {
    const url = `/crr/devicemodels?where=ieee${ieee}crr=1 AND vendorid=${vendorId}&include=devicemodelbatterycomponent&select=id,modelnumber,capacity,voltage,capacityrateid,voltagerateid&orderBY=modelnumber ASC&page=0`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CRR_ECO_MODELS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const initEcoCRR = (ieee, companyid = null) => async dispatch => {
  dispatch(call.startPreloader());

  return dispatch(fetchEcoModelsCRR(ieee, companyid))
    .then(() => dispatch(sites.simpleFetchManufSites(ieee, companyid, false)))
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const createRequestCRR = request => async dispatch => {
  try {
    const crrArgs = args.defineInitialArgsCRR(request);
    const response = await restClientGeneral.post(
      crrArgs.url,
      JSON.stringify(crrArgs.body)
    );
    const { id, devicemodelid, requests } = response.data.data;
    const newId = _.isArray(requests) ? requests[0].id : id;
    const deviceModelId = _.isArray(requests) ? id : devicemodelid;

    dispatch({
      type: actions.SUBMIT_INITIAL_REQUEST,
      payload: {
        newId,
        deviceModelId,
        isCRR: true
      }
    });

    const agreementSigned =
      request.complianceDeclarationToggler &&
      request.recognitionAgreementToggler;

    if (agreementSigned) {
      dispatch({
        type: actions.SUBMIT_WIZARD,
        payload: { isSubmitted: true }
      });
    } else {
      dispatch({
        type: actions.SAVE_WIZARD,
        payload: { isSaved: true }
      });
    }

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitInitialCRR(request) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(createRequestCRR(request))
      .then(res => {
        const { id, devicemodelid } = res.data.data;
        const modelId = request.existedModelId ? devicemodelid : id;
        const needAgreementSign =
          request.complianceDeclarationToggler &&
          request.recognitionAgreementToggler;

        if (needAgreementSign) {
          return dispatch(
            sign.signDeclarationAgreement(modelId, request.ieee, "crr")
          ).then(() =>
            dispatch(
              sign.signRecognitionAgreement(modelId, request.ieee, "crr")
            )
          );
        } else return res;
      })
      .then(quit)
      .catch(quit);
  };
}

export const createEcoRequestCRR = request => async dispatch => {
  try {
    const reqBody = args.prepareEcoArgsCRR(request);
    const response = await restClientGeneral.post(
      "crr/requests",
      JSON.stringify(reqBody)
    );
    const { id, devicemodelid } = response.data.data;

    dispatch({
      type: actions.SUBMIT_INITIAL_REQUEST,
      payload: {
        newId: id,
        deviceModelId: devicemodelid,
        isCRR: true
      }
    });

    dispatch({
      type: actions.SUBMIT_WIZARD,
      payload: { isSubmitted: true }
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitEcoCRR(request) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(createEcoRequestCRR(request)).then(quit).catch(quit);
  };
}

export const fetchRequestCRR = id => async dispatch => {
  try {
    const url = `/crr/requests/${id}/details`;
    const res = await restClientGeneral.get(url);
    const payload = res.data.data;

    dispatch({ type: actions.FETCH_REQUEST_CRR, payload });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export const getParentRequestDetailsCRR = id => async dispatch => {
  try {
    const url = `/crr/requests/${id}/details`;
    const response = await restClientGeneral.get(url);
    const payload = response.data.data;

    dispatch({ type: actions.FETCH_PARENT_REQUEST_CRR, payload });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export const fetchRequestTestsCRR = requestId => async dispatch => {
  try {
    const url = `/crr/requesttests?include=reports,requesttestconductinglabs,lab,interface&where=requestid=${requestId}`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_REQUEST_TESTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchTestReportsCRR = testModule => async dispatch => {
  try {
    const url = `/crr/requesttests/${testModule.id}/reports`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_TEST_REPORTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getDetailsCRR(requestId) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(fetchRequestCRR(requestId))
      .then(res => {
        const {
          ieeetypeid,
          submitter,
          parent,
          requestmanufsites
        } = res.data.data;

        const linkedManufsites =
          _.has(parent, "id") && requestmanufsites.length === 0
            ? parent.requestmanufsites
            : requestmanufsites;

        return dispatch(
          sites.simpleFetchManufSites(ieeetypeid, submitter.companyid, false)
        ).then(() => dispatch(mapManufSitesCRR(linkedManufsites)));
      })
      .then(() => dispatch(fetchRequestTestsCRR(requestId)))
      .then(res => {
        const resData = res.data;
        if (!resData) return Promise.resolve(null);

        const testId = resData.data[0].id;
        return dispatch(fetchTestReportsCRR({ id: testId }));
      })
      .then(quit)
      .catch(quit);
  };
}

export function getRequestDetailsCRR(requestId) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(fetchRequestCRR(requestId)).then(quit).catch(quit);
  };
}

export function makeLabDecisionCRR(decision, request) {
  const statusId = th.getLabDecisionStatus(decision);
  const url = `crr/requests/${request.id}/requesttests?statusid=${statusId}`;

  return {
    type: actions.MAKE_LAB_DECISION,
    payload: restClientGeneral.put(url)
  };
}

export function submitTestResultsCRR(request, testModule) {
  const reqArgs = args.getRequestFieldsForTestingCRR(request);
  const modelArgs = args.getDeviceFieldsForTestingCRR(request);
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(sendTestResultsCRR(testModule))
      .then(() => dispatch(updateRequestByArgs(reqArgs)))
      .then(() => dispatch(updateModelCRR(modelArgs)))
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch({
          type: actions.SAVE_REQUEST,
          payload: { formSaved: true, isLoading: true }
        });
        dispatch({
          type: actions.CLEAR_CURR_SITE,
          payload: null
        });
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });
  };
}

export const sendTestResultsCRR = testModule => async dispatch => {
  try {
    const body = args.getTestFieldsCRR(testModule);
    const payload = await restClientGeneral.put(
      `/crr/requesttests/${testModule.id}`,
      JSON.stringify(body)
    );

    dispatch({
      type: actions.SEND_TEST_RESULT,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSendTestResult));

    return Promise.reject(e);
  }
};

export const updateRequestByArgs = reqArgs => async dispatch => {
  try {
    const url = `/crr/requests/${reqArgs.args.requestId}`;
    const response = await restClientGeneral.put(url, JSON.stringify(reqArgs));
    const payload = response.data.data;

    dispatch({
      type: actions.UPDATE_REQUEST_CRR,
      payload
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export const updateModelCRR = modelArgs => async dispatch => {
  try {
    const url = `/crr/devicemodels/${modelArgs.args.modelId}`;
    return await restClientGeneral.put(url, JSON.stringify(modelArgs));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function signSavedInitialCRR(modelId, requestId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(sign.signDeclarationAgreement(modelId, ieee, "crr"))
      .then(() => dispatch(sign.signRecognitionAgreement(modelId, ieee, "crr")))
      .then(() => dispatch(fetchRequestCRR(requestId)))
      .then(quit)
      .catch(quit);
  };
}

export function mapManufSitesCRR(linkedSites) {
  return {
    type: actions.MAP_CRR_MANUF_SITES,
    payload: linkedSites
  };
}

export function mapSelectedModelFields(modelFields) {
  return {
    type: actions.MAP_CRR_MODEL_FIELDS,
    payload: modelFields
  };
}

export const getModelDetailsCRR = (modelId, ieee) => async dispatch => {
  try {
    const url = `/crr/requests?include=requestmanufsites&where=devicemodelid=${modelId} AND requeststatusid <> 8 AND ieeetypeid = ${ieee}&orderBY=requestid DESC&perPage=1`;
    return await restClientGeneral.get(url);
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export function fetchModelCRR(modelId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getModelDetailsCRR(modelId, ieee))
      .then(res => {
        const payload = res.data.data[0];

        dispatch({
          type: actions.FETCH_CRR_MODEL,
          payload
        });

        return res;
      })
      .then(res =>
        dispatch(mapManufSitesCRR(res.data.data[0].requestmanufsites))
      )
      .then(quit)
      .catch(quit);
  };
}

export const updateDeviceModel = deviceModel => async dispatch => {
  try {
    return await restClientGeneral.put(
      `/crr/devicemodels/${deviceModel.args.modelId}`,
      JSON.stringify(deviceModel)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function updateDeviceInfoCRR(request, sites) {
  const deviceModel = args.prepareModelArgsCRR(request);
  const reqArgs = args.prepareRequestArgs(request, sites);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(updateDeviceModel(deviceModel))
      .then(() => dispatch(req.updateRequest(reqArgs)))
      .then(() => {
        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

export const updateTestReportStatusCRR = ({
  requestId,
  testId,
  reportSlot,
  reportStatus
}) => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(tests.changeReportStatus(testId, reportSlot, reportStatus, "crr"))
    .then(res => {
      const { documentstatusid, report } = res.data.data;

      dispatch({
        type: actions.UPDATE_REPORT_STATUS,
        payload: {
          slot: report.slot,
          status: documentstatusid,
          testId
        }
      });

      return res;
    })
    .then(() => dispatch(getDetailsCRR(requestId)))
    .then(() => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
    })
    .catch(err => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

      return Promise.reject(err);
    });
};

export function downloadReportCRR(document, request) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this

  // file name pattern: CRR<000000>_REPORT_<Slot>.<extension>
  // e.g. CRR000345_REPORT_3.pdf
  const fileName = `${request.tn}_REPORT_${slot}.${document.document.extension}`;
  const url = `/crr/requesttests/${test}/reports/${slot}?download=1`;

  return fileDownload(fileName, url, "general");
}

export function downloadAllReportsCRR(request) {
  // file name: CRR<000000>_REPORTS.zip
  // e.g. CRR000345_REPORTS.zip
  const fileName = `${request.tn}_REPORTS.zip`;
  const url = `/crr/requests/${request.id}/reports?download=true`;

  return fileDownload(fileName, url, "general");
}

export function setRequestVoltagePermissions(canEditVoltageCapacity) {
  const payload = { canEditVoltageCapacity };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}
