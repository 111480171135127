import { Col, ControlLabel, FormGroup } from "react-bootstrap";
import React from "react";
import DateRange from "components/form/DateRange";

const DateRangeSelector = ({
  dateFrom,
  dateTo,
  onDateFromChange,
  onDateToChange
}) => {
  return (
    <FormGroup>
      <Col componentClass={ControlLabel} xs={12} sm={3} md={3}>
        Date:
      </Col>
      <DateRange
        dateFrom={dateFrom}
        dateTo={dateTo}
        onDateFromChange={onDateFromChange}
        onDateToChange={onDateToChange}
      />
    </FormGroup>
  );
};

export default DateRangeSelector;
