// React / Redux
import React from "react";
import { connect } from "react-redux";

// Dictionaries and Helpers
import { types } from "helpers/BatteryCertHelper";

// Components and Containers
import BatteryModelDetailsBSC
  from "containers/Devices/Battery/BatteryDeviceDetails/BatteryModelDetailsTables/BatteryModelDetailsBSC/BatteryModelDetailsBSC";
import BatteryModelDetailsSAR
  from "containers/Devices/Battery/BatteryDeviceDetails/BatteryModelDetailsTables/BatteryModelDetailsSAR/BatteryModelDetailsSAR";
import BatteryModelDetailsOtherCerts
  from "containers/Devices/Battery/BatteryDeviceDetails/BatteryModelDetailsTables/BatteryModelDetailsOtherCerts/BatteryModelDetailsOtherCerts";
import BatteryInfoBar
  from "containers/Devices/Battery/BatteryDeviceDetails/BatteryInfoBar";

const BatteryModelDetails = ({
  certType,
  deviceInfo = {},
  modelInfo = {},
  reloadTable = () => {}
}) => {
  const pageTitle = () => {
    switch (certType) {
      case types.BSC:
        return "Certified Battery Systems";
      case types.SAR:
        return "Site History";
      default:
        return "Device History";
    }
  };

  const subTitle = () => {
    switch (certType) {
      case types.BSC:
        return "Below are all certified system components.";
      case types.SAR:
        return "Below are all of the audits conducted at this site.";
      default:
        return "Below are all the recognized versions of this subsystem.";
    }
  };

  const getTableComponent = () => {
    switch (certType) {
      case types.BSC:
        return <BatteryModelDetailsBSC reloadRows={reloadTable} />;
      case types.SAR:
        return (
          <BatteryModelDetailsSAR
            certType={certType}
            reloadRows={reloadTable}
          />
        );
      default:
        return (
          <BatteryModelDetailsOtherCerts
            certType={certType}
            reloadRows={reloadTable}
          />
        );
    }
  };

  return (
    <div>
      <BatteryInfoBar
        modelInfo={modelInfo}
        deviceInfo={deviceInfo}
        certType={certType}
      />
      <h3 className="no-margin">{pageTitle()}</h3>
      <h4>{subTitle()}</h4>
      {getTableComponent()}
    </div>
  );
};

function mapStateToProps({ app }) {
  return { app };
}

export default connect(mapStateToProps)(BatteryModelDetails);
