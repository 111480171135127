import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Bubble } from 'ctia-ui';
import OperatorsList from 'containers/OperatorsList';

const OperatorsECO = ({ show }) => {
  if (!show) return null;

  return (
    <div>
      <Bubble show={true} type="warning">
        Operators allowed to view the certification record once the device is
        certified (read-only, you are not able to edit this list).
      </Bubble>
      <Form horizontal>
        <OperatorsList readonly={true} />
      </Form>
    </div>
  );
};

OperatorsECO.propTypes = {
  show: PropTypes.bool
};

OperatorsECO.defaultProps = {
  show: true
};

export default OperatorsECO;
