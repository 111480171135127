import React, { useState } from "react";
import { connect } from "react-redux";
import { DownloadButton, DocumentStatus, Drawer } from "ctia-ui";
import { Col, Row, Table, FormGroup, FormControl } from "react-bootstrap";
import EditButton from "components/form/buttons/EditButton";
import DocTitleReporter from "components/DocTitleReporter";
import DownloadAllButton from "components/form/buttons/DownloadAllButton";
import EditTestResultForm from "containers/request/battery/BatteryLabInfo/EditTestResultFormBattery";
import { getCertTestHandler } from "actions/battery/multitool";
import { hasReportDocuments } from "helpers/RequestTestsHelper";
import {
  updateTestReportStatusCRR,
  downloadReportCRR,
  downloadAllReportsCRR
} from "actions/battery/crrRequest";
import {
  updateTestReportStatusHRR,
  downloadReportHRR,
  downloadAllReportsHRR
} from "actions/battery/hrrRequest";
import {
  updateTestReportStatusPRR,
  downloadReportPRR,
  downloadAllReportsPRR
} from "actions/battery/prrRequest";
import {
  updateTestReportStatusEPR,
  downloadReportEPR,
  downloadAllReportsEPR
} from "actions/battery/eprRequest";
import {
  updateTestReportStatusARR,
  downloadReportARR,
  downloadAllReportsARR
} from "actions/battery/arrRequest";
import {
  updateTestReportStatusACR,
  downloadReportACR,
  downloadAllReportsACR
} from "actions/battery/acrRequest";
import {
  updateTestReportStatusBSC,
  downloadReportBSC,
  downloadAllReportsBSC
} from "actions/battery/bscRequest";

const tdStyle = { width: 150 };

const BatteryLabTestResult = props => {
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [lockDrawer, setLockDrawer] = useState(false);
  const { request, user, testModule } = props;
  const {
    updateReportStatusHandler,
    downloadReportHandler,
    downloadAllReportsHandler
  } = getCertTestHandler(request, props);

  const onSelect = (testId, slot, event) => {
    const reportStatus = event.target.value;
    const args = {
      requestId: request.id,
      testId,
      reportSlot: slot,
      reportStatus
    };

    updateReportStatusHandler(args);
  };

  if (!testModule) return null;
  if (!testModule.reports || !testModule.reports.length) return null;
  if (!hasReportDocuments(testModule.reports)) return null;

  const toggleEditDrawer = () => {
    if (!lockDrawer) setShowEditDrawer(!showEditDrawer);
  };
  const toggleLockDrawer = () => {
    setLockDrawer(!lockDrawer);
  };

  const testReports = testModule.reports.filter(report => report.docId !== 0);
  const showEditLabResult =
    user.isStaff || (user.canEditDeviceInfo && request.isApproved);

  return (
    <div>
      <Row className="center-row">
        <Col xs={6}>
          <h3>Test Results</h3>
        </Col>
        <Col xs={6} className="text-right">
          {(showEditLabResult || (user.isStaff && request.isApproved)) && (
            <>
              <EditButton
                size="small"
                label="Edit"
                action={() => toggleEditDrawer()}
              />
              <Drawer
                position="bottom"
                show={showEditDrawer}
                title="Edit Test Results"
                toggleDrawer={toggleEditDrawer}
                content={
                  <EditTestResultForm
                    testModule={testModule}
                    toggleDrawer={toggleEditDrawer}
                    toggleLockDrawer={toggleLockDrawer}
                    setLockDrawer={setLockDrawer}
                    setShowEditDrawer={setShowEditDrawer}
                  />
                }
                height="auto"
              />
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Table responsive striped className="ctia-table">
            <thead>
              <tr>
                <th>Status</th>
                <th>Document Title</th>
                <th />
                <th style={tdStyle} />
              </tr>
            </thead>
            <tbody>
              {testReports.map((doc, i) => {
                if (!doc.document) return null;

                const status = doc.document.documentstatusid;

                return (
                  <tr key={i}>
                    <td>
                      <DocumentStatus status={status} />
                    </td>
                    <td>
                      <DocTitleReporter doc={doc} />
                    </td>
                    {/* <!--- Next block shows only for staff user ---> */}
                    <td>
                      {user.isStaff && (
                        <FormGroup controlId="tests">
                          <FormControl
                            componentClass="select"
                            value={status}
                            onChange={e =>
                              onSelect(doc.requesttestid, doc.slot, e)
                            }
                            disabled={
                              !(user.canEditDeviceInfo || user.canEditTests || user.isStaff)
                            }
                          >
                            <option value={1}>--- Not selected ---</option>
                            <option value={2}>Approved</option>
                            <option value={3}>Rejected</option>
                          </FormControl>
                        </FormGroup>
                      )}
                    </td>
                    <td style={tdStyle}>
                      <DownloadButton
                        size="small"
                        action={() => downloadReportHandler(doc, request)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <DownloadAllButton
        show={testReports.length > 1}
        action={() => downloadAllReportsHandler(request)}
      />
    </div>
  );
};

function mapStateToProps({ user, request }) {
  return { user, request };
}

export default connect(mapStateToProps, {
  updateTestReportStatusCRR,
  downloadReportCRR,
  downloadAllReportsCRR,
  updateTestReportStatusHRR,
  downloadReportHRR,
  downloadAllReportsHRR,
  updateTestReportStatusPRR,
  downloadReportPRR,
  downloadAllReportsPRR,
  updateTestReportStatusEPR,
  downloadReportEPR,
  downloadAllReportsEPR,
  updateTestReportStatusARR,
  downloadReportARR,
  downloadAllReportsARR,
  updateTestReportStatusACR,
  downloadReportACR,
  downloadAllReportsACR,
  updateTestReportStatusBSC,
  downloadReportBSC,
  downloadAllReportsBSC
})(BatteryLabTestResult);
