import React from "react";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";

const SelectState = ({ countries, defaultValue = "none" }) => {
  const hasCountryStates = countries && countries.states;

  return (
    <Form.Control
      required
      as="select"
      defaultValue={defaultValue}
      disabled={!hasCountryStates}
    >
      <option value="none">--- Select One ---</option>
      {hasCountryStates &&
        countries.states.map(state => {
          return (
            <option
              key={state.id}
              value={state.short}
              selected={state.short === defaultValue}
            >
              {state.name}
            </option>
          );
        })}
    </Form.Control>
  );
};

function mapStateToProps({ countries }) {
  return { countries };
}

export default connect(mapStateToProps)(SelectState);
