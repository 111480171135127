import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import TabInstructions from 'components/request/TabInstructions';
import instructions from 'dictionaries/TabInstructions';

const OTATestPlansBubble = ({ request, ota }) => {
  if (!request) return null;
  if (!request.otaInterfaces) return null;

  // hide bubble in case all interfaces were selected:
  if (_.size(ota.reqInterfaces) === 0) return null;

  const { selectFirst, selectMore } = instructions.testPlansOTA;
  const messages = _.size(request.otaInterfaces) > 0 ? selectMore : selectFirst;

  return <TabInstructions messages={messages} />;
};

function mapStateToProps({ request, ota }) {
  return { request, ota };
}

export default connect(mapStateToProps)(OTATestPlansBubble);
