import React from "react";
import { FormControl } from "react-bootstrap";
import StaticText from "components/form/StaticText";
import PropTypes from "prop-types";

const TextArea = ({
  value,
  onChange,
  editable = true,
  placeholder,
  style = { height: 50 }
}) => {
  if (!editable) {
    return <StaticText value={value} />;
  }

  return (
    <FormControl
      componentClass="textarea"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={style}
    />
  );
};

TextArea.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  editable: PropTypes.bool
};

TextArea.defaultProps = {
  value: "",
  placeholder: ""
};

export default TextArea;
