import React from "react";
import { connect } from "react-redux";
import IeeeBadge from "components/Badges/IeeeBadge";
import ReqLink from "components/RequestsLink";
import Portal from "components/Portal";

const RecognizedVersionsList = ({ app, suppliers, user, certPrefix }) => {
  if (!suppliers) return null;

  const { recognizedVersions } = suppliers;

  if (!recognizedVersions) {
    return (
      <tr colSpan="7">
        <td>{`There are no recognized version of this subsystem`}</td>
      </tr>
    );
  }

  const renderRequestTn = reqData => {
    if (
      user.isStaff ||
      (user.isLab && reqData.labId === user.companyid) ||
      ((user.isVendor || user.isSupplier) && reqData.submitterId === user.id) ||
      user.isOperator
    ) {
      if (app.isBLCCert) {
        const prefix = app.urlPrefix.replace(app.programPrefix, "battery");

        return (
          <Portal
            changeCertProgram="battery"
            to={`${prefix}requests/${certPrefix}/${reqData.id}`}
            tn={reqData.tn}
          />
        );
      }

      return (
        <ReqLink
          requestId={reqData.id}
          linkTitle={reqData.tn}
          subType={certPrefix}
        />
      );
    }

    return reqData.tn;
  };

  const renderRow = version => {
    return (
      <tr key={version.id}>
        <td>{renderRequestTn(version)}</td>
        <td>
          <IeeeBadge ieeeType={version.authtype} />
        </td>
        <td>{version.revision}</td>
        <td>
          <ul className="ctia-list">
            {version.manufsites.map((site, i) => {
              return <li key={`manufsite-version-${i}`}>{site.name}</li>;
            })}
          </ul>
        </td>
        <td>{version.crsl}</td>
        <td>{version.recognizeddat}</td>
        {/* <td>{version.status}</td> */}
      </tr>
    );
  };

  return recognizedVersions.map(version => {
    return renderRow(version);
  });
};

function mapStateToProps({ app, suppliers, user }) {
  return { app, suppliers, user };
}

export default connect(mapStateToProps)(RecognizedVersionsList);
