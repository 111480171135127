import React from "react";
import RequiredMark from "components/form/RequiredMark";

const FormFieldLabel = ({ label, isRequired = false }) => {
  if (!isRequired) return label;

  return (
    <>
      <RequiredMark /> {label}
    </>
  );
};

export default FormFieldLabel;
