import FormElement from "components/FormElement";
import React from "react";

const CertificationTypeSelector = ({ onChange, value }) => {
  const buttonValues = [
    "ACR",
    "ARR",
    "BLC",
    "BSC",
    "CRR",
    "Cyber",
    "DHR",
    "EPRR",
    "HRR",
    "OTA",
    "PRR"
  ].map(val => {
    return {
      title: val,
      value: val
    };
  });

  return (
    <FormElement
      className="CertificationType"
      id="certificationSelector"
      label="Certification Type"
      onChange={onChange}
      options={buttonValues}
      type="select"
      value={value}
      inputColSizeSm={2}
      inputColSizeMd={2}
    />
  );
};

export default CertificationTypeSelector;
