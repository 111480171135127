import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";
import { LoaderButton } from "ctia-ui";
import FormFieldLabel from "components/form/FormFieldLabel";
import UserProfilePasswordRules from "containers/UserProfile/UserProfilePasswordRules";
import LogoPng from "containers/Logo/LogoPng";
import { setNewPassword } from "actions/user";
import { checkPasswordRequirements } from "helpers/ValidatorHelper";
import { setBodyCustomClass } from "helpers/AppHelper";
import validation from "dictionaries/Validation";
import "containers/tempPass.css";

const TempPass = ({ user, setNewPassword }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // @HACK: replace default body background color
    setBodyCustomClass("gray");
  });

  const passesEqual = password === passwordConfirm;
  const passIsValid = checkPasswordRequirements(password);

  const isFormValid = () => {
    if (password === "" || passwordConfirm === "" || !passesEqual) return false;

    return passIsValid;
  };

  const onClick = () => {
    setLoading(true);
    setNewPassword(password, user.ctiaid)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  return (
    <div className="temp-pass__container">
      <div className="text-center">
        <LogoPng cssClass="temp-pass__logo--inverted" />
        <h2>Set a New Password</h2>
      </div>
      <div className="text-justify" style={{ paddingBottom: 15 }}>
        Please change your temporary password in order to use this site.
      </div>
      <Form.Group controlId="password">
        <Form.Label>
          <FormFieldLabel label="Password" isRequired={true} />
        </Form.Label>
        <Form.Control
          type="password"
          required
          placeholder="Enter password"
          onChange={e => setPassword(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="passwordConfirm">
        <Form.Label>
          <FormFieldLabel label="Re-Enter Password" isRequired={true} />
        </Form.Label>
        <Form.Control
          type="password"
          required
          placeholder="Re-Enter password"
          onChange={e => setPasswordConfirm(e.target.value)}
          isInvalid={passIsValid && !passesEqual}
        />
        <Form.Control.Feedback type="invalid">
          {validation.passwordsDontMatch}
        </Form.Control.Feedback>
      </Form.Group>
      <UserProfilePasswordRules
        newPassword={password}
        confirmPassword={passwordConfirm}
      />
      <div className="text-center block-top-offset">
        <LoaderButton
          bsSize="large"
          bsStyle="primary"
          text="Submit"
          className="btn-shadow button-right-offset"
          onClick={onClick}
          disabled={!isFormValid()}
          isLoading={loading}
        />
      </div>
    </div>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, { setNewPassword })(TempPass);
