import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Bubble } from 'ctia-ui';
import { isExisted } from 'helpers/RequestHelper';

const TabInstructions = ({ type, messages, request }) => {
  const { submissionText, updatingText } = messages;
  const msg = isExisted(request) ? updatingText : submissionText;
  if (msg && String(msg).length) {
    return <Bubble type={type}>{msg}</Bubble>;
  }
  return null;
};

TabInstructions.propTypes = {
  type: PropTypes.string,
  submissionText: PropTypes.string,
  updatingText: PropTypes.string
};

TabInstructions.defaultProps = {
  type: 'info',
  submissionText: '',
  updatingText: ''
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(TabInstructions);
