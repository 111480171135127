import _ from "lodash";
import { isExisted, isEco } from "helpers/RequestHelper";
import { validateManufSiteAddress } from "helpers/ValidatorHelper";

export const requestFields = {
  siteId: "manufSiteId",
  siteOption: "manufSiteOption"
};

export const toggleOptions = [
  {
    id: 1,
    label: "Select a Site",
    disabled: false
  },
  {
    id: 2,
    label: "Create new Site",
    disabled: false
  }
];

export const formatSitesList = list => {
  return [
    {
      value: 0,
      title: "Select an existing Site ↴"
    },
    ...list
  ];
};

export const mapManufSites = sitesList => {
  if (!sitesList || !sitesList.length) return [];

  return sitesList.map(manufSite => {
    return {
      value: manufSite.id,
      title: manufSite.name,
      label: manufSite.name,
      addressId: manufSite.addressid,
      checked: false
    };
  });
};

export const sitesInitState = {
  current: {
    manufSiteName: "",
    manufSiteLine1: "",
    manufSiteLine2: "",
    manufSiteCountry: "",
    manufSiteCity: "",
    manufSiteState: "",
    manufSiteZip: ""
  }
};

export const createNewSelected = request => {
  if (!request) return false;
  return Number(request[requestFields.siteOption]) === 2;
};

export const validateAddress = (request, manufSite) => {
  if (!request || !manufSite) return false;

  // manuf site was selected:
  if (request[requestFields.siteId]) return true;

  // validate manuf site address form:
  if (validateManufSiteAddress(manufSite)) return true;

  return false;
};

export const validateRenewalSite = request => {
  if (!request) return false;

  // manuf site was selected:
  if (request[requestFields.siteId]) return true;

  return false;
};

export const getManufSiteName = (request, sites) => {
  // existed requests, has manufSiteName from API
  if (isExisted(request)) return request.manufSiteName;

  // if it was selected from a list of sites during submission
  if (request.manufSiteId) {
    const manufSite = _.find(sites.list, [
      "value",
      Number(request.manufSiteId)
    ]);
    return manufSite ? manufSite.title : "n/a";
  }

  // if was entered during submission (address form)
  if (sites.current.manufSiteName) return sites.current.manufSiteName;

  // something went wrong, need to return default N/A value
  return "n/a";
};

export const getAddressData = (siteId, sites, addresses) => {
  if (!siteId) return null;

  const address = _.find(sites, ["value", Number(siteId)]);

  if (!address) return null;

  const addressFields = _.find(addresses, ["id", address.addressId]);

  return addressFields ? mapAddressFields(addressFields) : null;
};

export const addressFields = {
  address1: "Address (Line 1)",
  address2: "Address (Line 2)",
  countrycode: "Country Code",
  city: "City",
  state: "State",
  zipcode: "Postal Code"
};

export const mapAddressFields = address => {
  return _.mapValues(addressFields, (val, key) => {
    return address[key];
  });
};

export const mapManufAddress = address => {
  return {
    manufSiteName: address.name,
    manufSiteLine1: address.address1,
    manufSiteLine2: address.address2,
    manufSiteCountry: address.countrycode,
    manufSiteCity: address.city,
    manufSiteState: address.state,
    manufSiteZip: address.zipcode
  };
};

export const getSelectedManufSitesIds = manufSites => {
  if (!manufSites || _.isEmpty(manufSites)) return [];
  const filtered = _.filter(manufSites, "checked");
  return filtered.map(site => {
    return {
      manufsiteid: site.value
    };
  });
};

export const selectNonSarSitesInitialOrEco = request => {
  const { requestmanufsites: sites, parent, requesttypeid } = request;

  if (sites && sites.length > 0) return sites;

  return isEco(requesttypeid) ? parent.requestmanufsites : [];
};

export const mapSiteAddress = site => {
  if (!site) return [];

  return [
    {
      value: site.address1,
      label: _.isEmpty(site.address2) ? "Address" : "Address (Line 1)",
      show: !_.isEmpty(site.address1)
    },
    {
      value: site.address2,
      label: "Address (Line 2)",
      show: !_.isEmpty(site.address2)
    },
    {
      value: site.city,
      label: "City",
      show: !_.isEmpty(site.city)
    },
    {
      value: site.state,
      label: "State",
      show: !_.isEmpty(site.state)
    },
    {
      value: site.countrycode,
      label: "Country",
      show: !_.isEmpty(site.countrycode)
    },
    {
      value: site.zipcode,
      label: "Zipcode",
      show: !_.isEmpty(site.zipcode)
    }
  ];
};
