import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestHWVersion from "components/request/RequestHWVersion";
import RequestSWVersion from "components/request/RequestSWVersion";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestModel from "containers/request/OTA/OTARequestModel";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import { setValue } from "actions/request";
import { fetchOTAInterfaces } from "actions/ota";
import { submitWizardStep } from "actions/wizard";
import { updateDeviceInfo } from "actions/requestOta";
import { ifModelExists } from "actions/model";
import { hasStringValue } from "helpers/RequestHelper";
import { findExistedModelByName } from "helpers/ModelHelper";
import { getRequestOwner } from "helpers/UsersHelper";

class OTAContainer extends CyberComponent {
  modelField = this.props.request.id ? "modelName" : "existedModel";
  ownerId = getRequestOwner(this.props.request, this.props.user);

  componentDidMount() {
    // fetch only for the first time
    if (!this.validate()) {
      // fetch OTA interfaces
      this.props.fetchOTAInterfaces(this.ownerId);
    }
  }

  onSaveClick = () => {
    const { request, ifModelExists, updateDeviceInfo } = this.props;

    const modelNameUntouched =
      request.modelName === request.__orig.vendorproductname;

    if (!modelNameUntouched) {
      ifModelExists(request.modelName, this.ownerId, false).then(isUnique => {
        // allow to update in case only modelname is unique
        if (isUnique) {
          updateDeviceInfo(request);
        }
      });
    } else {
      updateDeviceInfo(request);
    }
  };

  validateModel() {
    return hasStringValue(this.props.request, this.modelField);
  }

  validateHw() {
    return hasStringValue(this.props.request, "hwVersion");
  }

  validateSw() {
    return hasStringValue(this.props.request, "swVersion");
  }

  validate() {
    // don't need to validate if form is non-editable
    if (!this.props.user.canEditDeviceInfo) return true;

    // check if all required fields are valid
    return this.validateModel() && this.validateHw() && this.validateSw();
  }

  onContinueClick = () => {
    const {
      ota,
      user,
      request,
      setValue,
      ifModelExists,
      submitWizardStep
    } = this.props;

    // check if entered model name exists
    const existedModel = findExistedModelByName(
      request.existedModel,
      ota.vendorModels
    );

    // if so - use existed model id
    const existedModelId = existedModel ? existedModel.value : null;

    // update request field in redux
    setValue("existedModelId", existedModelId);

    // check modelname uniqueness
    if (!existedModel) {
      ifModelExists(request.existedModel, user.companyid, false).then(
        isUnique => {
          if (isUnique) {
            return submitWizardStep();
          }
        }
      );
    } else {
      return submitWizardStep();
    }
  };

  render() {
    const saveHandler = this.props.user.canEditDeviceInfo
      ? this.onSaveClick
      : null;

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestManufacturer />
          <RequestModel />
          <RequestHWVersion />
          <RequestSWVersion />
          <SubmissionDetails />
          <RequestControls
            isValid={this.validate()}
            onSave={saveHandler}
            onContinue={this.onContinueClick}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ ota, request, user }) {
  return { ota, request, user };
}

export default connect(mapStateToProps, {
  fetchOTAInterfaces,
  submitWizardStep,
  updateDeviceInfo,
  setValue,
  ifModelExists
})(OTAContainer);
