import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import FormElement from "components/FormElement";
import DeviceInfoExtended from "containers/request/Initial/Steps/Vendor/DeviceInfo/DeviceInfoExtended";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import DeviceWireless from "containers/request/Initial/Steps/Vendor/DeviceInfo/DeviceWireless";
// import VerifiedWireless from 'containers/request/Initial/Steps/Laboratory/VerifiedWireless';
import { getModelName } from "helpers/RequestHelper";
// import { getDeviceTitle } from 'helpers/DeviceTypeHelper';

const DeviceInfo = ({ show, request }) => {
  if (!show) return null;

  const { vendor, /*deviceType,*/ certCategoryName } = request;

  if (!request.id) return null;

  return (
    <div>
      <Form horizontal>
        <FormElement id="manufTitle" label="Vendor" value={vendor} />
        <FormElement
          id="modelName"
          label="Model Name/Number"
          value={getModelName(request)}
        />
        <FormElement
          id="certCategory"
          label="Certification Level"
          value={certCategoryName}
        />
        <SubmissionDetails />
        {/* <FormElement
          id="deviceType"
          label="Device Type"
          value={getDeviceTitle(deviceType)}
        /> */}
        <DeviceInfoExtended />
        <DeviceWireless />
        {/* <VerifiedWireless /> */}
      </Form>
    </div>
  );
};

DeviceInfo.propTypes = { show: PropTypes.bool };

DeviceInfo.defaultProps = { show: true };

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(DeviceInfo);
