import React from "react";
import { Col } from "ctia-react-bootstrap-v4";
import CtiaButton from "components/form/buttons/CtiaButton";
import "containers/request/RequestTypeBlock.css";

const ProgramBlock = ({ card, onCardClick }) => {
  return (
    <Col lg={4} md={6} sm={12}>
      <div className="ctia-jumbo text-center box-with-shadow">
        <div className="ctia-jumbo__title">
          <h3 className="jumbo-block-header">
            {card.title}
            <br />
            {card.subTitle}
          </h3>
        </div>
        <div className="ctia-jumbo__descr">{card.intro}</div>
        <div className="ctia-jumbo__button">
          <CtiaButton
            color="light-blue"
            label="Continue"
            onClick={() => onCardClick(card)}
          />
        </div>
      </div>
    </Col>
  );
};

export default ProgramBlock;
