import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Card, Table } from "ctia-react-bootstrap-v4";
import SupplierRow from "containers/request/battery/AdapterSupplier/AdapterSupplierListRow";

const AdapterSupplierList = ({ request }) => {
  if (
    !request ||
    !request.adapterSuppliers ||
    _.isEmpty(request.adapterSuppliers)
  )
    return null;

  return (
    <div className="block-bottom-offset">
      <Card border="light">
        <Card.Body>
          <h3>Selected Adapters ({request.adapterSuppliers.length})</h3>
          <Table responsive striped hover size="sm" className="ctia-table">
            <thead>
              <tr>
                <th />
                <th>Adapter Supplier</th>
                <th>Adapter Model Name/Number</th>
                <th>Adapter Revision #</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {request.adapterSuppliers.map((supplier, i) => {
                return <SupplierRow key={i} supplier={supplier} />;
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(AdapterSupplierList);
