import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Bubble, LoaderButton } from 'ctia-ui';

const DecisionResultPage = ({ id, isAccepted }) => {
  const resultTitle = isAccepted ? 'accepted' : 'rejected';
  const renderReportsLink = () => {
    if (!isAccepted) return null;
    return (
      <Link to={`/cyber/lab/results/${id}`}>
        <LoaderButton bsStyle="info" text="Upload Reports &rarr;" />
      </Link>
    );
  };

  return (
    <div>
      <Bubble>Request has been successfully {resultTitle}.</Bubble>
      <Link to="/cyber/lab/requests" className="button-right-offset">
        <LoaderButton text="Done" />
      </Link>
      {renderReportsLink()}
    </div>
  );
};

DecisionResultPage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isAccepted: PropTypes.bool
};

DecisionResultPage.defaultProps = {
  isAccepted: false,
  id: 1
};

export default DecisionResultPage;
