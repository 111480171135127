// ES6 / Helper
import _ from "lodash";

// React / Redux
import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";

// Components and Containers
import NoTableData from "components/NoTableData";
import BatteryDeviceRow from "containers/Devices/Battery/BatteryDevices/BatteryDeviceTable/BatteryDeviceRow";

const BatteryDeviceRows = ({
  requestReloadTable = () => {},
  showDelistColumn,
  showStatusColumn,
  showWithdrawColumn,
  tableData,
}) => {
  if (!tableData) return null;

  const numberOfColumns = 3;
  if (_.isEmpty(tableData)) return <NoTableData colSpan={numberOfColumns} />;

  // temp solution to get rid of duplicates while waiting for BE to handle it
  const table = _.uniqBy(tableData, "id");

  return _.map(table, (row) => {
    return (
      <BatteryDeviceRow
        key={row.id}
        requestReloadTable={requestReloadTable}
        row={row}
        showDelistColumn={showDelistColumn}
        showStatusColumn={showStatusColumn}
        showWithdrawColumn={showWithdrawColumn}
      />
    );
  });
};

BatteryDeviceRows.propTypes = {
  tableData: PropTypes.array,
};

function mapStateToProps({ model, user }) {
  return { model, user };
}

export default connect(mapStateToProps)(BatteryDeviceRows);
