import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import Stepper from 'components/Stepper/newStepper';
import InfoBar from 'containers/request/InfoBar/index';
import TabContent from 'containers/submission/TabContent';

class SubmissionContainer extends Component {
  state = { compact: false };

  updateState = () => {
    const { xs } = this.props.app;
    this.setState({
      compact: xs ? true : false
    });
  };

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps) {
    const { app } = this.props;
    if (app.width !== prevProps.app.width) {
      this.updateState();
    }
  }

  render() {
    const { history, wizard } = this.props;

    if (wizard && (wizard.isSubmitted || wizard.isSaved)) return null;

    const stepperColSize = this.state.compact ? 12 : 3;
    const contentColSize = this.state.compact ? 12 : 9;

    return (
      <div>
        <Col xs={stepperColSize}>
          <Stepper />
        </Col>
        <Col xs={contentColSize} className="block-top-offset">
          <InfoBar />
        </Col>
        <Col xs={contentColSize}>
          <TabContent history={history} />
        </Col>
      </div>
    );
  }
}

function mapStateToProps({ app, wizard }) {
  return { app, wizard };
}

export default connect(mapStateToProps)(SubmissionContainer);
