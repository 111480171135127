import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "components/router/AppliedRoute";
import AuthenticatedRoute from "components/router/AuthenticatedRoute";
import RoleRoute from "components/router/RoleRoute";
// import Graph from "components/Graph";

import Home from "containers/Home";
import Terms from "containers/TermsOfUse/Terms";
import Policy from "containers/Policy";
import RequestList from "containers/request/RequestList/index";
// import OperatorRequests from "containers/request/RequestList/OperatorRequests";
import Approve from "containers/Approve";
import RequestNew from "containers/request/RequestNew";
import InitialSubmitPage from "containers/request/Initial/InitialSubmitPage";
import RequestInfoCyber from "containers/request/RequestInfo/RequestInfoCyber";
import EcoSubmitPage from "containers/request/ECO/EcoSubmitPage";
import CertifiedDevices from "containers/Devices/index";
import CertifiedDeviceView from "containers/Devices/CertifiedDevice";
import TestLabsCyber from "containers/AuthorizedLabs/LabsCyber";
import CyberCertificate from "containers/Devices/Cyber/CyberCertificate";
import TestRequestList from "containers/request/TestRequestList/index";
import TestResultsList from "containers/TestResultsList";
import TestResultsItem from "containers/TestResultsItem";
import NotFound from "containers/NotFound";
import Signup from "containers/Signup/index";
import ForgotPassword from "containers/Forgot";
import Payments from "containers/request/Payments/index";
import AddPayment from "containers/request/Payments/AddPayment";

// admin panel
import ControlPanel from "containers/ControlPanel/index";
// import Bands from "containers/ControlPanel/Bands/index";
import BudgetReports from "containers/ControlPanel/Reports/BudgetReport/BudgetReportContainer";
import CertifiedPendingReports from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/CertifiedPendingReportContainer";
import InvoiceReports from "containers/ControlPanel/Reports/InvoiceReport/InvoiceReportContainer";
import UsersList from "containers/users/UsersList/index";
import UserSearch from "containers/users/UserSearch/index";
import LabInvoicing from "containers/ControlPanel/Payments/LabInvoicing";
import ASCInvoicing from "containers/ControlPanel/Payments/ASC/ASCInvoicing";
import OrgsVendors from "containers/ControlPanel/Orgs/Vendors/OrgsVendors";
import OrgsLabs from "containers/ControlPanel/Orgs/Labs/OrgsLabs";
import OrgsOperators from "containers/ControlPanel/Orgs/Operators/OrgsOperators";
import OrgsServiceCenter from "containers/ControlPanel/Orgs/ServiceCenter/OrgsServiceCenter";
import DeletedUsers from "containers/ControlPanel/Users/DeletedUsers";
import Users2Labs from "containers/users/Users2Labs/index";
import UsersOfLabs from "containers/users/UsersOfLabs/index";

// OTA
import RequestInfoOta from "containers/request/RequestInfo/RequestInfoOta";
import TestLabsOta from "containers/AuthorizedLabs/LabsOta";
import OtaTests from "containers/request/OTA/OTATestRequestList";
import OTATestResults from "containers/request/OTA/TestResults/OTATestResults";
import OTASubmission from "containers/request/OTA/OTASubmitPage";
import OtaRequests from "containers/request/RequestList/OtaRequests";

// battery cert
import BatterySubmitSelector from "containers/request/battery/BatterySubmitSelector";
import RequestInfoBatterySAR from "containers/request/RequestInfo/RequestInfoBatterySAR";
import RequestInfoBatteryCRR from "containers/request/RequestInfo/RequestInfoBatteryCRR";
import RequestInfoBatteryHRR from "containers/request/RequestInfo/RequestInfoBatteryHRR";
import RequestInfoBatteryPRR from "containers/request/RequestInfo/RequestInfoBatteryPRR";
import RequestInfoBatteryEPR from "containers/request/RequestInfo/RequestInfoBatteryEPR";
import RequestInfoBatteryARR from "containers/request/RequestInfo/RequestInfoBatteryARR";
import RequestInfoBatteryACR from "containers/request/RequestInfo/RequestInfoBatteryACR";
import RequestInfoBatteryBSC from "containers/request/RequestInfo/RequestInfoBatteryBSC";
import BatteryTestResults from "containers/request/battery/TestResults/BatteryTestResults";
import EnterResultsContainerSAR from "containers/request/battery/TestResults/EnterResultsContainerSAR";
import TestResultsItemCRR from "containers/request/battery/TestResults/TestResultsItemCRR";
import TestResultsItemHRR from "containers/request/battery/TestResults/TestResultsItemHRR";
import TestResultsItemPRR from "containers/request/battery/TestResults/TestResultsItemPRR";
import TestResultsItemEPR from "containers/request/battery/TestResults/TestResultsItemEPR";
import TestResultsItemARR from "containers/request/battery/TestResults/TestResultsItemARR";
import TestResultsItemACR from "containers/request/battery/TestResults/TestResultsItemACR";
import TestResultsItemBSC from "containers/request/battery/TestResults/TestResultsItemBSC";
import BatteryAll from "containers/request/RequestList/battery/BatteryAll";
import BatteryApproveContainer from "containers/request/battery/BatteryApprove/BatteryApproveContainer";
import TestLabsBattery from "containers/AuthorizedLabs/LabsBattery";
import CertifiedDevicesBattery from "containers/Devices/Battery/BatteryDevices";
import BatteryCertifiedDeviceView from "containers/Devices/Battery/BatteryDeviceDetails/BatteryCertifiedDevice";
import BatteryTests from "containers/request/RequestList/battery/BatteryTests";

// Battery Life cert program (BLC)
import InitialBLC from "containers/request/BLC/InitialSubmitBLC";
import EcoBLC from "containers/request/BLC/EcoSubmitBLC";
import RequestInfoBLC from "containers/request/RequestInfo/RequestInfoBLC";
import TestResultsItemBLC from "containers/request/battery/TestResults/TestResultsItemBLC";
import TestLabsBLC from "containers/AuthorizedLabs/LabsBLC";

// Hardware Reliability cert program (DHR)
import InitialDHR from "containers/submission/DHR/InitialSubmitDHR";
import EcoDHR from "containers/submission/DHR/EcoSubmitDHR";
import RequestInfoDHR from "containers/request/RequestInfo/RequestInfoDHR";
import TestRequestListDHR from "containers/request/DHR/TestRequestListDHR";
import TestResultsItemDHR from "containers/request/RequestInfo/TestResultsItemDHR";
import TestLabsDHR from "containers/AuthorizedLabs/LabsDhr";

// User Profile
import UserProfile from "containers/UserProfile/UserProfile";

// Accounting Reports
import AccountingReports from "containers/AccountingReports/index";

// user roles
import roles from "dictionaries/UserRoles";
import NewUserRequestsMenu
    from "containers/users/UserRequest/UserRequestMenu/NewUserRequestMenu";
import NewUserRequestForm
    from "containers/users/UserRequest/UserRequestForm/Form";

const { vendor, supplier, lab, staff, accountant, operator } = roles;

export default ({ childProps }) => (
  <Switch>
    {/* home page route */}
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/terms" exact component={Terms} props={childProps} />
    <AppliedRoute path="/policy" exact component={Policy} props={childProps} />

    {/*
     ***********************************
     ***     Vendor/Manufacturer     ***
     ***********************************
     */}

    {/* cybersecurity */}
    <RoleRoute
      path="/cyber/vendor/requests"
      exact
      component={RequestList}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/cyber/vendor/requests/:id"
      exact
      component={RequestInfoCyber}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/cyber/vendor/request/new"
      exact
      component={RequestNew}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/cyber/vendor/request/new/initial"
      exact
      component={InitialSubmitPage}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/cyber/vendor/request/new/eco"
      exact
      component={EcoSubmitPage}
      props={childProps}
      role={vendor}
    />

    {/* OTA */}
    <RoleRoute
      path="/ota/vendor/request/new"
      exact
      component={OTASubmission}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/ota/vendor/requests"
      exact
      component={OtaRequests}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/ota/vendor/requests/:id"
      exact
      component={RequestInfoOta}
      props={childProps}
      role={vendor}
    />

    {/* battery */}
    <RoleRoute
      path="/battery/vendor/request/new"
      exact
      component={BatterySubmitSelector}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/battery/vendor/request/new/:type"
      exact
      component={BatterySubmitSelector}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/battery/vendor/requests"
      exact
      component={BatteryAll}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/battery/vendor/requests/eprr/:id"
      exact
      component={RequestInfoBatteryEPR}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/battery/vendor/requests/bsc/:id"
      exact
      component={RequestInfoBatteryBSC}
      props={childProps}
      role={vendor}
    />

    {/* battery life (BLC) */}
    <RoleRoute
      path="/blc/vendor/requests"
      exact
      component={RequestList}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/blc/vendor/requests/:id"
      exact
      component={RequestInfoBLC}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/blc/vendor/request/new"
      exact
      component={RequestNew}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/blc/vendor/request/new/initial"
      exact
      component={InitialBLC}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/blc/vendor/request/new/eco"
      exact
      component={EcoBLC}
      props={childProps}
      role={vendor}
    />

    {/* hardware reliability */}
    <RoleRoute
      path="/dhr/vendor/requests"
      exact
      component={RequestList}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/dhr/vendor/requests/:id"
      exact
      component={RequestInfoDHR}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/dhr/vendor/request/new"
      exact
      component={RequestNew}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/dhr/vendor/request/new/initial"
      exact
      component={InitialDHR}
      props={childProps}
      role={vendor}
    />
    <RoleRoute
      path="/dhr/vendor/request/new/eco"
      exact
      component={EcoDHR}
      props={childProps}
      role={vendor}
    />

    {/*
     ***********************************
     ***      Laboratory (Lab)       ***
     ***********************************
     */}

    {/* cybersecurity */}
    <RoleRoute
      path="/cyber/lab/requests"
      exact
      component={TestRequestList}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/cyber/lab/requests/:id"
      exact
      component={RequestInfoCyber}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/cyber/lab/results"
      exact
      component={TestResultsList}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/cyber/lab/results/:id"
      exact
      component={TestResultsItem}
      props={childProps}
      role={lab}
    />

    {/* OTA */}
    <RoleRoute
      path="/ota/lab/requests"
      exact
      component={OtaTests}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/ota/lab/requests/:id"
      exact
      component={RequestInfoOta}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/ota/lab/results"
      exact
      component={TestResultsList}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/ota/lab/results/:id"
      exact
      component={OTATestResults}
      props={childProps}
      role={lab}
    />

    {/* battery */}
    <RoleRoute
      path="/battery/lab/requests"
      exact
      component={BatteryTests}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/requests/sar/:id"
      exact
      component={RequestInfoBatterySAR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/requests/crr/:id"
      exact
      component={RequestInfoBatteryCRR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/requests/hrr/:id"
      exact
      component={RequestInfoBatteryHRR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/requests/prr/:id"
      exact
      component={RequestInfoBatteryPRR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/requests/eprr/:id"
      exact
      component={RequestInfoBatteryEPR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/requests/arr/:id"
      exact
      component={RequestInfoBatteryARR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/requests/acr/:id"
      exact
      component={RequestInfoBatteryACR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/requests/bsc/:id"
      exact
      component={RequestInfoBatteryBSC}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/results"
      exact
      component={BatteryTestResults}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/results/sar/:id"
      exact
      component={EnterResultsContainerSAR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/results/crr/:id"
      exact
      component={TestResultsItemCRR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/results/hrr/:id"
      exact
      component={TestResultsItemHRR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/results/prr/:id"
      exact
      component={TestResultsItemPRR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/results/eprr/:id"
      exact
      component={TestResultsItemEPR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/results/arr/:id"
      exact
      component={TestResultsItemARR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/results/acr/:id"
      exact
      component={TestResultsItemACR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/battery/lab/results/bsc/:id"
      exact
      component={TestResultsItemBSC}
      props={childProps}
      role={lab}
    />

    {/* DHR */}
    <RoleRoute
      path="/dhr/lab/requests"
      exact
      component={TestRequestListDHR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/dhr/lab/requests/:id"
      exact
      component={RequestInfoDHR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/dhr/lab/results"
      exact
      component={TestResultsList}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/dhr/lab/results/:id"
      exact
      component={TestResultsItemDHR}
      props={childProps}
      role={lab}
    />

    {/* BLC */}
    <RoleRoute
      path="/blc/lab/requests"
      exact
      component={TestRequestListDHR}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/blc/lab/requests/:id"
      exact
      component={RequestInfoBLC}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/blc/lab/results"
      exact
      component={TestResultsList}
      props={childProps}
      role={lab}
    />
    <RoleRoute
      path="/blc/lab/results/:id"
      exact
      component={TestResultsItemBLC}
      props={childProps}
      role={lab}
    />
    {/*
     ***********************************
     ***    Admins (CTIA Staff)      ***
     ***********************************
     */}

    {/* cybersecurity */}
    <RoleRoute
      path="/cyber/admin/requests"
      exact
      component={RequestList}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/cyber/admin/requests/:id/:focusLab?"
      exact
      component={RequestInfoCyber}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/cyber/admin/approval"
      exact
      component={Approve}
      props={childProps}
      role={staff}
    />

    {/* OTA */}
    <RoleRoute
      path="/ota/admin/requests"
      exact
      component={OtaRequests}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/ota/admin/requests/:id"
      exact
      component={RequestInfoOta}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/ota/admin/approval"
      exact
      component={Approve}
      props={childProps}
      role={staff}
    />

    {/* battery */}
    <RoleRoute
      path="/battery/admin/requests"
      exact
      component={BatteryAll}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/battery/admin/requests/sar/:id/:focusLab?"
      exact
      component={RequestInfoBatterySAR}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/battery/admin/approval"
      exact
      component={BatteryApproveContainer}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/battery/admin/requests/crr/:id/:focusLab?"
      exact
      component={RequestInfoBatteryCRR}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/battery/admin/requests/hrr/:id/:focusLab?"
      exact
      component={RequestInfoBatteryHRR}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/battery/admin/requests/prr/:id/:focusLab?"
      exact
      component={RequestInfoBatteryPRR}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/battery/admin/requests/eprr/:id/:focusLab?"
      exact
      component={RequestInfoBatteryEPR}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/battery/admin/requests/arr/:id/:focusLab?"
      exact
      component={RequestInfoBatteryARR}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/battery/admin/requests/acr/:id/:focusLab?"
      exact
      component={RequestInfoBatteryACR}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/battery/admin/requests/bsc/:id/:focusLab?"
      exact
      component={RequestInfoBatteryBSC}
      props={childProps}
      role={staff}
    />

    {/* dhr */}
    <RoleRoute
      path="/dhr/admin/requests"
      exact
      component={RequestList}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/dhr/admin/requests/:id/:focusLab?"
      exact
      component={RequestInfoDHR}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/dhr/admin/approval"
      exact
      component={Approve}
      props={childProps}
      role={staff}
    />

    {/* blc */}
    <RoleRoute
      path="/blc/admin/requests"
      exact
      component={RequestList}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/blc/admin/requests/:id/:focusLab?"
      exact
      component={RequestInfoBLC}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/blc/admin/approval"
      exact
      component={Approve}
      props={childProps}
      role={staff}
    />

    {/* admin control panel */}
    <RoleRoute
      path="/admin/cp"
      exact
      component={ControlPanel}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/users"
      exact
      component={UsersList}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/users/newuserrequests"
      exact
      component={NewUserRequestsMenu}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/users/newuserrequests/:id"
      exact
      component={NewUserRequestForm}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/users/search"
      exact
      component={UserSearch}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/users/deleted"
      exact
      component={DeletedUsers}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/users2labs"
      exact
      component={Users2Labs}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/users-of-labs"
      exact
      component={UsersOfLabs}
      props={childProps}
      role={staff}
    />
    {/* <RoleRoute
      path="/admin/cp/bands"
      exact
      component={Bands}
      props={childProps}
      role={staff}
    /> */}
    <RoleRoute
      path="/admin/cp/reports/budget"
      exact
      component={BudgetReports}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/reports/certifiedpending"
      exact
      component={CertifiedPendingReports}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/reports/invoice"
      exact
      component={InvoiceReports}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/payments/invoice/lab"
      exact
      component={LabInvoicing}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/payments/invoice/asc"
      exact
      component={ASCInvoicing}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/organizations/vendors"
      exact
      component={OrgsVendors}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/organizations/labs"
      exact
      component={OrgsLabs}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/organizations/operators"
      exact
      component={OrgsOperators}
      props={childProps}
      role={staff}
    />
    <RoleRoute
      path="/admin/cp/organizations/asc"
      exact
      component={OrgsServiceCenter}
      props={childProps}
      role={staff}
    />

    {/*
     ***********************************
     ***       CTIA Accounting       ***
     ***********************************
     */}

    <RoleRoute
      path="/cyber/accountant/payments"
      exact
      component={Payments}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/ota/accountant/payments"
      exact
      component={Payments}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/dhr/accountant/payments"
      exact
      component={Payments}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/battery/accountant/payments"
      exact
      component={Payments}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/blc/accountant/payments"
      exact
      component={Payments}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/cyber/accountant/payments/:id/add"
      exact
      component={AddPayment}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/ota/accountant/payments/:id/add"
      exact
      component={AddPayment}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/dhr/accountant/payments/:id/add"
      exact
      component={AddPayment}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/battery/accountant/payments/:certType/:id/add"
      exact
      component={AddPayment}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/blc/accountant/payments/:id/add"
      exact
      component={AddPayment}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/cyber/accountant/requests/:id"
      exact
      component={RequestInfoCyber}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/ota/accountant/requests/:id"
      exact
      component={RequestInfoOta}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/dhr/accountant/requests/:id"
      exact
      component={RequestInfoDHR}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/battery/accountant/requests/sar/:id"
      exact
      component={RequestInfoBatterySAR}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/battery/accountant/requests/crr/:id"
      exact
      component={RequestInfoBatteryCRR}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/battery/accountant/requests/hrr/:id"
      exact
      component={RequestInfoBatteryHRR}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/battery/accountant/requests/prr/:id"
      exact
      component={RequestInfoBatteryPRR}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/battery/accountant/requests/eprr/:id"
      exact
      component={RequestInfoBatteryEPR}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/battery/accountant/requests/arr/:id"
      exact
      component={RequestInfoBatteryARR}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/battery/accountant/requests/acr/:id"
      exact
      component={RequestInfoBatteryACR}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/blc/accountant/requests/:id"
      exact
      component={RequestInfoBLC}
      props={childProps}
      role={accountant}
    />

    {/** Accounting access to reports */}
    <RoleRoute
      path="/accountant/reports"
      exact
      component={AccountingReports}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/accountant/reports/budget"
      exact
      component={BudgetReports}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/accountant/reports/invoice"
      exact
      component={InvoiceReports}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/accountant/reports/certifiedpending"
      exact
      component={CertifiedPendingReports}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/accountant/atl"
      exact
      component={LabInvoicing}
      props={childProps}
      role={accountant}
    />
    <RoleRoute
      path="/accountant/asc"
      exact
      component={ASCInvoicing}
      props={childProps}
      role={accountant}
    />

    {/*
     ***********************************
     ***          Operators          ***
     ***********************************
     */}

    {/* <RoleRoute path="/operator/requests" exact component={OperatorRequests} props={childProps} role={operator} /> */}
    <RoleRoute
      path="/cyber/operator/requests/:id"
      exact
      component={RequestInfoCyber}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/ota/operator/requests/:id"
      exact
      component={RequestInfoOta}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/dhr/operator/requests/:id"
      exact
      component={RequestInfoDHR}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/battery/operator/requests/sar/:id"
      exact
      component={RequestInfoBatterySAR}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/battery/operator/requests/crr/:id"
      exact
      component={RequestInfoBatteryCRR}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/battery/operator/requests/hrr/:id"
      exact
      component={RequestInfoBatteryHRR}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/battery/operator/requests/prr/:id"
      exact
      component={RequestInfoBatteryPRR}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/battery/operator/requests/eprr/:id"
      exact
      component={RequestInfoBatteryEPR}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/battery/operator/requests/arr/:id"
      exact
      component={RequestInfoBatteryARR}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/battery/operator/requests/acr/:id"
      exact
      component={RequestInfoBatteryACR}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/blc/operator/requests/:id"
      exact
      component={RequestInfoBLC}
      props={childProps}
      role={operator}
    />
    <RoleRoute
      path="/battery/operator/requests/bsc/:id"
      exact
      component={RequestInfoBatteryBSC}
      props={childProps}
      role={operator}
    />

    {/*
        ***********************************
        ***          Suppliers          ***
        ***********************************
          Like vendors, but really don't
                    ¯\_(ツ)_/¯
    */}

    {/* battery */}
    <RoleRoute
      path="/battery/supplier/request/new"
      exact
      component={BatterySubmitSelector}
      props={childProps}
      role={supplier}
    />
    <RoleRoute
      path="/battery/supplier/request/new/:type"
      exact
      component={BatterySubmitSelector}
      props={childProps}
      role={supplier}
    />
    <RoleRoute
      path="/battery/supplier/requests"
      exact
      component={BatteryAll}
      props={childProps}
      role={supplier}
    />
    <RoleRoute
      path="/battery/supplier/requests/:id"
      exact
      component={RequestInfoCyber}
      props={childProps}
      role={supplier}
    />
    <RoleRoute
      path="/battery/supplier/requests/sar/:id"
      exact
      component={RequestInfoBatterySAR}
      props={childProps}
      role={supplier}
    />
    <RoleRoute
      path="/battery/supplier/requests/crr/:id"
      exact
      component={RequestInfoBatteryCRR}
      props={childProps}
      role={supplier}
    />
    <RoleRoute
      path="/battery/supplier/requests/hrr/:id"
      exact
      component={RequestInfoBatteryHRR}
      props={childProps}
      role={supplier}
    />
    <RoleRoute
      path="/battery/supplier/requests/prr/:id"
      exact
      component={RequestInfoBatteryPRR}
      props={childProps}
      role={supplier}
    />
    <RoleRoute
      path="/battery/supplier/requests/eprr/:id"
      exact
      component={RequestInfoBatteryEPR}
      props={childProps}
      role={supplier}
    />
    <RoleRoute
      path="/battery/supplier/requests/arr/:id"
      exact
      component={RequestInfoBatteryARR}
      props={childProps}
      role={supplier}
    />
    <RoleRoute
      path="/battery/supplier/requests/acr/:id"
      exact
      component={RequestInfoBatteryACR}
      props={childProps}
      role={supplier}
    />

    {/*
        ***********************************
        ***          All roles          ***
        ***********************************
                Not role-based routes
            But need to be authentificated
    */}

    {/* cybersecurity */}
    <AuthenticatedRoute
      path="/cyber/devices"
      exact
      component={CertifiedDevices}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/cyber/devices/:id"
      exact
      component={CertifiedDeviceView}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/cyber/labs"
      exact
      component={TestLabsCyber}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/cyber/certificate/:id"
      exact
      component={CyberCertificate}
      props={childProps}
    />

    {/* <AuthenticatedRoute path="/graph" exact component={Graph} props={childProps} /> */}

    {/* OTA */}
    <AuthenticatedRoute
      path="/ota/devices"
      exact
      component={CertifiedDevices}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/ota/devices/:id"
      exact
      component={CertifiedDeviceView}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/ota/labs"
      exact
      component={TestLabsOta}
      props={childProps}
    />

    {/* Battery */}
    <AuthenticatedRoute
      path="/battery/labs"
      exact
      component={TestLabsBattery}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/battery/devices"
      exact
      component={CertifiedDevicesBattery}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/battery/devices/:type/:id"
      exact
      component={BatteryCertifiedDeviceView}
      props={childProps}
    />

    {/* DHR */}
    <AuthenticatedRoute
      path="/dhr/devices"
      exact
      component={CertifiedDevices}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/dhr/devices/:id"
      exact
      component={CertifiedDeviceView}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/dhr/labs"
      exact
      component={TestLabsDHR}
      props={childProps}
    />

    {/* BLC */}
    <AuthenticatedRoute
      path="/blc/devices"
      exact
      component={CertifiedDevices}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/blc/devices/:id"
      exact
      component={CertifiedDeviceView}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/blc/labs"
      exact
      component={TestLabsBLC}
      props={childProps}
    />

    {/*
     ***********************************
     ***        Miscellaneous        ***
     ***********************************
     */}

    {/* User Management */}
    <AppliedRoute path="/user/new" exact component={Signup} />
    <AppliedRoute path="/user/recover" exact component={ForgotPassword} />
    <AuthenticatedRoute
      path="/user/profile"
      exact
      component={UserProfile}
      props={childProps}
    />

    {/* 404 page */}
    <Route component={NotFound} />
  </Switch>
);
