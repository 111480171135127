import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SubmissionRender from 'containers/submission/SubmissionRender';
import { cyberTabs } from 'helpers/WizardHelper';
import { clearModel } from 'actions/model';
import { clearRequest, createEcoCyberRequest } from 'actions/request';
import { initWizard, clearWizard } from 'actions/wizard';

class EcoSubmitPage extends Component {
  // init redux storage with default values for a request and a wizard
  componentDidMount() {
    this.props.createEcoCyberRequest(this.props.user.name);
    this.props.initWizard({
      steps: cyberTabs.eco,
      totalSteps: _.size(cyberTabs.eco)
    });
  }

  componentWillUnmount() {
    this.props.clearRequest();
    this.props.clearModel();
    this.props.clearWizard();
  }

  render() {
    return <SubmissionRender history={this.props.history} />;
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(
  mapStateToProps,
  {
    createEcoCyberRequest,
    initWizard,
    clearRequest,
    clearWizard,
    clearModel
  }
)(EcoSubmitPage);
