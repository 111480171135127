import React from "react";
import { connect } from "react-redux";
import { WithModal, IconLabel } from "ctia-ui";
import EditInvoiceModal from "containers/ControlPanel/Payments/ASC/EditInvoice/EditInvoiceModalASC";
import { clearInvoiceASC } from "actions/asc";
import { showNotice } from "actions/notifier";
import { notifyMessages } from "helpers/NotifyHelper";

class EditInvoiceASC extends WithModal {
  modalHandler = () => {
    const { asc, showNotice } = this.props;

    if (asc.isBackgroundJobRunning) {
      return showNotice(notifyMessages.backgroundJobInActionWarning);
    }

    this.props.clearInvoiceASC();
    this.handleModal();
  };

  render() {
    return this.props.showLink ? (
      <div>
        <IconLabel awesomeIcon="edit" />{" "}
        <span className="glow-link cursor-pointer" onClick={this.modalHandler}>
          Edit Invoice
        </span>
        <EditInvoiceModal
          show={this.state.showModal}
          modalHandler={this.modalHandler}
          {...this.props}
        />
      </div>
    ) : null;
  }
}

function mapStateToProps({ asc }) {
  return { asc };
}

export default connect(mapStateToProps, { clearInvoiceASC, showNotice })(
  EditInvoiceASC
);
