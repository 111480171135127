import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestDescChanges from "components/request/RequestDescChanges";
import BatteryParentDetails from "components/request/BatteryParentDetails";
import RequestHWVersion from "components/request/RequestHWVersion";
import RequestSWVersion from "components/request/RequestSWVersion";
import RequestEcoModelBSC from "containers/request/BSC/RequestEcoModelBSC";
import BatteryPacks from "containers/request/battery/BatterySupplier/BatteryPacks";
import Adapters from "containers/request/battery/AdapterSupplier/Adapters";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import RequestParentLink from "components/request/RequestParentLink";
import { setValue, setBatteryRequestPermissions } from "actions/request";
import { updateDeviceInfoBSC, initEcoBSC } from "actions/battery/bscRequest";
import { submitWizardStep } from "actions/wizard";
import { hasStringValue } from "helpers/RequestHelper";
import { getRequestOwner } from "helpers/UsersHelper";

class RequestECOContainerBSC extends React.Component {
  modelField = this.props.request.id ? "modelName" : "existedModel";

  componentDidMount() {
    const { request, user } = this.props;
    const vendorId = getRequestOwner(request, user);

    // fetch only for the first time on submissions
    if (!this.validate() && !request.id) {
      this.props.initEcoBSC({ ieee: request.ieee, vendorId });
    }

    // set permissions for existed request
    this.props.setBatteryRequestPermissions(request, user);
  }

  onSaveClick = () => {
    this.props.updateDeviceInfoBSC(
      this.props.request,
      this.props.sites.nonSarSites
    );
  };

  validateModel() {
    return hasStringValue(this.props.request, this.modelField);
  }

  validateHw() {
    return hasStringValue(this.props.request, "hwVersion");
  }

  validateSw() {
    return hasStringValue(this.props.request, "swVersion");
  }

  validateDesc() {
    return hasStringValue(this.props.request, "descriptionOfChange");
  }

  validateBatterySuppliers() {
    const { batterySuppliers } = this.props.request;

    return batterySuppliers ? batterySuppliers.length > 0 : false;
  }

  validateAdapterSuppliers() {
    const { adapterSuppliers, adapterType } = this.props.request;

    // can proceed if "No Adapter" selected
    const noAdapter = adapterType && Number(adapterType) === 4;

    if (noAdapter) return true;

    return adapterSuppliers ? adapterSuppliers.length > 0 : false;
  }

  validate() {
    // don't need to validate if form is non-editable
    if (this.props.user.canEditDeviceInfo === false) return true;

    // check if all required fields are valid
    return (
      this.validateModel() &&
      this.validateHw() &&
      this.validateSw() &&
      this.validateDesc() &&
      this.validateBatterySuppliers() &&
      this.validateAdapterSuppliers()
    );
  }

  render() {
    const { request, user } = this.props;
    const { canEditDeviceInfo } = user;
    const saveHandler = canEditDeviceInfo ? this.onSaveClick : null;

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestParentLink certPrefix="bsc" />
          <BatteryParentDetails />
          <hr />
          <RequestManufacturer />
          <RequestEcoModelBSC />
          <hr />
          {request[this.modelField] && (
            <>
              <RequestHWVersion />
              <RequestSWVersion />
              <RequestDescChanges />
              <SubmissionDetails />
              <hr />
              <BatteryPacks />
              <hr />
              <Adapters />
            </>
          )}
          <RequestControls isValid={this.validate()} onSave={saveHandler} />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, user, sites }) {
  return { request, user, sites };
}

export default connect(mapStateToProps, {
  submitWizardStep,
  updateDeviceInfoBSC,
  setValue,
  setBatteryRequestPermissions,
  initEcoBSC
})(RequestECOContainerBSC);
