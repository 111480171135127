import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";
import { hasStringValue } from "helpers/RequestHelper";
import { emptyMsg } from "helpers/FormHelper";

const RequestModelnameSimple = ({ app, request, user, handleInputChange }) => {
  const [show, setShow] = useState(false);
  const [touched, setTouched] = useState(false);

  const hasFieldValue = useCallback(() => {
    // check for cyber program
    if (app.isCyberCert) {
      return false;
      // return hasStringValue(request, 'modelName');
    }

    // check for battery program
    if (app.isBatteryCert) {
      return true;
    }

    // check for BLC program
    if (app.isBLCCert) {
      return true;
    }

    // check for DHR program
    if (app.isDHRCert) {
      return false;
      // return hasStringValue(request, 'modelName') || request.isEco;
    }

    return false;
  }, [app]);

  useEffect(() => {
    setShow(hasFieldValue());
  }, [hasFieldValue]);

  const isValidationRequired = () => {
    // @TODO: pass prop isRequiredField, check after canEditRequest
    return user.canEditRequest;
  };

  const validateField = () => {
    if (!isValidationRequired() || !touched) return null;

    return show && hasStringValue(request, "modelName") ? null : "error";
  };

  const onFieldChanged = event => {
    if (!touched) {
      setTouched(true);
    }

    handleInputChange(event);
  };

  const isEditable = user.canEditModelname;
  const inputType = isEditable ? "text" : "";

  return (
    <FormElement
      id="modelName"
      label="Model Name/Number"
      value={request.modelName}
      show={show}
      editable={isEditable}
      type={inputType}
      onChange={onFieldChanged}
      validationState={validateField()}
      validationMessage={emptyMsg}
    />
  );
};

function mapStateToProps({ app, request, user }) {
  return { app, request, user };
}

export default connect(mapStateToProps, { handleInputChange })(
  RequestModelnameSimple
);
