import React from "react";
import { formatDate } from "helpers/DateHelper";
import { renderUserStatus } from "helpers/UsersHelper";
import EditUserLink from "containers/ControlPanel/Users/EditUser/EditUserLink";

const CompanyUserRow = ({ item }) => {
  return (
    <tr key={item.id}>
      <td>
        <EditUserLink userData={item} />
      </td>
      <td>{item.title}</td>
      <td>{item.email}</td>
      <td>{formatDate(item.lastloginat)}</td>
      <td>{renderUserStatus(item)}</td>
    </tr>
  );
};

export default CompanyUserRow;
