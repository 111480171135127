import _ from "lodash";
import { restClientGeneral } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import * as call from "helpers/ActionHelper";
import * as args from "helpers/ArgsHelperACR";
import * as th from "helpers/RequestTestsHelper";
import * as actions from "actions/types";
import * as sites from "actions/sites";
import * as sign from "actions/agreementsBattery";
import * as req from "actions/request";
import * as breq from "actions/battery/batteryRequests";
import * as tests from "actions/tests";
import { fileDownload } from "actions/uploads";

export const initACR = (companyid = null) => async dispatch => {
  dispatch(call.startPreloader());

  return dispatch(sites.fetchNonSarSites(companyid))
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const fetchEcoModelsACR = (ieee, vendorId) => async dispatch => {
  try {
    const url = `/acr/devicemodels?where=ieee${ieee}acr=1 AND vendorid=${vendorId}&include=requests&select=id,modelnumber&orderBY=modelnumber ASC&page=0`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_ACR_ECO_MODELS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const initEcoACR = (ieee, companyid = null) => async dispatch => {
  dispatch(call.startPreloader());

  return dispatch(fetchEcoModelsACR(ieee, companyid))
    .then(() => dispatch(sites.fetchNonSarSites(companyid)))
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const createRequestACR = request => async dispatch => {
  try {
    const acrArgs = args.prepareInitialArgsACR(request);
    const response = await restClientGeneral.post(
      acrArgs.url,
      JSON.stringify(acrArgs.body)
    );

    const { id, devicemodelid, requests } = response.data.data;
    const newId = _.isArray(requests) ? requests[0].id : id;
    const deviceModelId = _.isArray(requests) ? id : devicemodelid;

    dispatch({
      type: actions.SUBMIT_INITIAL_REQUEST,
      payload: {
        newId,
        deviceModelId,
        isACR: true
      }
    });

    const agreementSigned =
      request.complianceDeclarationToggler && request.licenseAgreementToggler;

    if (agreementSigned) {
      dispatch({
        type: actions.SUBMIT_WIZARD,
        payload: { isSubmitted: true }
      });
    } else {
      dispatch({
        type: actions.SAVE_WIZARD,
        payload: { isSaved: true }
      });
    }

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitInitialACR(request) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(createRequestACR(request))
      .then(res => {
        const { id, devicemodelid } = res.data.data;
        const modelId = devicemodelid || id;
        const needAgreementSign =
          request.complianceDeclarationToggler &&
          request.licenseAgreementToggler;

        if (needAgreementSign) {
          return dispatch(
            sign.signDeclarationAgreement(modelId, request.ieee, "acr")
          ).then(() =>
            dispatch(
              sign.signRecognitionAgreement(modelId, request.ieee, "acr")
            )
          );
        } else return res;
      })
      .then(quit)
      .catch(quit);
  };
}

export const createEcoRequestACR = request => async dispatch => {
  try {
    const reqBody = args.prepareEcoArgsACR(request);
    const response = await restClientGeneral.post(
      "acr/requests",
      JSON.stringify(reqBody)
    );
    const { id, devicemodelid } = response.data.data;

    dispatch({
      type: actions.SUBMIT_ECO_REQUEST,
      payload: {
        newId: id,
        deviceModelId: devicemodelid,
        isACR: true
      }
    });

    dispatch({
      type: actions.SUBMIT_WIZARD,
      payload: { isSubmitted: true }
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitEcoACR(request) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(createEcoRequestACR(request)).then(quit).catch(quit);
  };
}

export const getRequestDetailsACR = id => async dispatch => {
  try {
    const url = `/acr/requests/${id}/details`;
    const res = await restClientGeneral.get(url);
    const payload = res.data.data;

    dispatch({ type: actions.FETCH_REQUEST_ACR, payload });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

// export const getParentRequestDetailsCRR = id => async dispatch => {
//   try {
//     const url = `/crr/requests/${id}/details`;
//     const response = await restClientGeneral.get(url);
//     const payload = response.data.data;

//     dispatch({ type: actions.FETCH_PARENT_REQUEST_CRR, payload });

//     return response;
//   } catch (e) {
//     dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

//     return Promise.reject(e);
//   }
// };

export const fetchRequestTestsACR = requestId => async dispatch => {
  try {
    const url = `/acr/requesttests?include=reports,requesttestconductinglabs,lab,interface&where=requestid=${requestId}`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_REQUEST_TESTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchTestReportsACR = testModule => async dispatch => {
  try {
    const url = `/acr/requesttests/${testModule.id}/reports`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_TEST_REPORTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getDetailsACR(requestId) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getRequestDetailsACR(requestId))
      .then(res => {
        const { requestmanufsites, submitter } = res.data.data;
        dispatch(sites.fetchNonSarSites(submitter.companyid)).then(() => {
          dispatch(breq.mapNonSarManufSites(requestmanufsites));
        });
      })
      .then(() => dispatch(fetchRequestTestsACR(requestId)))
      .then(res => {
        const resData = res.data;
        if (!resData) return Promise.resolve(null);

        const testId = resData.data[0].id;
        return dispatch(fetchTestReportsACR({ id: testId }));
      })
      .then(quit)
      .catch(quit);
  };
}

export function submitTestResultsACR(request, testModule, certTypes) {
  const testResArgs = args.prepareTestResultsArgs(
    request,
    testModule,
    certTypes
  );
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(sendTestResultsACR(testResArgs, request.id))
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch({
          type: actions.SAVE_REQUEST,
          payload: { formSaved: true, isLoading: true }
        });
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });
  };
}

export const sendTestResultsACR = (args, reqId) => async dispatch => {
  try {
    return await restClientGeneral.put(
      `/acr/requests/${reqId}`,
      JSON.stringify(args)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSendTestResult));

    return Promise.reject(e);
  }
};

export const updateRequestByArgs = reqArgs => async dispatch => {
  try {
    const url = `/acr/requests/${reqArgs.args.requestId}`;

    return await restClientGeneral.put(url, JSON.stringify(reqArgs));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export const updateModelACR = modelArgs => async dispatch => {
  try {
    const url = `/acr/devicemodels/${modelArgs.args.modelId}`;
    return await restClientGeneral.put(url, JSON.stringify(modelArgs));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function signSavedInitialACR(modelId, requestId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(sign.signDeclarationAgreement(modelId, ieee, "acr"))
      .then(() => dispatch(sign.signRecognitionAgreement(modelId, ieee, "acr")))
      .then(() => dispatch(getRequestDetailsACR(requestId)))
      .then(quit)
      .catch(quit);
  };
}

export function mapManufSitesACR(linkedSites) {
  return {
    type: actions.MAP_NON_SAR_SITES,
    payload: linkedSites
  };
}

export const getModelDetailsACR = (modelId, ieee) => async dispatch => {
  try {
    const url = `/acr/requests?include=requestmanufsites&where=devicemodelid=${modelId} AND requeststatusid <> 8 AND ieeetypeid=${ieee}&orderBY=requestid DESC&perPage=1`;
    return await restClientGeneral.get(url);
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export function fetchModelACR(modelId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getModelDetailsACR(modelId, ieee))
      .then(res => {
        const payload = res.data.data[0];

        dispatch({
          type: actions.FETCH_ACR_MODEL,
          payload
        });

        return res;
      })
      .then(res =>
        dispatch(mapManufSitesACR(res.data.data[0].requestmanufsites))
      )
      .then(quit)
      .catch(quit);
  };
}

export const updateDeviceModel = deviceModel => async dispatch => {
  try {
    return await restClientGeneral.put(
      `/acr/devicemodels/${deviceModel.args.modelId}`,
      JSON.stringify(deviceModel)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function updateDeviceInfoACR(request, sites) {
  const deviceModel = args.prepareModelArgsACR(request);
  const reqArgs = args.prepareRequestArgs(request, sites);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(updateDeviceModel(deviceModel))
      .then(() => dispatch(req.updateRequest(reqArgs)))
      .then(() => {
        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

export function updateECODeviceInfoACR(request, sites) {
  const reqArgs = args.prepareRequestArgs(request, sites);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(req.updateRequest(reqArgs))
      .then(() => {
        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

export function makeLabDecisionACR(decision, request) {
  const statusId = th.getLabDecisionStatus(decision);
  const url = `acr/requests/${request.id}/requesttests?statusid=${statusId}`;

  return {
    type: actions.MAKE_LAB_DECISION,
    payload: restClientGeneral.put(url)
  };
}

export const updateTestReportStatusACR = ({
  requestId,
  testId,
  reportSlot,
  reportStatus
}) => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(tests.changeReportStatus(testId, reportSlot, reportStatus, "acr"))
    .then(res => {
      const { documentstatusid, report } = res.data.data;

      dispatch({
        type: actions.UPDATE_REPORT_STATUS,
        payload: {
          slot: report.slot,
          status: documentstatusid,
          testId
        }
      });

      return res;
    })
    .then(() => dispatch(getDetailsACR(requestId)))
    .then(() => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
    })
    .catch(err => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

      return Promise.reject(err);
    });
};

export function downloadReportACR(document, request) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this

  // file name pattern: ACR<000000>_REPORT_<Slot>.<extension>
  // e.g. ACR000345_REPORT_3.pdf
  const fileName = `${request.tn}_REPORT_${slot}.${document.document.extension}`;
  const url = `/acr/requesttests/${test}/reports/${slot}?download=1`;

  return fileDownload(fileName, url, "general");
}

export function downloadAllReportsACR(request) {
  // file name: ACR<000000>_REPORTS.zip
  // e.g. ACR000345_REPORTS.zip
  const fileName = `${request.tn}_REPORTS.zip`;
  const url = `/acr/requests/${request.id}/reports?download=true`;

  return fileDownload(fileName, url, "general");
}
