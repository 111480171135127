import React from "react";
import { connect } from "react-redux";
import { Badge } from "ctia-react-bootstrap-v4";
import FormElement from "components/FormElement";

const BatteryDeviceTypeReadonly = ({ app, request }) => {
  if (!request || !request.id) return null;

  const { isAdapter, isPack } = request;
  if (!isPack && !isAdapter) return null;

  const DeviceBadges = () => (
    <>
      {isPack && (
        <Badge variant="ctia-blue" className="button-right-offset">
          Pack
        </Badge>
      )}
      {isAdapter && <Badge variant="ctia-green">Adapter</Badge>}
    </>
  );

  return (
    <FormElement
      id="batteryDeviceTypes"
      label="Device Type"
      value={<DeviceBadges />}
    />
  );
};

function mapStateToProps({ app, request }) {
  return { app, request };
}

export default connect(mapStateToProps)(BatteryDeviceTypeReadonly);
