import React from "react";
import RequestContentEPR from "containers/request/battery/RequestContent/RequestContentEPR";
import LabContentEPR from "containers/request/battery/RequestContent/RequestContentLabEPR";
import roles from "dictionaries/UserRoles";

const ContentBatteryEPR = ({ role, focusLab }) => {
  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.supplier:
    case roles.accountant:
    case roles.operator:
      return <RequestContentEPR focusLab={focusLab} />;
    case roles.lab:
      return <LabContentEPR />;
    default:
      return null;
  }
};

export default ContentBatteryEPR;
