// es6 Helpers
import { Form } from "ctia-react-bootstrap-v4";
import _ from "lodash";

// React / Redux
import React, { useState } from "react";
import { connect } from "react-redux";

// Redux Actions
import { checkUserNameExists, getUnusedUserName } from "actions/user";
import {
  setUserRequestFormValues,
  setUserRequestUserNameValid,
} from "actions/newUserRequest";

// Components and Containers
import FormFieldLabel from "components/form/FormFieldLabel";

const UserName = ({
  newUserRequest,
  checkUserNameExists,
  getUnusedUserName,
  setUserRequestFormValues,
  setUserRequestUserNameValid
}) => {
  const {
    formValues,
    userNameIsValid
  } = newUserRequest.selectedUserRequest;
  const {
    userName
  } = formValues;

  const [firstLoad, setFirstLoad] = useState(true);
  const [suggestedUserName, setSuggestedUserName] = useState("");
  const [userNameIsTaken, setUserNameIsTaken] = useState(false);

  const validateUserName = (userName, event) => {
    const invalidUserName = _.isUndefined(userName) || _.isNull(userName) ||
      _.isEmpty(userName) || userName.toLowerCase() === "undefined";

    if (firstLoad) {
      // Load the suggested user name into the store once the store is initialized
      const { firstName, lastName } = formValues;
      getUnusedUserName({ firstName, lastName })
      .then(userName => {
        setSuggestedUserName(userName);
        if (invalidUserName) {
          setUserRequestFormValues({ userName });
          setUserRequestUserNameValid(true);
        }
      })
      .catch(() => {});
    }

    if (invalidUserName) setUserRequestUserNameValid(false);
    else {
      checkUserNameExists({ userName })
      .then(userNameAlreadyExists => {
        setUserRequestUserNameValid(!userNameAlreadyExists);
        setUserNameIsTaken(userNameAlreadyExists);
      })
      .catch(() => {
        setUserRequestUserNameValid(false);
        setUserNameIsTaken(false);
      });
    }
  };

  const handleChange = event => {
    const userName = event.target.value;
    setUserRequestFormValues({ userName });
    validateUserName(userName, event);
  };

  if (firstLoad) {
    validateUserName(userName);
    setFirstLoad(false);
  }

  /**
   * Setup component and update parent component of state
   */
  let userNameValidationMessage;
  if (_.isNull(userName) || _.isEmpty(userName)) {
    userNameValidationMessage = "";
  } else if (userNameIsTaken) {
    userNameValidationMessage = `This user name is already taken; however, '${suggestedUserName}' is available.`;
  } else {
    userNameValidationMessage = "";
  }

  return (
    <Form.Group controlId="dispositionUserName">
      <Form.Label>
        <FormFieldLabel label="User Name" isRequired={true} />
      </Form.Label>
      <Form.Control
        required
        isInvalid={!userNameIsValid}
        onChange={handleChange}
        placeholder="Enter user name"
        value={userName}
      />
      <Form.Control.Feedback type="invalid">
        { userNameValidationMessage }
      </Form.Control.Feedback>
    </Form.Group>
  );
};

function mapStateToProps({ newUserRequest }) {
  return { newUserRequest };
}

export default connect(mapStateToProps, {
  checkUserNameExists,
  getUnusedUserName,
  setUserRequestFormValues,
  setUserRequestUserNameValid
})(UserName);