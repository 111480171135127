import React from "react";
import { connect } from "react-redux";
import SelectBatterySupplier from "containers/request/battery/BatterySupplier/SelectBatterySupplier";
import SelectBatteryModel from "containers/request/battery/BatterySupplier/SelectBatteryModel";
import SelectBatteryRevision from "containers/request/battery/BatterySupplier/SelectBatteryRevision";
import BatteryAddButton from "containers/request/battery/BatterySupplier/BatterySupplierAdd";
import NoDataMessage from "containers/request/battery/BatterySupplier/NoDataMessage";

const BatterySupplierSelector = ({ suppliers }) => {
  if (!suppliers) return null;
  const { approvedPackSuppliers } = suppliers;

  if (!suppliers || !approvedPackSuppliers || !approvedPackSuppliers.length) {
    return <NoDataMessage message="No battery suppliers available" />;
  }
  return (
    <div>
      <SelectBatterySupplier />
      <SelectBatteryModel />
      <SelectBatteryRevision />
      <BatteryAddButton />
    </div>
  );
};

function mapStateToProps({ suppliers }) {
  return { suppliers };
}

export default connect(mapStateToProps)(BatterySupplierSelector);
