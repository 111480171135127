import React, { Component } from "react";
import { connect } from "react-redux";
import ModalConfirm from "components/ModalConfirm";
import BatteryAllTableRow from "containers/request/RequestList/battery/BatteryAllTableRow";
import { Table } from "react-bootstrap";
import { needHideActions, getTransPrefix } from "helpers/RequestHelper";
import { clearRequests } from "actions/requests";
import { withdrawRequest, unwithdrawRequest } from "actions/requestCyber";

const actionColStyle = { width: 40 };

class BatteryAllTable extends Component {
  state = {
    showWithdrawConfirm: false,
    showUnwithdrawConfirm: false,
    requestData: null,
    isLoading: false,
    isFormValid: true
  };

  componentWillUnmount() {
    // this.props.clearRequests();
  }

  setWithdrawalType = requestData => {
    if (!requestData) return null;

    // if SAR
    if (requestData.manufsiteid > 0) return "withdrawReasonSAR";

    return "withdrawReason";
  };

  setUnwithdrawalType = requestData => {
    if (!requestData) return null;

    // if SAR
    if (requestData.manufsiteid > 0) return "unwithdrawSAR";

    return "unwithdraw";
  };

  onWithdraw = () => {
    // withdraw reason
    const reason = document.getElementById("reasonRef").value;

    const { id, formatedtransactionnumber: tn } = this.state.requestData;
    const prefix = getTransPrefix(tn);

    if (reason.length < 5) {
      this.setState({ isFormValid: false });
    } else {
      this.setState({ isLoading: true });
      this.props
        .withdrawRequest(id, reason, this.clearAndFetch, prefix)
        .then(this.onCloseConfirm);
    }
  };

  onUnwithdraw = () => {
    this.setState({ isLoading: true });

    const { id, formatedtransactionnumber: tn } = this.state.requestData;
    const prefix = getTransPrefix(tn);

    this.props
      .unwithdrawRequest(id, this.clearAndFetch, prefix)
      .then(this.onCloseConfirm);
  };

  openWithdrawConfirm = requestData => {
    this.setState({
      showWithdrawConfirm: true,
      showUnwithdrawConfirm: false,
      requestData,
      withdrawalType: this.setWithdrawalType(requestData),
      unwithdrawalType: this.setUnwithdrawalType(requestData)
    });
  };

  openUnwithdrawConfirm = requestData => {
    this.setState({
      showUnwithdrawConfirm: true,
      showWithdrawConfirm: false,
      requestData,
      withdrawalType: this.setWithdrawalType(requestData),
      unwithdrawalType: this.setUnwithdrawalType(requestData)
    });
  };

  clearAndFetch = () => {
    this.props.clearRequests();
    this.props.fetchHandler();
  };

  onCloseConfirm = () => {
    this.setState({
      showWithdrawConfirm: false,
      showUnwithdrawConfirm: false,
      requestData: null,
      isLoading: false
    });
  };

  onValidate = value => {
    this.setState({ isFormValid: value.length > 4 });
  };

  render() {
    const { user, data } = this.props;

    if (!data) return null;

    const renderActionIconsHeader = () => {
      if (!user.isStaff) return null;
      if (needHideActions(data)) return null;

      return <th style={actionColStyle} />;
    };

    const renderModelTitle = () => {
      let columnTitle = "Model/Site Name";

      if (user.isVendor) columnTitle = "Model Name/Number";

      return <th>{columnTitle}</th>;
    };

    return (
      <div>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              {renderActionIconsHeader()}
              <th>Request ID</th>
              {user.isStaff && <th>Vendor/Supplier</th>}
              {renderModelTitle()}
              <th>Request Type</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <BatteryAllTableRow
              tableData={data}
              isStaffUser={user.isStaff}
              isVendorUser={user.isVendor || user.isSupplier}
              openWithdrawConfirm={this.openWithdrawConfirm}
              openUnwithdrawConfirm={this.openUnwithdrawConfirm}
            />
          </tbody>
        </Table>
        <ModalConfirm
          type={this.state.withdrawalType}
          show={this.state.showWithdrawConfirm}
          onHide={this.onCloseConfirm}
          onHandle={this.onWithdraw}
          requestData={this.state.requestData}
          isLoading={this.state.isLoading}
          isFormValid={this.state.isFormValid}
          onValidate={this.onValidate}
        />
        <ModalConfirm
          type={this.state.unwithdrawalType}
          show={this.state.showUnwithdrawConfirm}
          onHide={this.onCloseConfirm}
          onHandle={this.onUnwithdraw}
          requestData={this.state.requestData}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

function mapStateToProps({ user, app }) {
  return { user, app };
}

export default connect(mapStateToProps, {
  clearRequests,
  withdrawRequest,
  unwithdrawRequest
})(BatteryAllTable);
