import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { fetchApproveWaitingRequests } from 'actions/requests';

class ApproveCount extends Component {
  componentDidMount() {
    this.props.fetchApproveWaitingRequests();
  }

  componentWillReceiveProps(nextProps) {
    const { badges } = this.props;
    const updatingApproveCount = nextProps.badges.updatingApproveCount;

    if (
      updatingApproveCount === true ||
      (badges && updatingApproveCount !== badges.updatingApproveCount)
    ) {
      this.props.fetchApproveWaitingRequests();
    }
  }

  render() {
    const { badges } = this.props;
    if (!badges || !badges.approveCount) return null;
    return <Badge className="icon-left-offset">{badges.approveCount}</Badge>;
  }
}

function mapStateToProps({ badges }) {
  return { badges };
}

export default connect(
  mapStateToProps,
  { fetchApproveWaitingRequests }
)(ApproveCount);
