import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import VendorContent from "containers/request/RequestContent/VendorContent";
import reqTypes from "dictionaries/ReqTypes";
import {
  getVendorInitialTabsDHR,
  getVendorEcoTabsDHR
} from "helpers/RequestTabsHelper";
import { getChecklistDHR } from "helpers/ChecklistHelper";

const VendorRequestContentDHR = ({ hideBillingPay = false, request, focusLab }) => {
  const checkList = getChecklistDHR(request);

  switch (request.type) {
    case reqTypes.initial:
      const tabsInitial = getVendorInitialTabsDHR(request);
      const tabsDHRInitial = hideBillingPay
        ? _.filter(tabsInitial, tab => {
            return tab.key !== "billing";
          })
        : tabsInitial;

      return (
        <VendorContent
          tabs={tabsDHRInitial}
          checkList={checkList}
          defaultTab={focusLab ? "labInfo" : "deviceInfo"}
        />
      );
    case reqTypes.eco:
      const tabsECO = getVendorEcoTabsDHR(request);
      const tabsDHRECO = hideBillingPay
        ? _.filter(tabsECO, tab => {
            return tab.key !== "billing";
          })
        : tabsECO;

      return (
        <VendorContent
          tabs={tabsDHRECO}
          checkList={checkList}
          defaultTab={focusLab ? "labInfo" : "deviceInfo"}
        />
      );
    default:
      return null;
  }
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(VendorRequestContentDHR);
