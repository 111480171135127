import React from "react";
import { connect } from "react-redux";
import PTCRBLogoCanvas from "components/Canvas/PTCRBLogoCanvas";

const PTCRBLogo = ({ user, atlCode }) => {
  const facilityCode = user && user.isLab && user.CATLCode ? user.CATLCode : atlCode;

  if (facilityCode) {
    return <PTCRBLogoCanvas labCode={facilityCode} />;
  }

  return null;
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(PTCRBLogo);