// React/Redux imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Redux actions imports
import { fetchModelVersionsForProgram } from "actions/model";

/// Component and Container imports
import ModelVersionsCyber from "containers/Devices/Cyber/ModelVersionsCyber";
import { InlinePreloader } from "ctia-ui";

const CyberDeviceHistory = ({
  modelId,
  requestStatusId,
  fetchModelVersionsForProgram,
  model
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchModelVersionsForProgram("cyber", modelId, requestStatusId).then(() =>
      setLoading(false)
    );
  }, [fetchModelVersionsForProgram, modelId, requestStatusId]);

  if (loading) return <InlinePreloader />;

  if (!model || !model.versions) return <span>No Information</span>;

  return (
    <ModelVersionsCyber
      tableData={model.versions}
      showLink={true}
      show={true}
    />
  );
};

function mapStateToProps({ model }) {
  return { model };
}

export default connect(mapStateToProps, {
  fetchModelVersionsForProgram
})(CyberDeviceHistory);
