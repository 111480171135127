import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import {
  getActiveLabUsers,
  fetchActiveLabs,
  selectLabUser,
  fetchUserLabs,
  setTransferFormVisability,
  unmountView
} from "actions/users2labs";
import "antd/dist/antd.css";

const { Option } = Select;

const SelectUser = ({
  users2labs,
  getActiveLabUsers,
  fetchActiveLabs,
  selectLabUser,
  fetchUserLabs,
  setTransferFormVisability,
  unmountView
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // users list for selector
    getActiveLabUsers().then(() => setLoading(false));

    // in background we also need to get labs list
    fetchActiveLabs();

    // onUnmount implementation
    return () => {
      unmountView();
    };
  }, [getActiveLabUsers, fetchActiveLabs, setLoading, unmountView]);

  const onChange = value => {
    // hide transfer form
    setTransferFormVisability(false);

    // set value on redux for other components
    selectLabUser(value);

    // fetch assigned labs per selected user from server
    fetchUserLabs(value).then(() => setTransferFormVisability(true));
  };

  const options =
    users2labs && users2labs.activeUsers ? users2labs.activeUsers : [];

  return (
    <Select
      showSearch
      style={{ width: 250 }}
      placeholder="Select a user OR search by name"
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {options.map(user => {
        return (
          <Option key={user.id} value={user.ctiaid}>
            {user.reversedfullname}
          </Option>
        );
      })}
    </Select>
  );
};

function mapStateToProps({ users2labs }) {
  return { users2labs };
}

export default connect(mapStateToProps, {
  getActiveLabUsers,
  fetchActiveLabs,
  selectLabUser,
  fetchUserLabs,
  setTransferFormVisability,
  unmountView
})(SelectUser);
