import React from "react";
import Billing from "containers/request/Initial/Steps/Vendor/Billing";
import PaymentInfo from "containers/request/Initial/PaymentInfo";

const BillPay = () => {
  return (
    <div>
      <Billing />
      <div className="block-top-offset">
        <PaymentInfo />
      </div>
    </div>
  );
};

export default BillPay;
