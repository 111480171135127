import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";
import { LoaderButton, IconLabel, InlinePreloader } from "ctia-ui";
import { Col, Row, Modal, Button } from "react-bootstrap";
import moment from "moment";
import { validateOrgFormASC } from "helpers/ServiceCenterHelper";
import { scrollTo } from "helpers/AppHelper";
import { formatDate } from "helpers/DateHelper";
import DatePicker from "components/form/Date";
import SelectCountry from "containers/ControlPanel/Orgs/ServiceCenter/SelectCountry";
import SelectState from "containers/ControlPanel/Orgs/ServiceCenter/SelectState";
import FormFieldLabel from "components/form/FormFieldLabel";
import StateStaticReadonly from "components/form/StateStaticReadonly";
import AscLogo from "components/AscLogo";
import AscLogoPreview from "components/ProgramSelector/ServiceCenter/AscLogoPreview";
import { clearCountryState } from "actions/countries";
import { getOrganization } from "actions/org";
import { fetchCompanyUsers } from "actions/user";
import { updateCompanyASC } from "actions/asc";

import "containers/ControlPanel/Payments/payments.css";

const formId = "edit-center-form";

const EditServiceCenterModal = ({
  id,
  modalHandler,
  countries,
  clearCountryState,
  getOrganization,
  fetchCompanyUsers,
  updateCompanyASC,
  users
}) => {
  const [authDate, setAuthDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (!data)
      getOrganization(id)
        .then(res => {
          if (res && res.data) {
            const data = res.data.data;
            setData(data);

            const authDate = data.asclicensedate
              ? moment(data.asclicensedate)
              : moment();

            setAuthDate(authDate);

            setStatus(data.ascstatus);

            // force validation since initially we set form as invalid
            // @NOTE: using setTimeout to allow the form renders first
            setTimeout(() => validateForm(), 500);
          } else {
            throw new Error("Server responded with an error");
          }
        })
        .then(() => fetchCompanyUsers(id));
  }, [id, data, getOrganization, fetchCompanyUsers]);

  const renderSubmitButton = () => {
    return (
      <span className="icon-left-offset">
        <LoaderButton
          bsStyle="success"
          bsSize="small"
          text={<IconLabel label="Save" awesomeIcon="check" />}
          onClick={() => {
            setLoading(true);

            updateCompanyASC(formId, data.id)
              .then(() => {
                setLoading(false);
                modalHandler();
                clearCountryState();
              })
              .catch(err => {
                setLoading(false);
                modalHandler(err);
                scrollTo(formId);
              });
          }}
          disabled={!validated}
          isLoading={loading}
        />
      </span>
    );
  };

  const validateForm = () => {
    const form = document.getElementById(formId);

    if (form.checkValidity() === false || !validateOrgFormASC(form)) {
      return setValidated(false);
    }

    setValidated(true);
  };

  const showStateSelector =
    countries &&
    (countries.selectedCountry === "US" || countries.selectedCountry === "CA");
  const showContact =
    users &&
    data &&
    users.list &&
    _.find(users.list, {
      ctiaid: data.contactid
    });

  const showAuthDateSelector = Boolean(status && status === "Active");
  const showDisableReason = Boolean(data && !data.isActive);
  const renderAuthDateReadonly = () => {
    if (!data || data.asclicensedate === "") return null;

    return (
      <Form.Group controlId="authDate">
        <Form.Label>
          <FormFieldLabel label="ASC Authorization Date" isRequired={false} />
        </Form.Label>
        <p className="dark-teal bold form-control-static">
          {formatDate(data.asclicensedate)}
        </p>
      </Form.Group>
    );
  };

  return (
    <Modal show={true} className="ctia-modal">
      <Modal.Header closeButton onHide={modalHandler}>
        <Modal.Title>Edit Service Center</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data ? (
          <Form
            noValidate
            validated={validated}
            id={formId}
            className="ctia-form"
            onChange={validateForm}
          >
            <Form.Group controlId="orgName">
              <Form.Label>
                <FormFieldLabel label="Name" isRequired={true} />
              </Form.Label>
              <Form.Control
                required
                placeholder="Enter organization name"
                defaultValue={data.name}
              />
              <Form.Control.Feedback type="invalid">
                Please choose an organization name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="address1">
              <Form.Label>
                <FormFieldLabel label="Address 1" isRequired={true} />
              </Form.Label>
              <Form.Control
                required
                placeholder="Enter Address line 1"
                defaultValue={data.address1}
              />
            </Form.Group>
            <Form.Group controlId="address2">
              <Form.Label>
                <FormFieldLabel label="Address 2" />
              </Form.Label>
              <Form.Control
                placeholder="Enter Address line 2"
                defaultValue={data.address2}
              />
            </Form.Group>
            <Form.Group controlId="address3">
              <Form.Label>
                <FormFieldLabel label="Address 3" />
              </Form.Label>
              <Form.Control
                placeholder="Enter Address line 3"
                defaultValue={data.address3}
              />
            </Form.Group>
            <Form.Group controlId="country">
              <Form.Label>
                <FormFieldLabel label="Country" isRequired={true} />
              </Form.Label>
              <SelectCountry defaultValue={data.country} />
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Label>
                <FormFieldLabel label="City" isRequired={true} />
              </Form.Label>
              <Form.Control
                required
                placeholder="Enter City"
                defaultValue={data.citydisplay}
              />
            </Form.Group>
            <Form.Group controlId="state">
              <Form.Label>
                <FormFieldLabel label="State/Province" isRequired={true} />
              </Form.Label>
              {showStateSelector ? (
                <SelectState defaultValue={data.state} />
              ) : (
                <StateStaticReadonly />
              )}
            </Form.Group>
            <Form.Group controlId="zip">
              <Form.Label>
                <FormFieldLabel label="Zip/Postal Code" isRequired={true} />
              </Form.Label>
              <Form.Control
                required
                placeholder="Enter Zip or Postal Code"
                defaultValue={data.zipcode}
              />
            </Form.Group>
            {showContact && (
              <Form.Group controlId="contactid">
                <Form.Label>
                  <FormFieldLabel label="Contact" />
                </Form.Label>
                <Form.Control as="select" defaultValue={data.contactid}>
                  {users &&
                    users.list &&
                    users.list.map(user => {
                      return (
                        <option
                          key={user.ctiaid}
                          value={user.ctiaid}
                          // selected={user.ctiaid === data.contactid}
                          data-name={user.fullname}
                          data-email={user.email}
                        >
                          {user.fullname}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            )}
            <hr />
            <Form.Group controlId="status">
              <Form.Label>
                <FormFieldLabel label="ASC Status" isRequired={true} />
              </Form.Label>
              <Form.Control
                as="select"
                defaultValue={data.ascstatus}
                onChange={e => setStatus(e.target.value)}
              >
                <option value="none">--- Select One ---</option>
                <option>Active</option>
                <option>Expired</option>
                <option>N/A</option>
              </Form.Control>
            </Form.Group>
            {showAuthDateSelector && (
              <Form.Group controlId="authDate">
                <Form.Label>
                  <FormFieldLabel
                    label="ASC Authorization Date"
                    isRequired={showAuthDateSelector}
                  />
                </Form.Label>
                <DatePicker
                  value={authDate}
                  editable={true}
                  onChange={momentDate => setAuthDate(momentDate)}
                />
                <input
                  type="hidden"
                  id="authDate"
                  value={formatDate(authDate)}
                />
              </Form.Group>
            )}
            {!showAuthDateSelector && renderAuthDateReadonly()}
            <Form.Group controlId="level">
              <Form.Label>
                <FormFieldLabel label="Authorized Level" isRequired={true} />
              </Form.Label>
              <Form.Control as="select" defaultValue={data.authorizationlevel}>
                <option value="none">--- Select One ---</option>
                <option value="1">Level 1</option>
                <option value="2">Level 2</option>
                <option value="3">Level 2 - Chromebook Repair</option>
              </Form.Control>
            </Form.Group>
            {data && data.ASCCode && (
              <>
                <hr />
                <Row>
                  <Col md={6}>
                    <AscLogo ascCode={data.ASCCode} />
                  </Col>
                  <Col md={6}>
                    <ul className="ctia-list">
                      <AscLogoPreview companyId={data.id} />
                    </ul>
                  </Col>
                </Row>
              </>
            )}
            {showDisableReason && (
              <>
                <hr />
                <Form.Group controlId="disableReason">
                  <Form.Label>Reason for Disabling</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter the reason for disabling"
                    defaultValue={data.comments}
                    style={{ height: 75 }}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        ) : (
          <InlinePreloader />
        )}
      </Modal.Body>
      <Modal.Footer>
        {renderSubmitButton()}
        <Button onClick={modalHandler} className="agr-close-btn" bsSize="small">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps({ countries, users }) {
  return { countries, users };
}

export default connect(mapStateToProps, {
  clearCountryState,
  getOrganization,
  fetchCompanyUsers,
  updateCompanyASC
})(EditServiceCenterModal);
