// React/Redux imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { InlinePreloader } from "ctia-ui";

// Redux actions imports
import { fetchModelVersionsForProgram } from "actions/model";

/// Component and Container imports
import ModelVersionsBLC from "containers/Devices/BLC/ModelVersionsBLC";

const BLCDeviceHistory = ({
  modelId,
  requestStatusId,
  fetchModelVersionsForProgram,
  model
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchModelVersionsForProgram("blc", modelId, requestStatusId).then(() =>
      setLoading(false)
    );
  }, [fetchModelVersionsForProgram, modelId, requestStatusId]);

  if (loading) return <InlinePreloader />;

  if (!model || !model.versions) return <span>No Information</span>;

  return (
    <ModelVersionsBLC tableData={model.versions} showLink={true} show={true} />
  );
};

function mapStateToProps({ model }) {
  return { model };
}

export default connect(mapStateToProps, {
  fetchModelVersionsForProgram
})(BLCDeviceHistory);
