import { Component } from 'react';
import { connect } from 'react-redux';
import { resizeWindow } from 'actions/app';

class AppWindow extends Component {
  updateCallback = () => {
    this.props.resizeWindow();
  };

  componentDidMount() {
    this.updateCallback();
    window.addEventListener('resize', this.updateCallback);
  }

  render() {
    return null;
  }
}

export default connect(
  null,
  { resizeWindow }
)(AppWindow);
