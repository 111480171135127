import reqTypes from "dictionaries/ReqTypes";
// import { batteryRequestTypes } from "helpers/BatteryCertHelper";
import batteryRequestTypes from "dictionaries/BatteryRequestTypes";

// general components
import PointOfContact from "containers/request/Initial/Steps/Vendor/PointOfContact";
import Billing from "containers/request/Initial/Steps/Vendor/Billing";
// import Dummy from 'containers/request/Dummy';
// import SystemHost from "containers/request/SystemHost/SystemHostContainer";

// ECO related components
import SelectModel from "containers/request/ECO/SelectModel";
import ModelInfo from "containers/request/ECO/ModelInfo";
import LabInfoECO from "containers/request/ECO/LabInfo";
import DocumentsECO from "containers/request/ECO/Documents";

// Initial related components
import DeviceInfo from "containers/request/Initial/Steps/Vendor/DeviceInfo/index";
import LabInfo from "containers/request/Initial/Steps/Vendor/LabInfo";
import Operators from "containers/request/Initial/Steps/Vendor/Operators";
import LicenseAgreement from "containers/request/LicenseAgreement/index";
import Documents from "containers/request/Initial/Steps/Vendor/Documents";

// Battery related components
// @TODO: use at BLC, remove from here
// import BatterySupplier from "containers/request/battery/BatterySupplier/BatterySupplierContainer";
// @TODO: use at BLC, remove from here
// import AdapterSupplier from "containers/request/battery/AdapterSupplier/AdapterSupplierContainer";
import BatteryAgreements from "containers/request/LicenseAgreement/BatteryAgreement/BatteryAgreementContainer";
import ManufSite from "containers/request/battery/ManufSite/ManufSiteContainer";
import SiteRenewal from "containers/request/battery/ManufSite/SiteRenewalContainer";
import AuditLabInfo from "containers/request/battery/AuditLabInfo/index";
import RequestComment from "containers/request/battery/RequestComment";

// OTA related components
import OTADeviceInfo from "containers/request/OTA/OTAContainer";
import OTATestPlans from "containers/request/OTA/TestPlans/OTATestPlans";

// DHR related components
import DeviceInfoDHR from "containers/request/DHR/DHRContainer";
import DeviceInfoDHR_ECO from "containers/request/DHR/DeviceInfoECO";
import LabInfoDHR from "containers/request/DHR/LabInfoDHR";
import LicenseAgreementDHR from "containers/request/LicenseAgreement/DHR/LicenseAgreementDHR";
import DocumentsDHR from "containers/request/DHR/DocumentsECO";

// CRR related components
import DeviceInfoCRR from "containers/request/CRR/RequestContainerCRR";
import DeviceInfoCRR_ECO from "containers/request/CRR/RequestECOContainerCRR";
import LabInfoCRR from "containers/request/CRR/LabInfoCRR";

// HRR related components
import DeviceInfoHRR from "containers/request/HRR/RequestContainerHRR";
import DeviceInfoHRR_ECO from "containers/request/HRR/RequestECOContainerHRR";
import LabInfoHRR from "containers/request/HRR/LabInfoHRR";

// PRR related components
import DeviceInfoPRR from "containers/request/PRR/RequestContainerPRR";
import DeviceInfoPRR_ECO from "containers/request/PRR/RequestECOContainerPRR";
import LabInfoPRR from "containers/request/PRR/LabInfoPRR";

// EPRR related components
import DeviceInfoEPR from "containers/request/EPRR/RequestContainerEPR";
import DeviceInfoEPR_ECO from "containers/request/EPRR/RequestECOContainerEPR";
import LabInfoEPR from "containers/request/EPRR/LabInfoEPR";

// ARR related components
import DeviceInfoARR from "containers/request/ARR/RequestContainerARR";
import DeviceInfoARR_ECO from "containers/request/ARR/RequestECOContainerARR";
import LabInfoARR from "containers/request/ARR/LabInfoARR";

// ACR related components
import DeviceInfoACR from "containers/request/ACR/RequestContainerACR";
import DeviceInfoACR_ECO from "containers/request/ACR/RequestECOContainerACR";
import LabInfoACR from "containers/request/ACR/LabInfoACR";

// BLC related components
import DeviceInfoBLC from "containers/request/BLC/RequestContainerBLC";
import DeviceInfoBLC_ECO from "containers/request/BLC/RequestECOContainerBLC";
import LabInfoBLC from "containers/request/BLC/LabInfoBLC";
import DocumentsBLC from "containers/request/BLC/DocumentsBLC";

// BSC related components
import DeviceInfoBSC from "containers/request/BSC/RequestContainerBSC";
import DeviceInfoBSC_ECO from "containers/request/BSC/RequestECOContainerBSC";
import LabInfoBSC from "containers/request/BSC/LabInfoBSC";
import LicenseAgreementBLC from "containers/request/LicenseAgreement/BLC/LicenseAgreementBLC";

// wizard submission tabs for Cyber program:
export const cyberTabs = {
  initial: {
    1: { tab: DeviceInfo, header: "Device Info" },
    2: { tab: PointOfContact, header: "PoC" },
    3: { tab: Billing, header: "Billing" },
    4: { tab: LabInfo, header: "Lab Info" },
    5: { tab: Operators, header: "Operators" },
    6: { tab: Documents, header: "Documents" },
    7: { tab: LicenseAgreement, header: "License Agreement" }
  },
  eco: {
    1: { tab: SelectModel, header: "Select Model" },
    2: { tab: ModelInfo, header: "Model Info" },
    3: { tab: PointOfContact, header: "PoC" },
    4: { tab: Billing, header: "Billing" },
    5: { tab: LabInfoECO, header: "Lab Info" },
    6: { tab: DocumentsECO, header: "Documents" }
  }
};

// wizard submission tabs for Battery program:
export const batteryTabs = {
  // SAR
  [batteryRequestTypes.siteAuditRequest]: {
    [reqTypes.initial]: {
      1: { tab: ManufSite, header: "Manufacturing Site" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: AuditLabInfo, header: "Lab Info" },
      4: { tab: RequestComment, header: "Comments" }
    },
    [reqTypes.renewal]: {
      1: { tab: SiteRenewal, header: "Manufacturing Site" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: AuditLabInfo, header: "Lab Info" },
      4: { tab: RequestComment, header: "Comments" }
    }
  },
  // CRR
  [batteryRequestTypes.cellRecognitionRequest]: {
    [reqTypes.initial]: {
      1: { tab: DeviceInfoCRR, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: Billing, header: "Billing" },
      4: { tab: LabInfoCRR, header: "Lab Info" },
      5: { tab: BatteryAgreements, header: "Documents" }
    },
    [reqTypes.eco]: {
      1: { tab: DeviceInfoCRR_ECO, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: LabInfoCRR, header: "Lab Info" }
    }
  },
  // HRR
  [batteryRequestTypes.hybridRecognitionRequest]: {
    [reqTypes.initial]: {
      1: { tab: DeviceInfoHRR, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: Billing, header: "Billing" },
      4: { tab: LabInfoHRR, header: "Lab Info" },
      5: { tab: BatteryAgreements, header: "Documents" }
    },
    [reqTypes.eco]: {
      1: { tab: DeviceInfoHRR_ECO, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: LabInfoHRR, header: "Lab Info" }
    }
  },
  // PRR
  [batteryRequestTypes.packRecognitionRequest]: {
    [reqTypes.initial]: {
      1: { tab: DeviceInfoPRR, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: Billing, header: "Billing" },
      4: { tab: LabInfoPRR, header: "Lab Info" },
      5: { tab: BatteryAgreements, header: "Documents" }
    },
    [reqTypes.eco]: {
      1: { tab: DeviceInfoPRR_ECO, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: LabInfoPRR, header: "Lab Info" }
    }
  },
  // BSC
  [batteryRequestTypes.batterySystem]: {
    [reqTypes.initial]: {
      1: { tab: DeviceInfoBSC, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: LabInfoBSC, header: "Lab Info" },
      4: { tab: Operators, header: "Operators" },
      5: { tab: BatteryAgreements, header: "Documents" }
    },
    [reqTypes.eco]: {
      1: { tab: DeviceInfoBSC_ECO, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: LabInfoBSC, header: "Lab Info" }
    }
  },
  // EPR
  [batteryRequestTypes.embeddedPackRecognition]: {
    [reqTypes.initial]: {
      1: { tab: DeviceInfoEPR, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: Billing, header: "Billing" },
      4: { tab: LabInfoEPR, header: "Lab Info" },
      5: { tab: BatteryAgreements, header: "Documents" }
    },
    [reqTypes.eco]: {
      1: { tab: DeviceInfoEPR_ECO, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: LabInfoEPR, header: "Lab Info" }
    }
  },
  // ARR
  [batteryRequestTypes.adapterRecognitionRequest]: {
    [reqTypes.initial]: {
      1: { tab: DeviceInfoARR, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: Billing, header: "Billing" },
      4: { tab: LabInfoARR, header: "Lab Info" },
      5: { tab: BatteryAgreements, header: "Documents" }
    },
    [reqTypes.eco]: {
      1: { tab: DeviceInfoARR_ECO, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: LabInfoARR, header: "Lab Info" }
    }
  },
  // ACR
  [batteryRequestTypes.adapterCertificationRequest]: {
    [reqTypes.initial]: {
      1: { tab: DeviceInfoACR, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: Billing, header: "Billing" },
      4: { tab: LabInfoARR, header: "Lab Info" },
      5: { tab: BatteryAgreements, header: "Documents" }
    },
    [reqTypes.eco]: {
      1: { tab: DeviceInfoACR_ECO, header: "Device Info" },
      2: { tab: PointOfContact, header: "PoC" },
      3: { tab: LabInfoACR, header: "Lab Info" }
    }
  }
};

// wizard submission tabs for Battery Life Certification (BLC):
export const blcTabs = {
  // BLC
  initial: {
    1: { tab: DeviceInfoBLC, header: "Device Info" },
    2: { tab: PointOfContact, header: "POC" },
    3: { tab: Billing, header: "Billing" },
    4: { tab: LabInfoBLC, header: "Lab Info" },
    5: { tab: DocumentsECO, header: "Documents" },
    6: { tab: LicenseAgreementBLC, header: "License Agreement" }
  },
  eco: {
    1: { tab: DeviceInfoBLC_ECO, header: "Device Info" },
    2: { tab: PointOfContact, header: "POC" },
    3: { tab: LabInfoBLC, header: "Lab Info" },
    4: { tab: DocumentsBLC, header: "Documents" }
  }
};

// wizard submission tabs for OTA (previously NCT):
export const otaTabs = {
  1: { tab: OTADeviceInfo, header: "Device Info" },
  2: { tab: OTATestPlans, header: "Test Plans" },
  3: { tab: PointOfContact, header: "PoC" },
  4: { tab: Billing, header: "Billing" }
};

// wizard submission tabs for Hardware Reliability program (DHR):
export const dhrTabs = {
  initial: {
    1: { tab: DeviceInfoDHR, header: "Device Info" },
    2: { tab: PointOfContact, header: "PoC" },
    3: { tab: Billing, header: "Billing" },
    4: { tab: LabInfoDHR, header: "Lab Info" },
    5: { tab: Operators, header: "Operators" },
    6: { tab: DocumentsECO, header: "Documents" },
    7: { tab: LicenseAgreementDHR, header: "License Agreement" }
  },
  eco: {
    1: { tab: DeviceInfoDHR_ECO, header: "Device Info" },
    2: { tab: PointOfContact, header: "PoC" },
    3: { tab: LabInfoDHR, header: "Lab Info" },
    4: { tab: DocumentsDHR, header: "Documents" }
  }
};
