import _ from "lodash";
import { matchPath } from "react-router-dom";
import { nav } from "dictionaries/Navigation";

const routes = {
  [nav.pending.id]: [
    "/cyber/vendor/requests",
    "/cyber/vendor/requests/:id",
    "/cyber/admin/requests",
    "/cyber/admin/requests/:id",
    "/cyber/operator/requests/:id",
    "/cyber/accountant/requests/:id",
    "/ota/vendor/requests",
    "/ota/vendor/requests/:id",
    "/ota/admin/requests",
    "/ota/admin/requests/:id",
    "/battery/vendor/requests",
    "/battery/vendor/requests/:type/:id",
    "/battery/supplier/requests",
    "/battery/supplier/requests/:type/:id",
    "/battery/admin/requests",
    "/battery/admin/requests/:type/:id",
    "/battery/accountant/requests/:cert/:id",
    "/dhr/vendor/requests",
    "/dhr/vendor/requests/:id",
    "/dhr/admin/requests",
    "/dhr/admin/requests/:id",
    "/dhr/operator/requests/:id",
    "/dhr/accountant/requests/:id",
    "/blc/vendor/requests",
    "/blc/vendor/requests/:id",
    "/blc/admin/requests",
    "/blc/admin/requests/:id",
    "/blc/operator/requests/:id",
    "/blc/accountant/requests/:id"
  ],
  [nav.submitNew.id]: [
    "/cyber/vendor/request/new",
    "/cyber/vendor/request/new/initial",
    "/cyber/vendor/request/new/eco",
    "/battery/vendor/request/new",
    "/battery/vendor/request/new/:type",
    "/battery/supplier/request/new",
    "/battery/supplier/request/new/:type",
    "/ota/vendor/request/new",
    "/dhr/vendor/request/new",
    "/dhr/vendor/request/new/initial",
    "/dhr/vendor/request/new/eco",
    "/blc/vendor/request/new",
    "/blc/vendor/request/new/initial",
    "/blc/vendor/request/new/eco"
  ],
  [nav.certified.id]: [
    "/cyber/devices",
    "/cyber/devices/:id",
    "/cyber/certificate/:id",
    "/ota/devices",
    "/ota/devices/:id",
    "/dhr/devices",
    "/dhr/devices/:id",
    "/battery/devices",
    "/battery/devices/:id",
    "/blc/devices",
    "/blc/devices/:id"
  ],
  [nav.viewAuthorizedSite.id]: ["/battery/devices/audit/:id"],
  [nav.viewRecognizedSubsystems.id]: ["/battery/devices/model/:id"],
  [nav.viewBatterySubsystems.id]: ["/battery/devices", "/battery/devices/:id"],
  [nav.labs.id]: [
    "/cyber/labs",
    "/ota/labs",
    "/dhr/labs",
    "/battery/labs",
    "/blc/labs"
  ],
  [nav.forTesting.id]: [
    "/cyber/lab/requests",
    "/cyber/lab/requests/:id",
    "/ota/lab/requests",
    "/ota/lab/requests/:id",
    "/battery/lab/requests",
    "/battery/lab/requests/:type/:id",
    "/dhr/lab/requests",
    "/dhr/lab/requests/:id",
    "/blc/lab/requests",
    "/blc/lab/requests/:id"
  ],
  [nav.labReports.id]: [
    "/cyber/lab/results",
    "/cyber/lab/results/:id",
    "/ota/lab/results",
    "/ota/lab/results/:id",
    "/battery/lab/results",
    "/battery/lab/results/:type/:id",
    "/dhr/lab/results",
    "/dhr/lab/results/:id",
    "/blc/lab/results",
    "/blc/lab/results/:id"
  ],
  [nav.approve.id]: [
    "/cyber/admin/approval",
    "/ota/admin/approval",
    "/battery/admin/approval",
    "/dhr/admin/approval",
    "/blc/admin/approval"
  ],
  [nav.payments.id]: [
    "/cyber/accountant/payments",
    "/cyber/accountant/payments/:id/add",
    "/ota/accountant/payments",
    "/ota/accountant/payments/:id/add",
    "/dhr/accountant/payments",
    "/dhr/accountant/payments/:id/add",
    "/battery/accountant/payments",
    "/battery/accountant/payments/:cert/:id/add",
    "/blc/accountant/payments",
    "/blc/accountant/payments/:id/add"
  ],
  [nav.admin.id]: [
    "/admin/cp",
    "/admin/cp/users",
    "/admin/cp/users/search",
    "/admin/cp/users/newuserrequests",
    "/admin/cp/users/newuserrequests/:id",
    "/admin/cp/users/users2labs",
    "/admin/cp/users/users-of-labs",
    "/admin/cp/bands",
    "/admin/cp/reports",
    "/admin/cp/reports/certifiedpending",
    "/admin/cp/payments/invoice/lab",
    "/admin/cp/organizations/:type"
  ],
  [nav.accountingReports.id]: [
    "/accountant/reports",
    "/accountant/reports/:type"
  ],
  [nav.accountingATL.id]: ["/accountant/atl"],
  [nav.accountingASC.id]: ["/accountant/asc"]
};

const matchMyPath = (urlPath, pathPattern) =>
  matchPath(urlPath, { path: pathPattern, exact: true });

export const getCurrentLocation = path => {
  let locTitle = "Unknown";

  for (const route in routes) {
    if (_.some(routes[route], routePath => matchMyPath(path, routePath))) {
      const matchedRoute = _.find(nav, ["id", route]);
      locTitle = matchedRoute.title;
      // console.log(`Found match for path: ${path} with route key: ${route}`);
      // console.log(_.find(nav, ["id", route]).title);
      break;
    }
  }

  return locTitle;
};
