import _ from "lodash";
import {
  fillUploadedDocsArray,
  fillUploadedDocsArrayBLC,
  supportDocsCount,
  fillReportsArray,
  fillReportsArrayBLC,
  testReportsCount
} from "helpers/UploadsHelper";
import {
  isApproved,
  isSaved,
  getRequestStatuses,
  isEco,
  isInitial,
  getRequestTypeTitle,
  hasUsersManualApproved,
  getTransPrefix,
  isLegacy,
  isRenewal,
  formatOpSysList
} from "helpers/RequestHelper";
import { sortPocs } from "helpers/SortHelper";
import { formatWirelessList, getWirelessToggled } from "helpers/Wireless";
import { getFullName } from "helpers/UsersHelper";
import { formatBillingAddress } from "helpers/BillingHelper";
import { isComplete, testStatusIds } from "helpers/RequestTestsHelper";
import {
  setCertGroups,
  getCertGroupsAsString,
  verifyWireless,
  checkCertRequirements,
  getCertGroupsToggled
} from "helpers/VerifyCertification";
import {
  requestFields,
  setAsSelectedByDefault,
  isPack,
  isAdapter,
  setCellSuppliers
} from "helpers/BatterySupplierHelper";
import { requestFields as adapterFields } from "helpers/AdapterSupplierHelper";
import { requestFields as otaFields } from "helpers/OTATestPlanHelper";
import { mapLinkedAudits } from "helpers/AuditHelper";
import { selectNonSarSitesInitialOrEco } from "helpers/ManufSiteHelper";
import { formatOperatorsList } from "helpers/Operators";
import * as actions from "actions/types";
import * as ls from "libs/localStorage";
import types from "dictionaries/ReqTypes";
import { approved } from "helpers/DocumentStatus";

const fetchRequest = (action, state) => {
  const reqData = action.payload;
  const { devicemodel, poc, submitter, requeststatusid, billing, invoice } =
    reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const {
    lte,
    gcfcertified,
    ptcrbcertified,
    legacydevicemodelid,
    wifialliancecertified,
    devicemodelwirelesscapabilities
  } = reqData.devicemodel;

  const wireless = formatWirelessList(devicemodelwirelesscapabilities, lte);
  const wirelessToggled = getWirelessToggled(devicemodelwirelesscapabilities);
  const hasLteSupport = _.find(wireless, { id: 1 }).checked;
  const hasWifiSupport = _.find(wireless, { id: 2 }).checked;

  // const deviceModel = reqData.devicemodel;
  const verifiedPtcrb = ptcrbcertified === 1 || legacydevicemodelid > 0;
  const verifiedGcf = gcfcertified === 1;
  const verifiedWifi = wifialliancecertified === 1;

  const certGroups = setCertGroups({
    hasLteSupport,
    hasWifiSupport,
    verifiedPtcrb,
    verifiedGcf,
    verifiedWifi
  });

  const manualApproved =
    state && state.usersManual
      ? hasUsersManualApproved(state.usersManual)
      : false;
  const reqType = reqData.requesttypeid;

  return {
    ...state,
    ...reqStatuses,
    agreementAccepted: !isSaved(requeststatusid),
    billingId: reqData.billingid || 0,
    billingPocId: billing.pocid || 0,
    billingAddressId: billing.addressid || 0,
    billingOrderNum: reqData.purchaseorder,
    billingPocName: getFullName(billing.poc),
    billingPocPhone: billing.poc.phone,
    billingPocEmail: billing.poc.email,
    billingAddress: formatBillingAddress(billing.address),
    billingCompanyName: billing.address.name,
    billingCompanyId: billing.address.companyid,
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    certCategory: devicemodel.certcategoryid || 0,
    certCategoryName: devicemodel.certcategory.name || "",
    certGroups,
    certGroupsToggled: getCertGroupsToggled(certGroups),
    certGroupsString: getCertGroupsAsString(certGroups),
    certGroupsVerified: verifyWireless(checkCertRequirements(certGroups)),
    completeDate: reqData.completedat,
    descriptionOfChange: reqData.descriptionofchange,
    deviceClass: [],
    deviceType: devicemodel.devicetype.id ? devicemodel.devicetype.id : 13, // TODO: get deviceType from parent model
    existedModel: devicemodel.legacydevicemodelid,
    existedModelName: devicemodel.legacydevicemodelid
      ? String(devicemodel.modelnumber)
      : "",
    id: reqData.id,
    hasLteSupport,
    hasParentApproved:
      reqData.parent && isApproved(reqData.parent.requeststatusid),
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    hasUsersManualApproved: manualApproved,
    hasWifiSupport,
    hwVersion: reqData.hwversion.trim(),
    isApproved: isApproved(reqData.requeststatusid),
    isEco: isEco(reqType),
    ieee: reqData.ieeetypeid,
    isInitial: isInitial(reqType),
    isLegacy: isLegacy(reqData),
    isLegacyModel: devicemodel.legacydevicemodelid ? true : false,
    isPaid: Boolean(invoice.paid),
    isTogglerEnabled: false,
    isValid: true,
    invoice,
    ivcDate: invoice.createdat,
	licenseagreementversionid: reqData.licenseagreementversionid,
    model: devicemodel,
    modelName: String(devicemodel.modelnumber),
    needUpdate: false,
    parent: reqData.parent,
    payCheckNumber: invoice.checknumber,
    payMethod: invoice.paymentmethod,
    payDate: invoice.paidat,
    payNote: invoice.paymentnote,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    requestoperators: reqData.requestoperators,
    status: requeststatusid,
    saveDate: reqData.savedat,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    supportDocs: fillUploadedDocsArray(
      supportDocsCount,
      reqData.requestdocuments
    ),
    swVersion: reqData.swversion.trim(),
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn: reqData.formatedtransactionnumber,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    vendorProductName: reqData.vendorproductname,
    verifiedPtcrb,
    verifiedGcf,
    verifiedWifi,
    wireless,
    wirelessToggled
  };
};

const getOtaFields = (action, state) => {
  const reqData = action.payload;
  const { devicemodel, poc, submitter, requeststatusid, billing, invoice } =
    reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = types.ota;

  return {
    ...state,
    ...reqStatuses,
    billingId: reqData.billingid || 0,
    billingPocId: billing.pocid || 0,
    billingAddressId: billing.addressid || 0,
    billingOrderNum: reqData.purchaseorder,
    billingPocName: getFullName(billing.poc),
    billingPocPhone: billing.poc.phone,
    billingPocEmail: billing.poc.email,
    billingAddress: formatBillingAddress(billing.address),
    billingCompanyName: billing.address.name,
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    descriptionOfChange: reqData.descriptionofchange,
    deviceType: devicemodel.devicetype.id ? devicemodel.devicetype.id : 13, // TODO: get deviceType from parent model
    id: reqData.id,
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    hwVersion: reqData.hwversion.trim(),
    isApproved: isApproved(reqData.requeststatusid),
    isLegacy: isLegacy(reqData),
    isPaid: Boolean(invoice.paid),
    isTogglerEnabled: false,
    isValid: true,
    invoice,
    ivcDate: invoice.createdat,
	licenseagreementversionid: reqData.licenseagreementversionid,
    model: devicemodel,
    modelName: String(devicemodel.modelnumber),
    needUpdate: false,
    payCheckNumber: invoice.checknumber,
    payMethod: invoice.paymentmethod,
    payDate: invoice.paidat,
    payNote: invoice.paymentnote,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    swVersion: reqData.swversion.trim(),
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn: reqData.formatedtransactionnumber,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    vendorProductName: reqData.vendorproductname,
    __orig: { ...reqData }
  };
};

const fetchRequestSAR = (action, state) => {
  const reqData = action.payload;
  const { poc, submitter, requeststatusid, crslversion } = reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = reqData.requesttypeid;
  const tn = reqData.formatedtransactionnumber;

  return {
    ...state,
    ...reqStatuses,
    certPrefix: getTransPrefix(tn),
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    crslversion,
    descriptionOfChange: reqData.descriptionofchange,
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    id: reqData.id,
    ieee: reqData.ieeetypeid,
    isApproved: isApproved(reqData.requeststatusid),
    isAuditRequest: true,
    isAwaitingLinkedRequest: reqData.isAwaitingLinkedRequest,
    isAwaitingParentRequest: reqData.isAwaitingParentRequest,
    isBattery: true,
    isLegacy: isLegacy(reqData),
    isSAR: true,
    isRenewal: isRenewal(reqType),
    isTogglerEnabled: false,
    isValid: true,
	licenseagreementversionid: reqData.licenseagreementversionid,
    linkedRequests: reqData.linkedrequests,
    linkedAudits: mapLinkedAudits(reqData.linkedsiteaudits),
    manufSite: { ...reqData.manufsite },
    manufSiteId: reqData.manufsite.id,
    manufSiteName: reqData.manufsite.manufsitename,
    needUpdate: false,
    parent: reqData.parent,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: submitter.companyname,
    vendorNote: reqData.vendornote,
    vendorProductName: reqData.vendorproductname,
    __orig: { ...reqData }
  };
};

const fetchRequestCRR = (action, state) => {
  const reqData = action.payload;
  const {
    poc,
    submitter,
    requeststatusid,
    crslversion,
    devicemodel,
    revision,
    invoice,
    billing
  } = reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = reqData.requesttypeid;
  const { capacity, capacityrateid, voltage, voltagerateid } =
    devicemodel.devicemodelbatterycomponent;
  const reqCapacity = [capacityrateid, capacity];
  const reqVoltage = [voltagerateid, voltage];
  const tn = reqData.formatedtransactionnumber;
  const billingArgs = billing
    ? {
        billingId: reqData.billingid || 0,
        billingPocId: billing.pocid || 0,
        billingAddressId: billing.addressid || 0,
        billingOrderNum: reqData.purchaseorder,
        billingPocName: getFullName(billing.poc),
        billingPocPhone: billing.poc.phone,
        billingPocEmail: billing.poc.email,
        billingAddress: formatBillingAddress(billing.address),
        billingCompanyName: billing.address.name,
        billingCompanyId: billing.address.companyid
      }
    : {};

  const isEcoReq = isEco(reqType);
  const linkedManufsites =
    isEcoReq && reqData.requestmanufsites.length === 0
      ? reqData.parent.requestmanufsites
      : reqData.requestmanufsites;

  return {
    ...state,
    ...reqStatuses,
    ...billingArgs,
    agreementAccepted: !isSaved(requeststatusid),
    capacity: reqCapacity,
    certPrefix: getTransPrefix(tn),
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    crslversion,
    descriptionOfChange: reqData.descriptionofchange,
    hasParentApproved:
      reqData.parent && isApproved(reqData.parent.requeststatusid),
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    id: reqData.id,
    ieee: reqData.ieeetypeid,
    invoice,
    isApproved: isApproved(reqData.requeststatusid),
    isAwaitingLinkedRequest: reqData.isAwaitingLinkedRequest,
    isAwaitingParentRequest: reqData.isAwaitingParentRequest,
    isAwaitingManufSiteApproval: reqData.isAwaitingManufSiteApproval,
    isBattery: true,
    isCRR: true,
    isEco: isEcoReq,
    isInitial: isInitial(reqType),
    isLegacy: isLegacy(reqData),
    isPaid: Boolean(invoice.paid),
    isTogglerEnabled: false,
    isValid: true,
    ivcDate: invoice.createdat,
	licenseagreementversionid: reqData.licenseagreementversionid,
    linkedManufsites: setAsSelectedByDefault(linkedManufsites),
    manufSite: { ...reqData.manufsite },
    manufSiteId: reqData.manufsite.id,
    manufSiteName: reqData.manufsite.manufsitename,
    model: devicemodel,
    modelName: String(devicemodel.modelnumber),
    needUpdate: false,
    parent: reqData.parent,
    payCheckNumber: invoice.checknumber,
    payMethod: invoice.paymentmethod,
    payDate: invoice.paidat,
    payNote: invoice.paymentnote,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    revision,
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    supportDocs: fillUploadedDocsArray(
      supportDocsCount,
      reqData.requestdocuments
    ),
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn: reqData.formatedtransactionnumber,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    vendorNote: reqData.vendornote,
    vendorProductName: reqData.vendorproductname,
    voltage: reqVoltage,
    __orig: { ...reqData }
  };
};

const fetchRequestHRR = (action, state) => {
  const reqData = action.payload;
  const {
    poc,
    submitter,
    requeststatusid,
    crslversion,
    devicemodel,
    revision,
    invoice,
    billing
  } = reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = reqData.requesttypeid;
  const { capacity, capacityrateid, voltage, voltagerateid } =
    devicemodel.devicemodelbatterycomponent;
  const reqCapacity = [capacityrateid, capacity];
  const reqVoltage = [voltagerateid, voltage];
  const tn = reqData.formatedtransactionnumber;
  const billingArgs = billing
    ? {
        billingId: reqData.billingid || 0,
        billingPocId: billing.pocid || 0,
        billingAddressId: billing.addressid || 0,
        billingOrderNum: reqData.purchaseorder,
        billingPocName: getFullName(billing.poc),
        billingPocPhone: billing.poc.phone,
        billingPocEmail: billing.poc.email,
        billingAddress: formatBillingAddress(billing.address),
        billingCompanyName: billing.address.name,
        billingCompanyId: billing.address.companyid
      }
    : {};

  const deviceTypes = [
    { id: 1, label: "Pack", checked: false },
    { id: 2, label: "Adapter", checked: false }
  ];

  return {
    ...state,
    ...reqStatuses,
    ...billingArgs,
    agreementAccepted: !isSaved(requeststatusid),
    capacity: reqCapacity,
    cellSuppliers: setCellSuppliers(reqData),
    certPrefix: getTransPrefix(tn),
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    crslversion,
    descriptionOfChange: reqData.descriptionofchange,
    deviceTypes,
    hasParentApproved:
      reqData.parent && isApproved(reqData.parent.requeststatusid),
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    id: reqData.id,
    ieee: reqData.ieeetypeid,
    invoice,
    isAdapter: isAdapter(reqData),
    isApproved: isApproved(reqData.requeststatusid),
    isAwaitingLinkedRequest: reqData.isAwaitingLinkedRequest,
    isAwaitingParentRequest: reqData.isAwaitingParentRequest,
    isAwaitingManufSiteApproval: reqData.isAwaitingManufSiteApproval,
    isBattery: true,
    isHRR: true,
    isEco: isEco(reqType),
    isInitial: isInitial(reqType),
    isLegacy: isLegacy(reqData),
    isPack: isPack(reqData),
    isPaid: Boolean(invoice.paid),
    isTogglerEnabled: false,
    isValid: true,
    ivcDate: invoice.createdat,
	licenseagreementversionid: reqData.licenseagreementversionid,
    linkedManufsites: setAsSelectedByDefault(reqData.requestmanufsites),
    manufSite: { ...reqData.manufsite },
    manufSiteId: reqData.manufsite.id,
    manufSiteName: reqData.manufsite.manufsitename,
    model: devicemodel,
    modelName: String(devicemodel.modelnumber),
    needUpdate: false,
    parent: reqData.parent,
    partsComponents: reqData.partscomponentsnote || "",
    payCheckNumber: invoice.checknumber,
    payMethod: invoice.paymentmethod,
    payDate: invoice.paidat,
    payNote: invoice.paymentnote,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    revision,
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    supportDocs: fillUploadedDocsArray(
      supportDocsCount,
      reqData.requestdocuments
    ),
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn: reqData.formatedtransactionnumber,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    vendorNote: reqData.vendornote,
    vendorProductName: reqData.vendorproductname,
    voltage: reqVoltage,
    __orig: { ...reqData }
  };
};

const fetchRequestPRR = (action, state) => {
  const reqData = action.payload;
  const {
    poc,
    submitter,
    requeststatusid,
    crslversion,
    devicemodel,
    revision,
    invoice,
    billing
  } = reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = reqData.requesttypeid;
  const { capacity, capacityrateid, voltage, voltagerateid } =
    devicemodel.devicemodelbatterycomponent;
  const reqCapacity = [capacityrateid, capacity];
  const reqVoltage = [voltagerateid, voltage];
  const tn = reqData.formatedtransactionnumber;
  const billingArgs = billing
    ? {
        billingId: reqData.billingid || 0,
        billingPocId: billing.pocid || 0,
        billingAddressId: billing.addressid || 0,
        billingOrderNum: reqData.purchaseorder,
        billingPocName: getFullName(billing.poc),
        billingPocPhone: billing.poc.phone,
        billingPocEmail: billing.poc.email,
        billingAddress: formatBillingAddress(billing.address),
        billingCompanyName: billing.address.name,
        billingCompanyId: billing.address.companyid
      }
    : {};

  const hasEcoType = isEco(reqType);
  const linkedSites = selectNonSarSitesInitialOrEco(reqData);

  return {
    ...state,
    ...reqStatuses,
    ...billingArgs,
    agreementAccepted: !isSaved(requeststatusid),
    capacity: reqCapacity,
    cellSuppliers: setCellSuppliers(reqData),
    certPrefix: getTransPrefix(tn),
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    crslversion,
    descriptionOfChange: reqData.descriptionofchange,
    hasParentApproved:
      reqData.parent && isApproved(reqData.parent.requeststatusid),
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    id: reqData.id,
    ieee: reqData.ieeetypeid,
    invoice,
    isApproved: isApproved(reqData.requeststatusid),
    isAwaitingLinkedRequest: reqData.isAwaitingLinkedRequest,
    isAwaitingParentRequest: reqData.isAwaitingParentRequest,
    isAwaitingManufSiteApproval: reqData.isAwaitingManufSiteApproval,
    isBattery: true,
    isPRR: true,
    isEco: hasEcoType,
    isInitial: isInitial(reqType),
    isLegacy: isLegacy(reqData),
    isPaid: Boolean(invoice.paid),
    isTogglerEnabled: false,
    isValid: true,
    ivcDate: invoice.createdat,
	licenseagreementversionid: reqData.licenseagreementversionid,
    linkedManufsites: setAsSelectedByDefault(linkedSites),
    manufSite: { ...reqData.manufsite },
    manufSiteId: reqData.manufsite.id,
    manufSiteName: reqData.manufsite.manufsitename,
    model: devicemodel,
    modelName: String(devicemodel.modelnumber),
    needUpdate: false,
    parent: reqData.parent,
    payCheckNumber: invoice.checknumber,
    payMethod: invoice.paymentmethod,
    payDate: invoice.paidat,
    payNote: invoice.paymentnote,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    revision,
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    supportDocs: fillUploadedDocsArray(
      supportDocsCount,
      reqData.requestdocuments
    ),
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn: reqData.formatedtransactionnumber,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    vendorNote: reqData.vendornote,
    vendorProductName: reqData.vendorproductname,
    voltage: reqVoltage,
    __orig: { ...reqData }
  };
};

const fetchRequestEPR = (action, state) => {
  const reqData = action.payload;
  const {
    poc,
    submitter,
    requeststatusid,
    crslversion,
    devicemodel,
    revision,
    invoice,
    billing,
    requestmanufsites: sites
  } = reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = reqData.requesttypeid;
  const { capacity, capacityrateid, voltage, voltagerateid } =
    devicemodel.devicemodelbatterycomponent;
  const reqCapacity = [capacityrateid, capacity];
  const reqVoltage = [voltagerateid, voltage];
  const tn = reqData.formatedtransactionnumber;
  const billingArgs = billing
    ? {
        billingId: reqData.billingid || 0,
        billingPocId: billing.pocid || 0,
        billingAddressId: billing.addressid || 0,
        billingOrderNum: reqData.purchaseorder,
        billingPocName: getFullName(billing.poc),
        billingPocPhone: billing.poc.phone,
        billingPocEmail: billing.poc.email,
        billingAddress: formatBillingAddress(billing.address),
        billingCompanyName: billing.address.name,
        billingCompanyId: billing.address.companyid
      }
    : {};

  const hasEcoType = isEco(reqType);
  const linkedSites =
    sites && sites.length > 0
      ? reqData.requestmanufsites
      : hasEcoType
      ? reqData.parent.requestmanufsites
      : [];

  return {
    ...state,
    ...reqStatuses,
    ...billingArgs,
    agreementAccepted: !isSaved(requeststatusid),
    capacity: reqCapacity,
    cellSuppliers: setCellSuppliers(reqData),
    certPrefix: getTransPrefix(tn),
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    crslversion,
    descriptionOfChange: reqData.descriptionofchange,
    hasParentApproved:
      reqData.parent && isApproved(reqData.parent.requeststatusid),
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    id: reqData.id,
    ieee: reqData.ieeetypeid,
    invoice,
    isApproved: isApproved(reqData.requeststatusid),
    isAwaitingLinkedRequest: reqData.isAwaitingLinkedRequest,
    isAwaitingParentRequest: reqData.isAwaitingParentRequest,
    isAwaitingManufSiteApproval: reqData.isAwaitingManufSiteApproval,
    isBattery: true,
    isEPRR: true,
    isEco: hasEcoType,
    isInitial: isInitial(reqType),
    isLegacy: isLegacy(reqData),
    isPaid: Boolean(invoice.paid),
    isTogglerEnabled: false,
    isValid: true,
    ivcDate: invoice.createdat,
	licenseagreementversionid: reqData.licenseagreementversionid,
    linkedManufsites: setAsSelectedByDefault(linkedSites),
    manufSite: { ...reqData.manufsite },
    manufSiteId: reqData.manufsite.id,
    manufSiteName: reqData.manufsite.manufsitename,
    model: devicemodel,
    modelName: String(devicemodel.modelnumber),
    needUpdate: false,
    parent: reqData.parent,
    payCheckNumber: invoice.checknumber,
    payMethod: invoice.paymentmethod,
    payDate: invoice.paidat,
    payNote: invoice.paymentnote,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    revision,
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    supportDocs: fillUploadedDocsArray(
      supportDocsCount,
      reqData.requestdocuments
    ),
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn: reqData.formatedtransactionnumber,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    vendorNote: reqData.vendornote,
    vendorProductName: reqData.vendorproductname,
    voltage: reqVoltage,
    __orig: { ...reqData }
  };
};

const fetchRequestARR = (action, state) => {
  const reqData = action.payload;
  const {
    poc,
    submitter,
    requeststatusid,
    crslversion,
    devicemodel,
    revision,
    invoice,
    billing,
    requestmanufsites: sites
  } = reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = reqData.requesttypeid;
  const tn = reqData.formatedtransactionnumber;
  const billingArgs = billing
    ? {
        billingId: reqData.billingid || 0,
        billingPocId: billing.pocid || 0,
        billingAddressId: billing.addressid || 0,
        billingOrderNum: reqData.purchaseorder,
        billingPocName: getFullName(billing.poc),
        billingPocPhone: billing.poc.phone,
        billingPocEmail: billing.poc.email,
        billingAddress: formatBillingAddress(billing.address),
        billingCompanyName: billing.address.name,
        billingCompanyId: billing.address.companyid
      }
    : {};

  const hasEcoType = isEco(reqType);
  const linkedSites =
    sites && sites.length > 0
      ? reqData.requestmanufsites
      : hasEcoType
      ? reqData.parent.requestmanufsites
      : [];

  return {
    ...state,
    ...reqStatuses,
    ...billingArgs,
    agreementAccepted: !isSaved(requeststatusid),
    certPrefix: getTransPrefix(tn),
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    crslversion,
    descriptionOfChange: reqData.descriptionofchange,
    hasParentApproved:
      reqData.parent && isApproved(reqData.parent.requeststatusid),
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    id: reqData.id,
    ieee: reqData.ieeetypeid,
    invoice,
    isApproved: isApproved(reqData.requeststatusid),
    isAwaitingLinkedRequest: reqData.isAwaitingLinkedRequest,
    isAwaitingParentRequest: reqData.isAwaitingParentRequest,
    isAwaitingManufSiteApproval: reqData.isAwaitingManufSiteApproval,
    isBattery: true,
    isARR: true,
    isEco: hasEcoType,
    isInitial: isInitial(reqType),
    isLegacy: isLegacy(reqData),
    isPaid: Boolean(invoice.paid),
    isTogglerEnabled: false,
    isValid: true,
    ivcDate: invoice.createdat,
	licenseagreementversionid: reqData.licenseagreementversionid,
    linkedManufsites: setAsSelectedByDefault(linkedSites),
    manufSite: { ...reqData.manufsite },
    manufSiteId: reqData.manufsite.id,
    manufSiteName: reqData.manufsite.manufsitename,
    model: devicemodel,
    modelName: String(devicemodel.modelnumber),
    needUpdate: false,
    parent: reqData.parent,
    payCheckNumber: invoice.checknumber,
    payMethod: invoice.paymentmethod,
    payDate: invoice.paidat,
    payNote: invoice.paymentnote,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    revision,
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    supportDocs: fillUploadedDocsArray(
      supportDocsCount,
      reqData.requestdocuments
    ),
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn: reqData.formatedtransactionnumber,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    vendorNote: reqData.vendornote,
    vendorProductName: reqData.vendorproductname,
    __orig: { ...reqData }
  };
};

const fetchRequestACR = (action, state) => {
  const reqData = action.payload;
  const {
    poc,
    submitter,
    requeststatusid,
    crslversion,
    devicemodel,
    revision,
    invoice,
    billing,
    requestmanufsites: sites
  } = reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = reqData.requesttypeid;
  const tn = reqData.formatedtransactionnumber;
  const billingArgs = billing
    ? {
        billingId: reqData.billingid || 0,
        billingPocId: billing.pocid || 0,
        billingAddressId: billing.addressid || 0,
        billingOrderNum: reqData.purchaseorder,
        billingPocName: getFullName(billing.poc),
        billingPocPhone: billing.poc.phone,
        billingPocEmail: billing.poc.email,
        billingAddress: formatBillingAddress(billing.address),
        billingCompanyName: billing.address.name,
        billingCompanyId: billing.address.companyid
      }
    : {};

  const hasEcoType = isEco(reqType);
  const linkedSites =
    sites && sites.length > 0
      ? reqData.requestmanufsites
      : hasEcoType
      ? reqData.parent.requestmanufsites
      : [];

  return {
    ...state,
    ...reqStatuses,
    ...billingArgs,
    agreementAccepted: !isSaved(requeststatusid),
    certPrefix: getTransPrefix(tn),
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    crslversion,
    descriptionOfChange: reqData.descriptionofchange,
    hasParentApproved:
      reqData.parent && isApproved(reqData.parent.requeststatusid),
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    id: reqData.id,
    ieee: reqData.ieeetypeid,
    invoice,
    isApproved: isApproved(reqData.requeststatusid),
    isAwaitingLinkedRequest: reqData.isAwaitingLinkedRequest,
    isAwaitingParentRequest: reqData.isAwaitingParentRequest,
    isAwaitingManufSiteApproval: reqData.isAwaitingManufSiteApproval,
    isBattery: true,
    isACR: true,
    isEco: hasEcoType,
    isInitial: isInitial(reqType),
    isLegacy: isLegacy(reqData),
    isPaid: Boolean(invoice.paid),
    isTogglerEnabled: false,
    isValid: true,
    ivcDate: invoice.createdat,
	licenseagreementversionid: reqData.licenseagreementversionid,
    linkedManufsites: setAsSelectedByDefault(linkedSites),
    manufSite: { ...reqData.manufsite },
    manufSiteId: reqData.manufsite.id,
    manufSiteName: reqData.manufsite.manufsitename,
    model: devicemodel,
    modelName: String(devicemodel.modelnumber),
    needUpdate: false,
    parent: reqData.parent,
    payCheckNumber: invoice.checknumber,
    payMethod: invoice.paymentmethod,
    payDate: invoice.paidat,
    payNote: invoice.paymentnote,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    revision,
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    supportDocs: fillUploadedDocsArray(
      supportDocsCount,
      reqData.requestdocuments
    ),
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn: reqData.formatedtransactionnumber,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    vendorNote: reqData.vendornote,
    vendorProductName: reqData.vendorproductname,
    __orig: { ...reqData }
  };
};

const fetchRequestBSC = (action, state) => {
  const reqData = action.payload;
  const { poc, submitter, requeststatusid, devicemodel, crslversion } = reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = reqData.requesttypeid;
  const tn = reqData.formatedtransactionnumber;
  const hasEcoType = isEco(reqType);

  return {
    ...state,
    ...reqStatuses,
    adapterSuppliers: mapAdapters(reqData, state),
    agreementAccepted: !isSaved(requeststatusid),
    batterySuppliers: mapBatteryPacks(reqData, state),
    certPrefix: getTransPrefix(tn),
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    crslversion,
    descriptionOfChange: reqData.descriptionofchange,
    hasParentApproved:
      reqData.parent && isApproved(reqData.parent.requeststatusid),
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    hwVersion: reqData.hwversion.trim(),
    id: reqData.id,
    ieee: reqData.ieeetypeid,
    isApproved: isApproved(reqData.requeststatusid),
    isAwaitingLinkedRequest: reqData.isAwaitingLinkedRequest,
    isAwaitingParentRequest: reqData.isAwaitingParentRequest,
    isAwaitingManufSiteApproval: reqData.isAwaitingManufSiteApproval,
    isBattery: true,
    isBSC: true,
    isEco: hasEcoType,
    isInitial: isInitial(reqType),
    isLegacy: isLegacy(reqData),
    isTogglerEnabled: false,
    isValid: true,
	licenseagreementversionid: reqData.licenseagreementversionid,
    model: devicemodel,
    modelName: String(devicemodel.modelnumber),
    needUpdate: false,
    parent: reqData.parent,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    supportDocs: fillUploadedDocsArray(
      supportDocsCount,
      reqData.requestdocuments
    ),
    swVersion: reqData.swversion.trim(),
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn: reqData.formatedtransactionnumber,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    vendorProductName: reqData.vendorproductname,
    __orig: { ...reqData }
  };
};

const fetchRequestBLC = (action, state) => {
  const reqData = action.payload;
  const {
    devicemodelid,
    poc,
    submitter,
    requeststatusid,
    devicemodel,
    invoice,
    billing,
    linkedrequests
  } = reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = reqData.requesttypeid;
  const tn = reqData.formatedtransactionnumber;
  const billingArgs = billing
    ? {
        billingId: reqData.billingid || 0,
        billingPocId: billing.pocid || 0,
        billingAddressId: billing.addressid || 0,
        billingOrderNum: reqData.purchaseorder,
        billingPocName: getFullName(billing.poc),
        billingPocPhone: billing.poc.phone,
        billingPocEmail: billing.poc.email,
        billingAddress: formatBillingAddress(billing.address),
        billingCompanyName: billing.address.name,
        billingCompanyId: billing.address.companyid
      }
    : {};
  const hasEcoType = isEco(reqType);
  const testResults = fillReportsArrayBLC();

  const operatingSystem = hasEcoType
    ? reqData.parent.operatingsystemid
    : reqData.operatingsystemid;
  const operatingSystemName = hasEcoType
    ? reqData.parent.operatingsystemname
    : reqData.operatingsystemname;
  return {
    ...state,
    ...reqStatuses,
    ...billingArgs,
    agreementAccepted: !isSaved(requeststatusid),
    batteryLifeResult: reqData.batteryliferesult,
    batteryPackId: linkedrequests[0]?.linkedrequestid,
    certPrefix: getTransPrefix(tn),
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    deviceType: reqData.devicetype,
    devicemodelid,
    hasParentApproved:
      reqData.parent && isApproved(reqData.parent.requeststatusid),
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    hwVersion: reqData.hwversion.trim(),
    id: reqData.id,
    invoice,
    isApproved: isApproved(reqData.requeststatusid),
    isBLC: true,
    isEco: hasEcoType,
    isInitial: isInitial(reqType),
    isLegacy: isLegacy(reqData),
    isPaid: Boolean(invoice.paid),
    isTogglerEnabled: false,
    isValid: true,
    ivcDate: invoice.createdat,
	licenseagreementversionid: reqData.licenseagreementversionid,
    model: devicemodel,
    modelName: String(devicemodel.modelnumber),
    needUpdate: false,
    operatingSystem,
    operatingSystemName,
    parent: reqData.parent,
    payCheckNumber: invoice.checknumber,
    payMethod: invoice.paymentmethod,
    payDate: invoice.paidat,
    payNote: invoice.paymentnote,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    supportDocs: fillUploadedDocsArray(
      supportDocsCount,
      reqData.requestdocuments
    ),
    swVersion: reqData.swversion.trim(),
    testResults,
    testStatus: reqData.testingstatus,
    tn: reqData.formatedtransactionnumber,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    vendorNote: reqData.vendornote,
    vendorProductName: reqData.vendorproductname,
    __orig: { ...reqData }
  };
};

const fetchPocs = (action, state) => {
  const pocs = action.payload;
  if (_.isEmpty(pocs)) return { ...state, pocs: [] };

  return { ...state, pocs: sortPocs(pocs) };
};

const getUsersManualInfo = (action, state) => {
  const { payload } = action;
  const { status, data } = payload;

  if (status === 200) {
    const usersManual = data.data || undefined;
    const usersManualApproved = hasUsersManualApproved(usersManual);
    return {
      ...state,
      usersManual,
      hasUsersManualApproved: usersManualApproved
    };
  }

  return state;
};

const updateUsersManual = (action, state) => {
  const { payload } = action;
  const { status, data } = payload;

  if (status === 200) {
    const manual = { ...state.usersManual };
    const manualDoc = data.data || undefined;
    manual.document = manualDoc;

    const usersManualApproved = hasUsersManualApproved(manual);
    return {
      ...state,
      usersManual: manual,
      hasUsersManualApproved: usersManualApproved
    };
  }

  return state;
};

const fetchReports = (action, state) => {
  if (!action.payload.data) return state;

  const testResults = fillUploadedDocsArray(
    testReportsCount,
    action.payload.data.data
  );

  return { ...state, testResults };
};

const fetchReportsBLC = (action, state) => {
  if (!action.payload.data) return state;
  if (!action.payload.data.data) return state;

  const testResults = fillUploadedDocsArrayBLC(action.payload.data.data);

  return { ...state, testResults };
};

const updateStatus = (action, state) => {
  if (!action.payload.data) return state;
  return {
    ...state,
    requeststatusid: action.payload.data.data.requeststatusid
  };
};

const setToggler = (toggled, hasLte) => {
  const includesLte = _.includes(toggled, 1);

  if (hasLte && !includesLte) return [...toggled, 1];
  if (!hasLte && includesLte) return _.pull(toggled, 1);

  return toggled;
};

const updateTestReportStatus = (action, state) => {
  // payload shortcuts
  const { slot, status, testId } = action.payload;

  // grab testModule first
  const testModule = _.find(state.otaInterfaces, ["details.id", testId]);

  // ¯\_(ツ)_/¯ \\
  if (!testModule) return { ...state };

  // find report entry for passed slot id:
  const report = _.find(testModule.details.reports, ["slot", slot]);

  // update report status
  report.document.documentstatusid = status;

  // now change testModule status if needed
  const isCompleted = _.every(testModule.details.reports, report => {
    if (report.docId === 0) return true;
    return report.document.documentstatusid === approved;
  });

  testModule.details.requestteststatusid = isCompleted
    ? testStatusIds.complete
    : testStatusIds.underReview;

  return { ...state };
};

const addBatterySupplier = (action, state) => {
  const supplierToAdd = action.payload;
  const suppliersList = state.batterySuppliers || [];
  return {
    ...state,
    batterySuppliers: [...suppliersList, supplierToAdd],
    // also need to reset previous selected values:
    [requestFields.supplier]: null,
    [requestFields.supplierId]: null,
    [requestFields.supplierName]: null,
    [requestFields.model]: null,
    [requestFields.modelId]: null,
    [requestFields.modelName]: null,
    [requestFields.modelOption]: null,
    [requestFields.revision]: null,
    [requestFields.cellRequestId]: null,
    [requestFields.linkedRequestId]: null,
    [requestFields.linkedRequestCapacity]: null,
    [requestFields.linkedRequestRevision]: null,
    hasPendingRecognition: null,
    linkedrequestid: null,
    linkedrequestdevicemodelid: null,
    linkedrequestvendorproductname: null,
    linkedrequestvendorname: null,
    linkedrequestvendorid: null
  };
};

const removeBatterySupplier = (action, state) => {
  let batterySuppliers = [];

  // check if suppliers list exist:
  if (_.size(state.batterySuppliers) > 0) {
    const { requestId, vendorId } = action.payload;

    if (requestId) {
      batterySuppliers = _.reject(state.batterySuppliers, [
        "linkedrequestid",
        requestId
      ]);
    } else {
      batterySuppliers = _.reject(state.batterySuppliers, {
        linkedrequestvendorid: vendorId,
        linkedrequestid: null
      });
    }
  }

  return { ...state, batterySuppliers };
};

const addOtaTestPlan = (action, state) => {
  const interfaceToAdd = action.payload;
  const interfaceList = state.otaInterfaces || [];
  return {
    ...state,
    otaInterfaces: [...interfaceList, interfaceToAdd],
    // also need to reset previous selected values:
    [otaFields.otaPlan]: null,
    [otaFields.otaLab]: null
  };
};

const removeOtaTestPlan = (action, state) => {
  let otaInterfaces = [];

  // check if OTA test plan list exist:
  if (_.size(state.otaInterfaces) > 0) {
    const id = action.payload.planInfo.value;

    // remove test plan with passed id from existed list:
    otaInterfaces = _.reject(state.otaInterfaces, [
      "planInfo.value",
      Number(id)
    ]);
  }

  return { ...state, otaInterfaces };
};

const addAdapterSupplier = (action, state) => {
  const adapterToAdd = action.payload;
  const adaptersList = state.adapterSuppliers || [];
  return {
    ...state,
    adapterSuppliers: [...adaptersList, adapterToAdd],
    // also need to reset previous selected values:
    [adapterFields.type]: null,
    [adapterFields.supplier]: null,
    [adapterFields.supplierId]: null,
    [adapterFields.supplierName]: null,
    [adapterFields.linkedRequestId]: null,
    [adapterFields.linkedRequestRevision]: null,
    [adapterFields.model]: null,
    [adapterFields.modelId]: null,
    [adapterFields.modelName]: null,
    [adapterFields.modelOption]: null,
    [adapterFields.revision]: null
  };
};

const removeAdapterSupplier = (action, state) => {
  let adapterSuppliers = [];

  // check if suppliers list exist:
  if (_.size(state.adapterSuppliers) > 0) {
    const { requestId, vendorId, modelName } = action.payload;

    if (requestId) {
      adapterSuppliers = _.reject(state.adapterSuppliers, [
        "linkedrequestid",
        requestId
      ]);
    } else if (vendorId) {
      adapterSuppliers = _.reject(state.adapterSuppliers, {
        linkedrequestvendorid: vendorId,
        linkedrequestid: null
      });
    } else {
      adapterSuppliers = _.reject(state.adapterSuppliers, {
        linkedrequestvendorproductname: modelName
      });
    }
  }

  return { ...state, adapterSuppliers };
};

const deleteDocument = (action, state) => {
  if (!_.isUndefined(action.payload.slot)) {
    const docSlot = action.payload.slot;
    const docIndex = _.findIndex(state.supportDocs, ["slot", docSlot]);
    if (docIndex >= 0) {
      const supDocs = [...state.supportDocs];
      supDocs[docIndex] = {
        file: null,
        title: "",
        slot: docSlot,
        docId: 0
      };
      return { ...state, supportDocs: supDocs };
    }
  }
  return state;
};

const setLteWireless = (action, state) => {
  const hasLte = action.payload;
  const reqWireless = [...state.wireless];
  const reqWirelessToggled = state.wirelessToggled
    ? [...state.wirelessToggled]
    : [];

  // set wireless checkbox state
  reqWireless[0].checked = hasLte ? true : false;
  reqWireless[0].disabled = hasLte ? true : false;

  // set wireless checkbox value
  const reqToggled = setToggler(reqWirelessToggled, hasLte);

  return { ...state, wireless: reqWireless, wirelessToggled: reqToggled };
};

const addCellSupplier = (action, state) => {
  const supplierToAdd = action.payload;
  const suppliersList = state.cellSuppliers || [];
  return {
    ...state,
    cellSuppliers: [...suppliersList, supplierToAdd],
    // also need to reset previous selected values:
    [requestFields.supplier]: null,
    [requestFields.model]: null,
    [requestFields.modelOption]: null,
    [requestFields.revision]: null,
    hasPendingRecognition: null,
    linkedrequestid: null,
    linkedrequestdevicemodelid: null,
    linkedrequestvendorproductname: null,
    linkedrequestvendorname: null,
    linkedrequestvendorid: null
  };
};

const removeCellSupplier = (action, state) => {
  let cellSuppliers = [];

  // check if suppliers list exist:
  if (_.size(state.cellSuppliers) > 0) {
    const { requestId, vendorId } = action.payload;

    if (requestId) {
      cellSuppliers = _.reject(state.cellSuppliers, [
        "linkedrequestid",
        requestId
      ]);
    } else {
      cellSuppliers = _.reject(state.cellSuppliers, {
        linkedrequestvendorid: vendorId,
        linkedrequestid: null
      });
    }
  }

  return { ...state, cellSuppliers };
};

const checkAwaitingLinkedSites = (action, state) => {
  return { ...state, awaitingLinkedSites: action.payload };
};

const fetchRequestDHR = (action, state) => {
  const reqData = action.payload;
  const { billing, devicemodel, invoice, poc, submitter, requeststatusid } =
    reqData;
  const reqStatuses = getRequestStatuses(reqData);
  const reqType = reqData.requesttypeid;
  const tn = reqData.formatedtransactionnumber;
  const billingArgs = billing
    ? {
        billingId: reqData.billingid || 0,
        billingPocId: billing.pocid || 0,
        billingAddressId: billing.addressid || 0,
        billingOrderNum: reqData.purchaseorder,
        billingPocName: getFullName(billing.poc),
        billingPocPhone: billing.poc.phone,
        billingPocEmail: billing.poc.email,
        billingAddress: formatBillingAddress(billing.address),
        billingCompanyName: billing.address.name
      }
    : {};

  return {
    ...state,
    ...reqStatuses,
    ...billingArgs,
    agreementAccepted: !isSaved(requeststatusid),
    areOperatorsMandatory: true, // allows to customize Operators component view
    certPrefix: getTransPrefix(tn),
    cdmaLab: reqData.primarylabid,
    cdmaLabName: reqData.primarylabname || "n/a",
    completeDate: reqData.completedat,
    descriptionOfChange: reqData.descriptionofchange,
    hasTestReportsApproved: isComplete(reqData.testingstatus),
    hwVersion: reqData.hwversion.trim(),
    id: reqData.id,
    isApproved: isApproved(reqData.requeststatusid),
    isDHR: true,
    isEco: isEco(reqType),
    isInitial: isInitial(reqType),
    isLegacy: isLegacy(reqData),
    isPaid: Boolean(invoice.paid),
    isTogglerEnabled: false,
    isValid: true,
    invoice,
    ivcDate: invoice.createdat,
    model: devicemodel,
    modelName: devicemodel.legacydevicemodelid
      ? ""
      : String(devicemodel.modelnumber),
    needUpdate: false,
    parent: reqData.parent,
    payCheckNumber: invoice.checknumber,
    payMethod: invoice.paymentmethod,
    payDate: invoice.paidat,
    payNote: invoice.paymentnote,
    pocId: poc.id,
    pocFirstName: poc.firstname,
    pocLastName: poc.lastname,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    pocFullName: getFullName(poc),
    requestoperators: reqData.requestoperators,
    saveDate: reqData.createdat, // TODO: replace it with `savedat` when server will have a fix for it
    status: requeststatusid,
    submitterId: reqData.submitteruserid,
    submissionDate: reqData.pendingat,
    submitter,
    supportDocs: fillUploadedDocsArray(
      supportDocsCount,
      reqData.requestdocuments
    ),
    testResults: fillReportsArray(),
    testStatus: reqData.testingstatus,
    tn,
    type: reqType,
    typeTitle: getRequestTypeTitle(reqType),
    vendor: devicemodel.vendor.name,
    __orig: { ...reqData }
  };
};

const fetchCrrModel = (action, state) => {
  if (!action.payload) return state;

  const { revision } = action.payload;

  return {
    ...state,
    revision,
    model: action.payload,
    parentRequest: action.payload
  };
};

const fetchHrrModel = (action, state) => {
  if (!action.payload) return state;

  const { revision } = action.payload;

  return {
    ...state,
    revision,
    model: action.payload,
    parentRequest: action.payload
  };
};

const mapPackCells = request => {
  const cellSuppliers = [];
  const { linkedrequests, requestpendingrecognitions: recs } = request;

  // 1 - map selected Pack Cells, if presented
  if (linkedrequests && linkedrequests.length > 0) {
    linkedrequests.map(req => {
      cellSuppliers.push({ ...req, hasPendingRecognition: false });
      return req;
    });
  }

  // 2 - map selected model recognitions, if presented
  if (recs && recs.length > 0) {
    recs.map(recognition => {
      cellSuppliers.push({
        hasPendingRecognition: true,
        linkedrequestid: recognition.requestid,
        linkedrequestdevicemodelid: null,
        linkedrequestvendorproductname: null,
        linkedrequestvendorname: recognition.companyname,
        linkedrequestvendorid: recognition.companyid
      });
      return recognition;
    });
  }

  return cellSuppliers;
};

const fetchPrrModel = (action, state) => {
  const data = action.payload;
  if (!data) return state;

  const { revision } = data;

  return {
    ...state,
    revision,
    model: data,
    parentRequest: data,
    cellSuppliers: mapPackCells(data)
  };
};

const fetchEprModel = (action, state) => {
  const data = action.payload;
  if (!data) return state;

  const { revision } = data;

  return {
    ...state,
    revision,
    model: data,
    parentRequest: data,
    cellSuppliers: mapPackCells(data)
  };
};

const fetchArrModel = (action, state) => {
  if (!action.payload) return state;

  const { revision } = action.payload;

  return {
    ...state,
    revision,
    model: action.payload,
    parentRequest: action.payload
  };
};

const fetchAcrModel = (action, state) => {
  if (!action.payload) return state;

  const { revision } = action.payload;

  return {
    ...state,
    revision,
    model: action.payload,
    parentRequest: action.payload
  };
};

const fetchBlcModel = (action, state) => {
  const data = action.payload;
  if (!data) return state;

  const inheritedOnSubmission = !state.id
    ? {
        hwVersion: data.hwversion,
        swVersion: data.swversion,
        operatingSystem: data.operatingsystemid,
        operatingSystemName: data.operatingsystemname
      }
    : {};
  const inheritedData = state.isEco
    ? {
        batteryPackId: data.linkedrequests[0].linkedrequestid,
        ...inheritedOnSubmission
      }
    : {};

  return {
    ...state,
    ...inheritedData,
    devicemodelid: data.devicemodelid,
    parentRequest: data
  };
};

const fetchBscModel = (action, state) => {
  const data = action.payload;
  if (!data) return state;

  const { hwversion: hwVersion, swversion: swVersion, devicemodelid } = data;
  const model = state.model || {};

  const inheritedData =
    state.isEco && !state.id
      ? {
          hwVersion,
          swVersion,
          batterySuppliers: mapBatteryPacks(data, state),
          adapterSuppliers: mapAdapters(data, state)
        }
      : {};

  return {
    ...state,
    ...inheritedData,
    model: { ...model, id: devicemodelid },
    parentRequest: data
  };
};

const fetchParentRequest = (action, state) => {
  return {
    ...state,
    parentRequest: action.payload
  };
};

const mapModelFields = (action, state) => {
  const { capacity, capacityrateid, voltage, voltagerateid } = action.payload;
  return {
    ...state,
    capacity: [capacityrateid, capacity],
    voltage: [voltagerateid, voltage]
  };
};

const mapDeviceType = (action, state) => {
  const certTypes = action.payload;

  let isPack = false;
  let isAdapter = false;

  if (state.model.devicemodelcerttypes) {
    const mappedTypes = state.model.devicemodelcerttypes.map(type => {
      return _.find(certTypes, ["id", type.certtypeid]);
    });

    mappedTypes.map(mappedType => {
      if (mappedType.name === "PRR") isPack = true;
      if (mappedType.name === "ARR") isAdapter = true;
      return mappedType;
    });
  }

  // also need to update component-based values:
  const deviceTypes = [...state.deviceTypes];
  const [pack, adapter] = deviceTypes;

  // update pack selection
  pack.checked = isPack;

  // update adapter selection
  adapter.checked = isAdapter;

  return { ...state, isPack, isAdapter, deviceTypes };
};

const mapBatteryPacks = (request, state) => {
  const batterySuppliers = [];
  const { linkedrequests, requestpendingrecognitions: recs } = request;

  // 1 - filter all linked requests and get PRR/EPRR only:
  const packCertTypes = [state.idPRR, state.idEPRR];
  const packs = _.filter(linkedrequests, pack =>
    _.includes(packCertTypes, pack.linkedrequestcerttypeid)
  );

  // 2 - map selected Packs, if presented
  if (packs && packs.length > 0) {
    packs.map(req => {
      batterySuppliers.push({
        ...req,
        linkedrequestcapacity: req.linkedrequestdevicecapacity,
        hasPendingRecognition: false
      });
      return req;
    });
  }

  // 3 - filter all recognitions and get PRR/EPRR only:
  const recPacks = _.filter(recs, pack =>
    _.includes(packCertTypes, pack.certtypeid)
  );

  // 4 - map selected pack recognitions, if presented
  if (recPacks && recPacks.length > 0) {
    recPacks.map(recognition => {
      batterySuppliers.push({
        hasPendingRecognition: true,
        // linkedrequestid: recognition.requestid,
        linkedrequestid: null,
        linkedrequestdevicemodelid: null,
        linkedrequestvendorproductname: null,
        linkedrequestvendorname: recognition.companyname,
        linkedrequestvendorid: recognition.companyid
      });
      return recognition;
    });
  }

  return batterySuppliers;
};

const mapAdapters = (request, state) => {
  const adapterSuppliers = [];
  const { idACR, idARR } = state;
  const {
    linkedrequests,
    requestpendingrecognitions: recs,
    requestunrecognizedadapters: unrec
  } = request;

  // 1 - filter all linked requests and get ACR/ARR only:
  const adapterCertTypes = [idACR, idARR];
  const adapters = _.filter(linkedrequests, adapter =>
    _.includes(adapterCertTypes, adapter.linkedrequestcerttypeid)
  );

  // 2 - map selected Adapters, if presented
  if (adapters && adapters.length > 0) {
    adapters.map(req => {
      const type = req.linkedrequestcerttypeid === idARR ? 1 : 2;
      adapterSuppliers.push({ ...req, hasPendingRecognition: false, type });
      return req;
    });
  }

  // 3 - filter all recognitions and get ACR/ARR only:
  const recAdapters = _.filter(recs, adapter =>
    _.includes(adapterCertTypes, adapter.certtypeid)
  );

  // 4 - map selected adapter recognitions, if presented
  if (recAdapters && recAdapters.length > 0) {
    recAdapters.map(recognition => {
      adapterSuppliers.push({
        hasPendingRecognition: true,
        // linkedrequestid: recognition.requestid,
        linkedrequestid: null,
        linkedrequestdevicemodelid: null,
        linkedrequestvendorproductname: null,
        linkedrequestvendorname: recognition.companyname,
        linkedrequestvendorid: recognition.companyid
      });
      return recognition;
    });
  }

  // 5 - map selected unrecognized adapters, if presented
  if (unrec && unrec.length > 0) {
    unrec.map(req => {
      adapterSuppliers.push({
        type: 3,
        hasPendingRecognition: false,
        // linkedrequestid: req.requestid,
        linkedrequestid: null,
        linkedrequestdevicemodelid: null,
        linkedrequestvendorproductname: req.modelnumber,
        linkedrequestvendorname: req.manufname,
        linkedrequestvendorid: null
      });
      return req;
    });
  }

  return adapterSuppliers;
};

const attachCertTypes = (action, state) => {
  if (!action.payload || !action.payload.idPRR) {
    const idPRR = ls.getValue("idPRR");
    const idEPRR = ls.getValue("idEPRR");
    const idACR = ls.getValue("idACR");
    const idARR = ls.getValue("idARR");

    return { ...state, idPRR, idEPRR, idACR, idARR };
  }

  const { idPRR, idEPRR, idACR, idARR } = action.payload;
  return { ...state, idPRR, idEPRR, idACR, idARR };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_REQUEST_CYBER:
      return fetchRequest(action, state);
    case actions.FETCH_REQUEST_OTA:
      return getOtaFields(action, state);
    case actions.INIT_NEW_REQUEST:
      return action.payload;
    case actions.CHANGE_REQUEST_FIELD:
      return { ...state, [action.payload.field]: action.payload.value };
    case actions.CLEAR_REQUEST:
    case actions.LOGOUT:
      return null;
    case actions.FETCH_POCS:
      return fetchPocs(action, state);
    case actions.CREATE_NEW_POC:
      return { ...state, pocId: action.payload };
    case actions.CREATE_NEW_BILLING_POC:
      return { ...state, billingPocId: action.payload };
    case actions.CREATE_NEW_BILLING_ADDRESS:
      return { ...state, billingAddressId: action.payload };
    case actions.GET_USERS_MANUAL_INFO:
      return getUsersManualInfo(action, state);
    case actions.UPDATE_REQUEST:
      const updatedRequest = fetchRequest(action, state);
      return { ...state, ...updatedRequest, needUpdate: true };
    case actions.UPDATE_REQUEST_SAR:
      return { ...state, needUpdate: true };
    case actions.FORCE_REQUEST_UPDATE:
      return { ...state, formSaved: true };
    case actions.CLEAN_POC_INFO:
    case actions.RETURN_TO_EDIT_REQUEST:
    case actions.SUBMIT_INITIAL_REQUEST:
    case actions.SUBMIT_ECO_REQUEST:
    case actions.SAVE_REQUEST:
      return { ...state, ...action.payload };
    case actions.FETCH_TEST_REPORTS:
      return fetchReports(action, state);
    case actions.FETCH_TEST_REPORTS_BLC:
      return fetchReportsBLC(action, state);
    case actions.UPDATE_USERS_MANUAL:
      return updateUsersManual(action, state);
    case actions.UPDATE_REQUEST_STATUS:
      return updateStatus(action, state);
    case actions.SET_VALIDATION_STATUS:
      return {
        ...state,
        isValid: action.payload
      };
    case actions.TOGGLE_REQUEST_EDIT_MODE:
      return {
        ...state,
        isTogglerEnabled: !state.isTogglerEnabled
      };
    case actions.FETCH_CERT_CATEGORIES:
      return { ...state, certCategories: action.payload };
    // TODO: switch to `addresses` reducer insted -,
    case actions.FETCH_BILLING_ADDRESSES:
      return { ...state, billingAddresses: action.payload };
    case actions.FETCH_REQUEST_BILLING_INFO:
      const { pocid, addressid } = action.payload;
      return { ...state, billingPocId: pocid, billingAddressId: addressid };
    case actions.MAP_REQUEST_OPERATORS:
      return { ...state, operators: action.payload };
    case actions.UPDATE_MODEL_OPERATORS:
      const modelOperators = action.payload.devicemodeloperators;
      const list = [...state.operators];
      const operators = formatOperatorsList(list, modelOperators);

      state.model.devicemodeloperators = modelOperators;

      return { ...state, operators };
    case actions.CLEAR_COUNTRY_STATE:
      return { ...state, billingAddressState: "" };
    case actions.UPDATE_BILLING_INFO:
      return {
        ...state,
        billingAddressAdd: false,
        billingPocAdd: false,
        billingAddressId: action.payload.addressid,
        billingPocId: action.payload.pocid,
        billingId: action.payload.id
      };
    case actions.UPDATE_PAYMENT:
      const invoice = { ...state.invoice, ...action.payload, paid: 1 };
      return {
        ...state,
        invoice,
        isPaid: true,
        payCheckNumber: action.payload.checknumber
      };
    case actions.FETCH_OPERATING_SYSTEMS:
      const opSysList = formatOpSysList(action.payload);
      return { ...state, opSysList };
    case actions.SET_LTE_WIRELESS:
      return setLteWireless(action, state);
    case actions.ADD_BATTERY_SUPPLIER:
      return addBatterySupplier(action, state);
    case actions.REMOVE_BATTERY_SUPPLIER:
      return removeBatterySupplier(action, state);
    case actions.ADD_OTA_TEST_PLAN:
      return addOtaTestPlan(action, state);
    case actions.REMOVE_OTA_TEST_PLAN:
      return removeOtaTestPlan(action, state);
    case actions.ADD_ADAPTER_SUPPLIER:
      return addAdapterSupplier(action, state);
    case actions.REMOVE_ADAPTER_SUPPLIER:
      return removeAdapterSupplier(action, state);
    case actions.ADD_CELL_SUPPLIER:
      return addCellSupplier(action, state);
    case actions.REMOVE_CELL_SUPPLIER:
      return removeCellSupplier(action, state);
    case actions.DELETE_DOCUMENT:
      return deleteDocument(action, state);
    case actions.SET_OTA_INTERFACES:
      return { ...state, otaInterfaces: action.payload };
    case actions.CLEAR_OTA_INTERFACES:
      return { ...state, otaInterfaces: undefined };
    case actions.UPDATE_REPORT_STATUS:
      return updateTestReportStatus(action, state);
    case actions.FETCH_REQUEST_SAR:
      return fetchRequestSAR(action, state);
    case actions.MAP_LINKED_REQUESTS:
      return { ...state, auditLinkedRequests: action.payload };
    case actions.FETCH_REQUEST_DHR:
      return fetchRequestDHR(action, state);
    case actions.CHECK_AWAITING_LINKED_SITES:
      return checkAwaitingLinkedSites(action, state);
    case actions.FETCH_REQUEST_CRR:
      return fetchRequestCRR(action, state);
    case actions.FETCH_REQUEST_HRR:
      return fetchRequestHRR(action, state);
    case actions.FETCH_CRR_MODEL:
      return fetchCrrModel(action, state);
    case actions.FETCH_PARENT_REQUEST_CRR:
      return fetchParentRequest(action, state);
    case actions.MAP_CRR_MODEL_FIELDS:
    case actions.MAP_PRR_MODEL_FIELDS:
    case actions.MAP_EPR_MODEL_FIELDS:
      return mapModelFields(action, state);
    case actions.FETCH_HRR_MODEL:
      return fetchHrrModel(action, state);
    case actions.MAP_BATTERY_DEVICE_TYPE:
      return mapDeviceType(action, state);
    case actions.FETCH_REQUEST_PRR:
      return fetchRequestPRR(action, state);
    case actions.FETCH_REQUEST_EPR:
      return fetchRequestEPR(action, state);
    case actions.FETCH_REQUEST_ARR:
      return fetchRequestARR(action, state);
    case actions.FETCH_REQUEST_ACR:
      return fetchRequestACR(action, state);
    case actions.FETCH_REQUEST_BLC:
      return fetchRequestBLC(action, state);
    case actions.FETCH_REQUEST_BSC:
      return fetchRequestBSC(action, state);
    case actions.FETCH_PRR_MODEL:
      return fetchPrrModel(action, state);
    case actions.FETCH_EPR_MODEL:
      return fetchEprModel(action, state);
    case actions.FETCH_ARR_MODEL:
      return fetchArrModel(action, state);
    case actions.FETCH_ACR_MODEL:
      return fetchAcrModel(action, state);
    case actions.FETCH_BLC_MODEL:
      return fetchBlcModel(action, state);
    case actions.FETCH_BSC_MODEL:
      return fetchBscModel(action, state);
    case actions.ATTACH_APP_CERT_TYPES:
      return attachCertTypes(action, state);
    case actions.UPDATE_INVOICE_DATE:
      const updIvc = { ...state.invoice, ...action.payload };
      return {
        ...state,
        invoice: updIvc
      };
    default:
      return state;
  }
}
