import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BackHome from "containers/BackHome";
import { resetCertProgramStatus } from "actions/app";
import { scrollTo } from "helpers/AppHelper";
import "containers/TermsOfUse/terms.css";

const Policy = ({ app, user, resetCertProgramStatus }) => {
  useEffect(() => {
    if (app.certProgram) {
      // cleanup selected program
      resetCertProgramStatus();
    }

    scrollTo("policy-tag");
  }, [app, resetCertProgramStatus]);

  const termsLink = (
    <Link to="/terms" className="glow-link">
      Terms of Use
    </Link>
  );

  const emailLink = (
    <a className="glow-link" href="mailto:support@ctiacertification.org">
      support@ctiacertification.org
    </a>
  );

  const wrapperClass = user && user.token ? "" : "terms";

  return (
    <div className={wrapperClass}>
      <BackHome />
      <h1 id="policy-tag">Privacy Policy</h1>
      <p>Effective: November 24, 2020</p>
      <p>
        This Privacy Policy explains how CTIA Certification LLC (“CTIA
        Certification” or “we” or “us” or “our”) collects, uses, and discloses
        information about you. We use the terms “user” “you” and “your” to mean
        any person using the CTIA Certification database (“Database”), including
        any organization or person using the Database on an organization’s
        behalf. This Privacy Policy applies to the processing of information
        that we collect when you use our Database, which includes any associated
        websites, applications, or services we offer, or when you communicate
        with us. This Privacy Policy does not apply to the information that you
        may provide to third parties, such as other users of the Database, and
        any others with whom you may share information about yourself.
      </p>
      <h2>1. COLLECTION OF INFORMATION</h2>
      <h4>
        <i>1.1 Information You Provide to Us</i>
      </h4>
      <p>
        We collect information that you provide directly to us. For example, we
        collect information that you provide when you create an account, submit
        documentation to the Database, identify yourself as a point of contact
        for your company, fill out a form, or communicate with us. The types of
        information that we may collect include your name, password, email
        address, phone number, the name of the company that you work for, and
        any other information that you choose to provide.
      </p>
      <h4>
        <i>
          1.2 Information We Collect Automatically When You Use the Database
        </i>
      </h4>
      <p>
        When you use our Database, we automatically collect log in information
        about your use of the Database, including the last log in date, last
        action date, time stamp, and your IP address.
      </p>
      <h2>2. USE OF INFORMATION</h2>
      <h4>
        <i>2.1 Operating Our Database</i>
      </h4>
      <p>
        We may use information about you for various purposes related to
        operating our Database. For example, to allow us to:
      </p>
      <ul className="ctia-list">
        <li>
          Provide, maintain, and improve our Database, including to develop new
          products and services, and to manage the performance of our Database;
        </li>
        <li>
          Personalize the Database, for example, to provide content specifically
          associated with your account type;
        </li>
        <li>
          Monitor and analyze trends, usage, and activities in connection with
          our Database;
        </li>
        <li>
          Detect, investigate, prevent abuse, and other illegal activities; to
          protect the rights, property, or safety of CTIA Certification or
          others; to enforce compliance with our policies; and to comply with
          applicable law, legal claims, and government requests; and
        </li>
        <li>Perform administrative tasks.</li>
      </ul>
      <h4>
        <i>2.2 Communicating With You</i>
      </h4>
      <p>
        We may use information about you for various purposes related to
        communicating with you, including to:
      </p>
      <ul className="ctia-list">
        <li>
          Respond to your comments, questions, requests, and to provide customer
          service;
        </li>
        <li>
          Notify you about certification tasks and events as described in
          individual certification process documents; and
        </li>
        <li>
          Send you technical notices, updates, security alerts, support, and
          administrative messages.
        </li>
      </ul>
      <h2>3. SHARING OF INFORMATION</h2>
      <h4>
        <i>3.1 Through Our Database</i>
      </h4>
      <p>
        As part of the services we provide, we publish the contact information
        of certain users of CTIA Certification Authorized Test Labs
        (“Directory”) on our websites. If your account is associated with a CTIA
        Authorized Test Lab, and you identify yourself to us as the
        point-of-contact for that CTIA Authorized Test Lab, you grant us
        permission to list your company name, contact name, contact phone
        number, contact email address, and authorized testing capability in our
        Directory, as described herein. If you do not want your information to
        be included in the Directory, do not identify yourself to us as the
        point-of-contact for a CTIA Authorized Test Lab. We may also gather,
        collect, and utilize aggregated and de-identified information about the
        use of our Database. By using our Database, you acknowledge that CTIA
        Certification may use and retain the information in this Section 3.1
        indefinitely, or to the extent permissible by law.
      </p>
      <h4>
        <i>3.2 With Our Service Providers</i>
      </h4>
      <p>
        We may use service providers in connection with operating and improving
        the Database to assist with certain functions, such as email
        transmission, data hosting, and aspects of our technical and customer
        support. We take measures to ensure that these service providers access,
        process, and store information about you only for the purposes we
        authorize, subject to confidentiality and other contractual obligations.
      </p>
      <h4>
        <i>3.3 Following The Law And Protecting CTIA Certification</i>
      </h4>
      <p>
        We may access, preserve, and disclose information about you to third
        parties, including the content of messages, if we believe disclosure is
        in accordance with or required by applicable law, regulation, legal
        process, or audits. We may also disclose information about you if we
        believe that your actions are inconsistent with our {termsLink}, with
        our guidelines and policies, or if necessary to protect the rights,
        property, safety of, or to prevent fraud or abuse of CTIA Certification
        or others.
      </p>
      <h4>
        <i>3.4 Other Situations</i>
      </h4>
      <p>
        We may disclose personal data about you to third parties with your
        consent, which may be obtained in writing, online, or orally. We may
        also share your personal data with third parties when you direct us to
        do so or when you use our services to intentionally interact with third
        parties. Use of the information you agree to share will be subject to
        those third parties’ separate privacy policies.
      </p>
      <h2>4. ADDITIONAL DETAILS</h2>
      <h4>
        <i>4.1 Security</i>
      </h4>
      <p>
        We employ administrative, physical, and technical controls designed to
        appropriately protect your information that is under our control and
        that we process on your behalf from unauthorized access, collection,
        use, disclosure, copying, modification, or disposal both during
        transmission and once we receive it through various measures including
        through the use of secure servers, access restriction, employee
        training, firewalls and other network protections. However, no method of
        transmission over the internet or method of electronic storage is
        completely secure.
      </p>
      <p>
        Where you have a password, which enables you to use our services, you
        are responsible for keeping this password complex, secure, and
        confidential.
      </p>
      <h4>
        <i>4.2 Data Retention</i>
      </h4>
      <p>
        We may delete your account if at any time you cease to be employed by
        the company identified as part of your registration; or you violate
        either the {termsLink} or any other Database policies or guidelines. If
        you have any questions regarding the deletion of your account you may
        contact us at {emailLink}.
      </p>
      <p>
        We retain certain information that we collect from you while you are a
        user of the Database, and in certain cases where you have deleted your
        account, for the following reasons: so you can use our Database; to
        ensure that we do not communicate with you if you have asked us not to;
        to better understand the traffic to our Database so that we can provide
        all users with the best possible experience; to detect and prevent abuse
        of our Database, illegal activities, and breaches of our policies and to
        comply with applicable legal, tax, or accounting requirements. When we
        have no ongoing legitimate business need to access your information, we
        will either delete or anonymize it.
      </p>
      <h4>
        <i>4.3 Policy Scope</i>
      </h4>
      <p>
        This Privacy Policy does not apply to information that you provide to
        third parties, such as other users of the Database, and others with whom
        you may share information about you.
      </p>
      <h4>
        <i>4.4 Revisions To This Policy</i>
      </h4>
      <p>
        We may modify this Privacy Policy from time to time. When we do, we will
        provide notice to you by publishing the most current version and
        revising the date at the top of this page. If we make any material
        changes to this Privacy Policy, we will provide additional notice to
        you, such as by sending you an email or displaying a prominent notice on
        our Database. In any case, the new Privacy Policy will be effective upon
        posting, and your continued use of the Database will indicate your
        consent to all the updated terms and conditions of the then current
        Privacy Policy.
      </p>
      <h2>5. YOUR CHOICES</h2>
      <h4>
        <i>5.1 Your Choices: Account Information</i>
      </h4>
      <p>
        You may update or correct your account information by editing your
        account settings or by sending a request to {emailLink}.
      </p>
      <h4>
        <i>5.2 Your Choices: Cookies</i>
      </h4>
      <p>
        We may use and allow others to use cookies, web beacons, device
        identifiers, and other technologies to collect information about your
        use of the Database and other websites and online services. You may
        disable the cookie function, by selecting the appropriate setting in
        your browser. Please note that disabling cookies may result in our
        Database or its features not functioning properly.
      </p>
      <h2>6. CONTACT US</h2>
      <p>
        If you have any questions about this Privacy Policy or how we use your
        information, please contact us at {emailLink}.
      </p>
    </div>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps, {
  resetCertProgramStatus
})(Policy);
