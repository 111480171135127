import React, { useState } from "react";
import { IconLabel, Drawer } from "ctia-ui";
import BipolarBadge from "components/Badges/BipolarBadge";
import CompanyUsers from "containers/ControlPanel/CompanyUsers";
import ContactLink from "containers/ControlPanel/Orgs/ServiceCenter/ContactLink";
import EditOperator from "containers/ControlPanel/Orgs/Operators/EditOperator";
import DisableOrg from "containers/ControlPanel/Orgs/DisableOrg/DisableOrg";
import { getActiveProgramNames } from "helpers/OrgHelper";

const OrgOperatorRow = ({ item }) => {
  const [showBottomDrawer, setShowBottomDrawer] = useState(false);
  const toggleBottomDrawer = () => setShowBottomDrawer(!showBottomDrawer);
  const status = item.active ? "ACTIVE" : "DISABLED";
  const progList = getActiveProgramNames(item.companycerttypeslist);

  return (
    <tr>
      <td>
        <EditOperator id={item.id} name={item.name} />
        <div>
          <small className="block-left-offset1">
            <strong>
              <span
                className="glow-link cursor-pointer"
                onClick={toggleBottomDrawer}
              >
                <IconLabel awesomeIcon="users" label="View All Users" />
              </span>
            </strong>
          </small>
        </div>
      </td>
      {/* Cert Programs */}
      <td>{progList}</td>
      {/* Contact */}
      <td>
        <ContactLink name={item.contactname} email={item.email} />
      </td>
      <td>
        <BipolarBadge label={status} positive={item.active === 1} />
      </td>
      <td>
        <DisableOrg org={item} />
        <Drawer
          position="bottom"
          show={showBottomDrawer}
          title="View Operator Users"
          toggleDrawer={toggleBottomDrawer}
          content={
            <CompanyUsers
              companyId={item.id}
              companyName={item.name}
              flagCode={item.country}
            />
          }
          height="400"
        />
      </td>
    </tr>
  );
};

export default OrgOperatorRow;
