import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import { Bubble } from "ctia-ui";
import ReqLink from "components/RequestsLink";
import BatteryApproveButton from "containers/request/battery/BatteryApprove/BatteryApproveButton";
import {
  getRequestTypeTitle,
  getStatusTitle,
  getTransPrefix
} from "helpers/RequestHelper";
import { fetchBatteryForApproveRequests } from "actions/battery/batteryRequests";
import { clearRequests } from "actions/requests";

class BatteryApproveList extends Component {
  componentDidMount() {
    this.props.fetchBatteryForApproveRequests();
  }

  componentWillUnmount() {
    this.props.clearRequests();
  }

  renderRows() {
    return _.map(this.props.requests.approveList, req => {
      const type = getRequestTypeTitle(req.requesttypeid);
      const status = getStatusTitle(req.requeststatusid);
      const siteModelName = req.devicemodel.id
        ? req.devicemodel.modelnumber
        : req.manufsite.name;
      const subType = getTransPrefix(req.formatedtransactionnumber);

      return (
        <tr key={req.id}>
          <td>
            <ReqLink
              linkTitle={req.formatedtransactionnumber}
              requestId={req.id}
              subType={subType}
            />
          </td>
          <td>{req.vendorname}</td>
          <td>{siteModelName}</td>
          <td>{req.ieeetypeid}</td>
          <td>{type}</td>
          <td>{status}</td>
          <td>
            <BatteryApproveButton
              id={req.id}
              request={req}
              certType={subType}
            />
            <BatteryApproveButton
              id={req.id}
              request={req}
              certType={subType}
              withdraw={true}
            />
          </td>
        </tr>
      );
    });
  }

  render() {
    const { requests } = this.props;

    if (_.isEmpty(requests)) return null;

    if (_.isEmpty(requests.approveList)) {
      return <Bubble type="info">No requests waiting for approval.</Bubble>;
    }

    return (
      <div>
        <Bubble>
          Below is a list of the requests that are complete and waiting for
          approval.
        </Bubble>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>Request ID</th>
              <th>Vendor</th>
              <th>Model/Site Name</th>
              <th>IEEE</th>
              <th>Request Type</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </Table>
      </div>
    );
  }
}

function mapStateToProps({ requests }) {
  return { requests };
}

export default connect(mapStateToProps, {
  fetchBatteryForApproveRequests,
  clearRequests
})(BatteryApproveList);
