import _ from "lodash";
import React, { useEffect, useState } from "react";
import FormElement from "components/FormElement";

const noneSelected = "Please select one certification or recognition type.";

export const CertificationTypeSelector = ({
  list,
  selectedCertTypes = [],
  validationTrigger = true,
  onChangeHandler
}) => {
  const [certValidState, setCertValidState] = useState(null);
  const [certValidMsg, setCertValidMsg] = useState("");

  useEffect(() => {
    if (validationTrigger && _.isEmpty(selectedCertTypes)) {
      setCertValidState("error");
      setCertValidMsg(noneSelected);
    } else {
      setCertValidState(null);
      setCertValidMsg("");
    }
  }, [validationTrigger, selectedCertTypes]);

  return (
    <FormElement
      type="toggleButtonGroup"
      id="recognitionType"
      label="Select Recognition Type"
      listToggled={selectedCertTypes}
      onChange={onChangeHandler}
      list={list}
      validationMessage={certValidMsg}
      validationState={certValidState}
    />
  );
};
