import React from "react";
import { connect } from "react-redux";
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import { IconLabel } from "ctia-ui";
import { modeTypes } from "helpers/AppHelper";
import { toggleViewMode } from "actions/app";

const ViewToggle = ({ app, user, toggleViewMode }) => {
  if (user.isLab) return null;

  const modeId =
    app.compactView === true ? modeTypes.compact : modeTypes.detailed;

  return (
    <ButtonToolbar>
      <ToggleButtonGroup
        type="radio"
        name="viewToggle"
        defaultValue={modeId}
        onChange={toggleViewMode}
      >
        <ToggleButton value={1}>
          <IconLabel awesomeIcon="compress" label="Compact View" />
        </ToggleButton>
        <ToggleButton value={2}>
          <IconLabel awesomeIcon="search-plus" label="Detailed View" />
        </ToggleButton>
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps, { toggleViewMode })(ViewToggle);
