import React from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import { getTestPassedTitle } from "helpers/RequestTestsHelper";
import { formatDate } from "helpers/DateHelper";
import UploadedResults from "containers/testResult/UploadedResults";
import { downloadReportEPR } from "actions/battery/eprRequest";

const UnderReviewViewEPR = ({
  show,
  testModule,
  request,
  downloadReportEPR
}) => {
  if (!show) return null;

  const startDate = formatDate(testModule.actualstartdate);
  const endDate = formatDate(testModule.actualenddate);

  return (
    <div>
      <Table responsive className="ctia-table">
        <thead>
          <tr>
            <th>Test Result</th>
            <th>Revision Number</th>
            <th>Test Start Date</th>
            <th>Test End Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{getTestPassedTitle(testModule.requesttestpassedstatusid)}</td>
            <td>{request.revision}</td>
            <td>{startDate}</td>
            <td>{endDate}</td>
          </tr>
        </tbody>
      </Table>
      <UploadedResults
        reports={testModule.reports}
        downloadHandler={downloadReportEPR}
      />
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, { downloadReportEPR })(
  UnderReviewViewEPR
);
