import { connect } from "react-redux";
import React from "react";
import FormElement from "components/FormElement";
import { formatName } from "helpers/StringHelper";

const getModelName = request => {
  if (request.isAuditRequest) return request.manufSite.name;
  if (request.modelName) return request.modelName;

  return request.model.modelnumber;
}

const RequestSubmitter = ({ request }) => {
  if (!request || !request.id) return null;

  const { tn, submitter } = request;
  const modelName = getModelName(request);
  const { companyname, email, fullname } = submitter;
  const submitterName = formatName(fullname);
  const vendor = companyname || "(no vendor name)";
  const emailData = { tn, email, modelName, vendor, submitterName };

  return (
    <FormElement
      type="emailLink"
      id="reqSubmitter"
      label="Submitter"
      value={emailData}
      useSubmitterNameAsTitle={true}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(RequestSubmitter);
