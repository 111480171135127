// ES6 / Helper
import _ from "lodash";

// React / Redux
import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

// UI Imports
import {
  Bubble,
  CounterLabel,
  Pager,
  PagerPageSize,
  PagerStatusFilter,
  Divider
} from "ctia-ui";

// Redux actions
import { setPageSize, changeBatteryRequestStatus } from "actions/app";
import {
  getApprovedBatteryDevices,
  setSearchTerm,
  clearDeviceList
} from "actions/devices";

// Helpers and Dictionaries
import { getCertifiedStatusFilter } from "helpers/ModelHelper";

// Components and Containers
import GoBackButton from "components/form/buttons/GoBackButton";
import RenderOptions from "containers/request/RequestList/RenderOptions";
import CyberTable from "containers/CyberTable";
import BatteryManufSiteTable from "containers/Devices/Battery/BatteryDevices/BatteryManufSiteTable/BatteryManufsiteTable";

class BatteryManufSiteList extends CyberTable {
  componentDidMount() {
    const { devices } = this.props;
    if (
      !devices ||
      !devices.isSAR ||
      !devices.list ||
      _.isEmpty(devices.list)
    ) {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * Handle case where table data has changed because a request was delisted
     * or relisted and we need to refetch to make sure device table reflects
     * accurately.
     */
    if (this.props.devices.isSAR) {
      const oldKeepList = !_.isNull(prevProps.devices)
        ? prevProps.devices.keepList
        : null;
      const keepList = !_.isNull(this.props.devices)
        ? this.props.devices.keepList
        : null;

      if (oldKeepList !== keepList) {
        const { devices } = this.props;
        if (devices && devices.list && !_.isEmpty(devices.list)) {
          this.fetchTableData();
        }
      }
    }
  }

  setPagerSize = size => this.props.setPageSize(size);

  onStatusChange = event => {
    const newStatus = Number(event.target.value);

    // update app filter settings
    this.props.changeBatteryRequestStatus(newStatus);

    // fetch new list (waiting for redux storage update first)
    setTimeout(this.fetchTableData, 150);
  };

  fetchTableData = (
    pageNum = this.startPage,
    statusFilter = this.props.app.batteryRequestStatus.filter,
    pageSize = this.props.app.pageSize
  ) =>
    this.props.getApprovedBatteryDevices(
      {
        ...this.props.devices.searchTerms,
        pageNum,
        pageSize,
        statusFilter,
        isLab: this.props.user.isLab,
        isSupplier: this.props.user.isSupplier,
        currVendorId: this.props.user.companyid,
        fetchAdditionalWithdrawInfo: true
      },
      "sar"
    );

  preventFormSubmit = event => event.preventDefault();

  render() {
    const { app, devices, user } = this.props;
    if (!devices || !devices.isSAR || !devices.list) return null;

    const tableData = this.setTableData(devices);
    const siteStatus = app.batteryRequestStatus.id;

    // Filter out duplicate sites (both IEEE types)
    const uniqueSiteTableData = _.uniqBy(tableData, row => row.manufsiteid);

    // Counter will not work as we are filtering data from backend by key, which means metadata
    // from backend will be incorrect.
    const { page, pages, records } = this.setPagerData(devices);
    const counter = this.setCounter(uniqueSiteTableData, records);
    const statusFilter = getCertifiedStatusFilter(
      devices.searchTerms.certTypes
    );

    return (
      <div>
        <h1>View Authorized Battery Cell Manufacturing Sites</h1>
        <GoBackButton
          label="Return to Search"
          onClickHandler={this.props.clearDeviceList}
        />
        <Bubble>
          Below is a list of the authorized battery cell manufacturing sites.
          Click on the <strong>Site Name</strong> to see all audits conducted.
        </Bubble>
        <Row>
          <div id="request-filter">
            <Col xs={4} md={4}>
              <CounterLabel counter={counter} title="Total records" />
            </Col>
            <Col xs={8} md={4}>
              <PagerPageSize
                size={app.pageSize}
                onChangeHandler={this.onChangePageSize}
                options={[50, 100]}
                showAll={true}
              />
            </Col>
            <Col xs={12} md={4}>
              <PagerStatusFilter
                status={siteStatus}
                statusOptions={<RenderOptions options={statusFilter} />}
                onStatusChange={this.onStatusChange}
              />
            </Col>
          </div>
        </Row>
        <hr />
        <BatteryManufSiteTable
          isStaffuser={user.isStaff}
          requestReloadTable={this.fetchTableData}
          tableData={uniqueSiteTableData}
        />
        <Divider />
        <Pager moveTo={this.fetchTableData} totalPages={pages} page={page} />
      </div>
    );
  }
}

function mapStateToProps({ app, devices, user }) {
  return { app, devices, user };
}

export default connect(mapStateToProps, {
  setPageSize,
  setSearchTerm,
  changeBatteryRequestStatus,
  getApprovedBatteryDevices,
  clearDeviceList
})(BatteryManufSiteList);
