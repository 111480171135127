import React from "react";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { WithModal } from "ctia-ui";
import AgreementModal from "components/LicenseAgreementModal";
import { downloadAgreementBlank } from "actions/agreement";

class AgreementPreview extends WithModal {
  onPrintClick = () => {
    return this.props.downloadAgreementBlank(this.props.user.company);
  };

  render() {
    return (
      <div>
        <Alert bsStyle="warning">
          Click{" "}
          <span
            onClick={this.handleModal}
            className="agr-link cursor-pointer glow-link"
          >
            here
          </span>{" "}
          if you would like to review the certification license agreement terms
          and conditions before submitting your certification request. You will
          see the license agreement when submitting your request and will be
          asked to agree to its terms and conditions before the device can be
          certified.
        </Alert>
        <AgreementModal
          show={this.state.showModal}
          modalHandler={this.handleModal}
          downloadHandler={this.onPrintClick}
          certProgram={this.props.certProgram}
        />
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  downloadAgreementBlank
})(AgreementPreview);
