import _ from "lodash";
import React, { useState } from "react";
import { Bubble, Drawer } from "ctia-ui";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import AddNewSite from "containers/request/battery/NonSarSites/AddNewSite";
import { handleCheckboxGroupChange } from "actions/request";
import { updateNonSarSiteName, deleteNonSarSite } from "actions/sites";
import DrawerSiteForm from "containers/request/battery/NonSarSites/DrawerSiteForm";
import { addExtraCommaSpaces } from "helpers/StringHelper";

const validationMsg =
  "You must select at least one manufacturing site or add a new site.";
const emptyListMsg =
  "No manufacturing sites found. Click a button below to add a new site entry.";

// Note: need to keep request as prop here, w/o it component won't update linked sites
const NonSarSites = ({
  request,
  sites,
  handleCheckboxGroupChange,
  updateNonSarSiteName,
  deleteNonSarSite
}) => {
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [siteToEdit, setSiteToEdit] = useState(null);
  const [errorState, setErrorState] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  if (!sites) return "Loading...";

  const toggleEditDrawer = id => {
    setShowEditDrawer(!showEditDrawer);
    const siteId = _.isNumber(id) ? id : null;
    setSiteToEdit(siteId);
  };

  const someChecked = _.some(sites.nonSarSites, "checked");
  const isEmptyList = !sites.nonSarSites || _.isEmpty(sites.nonSarSites);

  const renderInfoMsg = () => {
    let msg;
    if (isEmptyList) {
      msg = emptyListMsg;
    } else if (!someChecked) {
      msg = validationMsg;
    }

    if (msg) {
      return (
        <Bubble icon="alert" type="warning">
          {msg}
        </Bubble>
      );
    }
  };

  const getMessage = () => {
    if (errorState === "error") {
      return errorMessage;
    }

    return "";
  };

  const deleteSite = siteId => {
    // reset previous message if any
    setErrorState(null);
    setErrorMessage("");

    return deleteNonSarSite(siteId)
      .then(res => {
        return res;
      })
      .catch(err => {
        const formattedMsg = addExtraCommaSpaces(err);

        setErrorState("error");
        setErrorMessage(formattedMsg);

        return Promise.reject(formattedMsg);
      });
  };

  return (
    <>
      {renderInfoMsg()}
      <FormElement
        type="checkboxList"
        id="linkedManufsites"
        label="Manufacturing Site(s)"
        onChange={handleCheckboxGroupChange.bind(this, sites.nonSarSites)}
        list={sites.nonSarSites}
        editable={true}
        inline={false}
        show={!isEmptyList}
        editHandler={el => {
          toggleEditDrawer(el);
        }}
        removeHandler={el => deleteSite(el.value)}
        validationState={errorState}
        validationMessage={getMessage()}
      />
      <Drawer
        position="right"
        show={showEditDrawer}
        title="Edit Site Name"
        toggleDrawer={toggleEditDrawer}
        content={
          <DrawerSiteForm
            toggleDrawer={toggleEditDrawer}
            actionOnSubmit={updateNonSarSiteName}
            targetId={siteToEdit}
          />
        }
        width={350}
      />
      <AddNewSite />
    </>
  );
};

function mapStateToProps({ request, sites }) {
  return { request, sites };
}

export default connect(mapStateToProps, {
  handleCheckboxGroupChange,
  updateNonSarSiteName,
  deleteNonSarSite
})(NonSarSites);
