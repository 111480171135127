import React from "react";
import { Form } from "ctia-react-bootstrap-v4";

const LabIEEETypeSelectors = ({
  default1625Checked = false,
  default1725Checked = false,
  onChange = () => {},
  requiresIEEE = true
}) => {
  if (!requiresIEEE)
    return (
      <div className="static-form-text">
        Only required for Battery Compliance and/or Site Audit Request
      </div>
    );

  const ieeeTypes = [
    {type: 'ieee', id: 1625, defaultChecked: default1625Checked, value: "1625"},
    {type: 'ieee', id: 1725, defaultChecked: default1725Checked, value: "1725"}
  ];

  return ieeeTypes.map((testingCapability, index) => {
    const {id, defaultChecked, type, value} = testingCapability;

    return (
      <Form.Check
        id={`${type}${index}`}
        defaultChecked={defaultChecked}
        key={index}
        label={value}
        onChange={onChange}
        type="checkbox"
        value={id}
      />
    );
  });
};

export default LabIEEETypeSelectors;