import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getModelName } from "helpers/RequestHelper";
import MainAgreementText from "components/LicenseAgreementContentText";

const LicenseAgreementContent = ({ request, user, agreement }) => {
	const modelName = request ? getModelName(request) : null;
	const manufacturer = agreement ? agreement.manufacturernamewhensigned || agreement.namesigned : user.company;
	const signedDate = request?.completeDate.length
		? moment(request.completeDate).format("MM/DD/YYYY")
		: undefined;

	let licenseAgreementVersionID = 0;
	if(request) {
		licenseAgreementVersionID = request.licenseagreementversionid;
	}

	return (
		<MainAgreementText
			manufacturer={manufacturer}
			signedDate={signedDate}
			modelName={modelName}
			version={licenseAgreementVersionID}
		/>
  	);
};

function mapStateToProps({ request, user, agreement }) {
  return { request, user, agreement };
}

export default connect(mapStateToProps)(LicenseAgreementContent);
