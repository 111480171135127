import React from "react";
import { Component } from 'react';
import { connect } from 'react-redux';

import { environment } from "dictionaries/Env";
import { getSystemInfo } from "actions/system";
import "styles/environmentRibbon.css";

class EnvironmentRibbon extends Component {
	constructor() {
		super();
		this.state = {
			branch: ""
		};
	}

	async componentDidMount() {
		const systemInfo = await getSystemInfo();
    	this.setState({
			branch: systemInfo?.payload?.data?.data?.branch || ""
		})
	}

	onMouseOver(event) {
		event.target.style.display = "none";
	}

	render() {
		if(environment === "production") {
			return (<div></div>)
		} else {
			return (
				<div className = {`environmentRibbon environment ${environment}`} onMouseOver={this.onMouseOver}>
					<span>{environment}</span>
					<span>{this.state.branch}</span>
				</div>
			)
		}
	}
}

export default connect(null, {})(EnvironmentRibbon);