import _ from "lodash";

const wirelessBase = {
  1: {
    label: "4G LTE",
    checked: false,
    disabled: false,
    id: 1,
    sortOrder: 2
  },
  2: {
    label: "Wi-Fi",
    checked: false,
    disabled: false,
    id: 2,
    sortOrder: 3
  },
  3: {
    label: "5G",
    checked: false,
    disabled: false,
    id: 3,
    sortOrder: 1
  }
};

const getBaseValue = () => {
  return {
    1: {
      label: "4G LTE",
      checked: false,
      disabled: false,
      id: 1,
      sortOrder: 2
    },
    2: {
      label: "Wi-Fi",
      checked: false,
      disabled: false,
      id: 2,
      sortOrder: 3
    },
    3: {
      label: "5G",
      checked: false,
      disabled: false,
      id: 3,
      sortOrder: 1
    }
  };
};

export const getWirelessBaseList = () => {
  const vals = _.values(getBaseValue());
  return _.sortBy(vals, ["sortOrder"]);
};

export const formatWirelessList = (list, lte) => {
  // get only ids from band list
  const wrlsIds = _.map(list, "wirelesscapabilityid");

  // iterate default wireless and prepare values
  const wrlsList = _.mapValues(wirelessBase, (value, key) => {
    const numKey = Number(key);
    const newValue = { ...value, id: numKey };
    newValue.checked = _.indexOf(wrlsIds, numKey) > -1;

    // if model is a legacy model and has LTE feature
    newValue.disabled = numKey === 1 && lte === 1;

    return newValue;
  });

  // convert object to array before return
  const unsortedList = _.values(wrlsList);

  return _.sortBy(unsortedList, ["sortOrder"]);
};

export const getWirelessToggled = list => {
  let toggled = [];

  if (!list || !list.length) return toggled;

  _.forEach(list, value => {
    toggled.push(value.wirelesscapabilityid);
  });

  return toggled;
};

export const getModelWireless = list => {
  const checkedWireless = _.filter(list, "checked");
  let arr = [];

  _.forEach(checkedWireless, wireless => {
    arr.push({ id: wireless.id });
  });

  return arr;
};

export const getWirelessAsString = list => {
  let arr = [];

  if (_.includes(list, 3)) arr.push("5G");
  if (_.includes(list, 1)) arr.push("4G LTE");
  if (_.includes(list, 2)) arr.push("Wi-Fi");

  return arr.length > 0 ? arr.join(", ") : "none";
};

export const hasLteSupport = list => list[0].checked;

export const hasWifiSupport = list => list[1].checked;

export const has5gSupport = list => list[2].checked;

export const getToggledWireless = list => {
  return _.map(list, elem => {
    return { wirelesscapabilityid: elem };
  });
};

// @NOTE: was previously used to define calc props here:
// `src\reducers\requests.js -> fetchRequestsForTesting()`
// should be removed if unused in future releases
// export const hasUnverifiedWireless = (
//   wirelessString,
//   lteVerified,
//   wifiVerified
// ) => {
//   const hasLteSupport = wirelessString.indexOf("4G LTE") > -1;
//   const hasWifiSupport = wirelessString.indexOf("Wi-Fi") > -1;

//   const unverifiedLte = hasLteSupport ? lteVerified === 0 : false;
//   const unverifiedWifi = hasWifiSupport ? wifiVerified === 0 : false;

//   return unverifiedLte || unverifiedWifi;
// };

export const validateWireless = request => {
  if (!request) return false;
  return !_.isEmpty(request.wirelessToggled);
};
