import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getModelName } from "helpers/RequestHelper";
import MainAgreementText from "components/LicenseAgreementContentText";

// Battery Agreement (previously - Recognition Agreement) -- modal content
const RecognitionContent = ({ request, user, version }) => {
  const modelName = getModelName(request);
  const manufacturer = request.vendor || user.company;
  const signedDate =
    request && request.isApproved
      ? moment(request.completeDate).format("MM/DD/YYYY")
      : undefined;

  return (
    <MainAgreementText
      manufacturer={manufacturer}
      signedDate={signedDate}
      modelName={modelName}
      version={version}
    />
  );
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(RecognitionContent);
