import React from "react";
import { connect } from "react-redux";
import TransferLabsForm from "containers/users/Users2Labs/TransferLabsForm";

const TransferLabs = ({ users2labs }) => {
  if (users2labs && users2labs.transferFormVisible) {
    return <TransferLabsForm />;
  }

  return null;
};

function mapStateToProps({ users2labs }) {
  return { users2labs };
}

export default connect(mapStateToProps)(TransferLabs);
