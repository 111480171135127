import React from "react";
import { connect } from "react-redux";
import BipolarBadge from "components/Badges/BipolarBadge";
import { downloadInvoiceATL } from "actions/catl";

const CatlInvoice = ({ user, downloadInvoiceATL }) => {
  const downloadHandler = () => downloadInvoiceATL(user);
  const invoiceHandler = user.isCATLFeeExists ? downloadHandler : null;

  const linkStyle = user.isCATLFeeExists ? "glow-link cursor-pointer" : "";
  const feeBadgeProps = user.isCATLFeePaid
    ? { label: "Paid" }
    : { label: "Pending", positive: false };

  return (
    <li>
      <span className={linkStyle} onClick={invoiceHandler}>
        Annual Invoice
      </span>
      <span className="icon-left-offset">
        <BipolarBadge {...feeBadgeProps} />
      </span>
    </li>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  downloadInvoiceATL
})(CatlInvoice);
