import React from "react";
import { connect } from "react-redux";
import BipolarBadge from "components/Badges/BipolarBadge";
import { downloadInvoiceASC } from "actions/asc";

const AscInvoice = ({ user, downloadInvoiceASC }) => {
  const downloadHandler = () => downloadInvoiceASC(user);
  const invoiceHandler = user.ASCAnualLicenseFeeExists ? downloadHandler : null;

  const linkStyle = user.ASCAnualLicenseFeeExists
    ? "glow-link cursor-pointer"
    : "";
  const feeBadgeProps = user.ASCAnualLicenseFeePaid
    ? { label: "Paid" }
    : { label: "Pending", positive: false };

  return (
    <li>
      <span className={linkStyle} onClick={invoiceHandler}>
        Annual Invoice
      </span>
      <span className="icon-left-offset">
        <BipolarBadge {...feeBadgeProps} />
      </span>
    </li>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  downloadInvoiceASC
})(AscInvoice);
