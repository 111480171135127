import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestDescChanges from "components/request/RequestDescChanges";
import RequestEcoModelECO from "containers/request/CRR/RequestEcoModelCRR";
import RequestVoltage from "components/request/RequestVoltage";
import RequestRevision from "components/request/RequestRevision";
import ManufSitesCRR from "containers/request/CRR/ManufSitesCRR";
import ManufSitesReadonlyCRR from "containers/request/CRR/ManufSitesReadonlyCRR";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import RequestParentLink from "components/request/RequestParentLink";
import BatteryParentDetails from "components/request/BatteryParentDetails";
import { setValue, setBatteryRequestPermissions } from "actions/request";
import {
  initEcoCRR,
  mapManufSitesCRR,
  updateDeviceInfoCRR
} from "actions/battery/crrRequest";
import { submitWizardStep } from "actions/wizard";
import { hasStringValue } from "helpers/RequestHelper";
import { getRequestOwner } from "helpers/UsersHelper";

class RequestECOContainerCRR extends CyberComponent {
  modelField = this.props.request.id ? "modelName" : "existedModel";

  componentDidMount() {
    const { request, user } = this.props;
    const ownerId = getRequestOwner(request, user);

    // fetch only for the first time on submissions
    if (!this.validate() && !request.id) {
      this.props.initEcoCRR(request.ieee, ownerId);
    }

    // fetch for existed requests
    if (request.id) {
      this.props
        .initEcoCRR(request.ieee, ownerId)
        .then(() => this.props.mapManufSitesCRR(request.linkedManufsites));
    }

    // set permissions for existed request
    this.props.setBatteryRequestPermissions(request, user);
  }

  onSaveClick = () => {
    this.props.updateDeviceInfoCRR(this.props.request, this.props.sites.list);
  };

  validateModel() {
    return hasStringValue(this.props.request, this.modelField);
  }

  validateRevision() {
    return hasStringValue(this.props.request, "revision");
  }

  validateDesc() {
    return hasStringValue(this.props.request, "descriptionOfChange");
  }

  validateManufSites() {
    const { sites } = this.props;
    return sites && sites.list && _.some(sites.list, "checked");
  }

  validate() {
    const { user } = this.props;

    // don't need to validate if form is non-editable
    if (user.canEditDeviceInfo === false) return true;

    // check if all required fields are valid
    return (
      this.validateModel() &&
      this.validateRevision() &&
      this.validateManufSites() &&
      this.validateDesc()
    );
  }

  render() {
    const { request, user } = this.props;
    const { canEditDeviceInfo } = user;
    const saveHandler = canEditDeviceInfo ? this.onSaveClick : null;

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestParentLink certPrefix="crr" />
          <BatteryParentDetails />
          <hr />
          <RequestManufacturer />
          <RequestEcoModelECO />
          {request[this.modelField] && (
            <>
              <RequestRevision
                fieldId="revision"
                editable={canEditDeviceInfo}
              />
              <RequestDescChanges />
              <RequestVoltage
                fieldId="voltage"
                fieldLabel="Voltage (V)"
                editable={false}
              />
              <RequestVoltage
                fieldId="capacity"
                fieldLabel="Capacity (mAh)"
                editable={false}
              />
              <SubmissionDetails />
              <hr />
              {canEditDeviceInfo ? (
                <ManufSitesCRR />
              ) : (
                <ManufSitesReadonlyCRR />
              )}
            </>
          )}
          <RequestControls isValid={this.validate()} onSave={saveHandler} />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, user, sites }) {
  return { request, user, sites };
}

export default connect(mapStateToProps, {
  initEcoCRR,
  submitWizardStep,
  updateDeviceInfoCRR,
  setValue,
  setBatteryRequestPermissions,
  mapManufSitesCRR
})(RequestECOContainerCRR);
