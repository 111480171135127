import React from "react";
import { connect } from "react-redux";
import { getAscLogoUrl } from "helpers/UrlHelper";
import { fileDownload } from "actions/uploads";

const AscLogoPreview = ({ user, fileDownload, companyId }) => {
  const id = companyId ? companyId : user.companyid;

  if (!id) return null;

  const logoFilename = "ASC_Logo.jpg";
  const logoURL = getAscLogoUrl(id, "big");

  return (
    <li>
      <span
        className="glow-link cursor-pointer"
        onClick={() => fileDownload(logoFilename, logoURL, '', {program: 'ASC'})}
      >
        ASC Logo
      </span>
    </li>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  fileDownload
})(AscLogoPreview);
