import React from "react";
import { connect } from "react-redux";
import PacksReadonly from "containers/request/battery/BatterySupplier/BatteryPackReadonlyBLC";
import SelectBattery from "containers/request/battery/BatterySupplier/SelectBattery";

const BatteryPacks = ({ app, request, user }) => {
  const PackRenderer =
    !(user.isVendor && request.id) && !request.isEco && user.canEditDeviceInfo
      ? SelectBattery
      : PacksReadonly;
  return <PackRenderer />;
};

function mapStateToProps({ app, request, user }) {
  return { app, request, user };
}

export default connect(mapStateToProps)(BatteryPacks);
