import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";

const PaymentCheckNumber = ({ request, handleInputChange }) => {
  if (request.payMethod !== "Check") return null;

  const hasCheckNum =
    request.payCheckNumber && String(request.payCheckNumber).length > 0;
  const validation = {
    message: hasCheckNum ? null : "Check # field allows only digits.",
    state: hasCheckNum ? null : "warning"
  };

  return (
    <FormElement
      type="maskedInput"
      mask="9999999999"
      maskChar={null}
      id="payCheckNumber"
      label="Check #"
      maxLength={10}
      value={request.payCheckNumber}
      onChange={handleInputChange}
      validationMessage={validation.message}
      validationState={validation.state}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  handleInputChange
})(PaymentCheckNumber);
