import React from "react";
import { WithModal } from "ctia-ui";
import AddButton from "components/form/buttons/AddButton";
import AddCompanyModal from "components/Companies/AddCompanyModal/AddCompanyModal";

class AddCompanyButton extends WithModal {
  render() {
    const {
      companyType = "",
      disabled = false,
      knownCompanyValues = {},
      onSuccess = () => {},
      showAddCompanyButton = true,
      ...rest
    } = this.props;

    const handleClick = event => {
      this.handleModal(event);
    };

    return (
      <>
        {showAddCompanyButton &&
          <AddButton
            {...rest}
            disabled={disabled}
            label="Add Company"
            onClick={handleClick}
          />
        }

        <AddCompanyModal
          companyType={companyType}
          modalHandler={handleClick}
          onSuccess={onSuccess}
          show={this.state.showModal}
          values={knownCompanyValues}
        />
      </>
    );
  };
};

export default AddCompanyButton;
