import React from "react";
import { Badge } from "ctia-react-bootstrap-v4";

const IeeeBadge = ({ ieeeType }) => {
  if (!ieeeType) return "n/a";

  const ieeeBadge = ieeeType === 1725 ? "ctia-blue" : "ctia-green";

  return <Badge variant={ieeeBadge}>IEEE {ieeeType}</Badge>;
};

export default IeeeBadge;
