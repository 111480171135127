export const DECLARATION_OF_COMPLIANCE = "complianceDeclarationToggler";
export const RECOGNITION_AGREEMENT = "recognitionAgreementToggler";
export const BATTERY_LICENSE_AGREEMENT = "licenseAgreementToggler";

export const batteryAgreementsTogglers = [
  DECLARATION_OF_COMPLIANCE,
  RECOGNITION_AGREEMENT,
  BATTERY_LICENSE_AGREEMENT,
];

export const countCheckedAgreements = (request) => {
  let counter = 0;

  batteryAgreementsTogglers.forEach((agreement) => {
    if (request[agreement] === true) counter++;
  });

  return counter;
};
