import { certProgramms } from "helpers/AppHelper";
import roles from "dictionaries/UserRoles";

export const cyberProg = "cyber";
export const otaProg = "ota";
export const batteryProg = "battery";
export const dhrProg = "dhr";
export const blcProg = "blc";
export const progOptions = [cyberProg, otaProg, batteryProg, dhrProg, blcProg];

export const progs = {
  [cyberProg]: {
    urlPrefix: {
      [roles.staff]: "/cyber/admin/",
      [roles.accountant]: "/cyber/accountant/"
    },
    id: certProgramms.cyber
  },
  [otaProg]: {
    urlPrefix: {
      [roles.staff]: "/ota/admin/",
      [roles.accountant]: "/ota/accountant/"
    },
    id: certProgramms.ota
  },
  [batteryProg]: {
    urlPrefix: {
      [roles.staff]: "/battery/admin/",
      [roles.accountant]: "/battery/accountant/"
    },
    id: certProgramms.battery
  },
  [dhrProg]: {
    urlPrefix: {
      [roles.staff]: "/dhr/admin/",
      [roles.accountant]: "/dhr/accountant/"
    },
    id: certProgramms.dhr
  },
  [blcProg]: {
    urlPrefix: {
      [roles.staff]: "/blc/admin/",
      [roles.accountant]: "/blc/accountant/"
    },
    id: certProgramms.blc
  }
};
