import _ from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { clearModel } from "actions/model";

// Components imports
import BatteryDeviceHistory from "components/DeviceHistory/BatteryDeviceHistory";
import BLCDeviceHistory from "components/DeviceHistory/BLCDeviceHistory";
import CyberDeviceHistory from "components/DeviceHistory/CyberDeviceHistory";
import DHRDeviceHistory from "components/DeviceHistory/DHRDeviceHistory";
import OTADeviceHistory from "components/DeviceHistory/OTADeviceHistory";

const nonBatteryComponents = {
  BLC: BLCDeviceHistory,
  Cyber: CyberDeviceHistory,
  DHR: DHRDeviceHistory,
  OTA: OTADeviceHistory
};

const defineComponent = certType => {
  const nonBatteryKeys = _.keys(nonBatteryComponents);

  return _.includes(nonBatteryKeys, certType)
    ? nonBatteryComponents[certType]
    : BatteryDeviceHistory;
};

const DeviceHistory = ({
  certificationType,
  dateFrom,
  dateTo,
  modelId,
  modelName,
  requestNumber,
  requestStatusId,
  clearModel
}) => {
  useEffect(() => {
    clearModel();
  }, [certificationType, modelId, clearModel]);

  const Component = defineComponent(certificationType);
  const componentProps = {
    certificationType,
    dateFrom,
    dateTo,
    modelId,
    requestStatusId
  };

  return <Component {...componentProps} />;
};

export default connect(null, {
  clearModel
})(DeviceHistory);
