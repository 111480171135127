import React from "react";
import { connect } from "react-redux";

const BatteryInfoBarContainer = ({ request, wizard }) => {
  if (!request) return null;
  if (request.formSaved) return null;

  // on submissions it doesn't appear on step 1
  if (wizard && wizard.step === 1) return null;

  const InfoBar = wizard.batteryCert.certInfo.infoBar;

  return <InfoBar />;
};

function mapStateToProps({ request, wizard }) {
  return { request, wizard };
}

export default connect(mapStateToProps)(BatteryInfoBarContainer);
