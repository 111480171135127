/* 
    This is a list of form fields that contain only `number` values
    and should be formatted from string to number before sending forward
*/
export default [
  "\\,\\s[eE]xt\\.\\s\\#",
  "\\,\\s[eE]xt\\.",
  "\\,",
  "\\~",
  "\\#",
  "\\*",
  "[eE][xX][tT]\\.:",
  "[eE][xX][tT]\\.\\#",
  "[eE][xX][tT]\\.",
  "[eE][xX][tT]:",
  "[eE][xX][tT]\\s:",
  "[eE][xX][tT]",
  "[eE]xtension",
  "[eE][xX][tT]\\.:",
  "[eE][xX][tT]\\s-",
  "[xX]:",
  "[xX]\\.",
  "[xX]"
];
