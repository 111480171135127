import React from 'react';
import { connect } from 'react-redux';
import CertCategories from 'containers/request/Initial/Steps/Vendor/DeviceInfo/CertCategories';

const LabCertCats = ({ request }) => {
  const { isEco, isPaid, certCategory } = request;
  const certValidate = () => {
    // ECO is readonly, not need to validate
    if (isEco) return null;
    return certCategory > 0 ? null : 'error';
  };
  const readonly = isEco ? true : isPaid;

  return (
    <CertCategories
      readonly={readonly}
      editable={!readonly}
      validate={certValidate}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(LabCertCats);
