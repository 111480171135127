import React from "react";
import { connect } from "react-redux";
import { Col, Jumbotron } from "react-bootstrap";
import { DownloadButton } from "ctia-ui";
import { downloadOtaReport } from "actions/tests";

const OtaReportDownload = ({
  downloadOtaReport,
  report,
  request,
  testModule
}) => {
  return (
    <Col xs={4} key={report.id}>
      <Jumbotron className="jumbo-block text-center">
        <h4 className="jumbo-block-header">{report.title}</h4>
        <DownloadButton
          size="small"
          action={() => downloadOtaReport(report, request, testModule)}
        />
      </Jumbotron>
    </Col>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  downloadOtaReport
})(OtaReportDownload);
