import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, FormGroup, FormControl } from "react-bootstrap";
import { DeleteButton, DownloadButton } from "ctia-ui";
import InputFile from "components/form/InputFile";
import UploadInstructions from "components/UploadInstructions";
import {
  downloadOtaReport,
  onTestReportTitleChange,
  onTestReportSelect
} from "actions/tests";
import { deleteTestReportOta } from "actions/uploads";
import { hasOtaReports, countOtaReports } from "helpers/RequestTestsHelper";
import { cutFileExtension } from "helpers/StringHelper";

class LabResultUploadOta extends Component {
  onSelectFile = (index, event) => {
    // handle file change action
    const {
      onTestReportSelect,
      onTestReportTitleChange,
      testModule
    } = this.props;
    onTestReportSelect(index, testModule, event);

    // set file title if it is empty
    const currentTitle = String(testModule.reports[index].title);

    if (_.isEmpty(currentTitle)) {
      // set file description as file name by default (w/o file extension)
      const fileName = cutFileExtension(event.target.files[0].name);

      // create event for changing description action manually
      const titleChangeEvent = {
        target: { id: "reports", value: fileName }
      };

      onTestReportTitleChange(index, testModule, titleChangeEvent);
    }
  };

  onDownloadClick = report => {
    const { downloadOtaReport, request, testModule } = this.props;
    return downloadOtaReport(report, request, testModule);
  };

  render() {
    const {
      show,
      onTestReportTitleChange,
      testModule,
      deleteTestReportOta
    } = this.props;

    if (!show) return null;

    const results = testModule.reports;
    const hasReports = hasOtaReports(results);
    const reportsCount = countOtaReports(results);

    return (
      <div>
        <h3>Upload Results</h3>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>Document Title</th>
              <th>Select Document</th>
              {hasReports && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {results.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <FormGroup
                      controlId={`reports-${testModule.id}`}
                      bsSize="small"
                    >
                      <FormControl
                        type="text"
                        value={item.title}
                        onChange={onTestReportTitleChange.bind(
                          this,
                          idx,
                          testModule
                        )}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup controlId={`reports-${testModule.id}`}>
                      <InputFile
                        id={`reports-${testModule.id}-${idx}`}
                        name={`reports-${testModule.id}`}
                        onChange={this.onSelectFile.bind(this, idx)}
                        file={item.file}
                        disabled={false}
                      />
                    </FormGroup>
                  </td>
                  {hasReports && (
                    <td>
                      {item.documentid > 0 && reportsCount > 1 && (
                        <DeleteButton
                          size="small"
                          action={() => deleteTestReportOta(item)}
                          customClassName="button-right-offset"
                        />
                      )}
                      {item.documentid > 0 && (
                        <DownloadButton
                          size="small"
                          action={this.onDownloadClick.bind(this, item)}
                        />
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <UploadInstructions />
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  onTestReportTitleChange,
  onTestReportSelect,
  deleteTestReportOta,
  downloadOtaReport
})(LabResultUploadOta);
