// React/Redux imports
import _ from "lodash";
import React, { useEffect, useState } from "react";
import connect from "react-redux/lib/connect/connect";

// Redux Actions
import { delistDevice, relistDevice } from "actions/devices";

// Dictionary and Helper imports
import { formatDate } from "helpers/DateHelper";
import { isRequestWithdrawn } from "helpers/RequestHelper";

import WithdrawDeviceRecognitionModal from "components/DeviceRecognition/WithdrawModalAndButton/WithdrawDeviceRecognitionModal";

// UI imports
import { IconLabel, LoaderButton, TooltipBase } from "ctia-ui";

/**
 * Note: The modal performs the api call to the withdraw/unwithdraw
 * and delist/relist functions. Unwithdrawing is hardcoded to unwithdraw
 * a device
 */

const BatteryDeviceRowWithdrawButton = ({
  deviceModelId = null,
  modalInfo,
  onError = () => {},
  onSuccess = () => {},
  requestId = null,
  requestIdsOfChildren = [],
  requestStatusId = null,
  requestTypeId = null,
  selectedCertType = null,
  shouldDelistOrRelistOnSuccess = false,
  withdrawnAt,
  withdrawReason
}) => {
  const [componentState, setComponentState] = useState({
    isWithdrawn: isRequestWithdrawn(requestStatusId),
    showWithdrawRecognitionModal: false
  });

  useEffect(() => {
    setComponentState(state => {
      return {
        ...state,
        isWithdrawn: isRequestWithdrawn(requestStatusId)
      };
    });
  }, [requestStatusId]);

  function withdrawIsRequest() {
    return !_.isNull(requestId);
  }

  function withdrawTypeIsSAR() {
    if (!_.isNull(selectedCertType))
      return selectedCertType.toLowerCase() === "sar";
    else
      return !_.isUndefined(modalInfo.manufsiteid);
  }

  const showModal = (show = true) => {
    setComponentState(state => {
      return {
        ...state,
        showWithdrawRecognitionModal: show
      };
    });
  };

  const handleSuccess = () => {
    onSuccess();
    if (showModal) showModal(false);
  };

  const withdrawReasonText = (
    <IconLabel label="Withdrawal Reason" awesomeIcon="question" />
  );
  const withdrawText = <IconLabel label="Withdraw" awesomeIcon="ban" />;
  const unWithdrawText = <IconLabel label="Unwithdraw" awesomeIcon="undo" />;

  const isSar = withdrawTypeIsSAR();
  const isRequest = withdrawIsRequest();

  const withdrawType = isSar && !isRequest
    ? "Authorization"
    : "Request";

  return (
    <>
      {componentState.showWithdrawRecognitionModal && (
        <WithdrawDeviceRecognitionModal
          deviceModelId={deviceModelId}
          isCurrentlyWithdrawn={componentState.isWithdrawn}
          isRequest={isRequest}
          onClose={() => showModal(false)}
          onError={onError}
          onSuccess={handleSuccess}
          requestId={requestId}
          requestIdsOfChildren={requestIdsOfChildren}
          requestStatusId={requestStatusId}
          requestTypeId={requestTypeId}
          selectedCertType={selectedCertType}
          shouldDelistOrRelistOnSuccess={shouldDelistOrRelistOnSuccess}
          showModal={componentState.showWithdrawRecognitionModal}
          requestData={modalInfo}
        />
      )}
      <div>
        {componentState.isWithdrawn && (
          <div>{ withdrawType } Withdrawn on {formatDate(withdrawnAt)}</div>
        )}
        <div>
          {componentState.isWithdrawn && (
            <TooltipBase tipText={withdrawReason} tipPlacement="left">
              <LoaderButton
                bsStyle="info"
                bsSize="xsmall"
                text={withdrawReasonText}
                className="icon-right-offset"
              />
            </TooltipBase>
          )}
          <LoaderButton
            bsStyle={componentState.isWithdrawn ? "warning" : "danger"}
            bsSize="xsmall"
            text={componentState.isWithdrawn ? unWithdrawText : withdrawText}
            onClick={() => showModal(true)}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  delistDevice,
  relistDevice
})(BatteryDeviceRowWithdrawButton);
