import { getSelectedManufSitesIds } from "helpers/ManufSiteHelper";
import { formatDate } from "helpers/DateHelper";
import { makeSimpleArgs } from "helpers/ArgsHelper";
import { getCertDataByFieldValue } from "helpers/AppHelper";
import { filterCheckedOnlyValues } from "helpers/ArrayHelper";

export const prepareInitialArgsHRR = request => {
  const basicFields = {
    billingid: Number(request.billingId),
    pocid: Number(request.pocId),
    purchaseorder: request.billingOrderNum,
    requesttypeid: 1,
    primarylabid: request.cdmaLab,
    revision: request.revision,
    requestmanufsites: getSelectedManufSitesIds(request.manufSites),
    ieeetypeid: request.ieee
  };

  if (request.existedModelId) {
    return {
      body: makeSimpleArgs({
        ...basicFields,
        devicemodelid: request.existedModelId
      }),
      url: "hrr/requests"
    };
  }

  return {
    body: makeSimpleArgs({
      requests: [basicFields],
      modelnumber: request.modelName
    }),
    url: "hrr/devicemodels"
  };
};

export const prepareEcoArgsHRR = request => {
  // if non SAR sites list goes untouched - it should be inherited from parent instead
  const requestmanufsites =
    request.linkedManufsites && request.linkedManufsites.length > 0
      ? getSelectedManufSitesIds(request.linkedManufsites)
      : request.parentRequest.requestmanufsites.map(site => {
          return { manufsiteid: site.manufsiteid };
        });

  return makeSimpleArgs({
    requesttypeid: 2,
    pocid: Number(request.pocId),
    primarylabid: request.cdmaLab,
    revision: request.revision,
    descriptionofchange: request.descriptionOfChange,
    requestmanufsites,
    parentid: request.parentRequest.id,
    devicemodelid: request.parentRequest.devicemodelid,
    ieeetypeid: request.ieee
  });
};

export const getRequestFieldsForTestingHRR = ({
  id,
  revision,
  crslversion
}) => {
  return makeSimpleArgs({
    requestId: id,
    revision,
    crslversion
  });
};

export const getTestFieldsHRR = testModule => {
  const {
    id,
    actualstartdate,
    actualenddate,
    requesttestpassedstatusid
  } = testModule;

  // @NOTE: All battery types require testing dates range
  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return {
    id,
    ...dates,
    requesttestpassedstatusid
  };
};

export const prepareModelArgsHRR = request => {
  const [capacityrateid, capacity] = request.capacity;
  return makeSimpleArgs({
    modelId: request.model.id,
    modelnumber: request.modelName,
    devicemodelbatterycomponent: {
      capacity,
      capacityrateid
    }
  });
};

export const getDeviceFieldsForTestingHRR = request => {
  const [capacityrateid, capacity] = request.capacity;
  return makeSimpleArgs({
    modelId: request.model.id,
    devicemodelbatterycomponent: {
      capacity,
      capacityrateid
    }
  });
};

export const prepareRequestArgs = (request, sites) => {
  const {
    id,
    revision,
    descriptionOfChange,
    crslversion,
    partsComponents
  } = request;
  const requestmanufsites = getSelectedManufSitesIds(sites);
  const linkedrequests = getLinkedCells(request);
  return {
    id,
    revision,
    crslversion,
    descriptionOfChange,
    requestmanufsites,
    linkedrequests,
    partscomponentsnote: partsComponents,
    urlPrefix: "hrr"
  };
};

const getDeviceTypesHRR = (request, certTypes) => {
  const devicemodelcerttypes = [];
  const [pack, adapter] = request.deviceTypes;

  if (pack.checked) {
    const prrType = getCertDataByFieldValue(certTypes, "name", "PRR");
    devicemodelcerttypes.push({ certtypeid: prrType.id });
  }

  if (adapter.checked) {
    const arrType = getCertDataByFieldValue(certTypes, "name", "ARR");
    devicemodelcerttypes.push({ certtypeid: arrType.id });
  }

  return devicemodelcerttypes;
};

const getLinkedNonSarSites = request => {
  if (request && request.linkedManufsites) {
    return filterCheckedOnlyValues(
      request.linkedManufsites,
      "manufsiteid",
      "value"
    );
  }

  return [];
};

const getLinkedCells = request => {
  // pass a list if device type is Pack
  const [pack] = request.deviceTypes;

  if (request && (request.isPack || pack.checked) && request.cellSuppliers) {
    return request.cellSuppliers.map(cell => {
      return { linkedrequestid: cell.linkedrequestid };
    });
  }

  return [];
};

const getCapacityValueHRR = request => {
  const [pack] = request.deviceTypes;

  if (pack.checked) {
    const [capacityrateid, capacity] = request.capacity;

    return {
      capacity,
      capacityrateid: capacityrateid === "" ? 1 : capacityrateid
    };
  }

  return {};
};

export const prepareTestResultsArgs = (request, testModule, certTypes) => {
  const devicemodelcerttypes = getDeviceTypesHRR(request, certTypes);
  const requestmanufsites = getLinkedNonSarSites(request);
  const linkedrequests = getLinkedCells(request);
  const testArgs = getTestFieldsHRR(testModule);

  const { model, crslversion, revision } = request;
  const devicemodel = {
    id: model.id,
    devicemodelcerttypes
  };

  if (request.isInitial) {
    devicemodel.devicemodelbatterycomponent = getCapacityValueHRR(request);
  }

  const args = {
    devicemodel,
    partscomponentsnote: request.partsComponents,
    crslversion,
    linkedrequests, // linked Cells (CRRs)
    revision,
    requesttests: [
      {
        ...testArgs
      }
    ],
    requestmanufsites // linked non SAR sites
  };

  return makeSimpleArgs(args);
};
