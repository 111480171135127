import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Label } from "react-bootstrap";
import { keepDeviceList } from "actions/devices";
import { clearModel } from "actions/model";
import { updateUserLocation } from "actions/user";
import {
  fetchCertifiedAuditVersions,
  fetchCertifiedDeviceVersions
} from "actions/battery/batteryRequests";
import { getDeviceRedirectUrl } from "helpers/UrlHelper";
import { types } from "helpers/BatteryCertHelper";
import { requestStatuses, getTransPrefix } from "helpers/RequestHelper";
import BatteryModel from "containers/Devices/Battery/BatteryDeviceDetails/BatteryModelDetails";
import GoBackButton from "components/form/buttons/GoBackButton";

class BatteryCertifiedDevice extends Component {
  fetchDevices = () => {
    const { devices, app, history, match } = this.props;
    const { type } = match.params;

    if (devices === null || (type === "audit" && devices.list === null)) {
      const redirectUrl = getDeviceRedirectUrl(app);
      return setTimeout(() => history.push(redirectUrl), 100);
    }

    this.fetchByTypeAndId();
  };

  fetchByTypeAndId = id => {
    const { app, devices, match, user } = this.props;
    const _id = id || match.params.id;
    const certTypes = devices.searchTerms.certTypes;
    const statuses =
      user.isLab || user.isStaff
        ? [requestStatuses.approved, requestStatuses.requestWithdrawn]
        : app.batteryRequestStatus.filter;

    return match.params.type === "audit"
      ? this.props.fetchCertifiedAuditVersions(_id, statuses)
      : this.props.fetchCertifiedDeviceVersions(_id, certTypes, statuses);
  };

  componentDidMount() {
    this.props.updateUserLocation(this.props.history.location.pathname);
    this.fetchDevices();
  }

  componentWillReceiveProps(nextProps) {
    const newId = nextProps.match.params.id;
    const { match } = this.props;

    if (newId !== match.params.id) {
      this.fetchByTypeAndId(newId);
    }
  }

  componentWillUnmount() {
    this.props.clearModel();
  }

  backToList = () => {
    const { app, history, keepDeviceList, clearModel } = this.props;

    const redirectUrl = getDeviceRedirectUrl(app);

    // clear redux data:
    clearModel();

    // don't clear device list
    keepDeviceList(true);

    // force redirection:
    history.push(redirectUrl);
  };

  getPageTitle() {
    const selectedCertTypes = this.props.devices.searchTerms.certTypes;

    let title;
    if (_.includes(selectedCertTypes, types.ACR)) {
      title = "Certified Device";
    } else if (_.includes(selectedCertTypes, types.BSC)) {
      title = "Certified Battery System Detail";
    } else if (_.includes(selectedCertTypes, types.SAR)) {
      title = "Authorized Battery Cell Manufacturing Site Detail";
    } else {
      title = "Recognized Subsystem Detail";
    }

    return title;
  }

  render() {
    const { model, devices, match } = this.props;

    if (!model || !devices) return null;
    if (model && !model.versions) return null;

    const { id } = match.params;
    const deviceInfo = devices.list.find(m => m.id === parseInt(id));
    const { modelnumber, name, vendorproductname } = deviceInfo;
    const modelInfo = model.versions[0];
    const cert = modelInfo.formatedtransactionnumber
      ? getTransPrefix(modelInfo.formatedtransactionnumber).toUpperCase()
      : "SAR";
    const certType = types[cert];

    return (
      <div>
        <div className="block-top-offset">
          <h1>
            {this.getPageTitle()}{" "}
            <Label>{modelnumber || name || vendorproductname}</Label>
          </h1>
          <GoBackButton
            label="Return to List"
            onClickHandler={this.backToList}
          />
          <BatteryModel
            certType={certType}
            deviceInfo={deviceInfo}
            modelInfo={modelInfo}
            reloadTable={this.fetchDevices}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ app, model, user, devices }) {
  return { app, model, user, devices };
}

export default connect(mapStateToProps, {
  keepDeviceList,
  clearModel,
  fetchCertifiedAuditVersions,
  fetchCertifiedDeviceVersions,
  updateUserLocation
})(BatteryCertifiedDevice);
