import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import FormElement from "components/FormElement";
import {
  formatAddressList,
  showAddressDetails,
  onSelectPoc
} from "helpers/BillingHelper";
import { handleInputChange, cleanBillingAddressInfo } from "actions/request";

class BillingAddressSelector extends CyberComponent {
  render() {
    const {
      show,
      request,
      toggleSelector,
      handleInputChange,
      cleanBillingAddressInfo,
      isEditable
    } = this.props;

    if (!show) return null;

    const {
      isLegacy,
      billingCompanyId,
      billingCompanyName,
      billingAddress,
      billingAddressId,
      billingAddresses
    } = request;

    const showLegacyBilling =
      isLegacy &&
      (!billingCompanyId ||
        (_.isEmpty(billingAddresses) &&
          !_.isEmpty(billingCompanyName) &&
          !_.isEmpty(billingAddress)));

    if (showLegacyBilling) {
      return (
        <div>
          <FormElement
            id="selectedName"
            label="Billing Company Name"
            value={billingCompanyName || "n/a"}
          />
          <FormElement
            id="selectedAddress"
            label="Billing Address"
            value={billingAddress || "n/a"}
          />
        </div>
      );
    } else if (billingAddresses) {
      return (
        <div>
          <FormElement
            show={isEditable}
            type="select"
            id="billingAddressId"
            label="Billing Address"
            value={billingAddressId}
            onChange={onSelectPoc.bind(
              this,
              toggleSelector,
              handleInputChange,
              cleanBillingAddressInfo
            )}
            options={formatAddressList(billingAddresses, request.id)}
            editable={isEditable}
          />
          {showAddressDetails(request)}
        </div>
      );
    }
    return null;
  }
}

// keep user for component re-render logic
function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  handleInputChange,
  cleanBillingAddressInfo
})(BillingAddressSelector);
