import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";
import { fetchDeletedUsers } from "actions/users";

const userTypes = [
  {
    label: "CTIA Accounting",
    value: "ACCOUNTING"
  },
  {
    label: "CTIA Staff",
    value: "ADMIN"
  },
  {
    label: "Laboratory",
    value: "LAB"
  },
  {
    label: "Operator",
    value: "OPERATOR"
  },
  {
    label: "Service Center",
    value: "SERVICECENTER"
  },
  {
    label: "Supplier",
    value: "SUPPLIER"
  },
  {
    label: "Unassigned",
    value: "UNASSIGNED"
  },
  {
    label: "Vendor",
    value: "VENDOR"
  }
];

const defaultRole = "ADMIN";

const DeletedUsersSelector = ({ users, fetchDeletedUsers }) => {
  useEffect(() => {
    fetchDeletedUsers(defaultRole);
  }, [fetchDeletedUsers]);

  return (
    <Form.Control
      as="select"
      onChange={e => fetchDeletedUsers(e.target.value)}
      defaultValue={defaultRole}
    >
      {userTypes.map(type => {
        return (
          <option key={type.value} value={type.value}>
            {type.label}
          </option>
        );
      })}
    </Form.Control>
  );
};

export default connect(null, { fetchDeletedUsers })(DeletedUsersSelector);
