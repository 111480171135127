// TODO: switch to app.certTypes values pulled up from API
export default {
  cyber: { certtypeid: 26, title: "Cybersecurity", activebudget: true },
  bsr: {
    certtypeid: 87,
    title: "Battery System Registration",
    activebudget: false
  },
  arr: { certtypeid: 92, title: "Adapter Recognition", activebudget: false },
  crr: { certtypeid: 88, title: "Cell Recognition", activebudget: false },
  sar: { certtypeid: 94, title: "Site Audit", activebudget: false },
  hrr: { certtypeid: 91, title: "Hybrid Recognition", activebudget: false },
  eprr: {
    certtypeid: 90,
    title: "Embedded Pack Recognition",
    activebudget: false
  },
  dhr: {
    certtypeid: 97,
    title: "Device Hardware Reliability",
    activebudget: false
  },
  acr: { certtypeid: 93, title: "Adapter Certification", activebudget: false },
  prr: { certtypeid: 89, title: "Pack Recognition", activebudget: false },
  bsc: { certtypeid: 95, title: "Battery System", activebudget: false },
  blc: { certtypeid: 96, title: "Battery Life", activebudget: true },
  ota: { certtypeid: 27, title: "OTA", activebudget: true },
  cbrs: { certtypeid: 25, title: "CBRS", activebudget: true },
  battery: { certtypeid: 95, title: "Battery Compliance", activebudget: true }
};
