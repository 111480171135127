import _ from "lodash";
import { notifyMessages } from "helpers/NotifyHelper";
import { restClientGeneral } from "libs/restClient";
import * as actions from "actions/types";

/**
 * New User Request store setup functions
 */

export const clearNewUserRequestStore = () => {
  return {
    type: actions.CLEAR_NEW_USER_REQUEST_STORE,
    payload: null
  };
};

export const clearSelectedNewUserRequest = () => {
  return {
    type: actions.CLEAR_SELECTED_USER_REQUEST,
    payload: null
  };
};

export const fetchNewUserRequestsList = ({
  program = "all",
  userRoleStatusIds = 0
}) => async dispatch => {
  // start preloader
  dispatch({
    type: actions.START_PRELOADER,
    payload: null
  });

  try {
    let activeParam;
    // Case: All new users
    if (_.includes([0, 1, 2, 3], Number(userRoleStatusIds)))
      activeParam = Number(userRoleStatusIds);
    else activeParam = userRoleStatusIds;

    const url =
      `/legacy` +
      `/newuserslist` +
      `?program=${program}` +
      `&active=${activeParam}`;

    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_NEW_USERS_REQUESTS_LISTS,
      payload: response.data
    });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });
  }

  // stop preloader
  dispatch({
    type: actions.STOP_PRELOADER,
    payload: null
  });
};

/**
 * User Form Functions
 */

export const setUserRequestFormValues = ({
  ...rest
}) => dispatch => {
  const formValues = {...rest};
  dispatch({
    type: actions.SET_USER_REQUEST_FORM_VALUES,
    payload: {
      formValues
    }
  });
};

export const setUserRequestUserNameValid = (
  userNameIsValid
) => dispatch => {
  dispatch({
    type: actions.SET_USER_REQUEST_USER_NAME_VALID,
    payload: {
      userNameIsValid
    }
  });
};

export const updateNewUserRequest = ({
  updateFields,
  userId,
  program = "ctia"
}) => async dispatch => {
  if (!userId || !updateFields) return;

  try {
    const body = {
      args: { ...updateFields }
    };
    const requestBody = JSON.stringify(body);

    const url = `/legacy/${program.toLowerCase()}newusers/${userId}`;
    const response = await restClientGeneral.put(url, requestBody);

    dispatch({
      type: actions.UPDATE_NEW_USER_REQUEST,
      payload: response.data
    });

    return response;
  } catch (error) {
    throw error;
  }
};