import { restClientAdmin } from "libs/restClient";
import {
  CONFIRM_ATL_PAYMENT,
  CONFIRM_ASC_PAYMENT,
  UPDATE_PAYMENT,
  SHOW_NOTICE,
  UPDATE_INVOICE_DATE
} from "actions/types";
import { notifyMessages } from "helpers/NotifyHelper";
import { setPaymentArgs, setIvcDateArgs } from "helpers/ArgsHelper";

export const updatePayment = request => async dispatch => {
  try {
    const url = `/invoices/${request.invoice.id}`;
    const body = setPaymentArgs(request);
    const response = await restClientAdmin.put(url, body);

    dispatch({ type: UPDATE_PAYMENT, payload: response.data.data });
    dispatch({
      type: SHOW_NOTICE,
      payload: notifyMessages.paymentUpdatedSuccessfully
    });
  } catch (e) {
    dispatch({
      type: SHOW_NOTICE,
      payload: notifyMessages.cantUpdateRequest
    });
  }
};

export const confirmPaymentATL = (id, payment) => async dispatch => {
  try {
    const url = `/atlinvoices/${id}`;
    const body = setPaymentArgs(payment);
    const response = await restClientAdmin.put(url, body);

    dispatch({ type: CONFIRM_ATL_PAYMENT, payload: response.data.data });
    dispatch({
      type: SHOW_NOTICE,
      payload: notifyMessages.paymentUpdatedSuccessfully
    });
  } catch (e) {
    dispatch({
      type: SHOW_NOTICE,
      payload: notifyMessages.cantUpdateATL
    });
  }
};

export const confirmPaymentASC = (id, payment) => async dispatch => {
  try {
    const url = `/ascinvoices/${id}`;
    const body = setPaymentArgs(payment);
    const response = await restClientAdmin.put(url, body);

    dispatch({ type: CONFIRM_ASC_PAYMENT, payload: response.data.data });
    dispatch({
      type: SHOW_NOTICE,
      payload: notifyMessages.paymentUpdatedSuccessfully
    });
  } catch (e) {
    dispatch({
      type: SHOW_NOTICE,
      payload: notifyMessages.cantUpdateATL
    });
  }
};

export const updateInvoiceDate = request => async dispatch => {
  try {
    const url = `/invoices/${request.invoice.id}`;
    const body = setIvcDateArgs(request);
    const response = await restClientAdmin.put(url, body);

    dispatch({ type: UPDATE_INVOICE_DATE, payload: response.data.data });
    dispatch({
      type: SHOW_NOTICE,
      payload: notifyMessages.paymentUpdatedSuccessfully
    });
  } catch (e) {
    dispatch({
      type: SHOW_NOTICE,
      payload: notifyMessages.cantUpdateRequest
    });
  }
};
