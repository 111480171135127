import React, { Component } from "react";
import { connect } from "react-redux";
import { InfoBar } from "ctia-ui";
import { fetchModel } from "actions/model";

class DeviceInfoBar extends Component {
  componentDidMount() {
    this.props.fetchModel(this.props.modelId);
  }

  render() {
    const { model } = this.props;

    if (!model.info) return null;

    const { vendor, modelnumber } = model.info;

    const reqDataInfo = [
      {
        key: "Vendor",
        value: vendor.name
      },
      {
        key: "Model Name/Number",
        value: modelnumber
      }
    ];

    return <InfoBar show={true} data={reqDataInfo} />;
  }
}

function mapStateToProps({ model }) {
  return { model };
}

export default connect(mapStateToProps, { fetchModel })(DeviceInfoBar);
