import _ from "lodash";
import React from "react";
import { Bubble } from "ctia-ui";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { getSimpleArrayByKey } from "helpers/ArrayHelper";

const ManufSitesReadonlyCRR = ({ request }) => {
  if (!request || !request.model) return null;

  const siteList = _.filter(request.linkedManufsites, "checked");

  if (!siteList || _.isEmpty(siteList)) {
    return (
      <Bubble type="danger">
        No manufacturing sites linked with this request. Send a request to the
        DB support team.
      </Bubble>
    );
  }

  const simpleList =
    getSimpleArrayByKey(siteList, "name") ||
    getSimpleArrayByKey(siteList, "label");

  return (
    <FormElement
      label="Manufacturing Site(s)"
      list={simpleList}
      id="linkedManufsite"
      type="readonlyList"
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(ManufSitesReadonlyCRR);
