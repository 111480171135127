// ES6 Helpers
import _ from "lodash";

// React / Redux
import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";

// Dictionaries and Helpers
import { requestStatuses } from "helpers/RequestHelper";

// Components and Containers
import NoTableData from "components/NoTableData";
import BatteryModelDetailsBSCRow from "containers/Devices/Battery/BatteryDeviceDetails/BatteryModelDetailsTables/BatteryModelDetailsBSC/BatteryModelDetailsBSCRow";

const BatteryModelDetailsBSC = ({
  reloadRows = () => {},
  // Redux Stores
  app, model, user
}) => {
  const tableData = model.versions;
  const showDateCertified =
    user.isStaff ||
    user.isLab ||
    !_.isEqual(app.batteryRequestStatus.filter, [
      requestStatuses.requestWithdrawn
    ]);

  if (!tableData || tableData.length === 0) {
    return <NoTableData colSpan={showDateCertified ? 7 : 6} />;
  }

  const showWithdrawColumn = user.isStaff;

  const renderRows = () => {
    return tableData.map((row, rowIndex) => {
      const childrenRequestIds = _.filter(tableData, childRow => {
        return childRow.parentid === row.id;
      }).map(childRow => childRow.id);

      return (
        <BatteryModelDetailsBSCRow
          key={rowIndex}
          reloadRow={reloadRows}
          requestIdsOfChildren={childrenRequestIds}
          row={row}
          rowIndex={rowIndex}
          showDateCertified={showDateCertified}
          showWithdrawColumn={showWithdrawColumn}
        />
      );
    });
  };

  return (
    <Table responsive striped className="ctia-table">
      <thead>
        <tr>
          <th>Request ID</th>
          <th>HW Version</th>
          <th>SW Version</th>
          <th>Batteries</th>
          <th>Adapters</th>
          {showDateCertified && <th>Date Certified</th>}
          {showWithdrawColumn && <th />}
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </Table>
  );
};

function mapStateToProps({ app, model, user }) {
  return { app, model, user };
}

export default connect(mapStateToProps)(BatteryModelDetailsBSC);
