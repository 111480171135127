import React from "react";
import { connect } from "react-redux";
import SelectRecognitionSupplier from "containers/request/battery/PackCellsWithRecognition/SelectRecognitionSupplier";
import SelectRecognitionModel from "containers/request/battery/PackCellsWithRecognition/SelectRecognitionModel";
import CellRecognitionSupplierAdd from "containers/request/battery/PackCellsWithRecognition/CellRecognitionSupplierAdd";

const CellRecognitionSupplierSelector = ({ suppliers }) => {
  if (!suppliers) return null;

  return (
    <div>
      <SelectRecognitionSupplier />
      <SelectRecognitionModel />
      <CellRecognitionSupplierAdd />
    </div>
  );
};

function mapStateToProps({ suppliers }) {
  return { suppliers };
}

export default connect(mapStateToProps)(CellRecognitionSupplierSelector);
