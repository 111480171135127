import React, { Component } from "react";
import { connect } from "react-redux";
import ModalConfirm from "components/ModalConfirm";
import ListRow from "components/request/ListRow";
import { Table } from "react-bootstrap";
import { needHideActions } from "helpers/RequestHelper";
import { clearRequests } from "actions/requests";
import { withdrawRequest, unwithdrawRequest } from "actions/requestCyber";

const actionColStyle = { width: 40 };

class RequestListTable extends Component {
  state = {
    showWithdrawConfirm: false,
    showUnwithdrawConfirm: false,
    requestData: null,
    isLoading: false,
    isFormValid: true
  };

  componentWillUnmount() {
    // this.props.clearRequests();
  }

  onWithdraw = () => {
    // withdraw reason
    const reason = document.getElementById("reasonRef").value;

    if (reason.length < 5) {
      this.setState({ isFormValid: false });
    } else {
      this.setState({ isLoading: true });
      this.props
        .withdrawRequest(
          this.state.requestData.id,
          reason,
          this.clearAndFetch,
          this.props.app.programPrefix
        )
        .then(this.onCloseConfirm);
    }
  };

  onUnwithdraw = () => {
    this.setState({ isLoading: true });
    this.props
      .unwithdrawRequest(
        this.state.requestData.id,
        this.clearAndFetch,
        this.props.app.programPrefix
      )
      .then(this.onCloseConfirm);
  };

  openWithdrawConfirm = requestData => {
    this.setState({
      showWithdrawConfirm: true,
      showUnwithdrawConfirm: false,
      requestData
    });
  };

  openUnwithdrawConfirm = requestData => {
    this.setState({
      showUnwithdrawConfirm: true,
      showWithdrawConfirm: false,
      requestData
    });
  };

  clearAndFetch = () => {
    this.props.clearRequests();
    this.props.fetchHandler();
  };

  onCloseConfirm = () => {
    this.setState({
      showWithdrawConfirm: false,
      showUnwithdrawConfirm: false,
      requestData: null,
      isLoading: false
    });
  };

  onValidate = value => {
    this.setState({ isFormValid: value.length > 4 });
  };

  render() {
    const { user, data, isBLC } = this.props;

    if (!data) return null;

    const renderActionIconsHeader = () => {
      if (!user.isStaff) return null;
      if (needHideActions(data)) return null;

      return <th style={actionColStyle} />;
    };

    return (
      <div>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              {renderActionIconsHeader()}
              <th>Request ID</th>
              {user.isStaff && <th>Manufacturer</th>}
              <th>Model Name/Number</th>
              {isBLC && <th>Device Type</th>}
              <th>Request Type</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <ListRow
              tableData={data}
              isStaffUser={user.isStaff}
              isVendorUser={user.isVendor}
              isBLC={isBLC}
              openWithdrawConfirm={this.openWithdrawConfirm}
              openUnwithdrawConfirm={this.openUnwithdrawConfirm}
            />
          </tbody>
        </Table>
        <ModalConfirm
          type="withdrawReason"
          show={this.state.showWithdrawConfirm}
          onHide={this.onCloseConfirm}
          onHandle={this.onWithdraw}
          requestData={this.state.requestData}
          isLoading={this.state.isLoading}
          isFormValid={this.state.isFormValid}
          onValidate={this.onValidate}
        />
        <ModalConfirm
          type="unwithdraw"
          show={this.state.showUnwithdrawConfirm}
          onHide={this.onCloseConfirm}
          onHandle={this.onUnwithdraw}
          requestData={this.state.requestData}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

function mapStateToProps({ user, app }) {
  return { user, app };
}

export default connect(mapStateToProps, {
  clearRequests,
  withdrawRequest,
  unwithdrawRequest
})(RequestListTable);
