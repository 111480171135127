import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Glyphicon } from "react-bootstrap";
import UploadQueue from "components/UploadQueue";
import {
  hasDocsForUpload,
  getUploadQueue,
  hasPendingUploads,
  getUploadedTitlesQueue,
  hasTitlesForChange,
  manageQueue
} from "helpers/UploadsHelper";
import {
  uploadTestReport,
  addToQueue,
  removeFromQueue,
  clearQueue,
  updateReportTitle
} from "actions/uploads";
import { deleteTestReport } from "actions/tests";

// @NOTE: uses pretty the same logic as `TestResultPanel` component,
// but in case of some battery tests needs to use different logic
// since we don't need to remove reports if `no testing` and etc.
// @NOTE: SAR request has their own component - `AuditResultPanel`.
class TestResultPanelBattery extends Component {
  componentDidMount() {
    const {
      tests,
      request,
      addToQueue,
      removeFromQueue,
      uploadTestReport,
      clientType,
      certType
    } = this.props;
    const { testResults } = request;
    const testId = tests[0].id;

    // @NOTE: by default - we only upload reports for test with "Pass" status
    // but, battery program e.g. has different logic,
    // and we must upload reports no matter of the status
    if (hasDocsForUpload(testResults)) {
      const queue = getUploadQueue(testResults);
      _.map(queue, doc => {
        const { file, title, slot } = doc;
        const uploadOptions = {
          slot,
          title,
          testId,
          fileContent: file,
          queueId: `report-${testId}-slot-${slot}`,
          clientType,
          certType
        };
        manageQueue(
          uploadOptions,
          uploadTestReport,
          addToQueue,
          removeFromQueue
        );
      });
    }

    // update report titles
    if (hasTitlesForChange(testResults)) {
      const queue = getUploadedTitlesQueue(testResults);

      _.map(queue, doc => {
        this.props.updateReportTitle(
          testId,
          doc.slot,
          doc.title,
          clientType,
          certType
        );
      });
    }
  }

  componentWillUnmount() {
    this.props.clearQueue();
  }

  getResultTitle = () => {
    const { isSubmit, isUpdate } = this.props;

    if (isSubmit) return "submitted";
    if (isUpdate) return "updated";

    return "saved";
  };

  render() {
    const { request, uploads } = this.props;
    const waitMsg = hasPendingUploads(uploads)
      ? "Please wait while test results are uploading."
      : "";

    return (
      <Alert bsStyle="warning">
        <p>
          <Glyphicon glyph="ok" className="icon-right-offset" />
          Test Result for Request <b>{request.tn}</b> has been successfully
          saved. {waitMsg}
        </p>
        <UploadQueue />
      </Alert>
    );
  }
}

function mapStateToProps({ request, tests, uploads }) {
  return { request, tests, uploads };
}

export default connect(mapStateToProps, {
  addToQueue,
  clearQueue,
  removeFromQueue,
  uploadTestReport,
  updateReportTitle,
  deleteTestReport
})(TestResultPanelBattery);
