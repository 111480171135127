import React from "react";
import { connect } from "react-redux";
import { BLC_AGREEMENTS } from "dictionaries/BatteryAgreementTypes";
import ControlsBLC from "containers/request/LicenseAgreement/BatteryAgreement/ControlsBLC";

const LicenseAgreementBLC = ({ request, wizard }) => {
  const [AgrComponent] = BLC_AGREEMENTS;

  return (
    <div>
      <AgrComponent certType="blc" />
      <ControlsBLC />
    </div>
  );
};

function mapStateToProps({ request, wizard }) {
  return { request, wizard };
}

export default connect(mapStateToProps)(LicenseAgreementBLC);
