import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import CyberComponent from "containers/CyberComponent";
import FormElement from "components/FormElement";
import RequestControls from "containers/request/RequestControls";
import PocSelectorBubble from "containers/request/Initial/Steps/Vendor/PocSelectorBubble";
import {
  formatPocList,
  renderSelectedPocInfo,
  getPocPermissions
} from "helpers/PocHelper";
import { onSelectPoc } from "helpers/BillingHelper";
import { handleInputChange, cleanPocInfo } from "actions/request";
import { fetchPocs, updateRequestPOC } from "actions/requestCyber";

class PocSelector extends CyberComponent {
  componentDidMount() {
    this.props.fetchPocs(this.props.user.requestOwnerCompanyId, false);
  }

  validate() {
    return this.props.request.pocId > 0;
  }

  onSaveClick = () => {
    this.props.updateRequestPOC(this.props.request);
  };

  onNewEntryClick = () => {
    // clean POC data from request
    this.props.cleanPocInfo();

    // toggle selector view and show POC form instead
    this.props.toggleSelector(false);
  };

  onPocSelect = event => {
    const { toggleSelector, handleInputChange, cleanPocInfo } = this.props;
    return onSelectPoc(toggleSelector, handleInputChange, cleanPocInfo, event);
  };

  renderSelector = (isEditable, request) => {
    if (!isEditable) return null;

    return (
      <FormElement
        type="select"
        id="pocId"
        label="Point of Contact"
        value={request.pocId}
        onChange={this.onPocSelect}
        options={formatPocList(request.pocs, request.id)}
        editable={isEditable}
      />
    );
  };

  renderControls = isEditable => {
    const saveHandler = isEditable ? this.onSaveClick : null;

    return <RequestControls isValid={this.validate()} onSave={saveHandler} />;
  };

  render() {
    const { app, request, show, user } = this.props;

    if (!show || !request.pocs) return null;

    const isEditable = getPocPermissions(app, user);

    return (
      <div>
        <PocSelectorBubble stateHandler={this.onNewEntryClick} />
        <Form horizontal>
          {this.renderSelector(isEditable, request)}
          {renderSelectedPocInfo(request)}
          {this.renderControls(isEditable)}
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, user, app }) {
  return { request, user, app };
}

export default connect(mapStateToProps, {
  handleInputChange,
  fetchPocs,
  cleanPocInfo,
  updateRequestPOC
})(PocSelector);
