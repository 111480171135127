import { connect } from "react-redux";
import React, { Component } from "react";
import { Bubble } from "ctia-ui";
import FormElement from "components/FormElement";
import {
  handleInputChange,
  setValue,
  attachAppCertTypes
} from "actions/request";
import { fetchModelBSC } from "actions/battery/bscRequest";
import { hasStringValue } from "helpers/RequestHelper";
import * as formHelper from "helpers/FormHelper";
import * as modelHelper from "helpers/ModelHelper";

class RequestEcoModelBSC extends Component {
  state = { touched: false, fieldName: "existedModel" };

  componentDidMount() {
    this.setState({
      fieldName: formHelper.defineModelFieldId(this.props.request)
    });

    this.props.attachAppCertTypes(this.props.app);
  }

  isValidationRequired = () => {
    return this.props.user.canEditModelname;
  };

  validateModelName() {
    if (!this.isValidationRequired() || !this.state.touched) return null;

    return hasStringValue(this.props.request, this.state.fieldName)
      ? null
      : "error";
  }

  onFieldChanged = event => {
    if (!this.state.touched) {
      this.setState({ touched: true });
    }

    const model = modelHelper.findExistedModelByName(
      event.target.value,
      this.props.requests.bscEcoModels
    );

    if (model) {
      this.props.setValue("modelName", event.target.value);
      this.props
        .fetchModelBSC(model.value, this.props.request.ieee)
        .then(() => this.props.handleInputChange(event));
    } else {
      console.error("Model for BSC request can't be found.");
    }
  };

  render() {
    const { requests, request } = this.props;
    const isEditable = this.isValidationRequired();
    const inputType = formHelper.defineEcoModelFieldType(isEditable, request);
    const modelName = formHelper.defineEcoModelName(isEditable, request);
    const { bscEcoModels } = requests || [];

    if (bscEcoModels && bscEcoModels.length === 0) {
      return (
        <Bubble type="danger">
          Please submit an BSC Initial request first in order to submit the ECO.
        </Bubble>
      );
    }

    return (
      <FormElement
        id={this.state.fieldName}
        type={inputType}
        label="Model Name/Number"
        value={modelName}
        options={bscEcoModels}
        editable={isEditable}
        onChange={this.onFieldChanged}
        validationState={this.validateModelName()}
        validationMessage={formHelper.emptyMsg}
        customOptions={{
          combobox: false,
          notFoundContent: "Model Name not found"
        }}
        useIdAsValue={true}
      />
    );
  }
}

function mapStateToProps({ app, requests, request, user }) {
  return { app, requests, request, user };
}

export default connect(mapStateToProps, {
  handleInputChange,
  fetchModelBSC,
  setValue,
  attachAppCertTypes
})(RequestEcoModelBSC);
