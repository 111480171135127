import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CyberComponent from "containers/CyberComponent";
import LabTestInfoCRR from "containers/request/CRR/LabTestInfoCRR";
import LabTestResult from "containers/request/Initial/LabTestResult";
import RequestControls from "containers/request/RequestControls";
import { Bubble } from "ctia-ui";
import { updateRequestLab } from "actions/requestCyber";
import { submitEcoHRR } from "actions/battery/hrrRequest";

class LabInfoHRR extends CyberComponent {
  validate() {
    return this.props.request.cdmaLab > 0;
  }

  onSaveClick = () => {
    this.props.updateRequestLab(this.props.request);
  };

  onSubmit = () => {
    const { request, submitEcoHRR } = this.props;

    // add to request info about parent model
    const requestWithModel = {
      ...request,
      parentId: request.parentRequest.id,
      parentModelId: request.model.id
    };

    return submitEcoHRR(requestWithModel);
  };

  render() {
    const { show, tests, user, canSubmit } = this.props;

    if (!show) return null;

    const testModule = tests ? tests[0] : null;
    const isEditable = user.canEditRequest;
    const saveHandler = isEditable ? this.onSaveClick : null;

    return (
      <div>
        <Bubble show={canSubmit}>Please select a lab for the testing.</Bubble>
        <LabTestInfoCRR editable={isEditable} />
        <LabTestResult testModule={testModule} />
        <RequestControls
          isValid={this.validate()}
          onSave={saveHandler}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

LabInfoHRR.propTypes = {
  show: PropTypes.bool,
  canSubmit: PropTypes.bool
};

LabInfoHRR.defaultProps = {
  show: true,
  canSubmit: false
};

function mapStateToProps({ request, tests, user }) {
  return { request, tests, user };
}

export default connect(mapStateToProps, {
  updateRequestLab,
  submitEcoHRR
})(LabInfoHRR);
