import _ from "lodash";
import { restClient, restClientGeneral } from "libs/restClient";
import { formatDate, addDays } from "helpers/DateHelper";
import { requestStatuses } from "helpers/RequestHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { getPageArgs } from "helpers/ArgsHelper";
import * as call from "helpers/ActionHelper";
import * as actions from "actions/types";

export function fetchDeviceList(
  { searchTerm = "", devicetype, dateFrom, dateTo, showFiltered },
  certId
) {
  const startDate = formatDate(dateFrom);

  // coldfusion issue, have to add 2 extra day
  const finishDate = formatDate(addDays(dateTo, 2));

  // must encode string to prevent issues with special characters like `&`
  const encodedTerm = encodeURIComponent(`${searchTerm}`);

  const encodedType = encodeURIComponent(`${devicetype}`);


  const certClause = certId ? ` AND certtypeid = ${certId}` : "";
  var url = ``;

  if(devicetype === "")
    url = `/certifieddevicemodels?all=${!showFiltered}&where=(name LIKE '%25${encodedTerm}%25' OR modelnumber LIKE '%25${encodedTerm}%25') AND completedat >= '${startDate}' AND completedat < '${finishDate}'${certClause}&page=0`;
  else
    url = `/certifieddevicemodels?all=${!showFiltered}&where=(name LIKE '%25${encodedTerm}%25' OR modelnumber LIKE '%25${encodedTerm}%25') AND devicetype = '${encodedType}' AND completedat >= '${startDate}' AND completedat < '${finishDate}'${certClause}&page=0`;

    const payload = restClient.get(url);
  return {
    type: actions.FETCH_DEVICE_LIST,
    payload
  };
}

export const getApprovedBatteryDevices = (data, type = null) => {
  if (type === "sar") return call.dispatchWithPreloader(fetchSARDevices, data);
  else return call.dispatchWithPreloader(fetchBatteryDevices, data);
};

const fetchSARDevices = ({
  pageNum,
  pageSize,
  statusFilter,
  isLab,
  isSupplier,
  currVendorId,
  authType = 0,
  searchTerm = "",
  dateFrom,
  dateTo,
  fetchAdditionalWithdrawInfo = false
}) => async dispatch => {
  try {
    /**
     * WHERE Clause
     */
    const startDate = formatDate(dateFrom);
    // coldfusion issue, have to add 2 extra day
    const finishDate = formatDate(addDays(dateTo, 2));
    const [status] = statusFilter;
    const authTypeClause =
      authType > 0
        ? `(ieee${authType}approvedat >= '${startDate}') AND (ieee${authType}approvedat <= '${finishDate}') AND `
        : `(ieee1625approvedat >= '${startDate}' OR ieee1725approvedat >= '${startDate}') AND ` +
          `(ieee1625approvedat <= '${finishDate}' OR ieee1725approvedat <= '${finishDate}') AND `;
    const fetchAdditionalWithdrawInfoClause = fetchAdditionalWithdrawInfo
      ? ` AND requesttypeid = 1`
      : "";
    const whereClause =
      status === requestStatuses.approved
        ? authTypeClause +
          `(name LIKE '%${searchTerm}%' OR companyname LIKE '%${searchTerm}%')` +
          ` AND requeststatusid = 2` +
          fetchAdditionalWithdrawInfoClause
        : `client='battery' AND ` +
          `withdrawnat >= '${startDate}' AND ` +
          `withdrawnat <= '${finishDate}' AND ` +
          `requesttypeid = 1 AND ` +
          `requeststatusid = ${status} AND ` +
          `(vendorproductname LIKE '%${searchTerm}%' OR vendorname LIKE '%${searchTerm}%')`;

    /**
     * SELECT Clause
     */
    let selectFields = [
      "address1",
      "address2",
      "city",
      "countrycode",
      "id",
      "primarylabids",
      "requeststatusid",
      "state",
      "zipcode"
    ];

    if (fetchAdditionalWithdrawInfo)
      selectFields = selectFields.concat([
        "certtypeid",
        "companyname",
        "ieeetypeid",
        "manufsiteid",
        "name",
        "requesttypeid",
        "vendorname",
        "vendorproductname",
        "withdrawnat",
        "withdrawreason"
      ]);
    else if (status === requestStatuses.approved)
      selectFields.push("name", "companyname");
    else selectFields.push("vendorname", "vendorproductname");

    if (isLab)
      selectFields.push(
        "ieee1625primarylabname",
        "ieee1725primarylabname",
        "primarylabname"
      );

    const orderByFields =
      status === requestStatuses.approved
        ? `companyname, name ASC`
        : `name ASC`;

    /**
     * INCLUDE Clause
     */
    let includeFields =
      status === requestStatuses.approved ? "address,company" : "address";

    if (fetchAdditionalWithdrawInfo) {
      includeFields += ",requests";
    }

    const pagerArgs = getPageArgs(pageNum, pageSize);

    const body = {
      params: {
        where: whereClause,
        select: selectFields.join(","),
        include: includeFields,
        orderBy: orderByFields,
        ...pagerArgs,
        returnAs: "query"
      }
    };

    const response = await restClientGeneral.get("/sar/manufsites", body);

    dispatch({
      type: actions.FETCH_SAR_DEVICE_LIST,
      payload: response
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    return Promise.reject(e);
  }
};

const fetchBatteryDevices = ({
  pageNum,
  pageSize,
  statusFilter,
  authType = 0,
  certTypeIds = [],
  searchTerm = "",
  isLab = false,
  isVendor = false,
  selectedSubsystem: companyId = 0,
  dateFrom,
  dateTo
}) => async dispatch => {
  try {
    /// WHERE Clause arguments
    const authTypeClause = authType === 0 ? "" : `ieeetypeid=${authType} AND `;
    const certificationTypes = certTypeIds.join();
    const certIdsClause = !_.isEmpty(certificationTypes)
      ? `certtypeid IN (${certificationTypes}) AND `
      : "";
    const [requestStatusId] = statusFilter;
    const startDate = formatDate(dateFrom);
    // coldfusion issue, have to add 2 extra day
    const finishDate = formatDate(addDays(dateTo, 2));
    const dateField =
      requestStatusId === requestStatuses.approved
        ? "completedat"
        : "withdrawnat";
    const searchTermClause = searchTerm
      ? `(modelnumber LIKE '%${searchTerm}%' OR name LIKE '%${searchTerm}%') AND `
      : "";

    let whereClause =
      `${authTypeClause}` +
      `${certIdsClause}` +
      `${searchTermClause}` +
      `requesttypeid = 1 AND ` +
      `requeststatusid = ${requestStatusId} AND ` +
      `${dateField} >= '${startDate}' AND ` +
      `${dateField} <= '${finishDate}'`;

    if (isLab && _.isNumber(companyId) && companyId > 0)
      whereClause += ` AND primarylabid = ${companyId}`;

    if (isVendor && _.isNumber(companyId) && companyId > 0)
      whereClause += ` AND vendorid = ${companyId}`;

    /// SELECT clause fields
    const selectFields = [
      "certtypeid",
      "delistedat",
      "formatedtransactionnumber",
      "id",
      "modelnumber",
      "name",
      "requesttypeid",
      "withdrawnat",
      "withdrawreason"
    ].join(",");

    const pagerArgs = getPageArgs(pageNum, pageSize);

    const body = {
      params: {
        where: whereClause,
        select: selectFields,
        include: "requests,vendor",
        orderBy: "name,modelnumber ASC",
        ...pagerArgs,
        allYourReqsRBelong2Us: true,
        returnAs: "query"
      }
    };

    const response = await restClientGeneral.get(`/devicemodels`, body);

    dispatch({
      type: actions.FETCH_BATTERY_DEVICE_LIST,
      payload: response
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    return Promise.reject(e);
  }
};

export function keepDeviceList(keep = false) {
  return { type: actions.SET_KEEP_LIST, payload: keep };
}

export function clearDeviceList() {
  return { type: actions.CLEAR_DEVICE_LIST };
}

export function setSearchTerm(terms) {
  return {
    type: actions.SET_DEVICE_LIST_SEARCH_TERM,
    payload: terms
  };
}

export async function withdrawDevice({ certTypeId, deviceId, withdrawReason }) {
  const params = `?certtypeid=${certTypeId}&withdrawreason=${withdrawReason}`;
  const url = `/devicemodels/${deviceId}/withdraw${params}`;

  try {
    const response = await restClientGeneral.put(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function unwithdrawDevice({ certTypeId, deviceId }) {
  const params = `?certtypeid=${certTypeId}`;
  const url = `/devicemodels/${deviceId}/unwithdraw${params}`;

  try {
    const response = await restClientGeneral.put(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function delistDevice({ certTypeId, deviceId, requestId = null }) {
  let params = `?certtypeid=${certTypeId}`;
  if (!_.isNull(requestId)) {
    params += `&requestid=${requestId}`;
  }
  const url = `/devicemodels/${deviceId}/delist${params}`;

  try {
    const response = await restClientGeneral.put(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function relistDevice({ certTypeId, deviceId, requestId = null }) {
  let params = `?certtypeid=${certTypeId}`;
  if (!_.isNull(requestId)) {
    params += `&requestid=${requestId}`;
  }
  const url = `/devicemodels/${deviceId}/relist${params}`;

  try {
    const response = await restClientGeneral.put(url);
    return response;
  } catch (error) {
    throw error;
  }
}
