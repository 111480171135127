import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import { Bubble, TooltipIcon, DownloadButton } from "ctia-ui";
import FileStatusInfo from "components/FileStatusInfo";
import UploadInstructions from "components/UploadInstructions";
import InputFile from "components/form/InputFile";
import { getDocStatus, docStatuses } from "helpers/DocumentStatus";
import { handleFileChange } from "actions/request";
import { usersManualDownload, getUsersManualInfo } from "actions/requestCyber";

class UserManual extends Component {
  componentDidMount() {
    const { request, getUsersManualInfo } = this.props;
    if (request.id) {
      getUsersManualInfo(request.model.id);
    }
  }

  onDownload = () => {
    return this.props.usersManualDownload(this.props.request);
  };

  renderManualDownload = manual => {
    if (!manual) return null;

    return (
      <div className="button-top-offset">
        <DownloadButton
          label="Download Product Description"
          action={this.onDownload}
          size="small"
        />
      </div>
    );
  };

  render() {
    const { request, canSubmit, user } = this.props;
    const manual = _.isUndefined(request.usersManual)
      ? null
      : request.usersManual.document;

    const statusInfo = manual
      ? getDocStatus(manual.documentstatusid)
      : docStatuses.pending;

    const manualFile = request.usersManual ? request.usersManual.file : null;

    return (
      <div>
        <Bubble type="warning" icon="time" show={canSubmit}>
          Please upload a product description. You may skip this step for now
          and come back to it later.
        </Bubble>
        <Row>
          <FileStatusInfo />
          <Col xs={11} sm={3} md={3}>
            <FormGroup controlId="usersManual">
              <ControlLabel>
                <TooltipIcon
                  glyph={statusInfo.icon}
                  tipText={statusInfo.title}
                />{" "}
                Product Description
              </ControlLabel>
              {statusInfo.id !== docStatuses.approved.id && (
                <InputFile
                  id="usersManual"
                  name="usersManual"
                  onChange={this.props.handleFileChange.bind(
                    this,
                    request.usersManual
                  )}
                  disabled={!user.canEditRequest && !user.canUploadRequestDocs}
                  file={manualFile}
                />
              )}
              {this.renderManualDownload(manual)}
            </FormGroup>
          </Col>
          {statusInfo.id !== docStatuses.approved.id &&
            user.canUploadRequestDocs && (
              <Col xs={12} sm={12} md={12}>
                <UploadInstructions />
              </Col>
            )}
        </Row>
      </div>
    );
  }
}

UserManual.propTypes = {
  fileChangeHandler: PropTypes.func,
  saveStepHandler: PropTypes.func,
  usersManual: PropTypes.object
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  handleFileChange,
  usersManualDownload,
  getUsersManualInfo
})(UserManual);
