import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import BatteryParentDetails from "components/request/BatteryParentDetails";
import RequestEcoModel from "containers/request/BLC/RequestEcoModelBLC";
import PacksReadonly from "containers/request/battery/BatterySupplier/BatteryPackReadonlyBLC";
import RequestOperatingSystem from "components/request/RequestOperatingSystem";
import RequestHWVersion from "components/request/RequestHWVersion";
import RequestSWVersion from "components/request/RequestSWVersion";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import RequestParentLink from "components/request/RequestParentLink";
import { setValue } from "actions/request";
import {
  initEcoBLC,
  updateECODeviceInfoBLC,
  setRequestPermissions
} from "actions/battery/blcRequest";
import { submitWizardStep } from "actions/wizard";
import { hasStringValue } from "helpers/RequestHelper";
import SelectDeviceType from "containers/request/BLC/RequestDeviceTypeBLC";

class RequestECOContainerBLC extends CyberComponent {
  modelField = this.props.request.id ? "modelName" : "existedModel";

  componentDidMount() {
    const { request, user, initEcoBLC, setRequestPermissions } = this.props;

    // fetch only for the first time on submissions
    if (!this.validate() && !request.id) initEcoBLC();

    // fetch for existed requests
    if (request.id) {
      initEcoBLC(request.devicemodelid).then(() =>
        setRequestPermissions(request, user)
      );
    }
  }

  onSaveClick = () => {
    const { request, updateECODeviceInfoBLC } = this.props;
    updateECODeviceInfoBLC(request);
  };

  validateModel() {
    return hasStringValue(this.props.request, this.modelField);
  }

  validateHwVersion() {
    return hasStringValue(this.props.request, "hwVersion");
  }

  validateSwVersion() {
    return hasStringValue(this.props.request, "swVersion");
  }

  validateOpSys() {
    const { operatingSystem } = this.props.request;
    return operatingSystem && operatingSystem > 0;
  }

  validateDeviceType() {
    if(this.props.request.deviceType === 0) {
      return hasStringValue(this.props.request.parentRequest, "devicetype")
    } else {
      return hasStringValue(this.props.request, "deviceType")
    }
  }

  validate() {
    const { user } = this.props;

    // don't need to validate if form is non-editable
    if (user.canEditDeviceInfo === false) return true;

    // check if all required fields are valid
    return (
      this.validateModel() &&
      this.validateHwVersion() &&
      this.validateSwVersion() &&
      this.validateOpSys() &&
      this.validateDeviceType()
    );
  }

  render() {
    const { canEditDeviceInfo } = this.props.user;
    const saveHandler = canEditDeviceInfo ? this.onSaveClick : null;

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestParentLink />
          <BatteryParentDetails />
          <hr />
          <RequestManufacturer />
          <RequestEcoModel />
          {this.props.request[this.modelField] && (
            <>
              <SelectDeviceType />
              <hr />
              <PacksReadonly />
              <RequestHWVersion />
              <RequestSWVersion />
              <RequestOperatingSystem />
              <SubmissionDetails />
            </>
          )}
          <RequestControls isValid={this.validate()} onSave={saveHandler} />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ app, request, user }) {
  return { app, request, user };
}

export default connect(mapStateToProps, {
  initEcoBLC,
  submitWizardStep,
  updateECODeviceInfoBLC,
  setRequestPermissions,
  setValue
})(RequestECOContainerBLC);
