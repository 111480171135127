import React from "react";

const CyberFees = () => {
  return (
    <div>
      <p>
        A certification fee, as shown below, will be invoiced by CTIA once the
        request is submitted. Testing fees are independently determined and
        billed by the test labs.
      </p>
      <ul className="ctia-list">
        <li>Level 1 Initial Certification: $500</li>
        <li>Level 2 Initial Certification: $750</li>
        <li>Level 3 Initial Certification: $1,000</li>
        <li>ECO Certification: $500</li>
      </ul>
    </div>
  );
};

export default CyberFees;
