import _ from "lodash";
import { searchTextInMoment } from "helpers/SearchHelper";

const CertifiedPendingReportTableHelper = {
  async filterTableData({ searchString = "", tableData = [] }) {
    if (_.isEmpty(searchString) || _.isEmpty(tableData)) return tableData;

    const searchParams = this.getIndividualQueryStrings({
      queryString: searchString,
    });

    return _.filter(tableData, (row) => {
      const columnValues = _.values(row);

      // Check that every individual item user has in search is included in the
      // columns of a single row or reject the row if they aren't all found.
      return _.every(searchParams, (searchValue) => {
        return _.find(columnValues, (columnValue) => {
          return this.searchTextMatchesColumnValue({
            columnValue,
            searchValue,
          });
        });
      });
    });
  },

  getIndividualQueryStrings({ queryString = "" }) {
    return queryString.toLowerCase().replace(",", " ").split(" ");
  },

  searchTextMatchesColumnValue({ columnValue, searchValue }) {
    if (typeof columnValue === "object" && columnValue !== null && columnValue._isAMomentObject)
      return searchTextInMoment(columnValue, searchValue);

    let columnValueLC = columnValue;
    if (typeof columnValue === "string")
      columnValueLC = columnValue.toLowerCase();

    return _.includes(columnValueLC, searchValue);
  },
};

export default CertifiedPendingReportTableHelper;
