import React from "react";
import { connect } from "react-redux";
import RequestVoltage from "components/request/RequestVoltage";
import PackCells from "containers/request/battery/PackCells/PackCellsContainer";
import PackCellsReadonly from "components/request/PackCellsReadonly";

const PackECODetailsHRR = ({ request, user }) => {
  if (request.id > 0) {
    if (request.isPack) {
      return user.canEditLabFields ? (
        <>
          <PackCells />
          <RequestVoltage
            fieldId="capacity"
            fieldLabel="Capacity (mAh)"
            shouldBeNumber={true}
            editable={false}
          />
        </>
      ) : (
        <PackCellsReadonly />
      );
    }
  }

  return null;
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(PackECODetailsHRR);
