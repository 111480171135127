import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  InputGroup
} from "react-bootstrap";
import {
  fetchBudgetLimits,
  setBudgetSetting,
  updateBudgetLimits,
  setBudgetLimit
} from "actions/reports/budgetReport";
import certTypes from "dictionaries/CertTypesEnum";
import BudgetDateRange from "./BudgetDateRange";

class BudgetForm extends Component {
  componentDidMount() {
    // fetch saved budget limits from API
    this.props.fetchBudgetLimits();
  }

  renderBudgetLimits = () => {
    const { budgetReport } = this.props;

    if (!budgetReport || !budgetReport.budgetLimits) return null;

    return _.keys(budgetReport.budgetLimits).map(key => {
      const value = Number(budgetReport.budgetLimits[key]).toLocaleString();

      return (
        <FormGroup controlId={key} key={key}>
          <Col componentClass={ControlLabel} sm={4} md={6}>
            {certTypes[key].title} Budget:
          </Col>
          <Col componentClass={InputGroup} sm={2} md={4}>
            <InputGroup.Addon>$</InputGroup.Addon>
            <FormControl
              type="text"
              id={key}
              placeholder="00000.00"
              value={value}
              onChange={e => {
                this.onLimitChange(e.target.value, key);
              }}
            />
          </Col>
        </FormGroup>
      );
    });
  };

  onLimitChange = (limitAmount, fieldId) => {
    const limitCleared = limitAmount.replace(/[^\d]/g, "");
    this.props.setBudgetLimit(fieldId, limitCleared);
  };

  onClick = event => {
    const { budgetLimits } = this.props.budgetReport;

    this.props.updateBudgetLimits(budgetLimits);
  };

  renderSubmit = () => {
    return (
      <FormGroup controlId="updateBudgetSettings">
        <Col componentClass={ControlLabel} sm={4} md={6} />
        <Col componentClass={InputGroup} sm={2} md={4}>
          <Button bsStyle="primary" onClick={this.onClick}>
            Submit
          </Button>
        </Col>
      </FormGroup>
    );
  };

  render() {
    return (
      <Form horizontal>
        <BudgetDateRange />
        <hr />
        {this.renderBudgetLimits()}
        {this.renderSubmit()}
      </Form>
    );
  }
}

function mapStateToProps({ budgetReport }) {
  return { budgetReport };
}

export default connect(mapStateToProps, {
  fetchBudgetLimits,
  setBudgetSetting,
  updateBudgetLimits,
  setBudgetLimit
})(BudgetForm);
