import _ from "lodash";

// React/Redux
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Divider } from "ctia-ui";
import { Form } from "ctia-react-bootstrap-v4";

// Redux Actions
import {
  clearSelectedNewUserRequest
} from "actions/newUserRequest";
import { getUnusedUserName } from "actions/user";

// Dictionaries and Helpers
import ErrorForm from "components/form/ErrorForm";
import { storeIsValid }
  from "containers/users/UserRequest/UserRequestForm/FormHelpers";

// User Request related components
import DispositionSelector
  from "containers/users/UserRequest/UserRequestForm/components/RequestDispositions/DispositionSelector";
import CompanyFormFields
  from "containers/users/UserRequest/UserRequestForm/FormFields/CompanyFormFields";
import FormButtons
  from "containers/users/UserRequest/UserRequestForm/components/FormControls/FormActions";
import NameAndTitle
  from "containers/users/UserRequest/UserRequestForm/components/NameAndTitle";
import UserInfoFormFields
  from "containers/users/UserRequest/UserRequestForm/FormFields/UserInfoFormFields";
import UserLocationFormFields
  from "containers/users/UserRequest/UserRequestForm/FormFields/UserLocationFormFields";
import UserRequestDispositions
  from "dictionaries/UserRequestDispositions";

const formId = "new-user-request-form";

const NewUserRequestForm = ({
  // Props
  // Redux Stores
  newUserRequest,
  // Redux Actions
  clearSelectedNewUserRequest
}) => {
  const [formValidated, setFormValidated] = useState(false);
  const [reduxValidated, setReduxValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onFormChange = event => {
    const form = event.currentTarget;

    if (_.isUndefined(form)) {
      setFormValidated(false);
      return;
    }

    // Hack -- The form validates before removing rejection reason component when changing selector to another value
    const skipFormCheck = (form.dispositionReason &&
      Number(form.disposition.value) === UserRequestDispositions.accepted);

    setFormValidated(skipFormCheck ? true : form.checkValidity());
  };

  const onSubmitError = errorMessage => {
    setErrorMessage(errorMessage);
  };

  const onSubmit = () => {
    setErrorMessage(null);
  };

  useEffect(() => {
    if (!_.isNull(newUserRequest) && !_.isNull(newUserRequest.selectedUserRequest)) {
      const { formValues, userNameIsValid } = newUserRequest.selectedUserRequest;
      const reduxStoreIsValid = storeIsValid(formValues, userNameIsValid);
      setReduxValidated(reduxStoreIsValid);
    }
  }, [newUserRequest]);

  useEffect(() => {
    return () => {
      clearSelectedNewUserRequest();
    }
  }, [clearSelectedNewUserRequest]);

  const history = useHistory();
  if (!newUserRequest || !newUserRequest.selectedUserRequest) {
    history.push('/admin/cp/users/newuserrequests');
    return null;
  }

  return (
    <div className="container-fluid" style={{maxWidth: 750}}>
      <NameAndTitle />
      <Form
        noValidate
        validated={formValidated}
        id={formId}
        className="ctia-form"
        onChange={onFormChange}
      >
        <ErrorForm errors={errorMessage} />
        <UserInfoFormFields />
        <Divider />
        <UserLocationFormFields />
        <Divider />
        <CompanyFormFields />
        <Divider />
        <DispositionSelector />
      </Form>
      <Divider />
      <FormButtons
        onError={onSubmitError}
        onSubmit={onSubmit}
        validated={formValidated && reduxValidated}
      />
    </div>
  );
};

function mapStateToProps({ companies, newUserRequest }) {
  return { companies, newUserRequest };
}

export default connect(mapStateToProps, {
  clearSelectedNewUserRequest,
  getUnusedUserName,
})(NewUserRequestForm);
