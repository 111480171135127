import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Container } from "ctia-react-bootstrap-v4";
import { changeCertProgram } from "actions/app";
import { updateUserLocation } from "actions/user";
import programs from "components/ProgramSelector/programSelectorHelper";
import Card from "components/ProgramSelector/ProgramBlock";
import "components/ProgramSelector/style.css";
import QuickSearch from "components/QuickSearch/index";
import CatlContainer from "components/ProgramSelector/CatlContainer";

const ProgramMenu = ({ app, user, changeCertProgram, updateUserLocation }) => {
  let history = useHistory();

  if (!user.role) return null;

  const onCardClick = program => {
    // change program action creator
    changeCertProgram(program.name);

    // update user location to selected program
    updateUserLocation(program.route[user.role]);

    // redirect to default program route
    history.push(program.route[user.role]);
  };

  const fluid = app.sm ? "fluid" : "";

  const rowSettings = {
    xl: 3,
    lg: 3,
    md: 3,
    sm: 12
  };

  return (
    <Container {...fluid}>
      <Row {...rowSettings} style={{ marginTop: 0 }}>
        <div className="home-description">
          <h1>CTIA Certification Database</h1>
          <CatlContainer />
        </div>
      </Row>
      <QuickSearch shortVersion={false} />
      <Row {...rowSettings} style={{ marginTop: 25 }}>
        {programs[user.role].map(program => {
          if (_.includes(user.accessiblePortals, program.name)) {
            return (
              <Card
                card={program}
                onCardClick={onCardClick}
                key={program.key}
              />
            );
          }

          return null;
        })}
      </Row>
    </Container>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps, {
  changeCertProgram,
  updateUserLocation
})(ProgramMenu);
