import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Col } from "ctia-react-bootstrap-v4";
import { InlinePreloader } from "ctia-ui";
import SelectUserType from "containers/ControlPanel/Users/SelectUserType";
import SelectUserCompany from "containers/ControlPanel/Users/SelectUserCompany";
import FormFieldLabel from "components/form/FormFieldLabel";
import StateStaticReadonly from "components/form/StateStaticReadonly";
import CompanyStaticReadonly from "components/form/CompanyStaticReadonly";
import OperatorNotifications from "containers/ControlPanel/Users/EditUser/OperatorNotifications";
import validation from "dictionaries/Validation";
import * as roles from "dictionaries/Roles";
import {
  clearCountryState,
  fetchCountries,
  fetchCountryStates
} from "actions/countries";
import { clearUserOrgList } from "actions/org";
import { validateEmail, validatePhone } from "helpers/ValidatorHelper";
import { separatePhoneExt, getUserCompanyId } from "helpers/UsersHelper";

const EditUserForm = ({
  countries,
  clearCountryState,
  fetchCountries,
  fetchCountryStates,
  org,
  data,
  role,
  modernId
}) => {
  const [email, setEmail] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [workPhoneExt, setWorkPhoneExt] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [labID, setLabID] = useState("");

  useEffect(() => {
    fetchCountries().then(() => {
      if (
        data?.countryid &&
        (data.countryid === "US" || data.countryid === "CA")
      )
        fetchCountryStates(data.countryid);
    });

    if (data?.workphone) {
      const { workphone, workphoneext } = separatePhoneExt(data.workphone);

      setWorkPhone(workphone);
      setWorkPhoneExt(workphoneext);
      setMobilePhone(data.mobilephone);
    }

    if (data?.vendorid && org?.userOrgs) {
      let companyId = getUserCompanyId(data, org.userTypeOrg);
      let company = org.userOrgs.find(element => {
        return element.ctiaid === companyId;
      });

      let temp = company === undefined ? labID : company["id"];

      if(labID === "") {
        setLabID(temp)
      }
    }

    return () => {
        clearUserOrgList();
        clearCountryState();
    };
  }, [org, labID, setLabID, data, fetchCountries, fetchCountryStates]);

  const hasCountries = countries
    ? _.has(countries, "list") && !_.isEmpty(countries.list)
    : false;
  const hasCountryStates = countries
    ? _.has(countries, "states") && !_.isEmpty(countries.states)
    : false;

  const hasValidEmail = Boolean(email === "" || validateEmail(email));
  const hasValidWorkPhone = Boolean(
    workPhone !== "" && validatePhone(workPhone)
  );
  const hasValidMobielPhone = Boolean(
    mobilePhone === "" || validatePhone(mobilePhone)
  );
  const hasValidPhoneExt =
    workPhoneExt !== "" ? validatePhone(workPhoneExt) : true;

  const showStaticCompanyField = () => {
    if (!org || !org.userTypeOrg) return false;

    const staticRoles = [roles.DB_STAFF, roles.DB_ACCOUNTING];

    return _.includes(staticRoles, org.userTypeOrg);
  };

  const getCompanyFlags = () => {
    const flags = { isStaff: false, isAccounting: false };

    if (!org || !org.userTypeOrg) return flags;

    if (org.userTypeOrg === roles.DB_STAFF) flags.isStaff = true;
    if (org.userTypeOrg === roles.DB_ACCOUNTING) flags.isAccounting = true;

    return flags;
  };



  const renderCompanyField = () => {
    if (!org || !org.userTypeOrg) return null;

    // Unassigned users require to provide a company name ahead
    if (org.userTypeOrg === roles.DB_UNASSIGNED) {
      return (
        <Form.Control
          required
          placeholder="Enter company name"
          defaultValue={data.unassignedcompanyname}
        />
      );
    }

    const showStatic = showStaticCompanyField();
    const companyId = getUserCompanyId(data, org.userTypeOrg);



    return showStatic ? (
      <CompanyStaticReadonly {...getCompanyFlags()} />
    ) : (
      <>
        <Form.Control as="select" defaultValue={companyId} onChange={(e) => {updateLabID(e)}}>
          <option value="none">--- Select One ---</option>
          <SelectUserCompany />
        </Form.Control>
        <input type="hidden" id="labID" value={labID} />
      </>
    );
  };

  if (!data) return <InlinePreloader />;

  const updateLabID = (event) => {
    var labID = event.nativeEvent.target.options[event.nativeEvent.target.selectedIndex].dataset.labid;
    var input = document.getElementById("labID");
    if(labID !== undefined) {
      setLabID(labID);
      input.value = labID;
    }
  }

  return (
    <>
      <Form.Group controlId="userType" className="was-validated">
        <Form.Label>
          <FormFieldLabel label="User Type" isRequired={true} />
        </Form.Label>
        <SelectUserType userRole={role} userId={modernId} />
      </Form.Group>
      <Form.Group controlId="firstName">
        <Form.Label>
          <FormFieldLabel label="First Name" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          placeholder="Enter first name"
          defaultValue={data.firstname}
        />
      </Form.Group>
      <Form.Group controlId="lastName">
        <Form.Label>
          <FormFieldLabel label="Last Name" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          placeholder="Enter last name"
          defaultValue={data.lastname}
        />
      </Form.Group>
      <Form.Group controlId="userName">
        <Form.Label>
          <FormFieldLabel label="User Name" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          placeholder="Enter user name"
          defaultValue={data.username}
        />
      </Form.Group>
      <Form.Group controlId="title">
        <Form.Label>
          <FormFieldLabel label="Business Title" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          placeholder="Enter business title"
          defaultValue={data.title}
        />
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label>
          <FormFieldLabel label="E-mail" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          placeholder="Enter user email"
          onChange={event => {
            setEmail(event.target.value);
          }}
          isInvalid={!hasValidEmail}
          defaultValue={data.email}
        />
        <Form.Control.Feedback type="invalid">
          {validation.invalidEmailFormat}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Row>
        <Col>
          <Form.Group controlId="workPhone">
            <Form.Label>
              <FormFieldLabel label="Work Phone" isRequired={true} />
            </Form.Label>
            <Form.Control
              required
              placeholder="Enter work phone number"
              onChange={event => {
                setWorkPhone(event.target.value);
              }}
              isInvalid={!hasValidWorkPhone}
              value={workPhone}
            />
            <Form.Control.Feedback type="invalid">
              {validation.invalidPhoneFormat}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs="auto" className="icon-left-offset">
          <Form.Group controlId="workPhoneExt">
            <Form.Label>
              <FormFieldLabel label="Ext." />
            </Form.Label>
            <Form.Control
              placeholder="Phone Number Ext."
              onChange={event => {
                setWorkPhoneExt(event.target.value);
              }}
              isInvalid={!hasValidPhoneExt}
              value={workPhoneExt}
            />
            <Form.Control.Feedback type="invalid">
              {validation.invalidPhoneFormat}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Group controlId="mobilePhone">
        <Form.Label>
          <FormFieldLabel label="Mobile Phone" />
        </Form.Label>
        <Form.Control
          placeholder="Enter mobile phone number"
          onChange={event => {
            setMobilePhone(event.target.value);
          }}
          isInvalid={!hasValidMobielPhone}
          defaultValue={data.mobilephone}
        />
        <Form.Control.Feedback type="invalid">
          {validation.invalidPhoneFormat}
        </Form.Control.Feedback>
      </Form.Group>
      <hr />
      <Form.Group controlId="address1">
        <Form.Label>
          <FormFieldLabel label="Address 1" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          placeholder="Enter Address line 1"
          defaultValue={data.address1}
        />
      </Form.Group>
      <Form.Group controlId="address2">
        <Form.Label>
          <FormFieldLabel label="Address 2" />
        </Form.Label>
        <Form.Control
          placeholder="Enter Address line 2"
          defaultValue={data.address2}
        />
      </Form.Group>
      <Form.Group controlId="address3">
        <Form.Label>
          <FormFieldLabel label="Address 3" />
        </Form.Label>
        <Form.Control
          placeholder="Enter Address line 3"
          defaultValue={data.address3}
        />
      </Form.Group>
      <Form.Group controlId="country">
        <Form.Label>
          <FormFieldLabel label="Country" isRequired={true} />
        </Form.Label>
        <Form.Control
          as="select"
          disabled={!hasCountries}
          onChange={e => {
            const countryCode = e.target.value;

            if (countryCode === "US" || countryCode === "CA") {
              fetchCountryStates(countryCode);
            } else {
              clearCountryState();
            }
          }}
          defaultValue={data.countryid}
        >
          <option value="none">--- Select One ---</option>
          {hasCountries &&
            countries.list.map(country => {
              return (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              );
            })}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="city">
        <Form.Label>
          <FormFieldLabel label="City" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          placeholder="Enter City"
          defaultValue={data.city}
        />
      </Form.Group>
      <Form.Group controlId="state">
        <Form.Label>
          <FormFieldLabel label="State/Province" isRequired={true} />
        </Form.Label>
        {hasCountryStates ? (
          <Form.Control required={true} as="select" defaultValue={data.stateid}>
            <option value="none">--- Select One ---</option>
            {countries.states.map(state => {
              return (
                <option key={state.id} value={state.short}>
                  {state.name}
                </option>
              );
            })}
          </Form.Control>
        ) : (
          <StateStaticReadonly />
        )}
      </Form.Group>
      <Form.Group controlId="zip">
        <Form.Label>
          <FormFieldLabel label="Zip/Postal Code" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          placeholder="Enter Zip or Postal Code"
          defaultValue={data.zipcode}
        />
      </Form.Group>
      <hr />
      <Form.Group controlId="company">
        <Form.Label>
          <FormFieldLabel label="Company" isRequired={true} />
        </Form.Label>
        {renderCompanyField()}
      </Form.Group>
      <Form.Group controlId="website">
        <Form.Label>
          <FormFieldLabel label="Company Website" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          placeholder="Enter website address"
          defaultValue={data.website}
        />
      </Form.Group>
      <OperatorNotifications
        userData={{ ...data, isAdmin: org.userTypeOrg === roles.DB_STAFF }}
      />
      <input type="hidden" id="isEditForm" value={true} />
    </>
  );
};

function mapStateToProps({ countries, org }) {
  return { countries, org };
}

export default connect(mapStateToProps, {
  clearCountryState,
  fetchCountries,
  fetchCountryStates
})(EditUserForm);
