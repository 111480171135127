import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Breadcrumb } from "react-bootstrap";
import { updateUserLocation } from "actions/user";
import { certProgramData } from "helpers/AppHelper";
import { getCurrentLocation } from "helpers/BreadCrumbsHelper";

const Crumbs = ({ app, history, updateUserLocation, user }) => {
  const { location, push } = history;

  // exclude some specific pages
  const excluded = ["/", "/user/profile", "/terms", "/policy"];

  if (_.includes(excluded, location.pathname)) return null;

  if (!user.token) return null;

  const goHome = () => {
    updateUserLocation();
    push("/");
  };

  const certProgramTitle = certProgramData[app.certProgram].title || "N/A";
  const currentLocation = getCurrentLocation(location.pathname);
  const isAdminHome =
    _.startsWith(location.pathname, "/admin/cp") && app.isNoneCert;
  const isAccountantHome =
    _.startsWith(location.pathname, "/accountant") && app.isNoneCert;

  return (
    <Breadcrumb className="ctia-breadcrumbs">
      <LinkContainer to="/" onClick={goHome}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </LinkContainer>
      {!isAdminHome && !isAccountantHome && (
        <Breadcrumb.Item active>{certProgramTitle}</Breadcrumb.Item>
      )}
      <Breadcrumb.Item active>{currentLocation}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps, { updateUserLocation })(Crumbs);
