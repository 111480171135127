import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import BatteryTestResultsInfoBar from "containers/request/battery/TestResults/BatteryTestResultsInfoBar";
import { LoaderButton } from "ctia-ui";
import TestResultPanelBattery from "components/TestResultPanelBattery";
import PageTitle from "components/RequestPageTitle";
import LabResultFormHRR from "containers/testResult/LabResultFormHRR";
import LabResultUpload from "containers/testResult/LabResultUpload";
import UnderReviewViewHRR from "containers/testResult/UnderReviewViewHRR";
import GoBackButton from "components/form/buttons/GoBackButton";
import { hasPreparedResults, isComplete } from "helpers/RequestTestsHelper";
import { clearRequest } from "actions/request";
import {
  submitTestResultsHRR,
  getDetailsHRR,
  downloadReportHRR
} from "actions/battery/hrrRequest";
import { validateCapacity } from "helpers/ValidatorHelper";
import { mapBatteryDeviceType } from "actions/battery/batteryRequests";

const backURL = "/battery/lab/results";

class TestResultsItemHRR extends Component {
  state = { showContent: false };

  fetchHandler = reqId => {
    const { app, getDetailsHRR, mapBatteryDeviceType } = this.props;

    return getDetailsHRR(reqId).then(() => {
      this.activateRender();
      mapBatteryDeviceType(app.certTypes);
    });
  };

  componentDidMount() {
    this.resetRender();
    this.fetchHandler(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const newId = nextProps.match.params.id;
    if (newId !== this.props.match.params.id) {
      this.fetchHandler(newId);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
  }

  resetRender = () => {
    this.setState({ showContent: false });
  };

  activateRender = () => {
    this.setState({ showContent: true });
  };

  onValidate() {
    const { request, tests } = this.props;
    const {
      testResults,
      revision,
      crslversion,
      isInitial,
      linkedManufsites
    } = request;
    const { reports, requesttestpassedstatusid } = tests[0];

    const commonFieldsValidate =
      requesttestpassedstatusid > 0 && revision !== "" && crslversion !== "";

    if (!commonFieldsValidate) return false;

    // check device type selection, must select at least one (Initials only)
    const validateDeviceType = isInitial
      ? _.some(request.deviceTypes, "checked")
      : true;

    if (!validateDeviceType) return false;

    // if device type is Pack - we need to have at least 1 cell selected
    const isPack = request.deviceTypes && request.deviceTypes[0].checked;

    if (isPack && isInitial) {
      // capacity need if device type is a Pack
      if (!validateCapacity(request)) return false;
    }

    if (isPack) {
      // check if at least 1 cell added
      if (!request.cellSuppliers || request.cellSuppliers.length === 0)
        return false;
    }

    // at least 1 non SAR sites is required
    const validateSites =
      linkedManufsites.length && _.some(linkedManufsites, "checked");

    if (!validateSites) return false;

    return hasPreparedResults(testResults) || reports.length;
  }

  onSubmit = () => {
    const { app, request, tests, submitTestResultsHRR } = this.props;
    return submitTestResultsHRR(request, tests[0], app.certTypes);
  };

  onDone = () => {
    this.props.history.push(backURL);
  };

  render() {
    const { request, tests, downloadReportHRR } = this.props;

    if (_.isNull(request) || _.isNull(tests) || !request.id) {
      return null;
    }

    if (!this.state.showContent) return null;

    if (request.formSaved) {
      return (
        <div>
          <h1 className="block-bottom-offset">Uploading Test Results</h1>
          <TestResultPanelBattery clientType="general" certType="hrr" />
          <LoaderButton text="Done" onClick={this.onDone} disabled={false} />
        </div>
      );
    }

    const testModule = { ...tests[0] };
    const completed = isComplete(request.testStatus);

    return (
      <div>
        <PageTitle />
        <GoBackButton dest={backURL} label="Return to list" />
        <BatteryTestResultsInfoBar />
        <UnderReviewViewHRR
          show={completed}
          testModule={testModule}
          request={request}
        />
        <LabResultFormHRR show={!completed} testModule={testModule} />
        <LabResultUpload
          show={!completed}
          downloadHandler={downloadReportHRR}
        />
        <Row>
          <Col className="text-center">
            <LoaderButton
              bsStyle="info"
              bsSize="large"
              text="Save changes"
              loadingText="Uploading..."
              onClick={this.onSubmit}
              isLoading={request.isLoading}
              disabled={!this.onValidate()}
              show={!completed}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ app, request, tests }) {
  return { app, request, tests };
}

export default connect(mapStateToProps, {
  clearRequest,
  getDetailsHRR,
  submitTestResultsHRR,
  downloadReportHRR,
  mapBatteryDeviceType
})(TestResultsItemHRR);
