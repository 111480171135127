// ==========================
// Battery Compliance Certification program
// =========================
// CRR - Cell Recognition
// ========================
const isAcceptedByLab = request => {
  return request.testStatus !== "Pending" && request.testStatus !== "Rejected";
};

export function checkDeviceInfoPermissionCRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    // for existed requests:
    // allow to edit until lab accepts request
    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPocPermissionCRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Suplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkBillingPermissionCRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkTestsPermissionCRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPaymentPermissionCRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) return false;

  return false;
}

export function checkModelnamePermissionCRR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled && request.isInitial ? true : false;
    return request.isInitial ? true : false;
  }

  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    // not allowed to edit submitted
    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function displayCapacityPermissionCRR(request) {
  if (request.isLegacy) return true;
  if (!request.submissionDate) return true;

  const { capacity } = request;
  if (capacity && capacity.length > 0) {
    return capacity[1] !== "";
  }

  return false;
}

export function displayVoltagePermissionCRR(request) {
  if (request.isLegacy) return true;
  if (!request.submissionDate) return true;

  const { voltage } = request;
  if (voltage && voltage.length > 0) {
    return voltage[1] !== "";
  }

  return false;
}

export function checkVoltageCapacityPermissionCRR(request, user) {
  const canEditModelFields =
    user.isStaff && request.isInitial && request.isApproved
      ? true
      : request && request.model
      ? !request.model.isModelNumberLocked
      : false;

  // nobody can edit if at least 1 related request was accepted by lab
  if (!canEditModelFields) return false;

  // TODO: uncomment to allow use Modify mode switcher
  // if (user.isStaff) return user.togglerEnabled;
  if (user.isStaff) return true;

  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    // not allowed to edit submitted
    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  if (user.isLab) return canEditModelFields;

  return false;
}

// =========================
// HRR - Hybrid Recognition
// ========================
export function checkDeviceInfoPermissionHRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    // for existed requests:
    // allow to edit until lab accepts request
    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPocPermissionHRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Suplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkBillingPermissionHRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkTestsPermissionHRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPaymentPermissionHRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) return false;

  return false;
}

export function checkModelnamePermissionHRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled && request.isInitial ? true : false;
    return request.isInitial ? true : false;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkVoltageCapacityPermissionHRR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Lab
  if (user.isLab) {
    return true;
  }

  return false;
}

export function displayCapacityPermissionHRR(request) {
  if (request.isLegacy) return true;

  const { capacity } = request;
  if (capacity && capacity.length > 0) {
    return capacity[1] !== "";
  }

  return false;
}

export function displayVoltagePermissionHRR(request) {
  if (request.isLegacy) return true;

  const { voltage } = request;
  if (voltage && voltage.length > 0) {
    return voltage[1] !== "";
  }

  return false;
}

export function checkLabFieldsPermissionHRR(request, user) {
  // TODO: uncomment to allow use Modify mode switcher
  // return user.isStaff && user.togglerEnabled && isAcceptedByLab(request);
  return user.isStaff && isAcceptedByLab(request);
}

// =========================
// PRR - Pack Recognition
// ========================
export function checkDeviceInfoPermissionPRR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    // for existed requests:
    // allow to edit until lab accepts request
    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPocPermissionPRR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkBillingPermissionPRR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkTestsPermissionPRR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPaymentPermissionPRR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier) return false;

  return false;
}

export function checkModelnamePermissionPRR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled && request.isInitial ? true : false;
    return request.isInitial ? true : false;
  }

  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    // not allowed to edit submitted
    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkVoltageCapacityPermissionPRR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    // not allowed to edit submitted
    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkLabFieldsPermissionPRR(request, user) {
  // TODO: uncomment to allow use Modify mode switcher
  // return user.isStaff && user.togglerEnabled;
  return user.isStaff;
}

// =========================
// EPR - Embedded Pack Recognition
// ========================
export function checkDeviceInfoPermissionEPR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier || user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    // for existed requests:
    // allow to edit until lab accepts request
    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPocPermissionEPR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier || user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkBillingPermissionEPR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier || user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkTestsPermissionEPR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier || user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPaymentPermissionEPR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier || user.isVendor) return false;

  return false;
}

export function checkModelnamePermissionEPR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled && request.isInitial ? true : false;
    return request.isInitial ? true : false;
  }

  if (user.isSupplier || user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    // not allowed to edit submitted
    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkVoltageCapacityPermissionEPR(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isSupplier || user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    // not allowed to edit submitted
    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkLabFieldsPermissionEPR(request, user) {
  // TODO: uncomment to allow use Modify mode switcher
  // return user.isStaff && user.togglerEnabled;
  return user.isStaff;
}

// =========================
// ARR - Adapter Recognition
// ========================
export function checkDeviceInfoPermissionARR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    // for existed requests:
    // allow to edit until lab accepts request
    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPocPermissionARR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Suplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkBillingPermissionARR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkTestsPermissionARR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPaymentPermissionARR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) return false;

  return false;
}

export function checkModelnamePermissionARR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled && request.isInitial ? true : false;
    return request.isInitial ? true : false;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkLabFieldsPermissionARR(request, user) {
  // TODO: uncomment to allow use Modify mode switcher
  // return user.isStaff && user.togglerEnabled && isAcceptedByLab(request);
  return user.isStaff && isAcceptedByLab(request);
}

// =========================
// ACR - Adapter Certification
// ========================
export function checkDeviceInfoPermissionACR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    // for existed requests:
    // allow to edit until lab accepts request
    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPocPermissionACR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Suplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkBillingPermissionACR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkTestsPermissionACR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPaymentPermissionACR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Supplier
  if (user.isSupplier) return false;

  return false;
}

export function checkModelnamePermissionACR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled && request.isInitial ? true : false;
    return request.isInitial ? true : false;
  }

  // Supplier
  if (user.isSupplier) {
    // allow to edit on submissions
    if (!request.id) return true;

    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkLabFieldsPermissionACR(request, user) {
  // TODO: uncomment to allow use Modify mode switcher
  // return user.isStaff && user.togglerEnabled && isAcceptedByLab(request);
  return user.isStaff && isAcceptedByLab(request);
}

// ===================================
// BSC - Battery System Certification
// ===================================
export function checkDeviceInfoPermissionBSC(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    // for existed requests:
    // allow to edit until lab accepts request
    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPocPermissionBSC(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkBillingPermissionBSC(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkTestsPermissionBSC(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkPaymentPermissionBSC(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  if (user.isVendor) return false;

  return false;
}

export function checkModelnamePermissionBSC(request, user) {
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled && request.isInitial ? true : false;
    return request.isInitial ? true : false;
  }

  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    // not allowed to edit submitted
    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkLabFieldsPermissionBSC(request, user) {
  // TODO: uncomment to allow use Modify mode switcher
  // return user.isStaff && user.togglerEnabled;
  return user.isStaff;
}
