import _ from "lodash";
import moment from "moment";
import isFuture from "date-fns/isFuture";
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";

export const fnsFormat = "MM/dd/yyyy";
export const fnsDbFormat = "MMMM, dd yyyy hh:mm:ss";

export const formatString = "MM/DD/YYYY";
export const formatDbString = "MMM, DD YYYY HH:mm:ss";

export const fileFormatString = "MM_DD_YYYY";

/**
 * Format passed date to MM/DD/YYYY format.
 *
 * @param {string} dateString Date value (server or database format)
 * @returns Formatted string date
 */
export const formatDate = dateString => {
  if (_.isEmpty(dateString)) return moment().format(formatString);

  if (moment.isMoment(dateString)) {
    return dateString.format(formatString);
  }

  return moment(dateString, formatDbString).format(formatString);
};

/**
 * Format passed string to `moment` object.
 *
 * @param {string} dateString Date value to be formatted
 * @returns moment.js object
 */
export const toMomentDate = dateString => {
  if (_.isEmpty(dateString)) return moment();
  return moment(dateString, formatDbString);
};

/**
 * Adds passed days to date object.
 *
 * @param {moment.js object} momentDate Date as moment.js object
 * @param {number} days Amount of days to be added
 * @returns moment.js object
 */
export const addDays = (momentDate, days) => {
  if (_.isEmpty(momentDate) || !days) return moment();
  return moment(momentDate).add(days, "days");
};

/**
 * Save a timestamp as a localstorage value.
 */
export const setUserTimestamp = () => {
  localStorage.setItem("timestamp", moment().unix());
};

/**
 * Check passed date to be less than passed days.
 *
 * @param {string} timestamp UNIX timestamp or any datetime value
 * @param {number} passedDays Amount of days to compare with
 * @returns boolean
 */
export const haveDaysPassed = (timestamp, passedDays) => {
  const now = toMomentDate();
  const dateToCheck = toMomentDate(timestamp);
  const days = moment.duration(now.diff(dateToCheck)).asDays();
  return days > passedDays;
};

export function getCurrentYear() {
  return new Date().getFullYear();
}

/**
 * Returns first day of a year (Jan 1, <passed_year>)
 * If year not passed - by default uses the current calendar year
 *
 * @param {number} year Calendar year value
 * @returns Date
 */
export const getFirstDayOfYear = year => {
  const dateYear = year || getCurrentYear();

  return new Date(`${dateYear}-1-1`);
};

export const toAPIString = momentDate => {
  const mDate = moment(momentDate);

  return mDate.isValid()
    ? mDate.format(formatString)
    : moment().format(formatString);
};

export function showNextYearInvoice() {
  // we generate new invoices each year by December, 1st
  const currYear = getCurrentYear();
  const currYearDeadline = new Date(currYear, 11, 1); // 11 - is an index for December - ¯\_(ツ)_/¯
  return !isFuture(currYearDeadline);
}

export function getInvoicingYears() {
  // first ATL year on a new platform is 2021
  const minYear = 2021;
  const currYear = getCurrentYear();
  const maxYear = showNextYearInvoice() ? currYear + 2 : currYear + 1;

  return _.range(minYear, maxYear);
}

export function getCurrATLYear() {
  var currDate = new Date();
  var currYear = currDate.getFullYear();
  var currMonth = currDate.getMonth() + 1;

  return currMonth >= 12 ? currYear + 1 : currYear;
}

/**
 * Function returning the build version
 * @param epoch Time in milliseconds
 */
export const getBuildVersion = epoch => {
  const epochStr = String(epoch);
  const majorVersion = epochStr.slice(0, 2);
  const minorVersion = epochStr.slice(2, 5);
  const buildNum = epochStr.slice(5);

  return { major: majorVersion, minor: minorVersion, build: buildNum };
};

/**
 * Formats db string date to human-readible one like: `January 3rd, 2021`
 *
 * @param {string} ivcDate Date in DB format (like `January, 03 2021 00:00:00`)
 * @returns string
 */
export const formatInvoiceDate = ivcDate => {
  if (ivcDate) {
    return format(new Date(ivcDate), "MMMM do, yyyy");
  }

  console.error("No invoice date passed.");

  return null;
};

export const formatDateUnix = timestamp => {
  if (_.isEmpty(timestamp)) return format(new Date(), fnsFormat);
  return format(fromUnixTime(timestamp), fnsFormat);
};

/**
 * Formats a date range to be used as part of the file name when
 * downloading or exporting a file.
 *
 * @param {moment} The starting date of the file content
 * @param {moment - optional} The last day of the file content
 * @returns string - Returns a formatted string to be usable in
 * a filepath.
 */
export const formatDateRangeForFileExport = (
  startingDate = moment(),
  endingDate = null
) => {
  const startingMoment = moment(startingDate);
  const endingMoment = moment(endingDate);

  const startingD = startingMoment.isValid()
    ? startingMoment.format(fileFormatString)
    : "";

  const endingD = endingMoment.isValid()
    ? endingMoment.format(fileFormatString)
    : "";

  return !_.isEmpty(startingD) && !_.isEmpty(endingD)
    ? `${startingD}_to_${endingD}`
    : `${startingD}`;
};
