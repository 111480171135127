import React from "react";
import { connect } from "react-redux";
import { IconLabel, MailtoLink } from "ctia-ui";
import { getRequestMailtoInfo } from "helpers/Emails";

const DocTitleReporter = ({ doc, request }) => {
  if (!doc) return null;

  const renderDocTitle = () => {
    if (!doc.document) return "Undefined title";

    if(doc.document.title === "Machine Readable Test Report") {
      return (

        <IconLabel
          awesomeIcon="file-invoice"
          label={<span class="dark-teal"><b>{doc.document.title}</b></span>}
        />

      );
    }

    return (
      <IconLabel
        awesomeIcon="file-invoice"
        label={<b>{doc.document.title}</b>}
      />
    );
  };

  const renderReporterLink = () => {
    if (!doc.user || !doc.user.email) return null;

    const reqValues = getRequestMailtoInfo(request);
    const mailToValues = { email: doc.user.email, ...reqValues };

    return (
      <div>
        <IconLabel
          awesomeIcon="user"
          label={<MailtoLink value={mailToValues} asInline={true} />}
        />
      </div>
    );
  };
  return (
    <div>
      {renderDocTitle()}
      {renderReporterLink()}
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(DocTitleReporter);
