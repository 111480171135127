import _ from "lodash";
import React from "react";
import { cyberOptions } from "containers/request/RequestList/statusOptions";

const RenderOptions = ({ options }) => {
  const list = _.isUndefined(options) ? cyberOptions : options;

  return _.map(list, option => {
    const title = option.title.toLowerCase();
    return (
      <option value={option.id} key={option.id}>
        {title}
      </option>
    );
  });
};

export default RenderOptions;
