import React, { Component } from "react";
import { connect } from "react-redux";
import SubmissionRender from "containers/submission/SubmissionRender";
import { clearRequest } from "actions/request";
import { startNewOtaSubmission } from "actions/requestOta";
import { clearWizard } from "actions/wizard";
import { clearOtaStore } from "actions/ota";

class OTASubmitPage extends Component {
  // init redux storage with default values for a request and a wizard
  componentDidMount() {
    this.props.startNewOtaSubmission(this.props.user);
  }

  componentWillUnmount() {
    this.props.clearRequest();
    this.props.clearOtaStore();
    this.props.clearWizard();
  }

  render() {
    return <SubmissionRender history={this.props.history} />;
  }
}

export default connect(null, {
  clearRequest,
  clearWizard,
  clearOtaStore,
  startNewOtaSubmission
})(OTASubmitPage);
