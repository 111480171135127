import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { getCatlLogoUrl, getPTCRBLogoUrl, getIoTLogoUrl } from "helpers/UrlHelper";
import { fileDownload } from "actions/uploads";

const CatlLogoPreview = ({ user, fileDownload, companyId, certTypes }) => {
  const id = companyId ? companyId : user.companyid;

  if (!id) return null;

  const ATLlogoURL = getCatlLogoUrl(id, "big");
  const PTCRBlogoURL = getPTCRBLogoUrl(id, "big");
  const IoTlogoURL = getIoTLogoUrl(id, "big");


  function downloadLogos() {
    if(_.includes(user.companyCertTypes, 1) || _.includes(certTypes, 1))
      fileDownload("ATL_Logo.jpg", ATLlogoURL, '', {program: 'CATL'})
    if(_.includes(user.companyCertTypes, 2) || _.includes(certTypes, 2))
      fileDownload("PTCRB_Logo.jpg", PTCRBlogoURL, '', {program: 'PTCRB'})
    if(_.includes(user.companyCertTypes, 27) || _.includes(certTypes, 27))
      fileDownload("PTCRB_Associate_Logo.jpg", PTCRBlogoURL, '', {program: 'PTCRBA'})
    if(_.includes(user.companyCertTypes, 41) || _.includes(certTypes, 41))
      fileDownload("IoT_Logo.jpg", IoTlogoURL, '', {program: 'IOT'})
  }

  return (
    <li>
      <span
        className="glow-link cursor-pointer"
        onClick={() => downloadLogos()}
      >
        ATL Logo(s)
      </span>
    </li>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  fileDownload
})(CatlLogoPreview);
