import _ from "lodash";
import { formatDate } from "helpers/DateHelper";
import { makeSimpleArgs } from "helpers/ArgsHelper";
import { getSimpleArrayByKey } from "helpers/ArrayHelper";
import { getSelectedOperatorIds } from "helpers/Operators";

export const getPendingRecognitions = request => {
  let recongitions = [];

  // map Battery Packs
  if (_.some(request.batterySuppliers, "hasPendingRecognition")) {
    request.batterySuppliers.forEach(pack => {
      if (pack.hasPendingRecognition) {
        recongitions.push({
          companyid: pack.linkedrequestvendorid,
          certtypeid: request.idPRR
        });
      }
    });
  }

  // map Adapters
  if (_.some(request.adapterSuppliers, "hasPendingRecognition")) {
    request.adapterSuppliers.forEach(adapter => {
      if (adapter.hasPendingRecognition) {
        const isACR = Number(adapter.type) === 2;
        const certtypeid = isACR ? request.idACR : request.idARR;

        recongitions.push({
          companyid: adapter.linkedrequestvendorid,
          certtypeid
        });
      }
    });
  }

  return recongitions;
};

export const getUnrecognizedAdapters = request => {
  let unrecognized = [];

  if (request.adapterSuppliers && request.adapterSuppliers.length > 0) {
    const filtered = _.filter(request.adapterSuppliers, ["type", "3"]);

    if (filtered.length) {
      filtered.forEach(adapter => {
        unrecognized.push({
          modelnumber: adapter.linkedrequestvendorproductname,
          manufname: adapter.linkedrequestvendorname
        });
      });
    }
  }

  return unrecognized;
};

export const getLinkedRequests = request => {
  let linkedRequests = [];

  const { adapterSuppliers: asup, batterySuppliers: bsup } = request;

  // map Battery Packs
  if (bsup && bsup.length > 0) {
    const packIds = _.compact(getSimpleArrayByKey(bsup, "linkedrequestid"));

    if (packIds.length) {
      packIds.forEach(packId => {
        linkedRequests.push({
          linkedrequestid: packId
        });
      });
    }
  }

  // map Adapters
  if (asup && asup.length > 0) {
    const adapterIds = _.compact(getSimpleArrayByKey(asup, "linkedrequestid"));

    if (adapterIds.length) {
      adapterIds.forEach(id => {
        linkedRequests.push({
          linkedrequestid: id
        });
      });
    }
  }

  return linkedRequests;
};

export const preparePacksAndAdapters = request => {
  const requestpendingrecognitions = getPendingRecognitions(request);
  const requestunrecognizedadapters = getUnrecognizedAdapters(request);
  const linkedrequests = getLinkedRequests(request);

  return {
    requestpendingrecognitions,
    requestunrecognizedadapters,
    linkedrequests
  };
};

export const prepareNewModelArgsBSC = request => {
  return makeSimpleArgs({
    requests: [
      {
        pocid: Number(request.pocId),
        purchaseorder: request.billingOrderNum,
        requesttypeid: 1,
        primarylabid: request.cdmaLab,
        hwversion: request.hwVersion,
        swversion: request.swVersion,
        ieeetypeid: request.ieee,
        ...preparePacksAndAdapters(request)
      }
    ],
    modelnumber: request.existedModel,
    devicemodeloperators: getSelectedOperatorIds(request.operators)
  });
};

export const prepareExistedModelArgsBSC = request => {
  return makeSimpleArgs({
    devicemodelid: request.existedModelId,
    pocid: Number(request.pocId),
    purchaseorder: request.billingOrderNum,
    requesttypeid: 1,
    primarylabid: request.cdmaLab,
    hwversion: request.hwVersion,
    swversion: request.swVersion,
    ieeetypeid: request.ieee,
    devicemodel: {
      id: request.existedModelId,
      devicemodeloperators: getSelectedOperatorIds(request.operators)
    },
    ...preparePacksAndAdapters(request)
  });
};

export const prepareExistedLegacyModelArgsBSC = request => {
  return makeSimpleArgs({
    legacydevicemodelid: request.existedModelId,
    modelnumber: request.existedModel,
    requests: [
      {
        pocid: Number(request.pocId),
        purchaseorder: request.billingOrderNum,
        requesttypeid: 1,
        primarylabid: request.cdmaLab,
        hwversion: request.hwVersion,
        swversion: request.swVersion,
        ieeetypeid: request.ieee,
        devicemodeloperators: getSelectedOperatorIds(request.operators),
        ...preparePacksAndAdapters(request)
      }
    ]
  });
};

export const defineInitialArgsBSC = request => {
  if (request.isExistedModelLegacy) {
    return {
      body: prepareExistedLegacyModelArgsBSC(request),
      url: "bsc/devicemodels"
    };
  }

  const body = request.existedModelId
    ? prepareExistedModelArgsBSC(request)
    : prepareNewModelArgsBSC(request);

  const url = request.existedModelId ? "bsc/requests" : "bsc/devicemodels";

  return { body, url };
};

export const prepareEcoArgsBSC = request => {
  return makeSimpleArgs({
    requesttypeid: 2,
    pocid: Number(request.pocId),
    primarylabid: request.cdmaLab,
    hwversion: request.hwVersion,
    swversion: request.swVersion,
    descriptionofchange: request.descriptionOfChange,
    parentid: request.parentRequest.id,
    devicemodelid: request.parentRequest.devicemodelid,
    ieeetypeid: request.ieee,
    ...preparePacksAndAdapters(request)
  });
};

export const getRequestFieldsForTestingBSC = ({
  id,
  revision,
  crslversion
}) => {
  return makeSimpleArgs({
    requestId: id,
    revision,
    crslversion
  });
};

export const getTestFieldsBSC = testModule => {
  const {
    id,
    actualstartdate,
    actualenddate,
    requesttestpassedstatusid
  } = testModule;

  // @NOTE: All battery types require testing dates range
  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return {
    id,
    ...dates,
    requesttestpassedstatusid
  };
};

export const prepareModelArgsBSC = request => {
  return makeSimpleArgs({
    modelId: request.model.id,
    modelnumber: request.modelName
  });
};

export const prepareRequestArgs = request => {
  const {
    id,
    hwVersion,
    swVersion,
    descriptionOfChange,
    crslversion
  } = request;

  return {
    id,
    crslversion,
    urlPrefix: "bsc",
    descriptionOfChange,
    hwversion: hwVersion,
    swversion: swVersion,
    ...preparePacksAndAdapters(request)
  };
};

export const prepareTestResultsArgs = (request, testModule) => {
  const testArgs = getTestFieldsBSC(testModule);

  const { crslversion, hwVersion, swVersion } = request;

  const args = {
    requesttests: [
      {
        ...testArgs
      }
    ],
    crslversion,
    hwversion: hwVersion,
    swversion: swVersion,
    ...preparePacksAndAdapters(request)
  };

  return makeSimpleArgs(args);
};
