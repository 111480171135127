import React from "react";
import { FormControl } from "react-bootstrap";

const StateStaticReadonly = ({ asBlock = true }) => {
  const msg = "Not required for selected country";
  const css = "static-form-text";

  if (asBlock) return <div className={css}>{msg}</div>;

  return <FormControl.Static className={css}>{msg}</FormControl.Static>;
};

export default StateStaticReadonly;
