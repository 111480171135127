import _ from "lodash";

const checkAwaitingLinked = request => {
  if (!request.linkedAudits) return null;
  if (_.some(request.linkedAudits, "checked")) {
    return {
      title: "Linked Site(s) Approved",
      finished: request.isAwaitingLinkedRequest === 0
    };
  }

  return null;
};

const checkReportsApproved = request => {
  return {
    title: "Audit Results Approved",
    finished: request.hasTestReportsApproved
  };
};

const checkParentSiteApproved = request => {
  return {
    title: "Parent Site Approved",
    finished: request.isAwaitingParentRequest === 0
  };
};

const checkTestReportsApproved = request => {
  return {
    title: "Test Results Approved",
    finished: request.hasTestReportsApproved
  };
};

const checkAgreementsAccepted = request => {
  return {
    title: "Documents Accepted",
    finished: !request.isSaved
  };
};

const checkIfPaid = request => {
  return {
    title: "CTIA Invoice Paid",
    finished: request.isPaid
  };
};

const checkIfParentRecognized = (request, isCert = false) => {
  const title = isCert ? "Parent Device Certified" : "Parent Device Recognized";
  return {
    title: title,
    finished: request.hasParentApproved
  };
};

const checkIfParentProductCertified = request => {
  return {
    title: "Parent Product Certified",
    finished: request.hasParentApproved
  };
};

const checkIfSitesAuthorized = request => {
  let finished = false;

  // for legacy requests - show as Authorized if it has approved status
  if (request.isLegacy && request.isApproved) {
    finished = true;
  }

  if (_.has(request, "isAwaitingManufSiteApproval")) {
    finished = request.isAwaitingManufSiteApproval === 0;
  }

  return {
    title: "Manufacturing Site(s) Authorized",
    finished
  };
};

/**
 * Returns checklist for SAR (Initial).
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistSARInitial = request => {
  if (!request) return null;

  const checkList = [
    checkReportsApproved(request),
    checkAwaitingLinked(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for SAR (Renewal/Update).
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistSARRenewal = request => {
  if (!request) return null;

  const checkList = [
    checkParentSiteApproved(request),
    checkReportsApproved(request),
    checkAwaitingLinked(request)
  ];

  return _.compact(checkList);
};

/**
 * Returns checklist for Initial CRR request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistInitialCRR = request => {
  if (!request) return null;

  const checkList = [
    checkAgreementsAccepted(request),
    checkTestReportsApproved(request),
    checkIfPaid(request),
    checkIfSitesAuthorized(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for ECO CRR request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistEcoCRR = request => {
  if (!request) return null;

  const checkList = [
    checkTestReportsApproved(request),
    checkIfParentRecognized(request),
    checkIfSitesAuthorized(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for Initial HRR request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistInitialHRR = request => {
  if (!request) return null;

  const checkList = [
    checkAgreementsAccepted(request),
    checkTestReportsApproved(request),
    checkIfPaid(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for ECO HRR request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistEcoHRR = request => {
  if (!request) return null;

  const checkList = [
    checkTestReportsApproved(request),
    checkIfParentRecognized(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for Initial PRR request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistInitialPRR = request => {
  if (!request) return null;

  const checkList = [
    checkAgreementsAccepted(request),
    checkTestReportsApproved(request),
    checkIfPaid(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for ECO PRR request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistEcoPRR = request => {
  if (!request) return null;

  const checkList = [
    checkTestReportsApproved(request),
    checkIfParentRecognized(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for Initial EPR request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistInitialEPR = request => {
  if (!request) return null;

  const checkList = [
    checkAgreementsAccepted(request),
    checkTestReportsApproved(request),
    checkIfPaid(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for ECO EPR request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistEcoEPR = request => {
  if (!request) return null;

  const checkList = [
    checkTestReportsApproved(request),
    checkIfParentRecognized(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

export const getChecklistInitialARR = request => {
  if (!request) return null;

  const checkList = [
    checkAgreementsAccepted(request),
    checkTestReportsApproved(request),
    checkIfPaid(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for ECO CRR request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistEcoARR = request => {
  if (!request) return null;

  const checkList = [
    checkTestReportsApproved(request),
    checkIfParentRecognized(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

export const getChecklistInitialACR = request => {
  if (!request) return null;

  const checkList = [
    checkAgreementsAccepted(request),
    checkTestReportsApproved(request),
    checkIfPaid(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for ECO CRR request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistEcoACR = request => {
  if (!request) return null;

  const checkList = [
    checkTestReportsApproved(request),
    checkIfParentRecognized(request, true)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for Initial BSC request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistInitialBSC = request => {
  if (!request) return null;

  const checkList = [
    checkTestReportsApproved(request),
    checkAgreementsAccepted(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for ECO BSC request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistEcoBSC = request => {
  if (!request) return null;

  const checkList = [
    checkIfParentProductCertified(request),
    checkTestReportsApproved(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for Initial BSC request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistInitialBLC = request => {
  if (!request) return null;

  const checkList = [
    checkAgreementsAccepted(request),
    checkTestReportsApproved(request),
    checkIfPaid(request)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};

/**
 * Returns checklist for ECO BLC request.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getChecklistEcoBLC = request => {
  if (!request) return null;

  const checkList = [
    checkTestReportsApproved(request),
    checkIfParentRecognized(request, true)
  ];

  // remove `null` values from array:
  return _.compact(checkList);
};
