export default [
  {
    type: "certification",
    id: 38,
    value: "Battery Compliance",
    abbr: "battery"
  },
  { type: "certification", id: 39, value: "Battery Life", abbr: "blc" },
  { type: "certification", id: 11, value: "Bluetooth", abbr: "bluetooth" },
  {
    type: "certification",
    id: 40,
    value: "Device Hardware Reliability",
    abbr: "dhr"
  },
  { type: "certification", id: 26, value: "IoT Cybersecurity", abbr: "cyber" },
  { type: "certification", id: 1, value: "LTE/CDMA", abbr: "ctia" },
  { type: "certification", id: 2, value: "PTCRB", abbr: "ptcrb" }
];
