import { connect } from "react-redux";
import React from "react";
import FormElement from "components/FormElement";
import { formatDate } from "helpers/DateHelper";

const RequestCertifiedDate = ({ request }) => {
  const certifiedDate =
    request && request.completeDate !== ""
      ? formatDate(request.completeDate)
      : null;

  if (!certifiedDate) return null;

  return (
    <FormElement
      id="reqCertifiedDate"
      label="Certified Date"
      value={certifiedDate}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(RequestCertifiedDate);
