import React, { useState } from "react";
import { connect } from "react-redux";
import { IconBase, IconLabel } from "ctia-ui";
import { downloadAgreementATL } from "actions/catl";
import "styles/Preloader.css";

const DownloadSignedAgrATL = ({ downloadAgreementATL, companyId, show, year }) => {
  const [loading, setLoading] = useState(false);

  if (!show) return null;

  return (
    <div className="Loaderlink">
      <small>
        {loading ? (
          <IconBase glyph="refresh" className="spinning" />
        ) : (
          <IconLabel awesomeIcon="download" />
        )}{" "}
        <span
          className="glow-link cursor-pointer"
          onClick={() => {
            setLoading(true);
            downloadAgreementATL(companyId, year).then(() => setLoading(false));
          }}
        >
          {loading ? "Downloading..." : "Download"}
        </span>
      </small>
    </div>
  );
};

export default connect(null, { downloadAgreementATL })(DownloadSignedAgrATL);
