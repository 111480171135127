import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Badge } from "ctia-react-bootstrap-v4";
import { LoaderButton, IconLabel } from "ctia-ui";
import { Modal, Button, Form } from "react-bootstrap";
import FormElement from "components/FormElement";
import { toMomentDate } from "helpers/DateHelper";
import { confirmPaymentATL } from "actions/payments";
import { isCustomPayMethod, isPayMethodValid } from "helpers/Payments";
import { renderNameWithFlag } from "containers/ControlPanel/Payments/RenderHelperATL";
import payMethods from "dictionaries/Payment";

import "containers/ControlPanel/Payments/payments.css";

const ConfirmPaymentModalATL = ({
  id,
  labName,
  flagCode,
  ivcNum,
  refetchAtlList,
  show,
  modalHandler,
  confirmPaymentATL
}) => {
  const [payDate, setPayDate] = useState(toMomentDate(""));
  const [payMethod, setPayMethod] = useState("");
  const [payCheckNumber, setPayCheckNumber] = useState("");
  const [customPayMethod, setCustomPayMethod] = useState("");
  const [loading, setLoading] = useState(false);

  const onDateChange = momentDate => {
    setPayDate(momentDate);
  };

  const validatePayMethod = () => {
    const isCustomPayMethodValid =
      customPayMethod === undefined ? true : isPayMethodValid(customPayMethod);
    const isBasicMethodValid = isPayMethodValid(payMethod);
    return isCustomPayMethod({ payMethod })
      ? isCustomPayMethodValid
      : isBasicMethodValid;
  };

  const validateCheckNumber = () => {
    if (payMethod !== "Check") return true;
    return payCheckNumber && !_.isEmpty(String(payCheckNumber));
  };

  const validateForm = () => {
    const date = toMomentDate(payDate);
    return date.isValid() && validatePayMethod() && validateCheckNumber();
  };

  const renderSubmitButton = () => (
    <span className="icon-left-offset">
      <LoaderButton
        bsStyle="success"
        bsSize="small"
        text={<IconLabel label="Confirm" awesomeIcon="check" />}
        onClick={() => {
          setLoading(true);
          confirmPaymentATL(id, {
            payMethod,
            customPayMethod,
            payCheckNumber,
            payDate,
            payNote: ""
          })
            .then(() => {
              modalHandler();
            })
            .catch(() => setLoading(false));
        }}
        disabled={!validateForm()}
        isLoading={loading}
        loadingText="Confirming..."
      />
    </span>
  );

  const hasCheckNum = String(payCheckNumber).length > 0;
  const validation = {
    message: hasCheckNum ? null : "Check # field allows only digits.",
    state: hasCheckNum ? null : "warning"
  };

  const cols = {
    labelColSizeSm: 4,
    labelColSizeMd: 4,
    inputColSizeSm: 8,
    inputColSizeMd: 8
  };

  return (
    <Modal show={show} onHide={modalHandler}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            Confirm Payment <Badge>{ivcNum}</Badge>
          </div>
          <small>Laboratory: {renderNameWithFlag(labName, flagCode, 22)}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form horizontal>
          <FormElement
            type="select"
            id="payMethod"
            label="Payment Method"
            value={payMethod}
            onChange={e => setPayMethod(e.target.value)}
            options={payMethods}
            {...cols}
          />
          <FormElement
            show={payMethod === "Other"}
            type="text"
            id="customPayMethod"
            label="Custom Payment Method"
            value={customPayMethod}
            onChange={e => setCustomPayMethod(e.target.value)}
            {...cols}
          />
          <FormElement
            show={payMethod === "Check"}
            type="maskedInput"
            mask="9999999999"
            maskChar={null}
            id="payCheckNumber"
            label="Check #"
            maxLength={10}
            value={payCheckNumber}
            onChange={e => setPayCheckNumber(e.target.value)}
            validationMessage={validation.message}
            validationState={validation.state}
            {...cols}
          />
          <FormElement
            type="date"
            id="payDate"
            label="Payment Date"
            value={payDate}
            onChange={onDateChange}
            {...cols}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {renderSubmitButton()}
        <Button onClick={modalHandler} className="agr-close-btn" bsSize="small">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmPaymentModalATL.propTypes = {
  show: PropTypes.bool,
  modalHandler: PropTypes.func
};

export default connect(null, { confirmPaymentATL })(ConfirmPaymentModalATL);
