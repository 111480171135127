import React from "react";
import RequestContentHRR from "containers/request/battery/RequestContent/RequestContentHRR";
import LabContentHRR from "containers/request/battery/RequestContent/RequestContentLabHRR";
import roles from "dictionaries/UserRoles";

const ContentBatteryHRR = ({ role, focusLab }) => {
  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.supplier:
    case roles.accountant:
    case roles.operator:
      return <RequestContentHRR focusLab={focusLab} />;
    case roles.lab:
      return <LabContentHRR />;
    default:
      return null;
  }
};

export default ContentBatteryHRR;
