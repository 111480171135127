import _ from "lodash";
import * as actions from "actions/types";
import { notifyMessages } from "./NotifyHelper";

export function startPreloader() {
  return {
    type: actions.START_PRELOADER
  };
}

export function stopPreloader() {
  return {
    type: actions.STOP_PRELOADER
  };
}

export function showNotice(message) {
  return {
    type: actions.SHOW_NOTICE,
    payload: message
  };
}

export function showError(text) {
  const message = { type: "error", text };

  return {
    type: actions.SHOW_NOTICE,
    payload: message
  };
}

export function forceRequestUpdate() {
  return { type: actions.FORCE_REQUEST_UPDATE };
}

// helper that allows to chain several similar async requests
// to be chained in a queue and hadled as single async action
export const makeMultiAsyncAction = (
  dataArray,
  asyncHandler,
  offset = 0
) => async dispatch => {
  await dispatch(asyncHandler(dataArray[offset]));

  offset += 1;

  if (dataArray.length > offset) {
    return await dispatch(
      makeMultiAsyncAction(dataArray, asyncHandler, offset)
    );
  }

  return Promise.resolve("makeMultiAsyncAction — DONE");
};

// TODO: multiple params
export const dispatchWithPreloader = (func, param = null) => async dispatch => {
  const quit = () => dispatch(stopPreloader());

  dispatch(startPreloader());

  return _.isNil(param)
    ? dispatch(func()).then(quit).catch(quit)
    : dispatch(func(param)).then(quit).catch(quit);
};

export const parseApiException = err => {
  if (err && err.response && err.response.data) {
    // pass a message from server
    return {
      text: err.response.data.exception,
      type: "error"
    };
  }

  // show generic common message
  return notifyMessages.errorAsyncAction;
};
