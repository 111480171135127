import _ from "lodash";
import * as actions from "actions/types";
import { filterModelFields } from "helpers/ModelHelper";

const getInterfaceLabs = otaInterface => {
  if (!otaInterface || _.isEmpty(otaInterface)) return null;
  return otaInterface.map(lab => lab.companyid);
};

const setInterfaces = payload => {
  if (!payload || !_.size(payload)) return null;

  return payload.map(({ id, altname, interfacecosts, companyinterfaces }) => {
    return {
      title: altname,
      value: id,
      costId: interfacecosts[0].costid,
      labs: getInterfaceLabs(companyinterfaces)
    };
  });
};

const filterPlans = (payload, state) => {
  const filtered = _.filter(state.interfaces, testPlan => {
    return _.findIndex(payload, ["interfaceid", testPlan.value]) === -1;
  });

  return { ...state, reqInterfaces: filtered };
};

const setOtaModelsList = (payload, state) => {
  const existedModels = payload.data.data;
  const vendorModels = filterModelFields(existedModels);

  return { ...state, vendorModels };
};

const getInterfacesInUse = (action, state) => {
  if (!action.data.data) return state;

  // mark interfaces in use to disable them for selection:
  return action.data.data.map(testEntry => testEntry.interfaceid);
};

const getInterfaces = payload => {
  return _.isNull(payload) ? null : setInterfaces(payload);
};

const addTestPlan = (payload, state) => {
  if (payload && _.has(payload, "planInfo.value")) {
    // need to remove added interface from list
    const filtered = _.reject(state.reqInterfaces, [
      "value",
      payload.planInfo.value
    ]);
    const allInUse = state.inUse.length === state.interfaces.length;
    return { ...state, allInUse, reqInterfaces: filtered };
  }

  // otherwise just return current state
  return state;
};

const removeTestPlan = (payload, state) => {
  if (payload && _.has(payload, "planInfo.value")) {
    // move interface back to list
    const updatedList = [...state.reqInterfaces, payload.planInfo];

    // remove interface id from `inUse` list as well
    _.remove(
      state.inUse,
      interfaceId => interfaceId === payload.planInfo.value
    );

    // sort by `id` and update redux storage
    return {
      ...state,
      allInUse: false,
      reqInterfaces: _.sortBy(updatedList, ["value"])
    };
  }

  // otherwise just return current state
  return state;
};

const checkModelInterfaces = (payload, state) => {
  const inUse = getInterfacesInUse(payload, state);
  const allInUse = _.every(
    state.reqInterfaces,
    test => _.indexOf(inUse, test.value) > -1
  );

  return { inUse, allInUse };
};

export default function(state = null, action) {
  switch (action.type) {
    case actions.FETCH_OTA_INTERFACES:
      const interfaces = getInterfaces(action.payload);
      return { ...state, interfaces, reqInterfaces: [...interfaces] };
    case actions.ADD_OTA_TEST_PLAN:
      return addTestPlan(action.payload, state);
    case actions.REMOVE_OTA_TEST_PLAN:
      return removeTestPlan(action.payload, state);
    case actions.GET_OTA_COSTS:
      const costs = _.isNull(action.payload) ? null : action.payload;
      return { ...state, costs };
    case actions.FETCH_OTA_LABS:
      const otaLabs = _.isNull(action.payload) ? null : action.payload;
      return { ...state, otaLabs };
    case actions.CLEAR_OTA_STORE:
      return null;
    case actions.FILTER_OTA_TEST_PLANS:
      return filterPlans(action.payload, state);
    case actions.FETCH_OTA_EXISTED_MODELS:
      return setOtaModelsList(action.payload, state);
    case actions.FETCH_MODEL_INTERFACES:
      const whatInUse = checkModelInterfaces(action.payload, state);
      return { ...state, ...whatInUse };
    case actions.CLEAR_MODEL_INTERFACES:
      return { ...state, inUse: [], allInUse: false };
    case actions.CLEAR_OTA_INTERFACES:
      return { ...state, reqInterfaces: [...state.interfaces] };
    default:
      return state;
  }
}
