import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Glyphicon } from "react-bootstrap";
import UploadQueue from "components/UploadQueue";
import {
  hasDocsForUpload,
  getUploadQueue,
  hasPendingUploads,
  getUploadedTitlesQueue,
  hasTitlesForChange,
  manageQueue
} from "helpers/UploadsHelper";
import {
  uploadTestReport,
  addToQueue,
  removeFromQueue,
  clearQueue,
  updateReportTitle
} from "actions/uploads";
import { deleteTestReport } from "actions/tests";

class OtaUploadPanel extends Component {
  componentDidMount() {
    const { tests, addToQueue, removeFromQueue, uploadTestReport } = this.props;

    // move through a whole cycle for each test module:
    tests.map(testModule => {
      const { id, reports } = testModule;

      // uploading test reports
      if (hasDocsForUpload(reports)) {
        const queue = getUploadQueue(reports);
        _.map(queue, doc => {
          const { file, title, slot } = doc;
          const uploadOptions = {
            queueId: `report-${id}-slot-${slot}`,
            slot,
            testId: id,
            title,
            fileContent: file
          };
          manageQueue(
            uploadOptions,
            uploadTestReport,
            addToQueue,
            removeFromQueue
          );
        });
      }

      // update report titles
      if (hasTitlesForChange(reports)) {
        const queue = getUploadedTitlesQueue(reports);
        _.map(queue, doc => {
          this.props.updateReportTitle(id, doc.slot, doc.title);
        });
      }

      return true;
    });
  }

  componentWillUnmount() {
    this.props.clearQueue();
  }

  getResultTitle = () => {
    const { isSubmit, isUpdate } = this.props;

    if (isSubmit) return "submitted";
    if (isUpdate) return "updated";

    return "saved";
  };

  render() {
    const { request, uploads } = this.props;
    const waitMsg = hasPendingUploads(uploads)
      ? "Please wait while test results are uploading."
      : "";

    return (
      <Alert bsStyle="warning">
        <p>
          <Glyphicon glyph="ok" className="icon-right-offset" />
          Test Result for Request <b>{request.tn}</b> has been successfully
          saved. {waitMsg}
        </p>
        <UploadQueue />
      </Alert>
    );
  }
}

function mapStateToProps({ request, tests, uploads }) {
  return { request, tests, uploads };
}

export default connect(mapStateToProps, {
  addToQueue,
  clearQueue,
  removeFromQueue,
  uploadTestReport,
  updateReportTitle,
  deleteTestReport
})(OtaUploadPanel);
