import axios from "axios";
import * as api from "dictionaries/Env";
import { sayGoodbye } from "helpers/UsersHelper";
import { certProgramms } from "helpers/AppHelper";

const defineBaseUrl = url => {
  if (url) return url;

  const programId = Number(localStorage.getItem("certProgram"));

  switch (programId) {
    case certProgramms.cyber:
      return api.apiUrl;
    case certProgramms.ota:
      return api.otaApiUrl;
    case certProgramms.battery:
      return api.sarApiUrl;
    case certProgramms.blc:
      return api.blcApiUrl;
    case certProgramms.dhr:
      return api.dhrApiUrl;
    case certProgramms.crr:
      return api.crrApiUrl;
    default:
      console.warn("Invalid Certification Program");
      return null;
  }
};

const createClientInstance = url => {
  const defaultOptions = {
    baseURL: defineBaseUrl(url),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const savedToken = localStorage.getItem("token");

    config.headers.Authorization = savedToken ? `Bearer ${savedToken}` : "";

    // replace baseURL in case cert program changed
    if (config.baseURL !== api.generalApiUrl) {
      config.baseURL = defineBaseUrl(url);
    }

    return config;
  });

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response && error.response.status === 503) {
        return sayGoodbye();
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const restClient = createClientInstance();
export const restClientGeneral = createClientInstance(api.generalApiUrl);
export const restClientAdmin = createClientInstance(api.adminApiUrl);

export const pickRestClient = (client = "") => {
  switch (client) {
    case "general":
      return restClientGeneral;
    case "admin":
      return restClientAdmin;
    default:
      return restClient;
  }
};
