import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import BatteryTestResultsInfoBar from "containers/request/battery/TestResults/BatteryTestResultsInfoBar";
import { LoaderButton } from "ctia-ui";
import TestResultPanelBattery from "components/TestResultPanelBattery";
import PageTitle from "components/RequestPageTitle";
import LabResultFormBSC from "containers/testResult/LabResultFormBSC";
import LabResultUpload from "containers/testResult/LabResultUpload";
import UnderReviewViewBSC from "containers/testResult/UnderReviewViewBSC";
import GoBackButton from "components/form/buttons/GoBackButton";
import { hasPreparedResults, isComplete } from "helpers/RequestTestsHelper";
import {
  clearRequest,
  setBatteryRequestPermissions,
  attachAppCertTypes
} from "actions/request";
import {
  submitTestResultsBSC,
  getDetailsBSC,
  setLabTestPermissionsBSC,
  resetRequestPermissionsForLabBSC
} from "actions/battery/bscRequest";
import { downloadReportBSC } from "actions/battery/bscRequest";
import {
  validateBatteryPacks,
  validateAdapters
} from "helpers/ValidatorHelper";

const backURL = "/battery/lab/results";

class TestResultsItemBSC extends Component {
  state = { showContent: false };

  fetchHandler = reqId => {
    return this.props.getDetailsBSC(reqId).then(() => {
      const { request, user } = this.props;
      this.props.setBatteryRequestPermissions(request, user);
      this.props.setLabTestPermissionsBSC();
      this.activateRender();
    });
  };

  componentDidMount() {
    this.resetRender();
    this.props.attachAppCertTypes(this.props.app);
    this.fetchHandler(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const newId = nextProps.match.params.id;
    if (newId !== this.props.match.params.id) {
      this.fetchHandler(newId);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
    this.props.resetRequestPermissionsForLabBSC();
  }

  resetRender = () => {
    this.setState({ showContent: false });
  };

  activateRender = () => {
    this.setState({ showContent: true });
  };

  onValidate() {
    const { request, tests } = this.props;
    const { testResults, hwVersion, swVersion, crslversion } = request;
    const { reports, requesttestpassedstatusid } = tests[0];

    const commonFieldsValidate =
      requesttestpassedstatusid > 0 &&
      crslversion !== "" &&
      hwVersion !== "" &&
      swVersion !== "";

    if (!commonFieldsValidate) return false;

    if (!validateBatteryPacks(request) || !validateAdapters(request))
      return false;

    return hasPreparedResults(testResults) || reports.length;
  }

  onSubmit = () => {
    const { request, tests, submitTestResultsBSC } = this.props;
    return submitTestResultsBSC(request, tests[0]);
  };

  onDone = () => {
    this.props.history.push(backURL);
  };

  render() {
    const { request, tests, downloadReportBSC } = this.props;

    if (_.isNull(request) || _.isNull(tests) || !request.id) {
      return null;
    }

    if (!this.state.showContent) return null;

    if (request.formSaved) {
      return (
        <div>
          <h1 className="block-bottom-offset">Uploading Test Results</h1>
          <TestResultPanelBattery clientType="general" certType="bsc" />
          <LoaderButton text="Done" onClick={this.onDone} disabled={false} />
        </div>
      );
    }

    const testModule = { ...tests[0] };
    const completed = isComplete(request.testStatus);

    return (
      <div>
        <PageTitle />
        <GoBackButton dest={backURL} label="Return to list" />
        <BatteryTestResultsInfoBar />
        <UnderReviewViewBSC
          show={completed}
          testModule={testModule}
          request={request}
        />
        <LabResultFormBSC show={!completed} testModule={testModule} />
        <hr />
        <LabResultUpload
          show={!completed}
          downloadHandler={downloadReportBSC}
        />
        <Row>
          <Col className="text-center">
            <LoaderButton
              bsStyle="info"
              bsSize="large"
              text="Save changes"
              loadingText="Uploading..."
              onClick={this.onSubmit}
              isLoading={request.isLoading}
              disabled={!this.onValidate()}
              show={!completed}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ app, request, tests }) {
  return { app, request, tests };
}

export default connect(mapStateToProps, {
  clearRequest,
  getDetailsBSC,
  submitTestResultsBSC,
  downloadReportBSC,
  setBatteryRequestPermissions,
  setLabTestPermissionsBSC,
  attachAppCertTypes,
  resetRequestPermissionsForLabBSC
})(TestResultsItemBSC);
