import _ from "lodash";
import * as actions from "actions/types";

const fetchLabs = (action, state) => {
  const { payload } = action;

  if (payload && payload.data && payload.data.data) {
    // filter only active labs first
    const activeOnly = _.filter(payload.data.data, "isActiveLab");
    return _.mapKeys(activeOnly, "id");
  }

  return state;
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_AUTHORIZED_LABS:
      return fetchLabs(action, state);
    case actions.LOGOUT:
      return null;
    default:
      return state;
  }
}
