import titles from "dictionaries/ReqTypesTitles";
import { isApproved } from "helpers/RequestHelper";

export function getRequestTypeWithIeeeTitle(request) {
  if (!request) return null;

  return `IEEE ${request.ieee} — ${titles[request.type]}`;
}

export function mapLinkedAudits(list) {
  if (!list || !list.length) return [];

  return list.map((audit) => {
    return {
      value: audit.linkedrequestid,
      label: audit.site,
      checked: audit.selected,
      approved: isApproved(audit.linkedrequeststatusid)
    };
  });
}
