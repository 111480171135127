import React from "react";
import DocTitleReporter from "components/DocTitleReporter";
import StatusBadge from "components/Badges/DocumentStatusBadge";
import DownloadReport from "containers/request/OTA/TestPlans/ApproveReports/ButtonDownloadReport";
// import ApproveReport from "containers/request/OTA/TestPlans/ApproveReports/ButtonApproveReport";
// import RejectReport from "containers/request/OTA/TestPlans/ApproveReports/ButtonRejectReport";

const OtaApproveReportRow = ({ report }) => {
  if (!report) return null;

  return (
    <tr>
      <th>
        <StatusBadge doc={report} />
      </th>
      <th>
        <DocTitleReporter doc={report} />
      </th>
      <th>
        {/* <ApproveReport report={report} />
        <RejectReport report={report} /> */}
        <DownloadReport report={report} />
      </th>
    </tr>
  );
};

export default OtaApproveReportRow;
