import React from "react";
import { connect } from "react-redux";
import pluralize from "pluralize";

const UsersOfLab = ({ users2labs }) => {
  if (users2labs && users2labs.labUsers) {
    const { labUsers } = users2labs;
    const isEmpty = labUsers.length === 0;

    const renderList = () => {
      if (isEmpty) return "No users assigned to this lab.";

      return (
        <ul className="ctia-list">
          {users2labs.labUsers.map(user => {
            return (
              <li>
                {user.lastname}, {user.firstname}
              </li>
            );
          })}
        </ul>
      );
    };

    const renderListHeader = () => {
      if (isEmpty) return null;

      const pluralizedLabel = pluralize("Assigned User", labUsers.length, true);

      return <h4>{pluralizedLabel}:</h4>;
    };

    return (
      <>
        {renderListHeader()}
        {renderList()}
      </>
    );
  }

  return null;
};

function mapStateToProps({ users2labs }) {
  return { users2labs };
}

export default connect(mapStateToProps)(UsersOfLab);
