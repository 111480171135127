import React from "react";
import { hasOtaReports } from "helpers/RequestTestsHelper";
import { formatDate } from "helpers/DateHelper";

const OtaTestStatusInfo = ({ testModule }) => {
  if (!testModule) return null;

  const {
    planversion,
    actualstartdate,
    actualenddate,
    reportedat,
    reports
  } = testModule.details;

  const preparePassedInfo = () => {
    return [
      {
        label: "Test Plan Version",
        value: planversion
      },
      {
        label: "Test Start Date",
        value: formatDate(actualstartdate)
      },
      {
        label: "Test Finish Date",
        value: formatDate(actualenddate)
      },
      {
        label: "Test Reported Date",
        value: formatDate(reportedat)
      }
    ];
  };

  const renderPassTestInfo = () => {
    if (hasOtaReports(reports)) {
      const details = preparePassedInfo();
      return (
        <div>
          {details.map(field => {
            return (
              <div key={field.label}>
                <b>{field.label}:</b> {field.value}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div>
      <b>Lab Name:</b> {testModule.catlInfo.name}
      <br />
      {renderPassTestInfo()}
    </div>
  );
};

export default OtaTestStatusInfo;
