import React from "react";
import { Link } from "react-router-dom";

const TermsContent = () => {
  const policyLink = (
    <Link to="/policy" className="glow-link" target="_blank">
      Privacy Policy
    </Link>
  );

  const emailLink = (
    <a className="glow-link" href="mailto:support@ctiacertification.org">
      support@ctiacertification.org
    </a>
  );

  return (
    <>
      <p>Effective: November 17, 2020</p>
      <h2>General</h2>
      <p>
        These Terms of Use (“TOU”) constitute a binding agreement between CTIA
        Certification LLC (“CTIA Certification” “we” “us” and “our”) and you. We
        use the terms “you” “user” and “your” to mean any person registered and
        approved to use the CTIA Certification Database (“Database”), including
        any organization or person using this Database on an organization’s
        behalf. These TOU govern your use of all portions of this Database, and
        any associated material or functionality contained on this Database.
        This Database is offered to you conditioned on your acceptance, without
        modification, of the terms and conditions contained herein. By using
        this Database, you confirm your acceptance of, and agree to be bound by
        these TOU. If you do not agree with any of these TOU, do not use this
        Database.
      </p>
      <p className="bold">
        ARBITRATION NOTICE: YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE
        RESOLVED BY BINDING INDIVIDUAL ARBITRATION AND THAT YOU WAIVE YOUR RIGHT
        TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE LAWSUIT OR
        ARBITRATION.
      </p>
      <h2>CTIA Certification Database</h2>
      <p>
        This Database is comprised of all software, hardware, application
        programming interfaces (“APIs”), technologies, applications, frontend
        user interface, and content posted herein. Providing you with services
        via this Database requires us to collect and use your information. Our{" "}
        {policyLink} describes how we collect, use, and share information that
        you provide to us.
      </p>
      <h2>Modification</h2>
      <p>
        CTIA Certification reserves the right to change the terms and conditions
        of these TOU from time to time as we deem appropriate. Such changes,
        modifications, additions, or deletions shall be effective immediately
        upon posting to this Database unless otherwise indicated. You are
        responsible for regularly reviewing these terms and conditions. Your
        continued use of this Database after changes are posted constitutes your
        acceptance of the most recent version of these TOU. CTIA Certification
        may change, restrict access to, suspend, or discontinue this Database
        (or any portion of this Database) at any time.
      </p>
      <h2>Privacy</h2>
      <p>
        CTIA Certification will operate this Database in accordance with its{" "}
        {policyLink} which is incorporated into these TOU by reference.
      </p>
      <h2>Use of this Database</h2>
      <p>
        You represent and warrant to CTIA Certification that you will not use
        this Database (i) for any unlawful purpose; (ii) in any manner
        prohibited by these TOU; and (iii) in any manner which could damage,
        disable, overburden, or impair this Database or interfere with any other
        party’s use and enjoyment of this Database.
      </p>
      <p>
        You agree that when using this Database, you will comply with the
        following:
      </p>
      <ul className="ctia-list">
        <li>You are at least 18 years of age;</li>
        <li>
          You will keep your password confidential and will not share it with
          others;
        </li>
        <li>
          You will not buy, sell, or transfer any aspect of your account or
          login credentials;
        </li>
        <li>
          You will contact us at {emailLink} when you no longer need your
          account, including when planning to leave your company, so that it may
          be disabled;
        </li>
        <li>
          You will use this Database in a professional manner and not do
          anything unauthorized, fraudulent, or misleading;
        </li>
        <li>
          You will not impersonate others or provide inaccurate, false, or
          misleading information. Your use of this Database requires you to
          disclose your identity to us and to others who access and use this
          Database. As such, you may not impersonate someone you are not, or
          create an account for someone else unless you have express permission;
        </li>
        <li>
          You will not violate or help others to violate these TOU, the{" "}
          {policyLink}, or any other applicable policies or guidelines that
          govern your use of this Database;
        </li>
        <li>
          You will not use this Database to solicit bids, discuss suppliers, or
          engage in anticompetitive behavior;
        </li>
        <li>You will not violate any applicable laws or regulations;</li>
        <li>
          You will not upload information that contains material that is
          private, sensitive, confidential, or proprietary, or protected by
          intellectual property laws, including without limitation material
          protected by patent, copyright, trademark, trade secrets, or by rights
          of privacy or publicity unless you own or control the rights thereto
          or have received all necessary consents;
        </li>
        <li>
          You will not send, upload, publish, distribute, or disseminate any
          inappropriate, threatening, abusive, profane, defamatory, infringing,
          obscene, indecent, otherwise objectionable materials, or any materials
          which encourage conduct that would constitute a criminal offense, give
          rise to civil liability, violate any law, or contain any advertising
          or any solicitation with respect to products or services;
        </li>
        <li>
          You will not upload viruses, corrupted files, malicious code, any
          software, any information, or take any action that may impair or
          damage the operation of this Database or another’s computer or
          network;
        </li>
        <li>
          You will not reverse engineer, decompile, disassemble, decipher, or
          otherwise attempt to derive the source code for this Database or any
          related technology;
        </li>
        <li>
          You will not engage in “framing,” “mirroring,” or otherwise simulating
          the appearance or the function of this Database or use any meta tags
          or any other “hidden text” utilizing the names or trademarks of CTIA
          Certification;
        </li>
        <li>
          You will not reproduce, re-transmit, re-present in any form, in whole
          or in part, any content, programming code, images, or graphics without
          the express written permission of CTIA Certification;
        </li>
        <li>
          You will not frame, squeeze back, overlay or employ other techniques
          to enclose or display this Database, or any trademark, logo, content,
          or other proprietary information (including images, text, page layout,
          or form) included on this Database, with any other software or content
          of a third party;
        </li>
        <li>
          You will only download or print copies of Database content for
          permitted purposes;
        </li>
        <li>
          You will not access, monitor, or copy any content or information of
          this Database using any “robot,” “spider,” “deep link,” “scraper” or
          other automated means, methodology, algorithm, device, or any manual
          process for any purpose; or
        </li>
        <li>
          Otherwise violate any CTIA Certification policies or guidelines.
        </li>
      </ul>
      <h2>Notices and Messages</h2>
      <p>
        By accessing this Database you acknowledge and agree that we may provide
        notices and messages to you through this Database and to the contact
        information you provided. In order to receive our communications, ensure
        that your contact information is up to date.
      </p>
      <h2>Content that You Provide to Us</h2>
      <p>
        As between us and you, you own the content that you upload to this
        Database or that you provide to us to upload on your behalf. CTIA
        Certification, our vendors and service providers, and certain authorized
        users of the Database, can see, copy, and use the content that you
        provide to us.
      </p>
      <h2>Service Availability</h2>
      <p>
        We may modify, suspend, or terminate your access to this Database, or
        any portion thereof, at any time for any reason, as we determine in our
        sole and reasonable discretion.
      </p>
      <h2>Complaints Regarding Content</h2>
      <p>
        To help support this Database, we encourage you to report content or
        conduct that you believe violates your rights, these TOU, and any other
        applicable policies and guidelines.
      </p>
      <h2>Content Deletion and Modification</h2>
      <p>
        We retain the right to remove or take down any content or information
        that you share, upload, or publish on this Database, without notice to
        you, if we believe that it violates these TOU, our {policyLink}, other
        applicable policies and guidelines, or we are permitted or required to
        do so by law.
      </p>
      <h2>Content that You Choose to Delete</h2>
      <p>
        You will be able to remove or delete content that you provide, upload,
        publish, or share on this Database. Content you delete may persist for a
        limited period of time in backup copies and will still be visible where
        others have shared it. If you wish to delete your account, information,
        or content from this Database you should contact {emailLink}.
      </p>
      <h2>Your Use of User Content</h2>
      <p>
        Your use of others’ content and any information uploaded, shared, or
        published to this Database is at your own risk. When you access this
        Database, you may encounter content from other users that is inaccurate,
        incomplete, delayed, misleading, illegal, offensive, or otherwise
        harmful. While CTIA Certification reviews the content that is uploaded
        by users, we do not review the content before it has been published. You
        agree that we are not responsible for the content uploaded and published
        by others.
      </p>
      <h2>Third Party Websites</h2>
      <p>
        This Database may contain links to third party websites within uploaded
        content. Third party websites are not under the control of CTIA
        Certification and we are not responsible for the contents of any third
        party website. The inclusion of any link in this Database to a third
        party website is as a convenience and does not imply endorsement by CTIA
        Certification of the third party sites or any association or
        relationship with its operators. Your use of any third party website and
        any purchases of products or services from such third party websites are
        subject to the terms and conditions of the third party website. You
        agree that you will bring no suit or claim against CTIA Certification
        arising from or based upon any such use of third party websites,
        including, without limitation, websites that we link to from this
        Database.
      </p>
      <h2>Disabling or Terminating Your Account</h2>
      <p>
        If you believe that your account has been suspended, restricted, or
        terminated in error or you wish to disable or delete your account,
        please contact {emailLink}.
      </p>
      <h2>Ownership</h2>
      <p>
        This Database and the compilation of the content displayed herein
        including all software, data, and information used to provide this
        Database, including text, images, method of display and presentation,
        source code, embedded routines, programs, and other materials, as well
        as all copyrights, trademarks, patents, and other intellectual property
        rights therein or thereto, are owned by and are proprietary to CTIA
        Certification, its affiliate, and licensees and are protected by the
        copyright laws of the United States and other countries. Nothing in
        these TOU or its performance shall grant you any right, title, interest,
        or license in or to the CTIA Certification names, logos, logotypes,
        trade dress, designs, or other trademarks.
      </p>
      <p>
        CTIA Certification does not claim ownership of the individual materials
        or information you may provide to CTIA Certification, including without
        limitation feedback, suggestions, uploads, inputs, or submissions
        (collectively, a “Submission”) to this Database. However, if you provide
        a Submission, you: represent and warrant that you own or otherwise
        control all of the rights to your Submission including, without
        limitation, all the rights necessary for you to provide, upload, input,
        or submit the Submission and license or sublicense the Submission.
      </p>
      <p>
        You may not modify, copy, distribute, transmit, display, perform,
        reproduce, publish, license, create derivative works from, transfer, or
        sell any information, software, products, or services obtained from this
        Database. Except as expressly authorized in these TOU, you may not
        modify, distribute, reproduce, display, or use this Database (or any
        elements thereof). All rights not expressly granted to you by us in
        these TOU are reserved to CTIA Certification and you acknowledge that
        you do not acquire any ownership rights by accessing or downloading
        copyrighted material from this Database.
      </p>
      <h2>Copyright Complaints</h2>
      <p>
        CTIA Certification respects the rights of all copyright holders and in
        this regard, we have adopted and implemented a policy that provides for
        the termination, in appropriate circumstances, of the account of users
        of this Database who have infringed on the rights of copyright holders.
        If you believe in good-faith that anything in this Database infringes on
        any copyright that you own or control, you may file a written
        notification, as required by the United States Digital Millennium
        Copyright Act, and email it to our designated Copyright Agent at{" "}
        {emailLink}.
      </p>
      <h2>Import/Export Control</h2>
      <p>
        You agree not to submit any content to this Database or commit any act
        involving the transfer of information relating to any content in
        violation of applicable import/export control, espionage, or national
        security laws.
      </p>
      <h2>General Disclaimer</h2>
      <p>
        The information, software, products, and services included in or
        available through this Database may include inaccuracies or
        typographical errors. CTIA Certification may make improvements or
        changes to this Database at any time. Advice received via this Database
        should not be relied upon for personal, medical, legal, or financial
        decisions, and you should consult an appropriate professional for
        specific advice.
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to indemnify CTIA Certification from and against any and all
        liabilities, expenses (including without limitation attorneys’ fees) and
        damages arising out of claims based upon your use of this Database,
        including without limitation any claim of libel, defamation, violation
        of rights of privacy or publicity, loss of service by other members, and
        the infringement of intellectual property rights or other rights. CTIA
        Certification will notify you of any claim for which CTIA Certification
        seeks indemnification and will afford you the opportunity to participate
        in the defense of such claim, provided that your participation will not
        be conducted in a manner prejudicial to CTIA Certification’s interests,
        as reasonably determined by CTIA Certification in its sole discretion.
      </p>
      <h2>NO WARRANTIES</h2>
      <p>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THIS DATABASE, OR
        ANY OF THE CONTENT YOU ACCESS ON OR THROUGH THIS DATABASE, IS AT YOUR
        OWN RISK. THIS DATABASE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
        BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED
        (INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, FUNCTIONALITY, TITLE,
        AND NON-INFRINGEMENT). NEITHER CTIA CERTIFICATION NOR ANY PERSON
        ASSOCIATED WITH CTIA CERTIFICATION (INCLUDING ITS MEMBERS) MAKES ANY
        WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
        RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THIS DATABASE. ANY
        MATERIAL VIEWED, DOWNLOADED, OTHERWISE OBTAINED THROUGH, OR DERIVED FROM
        THE USE OF THIS DATABASE IS ACCESSED AT YOUR OWN DISCRETION AND RISK.
        YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM,
        OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR VIEWING OF ANY SUCH
        MATERIAL.
      </p>
      <p>
        WITHOUT LIMITING THE FOREGOING, CTIA CERTIFICATION DOES NOT REPRESENT OR
        WARRANT THAT THIS DATABASE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
        UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED; THAT THIS DATABASE IS
        FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR THAT THIS DATABASE WILL
        OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. THE FOREGOING DOES NOT AFFECT
        ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </p>
      <h2>LIMITATION OF LIABILITY</h2>
      <p>
        TO THE EXTENT PERMITTED BY LAW, CTIA CERTIFICATION, INLCUDING ITS
        DIRECTORS, OFFICERS, MEMBERS, AND ASSIGNS WILL NOT BE LIABLE IN
        CONNECTION WITH THESE TOU OR YOUR USE OF THIS DATABASE FOR ANY DIRECT,
        INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES
        INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOST DATA, LOSS
        OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR ANY
        OTHER COMMERCIAL DAMAGES OR LOSSES, EVEN IF CTIA CERTIFICATION HAS BEEN
        ADVISED OF THE POSSIBILITY THEREOF AND REGARDLESS OF THE LEGAL OR
        EQUITABLE THEORY UPON WHICH THE CLAIM IS BASED.
      </p>
      <h2>Restricted Rights</h2>
      <p>
        The information that is downloaded from this Database for or on behalf
        of the Government of the United States of America and/or its agencies
        (“US Government”) is provided with Restricted Rights. Use, duplication,
        or disclosure by the US Government is subject to the restrictions set
        forth in DFARS 252.227-7013 and FAR 52.227-14 and 48 CFR 52.227-19, as
        applicable.
      </p>
      <h2>Governing Law</h2>
      <p>
        These TOU are governed by the laws of the District of Columbia, USA. You
        hereby consent to the exclusive jurisdiction and venue of courts in the
        District of Columbia in all disputes arising out of or relating to the
        use of this Database. Use of this Database is unauthorized in any
        jurisdiction that does not give effect to all provisions of these terms
        and conditions, including, without limitation, this paragraph.
      </p>
      <h2>Contact Information</h2>
      <p>
        If you have any questions regarding these TOU or this Database, please
        contact {emailLink}.
      </p>
    </>
  );
};

export default TermsContent;
