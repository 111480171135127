import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { requestFields } from "helpers/BatterySupplierHelper";
import { formatModelsList, getPackCellById } from "helpers/SupplierHelper";
import { setValue } from "actions/request";
import { sortByFieldname } from "helpers/SortHelper";

const PackCellsSelectModel = ({ request, suppliers, setValue }) => {
  const [show, setShow] = useState(false);
  const requestField = requestFields.model;
  const supplier = request[requestFields.supplier];
  const { cellModels } = suppliers;

  useEffect(() => {
    if (supplier && supplier > 0) {
      setShow(true);
    } else {
      setShow(false);
      setValue(requestField, "");
    }
  }, [supplier, requestField, setValue]);

  const onChange = event => {
    const modelId = event.target.value;

    setValue(requestField, modelId);

    const cell = getPackCellById(modelId, cellModels);

    if (cell) {
      setValue(requestFields.modelId, modelId);
      setValue(requestFields.modelName, cell.title);
      setValue(requestFields.linkedRequestId, cell.reqId);
    } else {
      setValue(requestFields.modelId, null);
      setValue(requestFields.modelName, null);
      setValue(requestFields.linkedRequestId, null);
    }
  };

  if (!show || !cellModels || !cellModels.length) return null;

  const sortedCellModels = sortByFieldname(cellModels, "title");

  return (
    <FormElement
      type="select"
      id={requestField}
      value={request[requestField] || 0}
      onChange={onChange}
      label="Cell Model"
      disabledOptionLabel="in use"
      options={formatModelsList(sortedCellModels, request.cellSuppliers)}
    />
  );
};

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, { setValue })(PackCellsSelectModel);
