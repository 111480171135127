import React from "react";
import { connect } from "react-redux";
import LabResultForm from "containers/testResult/LabResultFormOta";
import LabResultUpload from "containers/testResult/LabResultUploadOta";
import UnderReviewView from "containers/testResult/UnderReviewViewCommon";
import { isComplete } from "helpers/RequestTestsHelper";

const OTATestResultsItem = ({ request, testModule }) => {
  if (!testModule) return null;

  const completed = isComplete(request.testStatus);

  return (
    <div>
      <UnderReviewView show={completed} testModule={testModule} />
      <LabResultForm show={!completed} testModule={testModule} />
      <LabResultUpload show={!completed} testModule={testModule} />
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(OTATestResultsItem);
