import { PRELOADER_START, PRELOADER_FINISH } from 'actions/types';
import { resolvePromise } from 'helpers/PromiseHelper';

export function startPreloader() {
  return resolvePromise({
    type: PRELOADER_START,
    payload: true
  });
}

export function finishPreloader() {
  return resolvePromise({
    type: PRELOADER_FINISH,
    payload: false
  });
}
