// ES6 Helpers
import _ from "lodash";

// React/Redux
import React from "react";
import { connect } from "react-redux";
import { setUserRequestFormValues } from "actions/newUserRequest";

import { Form } from "ctia-react-bootstrap-v4";

import CompanyCombobox
  from "components/CompanySelectors/CompanyCombobox/CompanyCombobox";
import CompanyTypeSelector
  from "components/CompanySelectors/CompanyType/CompanyTypeSelector";
import FormFieldLabel from "components/form/FormFieldLabel";
import { isPtcrb } from "containers/users/UserRequest/UserRequestForm/FormHelpers";

const CompanyFormFields = ({
  newUserRequest,
  setUserRequestFormValues
}) => {
  if (_.isEmpty(newUserRequest) || _.isEmpty(newUserRequest.selectedUserRequest))
    return null;

  const { formValues } = newUserRequest.selectedUserRequest;
  if (_.isEmpty(formValues)) return null;

  const { company, companyDescription, companyType, companyWebsite, program }
    = formValues;
  const isPTCRB = isPtcrb(program);

  return (
    <>
      <Form.Group>
        <CompanyTypeSelector
          formElementProperties={{fullWidthColumns: true}}
          isPTCRB={isPTCRB}
          isRequired={true}
          onChange={companyType => setUserRequestFormValues({
            companyType
          })}
          value={companyType}
        />
      </Form.Group>
      <Form.Group>
        <CompanyCombobox
          companyType={companyType}
          formElementProperties={{fullWidthColumns: true}}
          isPtcrb={isPTCRB}
          isRequired={true}
          onChange={({ companyId, companyName }) => setUserRequestFormValues({
            companyId,
            company: _.isUndefined(companyName)
              ? formValues.company
              : companyName
          })}
          value={company}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <FormFieldLabel label="Company Website" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          isInvalid={_.isEmpty(companyWebsite)}
          onChange={event => setUserRequestFormValues({
            companyWebsite: event.target.value
          })}
          value={companyWebsite}
        />
      </Form.Group>
      <Form.Group controlId="companyDescription">
        <Form.Label>
          <FormFieldLabel label="Description of company’s business/operations" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          as="textarea"
          isInvalid={_.isEmpty(companyDescription)}
          onChange={event => setUserRequestFormValues({
            companyDescription: event.target.value
          })}
          style={{height: 150}}
          value={companyDescription}
        />
      </Form.Group>
    </>
  );
};

function mapStateToProps({ newUserRequest }) {
  return { newUserRequest };
};

export default connect(mapStateToProps, {
  setUserRequestFormValues
})(CompanyFormFields);
