import { restClientGeneral } from "libs/restClient";
import * as actions from "actions/types";
import * as call from "helpers/ActionHelper";
// import * as args from "helpers/ArgsBatteryHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { makeSimpleArgs } from "helpers/ArgsHelper";
// import * as sarAddresses from "actions/battery/sarAddresses";
// import * as sar from "actions/battery/sar";

/** Non SAR manufacturing sites, uses General API */

export const fetchNonSarSites = companyId => async dispatch => {
  // we need companyId in case of fetching as lab/staff
  try {
    const urlTail = companyId ? ` AND companyid=${companyId}` : "";
    const url = `/manufsites?page=0&where=addressid IS NULL${urlTail}&orderBy=name`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_NON_SAR_SITES,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const addNewSite = (siteName, companyId) => async dispatch => {
  try {
    const bodyObj = {
      name: siteName,
      description: siteName
    };

    if (companyId) {
      bodyObj.companyid = companyId;
    }

    const reqBody = makeSimpleArgs(bodyObj);

    const response = await restClientGeneral.post(
      "/manufsites",
      JSON.stringify(reqBody)
    );

    dispatch({
      type: actions.ADD_NON_SAR_SITE,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantCreateManufSite));

    return Promise.reject(e);
  }
};

export const updateNonSarSiteName = (
  newName,
  companyId,
  siteId
) => async dispatch => {
  try {
    const url = `/manufsites/${siteId}`;
    const body = {
      name: newName,
      description: newName
    };
    const reqBody = makeSimpleArgs(body);
    const response = await restClientGeneral.put(url, JSON.stringify(reqBody));

    dispatch({
      type: actions.UPDATE_NON_SAR_SITE_NAME,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const deleteNonSarSite = siteId => async dispatch => {
  try {
    const url = `/manufsites/${siteId}`;
    const response = await restClientGeneral.delete(url);

    dispatch({
      type: actions.DELETE_NON_SAR_SITE,
      payload: siteId
    });

    return response;
  } catch (e) {
    // send exact message from the server response
    // since it includes list of linked requests
    return Promise.reject(e.response.data.exception);
  }
};

export function clearNonSarSites() {
  return {
    type: actions.CLEAR_NON_SAR_SITES
  };
}
