import React from "react";
import { connect } from "react-redux";
import { DownloadButton } from "ctia-ui";
import { invoiceDownload } from "actions/requestCyber";

const DownloadInvoice = ({ request, invoiceDownload }) => {
  return (
    <DownloadButton
      size="small"
      label="Download Invoice (PDF)"
      action={() => invoiceDownload(request)}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  invoiceDownload
})(DownloadInvoice);
