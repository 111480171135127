import { getSelectedManufSitesIds } from "helpers/ManufSiteHelper";
import { formatDate } from "helpers/DateHelper";
import { makeSimpleArgs } from "helpers/ArgsHelper";
import { filterCheckedOnlyValues } from "helpers/ArrayHelper";

/*** Autocomplete-based solution ***/

const getCommonRequestFields = request => {
  return {
    billingid: Number(request.billingId),
    pocid: Number(request.pocId),
    purchaseorder: request.billingOrderNum,
    requesttypeid: 1,
    primarylabid: request.cdmaLab,
    revision: request.revision,
    requestmanufsites: getSelectedManufSitesIds(request.manufSites),
    ieeetypeid: request.ieee
  };
};

// Prepare args in case new model was entered
// (not selected from a list of existed models)
export const prepareNewModelArgsARR = request => {
  const commonFields = getCommonRequestFields(request);
  return makeSimpleArgs({
    requests: [{ ...commonFields }],
    modelnumber: request.existedModel
  });
};

// Prepare args in case model selected from existing models
export const prepareExistedModelArgsARR = request => {
  const commonFields = getCommonRequestFields(request);

  return makeSimpleArgs({
    devicemodelid: request.existedModelId,
    ...commonFields
  });
};

export const defineInitialArgsARR = request => {
  const body = request.existedModelId
    ? prepareExistedModelArgsARR(request)
    : prepareNewModelArgsARR(request);

  const url = request.existedModelId ? "arr/requests" : "arr/devicemodels";

  return { body, url };
};

export const prepareEcoArgsARR = request => {
  // if non SAR sites list goes untouched - it should be inherited from parent instead
  const requestmanufsites =
    request.linkedManufsites && request.linkedManufsites.length > 0
      ? getSelectedManufSitesIds(request.linkedManufsites)
      : request.parentRequest.requestmanufsites.map(site => {
          return { manufsiteid: site.manufsiteid };
        });

  return makeSimpleArgs({
    requesttypeid: 2,
    pocid: Number(request.pocId),
    primarylabid: request.cdmaLab,
    revision: request.revision,
    descriptionofchange: request.descriptionOfChange,
    requestmanufsites,
    parentid: request.parentRequest.id,
    devicemodelid: request.parentRequest.devicemodelid,
    ieeetypeid: request.ieee
  });
};

export const getRequestFieldsForTestingARR = ({
  id,
  revision,
  crslversion
}) => {
  return makeSimpleArgs({
    requestId: id,
    revision,
    crslversion
  });
};

export const getTestFieldsARR = testModule => {
  const {
    id,
    actualstartdate,
    actualenddate,
    requesttestpassedstatusid
  } = testModule;

  // @NOTE: All battery types require testing dates range
  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return {
    id,
    ...dates,
    requesttestpassedstatusid
  };
};

export const prepareModelArgsARR = request => {
  return makeSimpleArgs({
    modelId: request.model.id,
    modelnumber: request.modelName
  });
};

export const getDeviceFieldsForTestingARR = request => {
  return makeSimpleArgs({
    modelId: request.model.id
  });
};

export const prepareRequestArgs = (request, sites) => {
  const { id, revision, descriptionOfChange, crslversion } = request;
  const requestmanufsites = getSelectedManufSitesIds(sites);

  return {
    id,
    revision,
    crslversion,
    descriptionOfChange,
    requestmanufsites,
    urlPrefix: "arr"
  };
};

const getLinkedNonSarSites = request => {
  if (request && request.linkedManufsites) {
    return filterCheckedOnlyValues(
      request.linkedManufsites,
      "manufsiteid",
      "value"
    );
  }

  return [];
};

export const prepareTestResultsArgs = (request, testModule, certTypes) => {
  const requestmanufsites = getLinkedNonSarSites(request);
  const testArgs = getTestFieldsARR(testModule);

  const { model, crslversion, revision } = request;
  const devicemodel = {
    id: model.id
  };

  const args = {
    devicemodel,
    crslversion,
    revision,
    requesttests: [
      {
        ...testArgs
      }
    ],
    requestmanufsites // linked non SAR sites
  };

  return makeSimpleArgs(args);
};
