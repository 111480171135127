import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Table } from "react-bootstrap";
import { Bubble } from "ctia-ui";
import FormElement from "components/FormElement";
import RequestControls from "containers/request/RequestControls";
import { clearRequests } from "actions/requests";
import { fetchCyberModels } from "actions/requestCyber";
import {
  fetchModel,
  updateModelField,
  clearModel,
  fetchModelVersions
} from "actions/model";
import { getStatusTitle } from "helpers/RequestHelper";
import { sortByFieldname } from "helpers/SortHelper";
import { getRequestOwner } from "helpers/UsersHelper";
import { startPreloader, finishPreloader } from "actions/preloader";

class SelectModel extends Component {
  componentDidMount() {
    const { request, user, fetchCyberModels } = this.props;
    const ownerId = getRequestOwner(request, user);

    fetchCyberModels(ownerId);
  }

  componentWillUnmount() {
    this.props.clearRequests();
  }

  validate() {
    const { model } = this.props;
    return model && model.selectedVersion;
  }

  formatRequestList() {
    const unsorted = _.map(this.props.requests.models, req => {
      return {
        title: req.modelnumber,
        value: req.id
      };
    });
    const sorted = sortByFieldname(unsorted, "title");

    sorted.unshift({
      title: "--- Select a Model ---",
      value: 0
    });

    return sorted;
  }

  // TODO: move to action creator
  onModelSelect(event) {
    const {
      startPreloader,
      finishPreloader,
      fetchModel,
      fetchModelVersions,
      clearModel
    } = this.props;

    const modelId = event.target.value;

    clearModel();

    // just clear the model if selected default (empty) option
    if (Number(modelId) === 0) return null;

    startPreloader();
    fetchModel(modelId).then(fetchModelVersions(modelId).then(finishPreloader));
  }

  onRowClick(id) {
    // emulate real event
    const event = {
      target: {
        name: "selectedVersion",
        value: id
      }
    };

    this.props.updateModelField(event);
  }

  renderRows() {
    const { versions, selectedVersion } = this.props.model;

    return _.map(versions, elem => {
      const tn = elem.formatedtransactionnumber;
      const rowStyle =
        selectedVersion === elem.id ? "alert-warning" : "cursor-pointer";
      return (
        <tr
          key={elem.id}
          onClick={this.onRowClick.bind(this, elem.id)}
          className={rowStyle}
        >
          <td>{tn}</td>
          <td>{elem.hwversion}</td>
          <td>{elem.swversion}</td>
          <td>{getStatusTitle(elem.requeststatusid)}</td>
        </tr>
      );
    });
  }

  render() {
    const { wizard, requests, model, app } = this.props;

    // if (!wizard) return null;
    if (wizard.step !== 1 || !requests) return null;

    if (
      requests &&
      _.has(requests, "models") &&
      (requests.models === false || requests.models.length === 0)
    ) {
      return (
        <Bubble type="danger">
          There are no devices available. An{" "}
          <Link to={`${app.urlPrefix}request/new/initial`}>
            initial certification
          </Link>{" "}
          is needed first.
        </Bubble>
      );
    }

    const parentModelId = model && model.info ? model.info.id : 0;
    const noVersions =
      model && model.versions ? model.versions.length === 0 : false;

    return (
      <div>
        <Bubble show={!parentModelId}>Please select a model.</Bubble>
        <Form horizontal>
          <FormElement
            type="select"
            id="parentModelId"
            label="Select Model"
            value={parentModelId}
            onChange={this.onModelSelect.bind(this)}
            options={this.formatRequestList()}
          />
          <Bubble show={parentModelId > 0 && !noVersions}>
            Please select the parent device's HW/SW version.
          </Bubble>
          <Bubble show={noVersions} type="warning">
            Selected model has not been approved yet. Please choose another
            model.
          </Bubble>
          {parentModelId > 0 && !noVersions ? (
            <Table condensed hover className="ctia-table">
              <thead>
                <tr>
                  <th>Request ID</th>
                  <th>HW Version</th>
                  <th>SW Version</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>{this.renderRows()}</tbody>
            </Table>
          ) : null}
          <RequestControls show={model !== null} isValid={this.validate()} />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, requests, model, app, wizard, user }) {
  return { request, requests, model, app, wizard, user };
}

export default connect(mapStateToProps, {
  fetchCyberModels,
  fetchModel,
  updateModelField,
  clearModel,
  clearRequests,
  fetchModelVersions,
  startPreloader,
  finishPreloader
})(SelectModel);
