import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { InlinePreloader, LoaderButton } from "ctia-ui";
import LabResultUpload from "containers/testResult/LabResultUpload";
import TestResultPanelBattery from "components/TestResultPanelBattery";
import { hasPreparedResults } from "helpers/RequestTestsHelper";
import { prepareTestResultsArgs } from "helpers/ArgsHelperBLC";
import {
  fetchTestReportsBLC,
  sendTestResultsBLC,
  downloadReportBLC,
  updateDeviceInfoBLC
} from "actions/battery/blcRequest";

const EditTestResultForm = ({
  toggleDrawer,
  toggleLockDrawer,
  request,
  testModule,
  uploads,
  fetchTestReportsBLC,
  sendTestResultsBLC,
  downloadReportBLC,
  setLockDrawer,
  setShowEditDrawer
}) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [refetchedResults, setRefetchedResults] = useState(false);

  useEffect(() => {
    if (
      !loading &&
      submitted &&
      !refetchedResults &&
      uploads &&
      _.every(uploads, ["pending", false])
    ) {
      setRefetchedResults(true);
      fetchTestReportsBLC({ id: testModule.id })
        .then(() => toggleLockDrawer())
        .catch(() => toggleLockDrawer());
    }
  }, [
    fetchTestReportsBLC,
    toggleLockDrawer,
    setShowEditDrawer,
    loading,
    submitted,
    refetchedResults,
    uploads,
    testModule.id
  ]);

  const submitTest = () => {
    setLoading(true);
    const testResArgs = prepareTestResultsArgs(request, testModule);
    sendTestResultsBLC(testResArgs, request.id).then(() => {
      updateDeviceInfoBLC(request);
    })
    .then(() => {
      setLoading(false)
    });
  };

  const onValidate = () => {
    const { testResults } = request;
    const { reports } = testModule;

    return hasPreparedResults(testResults) || reports.length;
  };

  const onSave = () => {
    setSubmitted(true);
    toggleLockDrawer();
    submitTest();
  };

  const closeDrawer = () => {
    setLockDrawer(false);
    setShowEditDrawer(false);
    if(window.location.href.slice(-2) === '/1')
      window.location.reload()
    else
      window.location.href += '/1';
    }

  if (_.isNull(request) || _.isNull(testModule) || !request.id) {
    return null;
  }

  if (submitted) {
    return loading ? (
      <InlinePreloader />
    ) : (
      <div>
        <h1 className="block-bottom-offset">Uploading Test Results</h1>
        <TestResultPanelBattery clientType="general" certType="blc" />
        <LoaderButton text="Done" onClick={() => {closeDrawer()}} disabled={false} />
      </div>
    );
  }

  return (
    <div>
      <LabResultUpload show={true} downloadHandler={downloadReportBLC} testModule={testModule}/>
      <Row>
        <Col className="text-center">
          <LoaderButton
            bsStyle="info"
            bsSize="large"
            text="Save changes"
            loadingText="Uploading..."
            onClick={onSave}
            isLoading={request.isLoading}
            disabled={!onValidate()}
            show={true}
          />
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps({ request, uploads, dispatch }) {
  return { request, uploads, dispatch };
}

export default connect(mapStateToProps, {
  fetchTestReportsBLC,
  sendTestResultsBLC,
  downloadReportBLC
})(EditTestResultForm);
