import _ from "lodash";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Bubble, InlinePreloader } from "ctia-ui";
import BatterySupplierList from "containers/request/battery/BatterySupplier/BatterySupplierList";
import { fetchApprovedPackSuppliers } from "actions/suppliers";
import BatterySupplierSelector from "containers/request/battery/BatterySupplier/BatterySupplierSelector";

const validationMsg = "Enter the first battery in the system.";

const BatterySupplierContainer = ({ request, fetchApprovedPackSuppliers }) => {
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (request && request.ieee && !touched) {
      setTouched(true);
      setLoading(true);
      fetchApprovedPackSuppliers(request.ieee).then(() => setLoading(false));
    }
  }, [fetchApprovedPackSuppliers, request, touched]);

  const showWarning =
    !request.batterySuppliers || _.isEmpty(request.batterySuppliers);

  return (
    <>
      {showWarning && (
        <Bubble icon="alert" type="warning">
          {validationMsg}
        </Bubble>
      )}
      <BatterySupplierList />
      {loading ? <InlinePreloader /> : <BatterySupplierSelector />}
    </>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  fetchApprovedPackSuppliers
})(BatterySupplierContainer);
