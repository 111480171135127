import _ from "lodash";
import validate from "validate.js";
import { formatNumberString } from "helpers/StringHelper";
import dictionary from "dictionaries/Validation";

/**
 * Check if value exists and has valuable string length.
 *
 * @param {string} value Value to be checked
 * @returns boolean
 */
export const checkValue = value => {
  if (!value) return false;
  return String(value).length > 0;
};

/**
 * Validate email address.
 *
 * @param {string} emailAddres
 * @returns boolean
 */
export const validateEmail = emailAddress => {
  return !validate.single(emailAddress, { presence: true, email: true });
};

/**
 * Validate phone number.
 *
 * @param {number} phoneNumber
 */
export const validatePhone = phoneNumber => {
  return (
    !validate(
      { duration: phoneNumber },
      { duration: { numericality: { onlyInteger: true } } }
    ) &&
    !validate(
      { duration: String(phoneNumber).charAt(0) },
      { duration: { numericality: { onlyInteger: true } } }
    )
  );
};

export const validateZip = zipCode => {
  return !validate(
    { zipCode },
    {
      zipCode: {
        numericality: { onlyInteger: true },
        length: { minimum: 5, maximum: 9 }
      }
    }
  );
};

export const validatePocEmail = (pocEmail, emailList) => {
  return _.isUndefined(_.findKey(emailList, { email: pocEmail }));
};

export const pocFormValidate = ({
  pocFirstName,
  pocLastName,
  pocEmail,
  pocPhone,
  pocPhoneExt,
  pocs
}) => {
  return (
    pocFirstName.length > 0 &&
    pocLastName.length > 0 &&
    pocEmail.length > 0 &&
    String(pocPhone).length > 0 &&
    validateEmail(pocEmail) &&
    validatePhone(pocPhone) &&
    validatePocEmail(pocEmail, pocs)
  );
};

export const validateBillingPoc = ({
  billingPocFirstName,
  billingPocLastName,
  billingPocEmail,
  billingPocPhone,
  billingPocPhoneExt,
  ...rest
}) => {
  return (
    checkValue(billingPocFirstName) &&
    checkValue(billingPocLastName) &&
    checkValue(billingPocEmail) &&
    checkValue(billingPocPhone) &&
    validateEmail(billingPocEmail) &&
    validatePhone(billingPocPhone) &&
    makeEmaiBillinglValidation({ billingPocEmail, ...rest }).state === null
  );
};

export const isCountryValid = (country, state) => {
  // if country not selected
  if (Number(country) === 0) return false;

  // for US `state` is mandatory
  if (country === "US") return checkValue(state);

  return checkValue(country);
};

export const validateBillingAddress = ({
  billingAddressName,
  billingAddressLine1,
  billingAddressCountry,
  billingAddressState,
  billingAddressZip,
  billingAddressCity
}) => {
  return (
    makeZipValidation(billingAddressZip, billingAddressCountry).state ===
      null &&
    checkValue(billingAddressName) &&
    checkValue(billingAddressLine1) &&
    checkValue(billingAddressCity) &&
    checkValue(billingAddressZip) &&
    isCountryValid(billingAddressCountry, billingAddressState)
  );
};

export const makeEmailValidation = email => {
  const valid = { state: null, message: "" };

  if (email.length === 0) {
    return valid;
  }

  if (!validateEmail(email)) {
    return {
      state: "error",
      message: dictionary.invalidEmailFormat
    };
  }

  return valid;
};

export const makeEmailValidationPOC = ({ pocEmail, pocs, pocId }) => {
  const valid = { state: null, message: "" };

  if (pocEmail.length === 0) {
    return valid;
  }

  if (!validateEmail(pocEmail)) {
    return {
      state: "error",
      message: dictionary.invalidEmailFormat
    };
  }

  if (!validatePocEmail(pocEmail, pocs) && (!pocId || pocId === "")) {
    return {
      state: "error",
      message: dictionary.nonUniqueEmail
    };
  }

  return valid;
};

export const makeEmaiBillinglValidation = ({
  billingPocEmail,
  pocs,
  billingPocId
}) => {
  const valid = { state: null, message: "" };

  if (!billingPocEmail || billingPocEmail.length === 0) {
    return valid;
  }

  if (!validateEmail(billingPocEmail)) {
    return {
      state: "error",
      message: dictionary.invalidEmailFormat
    };
  }

  if (!validatePocEmail(billingPocEmail, pocs) && (!billingPocId || billingPocId === "")) {
    return {
      state: "error",
      message: dictionary.nonUniqueEmail
    };
  }

  return valid;
};

export const makePhoneValidation = phoneNumber => {
  const valid = { state: null, message: "" };

  if (!phoneNumber || phoneNumber.length === 0) {
    return valid;
  }

  if (!validatePhone(phoneNumber)) {
    return {
      state: "error",
      message: dictionary.invalidPhoneFormat
    };
  }

  return valid;
};

export const makeZipValidation = (zipCode, countryCode) => {
  const valid = { state: null, message: "" };

  if (countryCode !== "US") return valid;

  if (!zipCode || zipCode.length === 0) {
    return {
      state: "error",
      message: "Zip/Postal Code is required for all US addresses"
    };
  }

  // regexp zipCode and keep numbers only
  const zipLength = formatNumberString(zipCode).length;

  if ((zipLength > 5 && zipLength < 9) || (zipLength > 0 && zipLength < 5)) {
    return {
      state: "error",
      message: "Invalid zipcode. Must be of 5- or 9-digit format ZIP codes"
    };
  }

  return valid;
};

export const validateManufSiteAddress = ({
  manufSiteName,
  manufSiteLine1,
  manufSiteCountry,
  manufSiteState,
  manufSiteZip,
  manufSiteCity
}) => {
  return (
    makeZipValidation(manufSiteZip, manufSiteCountry).state === null &&
    checkValue(manufSiteName) &&
    checkValue(manufSiteLine1) &&
    checkValue(manufSiteCity) &&
    isCountryValid(manufSiteCountry, manufSiteState)
  );
};

export const validateNumber = numValue => {
  const val2num = _.toNumber(numValue);
  return _.isNumber(val2num) && !_.isNaN(val2num);
};

export const validateVoltage = request => {
  if (!request || !request.voltage) return false;

  // legacy request or voltage inherited from a legacy
  if (request.isLegacy || (!request.isLegacy && !request.voltage[0]))
    return true;

  return request.voltage[1] !== "" && validateNumber(request.voltage[1]);
};

export const validateCapacity = request => {
  if (!request || !request.capacity) return false;

  // skip 1st element, since we don't need it
  const [, value] = request.capacity;

  // legacy request or capacity inherited from a legacy
  if (request.isLegacy || (!request.isLegacy && !request.voltage[0]))
    return value !== "";

  return value !== "" && validateNumber(value);
};

// check if at least 1 battery pack added
export const validateBatteryPacks = request => {
  if (!request || !request.batterySuppliers) return false;

  return request.batterySuppliers.length > 0;
};

// check if at least 1 adapter added OR "No Adapter" selected
export const validateAdapters = request => {
  if (!request) return false;
  if (request.adapterType && Number(request.adapterType) === 4) return true;
  if (!request.adapterSuppliers) return false;

  return request.adapterSuppliers.length > 0;
};

export const checkPasswordRequirements = password => {
  if (!password) return false;

  const strPass = _.toString(password);

  if (!_.isString(strPass)) return false;

  // Must be 8 or more characters in length
  if (strPass.length < 8) return false;

  // English upper/lowercase characters (a-z, A-Z)
  const hasLetters = strPass.match("[a-zA-Z]+");

  // Base 10 digits (0-9)
  const hasDigits = strPass.match("[0-9]+");

  // Special characters (!@#$%^&* etc)
  const hasSpecChars = strPass.match("[^0-9a-zA-Z]+");

  return _.every([hasLetters, hasDigits, hasSpecChars], rule => rule !== null);
};
