import React from 'react';
import PropTypes from 'prop-types';
import Button from './FormButton';
import { IconLabel } from 'ctia-ui';

const SaveButton = ({ label, ...rest }) => {
  return (
    <Button
      text={<IconLabel label={label} awesomeIcon="archive" />}
      {...rest}
    />
  );
};

SaveButton.propTypes = {
  show: PropTypes.bool,
  label: PropTypes.string
};

SaveButton.defaultProps = {
  show: true,
  label: 'Save'
};

export default SaveButton;
