import React from "react";

const DeleteUserLink = ({ toggleModal }) => {
  return (
    <span className="cursor-pointer glow-link" onClick={toggleModal}>
      Delete
    </span>
  );
};

export default DeleteUserLink;
