import _ from "lodash";
import { checkValue } from "helpers/ValidatorHelper";
import RecognizedAdapters from "containers/request/battery/AdapterSupplier/SelectRecognizedAdapter";
import UnrecognizedAdapters from "containers/request/battery/AdapterSupplier/SelectUnrecognizedAdapter";
import CertifiedAdapters from "containers/request/battery/AdapterSupplier/SelectCertifiedAdapter";

export const requestFields = {
  type: "adapterType",
  supplier: "adapterSupplier",
  supplierId: "adapterSupplierId",
  supplierName: "adapterSupplierName",
  linkedRequestId: "linkedRequestId",
  linkedRequestRevision: "linkedRequestRevision",
  model: "adapterModel",
  modelId: "adapterModelId",
  modelName: "adapterModelName",
  modelOption: "adapterModelOption",
  revision: "adapterRevision"
};

export const hasUnrecognizedAdapter = request => {
  if (!request) return false;

  const adapterType = request[requestFields.type];
  if (!adapterType) return false;

  return Number(adapterType) === 3;
};

export const validateSupplierFields = request => {
  if (!request) return false;

  const {
    adapterSupplier,
    adapterModel,
    adapterModelName,
    adapterModelOption,
    adapterRevision,
    adapterSupplierName
  } = request;

  // for unrecognized adapters need just model and vendor names
  if (hasUnrecognizedAdapter(request)) {
    return checkValue(adapterModelName) && checkValue(adapterSupplierName);
  }

  // not valid if supplier has not selected
  if (!checkValue(adapterSupplier)) return false;

  // supplier selected and model has pending recognition
  if (checkValue(adapterModelOption === 2)) return true;

  // otherwise - just check that both model and revision selected
  return checkValue(adapterModel) && checkValue(adapterRevision);
};

export const adapterTypes = {
  recognized: "Recognized Adapter",
  unrecognized: "Unrecognized Embedded Adapter",
  certified: "Certified Adapter",
  noAdapter: "No Adapter"
};

export const adapterOptions = [
  {
    value: 0,
    title: "Select an Adapter Type ↴"
  },
  {
    value: 1,
    title: adapterTypes.recognized
  },
  {
    value: 2,
    title: adapterTypes.certified
  },
  {
    value: 3,
    title: adapterTypes.unrecognized
  },
  {
    value: 4,
    title: adapterTypes.noAdapter
  }
];

export const adapterComponents = {
  1: RecognizedAdapters,
  2: CertifiedAdapters,
  3: UnrecognizedAdapters
};

export const getAvailableTypes = canSkipSelection => {
  // 'No Adapter' option is available since no adapter was selected:
  if (canSkipSelection) return adapterOptions;

  // get all but 'No Adapter' option:
  return _.initial(adapterOptions);
};

export const getTypeTitle = typeValue => {
  const emptyTitle = "n/a";
  if (!typeValue) return emptyTitle;

  const typeTitle = _.find(adapterOptions, ["value", Number(typeValue)]);
  return _.isUndefined(typeTitle) ? emptyTitle : typeTitle.title;
};

export const badgeTypes = {
  1: "warning",
  2: "success",
  3: "secondary"
};

export const getAdapterModelById = (id, models) => {
  if (!id || !models) {
    console.warn(
      "No ID or models passed. Check `getAdapterModelById` method for a reference."
    );
    return null;
  }

  return _.find(models, ["value", Number(id)]);
};

export const getAdapterRequestById = (id, adapters) => {
  if (!id || !adapters) {
    console.warn(
      "No ID or adapters passed. Check `getAdapterRequestById` method for a reference."
    );
    return null;
  }

  return _.find(adapters, ["id", Number(id)]);
};

export const formatAdapterRevisionList = (list, apadtersInUse = []) => {
  let mappedList = list.map(elem => {
    return {
      value: elem.id,
      title: `Revision: ${elem.revision}`
    };
  });

  if (apadtersInUse.length > 0) {
    mappedList = mappedList.map(adapter => {
      const idsInUse = apadtersInUse.map(inUse =>
        Number(inUse.linkedrequestid)
      );

      if (_.includes(idsInUse, Number(adapter.value))) {
        adapter.disabled = true;
      }

      return adapter;
    });
  }

  mappedList.unshift({
    value: 0,
    title: "Select a Revision ↴"
  });

  return mappedList;
};
