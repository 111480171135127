import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IeeeBadge from "components/Badges/IeeeBadge";
import typesTitles from "dictionaries/ReqTypesTitles";
import { batteryVendor, batterySupplier } from "helpers/BatteryCertHelper";
import { getSimpleArrayByKey } from "helpers/ArrayHelper";
import { updateWizard } from "actions/wizard";
import { updateUserLocation } from "actions/user";
import "../RequestTypeBlock.css";

const BatteryRequestCards = ({
  updateWizard,
  user,
  updateUserLocation,
  history
}) => {
  const userCards = user.isSupplier ? batterySupplier : batteryVendor;
  const changeType = (key, ieee, type) => {
    const certInfo = userCards[key];
    const steps = certInfo.wizardSteps[type];
    const totalSteps = _.size(steps);

    // call action creator to update wizard
    updateWizard({
      batteryCert: {
        ieee,
        type,
        certInfo
      },
      // just a shortcut for batteryCert nesting data:
      steps,
      totalSteps
    });

    // trick to update URL to be able to go back later
    const userPrefix = user.isSupplier ? "supplier" : "vendor";
    const newUrl = `/battery/${userPrefix}/request/new/${certInfo.urlPrefix}`;
    history.push(newUrl);
    updateUserLocation(newUrl);
  };

  const renderCardLinks = (key, ieee, type) => {
    const domKey = ieee ? `${key}-${ieee}-${type}` : `${key}-${type}`;
    const typeLabel = typesTitles[type];

    return (
      <span
        onClick={() => changeType(key, ieee, type)}
        key={domKey}
        className="cert-card__link glow-link"
      >
        {typeLabel} Request
      </span>
    );
  };

  const renderLinks = key => {
    const { ieeeTypes, submissionTypes } = userCards[key];

    if (!ieeeTypes) {
      return submissionTypes.map(type => {
        return renderCardLinks(key, null, type);
      });
    }

    return ieeeTypes.map((ieee, i) => {
      const links = submissionTypes.map(type => {
        return renderCardLinks(key, ieee, type);
      });

      return (
        <div className="block-bottom-offset" key={`${key}-${i}`}>
          <h4 className="h4badge">
            <IeeeBadge ieeeType={ieee} />
          </h4>
          {links}
        </div>
      );
    });
  };

  const renderCard = key => {
    return (
      <div className="cert-card" key={key}>
        <h2>{userCards[key].title}</h2>
        <div className="cert-card__links">{renderLinks(key)}</div>
      </div>
    );
  };

  const renderCards = () => {
    const cardsOrderedByTitle = _.sortBy(userCards, [card => card.title]);
    const cardsKeys = getSimpleArrayByKey(cardsOrderedByTitle, "clientTypeId");

    return cardsKeys.map(key => {
      return renderCard(key);
    });
  };

  return <div>{renderCards()}</div>;
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  updateWizard,
  updateUserLocation
})(BatteryRequestCards);
