import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown } from "ctia-react-bootstrap-v4";
import { setOrgType, clearOrgList } from "actions/org";
import { orgTypes, getOrgTypePrefix } from "helpers/OrgHelper";

const OrgTypeSelector = ({ org, setOrgType, clearOrgList }) => {
  let history = useHistory();

  const OrgTypeToggle = React.forwardRef(({ children, onClick }, ref) => {
    return (
      <span
        className="dashed cursor-pointer"
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </span>
    );
  });

  const selectedType = org && org.type ? org.type : orgTypes[0];

  return (
    <Dropdown
      onSelect={type => {
        if (selectedType !== type) {
          setOrgType(type);

          // redirect
          const prefix = getOrgTypePrefix(type);

          if (prefix) {
            history.push(`/admin/cp/organizations/${prefix}`);
            // window.location = `/admin/cp/organizations/${prefix}`;
          } else console.error("Passed unsupported organization type: ", type);
        }
      }}
    >
      <Dropdown.Toggle as={OrgTypeToggle} id="dropdown-custom-components">
        {selectedType}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>Select an organization type:</Dropdown.Header>
        {orgTypes.map(type => {
          const isActive = type === selectedType;
          return (
            <Dropdown.Item key={type} eventKey={type} active={isActive}>
              {type}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

function mapStateToProps({ org }) {
  return { org };
}

export default connect(mapStateToProps, { setOrgType, clearOrgList })(
  OrgTypeSelector
);
