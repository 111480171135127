import * as actions from "actions/types";
import { getOperatorsList } from "helpers/Operators";

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_OPERATORS:
      return getOperatorsList(action.payload);
    case actions.CLEAR_OPERATORS:
    case actions.LOGOUT:
      return null;
    default:
      return state;
  }
}
