import React, { Component } from "react";
import { connect } from "react-redux";
import SubmissionRender from "containers/submission/SubmissionRender";
import {
  clearRequest,
  createInitialBatteryRequest,
  createEcoBatteryRequest,
  createRenewalBatteryRequest
} from "actions/request";
import { setEditManufsitePermissions } from "actions/battery/sar";
import reqTypes from "dictionaries/ReqTypes";
import {
  canRequestBeSaved,
  getTypeCalcPropName
} from "helpers/BatteryCertHelper";

class BatteryWizardContainer extends Component {
  componentDidMount() {
    const { user, wizard } = this.props;
    const { type, ieee, certInfo } = wizard.batteryCert;

    const params = {
      ieee,
      batteryType: certInfo.title,
      canBeSaved: canRequestBeSaved(wizard.batteryCert)
    };

    const calcPropName = getTypeCalcPropName(certInfo.clientTypeId);
    if (calcPropName) {
      params[calcPropName] = true;
    }

    if (type === reqTypes.initial) {
      this.props.createInitialBatteryRequest(user.company, params);
    }

    if (type === reqTypes.eco) {
      this.props.createEcoBatteryRequest(user.company, params);
    }

    if (type === reqTypes.renewal) {
      this.props.createRenewalBatteryRequest(params);
    }

    // set default permission to edit site info
    this.props.setEditManufsitePermissions(user);
  }

  componentWillUnmount() {
    this.props.clearRequest();
  }

  render() {
    const { history, request } = this.props;

    // this helps to prevent an issue when switching between submissions
    if (!request || (!request.isBLC && !request.ieee)) return "Loading...";

    return <SubmissionRender history={history} />;
  }
}

function mapStateToProps({ request, user, wizard }) {
  return { request, user, wizard };
}

export default connect(mapStateToProps, {
  createInitialBatteryRequest,
  createEcoBatteryRequest,
  createRenewalBatteryRequest,
  clearRequest,
  setEditManufsitePermissions
})(BatteryWizardContainer);
