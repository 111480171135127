import React from "react";
import { connect } from "react-redux";
import { DownloadButton, DocumentStatus, Drawer } from "ctia-ui";
import { Col, Row, Table, FormGroup, FormControl } from "react-bootstrap";
import EditButton from "components/form/buttons/EditButton";
import CyberComponent from "containers/CyberComponent";
import DocTitleReporter from "components/DocTitleReporter";
import DownloadAllButton from "components/form/buttons/DownloadAllButton";
import EditTestResultForm from "containers/request/Initial/EditTestResultForm";
import { hasReportDocuments } from "helpers/RequestTestsHelper";
import {
  testReportDownload,
  testReportsDownloadAll,
  updateTestReportStatus
} from "actions/tests";
import { fetchRequest } from "actions/requestCyber";

const tdStyle = { width: 150 };

class LabTestResult extends CyberComponent {
  state = { showEditDrawer: false, lockDrawer: false };

  onSelect = (testId, slot, event) => {
    const reportStatus = event.target.value;
    const args = {
      requestId: this.props.request.id,
      testId,
      reportSlot: slot,
      reportStatus
    };

    return this.props.updateTestReportStatus(args);
  };

  toggleEditDrawer() {
    const { showEditDrawer, lockDrawer } = this.state;
    if (!lockDrawer) this.setState({ showEditDrawer: !showEditDrawer });
  }

  toggleLockDrawer() {
    this.setState({ lockDrawer: !this.state.lockDrawer });
  }
  closeDrawer() {
    fetchRequest(this.props.request.id);
    this.setState({ lockDrawer: false })
    this.setState({ showEditDrawer: false })
  }
  render() {
    const {
      user,
      testModule,
      request,
      testReportDownload,
      testReportsDownloadAll
    } = this.props;

    if (!testModule) return null;
    if (!testModule.reports || !testModule.reports.length) return null;
    if (!hasReportDocuments(testModule.reports)) return null;

    const testReports = testModule.reports.filter(report => report.docId !== 0);
    const showEditLabResult =
      user.isStaff &&
      user.canEditRequest;

    return (
      <div>
        <Row className="center-row">
          <Col xs={6}>
            <h3>Test Results</h3>
          </Col>
          <Col xs={6} className="text-right">
            {(showEditLabResult || user.isStaff) && (
              <>
                <EditButton
                  size="small"
                  label="Edit"
                  action={() => this.toggleEditDrawer()}
                />
                <Drawer
                  position="bottom"
                  show={this.state.showEditDrawer}
                  title="Edit Test Results"
                  toggleDrawer={() => this.toggleEditDrawer()}
                  content={
                    <EditTestResultForm
                      testModule={testModule}
                      toggleDrawer={() => this.toggleEditDrawer()}
                      toggleLockDrawer={() => this.toggleLockDrawer()}
                      closeDrawer={() => this.closeDrawer()}
                    />
                  }
                  height="auto"
                />
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Table responsive striped className="ctia-table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Document Title</th>
                  <th />
                  <th style={tdStyle} />
                </tr>
              </thead>
              <tbody>
                {testReports.map((doc, i) => {
                  if (!doc.document) return null;

                  const status = doc.document.documentstatusid;

                  return (
                    <tr key={i}>
                      <td>
                        <DocumentStatus status={status} />
                      </td>
                      <td>
                        <DocTitleReporter doc={doc} />
                      </td>
                      {/* <!--- Next block shows only for staff user ---> */}
                      <td>
                        {user.isStaff && (
                          <FormGroup controlId="tests">
                            <FormControl
                              componentClass="select"
                              value={status}
                              onChange={this.onSelect.bind(
                                this,
                                doc.requesttestid,
                                doc.slot
                              )}
                              disabled={!user.canEditRequest}
                            >
                              <option value={1}>--- Not selected ---</option>
                              <option value={2}>Approved</option>
                              <option value={3}>Rejected</option>
                            </FormControl>
                          </FormGroup>
                        )}
                      </td>
                      <td style={tdStyle}>
                        <DownloadButton
                          size="small"
                          action={testReportDownload.bind(this, doc, request)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <DownloadAllButton
          show={testReports.length > 1}
          action={() => testReportsDownloadAll(request)}
        />
      </div>
    );
  }
}

function mapStateToProps({ user, request, tests }) {
  return { user, request, tests };
}

export default connect(mapStateToProps, {
  testReportDownload,
  testReportsDownloadAll,
  updateTestReportStatus
})(LabTestResult);
