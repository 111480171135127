import { restClientGeneral } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import * as call from "helpers/ActionHelper";
import * as actions from "actions/types";
import { resolvePromise } from "helpers/PromiseHelper";

export function addBatterySupplier(supplierInfo) {
  return {
    type: actions.ADD_BATTERY_SUPPLIER,
    payload: supplierInfo
  };
}

export function removeBatterySupplier(payload) {
  return resolvePromise({
    type: actions.REMOVE_BATTERY_SUPPLIER,
    payload
  });
}

export const fetchVersionsModel = (modelId, certPrefix) => async dispatch => {
  try {
    const url = `/${certPrefix}/devicemodels/${modelId}?include=vendor&select=modelnumber,name,capacityalias&allYourReqsRBelong2Us=true&returnAs=query&page=0`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_VERSIONS_MODEL,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchRecognizedVersions = (
  modelId,
  certPrefix
) => async dispatch => {
  try {
    const url = `/${certPrefix}/requests?where=devicemodelid=${modelId}&include=devicemodel,requestmanufsites&allYourReqsRBelong2Us=true&page=0`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_RECOGNIZED_VERSIONS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function removeAdapterSupplier(payload) {
  return resolvePromise({
    type: actions.REMOVE_ADAPTER_SUPPLIER,
    payload
  });
}

export function addAdapterSupplier(supplierInfo) {
  return {
    type: actions.ADD_ADAPTER_SUPPLIER,
    payload: supplierInfo
  };
}

// Cell Suppliers
export const fetchCellSuppliers = ieee => async dispatch => {
  try {
    const url = `/crr/companies?where=ieee${ieee}SupplierOfApprovedCRR = 1&page=0&orderBy=name`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CELL_SUPPLIERS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchCellModels = (supplierId, ieee) => async dispatch => {
  if (Number(supplierId) === 0) {
    return dispatch({
      type: actions.FETCH_CELL_MODELS,
      payload: null
    });
  }

  try {
    const url = `devicemodels?where=name='CRR' AND requeststatusid=2 AND vendorid=${supplierId} AND ieeetypeid=${ieee} AND requesttypeid=1&include=requests(certtype)&allYourReqsRBelong2Us=true&select=devicemodels.id,devicemodels.modelnumber,requests.id as requestid&page=0&orderBy=modelnumber`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CELL_MODELS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function addCellSupplier(supplierInfo) {
  return {
    type: actions.ADD_CELL_SUPPLIER,
    payload: supplierInfo
  };
}

export function removeCellSupplier(payload) {
  return resolvePromise({
    type: actions.REMOVE_CELL_SUPPLIER,
    payload
  });
}

// BSC ---> Adapters ---> Certified ACR \\
export const fetchApprovedAdapterSuppliers = ieee => async dispatch => {
  try {
    const url = `/companies?where=ieee${ieee}SupplierOfApprovedACR=1&orderBy=name&page=0&select=id,name`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_APPROVED_ADAPTER_SUPPLIERS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchCertifiedAdapters = (vednorId, ieee) => async dispatch => {
  try {
    const url = `/acr/devicemodels?include=requests&select=devicemodels.id,devicemodels.modelnumber&allYourReqsRBelong2Us=true&returnAs=Query&distinct=true&page=0&where=vendorid=${vednorId} AND ieeetypeid=${ieee} AND requeststatusid=2&orderBy=modelnumber`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CERTIFIED_ADAPTERS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchCertifiedAdapterRequests = (
  modelId,
  ieee
) => async dispatch => {
  try {
    const url = `/acr/requests?where=devicemodelid=${modelId} AND ieeetypeid=${ieee} AND requeststatusid=2&include=devicemodel(devicemodelbatterycomponent)&select=id,revision,formatedtransactionnumber&page=0&returnAs=query&orderBy=transactionnumber`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CERTIFIED_ADAPTER_REQUESTS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

// BSC ---> Adapters ---> Recognized ARR \\
export const fetchApprovedRecognitionSuppliers = ieee => async dispatch => {
  try {
    const url = `/companies?where=ieee${ieee}SupplierOfApprovedARR=1&orderBy=name&page=0&select=id,name`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_APPROVED_RECOGNITION_SUPPLIERS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchRecognizedAdapters = (vednorId, ieee) => async dispatch => {
  try {
    const url = `/arr/devicemodels?include=requests&select=devicemodels.id,devicemodels.modelnumber&allYourReqsRBelong2Us=true&returnAs=Query&distinct=true&page=0&where=vendorid=${vednorId} AND ieeetypeid=${ieee} AND requeststatusid=2&orderBy=modelnumber`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_RECOGNIZED_ADAPTERS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchRecognizedAdapterRequests = (
  modelId,
  ieee
) => async dispatch => {
  try {
    const url = `/arr/requests?where=devicemodelid=${modelId} AND ieeetypeid=${ieee} AND requeststatusid=2&include=devicemodel(devicemodelbatterycomponent)&select=id,revision,formatedtransactionnumber&page=0&returnAs=query&orderBy=transactionnumber`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_RECOGNIZED_ADAPTER_REQUESTS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

// BSC ---> Pack Suppliers ---> Certified PRR \\
export const fetchApprovedPackSuppliers = ieee => async dispatch => {
  try {
    const url = `/companies?where=ieee${ieee}SupplierOfApprovedPack=1&orderBy=name&page=0&select=id,name`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_APPROVED_PACK_SUPPLIERS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchCertifiedPacks = (
  vednorId,
  ieee,
  prrCertId,
  eprTypeId
) => async dispatch => {
  try {
    const url = `/devicemodels?include=requests&select=devicemodels.id,devicemodels.modelnumber,requests.certtypeid&allYourReqsRBelong2Us=true&returnAs=Query&distinct=true&page=0&where=certtypeid IN (${prrCertId},${eprTypeId}) AND vendorid=${vednorId} AND ieeetypeid=${ieee} AND requeststatusid=2&orderBy=modelnumber`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CERTIFIED_PACKS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchCertifiedPackRequests = (
  modelId,
  ieee,
  prrCertId,
  eprTypeId
) => async dispatch => {
  try {
    const url = `/requests?where=certtypeid IN (${prrCertId},${eprTypeId}) AND devicemodelid=${modelId} AND ieeetypeid=${ieee} AND requeststatusid=2&include=devicemodel(devicemodelbatterycomponent)&select=id,revision,capacity,formatedtransactionnumber&page=0&returnAs=query&orderBy=transactionnumber`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CERTIFIED_PACK_REQUESTS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchBSCPackRequests = (
  modelId,
  bscCertId,
  prrCertId,
  eprrCertId,
  withdrawnStatus
) => async dispatch => {
  try {
    const url = `requests?where=certtypeid IN (${bscCertId}) AND devicemodelid=${modelId} AND ieeetypeid = 1725 AND hasBatteryPacks = 1 AND linkedrequestcerttypeid IN (${prrCertId},${eprrCertId}) AND requeststatusid <> ${withdrawnStatus}&allYourReqsRBelong2Us=true&page=0&returnAs=query&include=linkedrequests&select=linkedrequestid,linkedrequestdevicemodelid,linkedrequestvendorname,linkedrequestvendorproductname,linkedrequestrevision,linkedrequestcerttypeid, hasPendingRecognition`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CERTIFIED_BSC_PACK_REQUESTS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};
