import _ from "lodash";
import * as actions from "actions/types";
import { filterModelFields } from "helpers/ModelHelper";
import { sortByFieldname } from "helpers/SortHelper";

const setDhrModelsList = (payload, state) => {
  const existedModels = payload.data.data || [];
  const vendorModels = filterModelFields(existedModels);

  return { ...state, vendorModels };
};

const combineModernWithLegacy = (payload, state) => {
  // @HACK: cyber shares the same method,
  // but for dhr state will be null
  if (!state) return null;

  // also mark legacy models
  const legacyModels = filterModelFields(payload).map(model => {
    return { ...model, isLegacy: true };
  });

  // on dev it has duplicates, so need to filter values-titles to be unique
  const combinedUniqueTitle = _.uniqBy(
    [...legacyModels, ...state.vendorModels],
    "title"
  );
  const combinedModels = _.uniqBy(combinedUniqueTitle, "value");

  // sort by title now
  const vendorModels = sortByFieldname(combinedModels, "title");

  return { ...state, vendorModels };
};

const setEcoModels = (payload, state) => {
  const modelList = payload || [];
  const ecoModels = filterModelFields(modelList);

  return { ...state, ecoModels };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_DHR_LABS:
      const dhrLabs = _.isNull(action.payload) ? null : action.payload;
      return { ...state, dhrLabs };
    case actions.CLEAR_DHR_STORE:
      return null;
    case actions.FETCH_DHR_EXISTED_MODELS:
      return setDhrModelsList(action.payload, state);
    case actions.CHECK_PTCRB_MODELS:
      return combineModernWithLegacy(action.payload, state);
    case actions.FETCH_DHR_ECO_MODELS:
      return setEcoModels(action.payload, state);
    default:
      return state;
  }
}
