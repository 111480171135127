// ES6 Helpers
import _ from "lodash";

// React / Redux
import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";

// Dictionaries and Helpers
import { requestStatuses } from "helpers/RequestHelper";

// Components and Containers
import NoTableData from "components/NoTableData";
import BatteryModelDetailsSARRow from "containers/Devices/Battery/BatteryDeviceDetails/BatteryModelDetailsTables/BatteryModelDetailsSAR/BatteryModelDetailsSARRow";

const BatteryModelDetailsSAR = ({
  reloadRows = () => {},
  // Redxu Stores
  app, model, user
}) => {
  const tableData = model.versions;
  const showDateAuthorized =
    user.isStaff ||
    user.isLab ||
    !_.isEqual(app.batteryRequestStatus.filter, [
      requestStatuses.requestWithdrawn
    ]);

  if (!tableData || tableData.length === 0) {
    return <NoTableData colSpan={showDateAuthorized ? 6 : 5} />;
  }

  const showWithdrawColumn = user.isStaff;

  const renderRows = () => {
    return tableData
      .sort((rowOne, rowTwo) => rowOne.completedat > rowTwo.completedat)
      .map((row, rowIndex) => {
        const childrenRequestIds = _.filter(tableData, (childRow) => {
        return childRow.parentid === row.id;
      }).map((childRow) => childRow.id);

        return (
          <BatteryModelDetailsSARRow
            requestIdsOfChildren={childrenRequestIds}
            reloadRow={reloadRows}
            row={row}
            rowIndex={rowIndex}
            showDateAuthorized={showDateAuthorized}
            showWithdrawColumn={showWithdrawColumn}
          />
        )
    });
  };

  return (
    <Table responsive striped className="ctia-table">
      <thead>
        <tr>
          <th>Request ID</th>
          <th>Authorization Type</th>
          <th>Request Type</th>
          <th>Comments</th>
          <th>CRSL Version</th>
          {showDateAuthorized && <th>Date Authorized</th>}
          {showWithdrawColumn && <th />}
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </Table>
  );
};

function mapStateToProps({ app, model, user }) {
  return { app, model, user };
}

export default connect(mapStateToProps)(BatteryModelDetailsSAR);
