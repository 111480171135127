import { Col } from "react-bootstrap";
import { InlineDatepicker } from "ctia-ui";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import DatePicker from 'react-datepicker';

const DateRange = ({ dateFrom, dateTo, earliestDate, latestDate, onDateFromChange, onDateToChange }) => {
  return (
    <>
      <Col className="datepicker-block" xs={12} sm={1} md={1}>
        <DatePicker
          customInput={<InlineDatepicker label="From: " />}
          dropdownMode="select"
          maxDate={dateTo}
          minDate={earliestDate}
          onChange={onDateFromChange}
          peekNextMonth
          selected={dateFrom}
          showMonthDropdown
          showYearDropdown
          value={dateFrom}
        />
      </Col>
      <Col className="datepicker-block" xs={12} sm={1} md={1}>
        <DatePicker
          customInput={<InlineDatepicker label="To: " />}
          dropdownMode="select"
          maxDate={latestDate}
          minDate={dateFrom}
          onChange={onDateToChange}
          peekNextMonth
          selected={dateTo}
          showMonthDropdown
          showYearDropdown
          value={dateTo}
        />
      </Col>
    </>
  )
}

Date.propTypes = {
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  earliestDate: PropTypes.object,
  latestDate: PropTypes.object,
  onChange: PropTypes.func,
};

Date.defaultProps = {
  dateFrom: moment().subtract(1, 'years'),
  dateTo: moment(),
  earliestDate: moment(),
  latestDate: moment(),
};

export default DateRange;
