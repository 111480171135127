/* The Ids are pulled from legacy at -- not sure why the ids are the same for site audit
 * http://10.150.36.85/ctia/cpo-legacy/-/blob/master/wwwroot/common_codebase/ctiastaff/organ/add_organ_form.cfm#L799
 */
export default {
  batteryCompliance: {
    id: 19,
    label: "Battery Compliance",
  },
  batterySiteAudit: {
    id: 20,
    label: "Battery Site Audit"
  }
};