import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { requestFields } from "helpers/BatterySupplierHelper";
import { formatSuppliersList, supplierTypes } from "helpers/SupplierHelper";
import { setValue } from "actions/request";
import { fetchCellModels } from "actions/suppliers";

class PackCellsSelectSupplier extends React.Component {
  requestField = requestFields.supplier;

  componentWillUnmount() {
    this.props.setValue(this.requestField, "");
  }

  onChange = event => {
    const { setValue, fetchCellModels, request, suppliers } = this.props;
    const supplierId = Number(event.target.value);

    // reset batteryModel value
    setValue(requestFields.model, "");
    setValue(requestFields.modelId, "");
    setValue(requestFields.modelName, "");
    setValue(requestFields.cellRequestId, "");

    const supplier = _.find(suppliers.cells, cell => cell.value === supplierId);

    if (supplier) {
      // set batterySupplier value
      setValue(this.requestField, supplierId);
      setValue(requestFields.supplierId, supplierId);
      setValue(requestFields.supplierName, supplier.title);

      // fetch related models
      fetchCellModels(supplierId, request.ieee);
    } else {
      setValue(this.requestField, null);
      setValue(requestFields.supplierId, null);
      setValue(requestFields.supplierName, null);
    }
  };

  setHintHelpers = () => {
    const { request } = this.props;
    const { cellSuppliers } = request;
    const isRequired = !cellSuppliers || cellSuppliers.length === 0;

    return {
      hintState: isRequired ? "error" : "success",
      hintMessage: isRequired
        ? "You must add at least 1 Cell Model to proceed"
        : "You can add more than one Cell Model if needed (optional)"
    };
  };

  render() {
    const { cells } = this.props.suppliers;

    if (!cells || !cells.length) return null;

    const { hintState, hintMessage } = this.setHintHelpers();

    return (
      <FormElement
        type="select"
        id={this.requestField}
        label="Cell Supplier"
        value={this.props.request[this.requestField] || 0}
        onChange={this.onChange}
        options={formatSuppliersList(cells, supplierTypes.cell)}
        validationState={hintState}
        validationMessage={hintMessage}
      />
    );
  }
}

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, {
  setValue,
  fetchCellModels
})(PackCellsSelectSupplier);
