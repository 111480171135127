import React, { Component } from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import { clearRequest } from "actions/request";
import { dispatchWithPreloader } from "helpers/ActionHelper";
import { getRequestDetails } from "actions/requestCyber";
import { getRequestDetailsCRR } from "actions/battery/crrRequest";
import { getRequestDetailsHRR } from "actions/battery/hrrRequest";
import { getRequestDetailsPRR } from "actions/battery/prrRequest";
import { getRequestDetailsEPR } from "actions/battery/eprRequest";
import { getRequestDetailsARR } from "actions/battery/arrRequest";
import { getRequestDetailsACR } from "actions/battery/acrRequest";
import { getRequestDetailsBLC } from "actions/battery/blcRequest";
import PaymentForm from "containers/request/Payments/PaymentFormAccountant";
import RequestDetailsCyber from "containers/request/Payments/RequestDetailsCyber";
import RequestDetailsOta from "containers/request/Payments/RequestDetailsOta";
import RequestDetailsBattery from "containers/request/Payments/RequestDetailsBattery";
import RequestDetailsDHR from "containers/request/Payments/RequestDetailsDHR";
import RequestDetailsBLC from "containers/request/Payments/RequestDetailsBLC";
import GoBackButton from "components/form/buttons/GoBackButton";

class AddPayment extends Component {
  componentDidMount() {
    const {
      app,
      dispatchWithPreloader,
      getRequestDetails,
      getRequestDetailsCRR,
      getRequestDetailsHRR,
      getRequestDetailsPRR,
      getRequestDetailsEPR,
      getRequestDetailsARR,
      getRequestDetailsACR,
      getRequestDetailsBLC,
      match
    } = this.props;
    const { id, certType } = match.params;

    // @TODO: make it more generic
    if (certType === "crr") return getRequestDetailsCRR(id);
    if (certType === "hrr")
      return dispatchWithPreloader(getRequestDetailsHRR, id);
    if (certType === "prr")
      return dispatchWithPreloader(getRequestDetailsPRR, id);
    if (certType === "eprr")
      return dispatchWithPreloader(getRequestDetailsEPR, id);
    if (certType === "arr")
      return dispatchWithPreloader(getRequestDetailsARR, id);
    if (certType === "acr")
      return dispatchWithPreloader(getRequestDetailsACR, id);
    if (app.isBLCCert) return dispatchWithPreloader(getRequestDetailsBLC, id);

    return getRequestDetails(id);
  }

  componentWillUnmount() {
    this.props.clearRequest();
  }

  render() {
    const { app, request } = this.props;

    if (!request) return null;

    return (
      <div>
        <div>
          <GoBackButton
            dest={`${app.urlPrefix}payments`}
            label="Return to Unpaid Requests"
          />
        </div>
        <Col xs={12} md={5}>
          <h1 className="block-bottom-offset">Add Payment</h1>
          <PaymentForm history={this.props.history} />
        </Col>
        <Col xs={12} md={1} />
        <Col xs={12} md={6}>
          <RequestDetailsCyber />
          <RequestDetailsOta />
          <RequestDetailsBattery />
          <RequestDetailsDHR />
          <RequestDetailsBLC />
        </Col>
      </div>
    );
  }
}

function mapStateToProps({ app, request }) {
  return { app, request };
}

export default connect(mapStateToProps, {
  dispatchWithPreloader,
  getRequestDetails,
  getRequestDetailsCRR,
  getRequestDetailsHRR,
  getRequestDetailsPRR,
  getRequestDetailsEPR,
  getRequestDetailsARR,
  getRequestDetailsACR,
  getRequestDetailsBLC,
  clearRequest
})(AddPayment);
