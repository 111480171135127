import React from "react";
import { Table } from "react-bootstrap";
import { Bubble } from "ctia-ui";
import LabResultRow from "components/labRequest/LabResultRow";

const LabResultList = ({ list }) => {
  if (!list) return null;

  return (
    <div>
      <Bubble>
        You may enter the test results for a request by clicking on the{" "}
        <strong>Request ID</strong>.
      </Bubble>
      <Table striped condensed hover className="ctia-table">
        <thead>
          <tr>
            <th>Request ID</th>
            <th>Vendor</th>
            <th>Model Name/Number</th>
            <th>Request Type</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <LabResultRow tableRows={list} />
        </tbody>
      </Table>
    </div>
  );
};

export default LabResultList;
