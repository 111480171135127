import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import Text from "components/Text";
import { formatDate } from "helpers/DateHelper";
import LoginAs from "containers/users/UsersList/LoginAs";
import LoginAsPTCRB from "containers/users/UsersList/LoginAsPTCRB";
import UserRoleBadge from "containers/users/UsersList/UserRoleBadge";
import StatusAction from "containers/users/UsersList/UserStatusActions/StatusAction";
import EditUserLink from "containers/ControlPanel/Users/EditUser/EditUserLink";
import NoTableData from "components/NoTableData";
import { renderUserStatus } from "helpers/UsersHelper";

const ListRow = ({ tableData }) => {
  if (!tableData) return null;

  if (_.isEmpty(tableData)) {
    return <NoTableData colSpan={7} />;
  }

  return _.map(tableData, row => {
    const lastLoginDate = _.isEmpty(row.lastloginat)
      ? ""
      : formatDate(row.lastloginat);
    const isActive = row.active > 0;
    const isDeleted = row.deletedat !== "";
    return (
      <tr key={row.id}>
        <td>
          <EditUserLink userData={row} />
        </td>
        <td>
          <UserRoleBadge textRole={row.role} />
        </td>
        <td>
          <Text string={row.companyname.toString()} />
        </td>
        <td>{row.email}</td>
        <td>{lastLoginDate}</td>
        <td>{renderUserStatus(row)}</td>
        <td><StatusAction user={row}/></td>
        <td>
          <LoginAs show={isActive && !isDeleted} userData={row} />
          <LoginAsPTCRB show={isActive && !isDeleted} userData={row} />
        </td>
      </tr>
    );
  });
};

ListRow.propTypes = {
  tableData: PropTypes.array
};

export default ListRow;
