import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Table } from "react-bootstrap";
import CenterRowASC from "containers/ControlPanel/Payments/ASC/CenterRowASC";
import { fetchAscList, fetchAscInvoices } from "actions/asc";
import "containers/ControlPanel/Payments/payments.css";

const CenterListASC = ({ asc, fetchAscList, fetchAscInvoices }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAscList().then(() => {
      setLoading(false);

      // run background long task
      fetchAscInvoices();
    });
  }, [fetchAscList, fetchAscInvoices]);

  if (loading || !asc || !asc.centers) return "Loading...";

  return (
    <Row>
      <Table striped condensed hover className="ctia-table">
        <thead>
          <tr>
            <th>
              <Row>
                <Col xs={3}>Authorized Service Center</Col>
              </Row>
            </th>
            <th style={{ width: 200 }}>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {asc.centers.map((center, i) => {
            if(center.isActiveASC)
              return <CenterRowASC center={center} key={i} />;
          })}
        </tbody>
      </Table>
    </Row>
  );
};

function mapStateToProps({ asc }) {
  return { asc };
}

export default connect(mapStateToProps, { fetchAscList, fetchAscInvoices })(
  CenterListASC
);
