import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { getWirelessAsString } from "helpers/Wireless";

const DeviceWireless = ({ request, editable = false }) => {
  const { wirelessToggled, wireless } = request;

  return (
    <FormElement
      type={editable ? "toggleButtonGroup" : ""}
      id="wirelessToggled"
      label="Wireless Technologies Supported"
      list={wireless}
      listToggled={wirelessToggled}
      value={getWirelessAsString(wirelessToggled)}
      editable={editable}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(DeviceWireless);
