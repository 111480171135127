import React from "react";
import ApproveCount from "components/MainMenu/Badges/ApproveCount";
import ApproveCountBattery from "components/MainMenu/Badges/ApproveCountBattery";

const badgeTypes = {
  approveCount: ApproveCount,
  approveCountBattery: ApproveCountBattery,
};

const NavBadge = ({ badge }) => {
  const Content = badgeTypes[badge];

  if (Content) {
    return <Content />;
  }

  return null;
};

export default NavBadge;
