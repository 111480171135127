import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import ModalConfirm from "components/ModalConfirm";
import ListRow from "components/request/ListRowOta";
import { Table } from "react-bootstrap";
import { withdrawRequest, unwithdrawRequest } from "actions/requestOta";
import NoTableData from "components/NoTableData";

class RequestListTableOta extends Component {
  state = {
    showWithdrawConfirm: false,
    showUnwithdrawConfirm: false,
    requestData: null,
    isLoading: false,
    isFormValid: true
  };

  onWithdraw = () => {
    // withdraw reason
    const reason = document.getElementById("reasonRef").value;

    if (reason.length < 5) {
      this.setState({ isFormValid: false });
    } else {
      this.setState({ isLoading: true });
      this.props
        .withdrawRequest(this.state.requestData.id, reason)
        .then(this.clearAndFetch);
    }
  };

  onUnwithdraw = () => {
    this.setState({ isLoading: true });
    this.props
      .unwithdrawRequest(this.state.requestData.id)
      .then(this.clearAndFetch);
  };

  openWithdrawConfirm = requestData => {
    this.setState({
      showWithdrawConfirm: true,
      showUnwithdrawConfirm: false,
      requestData
    });
  };

  openUnwithdrawConfirm = requestData => {
    this.setState({
      showUnwithdrawConfirm: true,
      showWithdrawConfirm: false,
      requestData
    });
  };

  onCloseConfirm = () => {
    this.setState({
      showWithdrawConfirm: false,
      showUnwithdrawConfirm: false,
      requestData: null,
      isLoading: false
    });
  };

  clearAndFetch = () => {
    this.props.fetchHandler();
    this.onCloseConfirm();
  };

  onValidate = value => {
    this.setState({ isFormValid: value.length > 4 });
  };

  render() {
    const { user, data } = this.props;

    const renderTableBody = () => {
      if (!data || _.isEmpty(data)) {
        return <NoTableData colSpan={user.isStaff ? 4 : 3} />;
      }

      return (
        <ListRow
          tableData={data}
          isStaffUser={user.isStaff}
          isVendorUser={user.isVendor}
          openWithdrawConfirm={this.openWithdrawConfirm}
          openUnwithdrawConfirm={this.openUnwithdrawConfirm}
        />
      );
    };

    return (
      <div>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>Request ID</th>
              {user.isStaff && <th>Vendor</th>}
              <th>Model Name/Number</th>
              <th />
            </tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </Table>
        <ModalConfirm
          type="withdrawReasonOta"
          show={this.state.showWithdrawConfirm}
          onHide={this.onCloseConfirm}
          onHandle={this.onWithdraw}
          requestData={this.state.requestData}
          isLoading={this.state.isLoading}
          isFormValid={this.state.isFormValid}
          onValidate={this.onValidate}
        />
        <ModalConfirm
          type="unwithdraw"
          show={this.state.showUnwithdrawConfirm}
          onHide={this.onCloseConfirm}
          onHandle={this.onUnwithdraw}
          requestData={this.state.requestData}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

function mapStateToProps({ user, app }) {
  return { user, app };
}

export default connect(mapStateToProps, {
  withdrawRequest,
  unwithdrawRequest
})(RequestListTableOta);
