import React, { Component } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { InlinePreloader } from "ctia-ui";
import { isInitial } from "helpers/RequestHelper";
import { handleCheckboxGroupCheckAll, mapOperators } from "actions/request";
import { fetchOperators } from "actions/operators";

class OperatorsList extends Component {
  componentDidMount() {
    this.props.fetchOperators().then(this.mapRequestOperators);
  }

  mapRequestOperators = () => {
    this.props.mapOperators(
      this.props.operators,
      this.props.request.model.devicemodeloperators
    );
  };

  handleSelectAllOption = checked => {
    const event = { id: "operators", checked };
    this.props.handleCheckboxGroupCheckAll(this.props.request.operators, event);
  };

  render() {
    if (!this.props.operators || !this.props.request.operators)
      return <InlinePreloader />;

    const { app, request, user, readonly } = this.props;
    const isEditable = app.isBLCCert
      ? false
      : user.canEditRequest && isInitial(request.type);

    return (
      <FormElement
        type="checkboxList"
        id="operators"
        label="Operators"
        list={request.operators}
        editable={isEditable}
        inline={false}
        selectAll={true}
        onSelectAll={this.handleSelectAllOption}
        readonly={readonly}
      />
    );
  }
}

function mapStateToProps({ app, request, operators, user }) {
  return { app, request, operators, user };
}

export default connect(mapStateToProps, {
  handleCheckboxGroupCheckAll,
  fetchOperators,
  mapOperators
})(OperatorsList);
