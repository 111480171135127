import _ from "lodash";
import React from "react";
import FormElement from "components/FormElement";
import { formatName } from "helpers/StringHelper";
import { sortByFieldname } from "helpers/SortHelper";

/**
 * Combine short billing info.
 *
 * Format all passed fields as a string using comma.
 *
 * @param {object} addressEntry Contains company name, city and country
 * @returns string Formatted short billng address as a string
 */
export const formatBillingCompany = ({ name, city, countrycode }) => {
  const emptyVal = "n/a";
  const nameVal = name || emptyVal;
  const cityVal = city || emptyVal;
  const countryVal = countrycode || emptyVal;
  return `${nameVal} (${cityVal}, ${countryVal})`;
};

/**
 * Combine full billing info.
 *
 * Format all passed fields as a string using comma.
 *
 * @param {object} addressEntry Contains all possible billing address fields
 * @returns string Formatted billing address which contains all passed fields
 */
export const formatBillingAddress = address => {
  if (!address) return "n/a";

  const { address1, address2, city, state, countrycode, zipcode } = address;

  const addressArr = _.compact([
    address1,
    address2,
    city,
    state,
    countrycode,
    zipcode
  ]).map(s => {
    return _.trimEnd(s, ",");
  });

  return _.join(addressArr, ", ") || "n/a";
};

/**
 * Prepares POCs to render as HTML <select> items.
 *
 * First and last elements of the list are always pre-defined.
 * They will be used for special action triggers inside a code logic.
 *
 * @param {array} pocList List of POCs
 * @returns [] if passed list is empty
 * @returns formatted array with options ready to be rendered
 */
export const formatPocList = (pocList, requestId = "") => {
  if (_.isEmpty(pocList)) return [];

  let options = _.map(pocList, poc => {
    return {
      title: formatName(poc.fullname),
      value: Number(poc.id)
    };
  });

  // add `select poc` option to the ending of an option list
  if (!requestId)
    options.unshift({
      title: "--- Select a Billing PoC ---",
      value: 0
    });

  // add `new poc` option to the beginning of an option list
  options.push({
    title: "--- Add new Billing PoC ---",
    value: "new"
  });

  return options;
};

export const renderSelectedPocInfo = request => {
  if (!request) return null;
  const { billingPocId, pocs, tn, modelName, vendor } = request;
  const pocsObj = _.mapKeys(pocs, "id");
  const selectedId = Number(billingPocId);
  const pocIds = _.map(pocs, "id");
  const pocsUpdated = _.indexOf(pocIds, selectedId) > -1;

  if (selectedId && selectedId > 0 && pocsUpdated) {
    const { fullname, phone, email } = pocsObj[selectedId];
    const emailData = { tn, email, modelName, vendor };

    return (
      <div>
        <FormElement
          id="selectedName"
          label="Billing PoC Name"
          value={fullname}
        />
        <FormElement
          id="selecteEmail"
          label="Billing PoC Email"
          value={emailData}
          type="emailLink"
          useSubmitterNameAsTitle={false}
        />
        <FormElement
          id="selectedPhone"
          label="Billing PoC Phone"
          value={phone}
        />
      </div>
    );
  }

  return null;
};

export const onSelectPoc = (
  onToggleHandler,
  onChangeHandler,
  onCleanHandler,
  event
) => {
  if (event.target.value === "new") {
    // hide selector (callback from parent component)
    onToggleHandler(false);

    // clean old (selected) values
    onCleanHandler();
  } // set poc id for request
  else onChangeHandler(event);
};

/**
 * Prepares address list to render as HTML <select> items.
 *
 * First and last elements of the list are always pre-defined.
 * They will be used for special action triggers inside a code logic.
 *
 * @param {array} pocList List of POCs
 * @returns [] if passed list is empty
 * @returns formatted array with options ready to be rendered
 */
export const formatAddressList = (addressList, requestId = "") => {
  if (_.isEmpty(addressList)) return [];

  let options = _.map(addressList, address => {
    return {
      title: formatBillingCompany(address),
      value: Number(address.id)
    };
  });

  // sort addresses by title
  options = sortByFieldname(options, "title");

  if (!requestId)
    options.unshift({
      title: "--- Select a Billing Address ---",
      value: 0
    });

  options.push({
    title: "--- Add new Address entry ---",
    value: "new"
  });

  return options;
};

export const showAddressDetails = request => {
  const { billingAddressId, billingAddresses } = request;
  const addrObj = _.mapKeys(billingAddresses, "id");
  const selectedId = Number(billingAddressId);
  const ids = _.map(billingAddresses, "id");
  const addrUpdated = _.indexOf(ids, selectedId) > -1;

  if (billingAddresses && selectedId && selectedId > 0 && addrUpdated) {
    const billingObj = addrObj[selectedId];
    const {
      name,
      address1,
      address2,
      city,
      state,
      countrycode,
      zipcode
    } = billingObj;
    return (
      <div>
        <FormElement
          id="selectedName"
          label="Billing Company Name"
          value={name}
        />
        <FormElement
          id="selectedAddressLine1"
          label="Billing Address Line 1"
          value={address1}
        />
        <FormElement
          id="selectedAddressLine2"
          label="Billing Address Line 2"
          value={address2}
          show={!_.isEmpty(String(address2))}
        />
        <FormElement
          id="selectedAddressCountry"
          label="Country"
          value={countrycode}
          show={!_.isEmpty(String(countrycode))}
        />
        <FormElement id="selectedAddressCity" label="City" value={city} />
        <FormElement
          id="selectedAddressState"
          label="State"
          value={state}
          show={!_.isEmpty(String(state))}
        />
        <FormElement
          id="selectedAddressZip"
          label="Postal Code"
          value={zipcode}
          show={!_.isEmpty(String(zipcode))}
        />
      </div>
    );
  }

  return null;
};

export const getBillingPermissions = (app, request, user) => {
  if (request.isPaid) return false;
  if (app.isCyberCert) return user.canEditRequest;
  if (app.isOTACert) return user.canEditBilling;
  if (app.isBatteryCert) return user.canEditBilling;
  if (app.isBLCCert) return user.canEditBilling;
  if (app.isDHRCert) return user.canEditRequest;

  console.error(
    "Unknown certification program, check getBillingPermissions() method"
  );
  return false;
};

/**
 * Helper to define correct invoice number.
 * Checks if the request has a legacy number or should use brand new instead.
 *
 * @param {object} request Request Object
 * @returns {string} Invoice number as a string
 */
export const getInvoiceNumber = request => {
  if (!request) return "n/a";
  if (request.invoice && request.invoice.number) return request.invoice.number;

  return request.tn || "n/a";
};
