import { getSelectedOperatorIds } from "helpers/Operators";
import { makeSimpleArgs } from "helpers/ArgsHelper";

const getCommonRequestFields = request => {
  return {
    requesttypeid: 1,
    pocid: Number(request.pocId),
    hwversion: request.hwVersion,
    primarylabid: request.cdmaLab,
    billingid: Number(request.billingId),
    purchaseorder: request.billingOrderNum
  };
};

export const prepareNewModelArgsDHR = request => {
  const commonFields = getCommonRequestFields(request);
  return makeSimpleArgs({
    requests: [{ ...commonFields }],
    modelnumber: request.existedModel,
    devicemodeloperators: getSelectedOperatorIds(request.operators)
  });
};

export const prepareExistingLegacyModelArgsDHR = request => {
  const commonFields = getCommonRequestFields(request);

  return makeSimpleArgs({
    requests: [{ ...commonFields }],
    legacydevicemodelid: request.existedModelId,
    modelnumber: request.existingModelName,
    devicemodeloperators: getSelectedOperatorIds(request.operators)
  });
};

export const prepareExistingModelArgsDHR = request => {
  const commonFields = getCommonRequestFields(request);

  return makeSimpleArgs({
    ...commonFields,
    devicemodelid: request.existedModelId,
    devicemodel: {
      id: request.existedModelId,
      devicemodeloperators: getSelectedOperatorIds(request.operators)
    }
  });
};

export const defineSubmissionParams = request => {
  if (request.existingModelIsLegacy) {
    return {
      body: prepareExistingLegacyModelArgsDHR(request),
      url: "devicemodels"
    };
  }

  const body = request.existedModelId
    ? prepareExistingModelArgsDHR(request)
    : prepareNewModelArgsDHR(request);

  const url = request.existedModelId ? "requests" : "devicemodels";

  return { body, url };
};

export const prepareEcoArgsDHR = request => {
  return makeSimpleArgs({
    devicemodelid: request.existedModelId,
    parentid: request.parentId,
    billingid: Number(request.billingId),
    hwversion: request.hwVersion,
    descriptionofchange: request.descriptionOfChange,
    pocid: Number(request.pocId),
    purchaseorder: request.billingOrderNum,
    requesttypeid: 2, // ECO
    primarylabid: request.cdmaLab
  });
};
