import moment from "moment";
import { fillDocsArray } from "helpers/UploadsHelper";
import { getWirelessBaseList } from "helpers/Wireless";
import testTypes from "dictionaries/TestTypes";
import reqTypes from "dictionaries/ReqTypes";

const getCoreFields = () => {
  return {
    agreementAccepted: false,
    cdmaLab: 0,
    certCategory: 0,
    descriptionOfChange: "",
    deviceType: 0,
    hwVersion: "",
    isInitial: false,
    isEco: false,
    modelName: "",
    supportDocs: fillDocsArray(),
    submissionDate: "",
    swVersion: "",
    tests: [],
    usersManual: {
      file: null,
      status: 1
    }
  };
};

const getBillingFields = () => {
  return {
    billingCompany: "",
    billingAddress: "",
    billingPocName: "",
    billingPocPhone: "",
    billingPocPhoneExt: "",
    billingPocEmail: "",
    billingOrderNumber: ""
  };
};

const getTestFields = () => {
  return {
    testResult: 0,
    planVersion: "",
    hardwareVersion: "",
    softwareVersion: "",
    testEndDate: moment(),
    testStartDate: moment(),
    reportedDate: ""
  };
};

const getPocFields = () => {
  return {
    pocFirstName: "",
    pocLastName: "",
    pocEmail: "",
    pocPhone: "",
    pocPhoneExt: ""
  };
};

export function getCyberRequestBackbone() {
  const coreFields = getCoreFields();
  const billingFields = getBillingFields();
  const testFields = getTestFields();
  const pocFields = getPocFields();
  return {
    ...coreFields,
    ...billingFields,
    ...pocFields,
    testInfo: {
      ...testFields,
      testType: testTypes.winnfTs0122
    },
    wireless: getWirelessBaseList()
  };
}

export function getBatteryRequestBackbone() {
  const coreFields = getCoreFields();
  const billingFields = getBillingFields();
  const testFields = getTestFields();
  const pocFields = getPocFields();
  return {
    ...coreFields,
    billing: {
      ...billingFields
    },
    ...pocFields,
    testInfo: {
      ...testFields,
      testType: testTypes.battery
    },
    voltage: [1, ""],
    capacity: [1, ""]
  };
}

export function getBLCRequestBackbone() {
  const coreFields = getCoreFields();
  const billingFields = getBillingFields();
  const testFields = getTestFields();
  const pocFields = getPocFields();

  return {
    ...coreFields,
    billing: {
      ...billingFields
    },
    ...pocFields,
    testInfo: {
      ...testFields,
      testType: testTypes.battery
    },
    modelName: "",
    devicemodelid: "",
    hwVersion: "",
    swVersion: "",
    operatingSystem: "",
    linkedrequests: []
  };
}

export function getOTARequestBackbone() {
  const billingFields = getBillingFields();
  const pocFields = getPocFields();
  return {
    ...billingFields,
    hwVersion: "",
    modelName: "",
    ...pocFields,
    swVersion: "",
    tests: [],
    type: reqTypes.ota
  };
}

export function getDHRRequestBackbone() {
  const coreFields = getCoreFields();
  const billingFields = getBillingFields();
  const testFields = getTestFields();
  const pocFields = getPocFields();
  return {
    ...coreFields,
    ...billingFields,
    ...pocFields,
    testInfo: {
      ...testFields,
      testType: testTypes.dhr
    },
    type: reqTypes.initial,
    isInitial: true,
    canBeSaved: true,
    areOperatorsMandatory: true
  };
}

export function getDHRRequestBackboneECO() {
  const billingFields = getBillingFields();
  const testFields = getTestFields();
  const pocFields = getPocFields();
  return {
    ...billingFields,
    ...pocFields,
    cdmaLab: 0,
    descriptionOfChange: "",
    hwVersion: "",
    isInitial: false,
    isEco: true,
    modelName: "",
    type: reqTypes.eco,
    supportDocs: fillDocsArray(),
    testInfo: {
      ...testFields,
      testType: testTypes.dhr
    }
  };
}
