import React from "react";
import { WithModal } from "ctia-ui";
import AgreementModalASC from "components/ProgramSelector/ServiceCenter/AgreementModalASC";

class AgreementSignASC extends WithModal {
  render() {
    const onClickHandler = this.props.agreementHandler || this.handleModal;
    return (
      <>
        <span className="glow-link cursor-pointer" onClick={onClickHandler}>
          Annual License and Service Agreement
        </span>
        <AgreementModalASC
          show={this.state.showModal}
          modalHandler={this.handleModal}
        />
      </>
    );
  }
}

export default AgreementSignASC;
