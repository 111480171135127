import { makeSimpleArgs } from "helpers/ArgsHelper";
import { formatDate } from "helpers/DateHelper";

export const formatTestsToArgs = tests =>
  tests.map(test => {
    return { labid: test.catlInfo.id, interfaceid: test.planInfo.value };
  });

export const prepareOtaSubmissionArgs = request => {
  const tests = formatTestsToArgs(request.otaInterfaces);

  return makeSimpleArgs({
    requests: [
      {
        billingid: Number(request.billingId),
        certtypeid: 27,
        hwversion: request.hwVersion,
        pocid: Number(request.pocId),
        purchaseorder: request.billingOrderNum,
        requesttests: [...tests],
        requesttypeid: 1,
        submitteruserid: Number(request.submitterId),
        swversion: request.swVersion
      }
    ],
    modelnumber: request.existedModel
  });
};

export const prepareOtaExistedModelArgs = request => {
  const tests = formatTestsToArgs(request.otaInterfaces);

  return makeSimpleArgs({
    billingid: Number(request.billingId),
    certtypeid: 27,
    devicemodelid: request.existedModelId,
    hwversion: request.hwVersion,
    pocid: Number(request.pocId),
    purchaseorder: request.billingOrderNum,
    requesttests: [...tests],
    requesttypeid: 1,
    submitteruserid: Number(request.submitterId),
    swversion: request.swVersion
  });
};

export const defineSubmissionParams = request => {
  const body = request.existedModelId
    ? prepareOtaExistedModelArgs(request)
    : prepareOtaSubmissionArgs(request);

  const url = request.existedModelId ? "requests" : "devicemodels";

  return { body, url };
};

export const getTestFieldsOTA = testModule => {
  const {
    id,
    actualstartdate,
    actualenddate,
    requesttestpassedstatusid
  } = testModule;

  // @NOTE: All battery types require testing dates range
  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return {
    id,
    ...dates,
    requesttestpassedstatusid
  };
};

export const prepareTestResultsArgs = (request, testModule) => {
  const testArgs = getTestFieldsOTA(testModule);

  const {
    model,
    devicetype,
    hwVersion: hwversion,
    swVersion: swversion } = request;

  const devicemodel = {
    id: model.id
  };

  const args = {
    devicemodel,
    devicetype,
    hwversion,
    swversion,
    requesttests: [
      {
        ...testArgs
      }
    ]
  };

  return makeSimpleArgs(args);
};