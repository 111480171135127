import React from "react";
import { WithModal } from "ctia-ui";
import EditOperatorModal from "containers/ControlPanel/Orgs/Operators/EditOperatorModal";

class EditOperator extends WithModal {
  render() {
    const { id, name } = this.props;

    return (
      <div className="glow-link cursor-pointer">
        <span onClick={this.handleModal}>{name}</span>
        {this.state.showModal && (
          <EditOperatorModal
            id={id}
            modalHandler={this.handleModal}
          />
        )}
      </div>
    );
  }
}

export default EditOperator;
