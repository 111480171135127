import * as actions from "actions/types";
import { restClientGeneral, restClientAdmin } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";

export function setOrgType(payload) {
  return {
    type: actions.SET_ORG_TYPE,
    payload
  };
}

export const getOrganization = (id, orgType) => async dispatch => {
  try {
    let urlTail = "";
    if (orgType && orgType === "operator")
      urlTail = "?include=companycerttypes";
    else if (orgType && orgType === "lab")
      urlTail = "?include=companycerttypes,companyinterfaces";

    const response = await restClientAdmin.get(
      `companies/${id}${urlTail}`
    );

    dispatch({ type: actions.GET_ORGANIZATION, payload: response.data.data });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUserAssignableOrgs = (role, userId) => async dispatch => {
  try {
    const selectClause = "&select=name,ctiaid,id";

    const response = await restClientAdmin.get(
      `users/${userId}/assignableCompanies?${selectClause}&orderBy=name&page=0&returnAs=query`
    );

    dispatch({
      type: actions.GET_ORGANIZATIONS_FOR_USER,
      payload: response.data.data
    });

    // save selected role for a reference
    dispatch({
      type: actions.SET_USER_TYPE_ORG,
      role
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getOrganizationsByUserRole = role => async dispatch => {
  try {
    let whereClause = `where=role='${role}'`;

    // tada! suppliers are vendors after all
    if (role === "SUPPLIER") {
      whereClause = "where=role='VENDOR' AND isSupplier=1";
    }

    whereClause += " AND active=1";

    const selectClause = "&select=name,ctiaid,id";

    const response = await restClientAdmin.get(
      `companies?${whereClause}&orderBy=name${selectClause}&page=0&returnAs=query`
    );
    dispatch({
      type: actions.GET_ORGANIZATIONS_FOR_USER,
      payload: response.data.data
    });

    // save selected role for a reference
    dispatch({
      type: actions.SET_USER_TYPE_ORG,
      role
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export function clearOrgList() {
  return {
    type: actions.CLEAR_ORG_LIST
  };
}

export function clearUserOrgList() {
  return {
    type: actions.CLEAR_USER_ORG_LIST
  };
}

export const fetchCompaniesASC = () => async dispatch => {
  try {
    const response = await restClientGeneral.get(
      "companies?where=role='servicecenter'&orderBy=name&page=0&returnAs=query&showDisabled=true"
    );

    dispatch({
      type: actions.GET_ORGANIZATIONS_BY_TYPE,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchCompaniesVendor = () => async dispatch => {
  try {
    const response = await restClientGeneral.get(
      "companies?select=id,name,active,contactname,deletedat&where=role='vendor'&orderBy=name&page=0&returnAs=query&showDisabled=true"
    );

    dispatch({
      type: actions.GET_ORGANIZATIONS_BY_TYPE,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchCompaniesLab = () => async dispatch => {
  try {
    const response = await restClientGeneral.get(
      "companies?select=id,name,citydisplay,country,countrydisplay,active,ATLstatus,isactiveatl,contactname,deletedat&where=role='lab'&orderBy=name&page=0&returnAs=query&showDisabled=true"
    );

    dispatch({
      type: actions.GET_ORGANIZATIONS_BY_TYPE,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchCompaniesOperator = () => async dispatch => {
  try {
    const response = await restClientGeneral.get(
      "companies?select=id,name,active,contactname,companycerttypeslist,deletedat&where=role='operator'&orderBy=name&page=0&returnAs=query&showDisabled=true"
    );

    dispatch({
      type: actions.GET_ORGANIZATIONS_BY_TYPE,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export function setUserTypeOrg(role) {
  return {
    type: actions.SET_USER_TYPE_ORG,
    role
  };
}

export const enableOrganization = org => async dispatch => {
  try {
    // we're about to enable it, so new status will be equal to 1
    const active = 1;

    // allow soft deleted orgs to be managed
    const includeSoftDeletes = org.deletedat === "" ? false : true;

    // prepare req body as args
    const reqBody = JSON.stringify({ args: { active } });

    // add an extra flag to the URL if needed
    const urlTail = includeSoftDeletes
      ? `?includeSoftDeletes=${includeSoftDeletes}`
      : "";

    // update value on the server side
    const response = await restClientAdmin.put(
      `companies/${org.id}${urlTail}`,
      reqBody
    );

    dispatch({
      type: actions.ENABLE_ORGANIZATION,
      id: org.id,
      active
    });

    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.companyUpdatedSuccessfully
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const disableOrganization = (org, disableReason) => async dispatch => {
  try {
    // we're about to disable it, so new status will be equal to 0
    const active = 0;

    // allow soft deleted orgs to be managed
    const includeSoftDeletes = org.deletedat === "" ? false : true;

    // prepare req body as args
    const reqBody = JSON.stringify({ args: { active, disableReason } });

    // add an extra flag to the URL if needed
    const urlTail = includeSoftDeletes
      ? `?includeSoftDeletes=${includeSoftDeletes}`
      : "";

    // update value on the server side
    const response = await restClientAdmin.put(
      `companies/${org.id}${urlTail}`,
      reqBody
    );

    dispatch({
      type: actions.DISABLE_ORGANIZATION,
      id: org.id,
      active
    });

    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.companyUpdatedSuccessfully
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
