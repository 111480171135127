import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Bubble } from "ctia-ui";
import CyberComponent from "containers/CyberComponent";
import LabAuditInfo from "containers/request/battery/AuditLabInfo/LabAuditInfo";
import LabAuditResult from "containers/request/battery/AuditLabInfo/LabAuditResult";
import RequestControls from "containers/request/RequestControls";
import { updateRequestLab } from "actions/requestCyber";

class AuditLabInfo extends CyberComponent {
  validate() {
    return this.props.request.cdmaLab > 0;
  }

  onSaveClick = () => {
    this.props.updateRequestLab(this.props.request);
  };

  render() {
    const { show, tests, user, canSubmit } = this.props;

    if (!show) return null;

    const testModule = tests ? tests[0] : null;
    const isEditable = (user.canEditRequest || user.isStaff);
    const saveHandler = isEditable ? this.onSaveClick : null;

    return (
      <div>
        <Bubble show={canSubmit}>Please select a lab for the audit.</Bubble>
        <LabAuditInfo editable={isEditable} />
        <LabAuditResult testModule={testModule} />
        <RequestControls isValid={this.validate()} onSave={saveHandler} />
      </div>
    );
  }
}

AuditLabInfo.propTypes = {
  show: PropTypes.bool,
  canSubmit: PropTypes.bool
};

AuditLabInfo.defaultProps = {
  show: true,
  canSubmit: false
};

function mapStateToProps({ request, tests, user }) {
  return { request, tests, user };
}

export default connect(mapStateToProps, {
  updateRequestLab
})(AuditLabInfo);
