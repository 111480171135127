import _ from "lodash";
import * as actions from "actions/types";
import { sortByFieldname } from "helpers/SortHelper";
import { filterModelFields, filterModelFieldsBLC } from "helpers/ModelHelper";

const fetchModels = (action, state) => {
  if (!action.payload.data) return state;
  return { ...state, models: action.payload.data.data };
};

const getEmptyResult = () => {
  return { list: [], pages: { TOTALRECORDS: 0 } };
};

const getEmptyAuditList = () => {
  return { auditList: [], auditPages: { TOTALRECORDS: 0 } };
};

const fetchRequestsForTesting = action => {
  const payload = action.payload;
  if (payload && payload.length > 0) {
    return { list: payload, pages: payload.meta };
    // ===========
    // logic for wireless verification process
    // const list = _.filter(payload, req => {
    //   const {
    //     testingstatus,
    //     requesttypeid,
    //     wirelesscapabilties,
    //     lteverified,
    //     wifiverified
    //   } = req;

    //   // test is accepted or under review
    //   const checkTestStatus =
    //     isAccepted(testingstatus) || isUnderReview(testingstatus);

    //   // check if request has unverified wireless capabilities
    //   const isUnverified = hasUnverifiedWireless(
    //     wirelesscapabilties,
    //     lteverified,
    //     wifiverified
    //   );

    //   // initial req type where test status is complete
    //   const isInitialComplete =
    //     isInitial(requesttypeid) && isComplete(testingstatus) && isUnverified;

    //   return checkTestStatus || isInitialComplete || isUnverified;
    // });
    // return { list, pages: payload.meta };
  }

  return getEmptyResult();
};

const fetchRequestsForAuditing = (action, state) => {
  const payload = action.payload;
  if (payload && payload.length > 0) {
    return { ...state, auditList: payload, auditPages: payload.meta };
  }

  return { ...state, ...getEmptyAuditList() };
};

const fetchCertRequests = (action, state) => {
  const { data, meta } = action.payload;
  if (_.isEmpty(data)) {
    const res = getEmptyResult();
    return { ...state, ...res };
  }
  return { ...state, list: data, pages: meta };
};

const fetchApproveRequests = (action, state) => {
  const { data, meta } = action.payload;
  if (_.isEmpty(data)) return { ...state, approveList: [], approvePages: 0 };
  return { ...state, approveList: data, approvePages: meta };
};

const fetchUnpaid = (action, state) => {
  if (!action.payload) return state;
  return { ...state, unpaid: action.payload };
};

const fetchCrrEcoModels = (action, state) => {
  if (!action.payload) return state;
  const crrEcoModels = filterModelFields(action.payload);
  return { ...state, crrEcoModels };
};

const fetchHrrEcoModels = (action, state) => {
  const hrrEcoModels = !action.payload ? [] : filterModelFields(action.payload);
  return { ...state, hrrEcoModels };
};

const fetchPrrEcoModels = (action, state) => {
  const prrEcoModels = !action.payload ? [] : filterModelFields(action.payload);
  return { ...state, prrEcoModels };
};

const fetchEprEcoModels = (action, state) => {
  const eprEcoModels = !action.payload ? [] : filterModelFields(action.payload);
  return { ...state, eprEcoModels };
};

const fetchArrEcoModels = (action, state) => {
  const arrEcoModels = !action.payload ? [] : filterModelFields(action.payload);
  return { ...state, arrEcoModels };
};

const fetchAcrEcoModels = (action, state) => {
  const acrEcoModels = !action.payload ? [] : filterModelFields(action.payload);
  return { ...state, acrEcoModels };
};

const fetchBlcEcoModels = (action, state) => {
  const blcEcoModels = !action.payload
    ? []
    : filterModelFieldsBLC(action.payload);
  return { ...state, blcEcoModels };
};

const fetchBlcInitialModels = (action, state) => {
  const blcModels = !action.payload ? [] : filterModelFieldsBLC(action.payload);
  return { ...state, blcModels };
};

const fetchBscEcoModels = (action, state) => {
  const bscEcoModels = !action.payload ? [] : filterModelFields(action.payload);
  return { ...state, bscEcoModels };
};

const fetchVendorBatteryModels = (action, state) => {
  const vendorBatteryModels = !action.payload
    ? []
    : filterModelFields(action.payload);
  return { ...state, vendorBatteryModels };
};

const combineWithLegacy = (payload, state) => {
  if (!state) return null;

  // also mark legacy models
  const legacyModels = filterModelFields(payload).map(model => {
    return { ...model, isLegacy: true };
  });

  // on dev it has duplicates, so need to filter values-titles to be unique
  const combinedUniqueTitle = _.uniqBy(
    [...legacyModels, ...state.vendorBatteryModels],
    "title"
  );
  const combinedModels = _.uniqBy(combinedUniqueTitle, "value");

  // sort by title now
  const vendorBatteryModels = sortByFieldname(combinedModels, "title");

  return { ...state, vendorBatteryModels };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_MODEL_LIST:
      return fetchModels(action, state);
    case actions.FETCH_CERTIFICATION_REQUESTS:
      return fetchCertRequests(action, state);
    case actions.FETCH_APPROVE_WAITING_REQUESTS:
      return fetchApproveRequests(action, state);
    case actions.FETCH_REQUESTS_FOR_TESTING:
      return fetchRequestsForTesting(action);
    case actions.FETCH_REQUESTS_FOR_AUDITING:
      return fetchRequestsForAuditing(action, state);
    case actions.FETCH_UNPAID_REQUESTS:
      return fetchUnpaid(action, state);
    case actions.CLEAR_REQUESTS:
      const emptyList = getEmptyResult();
      const emptyAuditList = getEmptyAuditList();
      return { ...state, ...emptyList, ...emptyAuditList };
    case actions.FETCH_CRR_ECO_MODELS:
      return fetchCrrEcoModels(action, state);
    case actions.FETCH_HRR_ECO_MODELS:
      return fetchHrrEcoModels(action, state);
    case actions.FETCH_PRR_ECO_MODELS:
      return fetchPrrEcoModels(action, state);
    case actions.FETCH_EPR_ECO_MODELS:
      return fetchEprEcoModels(action, state);
    case actions.FETCH_ARR_ECO_MODELS:
      return fetchArrEcoModels(action, state);
    case actions.FETCH_ACR_ECO_MODELS:
      return fetchAcrEcoModels(action, state);
    case actions.FETCH_BLC_INITIAL_MODELS:
      return fetchBlcInitialModels(action, state);
    case actions.FETCH_BLC_ECO_MODELS:
      return fetchBlcEcoModels(action, state);
    case actions.FETCH_BSC_ECO_MODELS:
      return fetchBscEcoModels(action, state);
    case actions.FETCH_VENDOR_BATTERY_MODELS:
    case actions.FETCH_BSC_MODELS:
      return fetchVendorBatteryModels(action, state);
    case actions.FETCH_PTCRB_MODELS_FOR_BSC:
      return combineWithLegacy(action.payload, state);
    case actions.LOGOUT:
      return null;
    default:
      return state;
  }
}
