import React from "react";
import { Table } from "react-bootstrap";
import DeviceRow from "containers/Devices/BLC/DeviceRowBLC";

const DeviceTableBLC = ({ list, hideVendors, show }) => {
  if (!show) return null;

  return (
    <Table striped condensed hover className="ctia-table">
      <thead>
        <tr>
          {hideVendors ? null : <th>Vendor</th>}
          <th>Model Name/Number</th>
          <th>Device Type</th>
          <th>Approval Date</th>
        </tr>
      </thead>
      <tbody>
        <DeviceRow tableData={list} hideVendors={hideVendors}/>
      </tbody>
    </Table>
  );
};

export default DeviceTableBLC;
