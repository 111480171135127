import React from 'react';
import { connect } from 'react-redux';
import FormElement from 'components/FormElement';
import { setValue } from 'actions/request';
import { toggleOptions } from 'helpers/SupplierHelper';
import { requestFields } from 'helpers/AdapterSupplierHelper';

class AdapterModelOptions extends React.Component {
  requestField = requestFields.modelOption;

  componentWillUnmount() {
    this.props.setValue(this.requestField, '');
  }

  render() {
    const { request } = this.props;

    // do not render until supplier be selected:
    if (!request[requestFields.supplier]) return null;

    return (
      <FormElement
        type="toggleRadioGroup"
        id={this.requestField}
        label="Adapter Model Name/Number"
        value={request[this.requestField]}
        list={toggleOptions}
      />
    );
  }
}

function mapStateToProps({ request }) {
  return { request };
}

export default connect(
  mapStateToProps,
  { setValue }
)(AdapterModelOptions);
