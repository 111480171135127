import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { LoaderButton, IconLabel } from "ctia-ui";
import { Modal, Button } from "react-bootstrap";
import { deleteUser } from "actions/user";

import "containers/ControlPanel/Payments/payments.css";

const ConfirmDeleteModal = ({ show, modalHandler, user, deleteUser }) => {
  const [loading, setLoading] = useState(false);

  const renderSubmitButton = () => (
    <span className="icon-left-offset">
      <LoaderButton
        bsStyle="danger"
        bsSize="small"
        text={<IconLabel label="Delete" awesomeIcon="user-slash" />}
        onClick={() => {
          setLoading(true);
          deleteUser(user.ctiaid, "deleted")
            .then(() => {
              setLoading(false);
              modalHandler();
            })
            .catch(e => {
              setLoading(false);
              console.error(e);
              modalHandler();
            });
        }}
        isLoading={loading}
        loadingText="Deleting..."
      />
    </span>
  );

  return (
    <Modal show={show} onHide={modalHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Deleting User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this user?</p>
      </Modal.Body>
      <Modal.Footer>
        {renderSubmitButton()}
        <Button
          onClick={modalHandler}
          className="agr-close-btn"
          disabled={loading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  show: PropTypes.bool,
  modalHandler: PropTypes.func
};

export default connect(null, { deleteUser })(ConfirmDeleteModal);
