// Helper imports
import _ from "lodash";
import {
  addDays,
  formatDate,
  formatDateRangeForFileExport
} from "helpers/DateHelper";
import { restClientGeneral } from "libs/restClient";

// React/Redux imports
import PropTypes from "prop-types";

// Action name imports
import * as actions from "actions/types";
import { fileDownload } from "actions/uploads";

export const clearCertifiedPendingReport = () => async dispatch => {
  dispatch({
    type: actions.CLEAR_CERTIFIED_PENDING_REPORT_DETAILS,
    payload: null
  });
};

export const getCertifiedPendingReport = ({
  authorizationType,
  certificationTypeId,
  endingDate,
  requestStatusId,
  requestTypeId,
  startingDate
}) => async dispatch => {
  /// WHERE Clause arguments
  let authorizationTypeClause = "";
  if (
    _.isNumber(authorizationType) &&
    (authorizationType === 1625 || authorizationType === 1725)
  ) {
    authorizationTypeClause = `ieeetypeid = ${authorizationType} AND `;
  }

  let dateFromClause = "";
  if (startingDate) {
    const startDate = formatDate(startingDate);
    dateFromClause = `completedat >= '${startDate}' AND `;
  }

  let dateToClause = "";
  if (endingDate) {
    // coldfusion issue, have to add 2 extra day
    const finishDate = formatDate(addDays(endingDate, 2));
    dateToClause = `completedat <= '${finishDate}' AND `;
  }

  let requestTypeIdClause = "";
  if (_.isNumber(requestTypeId) && requestTypeId > 0) {
    requestTypeIdClause = ` AND requesttypeid = ${requestTypeId}`;
  }

  let whereClause =
    authorizationTypeClause +
    `certtypeid = ${certificationTypeId} AND ` +
    dateToClause +
    dateFromClause +
    `requeststatusid = ${requestStatusId}` +
    requestTypeIdClause;

  /// SELECT clause fields
  const selectFields = [
    "completedat",
    "createdat",
    "devicemodelid",
    "formatedtransactionnumber",
    "id", // id is 'requestid'
    "modelnumber",
    "name", // Name is 'requesttype'
    "requeststatusid",
    "vendorid",
    "vendorname",
    "vendorproductname",
    "deviceType"
  ].join(",");

  const includeClause = `&include=devicemodel, requesttype`;

  const url =
    `/requests` +
    `?where=${whereClause}` +
    `&select=${selectFields}` +
    includeClause +
    `&returnAs=query&page=0`;

  const payload = await restClientGeneral.get(url);
  dispatch({
    type: actions.FETCH_CERTIFIED_PENDING_REPORT_DETAILS,
    payload
  });
};

export function downloadCertifiedPendingReport({
  authorizationType,
  certificationTypeId,
  endingDate,
  requestStatusId,
  requestTypeId,
  startingDate
}) {
  /// WHERE Clause arguments
  let authorizationTypeClause = "";
  if (
    _.isNumber(authorizationType) &&
    (authorizationType === 1625 || authorizationType === 1725)
  ) {
    authorizationTypeClause = `ieeetypeid = ${authorizationType} AND `;
  }

  let dateFromClause = "";
  if (startingDate) {
    const startDate = formatDate(startingDate);
    dateFromClause = `completedat >= '${startDate}' AND `;
  }

  let dateToClause = "";
  if (endingDate) {
    // coldfusion issue, have to add 2 extra day
    const finishDate = formatDate(addDays(endingDate, 2));
    dateToClause = `completedat <= '${finishDate}' AND `;
  }

  let requestTypeIdClause = "";
  if (_.isNumber(requestTypeId) && requestTypeId > 0) {
    requestTypeIdClause = ` AND requesttypeid = ${requestTypeId}`;
  }

  let whereClause =
    authorizationTypeClause +
    `certtypeid = ${certificationTypeId} AND ` +
    dateToClause +
    dateFromClause +
    `requeststatusid = ${requestStatusId}` +
    requestTypeIdClause;

  /// SELECT clause fields (BLC Needs DeviceType)
  const selectFields = certificationTypeId === 39 ? [
    "completedat",
    "createdat",
    "devicemodelid",
    "formatedtransactionnumber",
    "id", // id is 'requestid'
    "modelnumber",
    "deviceType", //BLC Exclusive
    "name", // Name is 'requesttype'
    "requeststatusid",
    "vendorid",
    "vendorname",
    "vendorproductname"
  ].join(",") : [
    "completedat",
    "createdat",
    "devicemodelid",
    "formatedtransactionnumber",
    "id", // id is 'requestid'
    "modelnumber",
    "name", // Name is 'requesttype'
    "requeststatusid",
    "vendorid",
    "vendorname",
    "vendorproductname"
  ].join(",");

  const includeClause = `&include=devicemodel, requesttype`;

  const url =
    "/requests" +
    `?where=${whereClause}` +
    `&select=${selectFields}` +
    includeClause +
    "&asExcel=true" +
    "&returnAs=query" +
    "&page=0";

  const dates = formatDateRangeForFileExport(startingDate, endingDate);
  const fileName = `Certified_And_Pending_Report_${dates}.zip`;

  return fileDownload(fileName, url, "general");
}

downloadCertifiedPendingReport.propTypes = {
  authorizationType: PropTypes.number,
  certificationType: PropTypes.number,
  endingDate: PropTypes.object,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  requestStatus: PropTypes.object,
  requestType: PropTypes.number,
  startingDate: PropTypes.object
};
