import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import {
  fetchActiveLabs,
  selectLabOfUsers,
  fetchUsersOfLabs
} from "actions/users2labs";
import { renderNameWithFlag } from "containers/ControlPanel/Payments/RenderHelperATL";
import "antd/dist/antd.css";

const { Option } = Select;

const SelectLab = ({
  users2labs,
  fetchActiveLabs,
  selectLabOfUsers,
  fetchUsersOfLabs
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchActiveLabs().then(() => setLoading(false));
  }, [fetchActiveLabs, setLoading]);

  const onChange = value => {
    // hide transfer form
    setLoading(true);

    // set value on redux for other components
    selectLabOfUsers(value);

    // fetch assigned labs per selected user from server
    fetchUsersOfLabs(value).then(() => setLoading(false));
  };

  const options =
    users2labs && users2labs.activeLabs ? users2labs.activeLabs : [];

  const placeholder = loading ? "Loading... Please wait" : "Select a Lab";

  return (
    <Select
      showSearch
      style={{ width: 350 }}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        option.textValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={loading}
    >
      {options.map(lab => {
        return (
          <Option key={lab.ctiaid} value={lab.ctiaid} textValue={lab.name}>
            {renderNameWithFlag(lab.name, lab.country)}
          </Option>
        );
      })}
    </Select>
  );
};

function mapStateToProps({ users2labs }) {
  return { users2labs };
}

export default connect(mapStateToProps, {
  fetchActiveLabs,
  selectLabOfUsers,
  fetchUsersOfLabs
})(SelectLab);
