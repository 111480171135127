import _ from "lodash";
import React from "react";
import {
  FormGroup,
  ControlLabel,
  Col,
  FormControl,
  HelpBlock
} from "react-bootstrap";
import PropTypes from "prop-types";
import CompanySelector from "components/signup/FormElements/CompanySelector";
import CountrySelector from "components/signup/FormElements/CountrySelector";
import StateAndProvinceSelector from "components/signup/FormElements/StateAndProvinceSelector";
import TextArea from "components/form/TextArea";
import {
  makeEmailValidation,
  makePhoneValidation
} from "helpers/ValidatorHelper";

const SignupFormField = ({
  formFieldDefinition,
  fieldKey,
  fieldValue,
  changeHandler
}) => {
  if (formFieldDefinition.type === "companySelector") {
    return <CompanySelector changeHandler={changeHandler} />;
  }

  let selector,
    validationState = null,
    validationMessage = null;

  switch (formFieldDefinition.type) {
    case "countrySelector":
      selector = (
        <CountrySelector
          fieldValue={fieldValue}
          changeHandler={changeHandler}
        />
      );
      break;
    case "stateAndProvinceSelector":
      const selectedCountryCode = formFieldDefinition.selectedCountryCode;
      selector = (
        <StateAndProvinceSelector
          fieldValue={fieldValue}
          selectedCountryCode={selectedCountryCode}
          changeHandler={changeHandler}
        />
      );
      break;
    case "textArea":
      selector = <TextArea value={fieldValue} onChange={changeHandler} />;
      break;
    case "email":
      selector = <FormControl value={fieldValue} onChange={changeHandler} />;
      if (!_.isUndefined(fieldValue)) {
        const validation = makeEmailValidation(fieldValue);

        if (validation) {
          const { state, message } = validation;
          validationState = state;
          validationMessage = message;
        }
      }
      break;
    case "phone":
      selector = <FormControl value={fieldValue} onChange={changeHandler} />;
      if (!_.isUndefined(fieldValue)) {
        const validation = makePhoneValidation(fieldValue);

        if (validation) {
          const { state, message } = validation;
          validationState = state;
          validationMessage = message;
        }
      }
      break;
    default:
      selector = <FormControl value={fieldValue} onChange={changeHandler} />;
  }

  return (
    <FormGroup controlId={fieldKey} validationState={validationState}>
      <Col componentClass={ControlLabel}>
        {formFieldDefinition.required && (
          <span className="text-danger">* </span>
        )}
        {formFieldDefinition.title}
      </Col>
      <Col>{selector}</Col>
      {validationState && <HelpBlock>{validationMessage}</HelpBlock>}
    </FormGroup>
  );
};

SignupFormField.propTypes = {
  field: PropTypes.object,
  fieldKey: PropTypes.string,
  fieldValue: PropTypes.string,
  changeHandler: PropTypes.func
};

export default SignupFormField;
