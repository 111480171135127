// ES6 / Helper
import _ from "lodash";

// React / Redux
import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";

// Components and Containers
import BatteryDeviceRows from "containers/Devices/Battery/BatteryDevices/BatteryDeviceTable/BatteryDeviceRows";

// Helpers and Dictionaries
import { types } from "helpers/BatteryCertHelper";
import { requestStatuses } from "helpers/RequestHelper";

const BatteryDeviceTable = ({
  requestReloadTable = () => {},
  show,
  tableData,
  // Redux
  app,
  devices,
  user
}) => {
  if (!show) return null;

  const containsEPRRResults = () => {
    const transactionNumbers = _.map(tableData, row => {
      return row.formatedtransactionnumber;
    });
    return _.some(transactionNumbers, "EPRR");
  };

  const firstColumnName = containsEPRRResults()
    ? "Supplier"
    : "Supplier/Vendor";

  const shouldShowStatusColumn = () => {
    if (_.isEmpty(tableData)) return false;

    const selectedCertTypes = devices.searchTerms.certTypes;
    const selectedStatusType = app.batteryRequestStatus.filter[0];
    const withdrawnStatusType = requestStatuses.requestWithdrawn;

    if (_.includes(selectedCertTypes, types.BSC)) {
      if (selectedStatusType === withdrawnStatusType) {
        // Check that at least one row actually has withdrawn status to prevent rendering glitch
        const hasAtLeastOneRowWithWithdrawnStatus = _.has(tableData, row => {
          return row.requeststatusid === withdrawnStatusType;
        });

        return hasAtLeastOneRowWithWithdrawnStatus;
      }

      return false;
    }

    return true;
  };

  const showStatusColumn = shouldShowStatusColumn();

  const shouldShowDelistColumn = () => {
    if (_.isEmpty(tableData)) return false;

    const selectedCerts = devices.searchTerms.certTypes;
    const isAdmin = user.isStaff;
    const isValidSelectedCert = !_.includes(selectedCerts, types.BSC);

    return isAdmin && isValidSelectedCert;
  };
  const showDelistColumn = shouldShowDelistColumn();

  const shouldShowWithdrawColumn = () => {
    if (_.isEmpty(tableData)) return false;
    return user.isStaff;
  };
  const showWithdrawColumn = shouldShowWithdrawColumn();

  return (
    <Table responsive striped condensed hover className="ctia-table">
      <thead>
        <tr>
          <th>{firstColumnName}</th>
          <th>Model Name/Number</th>
          <th>Certification Type</th>
          {showStatusColumn && <th>Status</th>}
          {showWithdrawColumn && <th />}
          {showDelistColumn && <th />}
        </tr>
      </thead>
      <tbody>
        <BatteryDeviceRows
          requestReloadTable={requestReloadTable}
          tableData={tableData}
          showDelistColumn={showDelistColumn}
          showStatusColumn={showStatusColumn}
          showWithdrawColumn={showWithdrawColumn}
        />
      </tbody>
    </Table>
  );
};

function mapStateToProps({ app, devices, user }) {
  return { app, devices, user };
}

export default connect(mapStateToProps, {})(BatteryDeviceTable);
