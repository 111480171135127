import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SupportDocs from 'components/SupportDocs';
import RequestControls from 'containers/request/RequestControls';
import { submitCyberECO } from 'actions/requestCyber';

class Documents extends Component {
  onSubmit = () => {
    const { model, request, submitCyberECO } = this.props;

    // add to request info about parent model
    const requestWithModel = {
      ...request,
      parentId: model.selectedVersion,
      parentModelId: model.info.id
    };

    return submitCyberECO(requestWithModel);
  };

  render() {
    if (!this.props.show) return null;

    return (
      <div>
        <SupportDocs />
        <RequestControls isValid={true} onSubmit={this.onSubmit} />
      </div>
    );
  }
}

Documents.propTypes = {
  show: PropTypes.bool,
  canSubmit: PropTypes.bool
};

Documents.defaultProps = {
  show: true,
  canSubmit: false
};

function mapStateToProps({ request, model }) {
  return { request, model };
}

export default connect(
  mapStateToProps,
  {
    submitCyberECO
  }
)(Documents);
