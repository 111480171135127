import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestModelName from "components/request/RequestModelnameSimple";
import BatteryVoltageCapacity from "components/request/BatteryVoltageCapacity";
import RequestRevision from "components/request/RequestRevision";
import RequestParentLink from "components/request/RequestParentLink";
import BatteryParentDetails from "components/request/BatteryParentDetails";
import PackCellsReadonly from "components/request/PackCellsReadonly";
import NonSarSitesReadonly from "containers/request/battery/NonSarSites/NonSarSitesReadonly";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import RequestDescChanges from "components/request/RequestDescChanges";
import { initEPR } from "actions/battery/eprRequest";
import { mapNonSarManufSites } from "actions/battery/batteryRequests";

const DeviceInfoLabEPR = ({ request, initEPR, mapNonSarManufSites }) => {
  useEffect(() => {
    if (!request.id) {
      initEPR(null).then(() => {
        mapNonSarManufSites(request.linkedManufsites);
      });
    }
  }, [initEPR, mapNonSarManufSites, request]);

  return (
    <div>
      <Instructions />
      <Form horizontal>
        {request.isEco && (
          <>
            <RequestParentLink certPrefix="eprr" />
            <BatteryParentDetails />
            <hr />
          </>
        )}
        <RequestManufacturer />
        <RequestModelName />
        <hr />
        <RequestRevision fieldId="revision" editable={false} />
        {request.isEco && <RequestDescChanges />}
        <BatteryVoltageCapacity />
        <SubmissionDetails />
        <hr />
        <NonSarSitesReadonly />
        <hr />
        <PackCellsReadonly />
      </Form>
    </div>
  );
};

function mapStateToProps({ request, sites }) {
  return { request, sites };
}

export default connect(mapStateToProps, {
  initEPR,
  mapNonSarManufSites
})(DeviceInfoLabEPR);
