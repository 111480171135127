import _ from "lodash";

const userInfoFields = {
  rowOne: {
    firstName: {
      title: "First Name",
      required: true,
      columnWidth: 6,
      maxLength: 50
    },
    lastName: {
      title: "Last Name",
      required: true,
      columnWidth: 6,
      maxLength: 50
    }
  },
  rowTwo: {
    businessTitle: {
      title: "Business Title",
      required: true,
      columnWidth: 6,
      maxLength: 50
    },
    email: {
      title: "Email",
      required: true,
      type: "email",
      columnWidth: 6,
      maxLength: 100
    }
  },
  rowThree: {
    workPhone: {
      title: "Work Phone",
      required: true,
      type: "phone",
      columnWidth: 4
    },
    workPhoneExt: {
      title: "Ext.",
      required: false,
      type: "phone",
      columnWidth: 2,
      customClass: "FormColumns-leaning-left"
    },
    mobilePhone: {
      title: "Mobile Phone",
      required: false,
      type: "phone",
      columnWidth: 6
    }
  },
  rowFour: {
    address1: {
      title: "Address 1",
      required: true,
      columnWidth: 12,
      maxLength: 250
    }
  },
  rowFive: {
    address2: {
      title: "Address 2",
      required: false,
      columnWidth: 12,
      maxLength: 50
    }
  },
  rowSix: {
    address3: {
      title: "Address 3",
      required: false,
      columnWidth: 12,
      maxLength: 50
    }
  },
  rowSeven: {
    country: {
      title: "Country",
      required: true,
      type: "countrySelector",
      columnWidth: 12
    }
  },
  rowEight: {
    city: {
      title: "City",
      required: true,
      columnWidth: 4
    },
    adrState: {
      title: "State/Province",
      required: true,
      type: "stateAndProvinceSelector",
      columnWidth: 4
    },
    zipCode: {
      title: "Zip/Postal Code",
      required: true,
      columnWidth: 4
    }
  },
  rowNine: {
    description: {
      title: "Description of company’s business/operations",
      required: true,
      type: "textArea",
      columnWidth: 8,
      maxLength: 2500,
      id: "company-description-user-form"
    },
    companyType: {
      type: "companySelector",
      columnWidth: 4,
      required: true
    }
  }
};

function getFormFieldsAsDictionary() {
  const values = _.values(userInfoFields);
  return _.flatMap(values, _.entries).reduce((dictionary, currentItem) => {
    dictionary[`${currentItem[0]}`] = currentItem[1];
    return dictionary;
  }, []);
}
const userInfoFormFields = getFormFieldsAsDictionary();

export { userInfoFields, userInfoFormFields };
