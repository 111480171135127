import { connect } from "react-redux";
import React, { Component } from "react";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";
import { hasStringValue } from "helpers/RequestHelper";
import * as formHelper from "helpers/FormHelper";

class RequestModelECO extends Component {
  state = { touched: false, fieldName: "existedModel" };

  componentDidMount() {
    this.setState({
      fieldName: formHelper.defineModelFieldId(this.props.request)
    });
  }

  isValidationRequired = () => {
    return this.props.user.canEditDeviceInfo;
  };

  validateModelName() {
    if (!this.isValidationRequired() || !this.state.touched) return null;

    return hasStringValue(this.props.request, this.state.fieldName)
      ? null
      : "error";
  }

  onFieldChanged = event => {
    if (!this.state.touched) {
      this.setState({ touched: true });
    }

    this.props.handleInputChange(event);
  };

  render() {
    const { dhr, request } = this.props;
    const isEditable = this.isValidationRequired();
    const inputType = formHelper.defineEcoModelFieldType(isEditable, request);
    const modelName = formHelper.defineDhrModelName(isEditable, request);
    const { ecoModels } = dhr || [];

    return (
      <FormElement
        id={this.state.fieldName}
        type={inputType}
        label="Model Name/Number"
        value={modelName}
        options={ecoModels}
        editable={isEditable}
        onChange={this.onFieldChanged}
        validationState={this.validateModelName()}
        validationMessage={formHelper.emptyMsg}
        customOptions={{
          combobox: false,
          notFoundContent: "Model Name not found"
        }}
        useIdAsValue={true}
      />
    );
  }
}

function mapStateToProps({ dhr, request, user }) {
  return { dhr, request, user };
}

export default connect(mapStateToProps, { handleInputChange })(RequestModelECO);
