import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestHWVersion from "components/request/RequestHWVersion";
import RequestDescChanges from "components/request/RequestDescChanges";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestModelECO from "containers/request/DHR/RequestModelECO";
import { setValue } from "actions/request";
import { getEcoModelsDHR } from "actions/dhr/dhrRequest";
import { submitWizardStep } from "actions/wizard";
import { updateDeviceInfo } from "actions/requestOta";
import { hasStringValue } from "helpers/RequestHelper";
import { getRequestOwner } from "helpers/UsersHelper";

class DeviceInfoEcoDHR extends CyberComponent {
  modelField = this.props.request.id ? "modelName" : "existedModel";

  componentDidMount() {
    // fetch only for the first time
    if (!this.validate()) {
      const { request, user, getEcoModelsDHR } = this.props;
      const ownerId = getRequestOwner(request, user);

      getEcoModelsDHR(ownerId);
    }
  }

  onSaveClick = () => {
    this.props.updateDeviceInfo(this.props.request);
  };

  validateModel() {
    return hasStringValue(this.props.request, this.modelField);
  }

  validateHw() {
    return hasStringValue(this.props.request, "hwVersion");
  }

  validateDesc() {
    return hasStringValue(this.props.request, "descriptionOfChange");
  }

  validate() {
    // don't need to validate if form is non-editable
    if (!this.props.user.canEditDeviceInfo) return true;

    // check if all required fields are valid
    return this.validateModel() && this.validateHw() && this.validateDesc();
  }

  render() {
    const saveHandler = this.props.user.canEditDeviceInfo
      ? this.onSaveClick
      : null;

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestManufacturer />
          <RequestModelECO />
          <RequestHWVersion />
          <RequestDescChanges />
          <RequestControls isValid={this.validate()} onSave={saveHandler} />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ dhr, request, user }) {
  return { dhr, request, user };
}

export default connect(mapStateToProps, {
  getEcoModelsDHR,
  submitWizardStep,
  updateDeviceInfo,
  setValue
})(DeviceInfoEcoDHR);
