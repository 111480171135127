import React from "react";
import { Col, Label, Row } from "react-bootstrap";
import QuickSearch from "components/QuickSearch";

const RequestListTitle = ({
  listTitle = "Certification Requests",
  listLabel
}) => {
  return (
    <Row>
      <Col xs={12} md={12}>
        <div className="reqListContainer">
          <div>
            <h1>
              {listTitle} <Label>{listLabel}</Label>
            </h1>
          </div>
          <QuickSearch />
        </div>
      </Col>
    </Row>
  );
};

export default RequestListTitle;
