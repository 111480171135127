import React from "react";
import { getRequestTypeTitle } from "helpers/RequestHelper";

const RequestListRow = ({
  item,
  key,
  header,
  renderLink,
  isBattery,
  isBLC
}) => {
  return (
    <tr key={key}>
      {header.map(col => {
        if (!col.title || !col.fieldname) return <td></td>;
        else if (col.function && col.function === "reqlink")
          return <td>{renderLink(item, isBattery, isBLC)}</td>;
        else if (col.function && col.function === "reqtype")
          return <td>{getRequestTypeTitle(item.requesttypeid)}</td>;
        else return <td>{item[col.fieldname]}</td>;
      })}
    </tr>
  );
};

export default RequestListRow;
