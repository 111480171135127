import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import {
  formatLabList,
  requestFields,
  getCATLInfo,
  filterOtaLabs
} from "helpers/OTATestPlanHelper";
import { setValue } from "actions/request";

class SelectCATL extends React.Component {
  requestField = requestFields.otaLab;

  componentWillUnmount() {
    this.props.setValue(this.requestField, "");
  }

  // get only CATLs for selected interface (test plan)
  getCATLs = () => {
    const { request, ota } = this.props;

    // need to filter labs using labs ids for selected plan only
    return filterOtaLabs(request.otaPlan, ota.otaLabs);
  };

  onChange = event => {
    const { setValue } = this.props;
    const labId = event.target.value;

    const labInfo = getCATLInfo(labId, this.getCATLs());

    // set otaLab value
    setValue(this.requestField, labInfo);
  };

  render() {
    const { request, ota } = this.props;
    const { interfaces } = ota;

    // test plans not fetched yet or appear empty
    if (!interfaces || !interfaces.length) return null;

    // do not render untill test plan will be selected:
    const otaPlan = request[requestFields.otaPlan];
    if (!otaPlan || Number(otaPlan) === 0) return null;

    const requestOTALab = request[this.requestField];
    const selectedCATL = requestOTALab ? requestOTALab.id : 0;

    return (
      <div>
        <FormElement
          type="select"
          id={this.requestField}
          label="Authorized Test Lab"
          show={true}
          value={selectedCATL}
          onChange={this.onChange}
          options={formatLabList(this.getCATLs())}
        />
      </div>
    );
  }
}

function mapStateToProps({ request, ota }) {
  return { request, ota };
}

export default connect(mapStateToProps, { setValue })(SelectCATL);
