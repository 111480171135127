import * as roles from "dictionaries/Roles";
import { userTypeOptions } from "dictionaries/Roles";

const { VENDOR, LAB, OPERATOR, ASC } = roles;

export const orgTypes = [VENDOR, LAB, OPERATOR, ASC];

export const getOrgTypePrefix = type => {
  if (type === VENDOR) return "vendors";
  if (type === LAB) return "labs";
  if (type === OPERATOR) return "operators";
  if (type === ASC) return "asc";

  return null;
};

export const dbCertPrograms = {
  battery: "Battery Compliance",
  blc: "Battery Life",
  bluetooth: "Bluetooth",
  cyber: "IoT Cybersecurity",
  dhr: "Device Hardware Reliability",
  ptcrb: "PTCRB",
  ctia: "LTE/CDMA"
};

export const dbCertProgramsById = {
  38: "battery",
  39: "blc",
  11: "bluetooth",
  26: "cyber",
  40: "dhr",
  2: "ptcrb",
  1: "ctia"
};

export const getActiveProgramNames = programList => {
  if (!programList) return "";

  return programList
    .split(",")
    .map(prog => dbCertPrograms[prog])
    .join(", ");
};

export const fillUserTypeSelector = () =>
  userTypeOptions.map(userType => {
    const [title, value] = userType;

    return { title, value };
  });

export const mapCertPrograms = progList => {
  if (!progList || !progList.length) return "";

  return progList
    .map(program => dbCertProgramsById[program.certtypeid])
    .join(",");
};
