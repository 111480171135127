import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { mapCellListValues } from "helpers/BatterySupplierHelper";

const PackCellsReadonly = ({ request }) => {
  if (!request || !request.cellSuppliers) return null;

  const formattedList = mapCellListValues(request.cellSuppliers);

  return (
    <FormElement
      label="Cell(s)"
      list={formattedList}
      id="packCells"
      type="listOfLinksCRR"
      emptyMsg="No cells selected"
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(PackCellsReadonly);
