import _ from "lodash";
import {
  getRequestStatusGroup,
  getStatusTitle,
  getBatteryStatusGroup
} from "helpers/RequestHelper";
// import { getBatteryStatusGroup } from "helpers/BatteryCertHelper";
import {
  getTestStatusGroup,
  getTestStatusGroupOta
} from "helpers/RequestTestsHelper";
import * as ls from "libs/localStorage";

export const certProgramms = {
  none: 0,
  cyber: 1,
  battery: 2,
  ota: 3,
  dhr: 4,
  blc: 5
};

/**
 * Check localStorage on app initialization.
 * If value found set is as a default cert program.
 *
 * @returns number certProgram id
 */
function setCertProgramId() {
  const programId = localStorage.getItem("certProgram");
  if (programId) return Number(programId);

  // set up as a default
  localStorage.setItem("certProgram", certProgramms.none);
  return certProgramms.none;
}

/**
 * Check localStorage on app initialization.
 * If value found set is as a default cert program.
 *
 * @returns number certProgram id
 */
function setCertProgramPrefix() {
  const programPrefix = localStorage.getItem("prefixProgram");
  if (programPrefix) return programPrefix;

  // set up as a default
  localStorage.setItem("prefixProgram", "");
  return "";
}

const certProgram = setCertProgramId();
const programPrefix = setCertProgramPrefix();

export const appInitState = {
  certTypes: ls.getValue("certTypes"),
  certMinDate: "2019-01-01",
  certMinDateBLC: "2017-01-01",
  certMinDateLegacy: "2000-01-01",
  compactView: true,
  certProgram,
  programPrefix,
  isNoneCert: isNoCert(certProgram),
  isCyberCert: isCyberCert(certProgram),
  isBatteryCert: isBatteryCert(certProgram),
  isBLCCert: isBLCCert(certProgram),
  isOTACert: isOTACert(certProgram),
  isDHRCert: isDHRCert(certProgram),
  pageSize: ls.getValue("defaultPageSize") || 100,
  cyberRequestStatus: {
    id: 1,
    title: getStatusTitle(1),
    filter: getRequestStatusGroup()
  },
  cyberRequestTestStatus: {
    title: "Pending",
    filter: getTestStatusGroup()
  },
  otaRequestStatus: {
    id: 1,
    title: getStatusTitle(1),
    filter: getRequestStatusGroup()
  },
  otaRequestTestStatus: {
    title: "All",
    filter: getTestStatusGroupOta()
  },
  batteryRequestStatus: {
    id: 1,
    title: getStatusTitle(1),
    filter: getBatteryStatusGroup()
  },
  batteryRequestTestStatus: {
    title: "Pending",
    filter: getTestStatusGroup()
  },
  dhrRequestStatus: {
    id: 1,
    title: getStatusTitle(1),
    filter: getRequestStatusGroup()
  },
  dhrRequestTestStatus: {
    title: "Pending",
    filter: getTestStatusGroup()
  },
  cyberAdminUsers: {
    title: "All",
    userType: 0
  },
  requestView: {
    activeTab: null
  },
  requestListView: {
    activeTab: null
  },
  hasProgramSelector: true,
  isSubmitting: false,
  isSaving: false,
  showOnlyActiveUsers: false,
  ...restoreCertTypeIds()
};

export const modeTypes = {
  compact: 1,
  detailed: 2
};

export const certProgramData = {
  0: { title: "", urlPrefix: "" },
  1: { title: "IoT Cybersecurity Certification Program", urlPrefix: "cyber" },
  2: {
    title: "Battery Compliance Certification Program",
    urlPrefix: "battery"
  },
  3: { title: "OTA Test Plan Use Request", urlPrefix: "ota" },
  4: {
    title: "Device Hardware Reliability Certification Program",
    urlPrefix: "dhr"
  },
  5: {
    title: "Battery Life Certification Program",
    urlPrefix: "blc"
  }
};

/**
 * Defines certification program prefix by passed id.
 *
 * Returns empty string if id not included to program list.
 *
 * @param {number} id Certification program identificator
 * @returns string Certification program prefix as a string
 */
export const getProgramPrefix = id => {
  const numId = Number(id);
  if (numId > 0 && _.includes(certProgramms, numId)) {
    return certProgramData[id].urlPrefix;
  }

  return "";
};

export const getProgramTitle = id => {
  const numId = Number(id);
  if (numId > 0 && _.includes(certProgramms, numId)) {
    return certProgramData[id].title;
  }

  return "";
};

export function isNoCert(programId) {
  return certProgramms.none === programId;
}

export function isCyberCert(programId) {
  return certProgramms.cyber === programId;
}

export function isBatteryCert(programId) {
  return certProgramms.battery === programId;
}

export function isBLCCert(programId) {
  return certProgramms.blc === programId;
}

export function isOTACert(programId) {
  return certProgramms.ota === programId;
}

export function isDHRCert(programId) {
  return certProgramms.dhr === programId;
}

export function defineCertProgram(appState) {
  return appState.certProgram || certProgramms.cyber;
}

/**
 * Allows to find a cert type object among app.certTypes values
 * 
 * E.g. [{ id: 27, name: "OTA", title: "OTA Certification"}] - certTypes typical structure (shorten version for a reference)
 
 * To find this object using helper method we can use this helper as following:
 *
 * `AppHelper.getCertDataByFieldValue(certTypes, "name", "OTA")` // => returns an OTA object
 * 
 * @param {Array} `app.certTypes` array of objects pulled from API
 * @param {String} fieldName Which field we should use as a search base
 * @param {any} fieldValue Which value we're looking for
 *
 * @returns Object || null Returns `null` if nothing found
 */
export function getCertDataByFieldValue(certTypes, fieldName, fieldValue) {
  if (!certTypes || !certTypes.length) return null;

  const certData = _.find(certTypes, [fieldName, fieldValue]);

  return certData || null;
}

/** Shorthand object to reach any of cert types
 * Return an object where keys are cert type names, like "CRR" etc.
 * So, to get any data related to CRR, just need to pass name like:
 *
 * const myTypes = getNamedCertTypes(certTypes);
 * console.log(myTypes["CRR"]); // => { id: 92, title: "Blah blah" }
 */
export function getNamedCertTypes(certTypes) {
  if (!certTypes || !certTypes.length) return null;

  return _.keyBy(certTypes, "name");
}

export function restoreCertTypeIds() {
  return {
    idACR: ls.getValue("idACR") || null,
    idARR: ls.getValue("idARR") || null,
    idBLC: ls.getValue("idBLC") || null,
    idBSC: ls.getValue("idBSC") || null,
    idBSR: ls.getValue("idBSR") || null,
    idCBRS: ls.getValue("idCBRS") || null,
    idCRR: ls.getValue("idCRR") || null,
    idCYBER: ls.getValue("idCYBER") || null,
    idEPRR: ls.getValue("idEPRR") || null,
    idHRR: ls.getValue("idHRR") || null,
    idOTA: ls.getValue("idOTA") || null,
    idPRR: ls.getValue("idPRR") || null,
    idSAR: ls.getValue("idSAR") || null
  };
}

// Allows the app to scroll the whole document up to the passed element ID in DOM
export function scrollTo(elemId) {
  if (elemId) {
    const elem = document.getElementById(elemId);

    if (elem) {
      elem.scrollIntoView();
    }
  }
}

// @HACK: replace default body background color
export function setBodyCustomClass(name = "") {
  /*
    Initially, the line below was setting the body's class list to empty, which would cause modals 
    to remove the scrollbar if the window was resized because the of the selector body.modal-open
    
    document.body.className = name;
  */

  if(name === "gray")
    document.body.classList.add(name);
}
