import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { InfoBar } from "ctia-ui";
import {
  getStatusTitle,
  getRequestSupplier,
  isExisted
} from "helpers/RequestHelper";
import { getRequestTypeWithIeeeTitle } from "helpers/AuditHelper";

const InfoBarBSC = ({ request, user }) => {
  const [
    { status, vendor, modelName, reqType, hwVersion, swVersion },
    setBarValues
  ] = useState({
    status: isExisted(request) ? getStatusTitle(request.status) : null,
    vendor: getRequestSupplier(request, user),
    modelName: request.modelName,
    reqType: getRequestTypeWithIeeeTitle(request),
    hwVersion: user.isStaff ? request.hwVersion : null,
    swVersion: user.isStaff ? request.swVersion : null
  });

  useEffect(() => {
    const _status = isExisted(request) ? getStatusTitle(request.status) : null;
    const _vendor = getRequestSupplier(request, user);
    const _modelName = request.modelName || request.existedModel;
    const _reqType = getRequestTypeWithIeeeTitle(request);
    const _hwVersion = user.isStaff ? request.hwVersion : null;
    const _swVersion = user.isStaff ? request.swVersion : null;

    setBarValues({
      status: _status,
      vendor: _vendor,
      modelName: _modelName,
      reqType: _reqType,
      hwVersion: _hwVersion,
      swVersion: _swVersion
    });
  }, [request, user]);

  const getValues = () => {
    const reqDataInfo = [
      {
        key: "Status",
        value: status
      },
      {
        key: "Request Type",
        value: reqType
      },
      {
        key: "Vendor",
        value: vendor
      },
      {
        key: "Model Name / Number",
        value: modelName
      },
      {
        key: "HW Version",
        value: hwVersion
      },
      {
        key: "SW Version",
        value: swVersion
      }
    ];

    // remove fields with no values:
    return _.filter(reqDataInfo, field => !_.isEmpty(field.value));
  };

  return <InfoBar show={true} data={getValues()} />;
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(InfoBarBSC);
