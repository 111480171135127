import React from 'react';
import {Bubble, Divider} from 'ctia-ui';
import ReturnLink from "../../components/ReturnHome";

export default () => (
  <div className="block-top-offset text-center">
    <Bubble type="success">
      A new temporary password has been emailed to you. Return home to try to login again.
    </Bubble>
    <Divider/>
    <ReturnLink/>
  </div>
);
