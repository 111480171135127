import _ from "lodash";
import { restClientGeneral } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import { getRequestCertType } from "helpers/BatteryCertHelper";
import * as call from "helpers/ActionHelper";
import {
  prepareBillingArgs,
  prepareBillingPocArgs,
  prepareBillingAddressArgs
} from "helpers/ArgsHelper";
import * as actions from "actions/types";
import { updateRequest } from "actions/request";

export const fetchBillingAddresses = (companyId = null) => async dispatch => {
  try {
    // companyid uses in case if someone want to fetch data for different company
    // (e.g. Staff viewing Vendor's request)
    const urlTail = companyId ? `&where=companyid=${companyId}` : "";
    const url = `/addresses?perPage=500${urlTail}`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_BILLING_ADDRESSES,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantFetchBillingAddresses));

    return Promise.reject(e);
  }
};

export const fetchRequestBillingInfo = billingId => async dispatch => {
  try {
    const url = `/billings/${billingId}`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_REQUEST_BILLING_INFO,
      payload: response.data.data
    });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorFetchRequestAction
    });
  }
};

export const updateBillingInfo = request => async dispatch => {
  try {
    const url = `/billings/${request.billingId}`;
    const body = prepareBillingArgs(request);
    const response = await restClientGeneral.put(url, body);

    dispatch({
      type: actions.UPDATE_BILLING_INFO,
      payload: response.data.data
    });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantUpdateRequest
    });
  }
};

export const createBillingPoc = (request, user) => async dispatch => {
  const billingPocId = Number(request.billingPocId);

  // create brand new poc entry
  if (billingPocId === 0 || request.billingPocAdd) {
    try {
      const reqBody = prepareBillingPocArgs(request, user);
      const response = await restClientGeneral.post(
        "pocs",
        JSON.stringify(reqBody)
      );
      const payload = response.data.data.id;

      dispatch({
        type: actions.CREATE_NEW_BILLING_POC,
        payload
      });

      return { note: "Create", billingPocId: payload };
    } catch (e) {
      dispatch(call.showNotice(notifyMessages.cantCreatePoc));

      return Promise.reject(e);
    }
  } else {
    // skip this step if billing poc id provided
    return Promise.resolve({ note: "Skip", billingPocId });
  }
};

export const createBillingAddress = (request, user) => async dispatch => {
  const billingAddressId = request.billingAddressId
    ? Number(request.billingAddressId)
    : 0;

  // create brand new billing address entry
  if (billingAddressId === 0 || request.billingAddressAdd) {
    try {
      const reqBody = prepareBillingAddressArgs(request, user);
      const response = await restClientGeneral.post(
        "addresses",
        JSON.stringify(reqBody)
      );
      const payload = response.data.data.id;

      dispatch({
        type: actions.CREATE_NEW_BILLING_ADDRESS,
        payload
      });

      return { action: "Create", billingAddressId: payload };
    } catch (e) {
      dispatch(call.showNotice(notifyMessages.cantCreateBillingAddress));

      return Promise.reject(e);
    }
  } else {
    // skip this step if billing address id provided
    return Promise.resolve({ action: "Skip", billingAddressId });
  }
};

export const createBillingEntry = billing => async dispatch => {
  const { id, pocId, addressId } = billing;
  // NOTE: skip creation logic in case of billingId is existed
  if (!_.isInteger(id)) {
    try {
      const billingArgs = prepareBillingArgs({
        billingAddressId: addressId,
        billingPocId: pocId
      });

      const billingResponse = await restClientGeneral.post(
        "/billings",
        JSON.stringify(billingArgs)
      );

      return { action: "Create", billingId: billingResponse.data.data.id };
    } catch (e) {
      dispatch(call.showNotice(notifyMessages.cantCreateBillingEntry));

      return Promise.reject(e);
    }
  } else {
    // skip this step if billing address id provided
    return Promise.resolve({ action: "Skip", billingId: id });
  }
};

// logic to create new billing entry for a request:
// 1) creates new POC (if not provided by request)
// 2) creates new billing address (if not provided by request)
// 3) creates new `billingid` by passing `billingPocId` as well as `billingAddressId`
export function createNewBillingEntry(request, user) {
  return dispatch => {
    // Init some variables
    const billing = {
      id: request.billingId,
      pocId: Number(request.billingPocId),
      addressId: Number(request.billingAddressId)
    };

    // STEP 1 (Optional): Add new POC
    return (
      dispatch(createBillingPoc(request, user))
        // STEP 2 (Optional): Add new billing address
        .then(res => {
          billing.pocId = res.billingPocId;

          return dispatch(createBillingAddress(request, user));
        })
        // STEP 3: Create new billing entry
        .then(res => {
          billing.addressId = res.billingAddressId;

          return dispatch(createBillingEntry(billing));
        })
        .then(res => {
          // set created id to local var first
          billing.id = res.billingId;

          // STEP 4: Update request (redux store billing fields)
          const payload = {
            addressid: billing.addressId,
            pocid: billing.pocId,
            id: billing.id
          };

          // update redux
          dispatch({ type: actions.UPDATE_BILLING_INFO, payload });

          // Return all collected billing values
          return Promise.resolve({ ...billing });
        })
        .catch(err => Promise.reject(err))
    );
  };
}

export const updateBillingData = ({
  id,
  addressId,
  pocId
}) => async dispatch => {
  try {
    const url = `/billings/${id}`;
    const body = {
      args: {
        addressid: addressId,
        pocid: pocId
      }
    };
    const response = await restClientGeneral.put(url, body);

    dispatch({
      type: actions.UPDATE_BILLING_INFO,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function submitBillingCyber(request, user) {
  return dispatch => {
    dispatch(call.startPreloader());
    return dispatch(createNewBillingEntry(request, user)).then(() => {
      dispatch({ type: actions.SUBMIT_WIZARD_STEP });
      dispatch(call.stopPreloader());
    });
  };
}

export const updateBillingEntry = (request, user) => async dispatch => {
  // Init some variables
  const billing = {
    id: request.billingId,
    pocId: Number(request.billingPocId),
    addressId: Number(request.billingAddressId),
    orderNum: request.billingOrderNum,
    reqId: request.id
  };

  const urlPrefix = getRequestCertType(request);

  // STEP 1: Set loading status for full-page preloader
  dispatch(call.startPreloader());

  // STEP 2: Update purchase order
  const args = {
    id: billing.reqId,
    purchaseorder: billing.orderNum,
    urlPrefix
  };

  dispatch(updateRequest(args))
    // STEP 3 (Optional): Add new POC
    .then(() => dispatch(createBillingPoc(request, user)))
    .then(res => {
      // STEP 4 (Optional): Add new billing address
      billing.pocId = res.billingPocId;

      return dispatch(createBillingAddress(request, user));
    })
    // STEP 5: Update request billing at server side
    .then(res => {
      billing.addressId = res.billingAddressId;

      return dispatch(updateBillingData(billing));
    })
    .then(() => {
      // STEP 6: Disable preloader and show notification
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
    });
};
