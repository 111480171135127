import { toTitleCase } from "helpers/StringHelper";

export const formatATLStatus = status => {
  if (!status) return "";
  if (status.toUpperCase() === "N/A") return "N/A";

  return toTitleCase(status);
};

export const mapMetaATL = meta => {
  return [
    {
      icon: "flask",
      label: "Authorized Test Labs",
      value: meta.TOTALACTIVEATLLABS
    },
    {
      icon: "signature",
      label: "Signed Agreements",
      value: meta.TOTALAGREEMENTSSIGNED,
      total: meta.TOTALACTIVEATLLABS
    },
    {
      icon: "dollar-sign",
      label: "Paid Invoices",
      value: meta.TOTALINVOICESPAID,
      total: meta.TOTALINVOICES
    },
    {
      icon: "user-check",
      label: "Membership Fee Paid",
      value: meta.TOTALMEMBERSHIPFEESPAID,
      total: meta.TOTALMEMBERSHIPFEES
    },
    {
      icon: "user-tag",
      label: "ATL Fee Paid",
      value: meta.TOTALATLFEESPAID,
      total: meta.TOTALATLFEES
    }
  ];
};

export const mapYearBasedProps = (year, props) => {
  return {
    agreementSigned: props[`ATLAnualLicenseAgreementSigned${year}`],
    invoicePaid: props[`ATLAnualLicenseFeePaid${year}`],
    invoicePaidDate: props[`ATLAnualLicenseFeePaidAt${year}`],
    invoiceNumber: props[`ATLAnualLicenseFeeNum${year}`],
    membershipFeePaid: props[`hasATLMembershipCost${year}`],
    atlFeePaid: props[`hasATLFeeCost${year}`],
    invoiceId: props[`ATLAnualLicenseFeeKey${year}`]
  };
};
