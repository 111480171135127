import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import RequestResultPage from "components/request/RequestResultPage";
import PageTitle from "components/RequestPageTitle/index";
import LabDecision from "containers/request/Initial/LabDecision";
import RequestInfoBar from "containers/request/InfoBar/battery/InfoBarCRR";
import ContentBatteryPRR from "containers/request/RequestInfo/ContentBatteryPRR";
import BatteryLabDecision from "containers/request/battery/TestResults/BatteryLabDecision";
import GoBackButton from "components/form/buttons/GoBackButton";
import { canBeAcceptedForTesting } from "helpers/RequestTestsHelper";
import {
  clearRequest,
  makeLabDecisionGeneral,
  setBatteryRequestPermissions
} from "actions/request";
import { clearTests } from "actions/tests";
import { getDetailsPRR } from "actions/battery/prrRequest";

class RequestInfoBatteryPRR extends Component {
  state = {
    labDecision: {
      selected: false,
      accepted: false
    },
    focusLab: false
  };

  fetchAll = id => {
    const { match, getDetailsPRR } = this.props;
    const reqId = id ? id : match.params.id;

    return getDetailsPRR(reqId).then(() => {
      this.props.setBatteryRequestPermissions(
        this.props.request,
        this.props.user
      );
    });
  };

  componentDidMount() {
    const { match } = this.props
    if(!this.state.focusLab)
      this.setState({focusLab: match.params.focusLab});
    return this.fetchAll();
  }

  componentDidUpdate(prevProps) {
    const { match, preloader, request } = this.props;

    // don't touch anything in case of active loading state
    if (preloader) return;

    // get new request id
    const newId = match.params.id;

    // if id is different with current, than fetch new request data
    if (newId !== prevProps.match.params.id) {
      return this.fetchAll(newId);
    }

    // need update request data due to server changes
    if (
      prevProps.request &&
      !prevProps.request.needUpdate &&
      request &&
      request.needUpdate
    ) {
      return this.fetchAll(newId);
    }

    if (
      request &&
      prevProps.request &&
      request.isTogglerEnabled !== prevProps.request.isTogglerEnabled &&
      request.isTogglerEnabled === false
    ) {
      this.fetchAll(match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
    this.props.clearTests();
  }

  render() {
    const { request, user, history, tests } = this.props;

    if (request === null || tests === null || !request.isPRR) {
      return null;
    }

    const { id, isInitial, isEco, formSaved } = request;

    const renderCommonBlocks = () => {
      const btnLabel = user.isAccountant
        ? "Return to Payment"
        : "Return to Certified Device";
      const showBackBtn =
        (user.isAccountant &&
          _.includes(user.location, "accountant/payments")) ||
        _.includes(user.location, "devices");

      return (
        <div>
          <PageTitle />
          <GoBackButton label={btnLabel} show={showBackBtn} />
          <RequestInfoBar />
        </div>
      );
    };

    if (formSaved) {
      return (
        <div>
          <RequestResultPage
            isUpdate={true}
            isInitial={isInitial}
            isEco={isEco}
            history={history}
          />
        </div>
      );
    }

    if (this.state.labDecision.selected) {
      return (
        <div>
          {renderCommonBlocks()}
          <BatteryLabDecision
            id={id}
            isAccepted={this.state.labDecision.accepted}
            cert="prr"
          />
        </div>
      );
    }

    const testStatus = tests.length ? request.testStatus : null;

    return (
      <div>
        {renderCommonBlocks()}
        <LabDecision
          testStatus={testStatus}
          show={canBeAcceptedForTesting(testStatus) && user.isLab}
          labDecisionHandler={this.handleLabDecision}
        />
        <ContentBatteryPRR role={user.role} focusLab={this.state.focusLab} />
      </div>
    );
  }

  handleLabDecision = value => {
    this.props.makeLabDecisionGeneral(value, this.props.request).then(res => {
      if (res.error) return (window.location = "/");

      this.setState({
        labDecision: {
          selected: true,
          accepted: value
        }
      });
    });
  };
}

function mapStateToProps({ request, user, tests, sites, preloader }) {
  return { request, user, tests, sites, preloader };
}

export default connect(mapStateToProps, {
  clearRequest,
  makeLabDecisionGeneral,
  clearTests,
  getDetailsPRR,
  setBatteryRequestPermissions
})(RequestInfoBatteryPRR);
