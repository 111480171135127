import React from "react";
import { Form } from "react-bootstrap";
import FormElement from "components/FormElement";
import { addressFields, mapAddressFields } from "helpers/ManufSiteHelper";
import AuditLinks from "containers/request/battery/TestResults/AuditLinks";

const SiteAddressDetailsReadonly = ({
  siteAddress,
  vendor,
  showAuditLinks = true
}) => {
  if (!siteAddress) return null;

  const mappedFields = mapAddressFields(siteAddress);
  const addressKeys = Object.keys(mappedFields);

  const renderSiteName = name => {
    if (!name) return null;
    if (!vendor)
      return (
        <FormElement
          id="manufSiteName"
          key="manufSiteName"
          value={name}
          label="Manufacturing Site"
        />
      );

    return (
      <>
        <FormElement id="suppTitle" label="Supplier" value={vendor} />
        <FormElement
          id="manufSiteName"
          key="manufSiteName"
          value={name}
          label="Manufacturing Site"
        />
      </>
    );
  };

  return (
    <Form horizontal>
      {renderSiteName(siteAddress.name)}
      {addressKeys.map(field => {
        return (
          <FormElement
            id={field}
            key={field}
            value={mappedFields[field]}
            label={addressFields[field]}
          />
        );
      })}
      {showAuditLinks && <AuditLinks />}
    </Form>
  );
};

export default SiteAddressDetailsReadonly;
