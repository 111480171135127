import _ from "lodash";
import * as actions from "actions/types";

const setLimits = (action, state) => {
  // need to clean up server payload
  // to keep just program codes
  const budgetLimits = _.omit(
    action.payload,
    "id",
    "createdat",
    "deletedat",
    "updatedat"
  );

  const stateSettings = { ...state.settings };

  return { ...state, budgetLimits, settings: { ...stateSettings } };
};

const switchView = (action, state) => {
  const { certType, viewType } = action.payload;

  return { ...state, activeView: { certType, viewType } };
};

const setLimit = (action, state) => {
  const { id, value } = action.payload;
  // make a copy of state.budgetLimits object:
  const budgetLimits = { ...state.budgetLimits };

  // working with it you won't mutate your current state:
  budgetLimits[id] = value;

  return { ...state, budgetLimits };
};

const setBudgetSetting = (action, state) => {
  const { id, value } = action.payload;
  const stateSettings = { ...state.settings };
  stateSettings[id] = value;

  return { ...state, settings: { ...stateSettings } };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_BUDGET_LIMITS:
      return setLimits(action, state);
    case actions.SWITCH_BUDGET_DETAILS_VIEW:
      return switchView(action, state);
    case actions.SET_BUDGET_SETTING:
      return setBudgetSetting(action, state);
    case actions.SET_BUDGET_LIMIT:
      return setLimit(action, state);
    default:
      return state;
  }
}
