import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IconLabel, Drawer } from "ctia-ui";
import { Nav, Navbar } from "ctia-react-bootstrap-v4";
import { getLabsToSwitch } from "actions/labs";
import LabsToSwitch from "components/BrandNav/LabsToSwitch";

const SwitchToUser = ({ catl, user, getLabsToSwitch }) => {
  const [fetched, setFetched] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (user && user.isLab && !fetched) {
      setFetched(true);
      getLabsToSwitch();
    }
  }, [user, fetched, getLabsToSwitch]);

  if (!user || !user.isLab) return null;
  if (!catl || !catl.labsToSwitch || !catl.labsToSwitch.length) return null;

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <Navbar.Text>
      <Nav.Link onClick={toggleDrawer}>
        <IconLabel awesomeIcon="sign-in-alt" label="Log In As" />
      </Nav.Link>
      <Drawer
        position="right"
        show={showDrawer}
        title="Log In As"
        toggleDrawer={toggleDrawer}
        content={<LabsToSwitch toggleDrawer={toggleDrawer} />}
        width="auto"
      />
    </Navbar.Text>
  );
};

function mapStateToProps({ catl, user }) {
  return { catl, user };
}

export default connect(mapStateToProps, { getLabsToSwitch })(SwitchToUser);
