import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Preloader, ToTop } from "ctia-ui";
import Notifications from "react-notify-toast";
// import Routes from "app/routes/index";
import Routes from "app/Routes";
// import MainNav from 'containers/MainNav';
import Footer from "containers/Footer/footerv2";
import Maintenance from "containers/Maintenance";
import BuildVersion from "containers/BuildVersion";
import Content from "containers/Content";
import Notifier from "components/Notifier";
import AppWindow from "components/AppWindow";
import TermsOfUse from "containers/TermsOfUse/index";
import TempPass from "containers/TempPass";
import BrandNav from "components/BrandNav/index";
import Crumbs from "components/Crumbs";
import EnvironmentRibbon from "components/EnvironmentRibbon";
import { tokenTimeout } from "dictionaries/Env";
import { refreshToken, logout, getUserInfo } from "actions/user";
import { getSystemInfo } from "actions/system";
import { getCertTypes } from "actions/app";
import { hasTermsAccepted, awaitingTempPassChange } from "helpers/UsersHelper";
import { setBodyCustomClass } from "helpers/AppHelper";

import "styles/App.css";
import "styles/flex.css";
import "styles/Preloader.css";

class App extends Component {
  restore = null;

  componentDidMount() {
    const { user, getCertTypes, getSystemInfo } = this.props;

    this.appLogin();
    getSystemInfo();

    if (user.token) {
      getCertTypes();
    }
  }

  componentWillUnmount() {
    clearInterval(this.restore);
  }

  componentWillUpdate() {
    const token = localStorage.getItem("token");
    if (!token) {
      clearInterval(this.restore);
    }
  }

  componentDidUpdate() {
    // refresh token each 30 minutes:
    if (!_.isNull(this.props.user.token) && _.isNull(this.restore)) {
      this.restore = setInterval(this.updateUser, tokenTimeout);
    }
  }

  updateUser = () => {
    return this.props.refreshToken(this.props.getUserInfo);
  };

  appLogin() {
    const token = localStorage.getItem("token");
    const timestamp = Number(localStorage.getItem("timestamp"));
    const expiredTimestamp = timestamp + 1800; // = (30 min * 60 sec)
    const nowStamp = moment().unix();
    const tokenExpired = timestamp > 0 ? nowStamp > expiredTimestamp : false;

    if (!token && timestamp === 0) return null;

    if (token && tokenExpired) {
      // show notify message: session expired (30 min)
      this.props.logout({ force: true });
    }

    if (token && !tokenExpired) {
      const { history, location } = this.props;

      // to keep current opened page/view
      history.push(location.pathname);

      // need to refresh old stored token for the first time
      this.updateUser();
    }
  }

  render() {
    const { app, user, history, preloader, system, location } = this.props;

    // show maintenance page if server is off
    if (system && system.serverIsDown) {
      return <Maintenance />;
    }

    // block site access until user provides new password
    if (awaitingTempPassChange(user)) return <TempPass />;

    // reset any custom classes for document.body
    setBodyCustomClass();

    // collect all needed data to pass further
    const appSettings = {
      app,
      user
    };

    // add preloader class if preloader is active
    const curtain = preloader ? "curtain" : "";

    // show terms of use if new user is coming
    if (!hasTermsAccepted(user)) {
      // allow to show only Policy and Terms of Use views in this case:
      if (!_.includes(["/policy", "/terms"], location.pathname)) {
        return <TermsOfUse history={history} />;
      }
    }

    return (
      <div className="app-body">
        <Preloader show={preloader} />
        <AppWindow />
        <ToTop />
        <BuildVersion />
        <div className={`app-body ${curtain}`}>
          <Notifications />
          <Notifier />
          <header className="app-header">
            {/* <MainNav appSettings={appSettings} /> */}
			<EnvironmentRibbon />
            <BrandNav history={history} />
          </header>
          <Content>
            <Crumbs history={history} />
            <Routes childProps={{ user: appSettings.user }} />
          </Content>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ app, user, preloader, notifier, system }) {
  return { app, user, preloader, notifier, system };
}

export default withRouter(
  connect(mapStateToProps, {
    refreshToken,
    logout,
    getSystemInfo,
    getUserInfo,
    getCertTypes
  })(App)
);
