import React from "react";
import BatteryTestStatus from "containers/request/battery/BatteryLabInfo/BatteryTestStatus";
import LabTestInfoContainer from "containers/request/battery/BatteryLabInfo/LabTestInfoContainer";

const customTitles = {
  col1: "Test Type",
  col2: "Lab",
  col3: "Status",
  col1Row: "Battery Compliance"
};

const LabTestInfo = ({ editable }) => {
  return (
    <LabTestInfoContainer
      editable={editable}
      customTitles={customTitles}
      statusComponent={BatteryTestStatus}
    />
  );
};

export default LabTestInfo;
