import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";
import { LoaderButton, IconLabel, InlinePreloader } from "ctia-ui";
import { Modal, Button } from "react-bootstrap";
import { fetchCompanyUsers } from "actions/user";
import { getOrganization } from "actions/org";
import { updateCompanyVendor } from "actions/orgAdmin/vendor";
import FormFieldLabel from "components/form/FormFieldLabel";

import "containers/ControlPanel/Payments/payments.css";

const formId = "edit-vendor-form";

const EditVendorModal = ({
  id,
  modalHandler,
  getOrganization,
  fetchCompanyUsers,
  updateCompanyVendor,
  users
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [isSupplier, setSupplier] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (!data)
      getOrganization(id)
        .then(res => {
          if (res && res.data) {
            const data = res.data.data;
            setData(data);
            setSupplier(data.isSupplier === 1);

            // force validation since initially we set form as invalid
            // @NOTE: using setTimeout to allow the form renders first
            setTimeout(() => validateForm(), 500);
          } else {
            throw new Error("Server responded with an error");
          }
        })
        .then(() => fetchCompanyUsers(id));
  }, [id, data, getOrganization, fetchCompanyUsers]);

  // @TODO: set the flag value based on POC list length (`false` if list is empty)
  const hasContacts = users && users.list && users.list.length > 0;

  const renderSubmitButton = () => (
    <span className="icon-left-offset">
      <LoaderButton
        bsStyle="success"
        bsSize="small"
        text={<IconLabel label="Save" awesomeIcon="check" />}
        onClick={() => {
          setLoading(true);

          updateCompanyVendor(formId, id)
            .then(() => {
              setLoading(false);
              modalHandler();
            })
            .catch(err => {
              setLoading(false);
              modalHandler(err);
            });
        }}
        disabled={!validated}
        isLoading={loading}
      />
    </span>
  );

  const renderPocs = () => {
    if (hasContacts) {
      return (
        <Form.Control as="select" defaultValue={data.contactid}>
          {users.list.map(user => {
            return (
              <option
                key={user.ctiaid}
                value={user.ctiaid}
                data-name={user.fullname}
                data-email={user.email}
              >
                {user.fullname}
              </option>
            );
          })}
        </Form.Control>
      );
    }

    return (
      <span className="icon-left-offset form-warn">
        No contacts assigned to the company yet.
      </span>
    );
  };

  const renderDisableReason = () => {
    return (
      <Form.Group controlId="disableReason">
        <Form.Label>Reason for Disabling</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter the reason for disabling"
          defaultValue={data.comments}
          style={{ height: 75 }}
        />
      </Form.Group>
    );
  };

  const validateForm = () => {
    const form = document.getElementById(formId);

    if (form.checkValidity() === false) {
      return setValidated(false);
    }

    setValidated(true);
  };

  return (
    <Modal show={true} className="ctia-modal">
      <Modal.Header closeButton onHide={modalHandler}>
        <Modal.Title>Edit Vendor Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data ? (
          <Form
            noValidate
            validated={validated}
            id={formId}
            className="ctia-form"
            onChange={validateForm}
          >
            <Form.Group controlId="vendorName">
              <Form.Label>
                <FormFieldLabel label="Name" isRequired={true} />
              </Form.Label>
              <Form.Control
                required
                placeholder="Enter organization name"
                maxLength={100}
                defaultValue={data.name}
              />
              <Form.Control.Feedback type="invalid">
                Organization name is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contact">
              <Form.Label>Contact</Form.Label>
              {renderPocs()}
            </Form.Group>
            {renderDisableReason()}
            <Form.Check>
              <Form.Check.Input
                id="supplierCheckbox"
                type="checkbox"
                checked={isSupplier}
                onChange={() => setSupplier(!isSupplier)}
              />
              <Form.Check.Label for="supplierCheckbox">
                Battery/Cell/Adapter Supplier
              </Form.Check.Label>
            </Form.Check>
          </Form>
        ) : (
          <InlinePreloader />
        )}
      </Modal.Body>
      <Modal.Footer>
        {renderSubmitButton()}
        <Button onClick={modalHandler} className="agr-close-btn">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditVendorModal.propTypes = {
  id: PropTypes.number,
  modalHandler: PropTypes.func
};

function mapStateToProps({ users }) {
  return { users };
}

export default connect(mapStateToProps, {
  getOrganization,
  fetchCompanyUsers,
  updateCompanyVendor
})(EditVendorModal);
