import React from "react";
import RequestContentCRR from "containers/request/battery/RequestContent/RequestContentCRR";
import LabContentCRR from "containers/request/battery/RequestContent/RequestContentLabCRR";
import roles from "dictionaries/UserRoles";

const ContentBatteryCRR = ({ role, focusLab }) => {
  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.supplier:
    case roles.accountant:
    case roles.operator:
      return <RequestContentCRR focusLab={focusLab} />;
    case roles.lab:
      return <LabContentCRR />;
    default:
      return null;
  }
};

export default ContentBatteryCRR;
