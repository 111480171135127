import _ from "lodash";
import { restClient, restClientGeneral } from "libs/restClient";
import { requestStatuses } from "helpers/RequestHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { prepareOperatorsArgs } from "helpers/ArgsHelper";
import * as call from "helpers/ActionHelper";
import * as actions from "actions/types";
import { legacyPtcrbApiUrl } from "dictionaries/Env";

export const getModelVersions = (id, requestStatus) => async dispatch => {
  const dispatchFunction =
    requestStatus === requestStatuses.approved
      ? fetchModelCertifiedVersions(id)
      : fetchModelVersions(id);

  dispatch(dispatchFunction);
};

export const fetchModelVersionsForProgram = (
  program,
  id,
  requestStatusId
) => async dispatch => {
  if (!program) return null;

  const urlPrefix = program;

  const approvedRequestUrl =
    `/${urlPrefix}/requests` +
    `?where=devicemodelid=${id} AND requeststatusid=${requestStatuses.approved}` +
    `&include=devicemodel` +
    `&orderBy=transactionnumber DESC`;

  const pendingRequestUrl =
    `/${urlPrefix}/requests` +
    `?where=devicemodelid=${id} AND requeststatusid=${requestStatusId}` +
    `&orderBy=transactionnumber DESC`;

  const url =
    requestStatusId === requestStatuses.approved
      ? approvedRequestUrl
      : pendingRequestUrl;

  const response = await restClientGeneral
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  dispatch({
    type: actions.FETCH_MODEL_VERSIONS,
    payload: response
  });

  return response;
};

export function fetchModelVersions(id) {
  const url = `/requests?where=devicemodelid=${id} AND requeststatusid!=${requestStatuses.requestWithdrawn}&orderBy=transactionnumber DESC`;
  const payload = restClient
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.FETCH_MODEL_VERSIONS,
    payload
  };
}

export function fetchModelCertifiedVersions(id) {
  const url = `/requests?&orderBy=transactionnumber DESC&where=devicemodelid=${id} AND requeststatusid=${requestStatuses.approved}&include=devicemodel`;
  const payload = restClient
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.FETCH_MODEL_VERSIONS,
    payload
  };
}

export function fetchModel(id) {
  const url = `/devicemodels/${id}?include=devicemodelbands,vendor`;
  const payload = restClient
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.FETCH_MODEL,
    payload
  };
}

export function updateModelField(event) {
  const { id, name, value } = event.target;
  const field = id || name;

  return {
    type: actions.UPDATE_MODEL_FIELD,
    payload: { [field]: value }
  };
}

export function clearModel() {
  return {
    type: actions.CLEAR_MODEL,
    payload: null
  };
}

export function checkExistedModel(modelName) {
  const encodedModelName = encodeURIComponent(modelName);
  const url = `/devicemodels?include=requests&where=modelnumber='${encodedModelName}' AND requesttypeid=1 AND requeststatusid <> ${requestStatuses.requestWithdrawn}`;
  const payload = restClient
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.CHECK_EXISTED_MODEL,
    payload
  };
}

export const checkPTCRBModels = vendorId => async dispatch => {
  try {
    const response = await restClient.get(
      `${legacyPtcrbApiUrl}/devicemodels?page=0&availableonly=true&companyid=${vendorId}&where=certtypeid=2&select=id,modelnumber,statusid&orderBy=modelnumber ASC`
    );

    dispatch({ type: actions.CHECK_PTCRB_MODELS, payload: response.data.data });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantFetchPTCRBModels));

    return Promise.reject(e);
  }
};

export const updateModelOperators = request => async dispatch => {
  try {
    const { model, operators, isBSC } = request;
    const body = prepareOperatorsArgs(operators);

    let response;

    // add more programms if needed
    if (isBSC) {
      response = await restClientGeneral.put(
        `bsc/devicemodels/${model.id}`,
        body
      );
    } else {
      response = await restClient.put(`devicemodels/${model.id}`, body);
    }

    dispatch({
      type: actions.UPDATE_MODEL_OPERATORS,
      payload: response.data.data
    });
    dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));
  }
};

export const updateDeviceModel = deviceModel => async dispatch => {
  const body = {
    args: { ...deviceModel }
  };

  // get rid of `id` value inside args
  delete body.args.id;

  try {
    return await restClient.put(
      `/devicemodels/${deviceModel.id}`,
      JSON.stringify(body)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.modelnameAlreadyExists));

    return Promise.reject(e);
  }
};

export const fetchExistedOtaModels = vendorId => async dispatch => {
  try {
    const url = `/devicemodels?include=requests&where=requesttypeid IN (1,20) AND requeststatusid <> ${requestStatuses.requestWithdrawn} AND vendorid=${vendorId}&select=id,modelnumber&page=0&orderBy=modelnumber ASC&allcerts=true&returnAs=query&distinct=true`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.FETCH_OTA_EXISTED_MODELS,
      payload
    });

    return payload;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchModelInterfaces = modelId => async dispatch => {
  try {
    const url = `/requesttests?include=request&select=interfaceid&where=devicemodelid=${modelId}`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.FETCH_MODEL_INTERFACES,
      payload
    });

    return payload;
  } catch (e) {
    return Promise.reject(e);
  }
};

export function clearModelInterfaces() {
  return {
    type: actions.CLEAR_MODEL_INTERFACES
  };
}

export function updateOperators(request) {
  return dispatch => {
    const successMsg = notifyMessages.requestUpdatedSuccessfully;
    const failureMsg = notifyMessages.cantUpdateRequest;

    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(updateModelOperators(request))
      .then(() => {
        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

// Using in Battery cert types
// which are not using autocomplete feature for model selection
export const ifModelExists = (
  modelName,
  vendorId,
  initialsOnly = true
) => async dispatch => {
  dispatch({
    type: actions.START_PRELOADER
  });
  try {
    let isUnique = true;
    const encodedModelName = encodeURIComponent(modelName);
    const typeClause = initialsOnly ? "AND requesttypeid=1" : "";
    const url = `/devicemodels?include=requests&where=modelnumber='${encodedModelName}' ${typeClause} AND vendorid=${vendorId}&select=id,requeststatusid&returnAs=query`;
    const res = await restClientGeneral.get(url);
    const { data } = res.data;

    // check if all models are linked to non-withdrawn requests
    if (data && data.length) {
      const allWithdrawn = _.every(data, [
        "requeststatusid",
        requestStatuses.requestWithdrawn
      ]);

      isUnique = allWithdrawn;

      // re-use the devicemodelid for sumbission
      if (allWithdrawn) {
        dispatch({
          type: actions.CHANGE_REQUEST_FIELD,
          payload: { field: "existedModelId", value: data[0].id }
        });

        // let OTA submission knows we're re-using existing withdrawn model
        // so it can use all available test plans and not limit them to the pre-selected
        dispatch({
          type: actions.CHANGE_REQUEST_FIELD,
          payload: { field: "noTestPlansInUse", value: true }
        });
      }
    }

    dispatch({
      type: actions.STOP_PRELOADER
    });

    if (!isUnique) {
      dispatch(call.showNotice(notifyMessages.modelnameAlreadyExists));
    }

    return Promise.resolve(isUnique);
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    dispatch({
      type: actions.STOP_PRELOADER
    });

    return Promise.reject(e);
  }
};
