import _ from "lodash";
import * as actions from "actions/types";

const fetchInvoice = (action, state) => {
  const data = action.payload;
  const {
    totalamount,
    invoicenumber,
    checknumber,
    targetyear,
    id,
    createdat,
    ascinvoiceaddress,
    ascinvoicecosts,
    company,
    purchaseorder
  } = data;

  const invoiceData = {
    totalAmount: totalamount,
    invoiceNumber: invoicenumber,
    checkNumber: checknumber,
    targetYear: targetyear,
    invoiceId: id,
    invoiceDate: createdat,
    customerId: company.customerid,
    purchaseOrder: purchaseorder,
    ...ascinvoiceaddress,
    costs: ascinvoicecosts
  };

  return { ...invoiceData };
};

// const updateAtlInvoice = (action, state) => {
//   const { id, costs } = action.payload;
//   const ivc = _.find(state.labs, [
//     `ATLAnualLicenseFeeKey${state.invoicingYear}`,
//     id
//   ]);

//   ivc.invoiceCosts = ivc.invoiceCosts.map(ivcCost => {
//     const costToCopy = _.find(costs, ["costid", ivcCost.costid]);
//     return { ...ivcCost, ...costToCopy };
//   });

//   return { ...state };
// };

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_ASC_INVOICE:
      return fetchInvoice(action, state);
    case actions.CHANGE_ASC_INVOICE_FIELD:
      _.set(state, [action.payload.field], action.payload.value);
      return { ...state };
    case actions.CHANGE_ASC_COST_FIELD:
      const { key, field, value } = action.payload;
      _.set(state, `costs[${key}][${field}]`, value);
      return { ...state };
    case actions.CLEAR_INVOICE_ASC:
      return null;
    default:
      return state;
  }
}
