import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { addNewCompany } from "actions/companies";
import { clearCountryState } from "actions/countries";

import { Form } from "ctia-react-bootstrap-v4";
import { IconLabel, LoaderButton } from "ctia-ui";
import ErrorForm from "components/form/ErrorForm";
import FormSuccess from "components/form/FormSuccess";
import {
  formatFormForAPISubmission,
  validateLabForm,
  validateOperatorForm
} from "components/Companies/AddCompanyModal/components/FormHelper";

import ManufacturerForm from "components/Companies/AddCompanyModal/Forms/ManufacturerForm";
import OperatorForm from "components/Companies/AddCompanyModal/Forms/OperatorForm";
import LabForm from "components/Companies/AddCompanyModal/Forms/LabForm";

const formId = "add-company-form";

const AddCompanyModal = ({
  clearCountryState,
  companyType,
  shouldAutoClose = true,
  show,
  modalHandler = () => {},
  onSuccess = () => {},
  values,
  addNewCompany
}) => {
  const [errors, setErrors] = useState(null);
  const [formState, setFormState] = useState({
    loading: false,
    submitSuccess: null,
    validated: true
  });

  const cType = companyType.toLowerCase();

  const onSubmit = event => {
    event.preventDefault();

    setErrors(null);
    setFormState(state => {
      return { ...state, loading: true };
    });

    const form = document.getElementById(formId);
    const params = formatFormForAPISubmission({ companyType, form });

    addNewCompany({ params })
      .then(response => {
        setFormState(state => {
          return {
            ...state,
            loading: false,
            submitSuccess: true
          };
        });
        onSuccess(response);
        clearCountryState();

        if (shouldAutoClose) modalHandler();
      })
      .catch(errors => {
        // Case: Will return nothing if server code is 500
        let errorMessages;
        if (_.isArray(errors)) {
          errorMessages = _.isObject(errors[0])
            ? errors.map(error => error[0])
            : errors;
        } else {
          errorMessages = String(errors);
        }

        setErrors(errorMessages);
        setFormState(state => {
          return { ...state, loading: false };
        });
      });
  };

  // Reset form and component state on company type change and when
  // closing/reopening modal
  useEffect(() => {
    const formsStartingInvalid = ["lab", "laboratory", "operator"];
    const validated = !_.includes(formsStartingInvalid, cType);

    setFormState({
      loading: false,
      submitSuccess: null,
      validated
    });
    setErrors(null);
  }, [cType, show, values]);

  const getFormBody = () => {
    const props = {
      isSupplier: cType === "supplier",
      values
    };

    switch (cType) {
      case "lab":
      case "laboratory":
        return <LabForm {...props} />;
      case "manufacturer":
      case "supplier":
        return <ManufacturerForm {...props} />;
      case "operator":
        return <OperatorForm {...props} />;
      default:
        return null;
    }
  };

  const getFormTitle = () => {
    switch (cType) {
      case "lab":
      case "laboratory":
        return "Add Test Lab";
      case "manufacturer":
      case "supplier":
        return "Add Vendor/Supplier";
      case "operator":
        return "Add Operator";
      default:
        return "Add Company";
    }
  };

  const getModalSize = () => {
    const isLarge = cType === "lab" || cType === "laboratory";
    return isLarge ? "lg" : null;
  };

  const onFormChange = event => {
    const form = event.currentTarget;

    let validated = form.checkValidity();
    if (validated && (cType === "lab" || cType === "laboratory"))
      validated = validateLabForm(form);
    else if (validated && cType === "operator")
      validated = validateOperatorForm(form);

    setFormState(state => {
      return { ...state, validated };
    });
  };

  const successMessage = () => {
    const form = document.getElementById(formId);
    if (
      formState.submitSuccess !== true ||
      _.isUndefined(form) ||
      _.isNull(form) ||
      _.isUndefined(form.name) ||
      _.isNull(form.name)
    )
      return null;

    return `Successfully added '${form.name.value}'`;
  };

  return (
    <Modal show={show} bsSize={getModalSize()} className="ctia-modal">
      <Modal.Header closeButton onHide={modalHandler}>
        <Modal.Title>{getFormTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormSuccess message={successMessage()} />
        <ErrorForm errors={errors} />
        <Form
          noValidate
          validated={formState.validated}
          id={formId}
          className="ctia-form"
          onChange={onFormChange}
          onSubmit={onSubmit}
        >
          {getFormBody()}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <span className="icon-left-offset">
          <LoaderButton
            type="submit"
            bsStyle="success"
            bsSize="small"
            onClick={onSubmit}
            text={<IconLabel label="Save" awesomeIcon="check" />}
            disabled={!formState.validated}
            isLoading={formState.loading}
          />
        </span>
        <Button onClick={modalHandler} className="agr-close-btn">
          {formState.submitSuccess ? "Close" : "Cancel"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(null, {
  addNewCompany,
  clearCountryState
})(AddCompanyModal);
