import _ from "lodash";
import * as actions from "actions/types";
import { restClientAdmin, restClientGeneral } from "libs/restClient";
import * as call from "helpers/ActionHelper";
import { downloadFile } from "helpers/UploadsHelper";
import { getCurrATLYear } from "helpers/DateHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { formatValue, getInputValue } from "libs/form";
import { makeSimpleArgs } from "helpers/ArgsHelper";
import { prepareCompanyArgs } from "helpers/ArgsHelperASC";

export const signAgreementASC = (
  companyId,
  fullName,
  jobTitle
) => async dispatch => {
  try {
    // we can sign for the current year only
    const year = getCurrATLYear();

    const url = `/companies/${companyId}/agreements/asc?year=${year}&name=${fullName}&title=${jobTitle}`;
    const payload = await restClientGeneral.post(url);

    dispatch({
      type: actions.SIGN_ASC_AGREEMENT,
      payload
    });

    return payload;
  } catch (e) {
    const errMsg = call.parseApiException(e);

    dispatch(call.showNotice(errMsg));

    return Promise.reject(e);
  }
};

export function downloadAgreementASC(companyId, year = getCurrATLYear()) {
  const fileName = `${year}_ASC_License_Service_Agreement.pdf`;
  const url = `/companies/${companyId}/agreements/asc?year=${year}`;
  const payload = restClientGeneral
    .get(url, { responseType: "blob" })
    .then(res => {
      downloadFile(res.data, fileName);
      return res;
    })
    .catch(err => err);

  return {
    type: actions.DOWNLOAD_ASC_AGREEMENT,
    payload
  };
}

export function downloadInvoiceASC({ invoiceId, companyid, ascInvoiceNumber }) {
  const fileName = `${ascInvoiceNumber}.pdf`;
  let restClient, url;

  if (invoiceId) {
    url = `/ascinvoices/${invoiceId}?pdf=true`;
    restClient = restClientAdmin;
  } else {
    const year = getCurrATLYear();
    url = `/companies/${companyid}/invoices/asc?year=${year}`;
    restClient = restClientGeneral;
  }

  const payload = restClient
    .get(url, { responseType: "blob" })
    .then(res => {
      downloadFile(res.data, fileName);
      return res;
    })
    .catch(err => {
      const errMsg = call.parseApiException(err);

      call.showNotice(errMsg);

      return err;
    });

  return {
    type: actions.DOWNLOAD_ASC_INVOICE,
    payload
  };
}

export function setInvoicingYear(year) {
  return {
    type: actions.SET_INVOICING_YEAR,
    payload: year
  };
}

// Soft-deletes the invoice of a disabled org
export const deleteASCInvoice = org => async dispatch => {
  try {
    const year = getCurrATLYear();
    const url = "/admin/ascinvoices/bulk";

    // Retrieve list of ASC Invoices
    const ascurl = `/admin/companies/ascinvoicing?year=${year}&orderBy=name`;
    const payload = await restClientGeneral.get(ascurl);
    console.log(org)

    console.log(payload);
    // Find invoice we're deleting
    const invoiceToDelete = _.remove(payload.data.data, invoice => {
      return invoice.id === org.id;
    });

    let invoice = invoiceToDelete[0];

    // Get it into the expected format
    const id = [{id: invoice[`ASCAnualLicenseFeeKey${year}`]}];

    if(!invoice[`ASCAnualLicenseFeePaid${year}`]) {
      // DELETE
      await restClientGeneral.delete(url, {
        data: { args: id }
      });
    }

    return Promise.resolve("DONE");
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
}

export const fetchAscList = atlYear => async dispatch => {
  try {
    const year = atlYear || getCurrATLYear();
    const url = `/admin/companies/ascinvoicing?year=${year}&orderBy=name`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_ASC_LIST,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchAscInvoices = atlYear => async dispatch => {
  try {
    dispatch(toggleBackgroundJobStatus());

    const year = atlYear || getCurrATLYear();

    // map existing costs to labs
    const costsUrl = `/admin/ascinvoices?include=ascinvoicecosts&where=targetyear=${year}&page=0`;
    const costsPayload = await restClientGeneral.get(costsUrl);

    dispatch({
      type: actions.FETCH_ASC_INVOICES_COSTS,
      payload: costsPayload
    });

    dispatch(toggleBackgroundJobStatus());

    return costsPayload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchInvoiceASC = id => async dispatch => {
  try {
    const url = `/admin/ascinvoices/${id}?include=ascinvoicecosts,ascinvoiceaddress,company`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_ASC_INVOICE,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function handleIvcInputChange(event) {
  return {
    type: actions.CHANGE_ASC_INVOICE_FIELD,
    payload: getInputValue(event)
  };
}

export function handleIvcCostChange(index, name, event) {
  const { value } = event.target;

  return {
    type: actions.CHANGE_ASC_COST_FIELD,
    payload: {
      key: index,
      field: name,
      value: formatValue(name, value)
    }
  };
}

export function clearInvoiceASC() {
  return {
    type: actions.CLEAR_INVOICE_ASC
  };
}

export const updateInvoiceASC = (
  invoiceId,
  invoice,
  costs
) => async dispatch => {
  try {
    const costBody = costs.map(cost => {
      return {
        costid: cost.costid,
        amount: cost.amount,
        description: cost.description,
        item: cost.description
      };
    });
    const reqBody = makeSimpleArgs({
      id: invoiceId,
      createdat: invoice.invoiceDate,
      purchaseorder: invoice.purchaseOrder,
      ascinvoicecosts: costBody,
      ascinvoiceaddress: {
        id: invoice.id,
        address1: invoice.address1,
        address2: invoice.address2,
        city: invoice.city,
        state: invoice.state,
        zipcode: invoice.zipcode,
        countrycode: invoice.countrycode,
        name: invoice.name
      }
    });
    const url = `/admin/ascinvoices/${invoiceId}`;
    const payload = await restClientGeneral.put(url, reqBody);

    dispatch({
      type: actions.UPDATE_INVOICE_ASC,
      payload: { id: invoiceId, costs: costBody }
    });

    dispatch(call.showNotice(notifyMessages.invoiceUpdatedSuccessfully));

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateASC));

    return Promise.reject(e);
  }
};

export function toggleBackgroundJobStatus() {
  return {
    type: actions.TOGGLE_ASC_BACKGROUND_JOB_STATUS
  };
}

export const addCompanyASC = formId => async dispatch => {
  try {
    const form = document.getElementById(formId);
    const reqBody = prepareCompanyArgs(form);
    const response = await restClientAdmin.post(
      "companies",
      JSON.stringify(reqBody)
    );

    dispatch({
      type: actions.ADD_ASC_COMPANY,
      payload: response.data.data
    });

    dispatch(call.showNotice(notifyMessages.companyAddedSuccessfully));

    return response;
  } catch (error) {
    const { data } = error.response;

    if (data && data.errors) {
      throw data.errors[0];
    } else {
      throw data.exception;
    }
  }
};

export const updateCompanyASC = (formId, id) => async dispatch => {
  try {
    const form = document.getElementById(formId);
    const reqBody = prepareCompanyArgs(form);
    const response = await restClientAdmin.put(
      `companies/${id}`,
      JSON.stringify(reqBody)
    );

    dispatch({
      type: actions.UPDATE_ASC_COMPANY,
      payload: response.data.data
    });

    dispatch(call.showNotice(notifyMessages.companyUpdatedSuccessfully));

    return response;
  } catch (error) {
    const { data } = error.response;

    if (data && data.errors) {
      throw data.errors[0];
    } else {
      throw data.exception;
    }
  }
};

export const removePaymentASC = invoiceId => async dispatch => {
  try {
    const reqBody = makeSimpleArgs({
      paidat: "",
      paymentmethod: "",
      checknumber: "",
      paymentnote: ""
    });
    const url = `/admin/ascinvoices/${invoiceId}`;
    const payload = await restClientGeneral.put(url, reqBody);

    dispatch({
      type: actions.REMOVE_ASC_PAYMENT,
      payload: payload.data.data
    });

    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.paymentRemovedSuccessfully
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateATL));

    return Promise.reject(e);
  }
};

export const generateASC = (year) => async dispatch => {
  try{
    const url = `/admin/ascinvoices/genascinvoices?year=${year}`;

    const payload = await restClientGeneral.post(url);

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.ascBulkingFailed));
  }

}
