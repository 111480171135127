import React, { Component } from "react";
import { connect } from "react-redux";
import CompletionPanel from "components/RequestCompletionPanel";
import CtiaButton from "components/form/buttons/CtiaButton";

class RequestCompletion extends Component {
  onDoneClick = () => {
    const { app, history } = this.props;
    const redirectUrl = `${app.urlPrefix}requests`;

    // use SPA router for redirection
    if (history) {
      history.push(redirectUrl);
      return;
    }

    // if `history` props has missed for some reason
    window.location = redirectUrl;
  };

  shouldBeRendered = () => {
    const { wizard } = this.props;
    const { isSubmitted, isSaved } = wizard;
    return wizard && (isSubmitted || isSaved);
  };

  render() {
    if (!this.shouldBeRendered()) return null;

    return (
      <div>
        <CompletionPanel />
        <CtiaButton label="Done" onClick={this.onDoneClick} />
      </div>
    );
  }
}

function mapStateToProps({ app, wizard }) {
  return { app, wizard };
}

export default connect(mapStateToProps)(RequestCompletion);
