import { Form } from "ctia-react-bootstrap-v4";
import React from "react";
import progs from "dictionaries/OperatorCertification";

const OperatorCertificationSelectors = ({
  defaultCerts = () => {},
  onChange = () => {}
}) => {
  return progs.map((testingCapability, index) => {
    const { id, type, value } = testingCapability;

    return (
      <Form.Check
        inline
        id={`${type}${index}`}
        key={index}
        label={value}
        onChange={onChange}
        type="checkbox"
        name="operatorCertTypes"
        value={id}
        defaultChecked={defaultCerts(id)}
      />
    );
  });
};

export default OperatorCertificationSelectors;
