import _ from 'lodash';

const str2num = value => {
  if (value === '' || _.isBoolean(value)) {
    return value;
  }

  const convertedValue = _.toNumber(value);
  return _.isNaN(convertedValue) ? value : convertedValue;
};

export default str2num;
