import React from "react";
import "components/CyberCertificateButton/style.css";
import PropTypes from "prop-types";

const CyberCertificateButton = ({ to, show, request }) => {

  if(!show) return null;

  return (
    <a href={to} request={request} className="cyberButton"><span>Download<br/>Certification Certificate</span></a>
  );
};


CyberCertificateButton.propTypes = {
    show: PropTypes.bool,
    to: PropTypes.string
};

CyberCertificateButton.defaultProps = {
    show: false,
    to: ""
};

export default CyberCertificateButton;
