import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import RenderOptions from "containers/request/RequestList/RenderOptions";
import { otaOptions } from "containers/request/RequestList/statusOptions";
import ListTable from "containers/request/RequestList/RequestListTableOta";
import {
  Pager,
  PagerPageSize,
  PagerStatusFilter,
  Divider,
  CounterLabel
} from "ctia-ui";
import { Col, Row } from "react-bootstrap";
import { clearRequests } from "actions/requests";
import { fetchCertificationRequests } from "actions/requestsOta";
import { setPageSize, changeOtaRequestStatus } from "actions/app";
import CyberTable from "containers/CyberTable";
import RequestListTitle from "containers/request/RequestList/RequestListTitle";

class OtaRequests extends CyberTable {
  componentDidMount() {
    this.startPage = this.props.app.pageSize === 0 ? 0 : 1;
    this.fetchTableData();
  }

  componentWillUnmount() {
    this.props.clearRequests();
  }

  onStatusChange = event => {
    const newStatus = Number(event.target.value);

    // update app filter settings
    this.props.changeOtaRequestStatus(newStatus);

    // fetch new list (waiting for redux storage update first)
    setTimeout(this.fetchTableData, 150);
  };

  fetchTableData = () => {
    const settings = {
      page: this.startPage,
      size: this.props.app.pageSize,
      statusFilter: this.props.app.otaRequestStatus.filter
    };

    return this.props.fetchCertificationRequests(settings);
  };

  setPagerSize = size => this.props.setPageSize(size);

  render() {
    const { requests, app } = this.props;
    if (!requests) return null;
    const { otaRequestStatus } = app;
    const tableData = this.setTableData(requests);
    const { page, pages, records } = this.setPagerData(requests);
    const counter = this.setCounter(tableData, records);
    const sortedOtaOptions = _.sortBy(otaOptions, ["title", "id"]);

    const renderTable = () => {
      return (
        <div>
          <ListTable data={tableData} fetchHandler={this.fetchTableData} />
          <Divider />
          <Pager moveTo={this.fetchTableData} totalPages={pages} page={page} />
        </div>
      );
    };

    return (
      <div>
        <RequestListTitle
          listTitle="Requests"
          listLabel={otaRequestStatus.title}
        />
        <Row>
          <div id="request-filter">
            <Col xs={4} md={4}>
              <CounterLabel counter={counter} />
            </Col>
            <Col xs={8} md={4}>
              <PagerPageSize
                size={app.pageSize}
                onChangeHandler={this.onChangePageSize}
                options={[50, 100]}
                showAll={true}
              />
            </Col>
            <Col xs={12} md={4}>
              <PagerStatusFilter
                status={otaRequestStatus.id}
                statusOptions={<RenderOptions options={sortedOtaOptions} />}
                onStatusChange={this.onStatusChange}
              />
            </Col>
          </div>
        </Row>
        {renderTable()}
      </div>
    );
  }
}

function mapStateToProps({ requests, app }) {
  return { requests, app };
}

export default connect(mapStateToProps, {
  setPageSize,
  changeOtaRequestStatus,
  fetchCertificationRequests,
  clearRequests
})(OtaRequests);
