import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import NoTableData from "components/NoTableData";
import { getRequestTypeTitle } from "helpers/RequestHelper";
import { formatDate } from "helpers/DateHelper";
import Portal from "components/Portal";

const ModelVersionsCyber = ({ tableData, showLink, show, urlPrefix }) => {
  if (!show) return null;
  if (tableData.length) {
    const renderRows = () => {
      return tableData.map((row, i) => {
        const {
          hwversion,
          swversion,
          id,
          formatedtransactionnumber,
          requesttypeid,
          completedat,
          devicemodel
        } = row;

        const reqType = getRequestTypeTitle(requesttypeid);
        const certDate = formatDate(completedat);
        const certificationLevel = devicemodel ? `${devicemodel.certcategoryname}` : "";
        const linkToRequest = () => {
          if (!showLink) return formatedtransactionnumber;

          return (
            <Portal
              changeCertProgram={"cyber"}
              to={`${urlPrefix}requests/${id}`}
              tn={formatedtransactionnumber}
            />
          );
        };

        return (
          <tr key={i}>
            <td>{linkToRequest()}</td>
            <td>{certificationLevel}</td>
            <td>{hwversion}</td>
            <td>{swversion}</td>
            <td>{reqType}</td>
            <td>{certDate}</td>
          </tr>
        );
      });
    };

    return (
      <div>
        <h3 className="no-margin">Device History</h3>
        <Table responsive striped className="ctia-table">
          <thead>
            <tr>
              <th>Request ID</th>
              <th>Certification Level</th>
              <th>HW Version</th>
              <th>SW Version</th>
              <th>Request Type</th>
              <th>Date Certified</th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </Table>
      </div>
    );
  }

  return <NoTableData colSpan={6} />;
};

ModelVersionsCyber.propTypes = {
  tableData: PropTypes.array,
  showLink: PropTypes.bool
};

ModelVersionsCyber.defaultProps = {
  tableData: [],
  showLink: false
};

export default ModelVersionsCyber;
