import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoaderButton, ContinueLabel } from 'ctia-ui';
import {
  Col,
  Tooltip,
  Jumbotron,
  Glyphicon,
  OverlayTrigger
} from 'react-bootstrap';

import 'containers/request/RequestTypeBlock.css';

const RequestTypeBlock = ({ app, request }) => {
  const btnTitle = request.locked ? 'Coming soon' : <ContinueLabel />;

  return (
    <Col xs={6}>
      <Jumbotron className="jumbo-block text-center">
        <h2 className="jumbo-block-header">
          {request.title}{' '}
          {!request.locked && (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="typeHint">{request.hint}</Tooltip>}
            >
              <Glyphicon
                glyph="question-sign"
                className="jumbo-block-icon gray"
              />
            </OverlayTrigger>
          )}
        </h2>
        <Link to={`${app.urlPrefix}request/new/${request.link}`}>
          <LoaderButton
            bsSize="large"
            bsStyle="info"
            text={btnTitle}
            disabled={request.locked}
            className="btn-shadow"
          />
        </Link>
      </Jumbotron>
    </Col>
  );
};

function mapStateToProps({ app }) {
  return { app };
}

export default connect(mapStateToProps)(RequestTypeBlock);
