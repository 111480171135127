import _ from "lodash";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";
import { LoaderButton, IconLabel, InlinePreloader } from "ctia-ui";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { fetchCompanyUsers } from "actions/user";
import { getOrganization } from "actions/org";
import { updateCompanyOperator } from "actions/companies";
import FormFieldLabel from "components/form/FormFieldLabel";
import OperatorCertificationSelectors from "components/Companies/AddCompanyModal/components/OperatorCertificationSelectors";

import "containers/ControlPanel/Payments/payments.css";

const formId = "edit-operator-form";

const EditOperatorModal = ({
  id,
  modalHandler,
  getOrganization,
  fetchCompanyUsers,
  updateCompanyOperator
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (!data)
      getOrganization(id, "operator")
        .then(res => {
          if (res && res.data) {
            const data = res.data.data;
            setData(data);

            // force validation since initially we set form as invalid
            // @NOTE: using setTimeout to allow the form renders first
            setTimeout(() => validateForm(), 500);
          } else {
            throw new Error("Server responded with an error");
          }
        })
        .then(() => fetchCompanyUsers(id))
        .then(res => {
          if (res && res.data) {
            const sortedResponder = _.sortBy(res.data.data, [
              user => user.reversedfullname.toUpperCase()
            ]);
            setUsers(sortedResponder);
          }
        });
  }, [id, data, getOrganization, fetchCompanyUsers]);

  const onResponderChange = event => {
    const { checked, value } = event.target;

    setUsers(
      users.map(responder =>
        responder.ctiaid === parseInt(value)
          ? {
              ...responder,
              isWaiverResponder: checked ? 1 : 0
            }
          : responder
      )
    );
  };

  const renderMembershipLevel = () => {
    return (
      <Form.Group controlId="membership">
        <FormFieldLabel label="Membership Level" />
        <Form.Control as="select" defaultValue={data.membership}>
          <option value>--- Select One ---</option>
          <option value="Active">Active</option>
          <option value="Passive">Passive</option>
          <option value="Observer">Observer</option>
          <option value="Associate">Associate</option>
        </Form.Control>
      </Form.Group>
    );
  };
  const renderPocs = () => {
    if (users && users.length > 0) {
      const sortedContact = _.sortBy(users, [
        user => user.fullname.toUpperCase()
      ]);

      return (
        <Form.Control as="select" defaultValue={data.contactid}>
          <option value={0} data-name="" data-email="">
            None
          </option>
          {sortedContact.map(user => {
            return (
              <option
                key={user.ctiaid}
                value={user.ctiaid}
                data-name={user.fullname}
                data-email={user.email}
              >
                {user.fullname}
              </option>
            );
          })}
        </Form.Control>
      );
    }

    return (
      <span className="icon-left-offset form-warn">
        No contacts assigned to the company yet.
      </span>
    );
  };

  const renderDisableReason = () => {
    return (
      <Form.Group controlId="disableReason">
        <FormFieldLabel label="Reason for Disabling" />
        <Form.Control
          as="textarea"
          placeholder="Enter the reason for disabling"
          defaultValue={data.comments}
          style={{ height: 75 }}
        />
      </Form.Group>
    );
  };

  const renderWaiverResponders = () => {
    if (users && users.length > 0) {
      return users.map((user, index) => {
        return (
          <Form.Check
            id={`waiverResponders-${user.id}`}
            key={index}
            label={user.reversedfullname}
            onChange={onResponderChange}
            type="checkbox"
            name="waiverResponders"
            value={user.ctiaid}
            checked={user.isWaiverResponder === 1}
          />
        );
      });
    }
    return (
      <span className="icon-left-offset form-warn">
        No waiver responders assigned to the company yet.
      </span>
    );
  };

  const renderSubmitButton = () => (
    <span className="icon-left-offset">
      <LoaderButton
        bsStyle="success"
        bsSize="small"
        text={<IconLabel label="Save" awesomeIcon="check" />}
        onClick={() => {
          setLoading(true);

          updateCompanyOperator(formId, id)
            .then(() => {
              setLoading(false);
              modalHandler();
            })
            .catch(err => {
              setLoading(false);
              modalHandler(err);
            });
        }}
        disabled={!validated}
        isLoading={loading}
      />
    </span>
  );

  const validateForm = () => {
    const form = document.getElementById(formId);

    if (form.checkValidity() === false) {
      return setValidated(false);
    }

    setValidated(true);
  };

  return (
    <Modal show={true} bsSize="lg" className="ctia-modal">
      <Modal.Header closeButton onHide={modalHandler}>
        <Modal.Title>Edit Operator Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data ? (
          <Form
            noValidate
            validated={validated}
            id={formId}
            className="ctia-form"
            onChange={validateForm}
          >
            <Row>
              <Col md={6} xs={12}>
                <Form.Group controlId="operatorName">
                  <Form.Label>
                    <FormFieldLabel label="Name" isRequired={true} />
                  </Form.Label>
                  <Form.Control
                    required
                    placeholder="Enter organization name"
                    maxLength={100}
                    defaultValue={data.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    Organization name is required.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <FormFieldLabel
                      label="Certification Programs"
                      isRequired={true}
                    />
                  </Form.Label>
                  <OperatorCertificationSelectors
                    defaultCerts={certId =>
                      _.findIndex(data.companycerttypes, [
                        "certtypeid",
                        certId
                      ]) !== -1
                    }
                  />
                </Form.Group>
                <Form.Group controlId="contact">
                  <Form.Label>Contact</Form.Label>
                  {renderPocs()}
                </Form.Group>
                {renderMembershipLevel()}
                {renderDisableReason()}
              </Col>
              <Col md={6} xs={12}>
                <Form.Group>
                  <Form.Label>Waiver Responders</Form.Label>
                  {renderWaiverResponders()}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        ) : (
          <InlinePreloader />
        )}
      </Modal.Body>
      <Modal.Footer>
        {renderSubmitButton()}
        <Button onClick={modalHandler} className="agr-close-btn">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditOperatorModal.propTypes = {
  id: PropTypes.number,
  modalHandler: PropTypes.func
};

export default connect(null, {
  getOrganization,
  fetchCompanyUsers,
  updateCompanyOperator
})(EditOperatorModal);
