import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Card, Table } from "ctia-react-bootstrap-v4";
import SupplierRow from "containers/request/battery/PackCells/PackCellsListRow";

const PackCellsList = ({ request }) => {
  if (!request || !request.cellSuppliers || _.isEmpty(request.cellSuppliers))
    return null;

  return (
    <div className="block-bottom-offset">
      <Card border="light">
        <Card.Body>
          <h3>Selected Cells ({request.cellSuppliers.length})</h3>
          <Table responsive striped hover size="sm" className="ctia-table">
            <thead>
              <tr>
                <th>Cell Supplier</th>
                <th>Cell Model</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {request.cellSuppliers.map((supplier, i) => {
                return <SupplierRow key={i} supplier={supplier} />;
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(PackCellsList);
