import React from 'react';
import ApproveList from 'components/request/ApproveList';

const StaffApprove = () => {
  return (
    <div>
      <h1>Approve Request</h1>
      <ApproveList />
    </div>
  );
};

export default StaffApprove;
