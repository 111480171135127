import { restClient, pickRestClient } from "libs/restClient";
import { downloadFile } from "helpers/UploadsHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import * as call from "helpers/ActionHelper";
import * as actions from "actions/types";

export function uploadSupportDocument({
  fileContent,
  title,
  slot,
  requestId,
  sendEmail = true,
  clientType = "",
  certType = ""
}) {
  // create new form-data object to send file
  const bodyFormData = new FormData();
  bodyFormData.append("file", fileContent);

  // send request to backend and upload file
  const url = `${certType}/requests/${requestId}/documents/${slot}?title=${title}&sendEmail=${sendEmail}`;

  // make request
  const payload = pickRestClient(clientType)
    .post(url, bodyFormData)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.UPLOAD_SUPPORT_DOC,
    payload
  };
}

export function uploadUsersManual({ fileContent, title, slot, modelId }) {
  // create new form-data object to send file
  const bodyFormData = new FormData();
  bodyFormData.append("file", fileContent);

  // send request to backend and upload file
  const url = `/devicemodels/${modelId}/manual?title=manual`;

  // make request
  const payload = restClient
    .post(url, bodyFormData)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.UPLOAD_USERS_MANUAL,
    payload
  };
}

export function addToQueue({ title, queueId }) {
  return {
    type: actions.ADD_TO_QUEUE,
    payload: {
      [queueId]: { title, pending: true }
    }
  };
}

export function removeFromQueue({ queueId, ...rest }) {
  return {
    type: actions.REMOVE_FROM_QUEUE,
    payload: {
      [queueId]: { pending: false, ...rest }
    }
  };
}

export function clearQueue() {
  return {
    type: actions.CLEAR_QUEUE,
    payload: null
  };
}

export function deleteUsersManual(modelId) {
  const payload = restClient
    .delete(`/devicemodels/${modelId}/manual`)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.DELETE_USERS_MANUAL,
    payload
  };
}

export function deleteSupportDocument(requestId, slot) {
  const payload = restClient
    .delete(`/requests/${requestId}/documents/${slot}`)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.DELETE_SUPPORT_DOCUMENT,
    payload
  };
}

export function uploadTestReport({
  fileContent,
  title,
  slot,
  testId,
  clientType = "",
  certType = ""
}) {
  const bodyFormData = new FormData();
  bodyFormData.append("file", fileContent);
  bodyFormData.append("title", title);

  const url = `${certType}/requesttests/${testId}/reports/${slot}`;

  const payload = pickRestClient(clientType)
    .post(url, bodyFormData)
    .then(response => {
      return response;
    })
    .catch(err => {
      Promise.reject(err);
      return err;
    });

  return {
    type: actions.UPLOAD_TEST_REPORT,
    payload
  };
}

export function deleteTestReport(testId, slot, clientType = "", certType = "") {
  const url = `${certType}/requesttests/${testId}/reports/${slot}`;

  const payload = pickRestClient(clientType)
    .delete(url)
    .then(response => {
      Promise.resolve(true);
      return response;
    })
    .catch(err => {
      Promise.reject(notifyMessages.cantDeleteUploadedFile);
      return err;
    });

  return {
    type: actions.DELETE_TEST_REPORT,
    payload
  };
}

export function updateReportTitle(
  testId,
  slot,
  title,
  clientType = "",
  certType = ""
) {
  const body = { args: { title } };
  const url = `${certType}/requesttests/${testId}/reports/${slot}`;

  const payload = pickRestClient(clientType)
    .put(url, JSON.stringify(body))
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.UPDATE_DOCUMENT_TITLE,
    payload
  };
}

export function updateSupDocTitle(requestId, slot, title) {
  const body = { args: { title } };
  const payload = restClient
    .put(`/requests/${requestId}/documents/${slot}`, JSON.stringify(body))
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.UPDATE_DOCUMENT_TITLE,
    payload
  };
}

export const deleteTestReportOta = ({
  requesttestid,
  slot
}) => async dispatch => {
  try {
    const payload = await restClient.delete(
      `/requesttests/${requesttestid}/reports/${slot}`
    );

    dispatch({
      type: actions.DELETE_TEST_REPORT_OTA,
      payload: {
        testId: requesttestid,
        slot,
        serverResponse: payload
      }
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantDeleteUploadedFile));

    return Promise.reject(e);
  }
};

// universal action for file downloading
// * fileName - name of file to be downloaded
// * API URL - path to call backend API
// * client  - pick which rest client to use.
// * params - any aditional params not handled in given url.
export const fileDownload = (
  fileName,
  url,
  client = "",
  params = {}
) => async dispatch => {
  try {

    const payload = await pickRestClient(client).get(url, {
      responseType: "blob",
      params: params
    });

    // data fetched, now starting download
    downloadFile(payload.data, fileName);

    // return async action
    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantDownloadFile));

    return Promise.reject(e);
  }
};

export function mustBeExcelFileMessage() {
  return call.showNotice(notifyMessages.mustBeExcelFileMessage);
};

export function mustBeWordPDFFileMessage() {
  return call.showNotice(notifyMessages.mustBeWordPDFFileMessage);
};
