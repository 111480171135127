import React, { useState } from "react";
import { connect } from "react-redux";
import { IconLabel, Switcher, Drawer } from "ctia-ui";
import { Col, Row } from "react-bootstrap";
import EditInvoiceATL from "containers/ControlPanel/Payments/EditInvoice/EditInvoiceATL";
import ConfirmPaymentATL from "containers/ControlPanel/Payments/ConfirmPaymentATL/ConfirmPaymentATL";
import RemovePaymentATL from "containers/ControlPanel/Payments/ConfirmPaymentATL/RemovePaymentATL";
import DownloadInvoiceATL from "containers/ControlPanel/Payments/DownloadInvoiceATL";
import LabUsers from "containers/ControlPanel/CompanyUsers";
import ShowCommentsATL from "containers/ControlPanel/Payments/ShowCommentsATL";
import DownloadSignedAgrATL from "containers/ControlPanel/Payments/DownloadSignedAgrATL";
import * as rh from "containers/ControlPanel/Payments/RenderHelperATL";
import { mapYearBasedProps } from "helpers/AtlHelper";
import { toggleMembershipFee, toggleAtlFee } from "actions/catl";
import "containers/ControlPanel/Payments/payments.css";

const LabRowATL = ({
  atl,
  catl,
  clearComments,
  toggleMembershipFee,
  toggleAtlFee
}) => {
  const [showBottomDrawer, setShowBottomDrawer] = useState(false);
  const toggleBottomDrawer = () => setShowBottomDrawer(!showBottomDrawer);

  const yProps = mapYearBasedProps(catl.invoicingYear, atl);

  const showLink = () => {
    // not show for all prev years
    if (catl.readOnly) return false;

    return !yProps.invoicePaid && yProps.invoiceId;
  };

  const cityCountry = atl.citydisplay
    ? `${atl.citydisplay}, ${atl.countrydisplay}`
    : atl.countrydisplay;

	function isMissingAddress() {
		if(!!atl.invoiceAddress) {
			return atl.invoiceAddress.address1 === null || atl.invoiceAddress.address1 === "";
		}

		return true;
	}

  return (
    <tr>
      <td>
        <Col className="payments-atl-link">
          <h4 className="bold">{atl.name}</h4>
        </Col>
        <Col style={{ paddingBottom: 15 }}>
          <small>{rh.renderNameWithFlag(cityCountry, atl.country)}</small>
          <small className="block-left-offset">
            <IconLabel awesomeIcon="user" label={atl.contactname || "n/a"} />
          </small>
          <small className="block-left-offset">
            <span
              className="glow-link cursor-pointer"
              onClick={toggleBottomDrawer}
            >
              <IconLabel awesomeIcon="users" label="View All Users" />
            </span>
          </small>
        </Col>
        <Row>
          <Col xs={3}>
            Agreement: {rh.renderAgreementBagde(yProps.agreementSigned)}
            <DownloadSignedAgrATL
              show={yProps.agreementSigned}
              companyId={atl.id}
              ivcNum={yProps.invoiceNumber}
              year={catl.invoicingYear}
            />
          </Col>
          <Col xs={3}>
            {rh.renderInvoiceBagde(
              yProps.invoicePaid,
              yProps.invoicePaidDate,
              yProps.invoiceNumber
            )}
          </Col>
          <Col xs={3}>
            <Switcher
              show={true}
              labelText="Membership"
              onChange={() => toggleMembershipFee(atl.id)}
              disabled={catl.readOnly}
              checked={atl.membershipPaid === true}
              showWarning={false}
            />
          </Col>
          <Col xs={3}>
            <Switcher
              show={true}
              labelText="ATL"
              onChange={() => {
                toggleAtlFee(atl.id);
              }}
              disabled={catl.readOnly}
              checked={atl.atlFeePaid === true}
              showWarning={false}
            />
          </Col>
        </Row>
      </td>
      <td>
        <Drawer
          position="bottom"
          show={showBottomDrawer}
          title="View Lab Users"
          toggleDrawer={toggleBottomDrawer}
          content={
            <LabUsers
              companyId={atl.id}
              companyName={atl.name}
              flagCode={atl.country}
            />
          }
          height="400"
        />
        <EditInvoiceATL
          id={yProps.invoiceId}
          labName={atl.name}
          ivcNum={yProps.invoiceNumber}
          showLink={showLink()}
        />
        <DownloadInvoiceATL
          show={yProps.invoiceId}
          companyId={atl.id}
          ivcNum={yProps.invoiceNumber}
          isMissingAddress={isMissingAddress()}
		  isBackgroundJobRunning={catl.isBackgroundJobRunning}
          id={yProps.invoiceId}
        />
        <ConfirmPaymentATL
          id={yProps.invoiceId}
          labName={atl.name}
          flagCode={atl.country}
          ivcNum={yProps.invoiceNumber}
          showLink={showLink()}
        />
        <RemovePaymentATL
          ivcId={yProps.invoiceId}
          showLink={yProps.invoicePaid}
        />
        <ShowCommentsATL atl={atl} />
      </td>
    </tr>
  );
};

function mapStateToProps({ catl }) {
  return { catl };
}

export default connect(mapStateToProps, {
  toggleMembershipFee,
  toggleAtlFee
})(LabRowATL);
