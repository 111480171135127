import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";
import ReqTypes from "dictionaries/ReqTypes";

const ManufSiteName = ({ request, user, handleInputChange }) => {
  if (!request.manufSite) return null;
  if (!request.manufSite.name) return null;

  const elemType = (user.canEditManufsite && user.canEditRequest) 
    && request.type !== ReqTypes.renewal ? "text" : "";
  const elemValue = request.manufSiteName || request.manufSite.name;

  return (
    <FormElement
      id="manufSiteName"
      type={elemType}
      key="manufSiteName"
      value={elemValue}
      label="Manufacturing Site"
      onChange={handleInputChange}
    />
  );
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, { handleInputChange })(ManufSiteName);
