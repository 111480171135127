import React from "react";
import { IconLabel } from "ctia-ui";

const Clear = ({
  label = "Clear",
  icon = "broom",
  handler = () => {},
  cssClass = "icon-left-offset cursor-pointer"
}) => {
  return (
    <span className={cssClass} onClick={() => handler()}>
      <IconLabel label={label} awesomeIcon={icon} />
    </span>
  );
};

export default Clear;
