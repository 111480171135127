import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { setValue } from "actions/request";
import { requestFields } from "helpers/BatterySupplierHelper";
import { toggleOptions } from "helpers/SupplierHelper";

class ModelOptions extends React.Component {
  requestField = requestFields.modelOption;

  componentWillUnmount() {
    this.props.setValue(this.requestField, "");
  }

  render() {
    const { request, user } = this.props;

    // do not render untill supplier be selected:
    if (!request[requestFields.supplier]) return null;

    // prevent labs from adding pending recognitions
    if (user.isLab) {
      toggleOptions[1].disabled = true;
    }

    return (
      <FormElement
        type="toggleRadioGroup"
        id={this.requestField}
        label="Battery Model Name/Number"
        value={request[this.requestField]}
        list={toggleOptions}
      />
    );
  }
}

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, { setValue })(ModelOptions);
