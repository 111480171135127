import React, { Component } from "react";
import { connect } from "react-redux";
import SubmissionRender from "containers/submission/SubmissionRender";
import { runInitialWizardDHR } from "actions/dhr/dhrRequest";
import { clearRequest } from "actions/request";
import { clearWizard } from "actions/wizard";

class InitialSubmitDHR extends Component {
  componentDidMount() {
    this.props.runInitialWizardDHR(this.props.user);
  }

  componentWillUnmount() {
    this.props.clearRequest();
    this.props.clearWizard();
  }

  render() {
    return <SubmissionRender history={this.props.history} />;
  }
}

export default connect(null, {
  runInitialWizardDHR,
  clearRequest,
  clearWizard
})(InitialSubmitDHR);
