import React from "react";
import { Bubble } from "ctia-ui";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import FormElement from "components/FormElement";

const RequestCommentReadonly = ({ request }) => {
  const { vendorNote } = request;

  if (!vendorNote) {
    return <Bubble>No comments provided by supplier for the request.</Bubble>;
  }

  return (
    <div>
      <Form horizontal>
        <FormElement
          // type="textarea"
          id="vendorNote"
          label="Optional Comments"
          value={vendorNote}
        />
      </Form>
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(RequestCommentReadonly);
