import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import AuditResultsInfoBar from "containers/request/battery/TestResults/AuditResultsInfoBar";
import { LoaderButton } from "ctia-ui";
import AuditResultPanel from "containers/request/battery/TestResults/AuditResultPanel";
import PageTitle from "components/RequestPageTitle";
import AuditResultForm from "containers/request/battery/TestResults/AuditResultForm";
import LabResultUpload from "containers/testResult/LabResultUpload";
import UnderReviewViewSAR from "containers/testResult/UnderReviewViewSAR";
import GoBackButton from "components/form/buttons/GoBackButton";
import { hasPreparedResults, isComplete } from "helpers/RequestTestsHelper";
import { validateManufSiteAddress } from "helpers/ValidatorHelper";
import { clearRequest } from "actions/request";
import {
  getDetailsSAR,
  submitAuditResults,
  auditReportDownload
} from "actions/battery/sar";

const goBackLink = "/battery/lab/results";

class AuditResultsItem extends Component {
  state = { showContent: false };

  componentDidMount() {
    const { testId, getDetailsSAR } = this.props;
    this.resetRender();
    getDetailsSAR(testId).then(this.activateRender);
  }

  componentWillReceiveProps(nextProps) {
    const { testId, getDetailsSAR } = this.props;
    const newId = nextProps.testId;
    if (newId !== testId) {
      getDetailsSAR(newId).then(this.activateRender);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
  }

  resetRender = () => {
    this.setState({ showContent: false });
  };

  activateRender = () => {
    this.setState({ showContent: true });
  };

  onValidate() {
    const { request, tests, sites } = this.props;
    const { testResults, crslversion } = request;
    const { reports, requesttestpassedstatusid } = tests[0];

    const commonFieldsValidate =
      requesttestpassedstatusid > 0 && !_.isEmpty(String(crslversion));

    if (!commonFieldsValidate) return false;

    // manufsite address validation
    if (!validateManufSiteAddress(sites.current)) return false;

    return hasPreparedResults(testResults) || reports.length;
  }

  onSubmit = () => {
    const { request, tests, sites, submitAuditResults } = this.props;

    // combine args in object
    const complexData = {
      request,
      testModule: tests[0],
      manufSite: { ...sites.current, id: request.manufSite.addressid }
    };

    // do all-in-one action
    return submitAuditResults(complexData);
  };

  onDone = () => {
    this.props.history.push(goBackLink);
  };

  render() {
    const { request, tests, auditReportDownload } = this.props;

    if (_.isNull(request) || _.isNull(tests) || !request.id) {
      return null;
    }

    if (!this.state.showContent) return null;

    if (request.formSaved) {
      return (
        <div>
          <h1 className="block-bottom-offset">Uploading Audit Results</h1>
          <AuditResultPanel />
          <LoaderButton text="Done" onClick={this.onDone} disabled={false} />
        </div>
      );
    }

    const testModule = { ...tests[0] };
    const completed = isComplete(request.testStatus);

    return (
      <div>
        <PageTitle />
        <GoBackButton dest={goBackLink} label="Return to list" />
        <AuditResultsInfoBar />
        <UnderReviewViewSAR show={completed} testModule={testModule} />
        <AuditResultForm show={!completed} testModule={testModule} />
        <LabResultUpload
          show={!completed}
          downloadHandler={auditReportDownload}
        />
        <Row>
          <Col className="text-center">
            <LoaderButton
              bsStyle="info"
              bsSize="large"
              text="Save changes"
              loadingText="Uploading..."
              onClick={this.onSubmit}
              isLoading={request.isLoading}
              disabled={!this.onValidate()}
              show={!completed}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ request, tests, sites }) {
  return { request, tests, sites };
}

export default connect(mapStateToProps, {
  clearRequest,
  submitAuditResults,
  getDetailsSAR,
  auditReportDownload
})(AuditResultsItem);
