export default {
  1: { id: 1, title: "Pending" },
  2: { id: 2, title: "Approved" },
  4: { id: 4, title: "Awaiting Parent Certification" },
  5: { id: 5, title: "Under Review" },
  8: { id: 8, title: "Withdrawn" },
  12: { id: 12, title: "Awaiting Parent Approval" },
  17: { id: 17, title: "Saved" },
  18: { id: 18, title: "Awaiting Approval of Battery System Certification" },
  19: { id: 19, title: "Awaiting Approval of Linked Audit" },
};
