import React from 'react';
import { connect } from 'react-redux';
import TabContainer from 'containers/RequestTabContainer';

const TabContent = ({ wizard, history }) => {
  const { step, steps, isSaved, isSubmitted } = wizard;

  // if wizard completed
  if (isSaved || isSubmitted) return null;

  return (
    <TabContainer
      component={steps[step].tab}
      isEditable={true}
      canSubmit={true}
      history={history}
    />
  );
};

function mapStateToProps({ wizard }) {
  return { wizard };
}

export default connect(mapStateToProps)(TabContent);
