import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Bubble, InlinePreloader } from "ctia-ui";
import SupplierList from "containers/request/battery/PackCellsWithRecognition/CellSupplierList";
import { fetchCellSuppliers } from "actions/suppliers";
import CellRecognitionSupplierSelector from "containers/request/battery/PackCellsWithRecognition/CellRecognitionSupplierSelector";

const validationMsg = "You must select and add at least one cell.";

const PackCellsWithRecognition = ({ request, fetchCellSuppliers }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchCellSuppliers(request.ieee).then(() => setLoading(false));
  }, [fetchCellSuppliers, request.ieee]);

  const showWarning =
    !request.cellSuppliers || _.isEmpty(request.cellSuppliers);

  return (
    <>
      {showWarning && (
        <Bubble icon="alert" type="warning">
          {validationMsg}
        </Bubble>
      )}
      <SupplierList />
      {loading ? <InlinePreloader /> : <CellRecognitionSupplierSelector />}
    </>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  fetchCellSuppliers
})(PackCellsWithRecognition);
