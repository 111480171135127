import { restClientGeneral } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import { downloadFile } from "helpers/UploadsHelper";
import { getModelName, getTransPrefix } from "helpers/RequestHelper";
import * as actions from "actions/types";

/*==========================================================*/
/** LICENSE AGREEMENT (PREVIOUSLY — RECOGNITION AGREEMENT) */
/*========================================================*/
const recognitionTemplate = "LicenseAgreement_Template.pdf";

export const signRecognitionAgreement = (
  modelId,
  ieeeType,
  certType
) => async dispatch => {
  try {
    const url = `/${certType}/devicemodels/${modelId}/recognitionagreements?ieeetypeid=${ieeeType}`;
    const res = await restClientGeneral.post(url);
    const isSigned = res.data.data.documentstatusid === 1;

    dispatch({
      type: actions.SIGN_AGREEMENT_BATTERY_RECOGNITION,
      payload: isSigned
    });

    return res;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantSignAgreement
    });

    return Promise.reject(e);
  }
};

export function downloadRecognitionSigned({ model, tn, ieee }) {
  const fileName = `${tn}_LicenseAgreement.pdf`;
  const urlPrefix = getTransPrefix(tn);
  const url = `${urlPrefix}/devicemodels/${model.id}/recognitionagreements?ieeetypeid=${ieee}&download=true`;
  const payload = restClientGeneral
    .get(url, { responseType: "blob" })
    .then(res => {
      downloadFile(res.data, fileName);
      return res;
    })
    .catch(err => err);

  return {
    type: actions.DOWNLOAD_SIGNED_AGREEMENT,
    payload
  };
}

export function downloadRecognitionBlank({
  submitter = "",
  modelName = "",
  ieee,
  urlPrefix,
  licenseagreementversionid
}) {
  const url = `${urlPrefix}/recognitionagreements?download=true&submitter=${submitter}&devicemodelnumber=${modelName}&ieeetypeid=${ieee}&licenseagreementversionid=${licenseagreementversionid}`;
  const payload = restClientGeneral
    .get(url, { responseType: "blob" })
    .then(response => {
      downloadFile(response.data, recognitionTemplate);
      return response;
    })
    .catch(err => err);

  return {
    type: actions.DOWNLOAD_AGREEMENT_BLANK,
    payload
  };
}

export const downloadRecognition = (request, certType) => async dispatch => {
  if (request.agreementAccepted) {
    return downloadRecognitionSigned(request);
  } else {
    const { ieee, tn, vendor, model } = request;
    const modelName = getModelName(request, true);
    const urlPrefix = certType || getTransPrefix(tn);

	let licenseAgreementVersionID = 0;
	if("licenseagreementversionid" in request) {
		licenseAgreementVersionID = request.licenseagreementversionid;
	} else {
		const response = await restClientGeneral
		.get("licenseagreementversion?newest=true")
		.then(response => {
			return response;
		})
		.catch(err => err);

		licenseAgreementVersionID = response.data.id;
	}

    const reqArgs = {
      submitter: vendor || model.vendor.name,
      modelName,
      ieee,
      urlPrefix,
	  licenseagreementversionid: licenseAgreementVersionID
    };

    return downloadRecognitionBlank(reqArgs);
  }
}

// ===================================
// BLC - Battery Life Certification - request-oriented agreement
// ===================================
export const signLicenseAgreementBLC = (
  requestId,
  certType
) => async dispatch => {
  try {
    const url = `/${certType}/requests/${requestId}/requestagreements`;
    const res = await restClientGeneral.post(url);
    const isSigned = res.data.data.documentstatusid === 1;

    dispatch({
      type: actions.SIGN_AGREEMENT_BATTERY_RECOGNITION,
      payload: isSigned
    });

    return res;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantSignAgreement
    });

    return Promise.reject(e);
  }
};

export function downloadAgreementSigned({ id, tn }) {
  const fileName = `${tn}_LicenseAgreement.pdf`;
  const urlPrefix = getTransPrefix(tn);
  const url = `${urlPrefix}/requests/${id}/requestagreements?download=true`;
  const payload = restClientGeneral
    .get(url, { responseType: "blob" })
    .then(res => {
      downloadFile(res.data, fileName);
      return res;
    })
    .catch(err => err);

  return {
    type: actions.DOWNLOAD_SIGNED_AGREEMENT,
    payload
  };
}

export function downloadAgreementBlank({
  submitter = "",
  modelName = "",
  urlPrefix,
  licenseagreementversionid
}) {
  const url = `${urlPrefix}/requestagreements?download=true&submitter=${submitter}&devicemodelnumber=${modelName}&licenseagreementversionid=${licenseagreementversionid}`;
  const payload = restClientGeneral
    .get(url, { responseType: "blob" })
    .then(response => {
      downloadFile(response.data, recognitionTemplate);
      return response;
    })
    .catch(err => err);

  return {
    type: actions.DOWNLOAD_AGREEMENT_BLANK,
    payload
  };
}

export const downloadAgreementBLC = (request) => async dispatch => {
  if (request.agreementAccepted) {
    return downloadAgreementSigned(request);
  } else {
    const modelName = getModelName(request, true);

	let licenseAgreementVersionID = 0;
	if("licenseagreementversionid" in request) {
		licenseAgreementVersionID = request.licenseagreementversionid;
	} else {
		const response = await restClientGeneral
		.get("licenseagreementversion?newest=true")
		.then(response => {
			return response;
		})
		.catch(err => err);

		licenseAgreementVersionID = response.data.id;
	}

    const reqArgs = {
      submitter: request.vendor,
      modelName,
      urlPrefix: "blc",
	  licenseagreementversionid: licenseAgreementVersionID
    };
    return downloadAgreementBlank(reqArgs);
  }
};

/*=============================*/
/** DECLARATION OF COMPLIANCE */
/*===========================*/
const declarationTemplate = "DeclarationOfCompliance_Template.pdf";

export const signDeclarationAgreement = (
  modelId,
  ieeeType,
  certType
) => async dispatch => {
  try {
    const url = `/${certType}/devicemodels/${modelId}/compliancedeclarations?ieeetypeid=${ieeeType}`;
    const res = await restClientGeneral.post(url);
    const isSigned = res.data.data.documentstatusid === 1;

    dispatch({
      type: actions.SIGN_AGREEMENT_BATTERY_DECLARATION,
      payload: isSigned
    });

    return res;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantSignAgreement
    });

    return Promise.reject(e);
  }
};

export function downloadDeclarationSigned({ model, tn, ieee }) {
  const fileName = `${tn}_DeclarationOfCompliance.pdf`;
  const urlPrefix = getTransPrefix(tn);
  const url = `${urlPrefix}/devicemodels/${model.id}/compliancedeclarations?ieeetypeid=${ieee}&download=true`;
  const payload = restClientGeneral
    .get(url, { responseType: "blob" })
    .then(res => {
      downloadFile(res.data, fileName);
      return res;
    })
    .catch(err => err);

  return {
    type: actions.DOWNLOAD_SIGNED_AGREEMENT,
    payload
  };
}

export function downloadDeclarationBlank({
  submitter = "",
  modelName = "",
  ieee,
  urlPrefix
}) {
  const url = `${urlPrefix}/compliancedeclarations?download=true&submitter=${submitter}&devicemodelnumber=${modelName}&ieeetypeid=${ieee}`;
  const payload = restClientGeneral
    .get(url, { responseType: "blob" })
    .then(response => {
      downloadFile(response.data, declarationTemplate);
      return response;
    })
    .catch(err => err);

  return {
    type: actions.DOWNLOAD_AGREEMENT_BLANK,
    payload
  };
}

export function downloadDeclaration(request, certType = "") {
  if (request.agreementAccepted) {
    return downloadDeclarationSigned(request);
  } else {
    const { ieee, tn, vendor } = request;
    const modelName = getModelName(request, true);
    const urlPrefix = certType || getTransPrefix(tn);
    const reqArgs = {
      submitter: vendor,
      modelName,
      ieee,
      urlPrefix
    };
    return downloadDeclarationBlank(reqArgs);
  }
}
