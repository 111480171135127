import React, { useState } from "react";
import { connect } from "react-redux";
import EditUserLink from "containers/ControlPanel/Users/EditUser/EditUserLink";
import { formatDate } from "helpers/DateHelper";
import { activateDeletedUser } from "actions/user";

const DeletedUsersRow = ({ item, activateDeletedUser }) => {
  const [loading, setLoading] = useState(false);

  const onActivate = () => {
    setLoading(true);
    activateDeletedUser(item.ctiaid).then(setLoading(false));
  };

  const renderLink = () => {
    const linkLabel = loading ? "Activating..." : "Activate";
    const linkHandler = loading ? null : onActivate;

    return (
      <span className="cursor-pointer glow-link" onClick={linkHandler}>
        {linkLabel}
      </span>
    );
  };

  return (
    <tr>
      {/* User Name */}
      <td>
        <EditUserLink userData={item} />
      </td>
      {/* Business Title */}
      <td>{item.title}</td>
      {/* Company */}
      <td>{item.companyname}</td>
      {/* E-mail */}
      <td>{item.email}</td>
      {/* Last Login */}
      <td>{item.deletedat ? formatDate(item.deletedat) : ""}</td>
      <td>{renderLink()}</td>
    </tr>
  );
};

export default connect(null, { activateDeletedUser })(DeletedUsersRow);
