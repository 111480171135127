import React from 'react';
import { HelpBlock } from 'react-bootstrap';
import PropTypes from 'prop-types';

const InputFile = ({ file, id, onChange, disabled, label }) => {
  const renderFileTitle = () => {
    if (!file) return null;
    return (
      <HelpBlock>
        <b>File:</b> {file.name}
      </HelpBlock>
    );
  };

  const renderButton = () => {
    const disabledClass = disabled ? 'input-file-disabled disabled' : '';
    return (
      <label htmlFor={id} className={`btn btn-sm btn-default ${disabledClass}`}>
        <i
          className="fa fa-upload icon-right-offset"
          aria-hidden="true"
        />
        {label}
      </label>
    );
  };

  return (
    <div>
      <input
        type="file"
        id={id}
        className="hidden"
        disabled={disabled}
        onChange={onChange}
      />
      {renderButton()}
      {renderFileTitle()}
    </div>
  );
};

InputFile.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string
};

InputFile.defaultProps = {
  label: 'Select File',
  disabled: true
};

export default InputFile;
