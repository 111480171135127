export const mapMeta = meta => {
  return [
    {
      icon: "flask",
      label: "Authorized Service Centers",
      value: meta.TOTALACTIVEATLLABS,
      invoicing: "asc"
    },
    {
      icon: "signature",
      label: "Signed Agreements",
      value: meta.TOTALAGREEMENTSSIGNED,
      total: meta.TOTALACTIVEATLLABS
    },
    {
      icon: "dollar-sign",
      label: "Paid Invoices",
      value: meta.TOTALINVOICESPAID,
      total: meta.TOTALINVOICES
    }
  ];
};

export const mapYearBasedProps = (year, props) => {
  return {
    agreementSigned: props[`ASCAnualLicenseAgreementSigned${year}`],
    invoicePaid: props[`ASCAnualLicenseFeePaid${year}`],
    invoicePaidDate: props[`ASCAnualLicenseFeePaidAt${year}`],
    invoiceNumber: props[`ASCAnualLicenseFeeNum${year}`],
    membershipFeePaid: props[`hasASCMembershipCost${year}`],
    atlFeePaid: props[`hasASCFeeCost${year}`],
    invoiceId: props[`ASCAnualLicenseFeeKey${year}`]
  };
};
