import React from "react";

const ReadonlyList = ({ list }) => {
  if (!list || list.length === 0) return "Empty list";
  return (
    <ul className="ctia-list form-field-list">
      {list.map((el, i) => (
        <li key={`readonly-list-el-${i}`}>{el}</li>
      ))}
    </ul>
  );
};

export default ReadonlyList;
