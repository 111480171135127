import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { setValue } from "actions/request";
import { hasStringValue } from "helpers/RequestHelper";

const SelectDeviceType = ({ user, request, setValue, editable }) => {
  if(editable === 'false') {
    editable = false;
  } else {
    editable = true;
  }
  const inputType = (editable && (!(request.isEco && hasStringValue(request.parentRequest, "devicetype")))) ? 'select' : '';

  const onChange = event => {
    setValue("deviceType", event.target.value);
  };

  const deviceTypeList = [
    {
        linkTitle: "Select Device Type ↴",
        value: ""
    },
    {
        linkTitle: "IoT Device",
        value: "IoT Device",
        certType: "blc"
    },
    {
        linkTitle: "Smartphone",
        value: "Smartphone",
        certType: "blc"
    },
    {
        linkTitle: "Smartwatch",
        value: "Smartwatch",
        certType: "blc"
    }
  ];

  if(request.isEco && hasStringValue(request.parentRequest, "devicetype") && !user.isStaff) {
    return (

      <FormElement
        type={inputType}
        id="deviceType"
        label="Device Type"
        value={request.parentRequest.devicetype}
        onChange={onChange}
        options={deviceTypeList}
        editable={!(request.isEco && hasStringValue(request.parentRequest, "devicetype"))}
      />
    );
  } else {

    return (

      <FormElement
        type="select"
        id="deviceType"
        label="Device Type"
        value={request.deviceType}
        onChange={onChange}
        options={deviceTypeList}
      />
    );
  }

};

function mapStateToProps({ user, request }) {
  return { user, request };
}

export default connect(mapStateToProps, {
  setValue
})(SelectDeviceType);
