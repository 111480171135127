import React from "react";
import { Divider } from "ctia-ui";
import { Row, Col } from "react-bootstrap";
import SaveBtn from "containers/request/action-buttons/RequestSaveButton";
import ContinueBtn from "containers/wizard/action-buttons/WizardContinueButton";
import SubmitBtn from "containers/wizard/action-buttons/WizardSubmitButton";
import WizardSaveBtn from "containers/wizard/action-buttons/WizardSaveButton";

const RequestControls = ({
  isValid,
  isValidToSave, // submission related flag
  isValidToSubmit, // submission related flag
  onSave,
  onWizardSave,
  onContinue,
  onSubmit,
  show = true,
}) => {
  if (!show) return null;

  const canBeSaved = isValidToSave || isValid;
  const canBeSubmitted = isValidToSubmit || isValid;

  return (
    <div>
      <Divider />
      <Row>
        <Col xs={6}>
          <WizardSaveBtn isValid={canBeSaved} onSave={onWizardSave} />
        </Col>
        <Col xs={6} className="text-right">
          <SaveBtn isValid={isValid} onSave={onSave} />
          <ContinueBtn isValid={isValid} onContinue={onContinue} />
          <SubmitBtn isValid={canBeSubmitted} onSubmit={onSubmit} />
        </Col>
      </Row>
    </div>
  );
};

export default RequestControls;
