import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { LoaderButton, IconLabel } from "ctia-ui";
import { Col, Row, Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  handleIvcInputChange,
  handleIvcCostChange,
  fetchInvoiceATL,
  updateInvoiceATL
} from "actions/catl";
import { InlinePreloader } from "ctia-ui";
import { formatDate } from "helpers/DateHelper";
import { formatNumberString } from "helpers/StringHelper";
import LogoPng from "containers/Logo/LogoPng";
import TextArea from "components/form/TextArea";
import InputText from "components/form/InputText";
import "containers/ControlPanel/Payments/payments.css";

const EditInvoiceModalATL = ({
  id,
  labName,
  ivcNum,
  show,
  modalHandler,
  app,
  catlInvoice,
  handleIvcInputChange,
  handleIvcCostChange,
  fetchInvoiceATL,
  updateInvoiceATL
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id && show) {
      fetchInvoiceATL(id);
    }
  }, [fetchInvoiceATL, id, show]);

  const validateCosts = costs => {
    if (!costs) return false;

    const validateDesc = _.some(costs, c => _.isEmpty(c.description));
    const validateAmount = _.some(
      costs,
      c =>
        (_.isString(c.amount) &&
          (_.isEmpty(c.amount) || _.isNaN(Number(c.amount)))) ||
        _.isNaN(c.amount)
    );

    return !validateAmount && !validateDesc;
  };

  const validateInvoice = () => {
    if (!catlInvoice) return false;

    if (!catlInvoice.countrycode) return false;

    if (
      catlInvoice.countrycode.toString().toUpperCase() === "US" &&
      (!catlInvoice.zipcode || !catlInvoice.state)
    )
      return false;

    return (
      !_.isEmpty(catlInvoice.name) &&
      !_.isEmpty(catlInvoice.address1) &&
      !_.isEmpty(catlInvoice.city) &&
      validateCosts(catlInvoice.costs)
    );
  };

  const renderSubmitButton = () => {
    if (!catlInvoice || !catlInvoice.costs) return null;

    return (
      <span className="icon-left-offset">
        <LoaderButton
          bsStyle="success"
          bsSize="small"
          text={<IconLabel label="Submit" awesomeIcon="check" />}
          disabled={!validateInvoice()}
          onClick={() => {
            setLoading(true);
            updateInvoiceATL(
              catlInvoice.invoiceId.length ? catlInvoice.invoiceiD : id,
              catlInvoice,
              catlInvoice.costs
            )
              .then(() => {
                setLoading(false);
                modalHandler();
              })
              .catch(() => setLoading(false));
          }}
          isLoading={loading}
          loadingText="Submitting..."
        />
      </span>
    );
  };

  const renderCosts = () => {
    if (!catlInvoice || !catlInvoice.costs) return null;

    return (
      <table className="atl-invoice text-center">
        <thead>
          <tr>
            <td>Description</td>
            <td>Price</td>
          </tr>
        </thead>
        <tbody>
          {catlInvoice.costs.map((el, i) => {
            return (
              <tr key={i}>
                <td>
                  <div style={{ margin: 10 }}>
                    <TextArea
                      onChange={e => handleIvcCostChange(i, "description", e)}
                      value={el.description}
                    />
                  </div>
                </td>
                <td>
                  <div style={{ margin: 10 }}>
                    <InputText
                      onChange={e => {
                        if (e.target.value) {
                          e.target.value = formatNumberString(e.target.value);
                        }
                        handleIvcCostChange(i, "amount", e);
                      }}
                      value={el.amount}
                      editable={true}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderTable = () => {
    if (!catlInvoice) return null;

    return (
      <div>
        <div>
          <LogoPng />
        </div>
        <div>
          <h1 className="text-right">INVOICE</h1>
        </div>
        <div style={{ fontSize: 13 }}>
          <Row>
            <Col xs={8}>
              CTIA Certification LLC
              <br />
              Attn: Accounts Receivable
              <br />
              1400 16th Street, NW
              <br />
              Suite 600
              <br />
              Washington DC, 20036
              <br />
              Phone# 1-202-736-3200
              <br />
              Federal ID #: 52-1347628
            </Col>
            <Col xs={4}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>Invoice</td>
                    <td className="text-right">{catlInvoice.invoiceNumber}</td>
                  </tr>
                  <tr>
                    <td>Customer ID</td>
                    <td className="text-right">{catlInvoice.customerid}</td>
                  </tr>
                  <tr>
                    <td>Customer PO</td>
                    <td className="text-right editIvcTop">
                      <input
                        type="text"
                        name="purchaseorder"
                        value={catlInvoice.purchaseorder}
                        onChange={handleIvcInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td className="text-right editIvcTop">
                      <DatePicker
                        id="createdat"
                        value={moment(catlInvoice.invoiceDate)}
                        selected={moment(catlInvoice.invoiceDate)}
                        onChange={date =>
                          handleIvcInputChange({
                            target: {
                              id: "invoiceDate",
                              value: formatDate(date)
                            }
                          })
                        }
                        minDate={moment(app.certMinDateLegacy)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row style={{ paddingTop: 25 }}>
            <Col xs={1}>Bill To:</Col>
            <Col xs={5} className="billToForm">
              <div className="billToForm__field">
                <b>Company:</b>
                <input
                  type="text"
                  name="name"
                  value={catlInvoice.name}
                  onChange={handleIvcInputChange}
                />
              </div>
              <div className="billToForm__field">
                <b>Address 1:</b>
                <input
                  type="text"
                  name="address1"
                  value={catlInvoice.address1}
                  onChange={handleIvcInputChange}
                />
              </div>
              <div className="billToForm__field">
                <b>Address 2:</b>
                <input
                  type="text"
                  name="address2"
                  value={catlInvoice.address2}
                  onChange={handleIvcInputChange}
                />
              </div>
              <div className="billToForm__field">
                <b>City:</b>
                <input
                  type="text"
                  name="city"
                  value={catlInvoice.city}
                  onChange={handleIvcInputChange}
                />
              </div>
              <div className="billToForm__field">
                <strong>State:</strong>
                <input
                  type="text"
                  size="2"
                  name="state"
                  value={catlInvoice.state}
                  onChange={handleIvcInputChange}
                  maxLength={2}
                />
                <strong>Zip:</strong>
                <input
                  type="text"
                  size="6"
                  name="zipcode"
                  value={catlInvoice.zipcode}
                  onChange={handleIvcInputChange}
                  maxLength={6}
                />
                <strong>Country:</strong>
                <input
                  type="text"
                  size="2"
                  name="countrycode"
                  value={catlInvoice.countrycode}
                  onChange={handleIvcInputChange}
                  maxLength={2}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ padding: 15 }}>{renderCosts()}</Row>
        </div>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={modalHandler} bsSize="large">
      <Modal.Header closeButton>
        <Modal.Title>
          <div>Edit Invoice</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!catlInvoice ? <InlinePreloader /> : renderTable()}
      </Modal.Body>
      <Modal.Footer>
        {renderSubmitButton()}
        <Button
          bsSize="small"
          onClick={modalHandler}
          className="agr-close-btn"
          disabled={loading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditInvoiceModalATL.propTypes = {
  show: PropTypes.bool,
  modalHandler: PropTypes.func
};

function mapStateToProps({ app, catlInvoice }) {
  return { app, catlInvoice };
}

export default connect(mapStateToProps, {
  handleIvcInputChange,
  handleIvcCostChange,
  fetchInvoiceATL,
  updateInvoiceATL
})(EditInvoiceModalATL);
