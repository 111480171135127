import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { Bubble } from "ctia-ui";
import FormElement from "components/FormElement";
import RequestControls from "containers/request/RequestControls";
import { handleInputChange, saveRequest } from "actions/request";

class ModelInfo extends Component {
  validate() {
    const { hwVersion, swVersion, descriptionOfChange } = this.props.request;

    return (
      hwVersion.length > 0 &&
      swVersion.length > 0 &&
      descriptionOfChange.length > 0
    );
  }

  render() {
    const {
      show,
      request,
      isEditable,
      canSave,
      handleInputChange,
      saveRequest
    } = this.props;

    if (!show) return null;

    const saveHandler = isEditable ? saveRequest : null;

    return (
      <div>
        {/** TODO: replace it with component */}
        <Bubble show={!canSave}>
          Please enter the <strong>Hardware/Software versions</strong> for this
          request and the <strong>Description of Change</strong>.
        </Bubble>
        <Bubble show={canSave}>
          Below is the <strong>Hardware/Software versions</strong> and the{" "}
          <strong>Description of Change</strong> provided for this request.
        </Bubble>
        <Form horizontal>
          <FormElement
            type="text"
            id="hwVersion"
            label="HW Version"
            value={request.hwVersion}
            onChange={handleInputChange}
            editable={isEditable}
            maxLength="50"
          />
          <FormElement
            type="text"
            id="swVersion"
            label="SW Version"
            value={request.swVersion}
            onChange={handleInputChange}
            editable={isEditable}
            maxLength="50"
          />
          <FormElement
            type="text"
            id="descriptionOfChange"
            label="Description of Change"
            value={request.descriptionOfChange}
            onChange={handleInputChange}
            editable={isEditable}
          />
          <RequestControls isValid={this.validate()} onSave={saveHandler} />
        </Form>
      </div>
    );
  }
}

ModelInfo.propTypes = {
  show: PropTypes.bool,
  canSave: PropTypes.bool,
  canSubmit: PropTypes.bool
};

ModelInfo.defaultProps = {
  show: true,
  canSave: false,
  canSubmit: false
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  handleInputChange,
  saveRequest
})(ModelInfo);
