import React from 'react';
import { Label, Col, FormGroup } from 'react-bootstrap';

export default ({ id, label, labelStyle, content }) => (
  <FormGroup controlId={id}>
    <Col sm={4} md={3}>
      <Label bsStyle={labelStyle}>{label}</Label>
    </Col>
    <Col sm={8} md={9}>
      {content}
    </Col>
  </FormGroup>
);
