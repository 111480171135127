import React from "react";
import { Alert, Glyphicon, Col } from "react-bootstrap";

const FileStatusInfo = () => {
  return (
    <Col xs={1} sm={1} md={1} className="file-status-info">
      <Alert bsStyle="info">
        <Glyphicon glyph="ok" /> — Approved
        <br />
        <Glyphicon glyph="hourglass" /> — Pending
        <br />
        <Glyphicon glyph="remove" /> — Rejected
      </Alert>
    </Col>
  );
};

export default FileStatusInfo;
