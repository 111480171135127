import React from "react";
import { connect } from "react-redux";
import { Accordion, Card } from "ctia-react-bootstrap-v4";
import TestPlanBadge from "components/Badges/TestStatusBadge";
import TestStatusInfo from "containers/request/OTA/TestResults/OtaTestStatusInfo";
import TestReports from "containers/request/OTA/TestResults/OtaTestReports--Readonly";

const TestPlansReadonly = ({ request }) => {
  if (!request || !request.otaInterfaces) return null;

  return (
    <div>
      <h3>Selected Test Plans:</h3>
      {request.otaInterfaces.map((testPlan, i) => {
        return (
          <Accordion key={i}>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={i}
                className="ctia-header-bg"
              >
                <h4>
                  <TestPlanBadge
                    statusId={testPlan.details.requestteststatusid}
                  />
                  {testPlan.planInfo.title}
                  <span style={{ paddingLeft: 10 }}>&darr;</span>
                </h4>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={i}>
                <Card.Body>
                  <TestStatusInfo testModule={testPlan} />
                  <TestReports testModule={testPlan} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        );
      })}
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(TestPlansReadonly);
