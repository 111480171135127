// ES6 Helpers
import _ from "lodash";

// React/Redux
import React from "react";
import { connect } from "react-redux";
import { setUserRequestFormValues } from "actions/newUserRequest";

import { Form } from "ctia-react-bootstrap-v4";
import FormFieldLabel from "components/form/FormFieldLabel";
import CountrySelector
  from "components/CountrySelector/CountrySelector";
import StateAndProvinceSelector
  from "components/StateAndProvinceSelector";

const UserLocationFormFields = ({
  newUserRequest,
  setUserRequestFormValues
}) => {
  if (_.isEmpty(newUserRequest) || _.isEmpty(newUserRequest.selectedUserRequest))
    return null;

  const { formValues } = newUserRequest.selectedUserRequest;
  if (_.isEmpty(formValues)) return null;

  const {
    address,
    address2,
    address3,
    country,
    city,
    state,
    zipCode
  } = formValues;

  return (
    <>
      <Form.Group controlId="address1">
        <Form.Label>
          <FormFieldLabel label="Address 1" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          isInvalid={_.isEmpty(address)}
          onChange={event => setUserRequestFormValues({
            address: event.target.value
          })}
          placeholder="Enter Address line 1"
          value={address}
        />
      </Form.Group>
      <Form.Group controlId="address2">
        <Form.Label>
          <FormFieldLabel label="Address 2" />
        </Form.Label>
        <Form.Control
          onChange={event => setUserRequestFormValues({
            address2: event.target.value
          })}
          placeholder="Enter Address line 2"
          value={address2}
        />
      </Form.Group>
      <Form.Group controlId="address3">
        <Form.Label>
          <FormFieldLabel label="Address 3" />
        </Form.Label>
        <Form.Control
          onChange={event => setUserRequestFormValues({
            address3: event.target.value
          })}
          placeholder="Enter Address line 3"
          value={address3}
        />
      </Form.Group>
      <Form.Group controlId="country">
        <Form.Label>
          <FormFieldLabel label="Country" isRequired={true} />
        </Form.Label>
        <CountrySelector
          required
          onChange={event => setUserRequestFormValues({
            country: event.target.value,
            state: ""
          })}
          value={country}
        />
      </Form.Group>
      <Form.Group controlId="city">
        <Form.Label>
          <FormFieldLabel label="City" />
        </Form.Label>
        <Form.Control
          required
          onChange={event => setUserRequestFormValues({
            city: event.target.value
          })}
          placeholder="Enter City"
          value={city}
       />
      </Form.Group>
      <Form.Group controlId="state">
        <Form.Label>
          <FormFieldLabel label="State/Province" isRequired={true} />
        </Form.Label>
        <StateAndProvinceSelector
          onChange={event => setUserRequestFormValues({
            state: event.target.value
          })}
          selectedCountryCode={country}
          value={state}
       />
      </Form.Group>
      <Form.Group controlId="zipCode">
        <Form.Label>
          <FormFieldLabel label="Zip/Postal Code" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          onChange={event => setUserRequestFormValues({
            zipCode: event.target.value
          })}
          placeholder="Enter Zip or Postal Code"
          value={zipCode}
        />
      </Form.Group>
    </>
  );
};

function mapStateToProps({ newUserRequest }) {
  return { newUserRequest };
}

export default connect(mapStateToProps, {
  setUserRequestFormValues
})(UserLocationFormFields);
