// ES6 / Helper
import _ from "lodash";

// React / Redux
import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

// UI Imports
import {
  Bubble,
  CounterLabel,
  PagerPageSize,
  PagerStatusFilter,
  Divider,
  Pager
} from "ctia-ui";

// Redux actions
import { changeBatteryRequestStatus, setPageSize } from "actions/app";
import {
  clearDeviceList,
  getApprovedBatteryDevices,
  setSearchTerm
} from "actions/devices";

// Helpers and Dictionaries
import { types } from "helpers/BatteryCertHelper";
import { getCertifiedStatusFilter } from "helpers/ModelHelper";

// Components and Containers
import GoBackButton from "components/form/buttons/GoBackButton";
import CyberTable from "containers/CyberTable";
import RenderOptions from "containers/request/RequestList/RenderOptions";
import BatteryDeviceTable from "containers/Devices/Battery/BatteryDevices/BatteryDeviceTable/BatteryDeviceTable";

class BatteryDeviceList extends CyberTable {
  componentDidMount() {
    const { devices } = this.props;
    if (!devices || devices.isSAR || !devices.list || _.isEmpty(devices.list)) {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.devices.isSAR) {
      /**
       * Handle case where table data has changed because a request was delisted
       * or relisted and we need to refetch to make sure device table reflects
       * accurately.
       */
      const oldKeepList = !_.isNull(prevProps.devices)
        ? prevProps.devices.keepList
        : null;
      const keepList = !_.isNull(this.props.devices)
        ? this.props.devices.keepList
        : null;

      if (oldKeepList !== keepList) {
        const { devices } = this.props;
        if (devices && devices.list && !_.isEmpty(devices.list)) {
          this.fetchTableData();
        }
      }
    }
  }

  setPagerSize = size => this.props.setPageSize(size);

  onStatusChange = event => {
    const newStatus = Number(event.target.value);

    // update app filter settings
    this.props.changeBatteryRequestStatus(newStatus);

    // fetch new list (waiting for redux storage update first)
    setTimeout(this.fetchTableData, 150);
  };

  fetchTableData = (
    pageNum = this.startPage,
    statusFilter = this.props.app.batteryRequestStatus.filter,
    pageSize = this.props.app.pageSize
  ) => {
    const { devices, user } = this.props;

    this.props.getApprovedBatteryDevices({
      ...devices.searchTerms,
      pageNum,
      pageSize,
      statusFilter,
      isLab: user.isLab,
      isVendor: user.isVendor || user.isSupplier
    });
  };

  changeHandler = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  startSearchResult = (list, term) => {
    return _.filter(list, device => {
      // need to convert any UPPER CASE to lower case first
      const modelName = String(device.vendorproductname).toLowerCase();
      const manufName = String(device.vendorname).toLowerCase();
      return (
        modelName.indexOf(term.toLowerCase()) !== -1 ||
        manufName.indexOf(term.toLowerCase()) !== -1
      );
    });
  };

  getPageTitle = () => {
    const selectedCertTypes = this.props.devices.searchTerms.certTypes;

    let title;
    if (_.includes(selectedCertTypes, types.ACR)) {
      title = "View Certified Devices";
    } else if (_.includes(selectedCertTypes, types.BSC)) {
      title = "View Certified Battery Systems";
    } else if (_.includes(selectedCertTypes, types.SAR)) {
      title = "View Authorized Battery Cell Manufacturing Sites";
    } else {
      title = "View Recognized Battery Subsystems";
    }

    return title;
  };

  getPageSubtitle = () => {
    const selectedCertTypes = this.props.devices.searchTerms.certTypes;
    const isACR = _.isEqual(selectedCertTypes, [types.ACR]);
    const isBSC = _.isEqual(selectedCertTypes, [types.BSC]);

    let subtitle = "Approved Requests";
    if (isACR || isBSC) {
      const typeOf = isACR ? "adapters" : "battery systems";
      subtitle = (
        <>
          Below is a list of the certified {typeOf}. Click on the{" "}
          <strong>Model Name/Number</strong> to see the system details and
          associated documentation.
        </>
      );
    } else {
      subtitle = (
        <>
          Below is a list of the recognized battery subsystems. Click on the{" "}
          <strong>Model Name/Number</strong> to see the subsystem details and
          associated documentation.
        </>
      );
    }

    return subtitle;
  };

  preventFormSubmit = event => event.preventDefault();

  render() {
    const { app, devices } = this.props;

    if (!devices || devices.isSAR || !devices.list) return null;

    const tableData = this.setTableData(devices);
    // Counter will not work as we are filtering data from backend by key, which means metadata
    // from backend will be incorrect.
    const { page, pages, records } = this.setPagerData(devices);
    const counter = this.setCounter(tableData, records);
    const statusFilter = getCertifiedStatusFilter(
      devices.searchTerms.certTypes
    );
    const { batteryRequestStatus } = app;

    return (
      <div>
        <h1>{this.getPageTitle()}</h1>
        <GoBackButton
          label="Return to Search"
          onClickHandler={this.props.clearDeviceList}
        />
        <Bubble>{this.getPageSubtitle()}</Bubble>
        <Row>
          <div id="request-filter">
            <Col xs={4} md={4}>
              <CounterLabel counter={counter} title="Total records" />
            </Col>
            <Col xs={8} md={4}>
              <PagerPageSize
                size={app.pageSize}
                onChangeHandler={this.onChangePageSize}
                options={[50, 100]}
                showAll={true}
              />
            </Col>
            <Col xs={12} md={4}>
              <PagerStatusFilter
                status={batteryRequestStatus.id}
                statusOptions={<RenderOptions options={statusFilter} />}
                onStatusChange={this.onStatusChange}
              />
            </Col>
          </div>
        </Row>
        <hr />
        <BatteryDeviceTable
          requestReloadTable={this.fetchTableData}
          show={app.isBatteryCert}
          tableData={tableData}
        />
        <Divider />
        <Pager moveTo={this.fetchTableData} totalPages={pages} page={page} />
      </div>
    );
  }
}

function mapStateToProps({ app, devices, user }) {
  return { app, devices, user };
}

export default connect(mapStateToProps, {
  setPageSize,
  setSearchTerm,
  changeBatteryRequestStatus,
  getApprovedBatteryDevices,
  clearDeviceList
})(BatteryDeviceList);
