import React from "react";
import { WithModal } from "ctia-ui";
import AddButton from "components/form/buttons/AddButton";
import AddVendorModal from "containers/ControlPanel/Orgs/Vendors/AddVendorModal";

class AddVendor extends WithModal {
  render() {
    return (
      <div>
        <AddButton onClick={this.handleModal} />
        <AddVendorModal
          show={this.state.showModal}
          modalHandler={this.handleModal}
        />
      </div>
    );
  }
}

export default AddVendor;
