import React from "react";
import { connect } from "react-redux";
import SiteOptions from "containers/request/battery/ManufSite/SiteOptions";
import AddAddress from "containers/request/battery/ManufSite/SiteAddressAdd";
import SiteSelector from "containers/request/battery/ManufSite/SiteSelector";
import AddressDetails from "containers/request/battery/ManufSite/SiteAddressDetails";
import { createNewSelected } from "helpers/ManufSiteHelper";

const SiteContainer = ({ request, sites }) => {
  if (!sites || !sites.list) return null;

  if (sites.list.length === 0) {
    return <AddAddress show={true} />;
  }

  const showAddress = createNewSelected(request);

  return (
    <div>
      <SiteOptions />
      <SiteSelector />
      <AddressDetails />
      <AddAddress show={showAddress} />
    </div>
  );
};

function mapStateToProps({ request, sites }) {
  return { request, sites };
}

export default connect(mapStateToProps)(SiteContainer);
