// es6 Helper library imports
import _ from "lodash";

// React/Redux imports
import React from "react";

// Components and Container imports
import NoTableData from "components/NoTableData";
import NewUserRequestTableRow from "containers/users/UserRequest/UserRequestMenu/NewUserRequestTable/TableRow";

// Helper imports
import { toMomentDate } from "helpers/DateHelper";

const NewUserRequestTableRows = ({
  onRowClick = () => {},
  showNeedsMoreInfo = false,
  sortDirection = "asc",
  sortField = "id",
  tableData = []
}) => {
  const rowSortRules = row => {
    const company = String(row["company"]).trim().toLowerCase();
    const createdAt = toMomentDate(row["createdat"])._d;
    const email = String(row["email"]).trim().toLowerCase();
    const firstName = String(row["firstname"]).trim().toLowerCase();
    const lastName = String(row["lastname"]).trim().toLowerCase();
    const program = String(row["program"]).toLowerCase();
    const rowId = Number(row["id"]);
    const status = Number(row["active"]);
    const title = String(row["title"]).trim().toLowerCase();

    if (sortField === "company")
      return company;
    else if (sortField === "dateSubmitted")
      return createdAt;
    else if (sortField === "email")
      return email;
    else if (sortField === "fullName")
      return [lastName, firstName];
    else if (sortField === "id")
      return rowId;
    else if (sortField === "program") {
      return [
        program,
        company,
        createdAt
      ];
    }
    else if (sortField === "status") {
      return status;
    }
    else if (sortField === "title") {
      return [
        title,
        company
      ];
    }

    return String(row[sortField]).toLowerCase();
  };

  const rowSortDirection = () => {
    const direction = sortDirection.toLowerCase();

    if (sortField === "company" || sortField === "dateSubmitted") {
      return [direction, "asc"];
    }
    else if (sortField === "program") {
      return [direction, "desc", "asc"];
      // Sort program by program, company name and then user id
    }

    return [direction]
  };

  if (!tableData || _.isEmpty(tableData)) {
    const numberOfColumns = showNeedsMoreInfo ? 7 : 6
    return <NoTableData colSpan={numberOfColumns} />;
  }

  const rows = _.orderBy(tableData, (row) => rowSortRules(row), rowSortDirection());
  return rows.map((row, rowIndex) => (
    <NewUserRequestTableRow
      key={rowIndex}
      onRowClick={onRowClick}
      rowData={row}
      rowIndex={rowIndex}
      showNeedsMoreInfo={showNeedsMoreInfo}
    />));
}

export default NewUserRequestTableRows;