export default {
  ADMIN: { id: 1, badgeType: "danger" },
  VENDOR: { id: 2, badgeType: "primary" },
  LAB: { id: 3, badgeType: "warning" },
  ACCOUNTING: { id: 4, badgeType: "success" },
  OPERATOR: { id: 5, badgeType: "info" },
  SUPPLIER: { id: 6, badgeType: "dark" },
  SERVICECENTER: { id: 7, badgeType: "secondary" },
  UNKNOWN: { id: 999, badgeType: "secondary" }
};
