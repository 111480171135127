import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { InfoBar } from "ctia-ui";

const InfoBarRecognizedDetails = ({ show, suppliers }) => {
  if (!show || !suppliers || !suppliers.model) return null;

  const { model } = suppliers;

  const getValues = () => {
    const reqDataInfo = [
      {
        key: "Supplier",
        value: model.vendor.name
      },
      {
        key: "Model Name/Number",
        value: model.modelnumber
      },
      {
        key: "Capacity",
        value: model.capacityAlias || "n/a"
      }
    ];

    // remove fields with no values:
    return _.filter(
      reqDataInfo,
      field => !_.isEmpty(field.value) || _.isNumber(field.value)
    );
  };

  return <InfoBar show={true} data={getValues()} />;
};

function mapStateToProps({ suppliers }) {
  return { suppliers };
}

export default connect(mapStateToProps)(InfoBarRecognizedDetails);
