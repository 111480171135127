import React from "react";
import VendorContent from "containers/request/Initial/VendorRequestContent";
import LabContent from "containers/request/RequestContent/LabView";
import roles from "dictionaries/UserRoles";

const ContentCyber = ({ role, focusLab }) => {
  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.accountant:
      return <VendorContent  focusLab={focusLab} />;
    case roles.operator:
      return <VendorContent hideBillingPay={true}  focusLab={focusLab} />;
    case roles.lab:
      return <LabContent />;
    default:
      return null;
  }
};

export default ContentCyber;
