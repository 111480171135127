import React, { Component } from "react";
import { connect } from "react-redux";
import { LoaderButton, IconLabel } from "ctia-ui";
import { approveRequest, withdrawRequest } from "actions/requestCyber";
import { fetchApproveWaitingRequests, clearRequests } from "actions/requests";
import ModalConfirm from "components/ModalConfirm/index";

class ApproveButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showWithdrawnConfirm: false,
      showApproveConfirm: false,
      isLoading: false,
      isFormValid: true
    };
  }

  onApprove = () => {
    this.setState({ isLoading: true });
    const {
      approveRequest,
      fetchApproveWaitingRequests,
      request,
      withdraw = false
    } = this.props;

    const approved = !withdraw;

    approveRequest(request.id, approved)
      .then(fetchApproveWaitingRequests)
      .then(() => this.closeModal);
  };

  onWithdraw = () => {
    const id = this.props.id;

    // withdraw reason
    const reason = document.getElementById("reasonRef").value;

    if (reason.length < 5) {
      this.setState({ isFormValid: false });
    } else {
      this.setState({ isLoading: true });
      this.props.withdrawRequest(id, reason, this.clearAndFetch);
    }
  };

  clearAndFetch = () => {
    this.props.clearRequests();
    this.props.fetchApproveWaitingRequests();
  };

  showWithdrawnModal = () => this.setState({ showWithdrawnConfirm: true });
  showApproveModal = () => this.setState({ showApproveConfirm: true });

  closeModal = () => {
    this.setState({
      showWithdrawnConfirm: false,
      showApproveConfirm: false,
      isLoading: false
    });
  };

  getStyleParams = withdraw => {
    return {
      btnStyle: withdraw ? "danger" : "success",
      btnText: withdraw ? "Withdraw" : "Approve",
      approved: !withdraw
    };
  };

  onValidate = value => {
    this.setState({ isFormValid: value.length > 4 });
  };

  render() {
    const { withdraw = false, request } = this.props;
    const params = this.getStyleParams(withdraw);

    const modalHandler = withdraw
      ? this.showWithdrawnModal
      : this.showApproveModal;

    const renderButtonIcon = () => {
      const icon = withdraw ? "ban" : "check";
      return <IconLabel label={params.btnText} awesomeIcon={icon} />;
    };

    return [
      <LoaderButton
        bsStyle={params.btnStyle}
        bsSize="xsmall"
        text={renderButtonIcon()}
        className="button-right-offset"
        onClick={modalHandler}
        key={1}
      />,
      <ModalConfirm
        type="withdrawReason"
        show={this.state.showWithdrawnConfirm}
        onHide={this.closeModal}
        onHandle={this.onWithdraw}
        requestData={request}
        isLoading={this.state.isLoading}
        isFormValid={this.state.isFormValid}
        onValidate={this.onValidate}
        key={2}
      />,
      <ModalConfirm
        type="approve"
        show={this.state.showApproveConfirm}
        onHide={this.closeModal}
        onHandle={this.onApprove}
        requestData={request}
        isLoading={this.state.isLoading}
        key={3}
      />
    ];
  }
}

export default connect(null, {
  approveRequest,
  withdrawRequest,
  fetchApproveWaitingRequests,
  clearRequests
})(ApproveButton);
