import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Table } from "react-bootstrap";
import LabRowATL from "containers/ControlPanel/Payments/LabRowATL";
import SwitcherMemberFee from "containers/ControlPanel/Payments/SwitcherMemberFee";
import SwitcherAtlFee from "containers/ControlPanel/Payments/SwitcherAtlFee";
import { fetchAtlList, fetchAtlInvoices } from "actions/catl";
import "containers/ControlPanel/Payments/payments.css";

const LabListATL = ({ catl, fetchAtlList, fetchAtlInvoices }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAtlList().then(() => {
      setLoading(false);

      // run background long task
      fetchAtlInvoices();
    });
  }, [fetchAtlList, fetchAtlInvoices]);

  if (loading || !catl || !catl.labs) return "Loading...";

  return (
    <Row>
      <Table striped condensed hover className="ctia-table">
        <thead>
          <tr>
            <th>
              <Row>
                <Col xs={3}>Laboratory</Col>
                <Col xs={3}>&nbsp;</Col>
                <Col xs={3}>
                  <SwitcherMemberFee />
                </Col>
                <Col xs={3}>
                  <SwitcherAtlFee />
                </Col>
              </Row>
            </th>
            <th style={{ width: 200 }}>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {catl.labs.map((lab, i) => {
            if(lab.isActiveLab)
              return <LabRowATL atl={lab} key={i} />;
          })}
        </tbody>
      </Table>
    </Row>
  );
};

function mapStateToProps({ catl }) {
  return { catl };
}

export default connect(mapStateToProps, { fetchAtlList, fetchAtlInvoices })(
  LabListATL
);
