import React from "react";
import { connect } from "react-redux";
import { Col, Label, Row, Table } from "react-bootstrap";
import {
  Bubble,
  Pager,
  PagerPageSize,
  PagerStatusFilter,
  Divider,
  CounterLabel
} from "ctia-ui";
import ListRow from "components/labRequest/LabListRow";
import FilterOptions from "containers/request/TestRequestList/FilterOptions";
import CyberTable from "containers/CyberTable";
import { getRequestsForTesting, clearRequests } from "actions/requests";
import { setPageSize, changeCyberRequestTestStatus } from "actions/app";

class TestRequestList extends CyberTable {
  componentWillUnmount() {
    this.props.clearRequests();
  }

  onStatusChange = event => {
    // update app filter settings
    this.props.changeCyberRequestTestStatus(event.target.value);

    // fetch new list (waiting for redux storage update first)
    setTimeout(this.fetchTableData, 150);
  };

  setPagerSize = size => this.props.setPageSize(size);

  fetchTableData = () => {
    const settings = {
      page: this.startPage,
      size: this.props.app.pageSize,
      statusFilter: this.props.app.cyberRequestTestStatus.filter
    };

    return this.props.getRequestsForTesting(settings);
  };

  render() {
    const { requests, app } = this.props;
    const { cyberRequestTestStatus: status } = app;
    const tableData = this.setTableData(requests);
    const { page, pages, records } = this.setPagerData(requests);
    const counter = this.setCounter(tableData, records);

    const renderBubble = () => {
      if (records > 0) {
        return (
          <Bubble>
            Click on the <strong>Request ID</strong> to view the request. You
            may sort by <strong>Status</strong> type.
          </Bubble>
        );
      }
    };

    return (
      <div>
        <h1 className="block-bottom-offset">
          Requests for Testing <Label>{status.title}</Label>
        </h1>
        {renderBubble()}
        <Row>
          <div id="request-filter">
            <Col xs={4} md={4}>
              <CounterLabel counter={counter} />
            </Col>
            <Col xs={8} md={4}>
              <PagerPageSize
                size={app.pageSize}
                onChangeHandler={this.onChangePageSize}
                options={[50, 100]}
                showAll={true}
              />
            </Col>
            <Col xs={12} md={4}>
              <PagerStatusFilter
                status={status.title}
                statusOptions={<FilterOptions />}
                onStatusChange={this.onStatusChange}
              />
            </Col>
          </div>
        </Row>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>Request ID</th>
              <th>Vendor</th>
              <th>Model Name/Number</th>
              <th>Request Type</th>
              <th>Testing Status</th>
            </tr>
          </thead>
          <tbody>
            <ListRow tableRows={tableData} />
          </tbody>
        </Table>
        <Divider />
        <Pager moveTo={this.fetchTableData} totalPages={pages} page={page} />
      </div>
    );
  }
}

function mapStateToProps({ requests, app }) {
  return { requests, app };
}

export default connect(mapStateToProps, {
  getRequestsForTesting,
  clearRequests,
  changeCyberRequestTestStatus,
  setPageSize
})(TestRequestList);
