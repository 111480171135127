import React from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import OTADeviceInfo from "containers/request/OTA/OTAContainer";
import PointOfContact from "containers/request/Initial/Steps/Laboratory/PointOfContactLab";
import TestPlansLabView from "containers/request/OTA/TestPlans/TestPlans--LabView";

const LabViewOta = () => {
  return (
    <Row>
      <Col xs={12}>
        <Tabs defaultActiveKey={1} id="request-edit-tabs">
          <Tab eventKey={1} title="Device Info">
            <OTADeviceInfo />
          </Tab>
          <Tab eventKey={2} title="Point of Contact">
            <PointOfContact />
          </Tab>
          <Tab eventKey={3} title="Test Results">
            <TestPlansLabView />
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};

export default LabViewOta;
