import React from 'react';
import { connect } from 'react-redux';

const Content = ({ user, children }) => {
  const cssContainer =
    user && user.token
      ? 'app-content__container'
      : 'app-content__container-center';

  return (
    <main className="app-content">
      <div className={cssContainer}>
        <div className="app-content__aligner">{children}</div>
      </div>
    </main>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(Content);
