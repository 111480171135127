import React, { useState, useEffect } from "react";
import { LoaderButton } from "ctia-ui";
import { connect } from "react-redux";
import { formatDate } from "helpers/DateHelper";
import { getFullName } from "helpers/UsersHelper";
import { renderNameWithFlag } from "containers/ControlPanel/Payments/RenderHelperATL";
import { fetchATLComments, postATLComment } from "actions/comments";
import { FormControl } from "react-bootstrap";

const LabComments = ({
  companyId,
  labName,
  flagCode,
  catl,
  comments,
  fetchATLComments,
  postATLComment
}) => {
  const [text, setText] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (companyId) {
      setLoading(true);
      fetchATLComments(companyId, catl.invoicingYear).then(() =>
        setLoading(false)
      );
    }
  }, [companyId, catl, fetchATLComments]);

  const onTextChange = event => {
    setText(event.target.value);
  };

  const onSubmit = () => {
    setLoading(true);
    postATLComment(companyId, catl.invoicingYear, text)
      .then(() => fetchATLComments(companyId, catl.invoicingYear))
      .then(() => {
        setText("");
        setLoading(false);
      });
  };

  const renderComments = () => {
    if (comments.data.length) {
      return comments.data.map((item, i) => {
        const postDate = formatDate(item.createdat);
        const submitter = getFullName(item.user);
        return (
          <div key={i}>
            <b>{submitter}</b> <i>({postDate})</i>:<p>{item.comment}</p>
          </div>
        );
      });
    }
    return null;
  };

  if (!comments) return null;

  const count = comments.pager.TOTALRECORDS || 0;

  return (
    <div>
      <h6>{renderNameWithFlag(labName, flagCode, 18)}</h6>
      <h4>Comments ({count})</h4>
      {renderComments()}
      <FormControl
        componentClass="textarea"
        value={text}
        onChange={onTextChange}
        disabled={isLoading}
      />
      <LoaderButton
        bsStyle="primary"
        bsSize="small"
        text="Post comment"
        onClick={onSubmit}
        isLoading={isLoading}
        loadingText="Posting..."
        className="button-top-offset"
        disabled={text.length < 3}
      />
    </div>
  );
};

function mapStateToProps({ catl, comments }) {
  return { catl, comments };
}

export default connect(mapStateToProps, { fetchATLComments, postATLComment })(
  LabComments
);
