import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Nav } from "ctia-react-bootstrap-v4";
import { LinkContainer } from "react-router-bootstrap";
import { IconLabel } from "ctia-ui";
import { isNoCert } from "helpers/AppHelper";
import { isActiveNavLink } from "helpers/UrlHelper";
import { updateUserLocation } from "actions/user";
import * as navList from "helpers/NavHelper";
import NavBadge from "components/MainMenu/NavBadge";
import {
  homeNav,
  homeNavStaff,
  homeNavAccounting
} from "dictionaries/Navigation";

const ProgramMenu = props => {
  const renderLink = navItem => {
    const isActive = isActiveNavLink(navItem.url, props.history.location);
    return (
      <LinkContainer
        to={navItem.url}
        key={navItem.id}
        isActive={() => isActiveNavLink(navItem.url, props.history.location)}
      >
        <Nav.Link
          eventKey={navItem.id}
          onSelect={() => {
            props.updateUserLocation(navItem.url);
            props.history.push(navItem.url);
          }}
          active={isActive}
        >
          <IconLabel
            awesomeIcon={navItem.awesomeIcon}
            glyph={navItem.glyphIcon}
            label={navItem.title}
          />
          <NavBadge badge={navItem.badgeType} />
        </Nav.Link>
      </LinkContainer>
    );
  };

  const getNavList = () => {
    const { app, user, history } = props;

    if (!user || !user.role) return [];

    const { pathname } = history.location;
    const isAdminCpPath = _.startsWith(pathname, "/admin/cp");
    const isAccountingReportPath = _.startsWith(pathname, "/accountant");
    const hasExceptionalPath =
      isNoCert(app.certProgram) && (isAdminCpPath || isAccountingReportPath);

    // show just home menu
    if (
      pathname === "/" ||
      pathname === "/user/profile" ||
      hasExceptionalPath
    ) {
      if (user.isStaff) return homeNavStaff;
      if (user.isAccountant) return homeNavAccounting;
      return homeNav;
    }

    // render Cyber program menu:
    if (app.isCyberCert) {
      return navList.cyberNavTabs[user.role];
    }

    // render Battery program menu:
    if (app.isBatteryCert) {
      return navList.batteryNavTabs[user.role];
    }

    // render BLC program menu:
    if (app.isBLCCert) {
      return navList.blcNavTabs[user.role];
    }

    // render OTA program menu:
    if (app.isOTACert) {
      return navList.otaNavTabs[user.role];
    }

    // render DHR program menu:
    if (app.isDHRCert) {
      return navList.dhrNavTabs[user.role];
    }

    return [];
  };

  return (
    <Nav className="mr-auto">
      {getNavList().map(navItem => renderLink(navItem))}
    </Nav>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps, { updateUserLocation })(ProgramMenu);
