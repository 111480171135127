import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Glyphicon } from "react-bootstrap";
import UploadQueue from "components/UploadQueue";
import {
  hasDocsForUpload,
  getUploadQueue,
  hasPendingUploads,
  getUploadedTitlesQueue,
  hasTitlesForChange,
  manageQueue
} from "helpers/UploadsHelper";
import {
  isResultPass,
  isResultNoTesting,
  countReports,
  getReports
} from "helpers/RequestTestsHelper";
import {
  uploadTestReport,
  addToQueue,
  removeFromQueue,
  clearQueue,
  updateReportTitle
} from "actions/uploads";
import { deleteTestReport } from "actions/tests";

class TestResultPanel extends Component {
  componentDidMount() {
    const {
      tests,
      request,
      addToQueue,
      removeFromQueue,
      uploadTestReport
    } = this.props;
    const { testResults } = request;
    const testId = tests[0].id;
    const testResultStatus = tests[0].requesttestpassedstatusid;

    // uploading test reports
    // if `no testing` - shouldn't upload
    if (hasDocsForUpload(testResults) && isResultPass(testResultStatus)) {
      const queue = getUploadQueue(testResults);
      _.map(queue, doc => {
        const { file, title, slot } = doc;
        const uploadOptions = {
          slot,
          title,
          testId,
          fileContent: file,
          queueId: `report-${testId}-slot-${slot}`
        };
        manageQueue(
          uploadOptions,
          uploadTestReport,
          addToQueue,
          removeFromQueue
        );
      });
    }

    // delete existed reports if selected `no testing`
    if (countReports(testResults) && isResultNoTesting(testResultStatus)) {
      const reportsList = getReports(testResults);

      reportsList.map(report => {
        return this.props.deleteTestReport(report.requesttestid, report.slot);
      });
    }

    // update report titles
    if (hasTitlesForChange(testResults) && isResultPass(testResultStatus)) {
      const queue = getUploadedTitlesQueue(testResults);

      _.map(queue, doc => {
        this.props.updateReportTitle(testId, doc.slot, doc.title);
      });
    }
  }

  componentWillUnmount() {
    this.props.clearQueue();
  }

  getResultTitle = () => {
    const { isSubmit, isUpdate } = this.props;

    if (isSubmit) return "submitted";
    if (isUpdate) return "updated";

    return "saved";
  };

  render() {
    const { request, uploads } = this.props;
    const waitMsg = hasPendingUploads(uploads)
      ? "Please wait while test results are uploading."
      : "";

    return (
      <Alert bsStyle="warning">
        <p>
          <Glyphicon glyph="ok" className="icon-right-offset" />
          Test Result for Request <b>{request.tn}</b> has been successfully
          saved. {waitMsg}
        </p>
        <UploadQueue />
      </Alert>
    );
  }
}

function mapStateToProps({ request, tests, uploads }) {
  return { request, tests, uploads };
}

export default connect(mapStateToProps, {
  addToQueue,
  clearQueue,
  removeFromQueue,
  uploadTestReport,
  updateReportTitle,
  deleteTestReport
})(TestResultPanel);
