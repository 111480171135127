import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import RequestResultPage from "components/request/RequestResultPage";
import PageTitle from "components/RequestPageTitle/index";
import RequestInfoBar from "containers/request/InfoBar/index";
import ContentOta from "containers/request/RequestInfo/ContentOta";
import GoBackButton from "components/form/buttons/GoBackButton";
import { clearRequest } from "actions/request";
import { clearTests } from "actions/tests";
import { clearOtaStore } from "actions/ota";
import { fetchOtaRequestData } from "actions/requestOta";

class RequestInfoOta extends Component {
  fetchAll = id => {
    const { match, user, fetchOtaRequestData } = this.props;
    const reqId = id ? id : match.params.id;

    return fetchOtaRequestData(reqId, user);
  };

  componentDidMount() {
    return this.fetchAll();
  }

  componentDidUpdate(prevProps) {
    const { match, preloader, request } = this.props;

    // don't touch anything in case of active loading state
    if (preloader) return;

    // get new request id
    const newId = match.params.id;

    // if id is different with current, than fetch new request data
    if (newId !== prevProps.match.params.id) {
      return this.fetchAll(newId);
    }

    // need update request data due to server changes
    if (
      prevProps.request &&
      !prevProps.request.needUpdate &&
      request &&
      request.needUpdate
    ) {
      return this.fetchAll(newId);
    }

    if (
      request &&
      prevProps.request &&
      request.isTogglerEnabled !== prevProps.request.isTogglerEnabled &&
      request.isTogglerEnabled === false
    ) {
      this.fetchAll(match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
    this.props.clearTests();
    this.props.clearOtaStore();
  }

  render() {
    const { request, user, history, tests } = this.props;

    if (request === null || tests === null) {
      return null;
    }

    const renderCommonBlocks = () => {
      const btnLabel = user.isAccountant
        ? "Return to Payment"
        : "Return to Certified Device";
      const showBackBtn =
        (user.isAccountant &&
          _.includes(user.location, "accountant/payments")) ||
        _.includes(user.location, "devices");

      return (
        <div>
          <PageTitle />
          <GoBackButton label={btnLabel} show={showBackBtn} />
          <RequestInfoBar />
        </div>
      );
    };

    if (request.formSaved) {
      return (
        <div>
          <RequestResultPage isUpdate={true} history={history} />
        </div>
      );
    }

    return (
      <div>
        {renderCommonBlocks()}
        <ContentOta role={user.role} />
      </div>
    );
  }
}

function mapStateToProps({ request, user, tests, preloader }) {
  return { request, user, tests, preloader };
}

export default connect(mapStateToProps, {
  clearRequest,
  clearTests,
  fetchOtaRequestData,
  clearOtaStore
})(RequestInfoOta);
