import React, { useState } from "react";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";
import { LoaderButton, IconLabel } from "ctia-ui";
import { Modal, Button } from "react-bootstrap";
import AddUserForm from "containers/users/UsersList/AddUserForm";
import ErrorForm from "components/form/ErrorForm";
import { clearCountryState } from "actions/countries";
import { addLegacyUser } from "actions/user";
import { validateUserAddForm } from "helpers/UsersHelper";
import { scrollTo } from "helpers/AppHelper";

const formId = "add-user-form";

const AddUserModal = ({
  show,
  modalHandler,
  clearCountryState,
  addLegacyUser
}) => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const renderSubmitButton = () => {
    return (
      <span className="icon-left-offset">
        <LoaderButton
          bsStyle="success"
          bsSize="small"
          text={<IconLabel label="Save" awesomeIcon="check" />}
          onClick={() => {
            setLoading(true);

            addLegacyUser()
              .then(res => {
                setLoading(false);
                modalHandler();
                setValidated(false);
                clearCountryState();
              })
              .catch(err => {
                setLoading(false);
                setErrorMessage(err);
                scrollTo(formId);
              });
          }}
          disabled={!validated}
          isLoading={loading}
        />
      </span>
    );
  };

  const onFormChange = event => {
    setErrorMessage(null);

    const form = event.currentTarget;

    if (form.checkValidity() === false || !validateUserAddForm(form)) {
      return setValidated(false);
    }

    setValidated(true);
  };

  return (
    <Modal show={show}>
      <Modal.Header closeButton onHide={modalHandler}>
        <Modal.Title>Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          id={formId}
          className="ctia-form"
          onChange={onFormChange}
        >
          <ErrorForm errors={errorMessage} />
          <AddUserForm />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {renderSubmitButton()}
        <Button onClick={modalHandler} className="agr-close-btn">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(null, {
  clearCountryState,
  addLegacyUser
})(AddUserModal);
