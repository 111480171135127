import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestHWVersion from "components/request/RequestHWVersion";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestModelDHR from "containers/request/DHR/RequestModelDHR";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import RequestParentLink from "components/request/RequestParentLink";
import { setValue } from "actions/request";
import {
  fetchDHRModelsAndLabs,
  setRequestPermissions
} from "actions/dhr/dhrRequest";
import { submitWizardStep } from "actions/wizard";
import { updateDeviceInfo } from "actions/requestOta";
import { ifModelExists } from "actions/model";
import { hasStringValue } from "helpers/RequestHelper";
import { findExistedModelByName } from "helpers/ModelHelper";
import { getRequestOwner } from "helpers/UsersHelper";

class DHRContainer extends CyberComponent {
  modelField = this.props.request.id ? "modelName" : "existedModel";

  componentDidMount() {
    const {
      request,
      user,
      fetchDHRModelsAndLabs,
      setRequestPermissions
    } = this.props;

    // fetch only for the first time
    if (!this.validate()) {
      // define request's owner
      const owner = getRequestOwner(request, user);

      // fetch devicemodels, legacy models and labs
      fetchDHRModelsAndLabs(owner);
    }

    setRequestPermissions(request, user);
  }

  onSaveClick = () => {
    const { dhr, request, setValue, updateDeviceInfo } = this.props;

    // if modelname not changed - just update device info
    const modelNameUntouched =
      request.modelName === request.__orig.vendorproductname;

    // modelname should be unique
    const existedModel = findExistedModelByName(
      request[this.modelField],
      dhr.vendorModels
    );

    if (existedModel && !modelNameUntouched) {
      setValue("modelNameAlreadyInUse", true);

      // return nothing, which means - no device update due to failed validation
      return;
    } else {
      setValue("modelNameAlreadyInUse", false);
      updateDeviceInfo(request);
    }
  };

  validateModel() {
    return hasStringValue(this.props.request, this.modelField);
  }

  validateHw() {
    return hasStringValue(this.props.request, "hwVersion");
  }

  validate() {
    // don't need to validate if form is non-editable
    if (!this.props.user.canEditDeviceInfo) return true;

    // check if all required fields are valid
    return this.validateModel() && this.validateHw();
  }

  onContinueClick = () => {
    const {
      dhr,
      request,
      user,
      setValue,
      ifModelExists,
      submitWizardStep
    } = this.props;

    // check if entered model name exists
    const existedModel = findExistedModelByName(
      request.existedModel,
      dhr.vendorModels
    );

    // if so - use existed model id
    const existedModelId = existedModel ? existedModel.value : null;
    const isLegacyModel = existedModel ? existedModel.isLegacy : false;
    const existingLegacyModelName = isLegacyModel ? existedModel.title : null;

    // update request field in redux
    setValue("existedModelId", existedModelId);
    setValue("existingModelIsLegacy", isLegacyModel);
    setValue("existingModelName", existingLegacyModelName);

    // check modelname uniqueness
    if (!existedModel) {
      ifModelExists(request.existedModel, user.companyid).then(isUnique => {
        if (isUnique) {
          return submitWizardStep();
        }
      });
    } else {
      return submitWizardStep();
    }
  };

  render() {
    const { request, user } = this.props;
    const saveHandler = user.canEditRequest ? this.onSaveClick : null;

    return (
      <div>
        <Instructions />
        <Form horizontal>
          {request.isEco && <RequestParentLink />}
          <RequestManufacturer />
          <RequestModelDHR />
          <RequestHWVersion />
          <SubmissionDetails />
          <RequestControls
            isValid={this.validate()}
            onSave={saveHandler}
            onContinue={this.onContinueClick}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ dhr, request, user }) {
  return { dhr, request, user };
}

export default connect(mapStateToProps, {
  fetchDHRModelsAndLabs,
  submitWizardStep,
  updateDeviceInfo,
  setValue,
  ifModelExists,
  setRequestPermissions
})(DHRContainer);
