import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import ApproveTestReports from "containers/request/OTA/TestPlans/ApproveReports/OtaApproveReport";
import { hasOtaReports } from "helpers/RequestTestsHelper";

const OtaTestReportsApprove = ({ tests, user, request }) => {
  if (!tests || !tests.length) return null;

  // check if at least 1 test has reports
  const hasReportsToApprove = _.some(tests, testModule => {
    if (!testModule.reports) return false;

    return hasOtaReports(testModule.reports);
  });
  
  if (!hasReportsToApprove) return null;

  // filter each test's reports (remove empty)
  const filteredTests = tests.map(testModule => {
    const filteredModule = { ...testModule };

    filteredModule.reports = _.filter(
      filteredModule.reports,
      report => report.docId !== 0
    );

    return filteredModule;
  });

  return filteredTests.map(testModule => {
    return <ApproveTestReports testModule={testModule} key={testModule.id} user={user} request={request}/>;
  });
};

function mapStateToProps({ tests }) {
  return { tests };
}

export default connect(mapStateToProps)(OtaTestReportsApprove);
